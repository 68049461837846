import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Table, Button, Badge } from "react-bootstrap";
import axios from "axios";
import moment from "moment-timezone";

import { config as initConfig } from "_config";
import { alertActions } from "_actions";
import { history, authHeader, getZConfig } from "_helpers";

import LoadingSpinner from "components/Spinner";

function Zendesk(props) {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const config = { ...initConfig, ...getZConfig() };
  const orderDetails = props.orderDetails;
  const customer_email = orderDetails.customer.email;
  const order_id = orderDetails.order.crmOrderId;
  const [tickets, setTickets] = useState([]);
  const [ticketids, setTicketids] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const activeTab = props.activeTab;

  const badge_style = {
    new: "warning",
    open: "danger",
    pending: "primary",
    solved: "info",
  };
  let ticket_array = [];

  const headers = {
    headers: { Authorization: "Bearer " + localStorage.getItem("zauth") },
  };

  const app_header = {
    headers: authHeader(),
  };

  const show_ticket = (ticketid) => {
    history.push("/admin/ticket/" + ticketid);
  };

  const showalert = () => {
    localStorage.removeItem("zauth");
    setError(true);
    dispatch(alertActions.error("Zendesk access token is expired. Go to Ticket page to reauthorize it again."));
  };

  const addticket = (response) => {
    if (response.data.results.length > 0) {
      for (let i = 0; i < response.data.results.length; i++) {
        ticket_array.push(response.data.results[i]);
      }
    }
  };

  const get_tickets = async (ticket_ids) => {
    let url;
    let response;

    for (let i = 0; i < ticket_ids.length; i++) {
      url = `${config.zendesk_url}/api/v2/tickets/${ticket_ids[i].id}`;
      response = await axios.get(url, headers).catch(function () {
        showalert();
      });
      if (response) ticket_array.push(response.data.ticket);
      else return false;
    }

    url = `${config.zendesk_url}/api/v2/search.json?query=type:ticket subject:"${order_id}"`;
    response = await axios.get(url, headers).catch(function () {
      showalert();
      return false;
    });
    if (response) {
      addticket(response);
    } else {
      return false;
    }

    url = `${config.zendesk_url}/api/v2/search.json?query=type:ticket comment:"${order_id}"`;
    response = await axios.get(url, headers).catch(function () {
      showalert();
      return false;
    });
    if (response) {
      addticket(response);
    } else {
      return false;
    }

    url = `${config.zendesk_url}/api/v2/search.json?query=type:ticket comment:"${customer_email}"`;
    response = await axios.get(url, headers).catch(function () {
      showalert();
      return false;
    });
    if (response) {
      addticket(response);
    } else {
      return false;
    }

    url = `${config.zendesk_url}/api/v2/search.json?query=type:ticket requester:"${customer_email}"`;
    response = await axios.get(url, headers).catch(function () {
      showalert();
      return false;
    });
    if (response) {
      addticket(response);
    } else {
      return false;
    }

    const seen = new Set();
    const ticket_id_array = [];

    const filteredArr = ticket_array.filter((el) => {
      const duplicate = seen.has(el.id);
      seen.add(el.id);
      ticket_id_array.push(el.id);
      return !duplicate;
    });
    setTickets(filteredArr);
    setTicketids(ticket_id_array);
    setLoading(false);
  };

  useEffect(() => {
    if (activeTab === "zendesk")
      axios.get(`${config.apiUrl1}/zendesk/tickets?orderUids=${orderId}`, app_header).then((res) => {
        const ticket_ids = res.data;
        get_tickets(ticket_ids);
      });
  }, [activeTab]);

  useEffect(() => {
    if (ticketids.length > 0) {
      let url = `${config.apiUrl1}/zendesk/tickets?orderUids=${orderId}`;
      for (let i = 0; i < ticketids.length; i++) {
        url += `&ids=${ticketids[i]}`;
      }
      axios.get(url, app_header).then((res) => {
        setKeywords(res.data);
      });
    }
  }, [ticketids]);

  if (error)
    return (
      <>
        <div className="order-info form-border fixed-height notes">
          <p>Zendesk Token is Expired</p>
          <p>
            Go to{" "}
            <Link to={"/admin/tickets/"} className="go-link">
              Tickets
            </Link>{" "}
            page to authorize zendesk.
          </p>
        </div>
      </>
    );

  return (
    <div className="order-info form-border fixed-height notes">
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <Table striped bordered responsive size="sm">
          <thead>
            <tr>
              <th>ID</th>
              <th>Subject</th>
              <th>Requested</th>
              <th>Updated</th>
              <th>Keywords</th>
              <th>Status</th>
              <th>View Ticket</th>
            </tr>
          </thead>
          <tbody>
            {tickets.length > 0 && (
              <>
                {tickets.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td>{item.id}</td>
                      <td>{item.subject}</td>
                      <td>{moment.utc(item.created_at).local().format("YYYY-MM-DD HH:mm:ss")}</td>
                      <td>{moment.utc(item.updated_at).local().format("YYYY-MM-DD HH:mm:ss")}</td>
                      <td>
                        {keywords.findIndex((x) => x.id === "" + item.id) > 0 &&
                          keywords[keywords.findIndex((x) => x.id === "" + item.id)]["detectedKeywords"].join(", ")}
                      </td>
                      <td>
                        <Badge variant={badge_style[item.status]}>{item.status}</Badge>
                      </td>
                      <td>
                        <Button size="sm" className="btn btn-primary" onClick={() => show_ticket(item.id)}>
                          View
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default Zendesk;
