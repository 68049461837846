import { config } from "_config";
import { authHeader, handleResponse, mappingQueryParams } from "_helpers";

export const trademarkDashboardService = { get };

function get(options) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/reports/trademark-dashboard${mappingQueryParams(options)}`;

  return fetch(url, requestOptions).then(handleResponse);
}
