import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Card, Row, Col, Form } from "react-bootstrap";
import moment from "moment-timezone";

import Button from "components/Button";

function AdvertisingFilters({
  date,
  category,
  categories,
  includeRecurring,
  onDateChange,
  onCategoryChange,
  onIncludeRecurringChange,
  onApply,
}) {
  const [dateRange, setDateRange] = useState("today");

  const loading = useSelector(({ ads: { loading } }) => loading);

  const handleChangeDataRange = ({ target: { value } }) => {
    setDateRange(value);

    switch (value) {
      case "today": {
        onDateChange({ from: moment().format("YYYY-MM-DD"), to: moment().format("YYYY-MM-DD") });
        break;
      }

      case "yesterday": {
        onDateChange({
          from: moment().subtract(1, "day").format("YYYY-MM-DD"),
          to: moment().subtract(1, "day").format("YYYY-MM-DD"),
        });
        break;
      }

      case "weektodate": {
        onDateChange({
          from: moment().startOf("week").format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
        });
        break;
      }

      case "lastweek": {
        onDateChange({
          from: moment().day(1).subtract(7, "day").format("YYYY-MM-DD"),
          to: moment().day(7).subtract(7, "day").format("YYYY-MM-DD"),
        });
        break;
      }

      case "monthtodate": {
        onDateChange({ from: moment().startOf("month").format("YYYY-MM-DD"), to: moment().format("YYYY-MM-DD") });
        break;
      }

      case "pastmonth": {
        onDateChange({
          from: moment().subtract(1, "months").format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
        });
        break;
      }

      case "lastmonth": {
        onDateChange({
          from: moment().subtract(1, "months").date(1).format("YYYY-MM-DD"),
          to: moment().startOf("month").subtract(1, "day").format("YYYY-MM-DD"),
        });
        break;
      }

      default:
        break;
    }
  };

  const handleChangeDate = ({ target: { name, value } }) => onDateChange({ ...date, [name]: value });
  const handleChangeCategory = ({ target: { value } }) => onCategoryChange(value);

  return (
    <Card className="p-2">
      <Row>
        <Col>
          <Form>
            <Form.Row>
              <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3}>
                <Form.Label>Date Range:</Form.Label>
                <Form.Control size="sm" as="select" name="dateRange" value={dateRange} onChange={handleChangeDataRange}>
                  <option value="" hidden></option>
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="weektodate">Week to Date</option>
                  <option value="lastweek">Last Week</option>
                  <option value="monthtodate">Month to Date</option>
                  <option value="pastmonth">Past Month</option>
                  <option value="lastmonth">Last Month</option>
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3}>
                <Form.Label>Start Date:</Form.Label>
                <Form.Control
                  size="sm"
                  type="date"
                  name="from"
                  max={moment().format("YYYY-MM-DD")}
                  value={date.from}
                  onChange={handleChangeDate}
                />
              </Form.Group>

              <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3}>
                <Form.Label>End Date:</Form.Label>
                <Form.Control
                  size="sm"
                  type="date"
                  name="to"
                  max={moment().format("YYYY-MM-DD")}
                  value={date.to}
                  onChange={handleChangeDate}
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3}>
                <Form.Label>Category:</Form.Label>
                <Form.Control size="sm" as="select" name="category" value={category} onChange={handleChangeCategory}>
                  <option value="">All categories</option>
                  {categories.map(({ code, value }) => (
                    <option key={`category-${code}`} value={code}>
                      {value}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group className="d-flex align-items-end" as={Col} xs={12} sm={6} md={6} lg={3} xl={3}>
                <Form.Check
                  id="includeRecurring"
                  type="checkbox"
                  name="includeRecurring"
                  checked={includeRecurring}
                  onChange={() => {
                    onIncludeRecurringChange(!includeRecurring);
                  }}
                />
                <Form.Label htmlFor="includeRecurring">Recurring payments</Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3}>
                <Button size="sm" isLoading={loading} onClick={onApply}>
                  Filter
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
        </Col>
      </Row>
    </Card>
  );
}

export default AdvertisingFilters;
