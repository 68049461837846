import React from "react";
import { Col, Form, Row, Card } from "react-bootstrap";
import styled from "styled-components";

import { TAB_CODES } from "variables/Variables";

const StyledCard = styled(Card)`
  padding-top: 8px;
  padding-bottom: 8px;

  .form-group:not(:last-child) {
    margin-bottom: 12px;
  }

  .form-label,
  .form-check-label {
    display: flex;
    align-items: start;
    color: black;
    margin: 0;
    padding-top: 3px;
    padding-bottom: 3px;
    text-transform: unset;
    font-size: 0.875rem;
  }

  & > hr {
    margin: 1rem 0;
  }
`;

const FormTrademarkAssessment = ({ orderDetails, selectedProduct, onChange, onError }) => {
  const productDetails = orderDetails.products[selectedProduct];

  const handleChangeProduct = ({ target: { name, value } }) => {
    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, [name]: value } : product
      ),
    });
  };

  const handleChangeFinding = ({ target: { name, value } }) => {
    const exFields = { [name]: value === "yes" };

    if (value === "no") {
      exFields[`${name}Description`] = null;
    }

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, ...exFields } : product
      ),
    });
  };

  const handleAddError = (field) => onError(TAB_CODES.assessment, field);

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">ASSESSMENT</h4>
      <hr />
      <h6 className="font-weight-bold">Findings</h6>

      <StyledCard className="mb-3 px-2">
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Trademark Assessment:
          </Form.Label>
          <Col sm={7} className="d-flex align-items-center">
            <Form.Check
              id="trademarkAssessment_r_no"
              className="mr-3"
              type="radio"
              label="No"
              name="trademarkAssessment"
              value="no"
              checked={!productDetails.trademarkAssessment}
              onChange={handleChangeFinding}
            />
            <Form.Check
              id="trademarkAssessment_r_yes"
              type="radio"
              label="Yes"
              name="trademarkAssessment"
              value="yes"
              checked={productDetails.trademarkAssessment}
              onChange={handleChangeFinding}
            />
          </Col>
        </Form.Group>
        {productDetails.trademarkAssessment && (
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Description:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="trademarkAssessmentDescription"
                value={productDetails.trademarkAssessmentDescription || ""}
                onChange={handleChangeProduct}
                onInvalid={() => handleAddError("Trademark Assessment Description")}
              />
            </Col>
          </Form.Group>
        )}
      </StyledCard>

      <StyledCard className="mb-3 px-2">
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Competitive Analysis:
          </Form.Label>
          <Col sm={7} className="d-flex align-items-center">
            <Form.Check
              id="competitiveAnalysis_r_no"
              className="mr-3"
              type="radio"
              label="No"
              name="competitiveAnalysis"
              value="no"
              checked={!productDetails.competitiveAnalysis}
              onChange={handleChangeFinding}
            />
            <Form.Check
              id="competitiveAnalysis_r_yes"
              type="radio"
              label="Yes"
              name="competitiveAnalysis"
              value="yes"
              checked={productDetails.competitiveAnalysis}
              onChange={handleChangeFinding}
            />
          </Col>
        </Form.Group>
        {productDetails.competitiveAnalysis && (
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Description:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="competitiveAnalysisDescription"
                value={productDetails.competitiveAnalysisDescription || ""}
                onChange={handleChangeProduct}
                onInvalid={() => handleAddError("Competitive Analysis Description")}
              />
            </Col>
          </Form.Group>
        )}
      </StyledCard>

      <StyledCard className="mb-3 px-2">
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Strategic Guidance:
          </Form.Label>
          <Col sm={7} className="d-flex align-items-center">
            <Form.Check
              id="strategicGuidance_r_no"
              className="mr-3"
              type="radio"
              label="No"
              name="strategicGuidance"
              value="no"
              checked={!productDetails.strategicGuidance}
              onChange={handleChangeFinding}
            />
            <Form.Check
              id="strategicGuidance_r_yes"
              type="radio"
              label="Yes"
              name="strategicGuidance"
              value="yes"
              checked={productDetails.strategicGuidance}
              onChange={handleChangeFinding}
            />
          </Col>
        </Form.Group>
        {productDetails.strategicGuidance && (
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Description:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="strategicGuidanceDescription"
                value={productDetails.strategicGuidanceDescription || ""}
                onChange={handleChangeProduct}
                onInvalid={() => handleAddError("Strategic Guidance Description")}
              />
            </Col>
          </Form.Group>
        )}
      </StyledCard>
    </div>
  );
};

export default FormTrademarkAssessment;
