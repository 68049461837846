import { adsAccountsConstants } from "_constants";

const initial_state = {
  loading: 0,
  accounts: null,
  categories: null,
};

export function adsAccounts(state = initial_state, action) {
  const { type, payload } = action;

  switch (type) {
    // GET ACCOUNTS
    case adsAccountsConstants.GET_ADS_ACCOUNTS_REQUEST:
      return { ...state, loading: state.loading + 1 };

    case adsAccountsConstants.GET_ADS_ACCOUNTS_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1, accounts: payload };

    case adsAccountsConstants.GET_ADS_ACCOUNTS_FAILED:
      return { ...state, loading: state.loading && state.loading - 1 };

    // ADD ACCOUNT
    case adsAccountsConstants.ADD_ADS_ACCOUNT_REQUEST:
      return { ...state, loading: state.loading + 1 };

    case adsAccountsConstants.ADD_ADS_ACCOUNT_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1, accounts: [...state.accounts, payload] };

    case adsAccountsConstants.ADD_ADS_ACCOUNT_FAILED:
      return { ...state, loading: state.loading && state.loading - 1 };

    // UPDATE ACCOUNT
    case adsAccountsConstants.UPDATE_ADS_ACCOUNT_REQUEST:
      return { ...state, loading: state.loading + 1 };

    case adsAccountsConstants.UPDATE_ADS_ACCOUNT_SUCCESS: {
      const accounts = state.accounts.map((item) => (item.id === payload.id ? payload : item));

      return { ...state, loading: state.loading && state.loading - 1, accounts };
    }

    case adsAccountsConstants.UPDATE_ADS_ACCOUNT_FAILED:
      return { ...state, loading: state.loading && state.loading - 1 };

    // DELETE ACCOUNT
    case adsAccountsConstants.DELETE_ADS_ACCOUNT_REQUEST:
      return { ...state, loading: state.loading + 1 };

    case adsAccountsConstants.DELETE_ADS_ACCOUNT_SUCCESS: {
      const accounts = state.accounts.filter((item) => item.id !== payload);

      return { ...state, loading: state.loading && state.loading - 1, accounts };
    }

    case adsAccountsConstants.DELETE_ADS_ACCOUNT_FAILED:
      return { ...state, loading: state.loading && state.loading - 1 };

    // GET CATEGORIES
    case adsAccountsConstants.GET_CATEGORIES_REQUEST:
      return { ...state, loading: state.loading + 1 };

    case adsAccountsConstants.GET_CATEGORIES_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1, categories: payload };

    case adsAccountsConstants.GET_CATEGORIES_FAILED:
      return { ...state, loading: state.loading && state.loading - 1 };

    default:
      return state;
  }
}
