import moment from "moment";

export function getEarliestPaymentDate(products) {
  return products.reduce((earliestDate, { paymentDate }) => {
    return moment(paymentDate).isBefore(earliestDate) ? paymentDate : earliestDate;
  }, products[0].paymentDate);
}

export function datePickerFormatter(date) {
  return moment.utc(date).local().format("YYYY-MM-DD");
}
