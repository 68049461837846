import { servicesConstants } from "_constants";

const initState = {
  loading: 0,
};

export function services(state = { ...initState }, action) {
  switch (action.type) {
    case servicesConstants.GETALL_REQUEST:
      return {
        loading: state.loading + 1,
      };
    case servicesConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        items: action.result,
      };
    case servicesConstants.GETALL_FAILURE:
      return {
        ...state,
        loading: state.loading - 1,
        error: action.error,
      };

    case servicesConstants.DEPLOY_REQUEST:
      return {
        ...state,
        loading: state.loading + 1,
      };
    case servicesConstants.DEPLOY_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
      };
    case servicesConstants.DEPLOY_FAILURE:
      return {
        ...state,
        loading: state.loading - 1,
        error: action.error,
      };

    default:
      return state;
  }
}
