export function parseQueryString(queryString) {
  const searchParams = new URLSearchParams(queryString);
  const res = {};

  for (const [key, value] of searchParams.entries()) {
    res[key] = value;
  }

  return res;
}

export function getFileExtension(fileName, includeDot = false) {
  const regexp = /(?:\.([^.]+))?$/;
  const extension = regexp.exec(fileName)[0];

  if (extension === ".%EXTENSION%") {
    return "";
  }

  if (extension && !includeDot) {
    return extension.slice(1);
  }

  return extension;
}

export function parseGoodsCategory(text) {
  if (!text || text === undefined || text?.length === 0) {
    return [];
  }

  // Check if markup valid
  let unclosedBracketsCount = 0;
  for (const c of text) {
    if (c === "{") {
      unclosedBracketsCount++;
    }
    if (c === "}") {
      unclosedBracketsCount--;
    }
  }
  if (unclosedBracketsCount !== 0) {
    return [{ type: "text", text, descIndex: null }];
  }

  const blockTemplate = { type: "text", text: "", descIndex: null };

  const blocks = [];
  blocks.push({ ...blockTemplate });

  let blockIndex = 0;
  let descIndex = 0;
  unclosedBracketsCount = 0;

  for (let i = 0; i < text.length; i++) {
    const char = text[i];

    if (char === "{") {
      unclosedBracketsCount++;
      if (unclosedBracketsCount === 1) {
        if (i !== 0) {
          blockIndex++;
          blocks.push({ ...blockTemplate, descIndex });
        } else {
          blocks[blockIndex].descIndex = descIndex;
        }

        blocks[blockIndex].type = "desc";
        descIndex++;
      }
    }

    blocks[blockIndex].text += char;

    if (char === "}") {
      unclosedBracketsCount--;
      if (unclosedBracketsCount === 0 && i !== 0 && i !== text?.length - 1 && text[i + 1] !== "{") {
        blockIndex++;
        blocks.push({ ...blockTemplate });
      }
    }
  }

  return blocks;
}

export function getProtocolAndDomain(url) {
  try {
    const parsedUrl = new URL(url);
    return `${parsedUrl.protocol}//${parsedUrl.hostname}`;
  } catch (error) {
    console.error("Invalid URL:", error);
    return null;
  }
}
