import { healthcheckConstants } from "../_constants";

const initState = {
  allHealth: {
    loading: false,
    healthchecks: null,
    error: null,
  },
  backendHealth: {
    loading: false,
    healthchecks: null,
    error: null,
  },
  frontendHealth: {
    loading: false,
    healthchecks: null,
    error: null,
  },
};

export function healthchecks(state = initState, action) {
  switch (action.type) {
    // ALL HEALTH
    case healthcheckConstants.GET_ALL_HEALTH_REQUEST:
      return {
        ...state,
        allHealth: { loading: true, healthchecks: null, error: null },
      };
    case healthcheckConstants.GET_ALL_HEALTH_SUCCESS:
      return {
        ...state,
        allHealth: { loading: false, healthchecks: action?.result || null, error: null },
      };
    case healthcheckConstants.GET_ALL_HEALTH_FAILURE:
      return {
        ...state,
        allHealth: { loading: true, healthchecks: null, error: action?.error },
      };

    // BACKEND HEALTH
    case healthcheckConstants.GET_BACKEND_HEALTH_REQUEST:
      return {
        ...state,
        backendHealth: { loading: true, healthchecks: null, error: null },
      };
    case healthcheckConstants.GET_BACKEND_HEALTH_SUCCESS:
      return {
        ...state,
        backendHealth: { loading: false, healthchecks: action?.result || null, error: null },
      };
    case healthcheckConstants.GET_BACKEND_HEALTH_FAILURE:
      return {
        ...state,
        backendHealth: { loading: false, healthchecks: null, error: action?.error },
      };

    // FRONTEND HEALTH
    case healthcheckConstants.GET_FRONTEND_HEALTH_REQUEST:
      return {
        ...state,
        frontendHealth: { loading: true, healthchecks: null, error: null },
      };
    case healthcheckConstants.GET_FRONTEND_HEALTH_SUCCESS:
      return {
        ...state,
        frontendHealth: { loading: false, healthchecks: action?.result || null, error: null },
      };
    case healthcheckConstants.GET_FRONTEND_HEALTH_FAILURE:
      return {
        ...state,
        frontendHealth: { loading: false, healthchecks: null, error: action?.error },
      };

    default:
      return state;
  }
}
