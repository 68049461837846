import React from "react";
import { Row, Form } from "react-bootstrap";

import { state_array } from "variables/Variables";

function IndividualAddress({ address, isForceShowing = false }) {
  if (!isForceShowing && !address) return null;

  return (
    <>
      <br />
      <Form.Group as={Row}>
        <Form.Label column sm={12}>
          Address
        </Form.Label>
      </Form.Group>

      <div className="pl-3">
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Address1:
          </Form.Label>
          <Form.Label column sm={7}>
            {address?.address1 || "–"}
          </Form.Label>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Address2:
          </Form.Label>
          <Form.Label column sm={7}>
            {address?.address2 || "–"}
          </Form.Label>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            City:
          </Form.Label>
          <Form.Label column sm={7}>
            {address?.city || "–"}
          </Form.Label>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            State:
          </Form.Label>
          <Form.Label column sm={7}>
            {state_array[address?.state] || "–"}
          </Form.Label>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Zip Code:
          </Form.Label>
          <Form.Label column sm={7}>
            {address?.zip || "–"}
          </Form.Label>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            County:
          </Form.Label>
          <Form.Label column sm={7}>
            {address?.county || "–"}
          </Form.Label>
        </Form.Group>
      </div>
    </>
  );
}

export default IndividualAddress;
