import moment from "moment";

export function getCurrentDateUTC() {
  return moment().tz("UTC").format("YYYY-MM-DDTHH:mm:ss");
}

export function currencyFormatter(amount) {
  const formatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });

  return formatter.format(amount);
}

export function phoneFormatter(phone) {
  if (phone) {
    const newPhone = phoneCleaner(phone);
    return `(${newPhone.slice(0, 3)}) ${newPhone.slice(3, 6)}-${newPhone.slice(6)}`;
  }
  return;
}

export function phoneCleaner(phone) {
  if (phone) {
    return phone.replace(/\D/g, "");
  }
  return;
}

export const phoneRegexp = new RegExp(/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/);
export const phoneNumRegexp = new RegExp(/\d{10}/);

export function capitalizeString(str) {
  if (!str) {
    return "";
  }

  return str.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase());
}
