import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Button, Form } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment-timezone";

import { history } from "_helpers";
import { customerActions } from "_actions";

const initChargeAmounts = { amendment: "0.00", USPTOFee: "0.00" };

const BorderlessTd = styled.td`
  border: none !important;
`;

function FilingFees({ orderDetails, productDetails }) {
  const dispatch = useDispatch();
  const {
    children,
    customer,
    order: { id },
  } = orderDetails || {};

  // const [chargeAmountUSPTOFee, setChargeAmountUSPTOFee] = useState("0.00");
  // const [chargeAmountAmendment, setChargeAmountAmendment] = useState("0.00");
  const [chargeAmounts, setChargeAmounts] = useState(initChargeAmounts);

  const isTrademarkRenewal = productDetails?.code?.code === "TRADEMARK_Trademark_Renewal";
  const isTrademarkRegister = ["TRADEMARK_RegisterTrademark", "TRADEMARK_RegisterTrademarkV2"].includes(
    productDetails?.code?.code
  );

  const handleChargeUSPTOFee = useCallback(() => {
    dispatch(customerActions.chargeUSPTOFee(id, chargeAmounts.USPTOFee));
  }, [dispatch, id, chargeAmounts]);

  const handleChargeAmendment = useCallback(() => {
    dispatch(customerActions.chargeAmendment(id, chargeAmounts.amendment));
  }, [dispatch, id, chargeAmounts]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    const sanitizedValue = value.split("").reduce((str, char) => {
      if (char === "." && str.includes(char)) {
        return str;
      }

      return (str += char);
    }, "");

    setChargeAmounts({ ...chargeAmounts, [name]: sanitizedValue });
  };

  const handleKeyPress = (e) => {
    if (!/^([0-9]{1,})?(\.)?([0-9]{1,})?$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    // const id = name.split(".")[1];
    const parsedValue = parseFloat(value);

    if (value === "." || value === "") {
      setChargeAmounts({ ...chargeAmounts, [name]: "0.00" });
      return;
    }

    if (!isNaN(parsedValue) && value !== parsedValue.toFixed(2)) {
      setChargeAmounts({ ...chargeAmounts, [name]: parsedValue.toFixed(2) });
    }
  };

  return (
    <div className="form-border">
      <br />
      <Table responsive>
        <thead>
          <tr className="info-row">
            <th>Name</th>
            <th>Date</th>
            <th>Amount ($)</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {!!children?.length &&
            children.map(({ order: { id, total }, products }) => (
              <tr key={`filing-fee-${id}`} className="info-row">
                <td>
                  <Link to={`/admin/orders/${id}`} target="_blank" style={{ textDecoration: "underline" }}>
                    {products[0]?.code?.customerName || products[0]?.customerName || "N/A"}
                  </Link>
                </td>
                <td>{moment.utc(products[0]?.paymentDate).local().format("YYYY/MM/DD HH:mm:ss")}</td>
                <td>{total}</td>
                <td>{products[0]?.paymentStatus}</td>
              </tr>
            ))}
          {!children?.length && (
            <tr className="info-row">
              <td>No Fees</td>
            </tr>
          )}
          <tr className="info-row">
            <td>
              <strong>Payment method was updated:</strong>
            </td>
            <td>
              {!customer?.paymentMethodWasUpdated
                ? "N/A"
                : moment.utc(customer?.paymentMethodWasUpdated).local().format("YYYY/MM/DD HH:mm:ss")}
            </td>
            <td className="d-flex">
              {isTrademarkRenewal && (
                <Form.Control
                  size="sm"
                  className="mr-2 w-50"
                  name="USPTOFee"
                  value={chargeAmounts.USPTOFee || ""}
                  onBlur={handleBlur}
                  onKeyPress={handleKeyPress}
                  onChange={handleChange}
                />
              )}
              <Button size="sm" style={{ minWidth: "120px" }} variant="info" onClick={handleChargeUSPTOFee}>
                Charge
                {Number(chargeAmounts.USPTOFee) ? " amount" : " all"}
              </Button>
            </td>
          </tr>
          {isTrademarkRegister && (
            <tr className="info-row">
              <BorderlessTd>
                <strong>Amendment Service</strong>
              </BorderlessTd>
              <BorderlessTd>
                <Form.Control
                  size="sm"
                  name="amendment"
                  value={chargeAmounts.amendment || ""}
                  onBlur={handleBlur}
                  onKeyPress={handleKeyPress}
                  onChange={handleChange}
                />
              </BorderlessTd>
              <BorderlessTd>
                <Button
                  disabled={chargeAmounts.amendment === "0.00"}
                  size="sm"
                  style={{ minWidth: "120px" }}
                  variant="info"
                  onClick={handleChargeAmendment}
                >
                  Charge
                </Button>
              </BorderlessTd>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default FilingFees;
