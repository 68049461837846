import { useEffect, useState } from "react";
import { Container, Row, Col, Table, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";

import { offerGroupsActions, offerActions } from "_actions";

import Card from "components/Card/Card";
import Spinner from "components/Spinner";
import Button from "components/Button";

export default function OfferReport() {
  const dispatch = useDispatch();
  const offerGroupState = useSelector((state) => state.offerGroup);
  const offerState = useSelector((state) => state.offer);

  const [searchOptions, setSearchOptions] = useState({
    fromDate: moment().format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    offerGroup: "",
  });

  const th = [
    { code: "offerName", value: "Offer" },
    { code: "clicks", value: "Count of clicks" },
  ];

  const handleChange = ({ target: { name, value } }) => setSearchOptions({ ...searchOptions, [name]: value });

  const search = () => dispatch(offerActions.getOfferReports(searchOptions));

  useEffect(() => {
    dispatch(offerActions.getOfferReports(searchOptions));

    if (!offerGroupState.list.length) {
      dispatch(offerGroupsActions.getAll());
    }
  }, []);

  const tableRenderer = () => (
    <Table responsive className="orders-table">
      <thead>
        <tr>
          {th.map(({ value }, key) => (
            <th key={`th-${key}`}>{value}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {offerState.listByClicks.map((row, key) => (
          <tr key={`row-${key}`}>
            {th.map(({ code }) => (
              <td key={`column-${code}`}>{row[code]}</td>
            ))}
          </tr>
        ))}
        {!offerState.listByClicks.length && (
          <tr>
            <td>No Results</td>
          </tr>
        )}
      </tbody>
    </Table>
  );

  return (
    <div className="content">
      <Container fluid className="mt-5 py-1">
        <Row>
          <Col md={12}>
            <div className="search-box my-3">
              <Form>
                <Form.Row>
                  <Form.Group as={Col} xs={12} sm={6} md={6} lg={4} xl={4}>
                    <Form.Label>Date From</Form.Label>
                    <Form.Control
                      size="sm"
                      type="date"
                      name="fromDate"
                      value={searchOptions.fromDate}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} sm={6} md={6} lg={4} xl={4}>
                    <Form.Label>Date to</Form.Label>
                    <Form.Control
                      size="sm"
                      type="date"
                      name="toDate"
                      value={searchOptions.toDate}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} sm={12} md={12} lg={4} xl={4}>
                    <Form.Label>Offer group</Form.Label>
                    <Form.Control
                      as="select"
                      size="sm"
                      custom
                      value={searchOptions.offerGroup}
                      name="offerGroup"
                      onChange={handleChange}
                    >
                      <option value="">Choose...</option>
                      {offerGroupState.list.map((group, key) => (
                        <option key={group.id + group.name} value={group.id}>
                          {group.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row className="justify-content-end">
                  <Button
                    size="sm"
                    variant="primary"
                    className="btn-sm mr-1"
                    type="button"
                    isLoading={offerState.status === "loading"}
                    onClick={search}
                  >
                    Search
                  </Button>
                </Form.Row>
              </Form>
            </div>
            <Card
              title="Offer Report"
              ctTableFullWidth
              content={offerState.status === "loading" ? <Spinner /> : tableRenderer()}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
