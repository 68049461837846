import { Form, Row, Col } from "react-bootstrap";
import moment from "moment-timezone";

import { month_array } from "variables/Variables";

const MIN_YEAR = 1920;

function getMonthDaysArray(y, m) {
  const leapYear = (y % 4 === 0 && y % 100 !== 0) || y % 400 === 0;
  let date_array = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  if (m === 2 && leapYear) {
    date_array[1] = 29;
  }

  return [...date_array];
}

export default function DateOfBirth({ productDetails, onChange, renderExtraLabel }) {
  const yearRange = [];
  const dateRange = [];
  const thisYear = new Date().getFullYear();

  const d = moment.utc(productDetails?.dateOfBirth || new Date());

  const month = d.month() + 1;
  const year = d.year();
  const date = d.date();

  const daysArray = getMonthDaysArray(year, month);

  for (let i = 1; i <= daysArray[month - 1]; i++) {
    dateRange.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  for (let i = thisYear, key = 0; i >= MIN_YEAR; i--, key++) {
    yearRange.push(
      <option key={key} value={i}>
        {i}
      </option>
    );
  }

  function handleDateChange({ target: { name, value } }) {
    if (value === "") {
      onChange({ target: { name: "dateOfBirth", value: null } });
      return;
    }

    const oldDate = moment
      .utc(productDetails?.dateOfBirth || MIN_YEAR + "-01-01")
      .format("YYYY-MM-DD")
      .split("-");

    const newVal = String(value).padStart(2, "0");

    if (name === "year") {
      oldDate[0] = newVal;
    }
    if (name === "month") {
      oldDate[1] = newVal;
    }
    if (name === "date") {
      oldDate[2] = newVal;
    }

    const daysArr = getMonthDaysArray(Number(oldDate[0]), Number(oldDate[1]));
    const monthDays = daysArr[Number(oldDate[1]) - 1];

    if (monthDays < Number(oldDate[2])) {
      oldDate[2] = String(monthDays).padStart(2, "0");
    }

    const newDate = oldDate.join("-");

    onChange({ target: { name: "dateOfBirth", value: newDate } });
  }

  return (
    <Form.Group as={Row}>
      <Form.Label column sm={5}>
        Date of Birth: {renderExtraLabel && renderExtraLabel()}
      </Form.Label>
      <Col sm={7} className="d-flex align-items-center">
        <Form.Control
          as="select"
          name="month"
          value={productDetails?.dateOfBirth ? month : ""}
          onChange={handleDateChange}
        >
          <option value="">Month</option>
          {Object.keys(month_array).map((prop, key) => (
            <option key={key} value={prop}>
              {month_array[prop]}
            </option>
          ))}
        </Form.Control>
        <Form.Control
          as="select"
          name="date"
          value={productDetails?.dateOfBirth ? date : ""}
          onChange={handleDateChange}
        >
          <option value="">Date</option>
          {dateRange}
        </Form.Control>
        <Form.Control
          as="select"
          name="year"
          value={productDetails?.dateOfBirth ? year : ""}
          onChange={handleDateChange}
        >
          <option value="">Year</option>
          {yearRange}
        </Form.Control>
      </Col>
    </Form.Group>
  );
}
