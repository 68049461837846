import React from "react";

const IncAmendmentForeignBusinessNameChangeForm = () => {
  return (
    <div
      className="order-info mx-0 my-2 p-3 rounded"
      style={{ backgroundColor: "#f8f8f8", borderLeft: "4px solid #d0d0d0", borderRight: "4px solid #d0d0d0" }}
    >
      <div className="text-center" style={{ fontSize: "0.875rem" }}>
        Product not required extra details
      </div>
    </div>
  );
};

export default IncAmendmentForeignBusinessNameChangeForm;
