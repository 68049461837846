// import { useSelector } from "react-redux";
import { Fragment } from "react";
import { Col, Row, Form } from "react-bootstrap";

import { state_array } from "variables/Variables";

export default function TrademarkOwnerList({ trademarkOwners }) {
  if (!trademarkOwners) return null;

  // const { nationalitiesOpt } = useSelector(({ variants }) => variants);

  const labelRenderer = (idx) => {
    switch (idx) {
      case 0:
        return (
          <>
            <hr />
            <h6 className="font-weight-bold">Current Trademark Owners</h6>
          </>
        );
      case 1:
        return (
          <>
            <hr />
            <h6 className="font-weight-bold">Trademark Owner History</h6>
          </>
        );

      default:
        return <hr />;
    }
  };

  return trademarkOwners.length > 0 ? (
    trademarkOwners.map((owner, idx) => (
      <Fragment key={`tm-owner-${idx}`}>
        {labelRenderer(idx)}
        <Col key={idx} sm={12} className="pl-3">
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Party name:
            </Form.Label>
            <Form.Label column sm={7}>
              {owner.partyName}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Party type:
            </Form.Label>
            <Form.Label column sm={7}>
              {owner.partyType}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Legal entity type:
            </Form.Label>
            <Form.Label column sm={7}>
              {owner.legalEntityTypeCode}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Address1:
            </Form.Label>
            <Form.Label column sm={7}>
              {owner.address1}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Address2:
            </Form.Label>
            <Form.Label column sm={7}>
              {owner.address2}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              City:
            </Form.Label>
            <Form.Label column sm={7}>
              {owner.city}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Country:
            </Form.Label>
            <Form.Label column sm={7}>
              {owner.country}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              State
            </Form.Label>
            <Form.Label column sm={7}>
              {state_array[owner.state]}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Postcode:
            </Form.Label>
            <Form.Label column sm={7}>
              {owner.postcode}
            </Form.Label>
          </Form.Group>

          {/* <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Entry number:
            </Form.Label>
            <Form.Label column sm={7}>
              {owner.entryNumber}
            </Form.Label>
          </Form.Group> */}
          {/* 
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Nationality:
            </Form.Label>
            <Form.Label column sm={7}>
              {(owner.nationality || []).map((value) => nationalitiesOpt[value]).join(", ")}
            </Form.Label>
          </Form.Group> */}

          {/* <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Name change explanation:
            </Form.Label>
            <Form.Label column sm={7}>
              {owner.nameChangeExpanation}
            </Form.Label>
          </Form.Group> */}
        </Col>
      </Fragment>
    ))
  ) : (
    <>
      <hr />
      <h6>Owners</h6>
      <Form.Group as={Row}>
        <Form.Label column sm={12}>
          No Owners
        </Form.Label>
      </Form.Group>
    </>
  );
}
