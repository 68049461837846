import React, { Component, createRef } from "react";
import { NavLink } from "react-router-dom";

import logo from "assets/img/reactlogo.png";
import { connect } from "react-redux";
import { customerActions, alertActions } from "_actions";

import { config } from "_config";

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submenu: false,
    };
    this.ref = createRef(null);

    this.showsubmenu = this.showsubmenu.bind(this);

    this.props.getsummary();
  }

  activeRoute(routeName) {
    return this.props.location.pathname === routeName ? "active" : "";
    // return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  showsubmenu(prop, e) {
    const {
      customers: { loading, exportLoading },
    } = this.props;

    if (prop.collapse !== undefined) {
      prop.collapse = !prop.collapse;
    }

    if (loading || exportLoading) {
      e.preventDefault();
    }
  }

  handleLinkClick = (e) => {
    const {
      customers: { loading, exportLoading },
    } = this.props;

    if (loading || exportLoading) {
      e.preventDefault();
    }
  };

  handleClickOutside = (e) => {
    const { isOpen, handleNavBar } = this.props;

    if (isOpen && this.ref.current && !this.ref.current?.contains(e.target)) {
      handleNavBar(false);
    }
  };

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    const { alert } = this.props;
    if (alert.message && alert.message !== prevProps.alert.message) {
      this.props.handleClick("tr", alert.type, alert.message);
      this.props.clearalert();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  render() {
    const { image, color, hasImage, routes, authentication, summaryorders, customers } = this.props;
    const loading = Boolean(customers.loading || customers.exportLoading);

    const sidebarBackground = {
      backgroundImage: "url(" + image + ")",
    };

    return (
      <div ref={this.ref} id="sidebar" className="sidebar" data-color={color} data-image={image}>
        {hasImage ? <div className="sidebar-background" style={sidebarBackground} /> : null}
        <div className="logo">
          <a href={`${config.publicUrl}/`} className="simple-text logo-mini">
            <div className="logo-img">
              <img src={logo} alt="logo_image" />
            </div>
          </a>
          <a href={`${config.publicUrl}/`} className="simple-text logo-normal">
            InnovaLab CRM
          </a>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            {routes.map((prop, key) => {
              if (!prop.redirect)
                return (
                  <li
                    className={prop.upgrade ? "active active-pro" : this.activeRoute(prop.layout + prop.path)}
                    key={key}
                  >
                    {!prop.child && (!prop.allowedRoles || prop.allowedRoles.includes(authentication.user.role)) && (
                      <NavLink
                        to={prop.path !== "#" ? prop.layout + prop.path : prop.path}
                        className="nav-link"
                        activeClassName="active"
                        onClick={(e) => this.showsubmenu(prop, e)}
                      >
                        <i className={prop.icon} />
                        <p>{prop.name}</p>
                      </NavLink>
                    )}

                    {prop.views && prop.collapse ? (
                      <ul className="nav submenu">
                        {prop.views.map((item, key) => {
                          if (!item.allowedRoles || item.allowedRoles.includes(authentication.user.role))
                            return (
                              <React.Fragment key={key}>
                                {item.submenu &&
                                  (prop.name !== "Orders" ||
                                    (prop.name === "Orders" &&
                                      (item.category === "ALL" ||
                                        authentication.user.availableCategories.includes(item.category)))) && (
                                    <li
                                      key={key}
                                      className={
                                        prop.upgrade ? "active active-pro" : this.activeRoute(item.layout + item.path)
                                      }
                                    >
                                      <NavLink
                                        onClick={this.handleLinkClick}
                                        to={item.layout + item.path}
                                        className="nav-link"
                                        activeClassName="active"
                                        style={{
                                          opacity: loading && prop.name === "Orders" ? "50%" : "100%",
                                        }}
                                      >
                                        <i className={item.icon} />
                                        <p>{item.name}</p>
                                      </NavLink>
                                    </li>
                                  )}
                              </React.Fragment>
                            );
                        })}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </li>
                );
              return null;
            })}
          </ul>
          <hr />
          <div className="summary-counts">
            <p className="title text-center">Summary Counts:</p>
            <ul>
              {summaryorders && (
                <>
                  <li>
                    Leads:&nbsp;
                    {summaryorders.countGroupedByEINStatus.Lead || 0}
                  </li>
                  <li>
                    Completed:&nbsp;
                    {summaryorders.countGroupedByEINStatus.Completed || 0}
                  </li>
                  <li>
                    Processing:&nbsp;
                    {summaryorders.countGroupedByEINStatus.Processing || 0}
                  </li>
                  <li>
                    SS4:&nbsp;
                    {summaryorders.countGroupedByEINStatus.SS4 || 0}
                  </li>
                  <li>
                    Other Error:&nbsp;
                    {summaryorders.countGroupedByEINStatus.OtherError || 0}
                  </li>
                  <li>
                    Cancelled:&nbsp;
                    {summaryorders.countGroupedByEINStatus.Cancelled || 0}
                  </li>
                  <li>
                    Partial Refunds:&nbsp;
                    {summaryorders.countGroupedByOrderStatus.PartialRefund || 0}
                  </li>
                  <li>
                    Full Refunds:&nbsp;
                    {summaryorders.countGroupedByOrderStatus.FullRefund || 0}
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const { authentication, summaryorders, alert, customers } = state;
  return { authentication, summaryorders, alert, customers };
}

const actionCreators = {
  getsummary: customerActions.getsummary,
  clearalert: alertActions.clear,
};

const connectedCustomerPage = connect(mapState, actionCreators)(Sidebar);

export { connectedCustomerPage as Sidebar };
