export const healthcheckConstants = {
  GET_ALL_HEALTH_REQUEST: "HEALTHCHECK_GET_ALL_HEALTH_REQUEST",
  GET_ALL_HEALTH_SUCCESS: "HEALTHCHECK_GET_ALL_HEALTH_SUCCESS",
  GET_ALL_HEALTH_FAILURE: "HEALTHCHECK_GET_ALL_HEALTH_FAILURE",

  GET_BACKEND_HEALTH_REQUEST: "HEALTHCHECK_GET_BACKEND_HEALTH_REQUEST",
  GET_BACKEND_HEALTH_SUCCESS: "HEALTHCHECK_GET_BACKEND_HEALTH_SUCCESS",
  GET_BACKEND_HEALTH_FAILURE: "HEALTHCHECK_GET_BACKEND_HEALTH_FAILURE",

  GET_FRONTEND_HEALTH_REQUEST: "HEALTHCHECK_GET_FRONTEND_HEALTH_REQUEST",
  GET_FRONTEND_HEALTH_SUCCESS: "HEALTHCHECK_GET_FRONTEND_HEALTH_SUCCESS",
  GET_FRONTEND_HEALTH_FAILURE: "HEALTHCHECK_GET_FRONTEND_HEALTH_FAILURE",
};
