import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Table, Row, Col, Form } from "react-bootstrap";

import { deployUIActions } from "_actions";

import Spinner from "components/Spinner";
import Button from "components/Button";

const mainBranchNames = ["master", "main"];

function TabDeployUI() {
  const dispatch = useDispatch();
  const [allVersions, loading] = useSelector(({ deployServices: { items, loading } }) => [items, loading]);

  const [branchType, setBranchType] = useState("master");
  const [jobID, setJobID] = useState("");
  const [selectedVersion, setSelectedVersion] = useState("");
  const [uiTypes, setUITypes] = useState([]);
  const [uiName, setUIName] = useState("");
  const [selectedDestinations, setSelectedDestinations] = useState([]);

  const filter = ({ branch }) =>
    (branchType === "master" && mainBranchNames.includes(branch)) ||
    (branchType === "custom" && !mainBranchNames.includes(branch));

  const labelBuilder = ({ timestamp, author, branch }) =>
    branchType === "master" ? `${timestamp} ${author}` : `${timestamp} ${author} (${branch})`;

  const handleBranchType = ({ target: { value } }) => {
    setBranchType(value);
    setUIName("");
    setJobID("");
    setSelectedVersion("");
    setSelectedDestinations([]);
  };

  const handleUIName = ({ target: { value } }) => {
    setUIName(value);
    setJobID("");
    setSelectedVersion("");
    setSelectedDestinations([]);
  };

  const handleSelectVersion = ({ target: { value: id } }) => {
    const version = allVersions.versions[uiName].find(({ jobId }) => jobId === id);

    setJobID(id);
    setSelectedVersion(`${version.timestamp} - ${version.author} (${version.branch})`);
    setSelectedDestinations([]);
  };

  const resetState = () => {
    setJobID("");
    setSelectedVersion("");
    setUITypes([]);
    setUIName("");
    setSelectedDestinations([]);
  };

  const handleRefresh = () => {
    resetState();
    dispatch(deployUIActions.getAll());
  };

  const handleDomainName = ({ target: { name } }) => {
    const isSelected = selectedDestinations.includes(name);

    setSelectedDestinations(
      isSelected ? selectedDestinations.filter((el) => el !== name) : [...selectedDestinations, name]
    );
  };

  const handleDeployService = () => {
    dispatch(deployUIActions.deployUI(jobID, selectedDestinations));
  };

  useEffect(() => {
    if (allVersions) {
      const sortedArray = Object.keys(allVersions.versions || {}).sort();
      setUITypes(sortedArray);
    }
  }, [allVersions]);

  useEffect(() => {
    dispatch(deployUIActions.getAll());
  }, []);

  return (
    <div className="form-border bg-white">
      <Row>
        <Col md="12">
          <Card className="p-3">
            <Form inline>
              <Form.Group>
                <Form.Label className="my-1 mr-3" htmlFor="inlineFormCustomSelectPref">
                  Branch Type:
                </Form.Label>
                <Form.Control
                  as="select"
                  className="my-1 mr-5"
                  id="inlineFormCustomSelectPref"
                  size="sm"
                  value={branchType}
                  onChange={handleBranchType}
                >
                  <option value="master">Master</option>
                  <option value="custom">Custom</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                  UI Type:
                </Form.Label>
                <Form.Control
                  as="select"
                  className="my-1 mr-5"
                  id="inlineFormCustomSelectPref"
                  size="sm"
                  value={uiName}
                  onChange={handleUIName}
                >
                  <option value="" disabled>
                    Select UI Type
                  </option>
                  {uiTypes &&
                    uiTypes.map((prop, key) => (
                      <option key={key} value={prop}>
                        {prop}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label className="my-1 mr-4" htmlFor="inlineFormCustomSelectPref">
                  Version:
                </Form.Label>
                <Form.Control
                  as="select"
                  className="my-1 mr-5"
                  id="inlineFormCustomSelectPref"
                  size="sm"
                  value={jobID}
                  onChange={handleSelectVersion}
                >
                  <option value="" disabled>
                    Select Version
                  </option>
                  {allVersions &&
                    uiName &&
                    allVersions.versions[uiName].filter(filter).map((prop) => (
                      <option key={prop.jobId} value={prop.jobId}>
                        {labelBuilder(prop)}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Button
                type="button"
                className="my-1"
                size="sm"
                disabled={loading}
                isLoading={loading}
                onClick={handleRefresh}
              >
                Refresh
              </Button>
            </Form>
          </Card>

          {allVersions ? (
            <Card>
              <Card.Title className="m-0 p-2">UI Version Deployment</Card.Title>
              <Table striped bordered size="sm">
                <thead>
                  <tr>
                    <th>UI Version</th>
                    <th>Domains</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedVersion ? (
                    <tr>
                      <td>{selectedVersion}</td>
                      <td>
                        {allVersions.destinations[uiName].map(({ domain }) => (
                          <p className="m-0" htmlFor={domain} key={domain}>
                            <input
                              type="checkbox"
                              name={domain}
                              className="mr-2"
                              onChange={handleDomainName}
                              checked={selectedDestinations.includes(domain)}
                            />
                            {domain}
                          </p>
                        ))}
                      </td>
                      <td>
                        <Button size="sm" disabled={loading} isLoading={loading} onClick={handleDeployService}>
                          Deploy
                        </Button>
                      </td>
                    </tr>
                  ) : (
                    <tr />
                  )}
                </tbody>
              </Table>
            </Card>
          ) : (
            <Spinner />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default TabDeployUI;
