import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import classnames from "classnames";
import moment from "moment-timezone";

import { customerActions } from "_actions";

import IndividualEdit, { initIndividual } from "../Individual/Edit";
import { initAddress } from "../IndividualAddress/Edit";

const initStateSearchResult = {
  markName: "",
  numberAssigned: "",
  owner: { ...initIndividual, address: initAddress },
  classification: "",
  service: "",
  description: "",
  renewalDate: moment().format("YYYY-MM-DD"),
};

export default function StateSearchResultList({ stateSearchResults, onError, onChange }) {
  const dispatch = useDispatch();

  const { collapseStateSearchResults } = useSelector(({ customers }) => customers);

  const handleAddStateSearchResult = () =>
    onChange(stateSearchResults ? [...stateSearchResults, initStateSearchResult] : [initStateSearchResult]);

  const handleDeleteStateSearchResult = (deleteIdx) =>
    onChange(stateSearchResults.filter((o, _idx) => deleteIdx !== _idx));

  const handleChangeStateSearchResult = ({ target: { name, value } }, index) =>
    onChange(
      stateSearchResults.map((searchResult, idx) => (idx === index ? { ...searchResult, [name]: value } : searchResult))
    );

  const handleChangeOwner = (value, index) =>
    handleChangeStateSearchResult({ target: { name: "owner", value } }, index);

  const handleChangeNumberAssigned = ({ target: { name, value } }, index) => {
    if (/^[a-zA-Z0-9\\\-]+$/.test(value) || value === "")
      onChange(
        stateSearchResults.map((stateSearch, idx) => (idx === index ? { ...stateSearch, [name]: value } : stateSearch))
      );
  };

  return (
    <>
      <hr />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          <h6 className="font-weight-bold">State search results</h6>
        </Form.Label>
        <Col sm={7}>
          <Button size="sm" onClick={handleAddStateSearchResult}>
            Add Search
          </Button>
        </Col>
      </Form.Group>

      {stateSearchResults?.map((result, idx) => {
        const isCollapsed = !collapseStateSearchResults.includes(idx);

        return (
          <Col key={idx} sm={12} className="pl-3">
            <Form.Group as={Row}>
              <Form.Label
                column
                sm={5}
                style={{ cursor: "pointer" }}
                onClick={() => dispatch(customerActions.setCollapseStateSearch(idx))}
              >
                <i className={`fa fa-caret-${isCollapsed ? "down" : "up"}`} /> Number assigned:
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  size="sm"
                  type="text"
                  name="numberAssigned"
                  pattern="^[a-zA-Z0-9\\\-]+$"
                  value={result.numberAssigned || ""}
                  onChange={(e) => handleChangeNumberAssigned(e, idx)}
                />
              </Col>
            </Form.Group>

            {!isCollapsed && (
              <Col className={classnames({ collapse: true, show: !isCollapsed })}>
                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Mark name:
                  </Form.Label>
                  <Col sm={7}>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="markName"
                      value={result.markName || ""}
                      onChange={(e) => handleChangeStateSearchResult(e, idx)}
                    />
                  </Col>
                </Form.Group>

                <hr />
                <h6 className="font-weight-bold">Owner</h6>
                <IndividualEdit
                  individual={result.owner}
                  onError={onError}
                  onChange={(e) => handleChangeOwner(e, idx)}
                />

                <hr />
                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Classification:
                  </Form.Label>
                  <Col sm={7}>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="classification"
                      value={result.classification || ""}
                      onChange={(e) => handleChangeStateSearchResult(e, idx)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Service:
                  </Form.Label>
                  <Col sm={7}>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="service"
                      value={result.service || ""}
                      onChange={(e) => handleChangeStateSearchResult(e, idx)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Description:
                  </Form.Label>
                  <Col sm={7}>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="description"
                      value={result.description || ""}
                      onChange={(e) => handleChangeStateSearchResult(e, idx)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Renewal date:
                  </Form.Label>
                  <Col sm={7}>
                    <Form.Control
                      type="date"
                      name="renewalDate"
                      max={moment().format("YYYY-MM-DD")}
                      value={result.renewalDate}
                      onChange={(e) => handleChangeStateSearchResult(e, idx)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    {result.markName}
                  </Form.Label>
                  <Col sm={7}>
                    <Button size="sm" variant="danger" onClick={() => handleDeleteStateSearchResult(idx)}>
                      Remove
                    </Button>
                  </Col>
                </Form.Group>
              </Col>
            )}
          </Col>
        );
      })}
    </>
  );
}
