import React, { useState, useEffect, useMemo } from "react";
import { Col, Form, Row, Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { adminActions, variantsActions } from "_actions";

import Spinner from "components/Spinner";
import EditActionModal from "components/EditActionModal";

const ActionsRequired = () => {
  const dispatch = useDispatch();

  const defaultCategory = localStorage.getItem("@VariantsOptions_ActionsRequired/category") || "INC";
  const defaultShowCodes = localStorage.getItem("@VariantsOptions_ActionsRequired/showCodes") === "true" ? true : false;

  const availableCategories = useSelector(({ authentication: { user } }) => user.availableCategories);
  const { actionsRequiredList } = useSelector(({ variantsOptions }) => variantsOptions);
  const { processingStatusOpt, productCodes, productProcessingStatuses } = useSelector(({ variants }) => variants);
  const loadingActions = useSelector(({ variantsOptions: { loading } }) => loading);
  const loadingVariants = useSelector(({ variants: { loading } }) => loading);

  const [mode, setMode] = useState(null);
  const [category, setCategory] = useState(availableCategories.includes(defaultCategory) ? defaultCategory : "INC");
  const [product, setProduct] = useState("");
  const [selectedAction, setSelectedAction] = useState(null);
  const [currentStatus, setCurrentStatus] = useState("");
  const [showCodes, setShowCodes] = useState(defaultShowCodes);

  const loading = loadingActions + loadingVariants;

  const productObj = productCodes?.find((item) => item?.code === product);
  const productName = productObj?.customerName;
  const statusName = processingStatusOpt[currentStatus];

  const existingActions = useMemo(() => {
    const filteredList = actionsRequiredList?.filter(({ code }) => product && code?.category === category);

    const sortedList = filteredList?.sort((a, b) => {
      if (a.code.code > b.code.code) return 1;
      if (a.code.code < b.code.code) return -1;
      return 0;
    });

    return sortedList;
  }, [actionsRequiredList, category, product]);

  const searchResults = useMemo(
    () => existingActions?.filter((item) => currentStatus === "" || item?.status === currentStatus),
    [existingActions, currentStatus]
  );

  const actionRequiredColumns = [
    {
      key: "code",
      label: "Product Name",
      style: { minWidth: "150px", width: "10%" },
      render: (value) => {
        return (
          <div>
            <div>{value.customerName}</div>
            <div style={{ color: "gray" }}>{value.code}</div>
          </div>
        );
      },
    },
    {
      key: "status",
      label: "Status",
      style: { minWidth: "200px", width: "10%" },
      render: (value) => processingStatusOpt[value],
    },
    {
      key: "actionRequired",
      label: "Action Title",
      style: { minWidth: "200px", width: "30%" },
    },
    {
      key: "customerActionDescription",
      label: "Action Description",
      style: { minWidth: "300px", width: "50%" },
      render: (value, key) => {
        const dividedByLines = value?.split("\n");
        return (
          <div>
            {dividedByLines?.map((item, idx) => (
              <p key={`action-description-line-${key}-${idx}`} style={{ marginBottom: "8px" }}>
                {item}
              </p>
            ))}
          </div>
        );
      },
    },
  ];

  const headerRenderer = () => (
    <thead>
      <tr style={{ tableLayout: "fixed" }}>
        {actionRequiredColumns.map(({ key, label, render, ...props }) => (
          <th key={key} {...props}>
            {label}
          </th>
        ))}
        <th style={{ minWidth: "160px", width: "5%" }}>Edit</th>
      </tr>
    </thead>
  );

  const actionRowRenderer = (action, key) => {
    return (
      <tr key={key}>
        {actionRequiredColumns.map(({ key: colKey, render, ...props }) => (
          <td key={key + colKey} {...props}>
            {(render ? render(action[colKey], key) : action[colKey]) || ""}
          </td>
        ))}
        <td style={{ minWidth: "80px", maxWidth: "80px" }}>
          <Button
            className="mb-1 ml-1"
            size="sm"
            variant="warning"
            style={{ width: "60px" }}
            onClick={() => {
              setMode("edit");
              setSelectedAction({ ...action, code: action.code.code });
            }}
          >
            Edit
          </Button>
          <Button
            className="mb-1 ml-1"
            size="sm"
            variant="danger"
            style={{ width: "60px" }}
            disabled={!action?.actionRequired && !action?.customerActionDescription}
            onClick={() => {
              setMode("delete");
              setSelectedAction({ ...action, code: action.code.code });
            }}
          >
            Clear
          </Button>
        </td>
      </tr>
    );
  };

  const tableBodyRenderer = () => {
    if (loading) {
      return (
        <tbody>
          <tr>
            <td colSpan={5}>
              <Spinner />
            </td>
          </tr>
        </tbody>
      );
    }

    if (!searchResults?.length) {
      if (!product) {
        return (
          <tbody>
            <tr>
              <td colSpan={5}>Please, select category and product</td>
            </tr>
          </tbody>
        );
      }

      if (product && !currentStatus) {
        return (
          <tbody>
            <tr>
              <td colSpan={5}>
                No existing Actions for <span>{productName ? <b>{productName}</b> : "this"}</span> product. Please,
                select <b>Status</b> to create the new one.
              </td>
            </tr>
          </tbody>
        );
      }

      if (product && currentStatus) {
        return (
          <tbody>
            <tr>
              <td colSpan={5}>
                <span>
                  No existing Actions for <span>{productName ? <b>{productName}</b> : "this"}</span> product with{" "}
                  <span>{statusName ? <b>{statusName}</b> : "selected"}</span> status. Please, click to create the new
                  one:
                </span>
                &nbsp;
                <Button
                  className="ml-2"
                  size="sm"
                  variant="success"
                  disabled={!product || loadingActions}
                  onClick={() => {
                    setMode("add");
                    setSelectedAction({
                      code: product,
                      status: currentStatus,
                      actionRequired: "",
                      customerActionDescription: "",
                    });
                  }}
                >
                  Add New Action
                </Button>
              </td>
            </tr>
          </tbody>
        );
      }
    }

    return <tbody>{searchResults?.map(actionRowRenderer)}</tbody>;
  };

  const handleShowCodes = (e) => {
    e.stopPropagation();

    const checked = !showCodes;

    setShowCodes(checked);

    localStorage.setItem("@VariantsOptions_ActionsRequired/showCodes", checked);
  };

  const handleChangeStatus = ({ target: { value } }) => {
    setCurrentStatus(value);
  };

  const handleChangeProduct = ({ target: { value } }) => {
    setProduct(value);
    handleChangeStatus({ target: { value: "" } });

    value && dispatch(adminActions.getActionRequiredFields(value));
    value && dispatch(variantsActions.getProductProcessingStatuses(category, value));
  };

  const handleChangeCategory = ({ target: { value } }) => {
    setCategory(value);
    handleChangeProduct({ target: { value: "" } });

    localStorage.setItem("@VariantsOptions_ActionsRequired/category", value);

    // dispatch(adminActions.getActionRequiredFields(value));
    dispatch(variantsActions.getproductcodes(value));
    dispatch(variantsActions.getprocessing_status(value));
  };

  useEffect(() => {
    dispatch(variantsActions.getproductcodes(category));
    dispatch(variantsActions.getprocessing_status(category));
    // dispatch(variantsActions.getProductProcessingStatu1s(category));
  }, []);

  return (
    <div>
      <Row className="option-wrapper" style={{ padding: "10px" }}>
        <Form.Group as={Col} lg="2">
          <Form.Label>Category</Form.Label>
          <Form.Control size="sm" as="select" value={category} onChange={handleChangeCategory}>
            {availableCategories.map((item, key) => (
              <option value={item} key={key}>
                {item}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} lg="5">
          <Form.Label
            className="d-flex"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            Product
            <div className="ml-4" style={{ color: "gray" }} onClick={handleShowCodes}>
              <Form.Check
                type="checkbox"
                label="Show Codes"
                checked={showCodes}
                onChange={() => {}}
                onClick={handleShowCodes}
              />
            </div>
          </Form.Label>
          <Form.Control size="sm" as="select" value={product} disabled={loadingVariants} onChange={handleChangeProduct}>
            <option value="">Select...</option>
            {productCodes?.map((item, key) => (
              <option value={item.code} key={`product-item-${key}`}>
                {showCodes ? item.code : item.customerName}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} lg="5">
          <Form.Label>Status</Form.Label>
          <Form.Control
            size="sm"
            as="select"
            value={currentStatus}
            disabled={!product || loading}
            onChange={handleChangeStatus}
          >
            <option value="">All Statuses</option>
            {productProcessingStatuses?.map((item, key) => (
              <option value={item.code} key={`product-item-${key}`}>
                {item?.value || item?.code || "N/A"}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Row>
      <Row style={{ padding: "10px" }}>
        <Table responsive hover bordered striped size="lg" style={{ width: "100% !important" }}>
          {headerRenderer()}
          {tableBodyRenderer()}
        </Table>
      </Row>

      <EditActionModal
        show={Boolean(selectedAction)}
        action={selectedAction}
        mode={mode}
        category={category}
        product={productObj}
        handleClose={() => {
          setMode(null);
          setSelectedAction(null);
        }}
      />
    </div>
  );
};

export default ActionsRequired;
