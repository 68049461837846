import { Fragment } from "react";
import { Row, Form } from "react-bootstrap";

export default function CommonLawTrademarkResultList({ commonLawTrademarkResults }) {
  return (
    <>
      <hr />
      <h6 className="font-weight-bold">Common Law Results</h6>

      {commonLawTrademarkResults?.length > 0 ? (
        commonLawTrademarkResults.map(({ term, websiteURL }, idx) => (
          <Fragment key={idx}>
            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Term:
              </Form.Label>
              <Form.Label column sm={7}>
                {term}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Website URL:
              </Form.Label>
              <Form.Label column sm={7}>
                {websiteURL}
              </Form.Label>
            </Form.Group>
          </Fragment>
        ))
      ) : (
        <Form.Group as={Row}>
          <Form.Label column sm={12}>
            No Common Law
          </Form.Label>
        </Form.Group>
      )}
    </>
  );
}
