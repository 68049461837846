import { graphSalesLeadsConstants } from "_constants";
import { graphSalesLeadsService } from "_services";

import { alertActions } from "./";

export const graphSalesLeadsActions = { get };

function get(options) {
  return (dispatch) => {
    dispatch(request());

    graphSalesLeadsService.get(options).then(
      (graph) => dispatch(success(graph)),
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: graphSalesLeadsConstants.GET_REQUEST };
  }
  function success(payload) {
    return { type: graphSalesLeadsConstants.GET_SUCCESS, payload };
  }
  function failure() {
    return { type: graphSalesLeadsConstants.GET_FAILURE };
  }
}
