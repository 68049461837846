import { thirdpartytypeConstants } from '../_constants';

export function thirdpartytypes(state = {}, action) {
  switch (action.type) {
    case thirdpartytypeConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case thirdpartytypeConstants.GETALL_SUCCESS:
      return {
        items: action.result,
      };
    case thirdpartytypeConstants.GETALL_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
