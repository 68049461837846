import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Table, Button } from "react-bootstrap";
import clsx from "clsx";
import moment from "moment-timezone";

import { getTransactions } from "_helpers";
import { customerActions, alertActions } from "_actions";

import ConfirmationModal from "components/ConfirmationModal";
import AddPaymentMethodCard from "components/OrderDetails/AddPaymentMethodCard";

const TM_REFUND_FEE = 50;
const EIN_REFUND_FEE = 75;

function PaymentRefunds({ orderDetails }) {
  const dispatch = useDispatch();
  const { paymentMethods: rawPaymentMethods } = useSelector((state) => state.customers);

  const [currentData, setCurrentData] = useState(null);
  const [refundWithFee, setRefundWithFee] = useState(false);
  const [totalMap, setTotalMap] = useState({});
  const [refundedMap, setRefundedMap] = useState({});
  const [values, setValues] = useState({});
  const transactions = useMemo(() => getTransactions(orderDetails), [orderDetails]);
  const { paymentDetails } = orderDetails.order || {};
  const { id: customerId } = orderDetails.customer || {};

  const isTrademark = orderDetails?.products?.some(({ code }) => code?.category === "TRADEMARKS");
  const refundFee = isTrademark ? TM_REFUND_FEE : EIN_REFUND_FEE;

  const paymentMethods = useMemo(() => {
    const primaryPaymentMethod = rawPaymentMethods?.find(({ primary }) => primary);
    const restPaymentMethods = rawPaymentMethods?.filter(({ primary }) => !primary);

    if (primaryPaymentMethod) {
      return [primaryPaymentMethod, ...restPaymentMethods];
    }

    return [...restPaymentMethods];
  }, [rawPaymentMethods]);

  const handleRefreshOrderDetails = () => {
    orderDetails?.order?.id && dispatch(customerActions.getOrder(orderDetails.order.id));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const orderId = name.split(".")[1];

    const sanitizedValue = value.split("").reduce((str, char) => {
      if (char === "." && str.includes(char)) {
        return str;
      }

      return (str += char);
    }, "");

    setValues((prevValues) => ({ ...prevValues, [orderId]: sanitizedValue }));
  };

  const handleKeyPress = (e) => {
    if (!/^([0-9]{1,})?(\.)?([0-9]{1,})?$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleBlur = useCallback(
    (e) => {
      const { name, value } = e.target;
      const id = name.split(".")[1];
      const parsedValue = parseFloat(value);

      if (value === "." || value === "") {
        setValues((prevValues) => ({ ...prevValues, [id]: "0.00" }));
        return;
      }
      if (parsedValue > totalMap[id] - refundedMap[id]) {
        setValues((prevValues) => ({
          ...prevValues,
          [id]: (totalMap[id] - refundedMap[id]).toFixed(2),
        }));

        return;
      }

      if (!isNaN(parsedValue) && value !== parsedValue.toFixed(2)) {
        setValues((prevValues) => ({ ...prevValues, [id]: parsedValue.toFixed(2) }));
      }
    },
    [totalMap, refundedMap]
  );

  const handleRefund = useCallback(() => {
    if (currentData && currentData?.orderId && currentData?.productId) {
      let amount = null;
      if (refundWithFee) {
        amount = parseFloat(totalMap[currentData?.productId] - refundedMap[currentData?.productId] - refundFee);

        if (!isNaN(amount) && amount > 0) {
          dispatch(
            customerActions.refunds({
              orderId: currentData?.orderId,
              productId: currentData?.productId,
              amount: amount.toFixed(2),
              cb: handleRefreshOrderDetails,
            })
          );
        }
      } else {
        amount = parseFloat(values[currentData?.productId]);

        if (
          !isNaN(amount) &&
          amount <= parseFloat((totalMap[currentData?.productId] - refundedMap[currentData?.productId]).toFixed(2))
        ) {
          dispatch(
            customerActions.refunds({
              orderId: currentData?.orderId,
              productId: currentData?.productId,
              amount: amount.toFixed(2),
              cb: handleRefreshOrderDetails,
            })
          );
        }
      }
    }
  }, [dispatch, currentData, values, totalMap, refundedMap, refundWithFee]);

  const handleFullRefund = useCallback(() => {
    if (currentData && currentData?.orderId) {
      dispatch(customerActions.refunds({ orderId: currentData?.orderId, cb: handleRefreshOrderDetails }));
    }
  }, [dispatch, currentData]);

  const handleAddPaymentMethod = useCallback(
    (cardData, replaceCard) => {
      const data = { ...cardData, paySourceId: null };
      if (replaceCard)
        paymentMethods.forEach(({ primary, paySourceId }) => {
          if (primary) {
            data.paySourceId = paySourceId;
          }
        });

      dispatch(customerActions.addPaymentMethod(customerId, data));
    },
    [dispatch, customerId]
  );

  const getRefundData = useCallback(() => {
    if (currentData) {
      if (currentData.productId) {
        return currentData.productId;
      }
      return currentData.orderId;
    }
  }, [currentData]);

  useEffect(() => {
    if (transactions) {
      transactions.forEach(({ orderId, productId, total, refunded, onlyFullRefund }) => {
        if (productId) {
          setTotalMap((prevMap) => ({ ...prevMap, [productId]: total }));
          setRefundedMap((prevMap) => ({ ...prevMap, [productId]: refunded || 0 }));
        } else {
          setTotalMap((prevMap) => ({ ...prevMap, [orderId]: total }));
          setRefundedMap((prevMap) => ({ ...prevMap, [orderId]: refunded || 0 }));
        }

        if (onlyFullRefund) {
          setValues((prevValues) => ({ ...prevValues, [orderId]: (total - refunded).toFixed(2) }));
        }
      });
    }
  }, [transactions]);

  return (
    <div className="form-border">
      <br />
      <Table responsive>
        <thead>
          <tr>
            <th>Summary</th>
            <th>Payment Date</th>
            <th>Total ($)</th>
            <th>Refund</th>
            <th />
            <th />
            <th>Refunded ($)</th>
            <th>Refund Date</th>
            <th>Merchant Descriptor</th>
          </tr>
        </thead>
        <tbody>
          {!!transactions.length &&
            transactions.map((transaction, idx) => {
              const {
                orderId,
                productId,
                summary,
                isPackageSummary,
                onlyFullRefund,
                paymentDate,
                total,
                refunded,
                refundDate,
                refundWithFee,
                noRefund,
                merchantDescriptor,
              } = transaction;

              return (
                !(total === +0 && refunded === +0) && (
                  <tr
                    key={`${idx}-${orderId}-${productId ? productId : ""}`}
                    className={clsx({
                      ["refunds-summary-row"]: isPackageSummary,
                      ["refunds-not-allowed-row"]: noRefund,
                    })}
                  >
                    <td>{summary}</td>
                    <td>{paymentDate && moment.utc(paymentDate).local().format("YYYY/MM/DD")}</td>
                    <td>{total?.toFixed(2)}</td>
                    {onlyFullRefund ? (
                      <>
                        <td>
                          <Form.Control
                            size="sm"
                            type="text"
                            placeholder="0.00"
                            disabled
                            name={`refund.${orderId}`}
                            value={noRefund ? "" : (total - refunded).toFixed(2) || ""}
                          />
                        </td>
                        <td colSpan={2}>
                          <Button
                            className="w-100"
                            size="sm"
                            variant={noRefund ? "secondary" : "info"}
                            disabled={
                              noRefund ||
                              parseFloat((totalMap[orderId] - refundedMap[orderId]).toFixed(2)) < values[orderId]
                            }
                            onClick={() => setCurrentData(transaction)}
                          >
                            Full Refund
                          </Button>
                        </td>
                        <td>{refunded || refunded === 0 ? refunded?.toFixed(2) : ""}</td>
                      </>
                    ) : (
                      <>
                        <td>
                          <Form.Control
                            size="sm"
                            type="text"
                            placeholder="0.00"
                            disabled={noRefund}
                            name={`refund.${productId}`}
                            value={noRefund ? "" : values[productId] || ""}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            onBlur={handleBlur}
                            onFocus={(e) => {
                              e.preventDefault();
                              if (e.target.value === "0.00") {
                                handleChange({ target: { value: "", name: `refund.${productId}` } });
                              }
                            }}
                          />
                        </td>
                        <td>
                          <Button
                            size="sm"
                            variant={noRefund ? "secondary" : "info"}
                            disabled={
                              noRefund ||
                              !values[productId] ||
                              values[productId] <= 0 ||
                              parseFloat((totalMap[productId] - refundedMap[productId]).toFixed(2)) < values[productId]
                            }
                            onClick={() => setCurrentData(transaction)}
                          >
                            Refund
                          </Button>
                        </td>
                        <td>
                          {noRefund && <span>Not Allowed</span>}
                          {!noRefund && refundWithFee && (
                            <Button
                              size="sm"
                              variant="info"
                              className="refunds-with-fee-btn"
                              disabled={
                                parseFloat((totalMap[productId] - refundedMap[productId] - refundFee).toFixed(2)) <= 0
                              }
                              onClick={() => {
                                setCurrentData(transaction);
                                setRefundWithFee(refundWithFee);
                              }}
                            >
                              <span>Refund with</span>
                              <br />
                              <span>${refundFee} Fee</span>
                            </Button>
                          )}
                        </td>
                        <td>{refunded || refunded === 0 ? refunded?.toFixed(2) : ""}</td>
                      </>
                    )}
                    <td>{refundDate && moment.utc(refundDate).local().format("YYYY/MM/DD")}</td>
                    <td>{merchantDescriptor || (idx ? "N/A" : "")}</td>
                  </tr>
                )
              );
            })}

          {!transactions.length && (
            <tr>
              <td>No Transactions</td>
            </tr>
          )}
        </tbody>
      </Table>

      <hr />

      <Row className="refunds-payment-details-wrapper">
        <Col sm={12} md={4}>
          <div className="form-border refunds-payment-details">
            <h6 className="font-weight-bold">Payment Details</h6>

            {paymentDetails && Object.keys(paymentDetails).length && (
              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  First Name
                </Form.Label>
                <Form.Label column sm={7}>
                  {paymentDetails?.firstName}
                </Form.Label>
                <Form.Label column sm={5}>
                  Last Name
                </Form.Label>
                <Form.Label column sm={7}>
                  {paymentDetails?.lastName}
                </Form.Label>
                <Form.Label column sm={5}>
                  Card Last 4
                </Form.Label>
                <Form.Label column sm={7}>
                  {paymentDetails?.cardLast4}
                </Form.Label>
                <Form.Label column sm={5}>
                  Card First 6
                </Form.Label>
                <Form.Label column sm={7}>
                  {paymentDetails?.cardFirst6}
                </Form.Label>
                <Form.Label column sm={5}>
                  Exp Month
                </Form.Label>
                <Form.Label column sm={7}>
                  {paymentDetails?.expMonth}
                </Form.Label>
                <Form.Label column sm={5}>
                  Exp Year
                </Form.Label>
                <Form.Label column sm={7}>
                  {paymentDetails?.expYear}
                </Form.Label>
                <Form.Label column sm="12">
                  <strong>Billing Address:</strong>
                </Form.Label>
                <Form.Label column sm={5}>
                  Address1
                </Form.Label>
                <Form.Label column sm={7}>
                  {paymentDetails?.billingAddress?.address1}
                </Form.Label>
                <Form.Label column sm={5}>
                  Address2
                </Form.Label>
                <Form.Label column sm={7}>
                  {paymentDetails?.billingAddress?.address2}
                </Form.Label>
                <Form.Label column sm={5}>
                  City
                </Form.Label>
                <Form.Label column sm={7}>
                  {paymentDetails?.billingAddress?.city}
                </Form.Label>
                <Form.Label column sm={5}>
                  State
                </Form.Label>
                <Form.Label column sm={7}>
                  {paymentDetails?.billingAddress?.state}
                </Form.Label>
                <Form.Label column sm={5}>
                  Zip
                </Form.Label>
                <Form.Label column sm={7}>
                  {paymentDetails?.billingAddress?.zip}
                </Form.Label>
                <Form.Label column sm={5}>
                  County
                </Form.Label>
                <Form.Label column sm={7}>
                  {paymentDetails?.billingAddress?.county}
                </Form.Label>
              </Form.Group>
            )}

            {paymentDetails && Object.keys(paymentDetails).length && <span>There isn't any information.</span>}
          </div>
        </Col>

        <Col sm={12} md={4}>
          <AddPaymentMethodCard onConfirm={handleAddPaymentMethod} />
        </Col>

        <Col sm={12} md={4}>
          <div className="form-border refunds-payment-details">
            <h6 className="font-weight-bold">Payment Methods</h6>

            {!!paymentMethods.length &&
              paymentMethods.map(({ cardType, cardNumber, cardMonth, cardYear, primary }, idx) => (
                <div
                  key={`${cardType}-${cardNumber}-${cardMonth}-${cardYear}-${idx}`}
                  className="form-border refunds-payment-method-card"
                >
                  {primary && <span className="primary-payment-method-indicator" />}
                  <Row>
                    <Col sm={6}>
                      <strong>Card Type</strong>
                    </Col>
                    <Col sm={6}>{cardType}</Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <strong>Card Last 4</strong>
                    </Col>
                    <Col sm={6}>{cardNumber}</Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <strong>Exp. Date</strong>
                    </Col>
                    <Col sm={6}>
                      {cardMonth}/{cardYear}
                    </Col>
                  </Row>
                </div>
              ))}

            {!paymentMethods.length && <span>There isn't any information.</span>}
          </div>
        </Col>
      </Row>

      <ConfirmationModal
        show={Boolean(currentData)}
        handleClose={() => {
          setCurrentData(null);
          setRefundWithFee(false);
        }}
        handleConfirm={() => {
          try {
            if (!currentData) throw new Error("Current data should exist");

            if (currentData.onlyFullRefund) {
              handleFullRefund();
            } else {
              handleRefund();
            }
          } catch (e) {
            console.error(e.toString());
            dispatch(alertActions.error("Something went wrong, please reload page and try again"));
          }

          setCurrentData(null);
          setRefundWithFee(false);
        }}
        header={currentData && (currentData?.onlyFullRefund ? "Full refund" : "Partial refund")}
        body={
          <>
            <div>Are you sure you want to refund?</div>
            <div>
              <span>
                Refund:{" "}
                {refundWithFee
                  ? `$${totalMap[getRefundData()] - refundedMap[getRefundData()] - refundFee}`
                  : `$${values[getRefundData()]}`}
              </span>
              &nbsp;
              {refundWithFee && !refundedMap[getRefundData()] && (
                <span>
                  (${totalMap[getRefundData()]} - ${refundFee})
                </span>
              )}
              {refundWithFee && !!refundedMap[getRefundData()] && (
                <span>
                  (${totalMap[getRefundData()]} - ${refundedMap[getRefundData()]} - ${refundFee})
                </span>
              )}
            </div>
          </>
        }
      />
    </div>
  );
}

export default PaymentRefunds;
