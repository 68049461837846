import { config } from "_config";
import { authHeader, mappingQueryParams, handleResponse } from "_helpers";

export const reportsUsersPerformanceService = {
  getAll,
};

function getAll(params) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/reports/users-performance${mappingQueryParams(params)}`;

  return fetch(url, requestOptions).then(handleResponse);
}
