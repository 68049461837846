import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";

import { state_array } from "variables/Variables";

export const initOrganization = {
  usBased: true,
  name: "",
  type: "",
  stateOrCountryOfFormation: "",
  position: "",
};

function Organization({ hidden, organization: oldOrganization, onError, onChange }) {
  const [organization, setOrganization] = useState(initOrganization);
  const [stateFormation, setStateFormation] = useState(oldOrganization?.stateOrCountryOfFormation || "");

  const handleOrganizationChange = ({ target: { name, value } }) => {
    let exFields = { [name]: value };

    if (name === "suffixName" && value === "") exFields = { [name]: null };

    if (name === "usBased") {
      const isUSBased = value === "yes";

      if (isUSBased) {
        exFields = { [name]: isUSBased, stateOrCountryOfFormation: stateFormation };
      } else {
        setStateFormation(oldOrganization.stateOrCountryOfFormation);
        exFields = { [name]: isUSBased, stateOrCountryOfFormation: "Unated States" };
      }
    }

    setOrganization({ ...organization, ...exFields });
    onChange({ ...oldOrganization, ...exFields });
  };

  useEffect(() => {
    if (oldOrganization === true) {
      if (!organization) {
        setOrganization(initOrganization);
        onChange({ ...initOrganization });
      } else {
        onChange(organization);
      }
    } else if (oldOrganization !== null) {
      setOrganization(oldOrganization);
    }
  }, [oldOrganization]);

  if (hidden) return null;

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Formation
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="usBased_r_yes"
            className="mr-3"
            type="radio"
            label="US Based"
            name="usBased"
            value="yes"
            defaultChecked={oldOrganization.usBased}
            onClick={handleOrganizationChange}
          />
          <Form.Check
            id="usBased_r_no"
            type="radio"
            label="Non US Based"
            name="usBased"
            value="no"
            defaultChecked={!oldOrganization.usBased}
            onClick={handleOrganizationChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Organization Name
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="name"
            value={oldOrganization.name || ""}
            onChange={handleOrganizationChange}
            onInvalid={() => onError("Organization Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Organization Type
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="type"
            value={oldOrganization.type || ""}
            onChange={handleOrganizationChange}
            onInvalid={() => onError("Organization Type")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          State of Formation
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            as="select"
            size="sm"
            name="stateOrCountryOfFormation"
            disabled={!oldOrganization.usBased}
            value={oldOrganization.stateOrCountryOfFormation || ""}
            onChange={handleOrganizationChange}
            onInvalid={() => onError("State of Formation")}
          >
            <option value="">Select a state</option>
            {Object.keys(state_array).map((key) => (
              <option key={key} value={key}>
                {state_array[key]}
              </option>
            ))}
            {!oldOrganization.usBased && <option value="Unated States">Unated States</option>}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Position
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="position"
            value={oldOrganization.position || ""}
            onChange={handleOrganizationChange}
          />
        </Col>
      </Form.Group>
    </>
  );
}

export default Organization;
