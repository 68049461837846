import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { registration } from "./registration.reducer";
import { users } from "./users.reducer";
import { alert } from "./alert.reducer";
import { customers } from "./customers.reducer";
import { healthchecks } from "./healthcheck.reducer";
import { services } from "./services.reducer";
import { deployServices } from "./deployUI.reducer";
import { userlogs } from "./userlogs.reducer";
import { thirdpartytypes } from "./thirdpartytype.reducer";
import { thirdpartylogs } from "./thirdpartylogs.reducers";
import { consumerLogs } from "./consumerLogs.reducer";
import { summaryorders } from "./summaryorders.reducer";
import { primaryactivity } from "./primaryactivity.reducer";
import { orderFilters } from "./orderFilters.reducer";
import { tickets } from "./zendesk.reducer";
import { ticketfilter } from "./ticketfilter.reducer";
import { ads } from "./ads.reducer";
import { einoptions } from "./einoptions.reducer";
import { variantsOptions } from "./variantsOptions.reducer";
import { variants } from "./variants.reducer";
import { reports } from "./reports.reducer";
import { reportsClicks } from "./reportsClicks.reducer";
import { reportsUsersPerformance } from "./reportsUsersPerformance.reducer";
import { reportsProductsProcessing } from "./reportsProductsProcessing.reducer";
import { reportsEINPayment } from "./reportsEINPayment.reducer";
import { crmConfig } from "./crmConfig.reducer";
import { adsAccounts } from "./adsAccounts.reducer";
import { graphSalesLeads } from "./graphSalesLeads.reducer";
import { trademarkDashboard } from "./trademarkDashboard.reducer";
import offer from "./offer.reducer";
import offerGroup from "./offerGroup.reducer";
import { ui } from "./ui.reducer";
import { modals } from "./modals.reducer";

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  alert,
  customers,
  healthchecks,
  services,
  userlogs,
  thirdpartytypes,
  thirdpartylogs,
  consumerLogs,
  summaryorders,
  primaryactivity,
  summaryorders,
  orderFilters,
  tickets,
  ticketfilter,
  ads,
  einoptions,
  variantsOptions,
  variants,
  offer,
  offerGroup,
  deployServices,
  reports,
  reportsClicks,
  reportsProductsProcessing,
  reportsEINPayment,
  reportsUsersPerformance,
  crmConfig,
  adsAccounts,
  graphSalesLeads,
  trademarkDashboard,
  ui,
  modals,
});

export default (state, action) => rootReducer(action.type === "USERS_LOGOUT" ? undefined : state, action);
