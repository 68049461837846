import React from "react";
import { Row, Form } from "react-bootstrap";

import { checkIsStateIncorporationFee, currencyFormatter } from "_helpers";

import { state_array } from "variables/Variables";

function StateIncFee({ orderDetails: { products } }) {
  if (!products.length === 0) return null;

  return (
    <div className="order-info form-border">
      {products.map(
        (p, idx) =>
          checkIsStateIncorporationFee(p.code.code) && (
            <Form.Group as={Row} key={p?.crmProductId + idx}>
              <Form.Label column sm="3">
                State:
              </Form.Label>
              <Form.Label column sm="9">
                {state_array[p.state] || "—"}
              </Form.Label>
              <Form.Label column sm="3">
                Price:
              </Form.Label>
              <Form.Label column sm="9">
                {currencyFormatter(p.price) || "—"}
              </Form.Label>
            </Form.Group>
          )
      )}
    </div>
  );
}

export default StateIncFee;
