import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Table, Button, Form, Row, Col } from "react-bootstrap";

import { zendeskactions, alertActions } from "_actions";
import { config as initConfig } from "_config";
import { getZConfig } from "_helpers";

import LoadingSpinner from "components/Spinner";

import AddAccount from "./AddAccount";
import EditAccount from "./EditAccount";

function Credentials(props) {
  const dispatch = useDispatch();
  const config = { ...initConfig, ...getZConfig() };
  const zendesk_accounts = useSelector((state) => (state.tickets.accounts ? state.tickets.accounts : null));
  const [addmodalshow, setAddmodalshow] = useState(false);
  const [editmodalshow, setEditmodalshow] = useState(false);
  const [selectedaccount, setSelectedaccount] = useState(null);
  const [clientid, setClientid] = useState(config.zendesk_client_id || "");
  const [status, setStatus] = useState(true);

  const filter = (e) => {
    const new_status = !status;
    setStatus(new_status);
    dispatch(zendeskactions.get_zendesk_account(new_status));
  };

  const editaccount = (item) => {
    setEditmodalshow(true);
    setSelectedaccount(item);
  };

  const authorize_zendesk = (link, clientId) => {
    if (link && clientId) {
      localStorage.removeItem("zauth");
      localStorage.setItem("zconfig", JSON.stringify({ zendesk_url: link, zendesk_client_id: clientId }));

      const endpoint = link + "oauth/authorizations/new";
      const url_params =
        "?response_type=token" +
        "&redirect_uri=" +
        config.zendesk_redirect_uri +
        "&client_id=" +
        clientId +
        "&scope=" +
        encodeURIComponent("read write");
      window.location = endpoint + url_params;
    } else {
      dispatch(alertActions.error("Something went wrong..."));
    }
  };

  useEffect(() => {
    dispatch(zendeskactions.get_zendesk_account(status));
  }, []);

  return (
    <>
      <AddAccount show={addmodalshow} onHide={() => setAddmodalshow(false)} className="addnewaccount" />
      <EditAccount
        show={editmodalshow}
        status={status}
        accountdata={selectedaccount}
        onHide={() => setEditmodalshow(false)}
        className="addnewaccount"
      />

      <Container fluid className="main-content zendeskaccounts">
        <Row className="my-3">
          <Col md={3}>
            <Form.Control as="select" name="state" required size="sm" onChange={() => filter()}>
              <option>Active</option>
              <option>Disabled</option>
            </Form.Control>
          </Col>
          <Col md={9}>
            <Button size="sm" onClick={() => setAddmodalshow(true)} className="pull-right">
              Add new
            </Button>
          </Col>
        </Row>
        <Table striped bordered responsive size="sm">
          <thead>
            <tr>
              <th>Url</th>
              <th>login</th>
              <th>Keyword</th>
              <th>Status</th>
              <th>Edit</th>
              <th>
                <div className="d-flex align-items-center">
                  Client&nbsp;ID&nbsp;({clientid === config.zendesk_client_id ? "default" : "custom"})
                  <i
                    title="Reset to default"
                    className="fa fa-close"
                    onClick={() => setClientid(config.zendesk_client_id)}
                  />
                </div>
                <div style={{ maxWidth: "130px" }}>
                  <Form.Control
                    required
                    size="sm"
                    value={clientid}
                    onChange={({ target: { value } }) => {
                      setClientid(value.trim());
                    }}
                    isInvalid={!clientid.trim()}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {zendesk_accounts ? (
              <>
                {zendesk_accounts.map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      <tr>
                        <td>{item.url}</td>
                        <td>{item.login}</td>
                        <td>{item.keywords.join(", ")}</td>
                        <td>{item.status}</td>
                        <td>
                          <Button size="sm" onClick={() => editaccount(item)}>
                            Edit
                          </Button>
                        </td>
                        <td>
                          <Button size="sm" onClick={() => authorize_zendesk(item.url, clientid)}>
                            Authorize
                          </Button>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <tr>
                <td colSpan="5">
                  <LoadingSpinner></LoadingSpinner>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Container>
    </>
  );
}

export default Credentials;
