import { getHealthStatusesCount } from "_helpers/healthcheck";
import React from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const PillRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1px;
  height: 8px;
  width: 100%;

  ${({ empty }) =>
    empty &&
    css`
      background: var(--gray);
    `}

  ${({ loading }) =>
    loading &&
    css`
      background: var(--gray);
      background-size: 30px 30px;
      background-image: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
      );
      animation: animate-stripes 2.4s linear infinite;

      @keyframes animate-stripes {
        0% {
          background-position: 0 0;
        }

        100% {
          background-position: 60px 0;
        }
      }
    `}
`;

const Tag = styled.div`
  position: relative;
  flex: 1;
  color: white;
  font-size: 0.825rem;
  text-align: center;
  transition: all 0.3s;

  ${({ status }) => {
    switch (status) {
      case "green":
        return css`
          background-color: var(--success);
        `;

      case "yellow":
        return css`
          background-color: var(--warning);
        `;

      case "red":
        return css`
          background-color: var(--danger);
        `;

      default:
        return css`
          background-color: var(--light);
        `;
    }
  }}
`;

const TagContent = styled.div`
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translatex(-50%) translatey(-50%);

  font-weight: bold;
  font-size: 0.725rem;
  padding: 0px 3px;
  background-color: #000000bb;
  min-width: 1rem;
  border-radius: 4px;
`;

const HealthCheckTitle = ({ type, healthchecks, loading }) => {
  const { green, yellow, red } = getHealthStatusesCount(healthchecks);

  const hasGreen = green > 0;
  const hasYellow = yellow > 0;
  const hasRed = red > 0;
  const hasServices = hasGreen || hasYellow || hasRed;

  return (
    <Container>
      <div>HealthCheck {type}</div>
      <PillRow empty={!hasServices} loading={loading}>
        {hasGreen && (
          <Tag left right={!hasRed && !hasYellow} status="green">
            <TagContent>{green}</TagContent>
          </Tag>
        )}
        {hasYellow && (
          <Tag left={!hasGreen} right={!hasRed} status="yellow">
            <TagContent>{yellow}</TagContent>
          </Tag>
        )}
        {hasRed && (
          <Tag left={!hasGreen && !hasYellow} right={hasRed} status="red">
            <TagContent>{red}</TagContent>
          </Tag>
        )}
      </PillRow>
    </Container>
  );
};

export default HealthCheckTitle;
