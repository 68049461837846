import clsx from "clsx";
import { useState } from "react";
import { Row, Form, Card } from "react-bootstrap";
import styled from "styled-components";

import { state_array, suffixArray } from "variables/Variables";

import { phoneFormatter } from "_helpers";

const StyledCard = styled(Card)`
  padding-top: 8px;
  padding-bottom: 8px;

  .form-group:not(:last-child) {
    margin-bottom: 12px;
  }

  .form-label {
    display: flex;
    align-items: center;
    color: black;
    margin: 0;
    padding-top: 3px;
    padding-bottom: 3px;
    text-transform: unset;
    font-size: 0.875rem;
  }

  & > hr {
    margin: 1rem 0;
  }
`;

const InfoText = styled.span`
  color: gray;
`;

export default function CoOwners({ hidden, coOwners }) {
  const [collapsedRows, setCollapsedRows] = useState([]);

  if (hidden || !coOwners) return null;

  const handleCollapse = (rowId) => {
    if (collapsedRows.includes(rowId)) {
      setCollapsedRows(collapsedRows.filter((id) => rowId !== id));
    } else {
      setCollapsedRows([...collapsedRows, rowId]);
    }
  };

  const isRowCollapsed = (rowId) => {
    return collapsedRows.includes(rowId);
  };

  return (
    <>
      <hr />
      <h6 className="font-weight-bold">
        Co-Owners <InfoText>({coOwners?.length || "0"})</InfoText>
      </h6>

      {coOwners.length > 0 ? (
        coOwners.map((coOwner, idx) => (
          <StyledCard key={`coowners-card-${idx}`} className="mb-3 px-2">
            <Form.Group as={Row} className="mb-0">
              <Form.Label column xs={12} className="align-items-start" onClick={() => handleCollapse(idx)}>
                <i
                  className={clsx("fa", { "fa-caret-up": isRowCollapsed(idx), "fa-caret-down": !isRowCollapsed(idx) })}
                />
                &nbsp;
                <InfoText className="mr-2">#{idx + 1}</InfoText>&nbsp;
                {coOwner.firstName || coOwner.lastName ? (
                  <span>{`${coOwner.firstName} ${coOwner.lastName}`}</span>
                ) : (
                  <InfoText>{"<new co-owner>"}</InfoText>
                )}
              </Form.Label>
            </Form.Group>

            {isRowCollapsed(idx) && (
              <>
                <hr />
                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    First Name
                  </Form.Label>
                  <Form.Label column sm={7}>
                    {coOwner.firstName || "—"}
                  </Form.Label>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Middle Name
                  </Form.Label>
                  <Form.Label column sm={7}>
                    {coOwner.middleName || "—"}
                  </Form.Label>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Last Name
                  </Form.Label>
                  <Form.Label column sm={7}>
                    {coOwner.lastName || "—"}
                  </Form.Label>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Suffix Name
                  </Form.Label>
                  <Form.Label column sm={7}>
                    {suffixArray[coOwner.suffixName] || "—"}
                  </Form.Label>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Email
                  </Form.Label>
                  <Form.Label column sm={7}>
                    {coOwner.email || "—"}
                  </Form.Label>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Phone
                  </Form.Label>
                  <Form.Label column sm={7}>
                    {phoneFormatter(coOwner.phone) || "—"}
                  </Form.Label>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Address 1
                  </Form.Label>
                  <Form.Label column sm={7}>
                    {coOwner.address?.address1 || "—"}
                  </Form.Label>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Address 2
                  </Form.Label>
                  <Form.Label column sm={7}>
                    {coOwner.address?.address2 || "—"}
                  </Form.Label>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    City
                  </Form.Label>
                  <Form.Label column sm={7}>
                    {coOwner.address?.city || "—"}
                  </Form.Label>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    State
                  </Form.Label>
                  <Form.Label column sm={7}>
                    {state_array[coOwner.address?.state] || "—"}
                  </Form.Label>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    ZIP
                  </Form.Label>
                  <Form.Label column sm={7}>
                    {coOwner.address?.zip || "—"}
                  </Form.Label>
                </Form.Group>
              </>
            )}
          </StyledCard>
        ))
      ) : (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            No Co-Owners
          </Form.Label>
        </Form.Group>
      )}
    </>
  );
}
