import { Modal, Button } from "react-bootstrap";

export default function LeadWarningModal({ show, handleClose = () => {}, handleConfirm = () => {} }) {
  return (
    <Modal show={show} onHide={handleClose} size="md" centered>
      <Modal.Header closeButton>Warning</Modal.Header>
      <Modal.Body>
        <p>You are going to change processing status for unpaid product. Are you sure?”</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => handleConfirm()}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
