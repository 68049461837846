import groupBy from "lodash.groupby";
import orderBy from "lodash.orderby";
import moment from "moment-timezone";

export function transformEmailsLogs(logs) {
  const rawLogs = Object.keys(logs).map(timestamp => {
    return {
      ...logs[timestamp],
      timestampMs: moment.utc(timestamp).valueOf(),
      timestamp,
    }
  });

  const groupedEmails = groupBy(rawLogs, log => log.emailKey);

  const transformedEmails = Object.keys(groupedEmails).map(emailKey => {
    const orderedEmailsWithinGroup = orderBy([...groupedEmails[emailKey]], ['timestampMs'], ["asc"]);
    const { emailDescription, timestamp: sent, timestampMs: sentMs } = orderedEmailsWithinGroup[0] || {};
    const { timestamp: resent } = orderedEmailsWithinGroup[orderedEmailsWithinGroup.length - 1] || {};

    return {
      emailKey,
      emailDescription,
      sent,
      sentMs,
      resent: sent === resent ? null : resent,
    };
  });

  return orderBy(transformedEmails, ["sentMs"], ["desc"]);
}
