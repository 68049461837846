export const offerGroupConstants = {
  GET_OFFERGROUP_REQUEST: 'GET_OFFERGROUP_REQUEST',
  GET_OFFERGROUP_SUCCESS: 'GET_OFFERGROUP_SUCCESS',
  GET_OFFERGROUP_FAIL: 'GET_OFFERGROUP_FAIL',
  ADD_OFFERGROUP_REQUEST: 'ADD_OFFERGROUP_REQUEST',
  ADD_OFFERGROUP_SUCCESS: 'ADD_OFFERGROUP_SUCCESS',
  ADD_OFFERGROUP_FAIL: 'ADD_OFFERGROUP_FAIL',
  DELETE_OFFERGROUP_SUCCESS: 'DELETE_OFFERGROUP_SUCCESS',
  DELETE_OFFERGROUP_FAIL: 'DELETE_OFFERGROUP_FAIL',
};
