import React from "react";
import { Form, Row, Card } from "react-bootstrap";
import styled from "styled-components";

const StyledCard = styled(Card)`
  padding-top: 8px;
  padding-bottom: 8px;

  .form-group:not(:last-child) {
    margin-bottom: 12px;
  }

  .form-label,
  .form-check-label {
    display: flex;
    align-items: start;
    color: black;
    margin: 0;
    padding-top: 3px;
    padding-bottom: 3px;
    text-transform: unset;
    font-size: 0.875rem;
  }

  & > hr {
    margin: 1rem 0;
  }
`;

const FormTrademarkAssessment = ({ orderDetails, selectedProduct }) => {
  const productDetails = orderDetails.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">ASSESSMENT</h4>
      <hr />
      <h6 className="font-weight-bold">Findings</h6>

      <StyledCard className="mb-3 px-2">
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Trademark Assessment:
          </Form.Label>
          <Form.Label column sm={7}>
            {productDetails.trademarkAssessment ? "Yes" : "No"}
          </Form.Label>
        </Form.Group>
        {productDetails.trademarkAssessment && (
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Description:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.trademarkAssessmentDescription || "–"}
            </Form.Label>
          </Form.Group>
        )}
      </StyledCard>

      <StyledCard className="mb-3 px-2">
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Competitive Analysis:
          </Form.Label>
          <Form.Label column sm={7}>
            {productDetails.competitiveAnalysis ? "Yes" : "No"}
          </Form.Label>
        </Form.Group>
        {productDetails.competitiveAnalysis && (
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Description:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.competitiveAnalysisDescription || "–"}
            </Form.Label>
          </Form.Group>
        )}
      </StyledCard>

      <StyledCard className="mb-3 px-2">
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Strategic Guidance:
          </Form.Label>
          <Form.Label column sm={7}>
            {productDetails.strategicGuidance ? "Yes" : "No"}
          </Form.Label>
        </Form.Group>
        {productDetails.strategicGuidance && (
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Description:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.strategicGuidanceDescription || "–"}
            </Form.Label>
          </Form.Group>
        )}
      </StyledCard>
    </div>
  );
};

export default FormTrademarkAssessment;
