import React, { useState } from "react";
import styled, { css } from "styled-components";
import moment from "moment";

const lineColors = {
  success: "#18A12E",
  right: "#3AA2EB",
  left: "#F37217",
  regular: "#B8BBBE",
};

const LEFT_SIDE = ["TEAM_USER", "TEAM_ATTORNEY", "SYSTEM"];
const RIGHT_SIDE = ["CUSTOMER"];

const statusesInfoLocal = {
  FederalSearchResultsAvailable: {
    prefix: "Completed: ",
  },
  ReadyForSubmissionToTheUSPTO: {
    description:
      "Specialists are preparing the application for submission, next it will be sent to the Legal Team for review.",
    prefix: "Completed: ",
  },
  USPTOOfficeActionIssued: {
    prefix: "Completed: ",
  },
  SignatureReceived: {
    description:
      "Specialists are preparing the application for submission, next it will be sent to the Legal Team for review.",
    prefix: "Received: ",
  },
  Processing: {
    description: "Order received, preliminary review in process, search results being prepared.",
    prefix: "Received: ",
  },

  SignatureEmailSentToCustomer: {
    description: "Customer needs to sign the USPTO email  that was sent to them.",
    prefix: "Received: ",
  },
  SearchSentToCustForReview: {
    description: "Search results sent to customer, customer needs to review and confirm via email",
    prefix: "Completed: ",
  },
  PendingCustomerSOUOrEXT: {
    prefix: "",
  },
  AwaitingSpecimen: {
    description: "Waiting for customer to upload specimen, customer must do this through their user dashboard.",
    prefix: "Received: ",
  },
  SpecimenReceived: {
    prefix: "Received: ",
  },
  EditMade: {
    description: "Edit has been made",
    prefix: "",
  },
  Error: {
    description:
      "An error was found or the specialists needs verification, customer needs to check the Error email we sent, or login to their dashboard to resolve.",
    prefix: "Resolved: ",
  },

  PendingNoticeOfAllowance: {
    prefix: "Completed: ",
  },
  ApplicationAbandoned: {
    prefix: "Completed: ",
  },
  PendingRegistration: {
    prefix: "Completed: ",
  },
  PublishedToGazette: {
    prefix: "Completed: ",
  },
  SubmittedToUSPTO: {
    description: "Application was submitted to Trademark Office.  Approval time can take up to 6-8 months.",
    prefix: "Completed: ",
  },
  CardDeclined: {
    prefix: "Completed: ",
  },
  Cancelled: {
    prefix: "Completed: ",
  },
  Lead: {
    prefix: "Completed: ",
  },
  SignatureRemindersSent: {
    prefix: "Received: ",
  },
  Completed: {
    prefix: "Completed: ",
  },

  AttorneyReview: {
    description:
      "Legal Team is reviewing the application, customer will be notified if Legal Team finds any issues. Typical review time: 24-48 hrs",
    prefix: "Completed: ",
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 48px;
`;
const Headers = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 32px;
  max-width: 560px;
  width: 100%;
`;
const HeaderItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  line-height: 42px;
  font-weight: 700;
  color: #03589c;
  text-align: center;

  @media (max-width: 410px) {
    font-size: 14px;
  }
`;
const Body = styled.div`
  position: relative;
  min-height: 48px;
  max-width: 560px;
  width: 100%;
  margin-bottom: 4px;
`;
const TopBorder = styled.div`
  box-sizing: border-box;
  position: relative;
  height: 40px;
  width: 100%;
  border-top: 1px solid #03589c;

  ${({ left }) =>
    left &&
    css`
      width: calc(100% + 1px);
      border-right: 1px solid #03589c;
      border-top-right-radius: 48px;
      &::before {
        content: "";
        position: absolute;
        left: -3px;
        top: -4px;
        border: 1px solid #03589c;
        background-color: #03589c;
        border-radius: 50%;
        max-width: 7px;
        min-width: 7px;
        max-height: 7px;
        min-height: 7px;
      }
    `}

  ${({ right }) =>
    right &&
    css`
      border-left: 1px solid #03589c;
      border-top-left-radius: 48px;

      &::before {
        content: "";
        position: absolute;
        right: -3px;
        top: -4px;
        border: 1px solid #03589c;
        background-color: #03589c;
        border-radius: 50%;
        max-width: 7px;
        min-width: 7px;
        max-height: 7px;
        min-height: 7px;
      }
    `}
`;
const VerticalRow = styled.div`
  position: absolute;
  left: 50%;
  width: 1px;
  height: 100%;
  background-color: #03589c;

  &::after {
    content: "";
    position: absolute;
    bottom: 1px;
    right: -4px;
    width: 9px;
    height: 9px;
    border-right: 1px solid #03589c;
    border-bottom: 1px solid #03589c;
    transform: rotate(45deg);
  }
`;
const BranchContainer = styled.div`
  display: flex;
  width: 50%;
  margin-top: 24px;
  margin-bottom: 34px;

  ${({ side }) =>
    RIGHT_SIDE.includes(side) &&
    css`
      justify-content: end;
      margin-right: 0;
      margin-left: auto;
    `}

  ${({ side }) =>
    LEFT_SIDE.includes(side) &&
    css`
      justify-content: start;
      margin-right: auto;
      margin-left: 0;
    `}
`;
const BranchLine = styled.div`
  width: 100%;
  height: 1px;
  margin-top: auto;
  margin-bottom: auto;
  background-color: ${({ lineColor }) => lineColor};
`;

const TreeItemContainer = styled.div`
  position: relative;
`;
const TreeItemContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 160px;
  max-width: 200px;
  min-height: 42px;
  max-height: 42px;
  padding: 6px 20px;
  background-color: #ffffff;
  border: 2px solid ${({ lineColor }) => lineColor};
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  text-align: center;

  ${({ side }) =>
    RIGHT_SIDE.includes(side) &&
    css`
      &::before {
        content: "";
        position: absolute;
        left: -2px;
        top: calc(50% -3px);

        background-color: ${({ lineColor }) => lineColor};
        border-radius: 50%;
        max-width: 6px;
        min-width: 6px;
        max-height: 6px;
        min-height: 6px;
      }
    `}

  ${({ side }) =>
    LEFT_SIDE.includes(side) &&
    css`
      &::before {
        content: "";
        position: absolute;
        right: -2px;
        top: calc(50% -3px);

        background-color: ${({ lineColor }) => lineColor};
        border-radius: 50%;
        max-width: 6px;
        min-width: 6px;
        max-height: 6px;
        min-height: 6px;
      }
    `}

${({ next }) =>
    next &&
    css`
      background-color: #ececec;
      color: #828282;
    `}

  @media (max-width: 410px) {
    min-width: 120px;
    min-height: 60px;
    max-height: 60px;
  }
`;
const TreeItemDate = styled.div`
  position: absolute;
  top: 44px;
  min-width: 100%;
  color: #828282;
  text-align: center;
  font-size: 9px;

  @media (max-width: 410px) {
    top: 64px;
  }
`;
const StyledDescriptionIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 4px;
  right: 4px;
  width: 14px;
  height: 14px;
  font-size: 10px;
  color: #03589c;
  border: 1px solid #03589c;
  border-radius: 50%;
  cursor: pointer;
`;
const DescriptionBox = styled.div`
  position: absolute;
  top: 48px;
  right: 0;
  width: 0;
  height: 0;
  padding: 0;
  border: none;
  font-size: 0;
  opacity: 0%;
  z-index: 1;
  background-color: #ffffff;
  text-align: start;
  transition: opacity 0.3s;

  ${({ visible }) =>
    visible &&
    css`
      width: 160px;
      height: auto;
      padding: 8px;
      border: 1px solid #000000;
      background-color: #faf2dc;
      font-size: 10px;
      opacity: 100%;

      &::before {
        content: "";
        position: absolute;
        top: -7px;
        right: 6px;
        width: 12px;
        height: 12px;
        background-color: #faf2dc;
        border-top: 1px solid black;
        border-left: 1px solid black;
        transform: rotate(45deg);
      }

      @media (max-width: 767px) {
        top: 48px;
        right: 0;
        width: 160px;
      }

      @media (max-width: 410px) {
        top: 64px;
        width: 120px;
      }
    `}
`;

const DescriptionIcon = (props) => <StyledDescriptionIcon {...props}>?</StyledDescriptionIcon>;

const TreeItem = ({ title = "", date = "", prefix = "", description = "", lineColor, current, next, side }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <TreeItemContainer>
      <TreeItemContent current={current} next={next} side={side} lineColor={lineColor}>
        {title === "Error" ? "Error / Verification Required" : title}
      </TreeItemContent>
      <TreeItemDate>
        {prefix}
        {date}
        {current && "Current Step"}
        {next && "Next Step"}
      </TreeItemDate>
      <DescriptionIcon onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} />
      <DescriptionBox visible={showTooltip}>
        {current && <b>Current Status</b>}
        {next && <b>Next Status</b>}
        <div>{description}</div>
      </DescriptionBox>
    </TreeItemContainer>
  );
};

const Branch = ({ title, prefix, date, description, side, current, next }) => {
  let lineColor = lineColors.regular;
  if (current || next) {
    if (LEFT_SIDE.includes(side)) lineColor = lineColors.left;
    if (RIGHT_SIDE.includes(side)) lineColor = lineColors.right;
  }

  return (
    <BranchContainer side={side}>
      {RIGHT_SIDE.includes(side) && <BranchLine lineColor={lineColor} />}
      <TreeItem
        title={title}
        prefix={prefix}
        date={date}
        description={description}
        lineColor={lineColor}
        side={side}
        current={current}
        next={next}
      />
      {LEFT_SIDE.includes(side) && <BranchLine lineColor={lineColor} />}
    </BranchContainer>
  );
};

const Timeline = ({ orderDetails, selectedProduct, availableProcessingStatus, processingStatuses = [] }) => {
  const logOfStatuses = orderDetails?.products[selectedProduct]?.processingResult?.logOfStatuses || [];
  const { currentStatus } = availableProcessingStatus;
  const nextSuccessStatus = availableProcessingStatus.nextStatuses
    ? availableProcessingStatus.nextStatuses[0]
    : undefined;

  const statusesInfo = processingStatuses.reduce((obj, status) => {
    obj[status.code] = {
      statusCode: status.code,
      statusName: status.value,
      assigneeType: status.assigneeType,
      description: statusesInfoLocal[status.code]?.description || status.value,
      prefix: statusesInfoLocal[status.code]?.prefix || "Completed: ",
    };
    return obj;
  }, {});

  const isStatusesOnSameSide =
    (LEFT_SIDE.includes(currentStatus?.assigneeType) && LEFT_SIDE.includes(nextSuccessStatus?.assigneeType)) ||
    (RIGHT_SIDE.includes(currentStatus?.assigneeType) && RIGHT_SIDE.includes(nextSuccessStatus?.assigneeType));

  return (
    <Container className="form-border">
      <Headers>
        <HeaderItem>
          Trademark&nbsp;Genius
          <TopBorder left />
        </HeaderItem>
        <HeaderItem>
          Customer
          <TopBorder right />
        </HeaderItem>
      </Headers>
      <Body>
        <VerticalRow />
        {logOfStatuses
          .filter((status, idx) => idx !== logOfStatuses?.length - 1)
          .map(({ status, timestamp }) => {
            return (
              <Branch
                key={timestamp}
                title={statusesInfo[status]?.statusName || ""}
                prefix={statusesInfo[status]?.prefix}
                date={moment(timestamp).format("MM/DD/YYYY")}
                description={statusesInfo[status]?.description}
                side={statusesInfo[status]?.assigneeType}
              />
            );
          })}

        {currentStatus && currentStatus?.statusCode !== "Completed" && (
          <Branch
            title={currentStatus?.statusName}
            description={statusesInfo[currentStatus?.statusCode]?.description}
            side={currentStatus?.assigneeType}
            current
          />
        )}

        {nextSuccessStatus && !isStatusesOnSameSide && (
          <Branch
            title={nextSuccessStatus?.statusName}
            description={statusesInfo[nextSuccessStatus?.statusCode]?.description}
            side={nextSuccessStatus?.assigneeType}
            next
          />
        )}
      </Body>

      {currentStatus?.statusCode === "Completed" && (
        <TreeItem
          title="Trademark Approved"
          description="Application was approved by USPTO."
          lineColor={lineColors.success}
        />
      )}

      {Array.isArray(logOfStatuses) &&
        logOfStatuses.length === 0 &&
        availableProcessingStatus.nextStatuses?.length === 0 &&
        currentStatus.statusCode === null &&
        "Timeline is empty."}
    </Container>
  );
};

export default Timeline;
