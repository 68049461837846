import moment from "moment";
import { Form, Row } from "react-bootstrap";

export default function DateView({ label, date }) {
  const dateValue = date ? moment(date).format("MM/DD/YYYY") : "N/A";

  return (
    <Form.Group as={Row}>
      <Form.Label column sm={5}>
        {label}
      </Form.Label>
      <Form.Label column sm={7}>
        {dateValue}
      </Form.Label>
    </Form.Group>
  );
}
