import { thirdpartytypeConstants } from '../_constants';
import { thirdpartytypeService } from '../_services';
import { alertActions } from '.';

export const thirdpartytypeActions = {
  get,
};

function get() {
  return (dispatch) => {
    dispatch(request());

    thirdpartytypeService.get().then(
      (result) => dispatch(success(result)),
      (error) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return { type: thirdpartytypeConstants.GETALL_REQUEST };
  }
  function success(result) {
    return { type: thirdpartytypeConstants.GETALL_SUCCESS, result };
  }
  function failure(error) {
    return { type: thirdpartytypeConstants.GETALL_FAILURE, error };
  }
}
