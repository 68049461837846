import { adminService } from "_services";
import { adsConstants, einoptionConstants, reportsConstants, variantsOptionsConstants } from "_constants";
import { alertActions, variantsActions } from "./";

export const adminActions = {
  generatereport,
  adsreport,
  getEinOptions,
  saveoptions,
  getcampaigns,
  getsalesbydomain,
  getActionRequiredFields,
  createActionRequiredFields,
  getAddressesRA,
  putAddressRA,
};

function generatereport(startdate, enddate) {
  return (dispatch) => {
    adminService.generatereport(startdate, enddate).then(
      (result) => {},
      (error) => {}
    );
  };
}

function adsreport({ startDate, endDate, category, includeRecurring }) {
  return (dispatch) => {
    dispatch(request());

    adminService.adsreport({ startDate, endDate, category, includeRecurring }).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {}
    );
  };

  function request() {
    return { type: adsConstants.GET_ADS_REQUEST };
  }

  function success(result) {
    return { type: adsConstants.GET_ADS_SUCCESS, result };
  }
}

function getcampaigns() {
  return (dispatch) => {
    dispatch(request());

    adminService.getcampaigns().then(
      (campaigns) => {
        dispatch(success(campaigns));
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error?.toString() || "Something went wrong..."));
      }
    );
  };

  function request() {
    return { type: reportsConstants.GET_CAMPAIGNS_REQUEST };
  }

  function failure() {
    return { type: reportsConstants.GET_CAMPAIGNS_FAILURE };
  }

  function success(campaigns) {
    return { type: reportsConstants.GET_CAMPAIGNS_SUCCESS, campaigns };
  }
}

function getsalesbydomain(startdate, enddate, campaignId, includeTest) {
  return (dispatch) => {
    dispatch(request());
    adminService.getsalesbydomain(startdate, enddate, campaignId, includeTest).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error?.toString() || "Something went wrong..."));
      }
    );
  };

  function request() {
    return { type: reportsConstants.GET_SALES_BY_DOMAIN_REQUEST };
  }

  function failure() {
    return { type: reportsConstants.GET_SALES_BY_DOMAIN_FAILURE };
  }

  function success(sales) {
    return { type: reportsConstants.GET_SALES_BY_DOMAIN_SUCCESS, sales };
  }
}

function getEinOptions(category = "EIN") {
  return (dispatch) => {
    adminService.getEinOptions(category).then(
      (options) => {
        dispatch(success(options));
      },
      (error) => {
        dispatch(alertActions.error(error));
      }
    );
  };

  function success(options) {
    return { type: einoptionConstants.GET_OPTION_SUCCESS, options };
  }
}

function saveoptions(option) {
  return (dispatch) => {
    adminService.saveoptions(option).then(
      (result) => {
        dispatch(getEinOptions(option.category));
      },
      (error) => {
        dispatch(alertActions.error(error));
      }
    );
  };
}

function getActionRequiredFields(productCode) {
  return (dispatch) => {
    dispatch(request());

    adminService.getActionRequiredFields(productCode).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error?.message || error.toString() || "Something went wrong..."));
      }
    );
  };

  function request() {
    return { type: variantsOptionsConstants.GET_ACTIONS_REQUIRED_LIST_REQUEST };
  }

  function success(payload) {
    return { type: variantsOptionsConstants.GET_ACTIONS_REQUIRED_LIST_SUCCESS, payload };
  }

  function failure(payload) {
    return { type: variantsOptionsConstants.GET_ACTIONS_REQUIRED_LIST_FAILED, payload };
  }
}

function createActionRequiredFields(body, mode, category) {
  return (dispatch) => {
    dispatch(request());

    adminService.createActionRequiredFields(body).then(
      (result) => {
        dispatch(success(result));

        if (mode === "add") {
          dispatch(alertActions.success("Action added successfully"));
        }
        if (mode === "edit") {
          dispatch(alertActions.success("Action changed successfully "));
        }
        if (mode === "delete") {
          dispatch(alertActions.success("Action deleted successfully "));
        }

        dispatch(getActionRequiredFields(body.code));
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error?.message || error.toString() || "Something went wrong..."));
      }
    );
  };

  function request() {
    return { type: variantsOptionsConstants.POST_ACTION_REQUEST };
  }

  function success(payload) {
    return { type: variantsOptionsConstants.POST_ACTION_SUCCESS, payload };
  }

  function failure(payload) {
    return { type: variantsOptionsConstants.POST_ACTION_FAILED, payload };
  }
}

function getAddressesRA(state) {
  return (dispatch) => {
    dispatch(request());

    adminService.getAddressesRA(state).then(
      (result) => {
        dispatch(success(result));
      },
      (error) => {
        dispatch(failure(result));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: variantsOptionsConstants.GET_ADDRESSES_RA_REQUEST };
  }

  function success(payload) {
    return { type: variantsOptionsConstants.GET_ADDRESSES_RA_SUCCESS, payload };
  }

  function failure(payload) {
    return { type: variantsOptionsConstants.GET_ADDRESSES_RA_FAILED, payload };
  }
}

function putAddressRA(body, mode) {
  return (dispatch) => {
    dispatch(request());

    adminService.putAddressRA(body).then(
      (result) => {
        dispatch(success(result));

        if (mode === "add") {
          dispatch(alertActions.success("Address added successfully"));
        }
        if (mode === "edit") {
          dispatch(alertActions.success("Address changed successfully "));
        }

        dispatch(getAddressesRA());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: variantsOptionsConstants.PUT_ADDRESS_RA_REQUEST };
  }

  function success(payload) {
    return { type: variantsOptionsConstants.PUT_ADDRESS_RA_SUCCESS, payload };
  }

  function failure(payload) {
    return { type: variantsOptionsConstants.PUT_ADDRESS_RA_FAILED, payload };
  }
}
