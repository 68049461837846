import { Modal, Button } from "react-bootstrap";

export default function ReloadModal() {
  const handleReloadPage = () => window.location.reload();

  return (
    <Modal centered show onHide={handleReloadPage} size="md">
      <Modal.Header style={{ border: 0 }}></Modal.Header>
      <Modal.Body>
        The CRM version has been updated <br />
        You need to reload page to continue working with the site
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleReloadPage}>
          Reload
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
