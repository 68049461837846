import { Row, Form } from "react-bootstrap";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import ActivityView from "./Activity/View";
import AllQuestionView from "./GeneralQuestion/View/All";
import AltAddressView from "./AltAddress/View";
import CorpAddressView from "./CorpAddress/View";
import DateView from "./Date/View";

import StatusView from "../Status/View";

import { EIN_title_array } from "variables/Variables";

export default function FormPartnership({ orderDetails, selectedProduct }) {
  const productDetails = orderDetails.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">PARTNERSHIP</h4>

      <StatusView orderDetails={orderDetails} selectedProduct={selectedProduct} />

      <FormPDF productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">Legal information</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Name of the Partnership:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.legalName}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          DBA of the Partnership:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.dbaname}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Primary Partner information</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.primaryPartner.firstName}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Middle Name (optional):
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.primaryPartner.middleName}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Last Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.primaryPartner.lastName}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Suffix Name (optional):
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.primaryPartner.suffixName}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Title:
        </Form.Label>
        <Form.Label column sm={7}>
          {EIN_title_array[productDetails.primaryPartner.title]}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Social Security Number:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.primaryPartner.ssn}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Activity</h6>

      <ActivityView productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">General questions</h6>

      <AllQuestionView productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">Corporate Address (PO Boxes are not authorized)</h6>
      <CorpAddressView productDetails={productDetails} />

      <AltAddressView productDetails={productDetails} />

      <DateView productDetails={productDetails} />
    </div>
  );
}
