import { config } from "_config";
import { authHeader, mappingQueryParams, handleResponse } from "_helpers";

export const reportsEINPaymentService = {
  getAll,
  exportAll,
};

function getAll(params) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/reports/ein-payment${mappingQueryParams(params)}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function exportAll(params) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/reports/ein-payment/csv${mappingQueryParams(params)}`;

  return fetch(url, requestOptions).then(filehandle);
}

function filehandle(response) {
  response.blob().then((blob) => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    const file_name = "ein-payment-report.csv";
    link.setAttribute("download", file_name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  });

  return true;
}
