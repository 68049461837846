import { config } from "_config";
import { handleResponse } from "_helpers";

export const uiService = { getHash };

function getHash() {
  const requestOptions = {
    method: "GET",
    cache: "no-cache",
  };
  const url = `${config.publicUrl}/info.json`;

  return fetch(url, requestOptions).then(handleResponse);
}
