import React from "react";
import { Button, Spinner } from "react-bootstrap";

function Btn({ children, isLoading, disabled, ...props }) {
  return (
    <Button disabled={disabled || isLoading} {...props}>
      {Boolean(isLoading) && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
      &nbsp;{children}
    </Button>
  );
}

export default Btn;
