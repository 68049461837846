import { Row, Col, Form } from "react-bootstrap";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import IndividualEdit from "../Individual/Edit";
import NewAddressEdit from "../NewAddress/Edit";

import StatusEdit from "../../Status/Edit";

import { EXTENSION_REASON_OPTIONS, TAB_CODES } from "variables/Variables";

export default function FormTrademarkExtension({ orderDetails, selectedProduct, onError, onChange, ...props }) {
  const productDetails = orderDetails.products[selectedProduct];

  const handleAddError = (field) => onError(TAB_CODES.trademarkInfo, field);

  const handleProductChange = ({ target: { name, value } }) =>
    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, [name]: value } : product
      ),
    });

  const handleProductNewAddressChange = (value) => handleProductChange({ target: { name: "newAddress", value } });
  const handleProductTrademarkOwnerChange = (value) =>
    handleProductChange({ target: { name: "trademarkOwner", value } });
  const handleProductChangesToOwnerChange = (value) =>
    handleProductChange({ target: { name: "changesToOwner", value } });
  const handleProductOwnerChange = (value) => handleProductChange({ target: { name: "owner", value } });

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">EXTENSION TRADEMARK</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
      />

      <FormPDF productDetails={productDetails} {...props} />

      <hr />
      <h6 className="font-weight-bold">Extension Reason</h6>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Extension Reason:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            as="select"
            size="sm"
            name="extensionReason"
            value={productDetails.extensionReason || ""}
            onChange={handleProductChange}
          >
            <option value={""}>Select Reason...</option>
            {EXTENSION_REASON_OPTIONS.map(({ value, title }, idx) => (
              <option key={`reason-${value}-${idx}`} value={value}>
                {title}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      {productDetails.extensionReason === "Other" && (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Other Extenstion Reason:
          </Form.Label>
          <Col sm={7}>
            <Form.Control
              size="sm"
              type="text"
              name="extensionReasonOther"
              value={productDetails.extensionReasonOther || ""}
              onChange={handleProductChange}
            />
          </Col>
        </Form.Group>
      )}

      <hr />
      <h6 className="font-weight-bold">Mark Information</h6>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Serial Number:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="serialNumber"
            value={productDetails.serialNumber || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Serial Number")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Trademark Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="trademarkName"
            value={productDetails.trademarkName || ""}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <hr />
      <IndividualEdit
        label="Trademark Owner"
        individual={productDetails.trademarkOwner}
        onError={handleAddError}
        onChange={handleProductTrademarkOwnerChange}
      />

      <hr />
      <NewAddressEdit
        label="New Address"
        newAddress={productDetails.newAddress}
        onError={handleAddError}
        onChange={handleProductNewAddressChange}
      />

      <hr />

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Changes to drawing:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="changesToDrawing"
            value={productDetails.changesToDrawing || ""}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <hr />
      {/* <h6 className="font-weight-bold">Trademark Owner</h6> */}
      <IndividualEdit
        label="Change to Owner"
        individual={productDetails.changesToOwner}
        onError={handleAddError}
        onChange={handleProductChangesToOwnerChange}
      />

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Missed goods or services:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="missedGoodsOrServices"
            value={productDetails.missedGoodsOrServices || ""}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Times deadline was extended:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="number"
            name="timesDeadlineWasExtended"
            value={productDetails.timesDeadlineWasExtended || ""}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <hr />
      {/* <h6 className="font-weight-bold">Changes to Owner</h6> */}
      <IndividualEdit
        label="Changes to Owner"
        individual={productDetails.owner}
        onError={handleAddError}
        onChange={handleProductOwnerChange}
      />
    </div>
  );
}
