import { useEffect, useState } from "react";
import { Row, Col, Form, Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";

import { consumerLogsActions } from "_actions";

import Spinner from "components/Spinner";
import Button from "components/Button";

function TabConsumerLog() {
  const dispatch = useDispatch();
  const consumerTypes = useSelector(({ consumerLogs: { types } }) => types || []);
  const consumerLogs = useSelector(({ consumerLogs: { logs } }) => logs || null);
  const loading = useSelector(({ consumerLogs: { loading } }) => loading);

  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [logType, setLogType] = useState("EMAIL");
  const [body, setBody] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(consumerLogsActions.get(startDate, endDate, logType, body, error === "" ? null : error));
  };

  useEffect(() => {
    dispatch(consumerLogsActions.getTypes());
  }, []);

  return (
    <div className="form-border bg-white fixed-height consumer">
      <Row>
        <Col md={12}>
          <Card className="p-3">
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Start Date"
                    name="startDate"
                    size="sm"
                    value={startDate}
                    max={endDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="End Date"
                    name="endDate"
                    size="sm"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    as="select"
                    size="sm"
                    value={logType || ""}
                    onChange={(e) => setLogType(e.target.value)}
                  >
                    {consumerTypes.map((item, key) => (
                      <option value={item} key={key}>
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Form.Label>Body</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Body"
                    size="sm"
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Form.Label>Error</Form.Label>
                  <Form.Control as="select" size="sm" value={error} onChange={(e) => setError(e.target.value)}>
                    <option value="">All</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group
                  as={Col}
                  className="d-flex align-items-sm-end justify-content-sm-end"
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                >
                  <Button type="submit" size="sm" isLoading={loading}>
                    Search
                  </Button>
                </Form.Group>
              </Form.Row>
            </Form>
          </Card>

          {consumerLogs && (
            <Card className="mb-1 full-height">
              <Card.Title className="m-0 p-2">Consumer Logs Information</Card.Title>
              <Table striped bordered responsive size="sm" className="thirdpartylogstable">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Duration</th>
                    <th>Error</th>
                    <th>Body</th>
                  </tr>
                </thead>
                <tbody>
                  {consumerLogs.map((prop, key) => (
                    <tr key={key}>
                      <td>{moment(prop.created).format("YYYY/MM/DD HH:mm:ss")}</td>
                      <td>{prop.messageType}</td>
                      <td>{prop.time}</td>
                      <td>{prop.error}</td>
                      <td>{prop.body}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          )}

          {!!loading && <Spinner />}
        </Col>
      </Row>
    </div>
  );
}

export default TabConsumerLog;
