
import { offerConstants } from '../_constants';
import { offerService } from '../_services';


function getOfferReports(searchOptions) {
  return (dispatch) => {
    dispatch(request());

    offerService.getOfferReports(searchOptions).then(
      (result) => dispatch(success(result)),
      (error) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return { type: offerConstants.GET_OFFER_CLICKS_REQUEST };
  }
  function success(offers) {
    return { type: offerConstants.GET_OFFER_CLICKS_SUCCESS, offers };
  }
  function failure(message) {
    return { type: offerConstants.GET_OFFER_CLICKS_FAIL, message };
  }
}

function getOffers(groupId) {
  return (dispatch) => {
    dispatch(request());

    offerService.getOffers(groupId).then(
      (result) => dispatch(success(result)),
      (error) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return { type: offerConstants.GET_OFFER_REQUEST };
  }
  function success(offers) {
    return { type: offerConstants.GET_OFFER_SUCCESS, offers };
  }
  function failure(message) {
    return { type: offerConstants.GET_OFFER_FAIL, message };
  }
}

function addOffer(form, uid) {
  return (dispatch) => {
    return offerService.addOffer(form, uid).then(
      (result) => dispatch(success(result)),
      (error) => {
        dispatch(failure(error.message));
        return Promise.reject(error); 
      },
    );
  };

  function success() {
    return { type: offerConstants.ADD_OFFER_SUCCESS };
  }
  function failure(message) {
    return { type: offerConstants.ADD_OFFER_FAIL, message };
  }
}

function editOffer(form, uid) {
  return (dispatch) => {
    return offerService.editOffer(form, uid).then(
      (result) => dispatch(success(result)),
      (error) => {
        dispatch(failure(error.message));
        return Promise.reject(error); 
      },
    );
  };

  function success() {
    return { type: offerConstants.ADD_OFFER_SUCCESS };
  }
  function failure(message) {
    return { type: offerConstants.ADD_OFFER_FAIL, message };
  }
}

function deleteOffer(id, groupId) {
  return (dispatch) => {
    return offerService.deleteOffer(id, groupId).then(
      () => Promise.resolve(true),
      (error) => {
        return Promise.reject(error); 
      },
    );
  };
}

function resetOfferState() {
  return { type: offerConstants.RESET_OFFER_STATE };
}
export const offerActions = {
  getOfferReports,
  resetOfferState,
  getOffers,
  addOffer,
  editOffer,
  deleteOffer
};