import { Modal, Button } from "react-bootstrap";

export default function ChangeEmailConfirmationModal({ show, handleClose = () => {}, handleConfirm = () => {} }) {
  return (
    <Modal show={show} onHide={handleClose} size="md" centered>
      <Modal.Header closeButton style={{ border: 0 }}>
        <h3>
          <b>Confirm Action</b>
        </h3>
      </Modal.Header>
      <Modal.Body>
        Other customer exists with this email - to replace email the orders will be linked to it - please make sure the
        customers are the same and intended to be linked. Are you sure you would like to continue?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          No
        </Button>
        <Button variant="danger" onClick={handleConfirm}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
