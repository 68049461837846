import { config } from "_config";
import { authHeader, handleResponse } from "_helpers";

export const thirdpartylogsService = {
  get,
  exportcsv,
};

function get(start_date, end_date, log_type, obj_id, req_string) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = `${config.healthcheckurl}/healthcheck/third-party-system-request-logs?from=${start_date}&to=${end_date}`;
  if (log_type !== "") {
    url += `&type=${log_type}`;
  }
  if (obj_id !== "") {
    url += `&linkedObjectId=${obj_id}`;
  }
  if (req_string !== "") {
    url += `&request=${req_string}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function exportcsv(start_date, end_date, log_type, obj_id, req_string) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = `${config.healthcheckurl}/healthcheck/third-party-system-request-logs/csv?from=${start_date}&to=${end_date}`;
  if (log_type !== "") {
    url += `&type=${log_type}`;
  }
  if (obj_id !== "") {
    url += `&linkedObjectId=${obj_id}`;
  }
  if (req_string !== "") {
    url += `&request=${req_string}`;
  }
  return fetch(url, requestOptions).then(filehandle);
}

function filehandle(response) {
  response.blob().then((blob) => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    const file_name = "thirdpartylogs.csv";
    link.setAttribute("download", file_name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  });
  return true;
}
