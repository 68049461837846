import { Modal, Button } from "react-bootstrap";

export default function RefreshModal() {
  const handleReloadPage = () => window.location.replace(`${window.location.origin}${window.location.pathname}`);

  return (
    <Modal centered show onHide={handleReloadPage} size="md">
      <Modal.Header style={{ border: 0 }}></Modal.Header>
      <Modal.Body>
        The data you are trying to send is outdated<br />
        Please, reload the page and try again
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleReloadPage}>
          Reload
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
