import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Container, Row, Col, Card, Pagination, Form } from "react-bootstrap";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import styled, { css } from "styled-components";

import { userActions, alertActions, variantsActions } from "_actions";
import { getIndicator } from "_helpers";

import { ReactComponent as EmailSVG } from "assets/img/Email.svg";
import { ReactComponent as EmailTemplateSVG } from "assets/img/EmailTemplate.svg";
import { ReactComponent as PhoneSVG } from "assets/img/Phone.svg";

import Spinner from "components/Spinner";
import Button from "components/Button";

const ColorIndicator = styled.td`
  padding: 0 !important;

  ${({ status }) => {
    switch (status) {
      case "disabled":
        return css`
          background-color: #bcbcbc;
        `;
      case "account-manager":
        return css`
          background-color: #a5d6a7;
        `;
      case "user":
        return css`
          background-color: #b3e5fc;
        `;
      case "legal-team":
        return css`
          background-color: #b39ddb;
        `;
      case "admin":
        return css`
          background-color: #ef9a9a;
        `;
      case "developer":
        return css`
          background-color: #ffe082;
        `;
      default:
        return css`
          background-color: #ffffff;
        `;
    }
  }}
`;

const ROLES = {
  USER: "User",
  ADMIN: "Admin",
  ATTORNEY: "Legal Team",
  DEVELOPER: "Developer",
};

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pagecount: 25,
      page: 1,
      editing: false,
      name: "",
      email: "",
      username: "",
      password: "",
      status: "Active",
      role: "USER",
      categories_list: [],
      phone: "",
      accountManager: false,
      searchByName: "",
      searchByRole: "",
      includeDisabled: "",
      welcomeEmailTemplate: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.saveuser = this.saveuser.bind(this);
    this.pageChanged = this.pageChanged.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.firstPage = this.firstPage.bind(this);
    this.lastPage = this.lastPage.bind(this);
    this.getDropdownButtonLabel = this.getDropdownButtonLabel.bind(this);
    this.onchange = this.onchange.bind(this);
  }

  componentDidMount() {
    const { pagecount, page } = this.state;

    this.props.getAll({ page, count: pagecount });
    this.props.getvariants();
  }

  onchange(value, event) {
    this.state = {
      ...this.state,
      ...{
        [event.name]: value,
      },
    };
    this.setState(this.state);
  }

  getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  handleChange(e) {
    const { name, value, checked, type } = e.target;

    if (type === "checkbox") {
      this.setState({ [name]: checked });
    } else {
      this.setState({ [name]: value });
    }
  }

  canceledit() {
    this.setState({
      id: "",
      editing: false,
      name: "",
      email: "",
      username: "",
      password: "",
      status: "Active",
      role: "USER",
      categories_list: [],
      phone: "",
      accountManager: false,
      welcomeEmailTemplate: "",
    });
  }

  edituser(user) {
    const categories = user.availableCategories;
    let categories_list = [];
    for (let i = 0; i < categories.length; i++) {
      categories_list.push({
        label: categories[i],
        value: categories[i],
      });
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    this.setState({
      editing: true,
      id: user.id,
      name: user.name,
      email: user.email,
      username: user.username,
      password: "",
      status: user.status,
      role: user.role,
      categories_list: categories_list,
      phone: user.phone || "",
      accountManager: user.accountManager || false,
      welcomeEmailTemplate: user.welcomeEmailTemplate || "",
    });
  }

  deleteuser(user) {
    this.props.deleteuser(user);
  }

  saveuser(e) {
    e.preventDefault();
    let userdata = {
      ...this.state,
    };

    let availableCategories = userdata.categories_list.map((a) => a.value);
    userdata = { ...userdata, ...{ availableCategories } };

    if (availableCategories.length === 0) {
      this.props.showAlerts("At least one Category should be selected");
      return false;
    }

    this.setState({
      editing: false,
      id: "",
      name: "",
      email: "",
      username: "",
      password: "",
      status: "Active",
      categories_list: [],
      role: "USER",
      phone: "",
      accountManager: false,
      welcomeEmailTemplate: "",
    });

    if (userdata.editing) {
      this.props.updateuser(userdata);
    } else {
      this.props.saveuser(userdata);
    }
  }

  pageChanged(e) {
    this.setState({ page: Number(e.target.text) });
    this.props.getAll({
      page: Number(e.target.text),
      count: this.state.pagecount,
      search: this.state.searchByName,
      role: this.state.searchByRole,
      includeDisabled: this.state.includeDisabled,
    });
  }

  prevPage(e) {
    this.setState({ page: this.state.page - 1 });
    this.props.getAll({
      page: this.state.page - 1,
      count: this.state.pagecount,
      search: this.state.searchByName,
      role: this.state.searchByRole,
      includeDisabled: this.state.includeDisabled,
    });
  }

  nextPage(e) {
    this.setState({ page: this.state.page + 1 });
    this.props.getAll({
      page: this.state.page + 1,
      count: this.state.pagecount,
      search: this.state.searchByName,
      role: this.state.searchByRole,
      includeDisabled: this.state.includeDisabled,
    });
  }

  firstPage(e) {
    this.setState({ page: 1 });
    this.props.getAll({
      page: 1,
      count: this.state.pagecount,
      search: this.state.searchByName,
      role: this.state.searchByRole,
      includeDisabled: this.state.includeDisabled,
    });
  }

  lastPage(e) {
    this.setState({ page: this.props.users.items.totalPages });
    this.props.getAll({
      page: this.props.users.items.totalPages,
      count: this.state.pagecount,
      search: this.state.searchByName,
      role: this.state.searchByRole,
      includeDisabled: this.state.includeDisabled,
    });
  }

  filter(e) {
    this.setState({
      page: 1,
      id: "",
      editing: false,
      name: "",
      email: "",
      username: "",
      password: "",
      status: "Active",
      role: "USER",
      categories_list: [],
      phone: "",
      accountManager: false,
    });

    this.props.getAll({
      page: 1,
      count: this.state.pagecount,
      search: this.state.searchByName,
      role: this.state.searchByRole,
      includeDisabled: this.state.includeDisabled,
    });
  }

  render() {
    const {
      name,
      username,
      password,
      role,
      status,
      email,
      categories_list,
      phone,
      accountManager,
      welcomeEmailTemplate,
    } = this.state;
    const { users, variants } = this.props;

    const categories = variants?.categories?.values || [];

    let categories_array = [];
    for (let i = 0; i < categories.length; i++) {
      categories_array.push({
        label: categories[i].value,
        value: categories[i].code,
      });
    }

    const items = [];
    let userdata = [];
    let totalpages = 1;
    let totalresults = 0;
    let currentpage = 1;

    if (users.items && users.items.elements) {
      userdata = users.items.elements;
      totalpages = users.items.totalPages;
      currentpage = users.items.page;
      totalresults = users.items.totalResults;

      for (let i = 1; i <= totalpages; i++) {
        items.push(
          <Pagination.Item key={i} active={currentpage === i} onClick={currentpage != i ? this.pageChanged : null}>
            {i}
          </Pagination.Item>
        );
      }
    }

    return (
      <Container fluid className="py-5 my-3">
        <Row className="mt-3">
          <Col xs={12} sm={12} md={3} lg={3} xl={3}>
            <Card className="px-2 pb-2">
              <Form onSubmit={this.saveuser} autoComplete="off">
                {this.state.editing ? <h3>Edit User</h3> : <h3>Add New User</h3>}

                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    size="sm"
                    placeholder="Name"
                    name="name"
                    value={name}
                    autoComplete="new-name"
                    onChange={this.handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    size="sm"
                    name="email"
                    value={email}
                    autoComplete="new-email"
                    onChange={this.handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="Username">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Username"
                    size="sm"
                    name="username"
                    value={username}
                    autoComplete="new-username"
                    onChange={this.handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="Password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    size="sm"
                    name="password"
                    value={password}
                    autoComplete="new-password"
                    onChange={this.handleChange}
                    required={!this.state.editing}
                  />
                </Form.Group>
                <Form.Group controlId="status">
                  <Form.Label>Status</Form.Label>
                  <Form.Control as="select" name="status" size="sm" value={status} onChange={this.handleChange}>
                    <option value="Active">Active</option>
                    <option value="Disabled">Disabled</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="role">
                  <Form.Label>Categories</Form.Label>

                  <ReactMultiSelectCheckboxes
                    placeholderButtonLabel="Categories"
                    name="categories_list"
                    value={categories_list}
                    options={categories_array}
                    onChange={this.onchange}
                    getDropdownButtonLabel={this.getDropdownButtonLabel}
                    className="reactMultiSelectCheckboxes"
                  />
                </Form.Group>

                <Form.Group controlId="Category">
                  <Form.Label>Role</Form.Label>
                  <Form.Control as="select" name="role" size="sm" value={role} onChange={this.handleChange}>
                    <option value="USER">{ROLES["USER"]}</option>
                    <option value="ADMIN">{ROLES["ADMIN"]}</option>
                    <option value="ATTORNEY">{ROLES["ATTORNEY"]}</option>
                    {role === "DEVELOPER" && <option value="DEVELOPER">{ROLES["DEVELOPER"]}</option>}
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="phone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="tel"
                    size="sm"
                    placeholder="Phone"
                    name="phone"
                    value={phone}
                    autoComplete="new-phone"
                    onChange={this.handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="accountManager">
                  <Form.Check
                    id="accountManager"
                    className="text-dark"
                    type="checkbox"
                    label="Account Manager"
                    name="accountManager"
                    checked={accountManager}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                {accountManager && (
                  <Form.Group controlId="welcomeEmailTemplate">
                    <Form.Label>Welcome Email Template</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Welcome Email Template"
                      size="sm"
                      name="welcomeEmailTemplate"
                      value={welcomeEmailTemplate}
                      autoComplete="new-welcome-email-template"
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                )}

                {this.state.editing ? (
                  <div>
                    <Button variant="success" type="submit" className="btn-sm pull-right ml-1">
                      Update
                    </Button>
                    <Button
                      variant="primary"
                      type="button"
                      className="btn-sm pull-right"
                      onClick={() => this.canceledit()}
                    >
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <Button variant="success" type="submit" className="btn-sm pull-right">
                    Save
                  </Button>
                )}
              </Form>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={9} lg={9} xl={9}>
            <Card>
              <h3 className="ml-2">All Users</h3>
              <Form
                className="p-2"
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.filter();
                }}
              >
                <Form.Row>
                  <Form.Group md={3} sm={12} as={Col}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      size="sm"
                      name="searchByName"
                      value={this.state.searchByName}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                  <Form.Group md={3} sm={12} as={Col}>
                    <Form.Label>Role</Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      name="searchByRole"
                      value={this.state.searchByRole}
                      onChange={this.handleChange}
                    >
                      <option value="">All</option>
                      <option value="USER">{ROLES["USER"]}</option>
                      <option value="ATTORNEY">{ROLES["ATTORNEY"]}</option>
                      <option value="ADMIN">{ROLES["ADMIN"]}</option>
                      <option value="DEVELOPER">{ROLES["DEVELOPER"]}</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group md={3} sm={12} as={Col}>
                    <Form.Label>Show Disabled</Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      name="includeDisabled"
                      value={this.state.includeDisabled}
                      onChange={this.handleChange}
                    >
                      <option value="">No</option>
                      <option value="true">Yes</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group
                    className="d-flex align-items-end justify-content-md-start justify-content-end"
                    as={Col}
                    sm={12}
                    md={3}
                  >
                    <Button variant="primary" type="submit" className="btn-sm" isLoading={users.loading}>
                      Filter
                    </Button>
                  </Form.Group>
                </Form.Row>
              </Form>
              <Table responsive bordered striped hover>
                <thead>
                  <tr>
                    <th className="px-1">#</th>
                    <th>Name</th>
                    <th>User Info</th>
                    <th>Username</th>
                    <th>status</th>
                    <th>Categories</th>
                    <th>Role</th>
                    <th style={{ minWidth: "175px" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {userdata.map((prop, key) => {
                    return (
                      <tr key={key}>
                        <ColorIndicator size="12px" status={getIndicator(prop)} title={getIndicator(prop)} />
                        <td>{prop.name}</td>
                        <td style={{ maxWidth: "375px" }}>
                          <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                            <div title="E-mail">
                              <EmailSVG width="18px" height="18px" />
                            </div>
                            <div>{prop.email ? prop.email : <span>&#8212;</span>}</div>
                          </div>
                          <div style={{ borderTop: "1px dashed #afafaf" }} />
                          <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                            <div title="Welcome E-mail Template">
                              <EmailTemplateSVG width="18px" height="18px" />
                            </div>
                            <div style={{ whiteSpace: "normal", wordBreak: "break-all" }}>
                              {prop.welcomeEmailTemplate ? prop.welcomeEmailTemplate : <span>&#8212;</span>}
                            </div>
                          </div>
                          <div style={{ borderTop: "1px dashed #afafaf" }} />
                          <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                            <div title="Phone">
                              <PhoneSVG width="18px" height="18px" />
                            </div>
                            <div>{prop.phone ? prop.phone : <span>&#8212;</span>}</div>
                          </div>
                        </td>
                        <td>{prop.username}</td>
                        <td>{prop.status}</td>
                        <td>{prop.availableCategories.join(", ")}</td>
                        <td>{ROLES[prop.role]}</td>
                        <td>
                          <Button variant="warning" className="btn btn-sm mr-2" onClick={() => this.edituser(prop)}>
                            Edit <i className="fa fa-edit"></i>{" "}
                          </Button>
                          <Button variant="danger" className="btn btn-sm" onClick={() => this.deleteuser(prop)}>
                            Delete <i className="fa fa-trash"></i>{" "}
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

              {totalresults > 0 && (
                <Pagination className="ml-2">
                  <Pagination.First disabled={currentpage === 1} onClick={this.firstPage} />
                  <Pagination.Prev disabled={currentpage === 1} onClick={this.prevPage} />
                  {items}
                  <Pagination.Next disabled={currentpage === totalpages} onClick={this.nextPage} />
                  <Pagination.Last disabled={currentpage === totalpages} onClick={this.lastPage} />
                </Pagination>
              )}

              {!users.loading && totalresults === 0 && <div className="ml-2 mb-2">No results</div>}
              {users.loading && <Spinner />}
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

function mapState(state) {
  const { users, variants } = state;
  return { users, variants };
}

const actionCreators = {
  getAll: userActions.getAll,
  saveuser: userActions.saveuser,
  deleteuser: userActions.delete,
  updateuser: userActions.updateuser,
  showAlerts: alertActions.error,
  getvariants: variantsActions.getProductCategories,
};

const connectedUsersPage = connect(mapState, actionCreators)(Users);

export { connectedUsersPage as Users };
