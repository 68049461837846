import { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

export const initMailgun = {
  name: "",
  secretKey: "",
  domain: "",
  leadDiscountTemplate: "",
  from: "",
  leadDiscountSubject: "",
  paymentConfirmationTemplate: "",
  paymentConfirmationSubject: "",
  processingResultTemplate: "",
  processingResultSubject: "",
  supportEmail: "",
  statusUrl: "",
  baseSalePageUrl: "",
  membershipUrl: "",
  supportPhone: "",
};

export default function EditMailgun({ show, mailgun, productCategories = [], handleClose, handleSave }) {
  const [formData, setFormData] = useState(initMailgun);
  const [oldName, setOldName] = useState("");
  const [isDirty, setDirty] = useState(false);
  const [isPassFieldDirty, setPassFieldDirty] = useState(!formData.secretKey);

  const handleChange = ({ target: { name, value } }) => setFormData({ ...formData, [name]: value });

  const handleCancel = () => {
    setFormData(initMailgun);
    handleClose();
    setDirty(false);
  };

  const handleSaveMailgun = () => {
    const { name, ...m } = formData;

    handleSave(m, name, oldName);
    setFormData(initMailgun);
    setDirty(false);
  };

  useEffect(() => {
    if (mailgun) {
      setFormData({ ...formData, ...mailgun });
      setOldName(mailgun.name);
      setPassFieldDirty(!mailgun.secretKey);
    }
  }, [mailgun]);

  return (
    <Modal show={show} onHide={handleCancel} size="md" centered>
      <Modal.Header closeButton>
        <div>{oldName ? oldName : "New field"}</div>
      </Modal.Header>
      <Modal.Body>
        <Form onChange={() => setDirty(true)}>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              as="select"
              style={{ background: 0 }}
              name="name"
              value={formData.name}
              onChange={handleChange}
              isInvalid={!formData.name}
            >
              <option value="">Select Name</option>
              {productCategories.map(({ code }) => (
                <option key={code} value={code}>
                  {code}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">Name is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Secret Key</Form.Label>
            {isPassFieldDirty ? (
              <Form.Control required name="secretKey" value={formData.secretKey} onChange={handleChange} />
            ) : (
              <Form.Control
                required
                name="secretKey"
                type="password"
                autoComplete="new-password"
                value={formData.secretKey}
                onChange={() => setPassFieldDirty(true)}
              />
            )}
            <Form.Control.Feedback type="invalid">Secret Key is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Domain</Form.Label>
            <Form.Control required name="domain" value={formData.domain} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">Domain is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Lead Discount Template</Form.Label>
            <Form.Control
              required
              name="leadDiscountTemplate"
              value={formData.leadDiscountTemplate}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">Lead Discount Template is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>From</Form.Label>
            <Form.Control required name="from" value={formData.from} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">From is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Lead Discount Subject</Form.Label>
            <Form.Control
              required
              name="leadDiscountSubject"
              value={formData.leadDiscountSubject}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">Lead Discount Subject is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Payment Confirmation Template</Form.Label>
            <Form.Control
              required
              name="paymentConfirmationTemplate"
              value={formData.paymentConfirmationTemplate}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">Payment Confirmation Template is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Payment Confirmation Subject</Form.Label>
            <Form.Control
              required
              name="paymentConfirmationSubject"
              value={formData.paymentConfirmationSubject}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">Payment Confirmation Subject is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Processing Result Template</Form.Label>
            <Form.Control
              required
              name="processingResultTemplate"
              value={formData.processingResultTemplate}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">Processing Result Template is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Processing Result Subject</Form.Label>
            <Form.Control
              required
              name="processingResultSubject"
              value={formData.processingResultSubject}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">Processing Result Subject is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Support Email</Form.Label>
            <Form.Control required name="supportEmail" value={formData.supportEmail} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">Support Email is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Status Url</Form.Label>
            <Form.Control required name="statusUrl" value={formData.statusUrl} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">Status Url is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Base Sale Page Url</Form.Label>
            <Form.Control required name="baseSalePageUrl" value={formData.baseSalePageUrl} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">Base Sale Page Url is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Membership Url</Form.Label>
            <Form.Control required name="membershipUrl" value={formData.membershipUrl} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">Membership Url is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Support Phone</Form.Label>
            <Form.Control required name="supportPhone" value={formData.supportPhone} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">Support Phone is required</Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Close
        </Button>
        <Button variant="primary" disabled={!formData.name || !isDirty} onClick={handleSaveMailgun}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
