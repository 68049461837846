import { deployUIConstants } from "_constants";
import { deployUIService } from "_services";
import { alertActions } from ".";

export const deployUIActions = {
  getAll,
  deployUI,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());

    deployUIService.getAll().then(
      (result) => dispatch(success(result)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: deployUIConstants.GETALL_UI_REQUEST };
  }
  function success(result) {
    return { type: deployUIConstants.GETALL_UI_SUCCESS, result };
  }
  function failure(error) {
    return { type: deployUIConstants.GETALL_UI_FAILURE, error };
  }
}

function deployUI(jobid, services_array) {
  return (dispatch) => {
    dispatch(request());

    deployUIService.deployUI(jobid, services_array).then(
      (result) => {
        dispatch(success());
        dispatch(alertActions.success("Successfully deployed"));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: deployUIConstants.DEPLOY_UI_REQUEST };
  }
  function success(result) {
    return { type: deployUIConstants.DEPLOY_UI_SUCCESS };
  }
  function failure(error) {
    return { type: deployUIConstants.DEPLOY_UI_FAILURE, error };
  }
}
