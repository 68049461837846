import { Row, Form } from "react-bootstrap";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import IndividualView from "./Individual/View";
import MonitoringReportList from "./MonitoringReportList/View";

import StatusView from "../Status/View";

export default function FormTrademarkMonitiring({ orderDetails, selectedProduct }) {
  const orderId = orderDetails.order.id;
  const productDetails = orderDetails.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">MONITORING TRADEMARK</h4>

      <StatusView orderDetails={orderDetails} selectedProduct={selectedProduct} />

      <FormPDF productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">Mark Information</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Trademark Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.trademarkName}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Owner Information</h6>
      <IndividualView individual={productDetails.owner} />

      <MonitoringReportList monitoringReports={productDetails.processingResult.monitoringReports} />
    </div>
  );
}
