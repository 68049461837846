export function CollapseHeader({ children, onClick }) {
  return (
    <div className="collapse-header" onClick={onClick}>
      {children}
    </div>
  );
}

export function CollapseList({ isCollapsed, children }) {
  return <div className={`collapse-list${isCollapsed ? " collapsed" : ""}`}>{children}</div>;
}
