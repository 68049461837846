import { Form, Row, Col } from "react-bootstrap";
import moment from "moment-timezone";

export default function DateEdit({ name, column, label, date, onChange = () => {}, onInvalid = () => {} }) {
  return (
    <Form.Group as={Row}>
      {label && (
        <Form.Label column sm={column ? 12 : 5}>
          {label}
        </Form.Label>
      )}
      <Col sm={label && !column ? 7 : 12}>
        <Form.Control
          required
          size="sm"
          type="date"
          name={name}
          value={moment(date).format("YYYY-MM-DD") || ""}
          onChange={onChange}
          onInvalid={onInvalid}
        />
        <Form.Control.Feedback type="invalid">Please, select date</Form.Control.Feedback>
      </Col>
    </Form.Group>
  );
}
