import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Form } from "react-bootstrap";
import moment from "moment-timezone";
import classnames from "classnames";

import { customerActions } from "_actions";

import IndividualView from "../Individual/View";

export default function StateSearchResultList({ stateSearchResults }) {
  const dispatch = useDispatch();

  const { collapseStateSearchResults } = useSelector(({ customers }) => customers);

  return (
    <>
      <hr />
      <h6 className="font-weight-bold">State search results</h6>

      {stateSearchResults?.length > 0 ? (
        stateSearchResults.map((result, idx) => {
          const isCollapsed = !collapseStateSearchResults.includes(idx);

          return (
            <Col key={idx} sm={12} className="pl-3">
              <Form.Group as={Row} onClick={() => dispatch(customerActions.setCollapseStateSearch(idx))}>
                <Form.Label column sm={5} style={{ cursor: "pointer" }}>
                  <i className={`fa fa-caret-${isCollapsed ? "down" : "up"}`} /> Number assigned:
                </Form.Label>
                <Form.Label column sm={7} style={{ cursor: "pointer" }}>
                  {result?.numberAssigned || "—"}
                </Form.Label>
              </Form.Group>

              {!isCollapsed && (
                <Col className={classnames({ collapse: true, show: !isCollapsed })}>
                  <Form.Group as={Row}>
                    <Form.Label column sm={5}>
                      Mark name:
                    </Form.Label>
                    <Form.Label column sm={7}>
                      {result.markName}
                    </Form.Label>
                  </Form.Group>

                  <hr />
                  <h6 className="font-weight-bold">Owner</h6>
                  <IndividualView individual={result.owner} />

                  <hr />
                  <Form.Group as={Row}>
                    <Form.Label column sm={5}>
                      Classification:
                    </Form.Label>
                    <Form.Label column sm={7}>
                      {result.classification}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={5}>
                      Service:
                    </Form.Label>
                    <Form.Label column sm={7}>
                      {result.markDrawingCode}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={5}>
                      Description:
                    </Form.Label>
                    <Form.Label column sm={7}>
                      {result.description}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={5}>
                      Renewal date:
                    </Form.Label>
                    <Form.Label column sm={7}>
                      {moment.utc(result.renewalDate).format("YYYY/MM/DD")}
                    </Form.Label>
                  </Form.Group>
                </Col>
              )}
            </Col>
          );
        })
      ) : (
        <Form.Group as={Row}>
          <Form.Label column sm={12}>
            No search results
          </Form.Label>
        </Form.Group>
      )}
    </>
  );
}
