import { config } from "_config";
import { authHeader, handleResponse, mappingQueryParams } from "_helpers";

export const adminService = {
  generatereport,
  adsreport,
  getAdsAccounts,
  addAdsAccounts,
  updateAdsAccounts,
  deleteAdsAccount,
  pauseAdsAccount,
  getEinOptions,
  saveoptions,
  getcampaigns,
  getsalesbydomain,
  getProcessingOptions,
  getCategories,
  getActionRequiredFields,
  createActionRequiredFields,
  getAddressesRA,
  putAddressRA,
};

function generatereport(startdate, enddate) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = `${config.apiUrl1}/orders/gclid-report?category=EIN`;

  if (startdate !== "") url += `&from=${startdate}`;
  if (enddate !== "") url += `&to=${enddate}`;

  return fetch(url, requestOptions).then((response) => filehandle(response));
}

function adsreport({ startDate, endDate, category, includeRecurring }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/ads/statistics2${mappingQueryParams({
    from: startDate,
    to: endDate,
    category,
    includeRecurring: includeRecurring.toString(),
  })}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getAdsAccounts() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${config.apiUrl1}/ads/accounts`;

  return fetch(url, requestOptions).then(handleResponse);
}

function addAdsAccounts(account) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(account),
  };
  const url = `${config.apiUrl1}/ads/accounts`;

  return fetch(url, requestOptions).then(handleResponse);
}

function updateAdsAccounts(account) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(account),
  };
  const url = `${config.apiUrl1}/ads/accounts/${account.id}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function deleteAdsAccount(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };
  const url = `${config.apiUrl1}/ads/accounts/${id}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function pauseAdsAccount(accountId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/ads/accounts/campaigns/pause?accountId=${accountId}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getEinOptions(category) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = `${config.apiUrl1}/category/${category}/processing-options-configs`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getProcessingOptions(category, onlyActive = true) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = `${config.apiUrl1}/category/${category}/processing-options?onlyActive=${onlyActive}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function saveoptions(option) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(option),
  };
  let url = `${config.apiUrl1}/category/${option.category}/processing-options-configs`;
  return fetch(url, requestOptions).then(handleResponse);
}

function filehandle(response) {
  response.blob().then((blob) => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    const file_name = "gclid_report.csv";
    link.setAttribute("download", file_name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  });
  return true;
}

function getcampaigns() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = `${config.apiUrl1}/reports/campaigns`;
  return fetch(url, requestOptions).then(handleResponse);
}

function getsalesbydomain(startdate, enddate, campaignId, includeTest) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = `${config.apiUrl1}/reports/sales-by-domain-page?`;

  if (startdate !== "") url += `&from=${startdate}`;
  if (enddate !== "") url += `&to=${enddate}`;
  if (campaignId !== "" && campaignId !== "ALL") url += `&campaignId=${campaignId}`;
  if (includeTest !== "") url += `&includeTest=${includeTest}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getCategories() {
  const requestOptions = {
    method: "GET",
  };
  const url = `${config.apiUrl1}/category`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getActionRequiredFields(productCode) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/config/action-required-fields`;

  if (productCode) {
    url += `?code=${productCode}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function createActionRequiredFields(body) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  const url = `${config.apiUrl1}/config/action-required-fields`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getAddressesRA(state = null) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/resident-agents/addresses`;

  if (state) {
    url += `?state=${state}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function putAddressRA(body) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };

  const url = `${config.apiUrl1}/resident-agents/addresses`;

  return fetch(url, requestOptions).then(handleResponse);
}
