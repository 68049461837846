export function mappingQueryParams(params) {
  if (params && typeof params === "object" && Object.keys(params)) {
    let queryStr = "?";

    Object.keys(params).forEach((prop, idx) => {
      if (params[prop]) {
        if (queryStr.length > 1) {
          queryStr += "&";
        }

        queryStr += `${prop}=${encodeURIComponent(params[prop])}`;
      }
    });

    return queryStr.length > 1 ? queryStr : "";
  }

  return "";
}

export function normalizeCRMConfig(crmConfig) {
  return {
    ...crmConfig,
    services: crmConfig.services.map((srv) => ({ ...srv, node: !!srv.node ? srv.node : null })),
  };
}
