import { uiConstants } from "_constants";
import { uiService } from "_services";

export const uiActions = {
  getHash,
  checkHash,
};

function getHash() {
  return (dispatch) => {
    dispatch(request());

    uiService.getHash().then(
      (infoJson) => {
        const newHash = infoJson.CI_COMMIT_SHORT_SHA;

        dispatch(success(newHash));
      },
      () => dispatch(failure())
    );
  };

  function request() {
    return { type: uiConstants.GET_UI_HASH_REQUEST };
  }
  function success(payload) {
    return { type: uiConstants.GET_UI_HASH_SUCCESS, payload };
  }
  function failure() {
    return { type: uiConstants.GET_UI_HASH_FAILURE };
  }
}

function checkHash(hash, cb) {
  return (dispatch) => {
    dispatch(request());

    uiService.getHash().then(
      (infoJson) => {
        const newHash = infoJson.CI_COMMIT_SHORT_SHA;

        dispatch(success(newHash));

        if (newHash && cb) {
          if (hash === null || hash === newHash) {
            dispatch(cb);
          }
        }
      },
      () => {
        dispatch(failure());

        if (cb) dispatch(cb);
      }
    );
  };

  function request() {
    return { type: uiConstants.GET_UI_HASH_REQUEST };
  }
  function success(payload) {
    return { type: uiConstants.GET_UI_HASH_SUCCESS, payload };
  }
  function failure() {
    return { type: uiConstants.GET_UI_HASH_FAILURE };
  }
}
