import { Form, Row } from "react-bootstrap";

import StatusView from "../Status/View";

export default function FormIncorpifyOtherAmendment({ orderDetails, selectedProduct }) {
  const productDetails = orderDetails?.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">AMENDMENT</h4>

      <StatusView orderDetails={orderDetails} selectedProduct={selectedProduct} />

      <hr />
      <h6 className="font-weight-bold mb-4">Other Amendment Details</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Description:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.description
            ?.split("\n")
            ?.map((description) => <div style={{ minHeight: "1rem" }}>{description}</div>) || "–"}
        </Form.Label>
      </Form.Group>
    </div>
  );
}
