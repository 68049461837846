import { config } from '_config';
import { authHeader, handleResponse } from '_helpers';

export const thirdpartytypeService = {
  get,
};

function get() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  let url = `${config.healthcheckurl}/healthcheck/third-party-system-request-logs/object-types`;
  return fetch(url, requestOptions).then(handleResponse);
}
