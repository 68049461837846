import React from "react";
import { Col, Row, Form } from "react-bootstrap";

import { state_array } from "variables/Variables";

function AltAddressView({ productDetails }) {
  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Do you want to receive your mail at another address?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.altAddress ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>

      {productDetails?.altAddress && (
        <Col sm={12} className="pl-3">
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Mailing Address
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails?.altAddress?.address1 || "–"}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Mailing Address2
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails?.altAddress?.address2 || "–"}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Mailing City
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails?.altAddress?.city || "–"}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Mailing State
            </Form.Label>
            <Form.Label column sm={7}>
              {state_array[productDetails?.altAddress?.state] || "–"}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Mailing Zip Code
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails?.altAddress?.zip || "–"}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Mailing County
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails?.altAddress?.county || "–"}
            </Form.Label>
          </Form.Group>
        </Col>
      )}
    </>
  );
}

export default AltAddressView;
