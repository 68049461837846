import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChartistGraph from "react-chartist";
import { Container, Row, Col, Form, Card } from "react-bootstrap";
import moment from "moment-timezone";
import styled from "styled-components";

import { graphSalesLeadsActions } from "_actions";

import GraphCard from "components/Card/Card";
import Spinner from "components/Spinner";
import Button from "components/Button";

const StyledChartistGraph = styled(ChartistGraph)`
  & .ct-label {
    font-size: 1.125rem;
  }
`;

const Legend = styled.div`
  display: flex;
  gap: 10px;
`;
const LegendItem = styled.div`
  display: flex;
  align-items: center;
`;

export default function Sales() {
  const dispatch = useDispatch();

  const graph = useSelector(({ graphSalesLeads: { graph } }) => graph);
  const loading = useSelector(({ graphSalesLeads: { loading } }) => loading);

  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const [plotData, setPlotData] = useState({});

  const dataLabel = (date) => moment(date, "YYYY-MM-DDTHH").format("h");

  const legendSalesLeads = {
    names: ["Leads", "Sales"],
    types: ["info", "danger"],
  };

  const createLegend = (json) => {
    const legend = [];
    for (let i = 0; i < json.names.length; i++) {
      const type = "fa fa-circle text-" + json.types[i];

      legend.push(
        <LegendItem>
          <i className={type} key={i} /> {json.names[i]}
        </LegendItem>
      );
    }
    return legend;
  };

  const optionsBar = {
    axisX: {
      showGrid: true,
      offset: 50,
      labelOffset: {
        y: 10,
      },
      labelInterpolationFnc: dataLabel,
    },
    axisY: {
      scaleMinSpace: 20,
      offset: 50,
      labelOffset: {
        y: 10,
      },
      labelInterpolationFnc: (value) => (Number.isInteger(value) ? value : ""),
    },
    chartPadding: {
      top: 20,
      right: 10,
      bottom: 15,
      left: 10,
    },
    height: "245px",
  };

  const handleStartDate = ({ target: { value } }) => setStartDate(value);
  const handleEndDate = ({ target: { value } }) => setEndDate(value);

  const handleFilterButton = () => dispatch(graphSalesLeadsActions.get({ startDate, endDate }));

  useEffect(() => {
    if (graph.graph) {
      const labels = Object.keys(graph.graph);
      const salesArr = [];
      const leadsArr = [];

      labels.forEach((key) => {
        const { sales, leads } = graph.graph[key];

        salesArr.push(sales);
        leadsArr.push(leads);
      });
      setPlotData({ labels, series: [leadsArr, salesArr] });
    }
  }, [graph]);

  useEffect(() => {
    dispatch(graphSalesLeadsActions.get({ startDate, endDate }));
  }, []);

  return (
    <Container fluid className="mt-5 py-4">
      <Card className="p-2">
        <Row>
          <Col md={6}>
            <Form>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Start Date:</Form.Label>
                  <Form.Control
                    size="sm"
                    type="date"
                    name="startDate"
                    max={moment().format("YYYY-MM-DD")}
                    value={startDate}
                    onChange={handleStartDate}
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>End Date:</Form.Label>
                  <Form.Control
                    size="sm"
                    type="date"
                    name="endDate"
                    max={moment().format("YYYY-MM-DD")}
                    value={endDate}
                    onChange={handleEndDate}
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </Col>

          <Form.Group as={Col} md={6} style={{ display: "flex", alignItems: "flex-end" }}>
            <Button size="sm" className="mr-2" isLoading={loading} onClick={handleFilterButton}>
              Filter
            </Button>
          </Form.Group>
        </Row>
      </Card>
      {loading ? (
        <Spinner />
      ) : (
        <GraphCard
          id="chartSalesLeads"
          title="Sales"
          hCenter
          content={
            loading ? (
              <Spinner />
            ) : (
              <div style={{ overflow: "auto" }}>
                <StyledChartistGraph
                  style={{ minWidth: plotData.labels?.length ? plotData.labels.length * 30 : 0 }}
                  data={plotData}
                  type="Bar"
                  options={optionsBar}
                />
              </div>
            )
          }
          legendInHeader={<Legend className="legend">{createLegend(legendSalesLeads)}</Legend>}
        />
      )}
    </Container>
  );
}
