import React, { useEffect, useState } from "react";
import { Container, Button, Badge, Form, Row, Col } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";

import { config as initConfig } from "_config";
import { authHeader, getZConfig } from "_helpers";
import { alertActions } from "_actions";

import TicketCommentbox from "components/Tickets/TicketCommentbox";
import LoadingSpinner from "components/Spinner";

function TicketDetail(props) {
  const dispatch = useDispatch();
  const { ticketid } = useParams();
  const config = { ...initConfig, ...getZConfig() };
  const [ticket, setTicket] = useState(null);
  const [orderId, setOrderId] = useState("");
  const [linkedorder, setLinkedorder] = useState(null);

  const badge_style = {
    new: "warning",
    open: "danger",
    pending: "primary",
    solved: "info",
    closed: "dark",
  };

  const Zendesk_headers = {
    headers: { Authorization: "Bearer " + localStorage.getItem("zauth") },
  };

  const app_header = {
    headers: authHeader(),
  };

  const getticket = (e) => {
    const url = `${config.zendesk_url}/api/v2/tickets/${ticketid}`;
    axios.get(url, Zendesk_headers).then((res) => {
      setTicket(res.data.ticket);
      getlinkedorder();
    });
  };

  const linkorder = (e) => {
    if (orderId !== "")
      axios
        .post(`${config.apiUrl1}/zendesk/tickets/${ticketid}/orders/${orderId}`, {}, app_header)
        .then((res) => {
          setLinkedorder(res.data);
          setOrderId("");
        })
        .catch((error) => {
          if (error.response.data.message === "illegal order id") {
            dispatch(alertActions.error("Order is not exist."));
          }
        });
  };

  const unlink_order = (e) => {
    axios.delete(`${config.apiUrl1}/zendesk/tickets/${ticketid}`, app_header).then((res) => {
      setLinkedorder([]);
    });
  };

  const getlinkedorder = () => {
    axios.get(`${config.apiUrl1}/zendesk/tickets?ids=${ticketid}`, app_header).then((res) => {
      if (res.data.length > 0) setLinkedorder(res.data[0]);
      else setLinkedorder([]);
    });
  };

  useEffect(() => {
    getticket();
  }, []);

  if (!ticket)
    return (
      <Container fluid className="mt-5 py-4 fixed-height tickets">
        <LoadingSpinner></LoadingSpinner>
      </Container>
    );

  return (
    <>
      <Container fluid className="mt-5 py-4 mb-5 fixed-height tickets">
        <div className="row">
          <div className="col-md-4 left-side">
            <Container fluid>
              <Row>
                <Col sm="4">ID:</Col>
                <Col sm="8">#{ticket.id}</Col>
              </Row>

              <Row>
                <Col sm="4">Subject:</Col>
                <Col sm="8">{ticket.subject}</Col>
              </Row>

              <Row>
                <Col sm="4">Status:</Col>
                <Col sm="8">
                  <Badge variant={badge_style[ticket.status]}>{ticket.status}</Badge>
                </Col>
              </Row>

              <Row>
                <Col sm="4">Linked Order ID:</Col>

                <Col sm="8">
                  {linkedorder && (
                    <>
                      {linkedorder.length === 0 || !linkedorder.orderCRMId ? (
                        <>
                          <Form.Control className="mb-2" value={orderId} onChange={(e) => setOrderId(e.target.value)} />
                          <Button size="sm" onClick={linkorder}>
                            Link to order
                          </Button>
                        </>
                      ) : (
                        <>
                          <p className="m-0">{linkedorder.orderCRMId}</p>
                          <Button size="sm mr-2">
                            <Link to={"/admin/orders/" + linkedorder.orderUid} className="dropdown-item">
                              View Order
                            </Link>
                          </Button>
                          <Button size="sm" onClick={unlink_order}>
                            Remove
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Container>
          </div>

          <TicketCommentbox ticket={ticket}></TicketCommentbox>
        </div>
      </Container>
    </>
  );
}

export default TicketDetail;
