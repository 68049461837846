import { Row, Col, Form } from "react-bootstrap";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import { officeActionStatus, statementOfUseStatus, revivalServices } from "constants/trademarks.constants";

import IndividualEdit from "../Individual/Edit";
import StatusEdit from "../../Status/Edit";

import { TAB_CODES } from "variables/Variables";

export default function FormTrademarkRevivalFromAbandonment({
  orderDetails,
  selectedProduct,
  onError,
  onChange,
  ...props
}) {
  const orderId = orderDetails.order.id;
  const productDetails = orderDetails.products[selectedProduct];

  const handleAddError = (field) => onError(TAB_CODES.trademarkInfo, field);

  const handleChangeProduct = ({ target: { name, value } }) =>
    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, [name]: value } : product
      ),
    });

  const handleChangeProductTrademarkOwner = (value) =>
    handleChangeProduct({ target: { name: "trademarkOwner", value } });
  const handleChangeProductIndividual = (value) => handleChangeProduct({ target: { name: "individual", value } });
  const handleChangeProductService = ({ target: { value } }) => {
    const newVal = Object.keys(revivalServices)
      .filter((k) => k !== value)
      .reduce((cur, k) => (cur = { ...cur, [k]: "NONE" }), {});

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, [value]: "", ...newVal } : product
      ),
    });
  };

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">REVIVAL FROM ABANDOMENT TRADEMARK</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
      />

      <FormPDF productDetails={productDetails} {...props} />

      {/* <h6 className="font-weight-bold">Individual Information</h6> */}
      <IndividualEdit
        label="Individual Information"
        individual={productDetails.individual}
        onError={handleAddError}
        onChange={handleChangeProductIndividual}
      />

      <hr />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Serial number:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            size="sm"
            name="serialNumber"
            value={productDetails.serialNumber || ""}
            onChange={handleChangeProduct}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Trademark name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="trademarkName"
            value={productDetails.trademarkName || ""}
            onChange={handleChangeProduct}
          />
        </Col>
      </Form.Group>

      <hr />
      <IndividualEdit
        label="Trademark owner"
        individual={productDetails.trademarkOwner}
        onError={handleAddError}
        onChange={handleChangeProductTrademarkOwner}
      />

      <hr />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Select service:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            as="select"
            size="sm"
            value={Object.keys(revivalServices).find((k) => productDetails[k] !== "NONE")}
            onChange={handleChangeProductService}
          >
            {Object.keys(revivalServices).map((key) => (
              <option key={key} value={key}>
                {revivalServices[key]}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      {productDetails.officeActionStatus !== "NONE" && (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Office action status:
          </Form.Label>
          <Col sm={7}>
            <Form.Control
              required
              as="select"
              size="sm"
              name="officeActionStatus"
              value={productDetails.officeActionStatus}
              onChange={handleChangeProduct}
              onInvalid={() => handleAddError(" Office action status")}
            >
              <option value="">Select a status</option>
              {Object.keys(officeActionStatus).map((key) => (
                <option key={key} value={key}>
                  {officeActionStatus[key]}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
      )}

      {productDetails.statementOfUseStatus !== "NONE" && (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Statement of use status:
          </Form.Label>
          <Col sm={7}>
            <Form.Control
              required
              as="select"
              size="sm"
              name="statementOfUseStatus"
              value={productDetails.statementOfUseStatus}
              onChange={handleChangeProduct}
              onInvalid={() => handleAddError("Statement of use status:")}
            >
              <option value="">Select a status</option>
              {Object.keys(statementOfUseStatus).map((key) => (
                <option key={key} value={key}>
                  {statementOfUseStatus[key]}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
      )}
    </div>
  );
}
