import { orderFiltersConstants } from "_constants";

const initialState = {
  ein: null,
  ein_inc: null,
  inc: null,
  vital: null,
  trademarks: null,
};

export function orderFilters(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case orderFiltersConstants.SAVE_ORDER_FILTERS:
      return { ...state, ...payload };
    default:
      return state;
  }
}
