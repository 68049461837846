import React from "react";
import { Col, Row, Form } from "react-bootstrap";

import { state_array } from "variables/Variables";

function AltAddressEdit({ productDetails, onError, onProductChange }) {
  const handleProductChange = ({ target: { name, value } }) =>
    onProductChange({ target: { name, value: value === "yes" ? { country: "US" } : null } });

  const handleProdcutAltAddressChange = ({ target: { name, value } }) =>
    onProductChange({ target: { name: "altAddress", value: { ...productDetails?.altAddress, [name]: value } } });

  return (
    <Form.Group as={Row}>
      <Form.Label column sm={5}>
        Do you want to receive your mail at another address?
      </Form.Label>
      <Col sm={7} className="d-flex align-items-center">
        <Form.Check
          id="altAddress_r_no"
          className="mr-3"
          type="radio"
          label="no"
          name="altAddress"
          defaultChecked={!productDetails?.altAddress}
          value="no"
          onChange={handleProductChange}
        />
        <Form.Check
          id="altAddress_r_yes"
          type="radio"
          label="yes"
          name="altAddress"
          defaultChecked={productDetails?.altAddress}
          value="yes"
          onChange={handleProductChange}
        />
      </Col>

      {productDetails?.altAddress && (
        <Col sm={12} className="pl-3">
          <Form.Label column sm={5}>
            Mailing Address
          </Form.Label>
          <Form.Label column sm={7}>
            <Form.Control
              required
              size="sm"
              type="text"
              name="address1"
              pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
              value={productDetails?.altAddress?.address1 || ""}
              onChange={handleProdcutAltAddressChange}
              onInvalid={() => onError("Mailing Address")}
            />
          </Form.Label>

          <Form.Label column sm={5}>
            Mailing Address2
          </Form.Label>
          <Form.Label column sm={7}>
            <Form.Control
              size="sm"
              type="text"
              name="address2"
              pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
              value={productDetails?.altAddress?.address2 || ""}
              onChange={handleProdcutAltAddressChange}
              onInvalid={() => onError("Mailing Address2")}
            />
          </Form.Label>

          <Form.Label column sm={5}>
            Mailing City
          </Form.Label>
          <Form.Label column sm={7}>
            <Form.Control
              required
              size="sm"
              type="text"
              name="city"
              pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
              value={productDetails?.altAddress?.city || ""}
              onChange={handleProdcutAltAddressChange}
              onInvalid={() => onError("Mailing City")}
            />
          </Form.Label>

          <Form.Label column sm={5}>
            Mailing State
          </Form.Label>
          <Form.Label column sm={7}>
            <Form.Control
              required
              as="select"
              name="state"
              value={productDetails?.altAddress?.state || ""}
              onChange={handleProdcutAltAddressChange}
              onInvalid={() => onError("Mailing State")}
            >
              <option value="">Select a state</option>
              {Object.keys(state_array).map((key) => (
                <option key={key} value={key}>
                  {state_array[key]}
                </option>
              ))}
            </Form.Control>
          </Form.Label>

          <Form.Label column sm={5}>
            Mailing Zip Code
          </Form.Label>
          <Form.Label column sm={7}>
            <Form.Control
              required
              size="sm"
              type="text"
              name="zip"
              pattern="^\d{5}$"
              value={productDetails?.altAddress?.zip || ""}
              onChange={handleProdcutAltAddressChange}
              onInvalid={() => onError("Mailing Zip Code")}
            />
          </Form.Label>

          <Form.Label column sm={5}>
            Mailing County
          </Form.Label>
          <Form.Label column sm={7}>
            <Form.Control
              size="sm"
              type="text"
              name="county"
              pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
              value={productDetails?.altAddress?.county || ""}
              onChange={handleProdcutAltAddressChange}
              onInvalid={() => onError("Mailing County")}
            />
          </Form.Label>
        </Col>
      )}
    </Form.Group>
  );
}

export default AltAddressEdit;
