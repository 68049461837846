import { Fragment, useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";

export const initUI = {
  projectId: "",
  description: "",
  deployments: [],
};

const initDeployment = {
  node: null,
  path: process.env.REACT_APP_UI_DEPLOYMENT_PATH || "New deployment",
  active: true,
  deployScriptPath: process.env.REACT_APP_UI_DEPLOYMENT_SCRIPT_PATH || "",
  domainName: "",
  artifactName: "",
};

const initNode = {
  host: process.env.REACT_APP_UI_DEPLOYMENT_NODE_HOST || "",
  keyPath: process.env.REACT_APP_UI_DEPLOYMENT_NODE_KEY_PATH || "",
  user: process.env.REACT_APP_UI_DEPLOYMENT_NODE_USER || "",
  port: process.env.REACT_APP_UI_DEPLOYMENT_NODE_PORT || 0,
};

function DefaultButton({ onDefault }) {
  return (
    <Button title="Set Default" variant="light" className="btn btn-sm ml-1 p-0" onClick={onDefault}>
      <i className="fa fa-rotate-left" />
    </Button>
  );
}

function Node({ node, onChange }) {
  const handleChange = ({ target: { name, value, type } }) =>
    onChange({ target: { name: "node", value: { ...node, [name]: type === "number" ? Number(value) : value } } });

  const handleDefault = (name) => handleChange({ target: { name, value: initNode[name] } });

  const handleAddNode = () => onChange({ target: { name: "node", value: { ...initNode } } });
  const handleDeleteNode = () => onChange({ target: { name: "node", value: null } });

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column>Node</Form.Label>
        <Button
          style={{ width: "85px" }}
          variant={!node ? "success" : "danger"}
          className="btn-sm pull-right mr-3"
          onClick={!node ? handleAddNode : handleDeleteNode}
        >
          {!node ? "Add" : "Delete"} <i className={`fa fa-${!node ? "plus" : "trash"}`} />
        </Button>
      </Form.Group>
      {!!node && (
        <Form.Group className="ml-3">
          <Form.Group>
            <Form.Label>
              Host
              <DefaultButton onDefault={() => handleDefault("host")} />
            </Form.Label>
            <Form.Control required name="host" value={node.host} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">Host is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Key Path
              <DefaultButton onDefault={() => handleDefault("keyPath")} />
            </Form.Label>
            <Form.Control required name="keyPath" value={node.keyPath} onChange={handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              User
              <DefaultButton onDefault={() => handleDefault("user")} />
            </Form.Label>
            <Form.Control required name="user" value={node.user} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">User is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Port
              <DefaultButton onDefault={() => handleDefault("port")} />
            </Form.Label>
            <Form.Control required type="number" name="port" value={node.port} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">Port is required</Form.Control.Feedback>
          </Form.Group>
        </Form.Group>
      )}
    </>
  );
}

function Deployment({ idx, deployment, onSave }) {
  const [deploymentInfo, setDeploymentInfo] = useState({ ...initDeployment });

  const handleChange = ({ target: { name, value, checked, type } }) =>
    setDeploymentInfo({ ...deploymentInfo, [name]: type === "checkbox" ? checked : value });

  const handleSave = (name, value) => onSave(deploymentInfo, idx);

  const handleDefault = (name) => handleChange({ target: { name, value: initDeployment[name] } });

  useEffect(() => {
    setDeploymentInfo(deployment);
  }, [deployment]);

  return (
    <>
      <Form.Group controlId="path">
        <Form.Label>
          Path
          <DefaultButton onDefault={() => handleDefault("path")} />
        </Form.Label>
        <Form.Control name="path" value={deploymentInfo.path} onChange={handleChange} />
      </Form.Group>

      <Form.Group controlId="active">
        <Form.Check
          type="checkbox"
          label="Active"
          name="active"
          checked={deploymentInfo.active}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group controlId="deployScriptPath">
        <Form.Label>
          Deploy Script Path
          <DefaultButton onDefault={() => handleDefault("deployScriptPath")} />
        </Form.Label>
        <Form.Control name="deployScriptPath" value={deploymentInfo.deployScriptPath} onChange={handleChange} />
      </Form.Group>

      <Form.Group controlId="domainName">
        <Form.Label>Domain Name</Form.Label>
        <Form.Control name="domainName" value={deploymentInfo.domainName} onChange={handleChange} />
      </Form.Group>

      <Form.Group controlId="artifactName">
        <Form.Label>Artifact Name</Form.Label>
        <Form.Control name="artifactName" value={deploymentInfo.artifactName} onChange={handleChange} />
      </Form.Group>

      <Form.Group controlId="node" className="pl-2">
        <Node node={deploymentInfo.node} onChange={handleChange} />
      </Form.Group>

      <Form.Group controlId="buttons" className="d-flex justify-content-end">
        <Button style={{ width: "170px" }} variant="success" className="btn-sm pull-right" onClick={handleSave}>
          Save Deployment
        </Button>
      </Form.Group>
    </>
  );
}

function Deployments({ deployments, editIdx, onEdit, onSave, onDelete, onAdd }) {
  return (
    <>
      {deployments.map((deployment, idx) => (
        <Fragment key={idx}>
          <Form.Group key={idx} as={Row}>
            <Form.Label column style={deployment.active ? {} : { color: "grey", fontStyle: "italic" }}>
              {deployment.domainName}
            </Form.Label>
            <Button
              style={{ width: "85px" }}
              variant="warning"
              className="btn btn-sm pull-right mr-1"
              onClick={() => onEdit(idx)}
            >
              Edit <i className="fa fa-edit" />
            </Button>
            <Button
              style={{ width: "85px" }}
              variant="danger"
              className="btn btn-sm mr-3"
              onClick={() => onDelete(idx)}
            >
              Delete <i className="fa fa-trash" />
            </Button>
          </Form.Group>
          {editIdx === idx && <Deployment idx={idx} deployment={deployment} onSave={onSave} />}
        </Fragment>
      ))}
      {editIdx === null && (
        <Form.Group as={Row}>
          <Form.Label column>Create new deployment field</Form.Label>
          <Button style={{ width: "85px" }} variant="success" className="btn-sm pull-right mr-3" onClick={onAdd}>
            Add <i className="fa fa-plus" />
          </Button>
        </Form.Group>
      )}
    </>
  );
}

export default function EditUI({ show, ui, handleClose, handleSave }) {
  const [formData, setFormData] = useState(initUI);
  const [oldId, setOldId] = useState("");
  const [editIdx, setEditIdx] = useState(null);
  const [isDirty, setDirty] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    if (name.match(/\./)) {
      const [parentField, childField] = name.split(".");

      setFormData({
        ...formData,
        [parentField]: { ...formData[parentField], [childField]: value },
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSaveDeployment = (_deployment, _idx) => {
    setFormData({
      ...formData,
      deployments: formData.deployments.map((deployment, idx) => (idx === _idx ? _deployment : deployment)),
    });
    setEditIdx(null);
    setDirty(true);
  };

  const handleAddDeployment = () => {
    setFormData({ ...formData, deployments: [...formData.deployments, { ...initDeployment }] });
    setEditIdx(formData.deployments.length);
    setDirty(true);
  };

  const handleEditDeployment = (idx) => setEditIdx(idx);

  const handleDeleteDeployment = (_idx) => {
    if (_idx === editIdx) setEditIdx(null);

    setFormData({ ...formData, deployments: formData.deployments.filter((d, idx) => idx !== _idx) });
  };

  const handleCancel = () => {
    setFormData(initUI);
    handleClose();
    setDirty(false);
    setEditIdx(null);
  };

  const handleSaveUI = () => {
    handleSave(formData, oldId);
    setFormData(initUI);
    setDirty(false);
    setEditIdx(null);
  };

  useEffect(() => {
    setFormData(ui);
    setOldId(ui.projectId);
  }, [ui]);

  return (
    <Modal show={show} onHide={handleCancel} size="md" centered>
      <Modal.Header closeButton>
        <div>{oldId ? oldId : "New field"}</div>
      </Modal.Header>
      <Modal.Body>
        <Form onChange={() => setDirty(true)}>
          <Form.Group>
            <Form.Label>Project Id</Form.Label>
            <Form.Control
              name="projectId"
              value={formData.projectId}
              isInvalid={!formData.projectId}
              style={{ background: 0 }}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">ProjectId is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              name="description"
              value={formData.description}
              style={{ background: 0 }}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Label>Deployments</Form.Label>
          <Deployments
            deployments={formData.deployments}
            editIdx={editIdx}
            onEdit={handleEditDeployment}
            onSave={handleSaveDeployment}
            onAdd={handleAddDeployment}
            onDelete={handleDeleteDeployment}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Close
        </Button>
        <Button variant="primary" disabled={!formData.projectId || !isDirty} onClick={handleSaveUI}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
