import { uiConstants } from "_constants";

const initState = {
  isUiUpdated: false,
  hash: null,
};

export function ui(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case uiConstants.GET_UI_HASH_SUCCESS: {
      if (!state.hash) {
        return { ...state, hash: payload };
      }

      if (state.hash !== payload) return { ...state, isUiUpdated: true };
    }

    default:
      return state;
  }
}
