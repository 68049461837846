import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Table, Button, Form } from "react-bootstrap";
import moment from "moment-timezone";

import { transformEmailsLogs } from "_helpers";
import { customerActions } from "_actions";

function Emails({ orderDetails, selectedProduct }) {
  const dispatch = useDispatch();
  const productDetails = orderDetails.products[selectedProduct];
  const orderId = orderDetails.order.id;
  const productId = productDetails.id;
  const emailsLogs = productDetails?.emailsSentLogs || {};
  const emailsData = useMemo(() => transformEmailsLogs(emailsLogs), [emailsLogs]);

  const handleResendEmail = useCallback(
    (emailKey) => () => {
      dispatch(customerActions.resendEmailByKey(orderId, productId, emailKey));
    },
    [dispatch, orderId, productId]
  );

  const handleSendReceipt = useCallback(() => {
    dispatch(customerActions.sendReceipt(orderId));
  }, [dispatch, orderId]);

  return (
    <div className="form-border">
      {["TRADEMARKS", "INC", "INC_States"].includes(productDetails?.code?.category) && (
        <Form.Group className="sub-form">
          <span className="title">Email Actions</span>
          <Form.Label column sm="4">
            <Button size="sm" onClick={handleSendReceipt}>
              Send Receipt
            </Button>
          </Form.Label>
        </Form.Group>
      )}

      <Table responsive>
        <thead>
          <tr>
            <th>Email</th>
            <th>Date Sent</th>
            <th>Resend Email</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {!!emailsData.length &&
            emailsData.map(({ emailKey, emailDescription, sent, resent }) => (
              <tr key={emailKey}>
                <td>{emailDescription}</td>
                <td>{moment.utc(sent).local().format("YYYY/MM/DD HH:mm:ss")}</td>
                <td>
                  <Button size="sm" variant="success" onClick={handleResendEmail(emailKey)}>
                    Resend
                  </Button>
                </td>
                <td>
                  {resent && (
                    <span className="email-resent-message">
                      Email resent: {moment.utc(resent).local().format("YYYY/MM/DD HH:mm:ss")}
                    </span>
                  )}
                </td>
              </tr>
            ))}
          {!emailsData.length && (
            <tr>
              <td>No Emails were sent</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default Emails;
