import React from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import { customerActions } from "_actions";

import Indicator from "components/Indicator";

import CommonLawTrademarkResultListView from "../CommonLawTrademarkResultList/View";
import FederalSearchResultListView from "../FederalSearchResultList/View";
import StateSearchResultListView from "../StateSearchResultList/View";

export default function MonitoringReportList({ monitoringReports }) {
  const dispatch = useDispatch();

  const { collapseMonitoringReport } = useSelector(({ customers }) => customers);

  return (
    <>
      <hr />
      <h6 className="font-weight-bold">Monitoring Reports</h6>

      {monitoringReports && Object.keys(monitoringReports).length > 0 ? (
        Object.keys(monitoringReports).map((idx) => {
          const isCollapsed = !collapseMonitoringReport.includes(idx);

          return (
            <Col key={idx} sm={12} className="pl-3">
              <Form.Group as={Row} onClick={() => dispatch(customerActions.setCollapseMonitoringReport(idx))}>
                <Form.Label style={{ cursor: "pointer" }}>
                  <i className={`fa fa-caret-${isCollapsed ? "down" : "up"}`} />
                  {idx}
                </Form.Label>
                <Col>
                  <Indicator
                    title={monitoringReports[idx].completed ? "Completed" : "Uncomplited"}
                    status={monitoringReports[idx].completed}
                    margin="0 0 0 16px"
                  />
                </Col>
              </Form.Group>
              <Col key={idx} className={classnames({ collapse: true, show: !isCollapsed })}>
                <FederalSearchResultListView federalSearchResults={monitoringReports[idx].federalSearchResults} />
                <StateSearchResultListView stateSearchResults={monitoringReports[idx].stateSearchResults} />
                <CommonLawTrademarkResultListView commonLawTrademarkResults={monitoringReports[idx].commonLawResults} />
              </Col>
            </Col>
          );
        })
      ) : (
        <Form.Group as={Row}>
          <Form.Label column sm={12}>
            No Monitoring Reports
          </Form.Label>
        </Form.Group>
      )}
    </>
  );
}
