import { zendeskConstants } from '../_constants';

const initial_state = null;

export function ticketfilter(state = initial_state, action) {
  switch (action.type) {
    case zendeskConstants.SAVE_TICKET_FILTER:
      return action.options
    default:
      return state;
  }
}
