import { userService } from "_services";

export function getIndicator(user) {
  const { accountManager, status, role } = user;

  if (status === "Disabled") {
    return "disabled";
  }

  if (accountManager) {
    return "account-manager";
  }

  if (role === "ATTORNEY") {
    return "legal-team";
  }

  if (role) {
    return role.toLowerCase();
  }

  return "none";
}

const loadUserOptions = async (search, prevOptions, { page }) => {
  const options = [];
  let hasMore = false;

  try {
    const { elements, maxPerPage, totalResults } = await userService.getAll({
      page,
      count: 5,
      search,
    });

    hasMore = totalResults > maxPerPage * page;

    elements.forEach(({ id, email, username }) => {
      options.push({
        label: `${username} - ${email}`,
        value: id,
      });
    });
  } catch (e) {
    return {
      options: [],
      hasMore: false,
      additional: {
        page: page,
      },
    };
  }

  return {
    options,
    hasMore,
    additional: {
      page: page + 1,
    },
  };
};

const loadAccountManagerOptions = async (search, prevOptions, { page }) => {
  const options = [];
  let hasMore = false;

  try {
    const { elements, maxPerPage, totalResults } = await userService.getAll({
      page,
      count: 5,
      search,
      accountManager: true,
    });

    hasMore = totalResults > maxPerPage * page;

    elements.forEach(({ id, email, username }) => {
      options.push({
        label: `${username} - ${email}`,
        value: id,
      });
    });
  } catch (e) {
    return {
      options: [],
      hasMore: false,
      additional: {
        page: page,
      },
    };
  }

  return {
    options,
    hasMore,
    additional: {
      page: page + 1,
    },
  };
};

export const asyncOptionsList = {
  loadUserOptions,
  loadAccountManagerOptions,
};
