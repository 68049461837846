import { reportsClicksConstants } from "_constants";

const initialState = {
  loading: 0,
  clicks: null,
};

export function reportsClicks(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case reportsClicksConstants.GET_CLICKS_REQUEST:
      return { ...state, loading: state.loading + 1, clicks: null };

    case reportsClicksConstants.GET_CLICKS_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1, clicks: payload };

    case reportsClicksConstants.GET_CLICKS_FAILURE:
      return { ...state, loading: state.loading && state.loading - 1, clicks: null };

    default:
      return state;
  }
}
