export function getHealthStatusesCount(healthchecks) {
  const items = healthchecks ? healthchecks : [];

  return items.reduce(
    (acc, service) => {
      const { marker } = service;
      return { ...acc, [marker]: acc[marker] + 1 };
    },
    { green: 0, yellow: 0, red: 0 }
  );
}

export function transformHealthMapToList(mapOfServiceHealthchecks = {}) {
  return Object.values(mapOfServiceHealthchecks).reduce((list, subList) => [...list, ...subList], []);
}
