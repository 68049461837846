import { config } from "_config";
import { authHeader, handleResponse } from "_helpers";

export const crmConfigService = {
  get,
  saveConfig,
  getProductCategories,
};

function get() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/config`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getProductCategories() {
  const requestOptions = {
    method: "GET",
  };

  const url = `${config.apiUrl1}/category`;

  return fetch(url, requestOptions).then(handleResponse);
}

function saveConfig(crmConfig) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(crmConfig),
  };

  const url = `${config.apiUrl1}/config`;

  return fetch(url, requestOptions).then(handleResponse);
}
