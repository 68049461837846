import { Row, Col, Form } from "react-bootstrap";
import InputMask from "react-input-mask";

import { TAB_CODES } from "variables/Variables";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";
import DateOfBirth from "components/OrderDetails/ProductDetails/DateOfBirth/Edit";

import StatusEdit from "../../Status/Edit";

function FormBoi({ orderDetails, selectedProduct, onChange, onError, ...props }) {
  const productDetails = orderDetails?.products[selectedProduct];

  const isEINProductIncluded =
    productDetails?.code?.code === "EIN_BOI" || orderDetails?.products?.some(({ code }) => code?.code === "INC_EIN");

  const handleAddError = (field) => onError(TAB_CODES.boiInfo, field);

  const handleProductChange = ({ target: { name, value } }) => {
    let exFields = { [name]: value || null };

    if (value === "Invalid date") {
      exFields[name] = null;
    }

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, ...exFields } : product
      ),
    });
  };

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">Beneficial Ownership Information Report</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
      />

      <FormPDF productDetails={productDetails} {...props} />

      <hr />
      <DateOfBirth productDetails={productDetails} onChange={handleProductChange} />

      {!isEINProductIncluded && (
        <>
          <hr />
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Social Security Number:
            </Form.Label>
            <Col sm={7}>
              <InputMask
                className="form-control form-control-sm"
                mask="999-99-9999"
                maskChar=" "
                name="ssn"
                placeholder="999-99-9999"
                pattern="^\d{3}-\d{2}-\d{4}$"
                value={productDetails?.ssn || ""}
                onChange={handleProductChange}
                onInvalid={() => handleAddError("Social Security Number")}
              />
            </Col>
          </Form.Group>
        </>
      )}

      <hr />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Driver's License or ID Number:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="driverLicenceOrID"
            value={productDetails?.driverLicenceOrID || ""}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>
    </div>
  );
}

export default FormBoi;
