import { useState, useEffect } from "react";
import { Card, Table, Row, Col, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { healthcheckActions } from "_actions";
import { transformHealthMapToList } from "_helpers/healthcheck";

function TabHealthCheckFrontend() {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [uiTypes, setUITypes] = useState([]);
  const [uiName, setUIName] = useState("");

  const { healthchecks } = useSelector(({ healthchecks }) => healthchecks.frontendHealth);
  const { serviceHealthchecks, mapOfServiceHealthchecks } = healthchecks || {};

  const getHealthcheck = () => {
    dispatch(healthcheckActions.getFrontendHealth());
  };

  const handleUIName = ({ target: { value } }) => {
    setUIName(value);
  };

  useEffect(() => {
    if (mapOfServiceHealthchecks) {
      setUITypes(Object.keys(mapOfServiceHealthchecks)?.sort());
    }
  }, [mapOfServiceHealthchecks]);

  useEffect(() => {
    if (uiName) {
      setList([...(mapOfServiceHealthchecks || {})[uiName]]);
    } else {
      setList(transformHealthMapToList(mapOfServiceHealthchecks));
    }
  }, [mapOfServiceHealthchecks, uiName]);

  return (
    <div className="form-border bg-white fixed-height healthcheck">
      <Row>
        <Col md="12">
          <Card className="mb-1">
            <Card.Title className="m-0 p-2">
              <Form inline>
                User Interface Informations
                <Button type="button" className="ml-4 mr-4 my-1" size="sm" onClick={getHealthcheck}>
                  Ping
                </Button>
                <Form.Group>
                  <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                    UI Type:
                  </Form.Label>
                  <Form.Control
                    as="select"
                    className="my-1 mr-5"
                    id="inlineFormCustomSelectPref"
                    size="sm"
                    value={uiName}
                    onChange={handleUIName}
                  >
                    <option value="">Select UI Type</option>
                    {uiTypes &&
                      uiTypes.map((prop, key) => (
                        <option key={key} value={prop}>
                          {prop}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Form>
            </Card.Title>
            <Table striped bordered responsive size="sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Health</th>
                  <th>Ping</th>
                  <th>Skin</th>
                  <th>URL</th>
                  <th>Version</th>
                  <th>Last deployed</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, key) => (
                  <tr key={key} title={item.versionTooltip}>
                    <td>{item.name}</td>
                    <td>
                      <span className={"indicator-status status-" + item.marker} />
                    </td>
                    <td>{item.ping}</td>
                    <td>{item.skin}</td>
                    <td>
                      <a href={item.healthcheckUrl} target="_blank">
                        {item.healthcheckUrl}
                      </a>
                    </td>
                    <td>{item.buildVersion}</td>
                    <td>{moment.utc(item.lastDeployed).local().format("YYYY/MM/DD HH:mm:ss")}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default TabHealthCheckFrontend;
