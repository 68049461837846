import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { Container, Row, Col, Table, Card, Pagination, Form } from "react-bootstrap";

import { history } from "_helpers";
import { reportsEINPaymentActions, variantsActions } from "_actions";

import Spinner from "components/Spinner";
import Button from "components/Button";

const QUESTION_TYPES = [
  { key: "CreditCard", label: "CreditCard" },
  { key: "Payroll", label: "Payroll" },
];

const EIN_PAYMENT_COLUMNS = [
  { key: "orderCrmId", label: "Order ID" },
  { key: "llcName", label: "LLC Name" },
  { key: "entityType", label: "Entity Type" },
  { key: "firstName", label: "First Name" },
  { key: "lastName", label: "Last Name" },
  { key: "email", label: "E-Mail" },
  { key: "phone", label: "Phone" },
  { key: "activityPrimaryActivity", label: "Primary Activity" },
  { key: "activitySpecificProducts", label: "Specific Product" },
  { key: "productDescription", label: "Product Description" },
];

export default function EinPayment() {
  const dispatch = useDispatch();
  const { loading, orders, page, totalPages } = useSelector(({ reportsEINPayment }) => reportsEINPayment);
  const { orderStatus: statuses, activities } = useSelector(({ variants }) => variants);

  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [orderStatus, setOrderStatus] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const handleStartDate = ({ target: { value } }) => setStartDate(value);
  const handleEndDate = ({ target: { value } }) => setEndDate(value);
  const handleQuestionType = ({ target: { value } }) => setQuestionType(value);
  const handleStatus = ({ target: { value } }) => setOrderStatus(value);

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = useCallback(() => {
    setCurrentPage(totalPages);
  }, [totalPages]);

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleActivePage = (page) => () => {
    setCurrentPage(page);
  };

  const handleGetActivities = useCallback(() => {
    dispatch(variantsActions.getActivities("EIN"));
  }, [dispatch]);

  const handleGetStatuses = useCallback(() => {
    dispatch(variantsActions.get_order_status("EIN"));
  }, [dispatch]);

  const handleGetReport = useCallback(() => {
    dispatch(
      reportsEINPaymentActions.getAll({
        from: startDate,
        to: endDate,
        orderStatus,
        questionType,
        page: currentPage,
        count: 25,
      })
    );
  }, [dispatch, startDate, endDate, orderStatus, questionType, currentPage]);

  const handleExportReport = useCallback(() => {
    dispatch(
      reportsEINPaymentActions.exportAll({
        from: startDate,
        to: endDate,
        orderStatus,
        questionType,
      })
    );
  }, [dispatch, startDate, endDate, orderStatus, questionType]);

  const handleGoToOrderDetails = (orderID) => () => {
    history.push(`/admin/orders/${orderID}`);
  };

  useEffect(() => {
    handleGetReport();
  }, [currentPage]);

  useEffect(() => {
    handleGetActivities();
    handleGetStatuses();
  }, []);

  const formattedOrders = useMemo(
    () =>
      orders.map((order) => {
        const activity = activities.find(({ code }) => code === order.activityPrimaryActivity);
        const primaryActivityLabel = activity?.label;
        const specificProductLabel = activity?.specificProducts?.find(
          ({ code }) => code === order.activitySpecificProducts
        )?.label;
        return {
          ...order,
          activityPrimaryActivity: primaryActivityLabel || order.activityPrimaryActivity,
          activitySpecificProducts: specificProductLabel || order.activitySpecificProducts,
        };
      }),
    [orders, activities]
  );

  const paginationItems = useMemo(() => {
    const prevPage = page - 1;
    const multiplicator = Math.floor(prevPage / 5);
    const fromPage = page <= 5 ? 1 : multiplicator * 5 + 1;
    const visiblePages = totalPages - multiplicator * 5 < 5 ? totalPages - multiplicator * 5 : 5;

    return (
      <>
        {page > visiblePages && <Pagination.Ellipsis onClick={handleActivePage(fromPage - 5)} />}
        {Array.from({ length: visiblePages }, (_, i) => {
          return (
            <Pagination.Item
              key={fromPage + i}
              disabled={loading}
              active={page === fromPage + i}
              onClick={handleActivePage(fromPage + i)}
            >
              {fromPage + i}
            </Pagination.Item>
          );
        })}
        {visiblePages === 5 && (multiplicator + 1) * 5 < totalPages && (
          <Pagination.Ellipsis onClick={handleActivePage(fromPage + 5)} />
        )}
      </>
    );
  }, [page, totalPages, loading]);

  return (
    <>
      <Container fluid className="mt-5 py-4">
        <Card className="p-2">
          <Row>
            <Col>
              <Form>
                <Form.Row>
                  <Form.Group as={Col} md>
                    <Form.Label>Start Date:</Form.Label>
                    <Form.Control
                      size="sm"
                      type="date"
                      name="startDate"
                      max={moment().format("YYYY-MM-DD")}
                      value={startDate}
                      onChange={handleStartDate}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md>
                    <Form.Label>End Date:</Form.Label>
                    <Form.Control
                      size="sm"
                      type="date"
                      name="endDate"
                      max={moment().format("YYYY-MM-DD")}
                      value={endDate}
                      onChange={handleEndDate}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md>
                    <Form.Label>Question Type</Form.Label>
                    <Form.Control size="sm" as="select" value={questionType} onChange={handleQuestionType}>
                      <option value="">Select Type</option>
                      {QUESTION_TYPES.map(({ key, label }) => (
                        <option key={key} value={key}>
                          {label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} md>
                    <Form.Label>Payment Status</Form.Label>
                    <Form.Control size="sm" as="select" value={orderStatus} onChange={handleStatus}>
                      <option value="">Select Status</option>
                      {statuses.map(({ code, value }) => (
                        <option key={code} value={code}>
                          {value}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
              </Form>
            </Col>

            <Col md={3} style={{ display: "flex", alignItems: "center" }}>
              <Button size="sm" className="mr-2" isLoading={loading} onClick={handleGetReport}>
                Filter
              </Button>
              <Button size="sm" className="mr-2" disabled={!formattedOrders.length} onClick={handleExportReport}>
                Export CSV
              </Button>
            </Col>
          </Row>
        </Card>

        <Card>
          <Table responsive>
            <thead>
              <tr>
                {EIN_PAYMENT_COLUMNS.map(({ key, label }) => (
                  <th key={key}>{label}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {!loading && (
                <>
                  {formattedOrders.map((row) => (
                    <tr key={row.orderUid}>
                      {EIN_PAYMENT_COLUMNS.map(({ key }) => (
                        <td key={`${row.orderUid}:${key}`} onClick={handleGoToOrderDetails(row.orderUid)}>
                          {row[key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                  {!formattedOrders.length && (
                    <tr>
                      <td>No Results</td>
                    </tr>
                  )}
                </>
              )}
            </tbody>

            {!!totalPages && (
              <Pagination className="ml-2">
                <Pagination.First disabled={page === 1 || loading} onClick={handleFirstPage} />
                <Pagination.Prev disabled={page === 1 || loading} onClick={handlePrevPage} />
                {paginationItems}
                <Pagination.Next disabled={page === totalPages || loading} onClick={handleNextPage} />
                <Pagination.Last disabled={page === totalPages || loading} onClick={handleLastPage} />
              </Pagination>
            )}
          </Table>

          {!!loading && <Spinner />}
        </Card>
      </Container>
    </>
  );
}
