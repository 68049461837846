import React, { useState } from "react";
import { Form, Row } from "react-bootstrap";
import clsx from "clsx";

import { phoneFormatter } from "_helpers";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";
import ActivityIncView from "components/OrderDetails/ProductDetails/Incorporation/Activity/View";
import DateView from "components/OrderDetails/ProductDetails/Incorporation/Date/View";

import StatusView from "../Status/View";

import { state_array } from "variables/Variables";

function FormLLCInc({ orderDetails, selectedProduct }) {
  const [membersCollapsed, setMembersCollapsed] = useState(true);

  const productDetails = orderDetails?.products[selectedProduct];

  const getManagedBy = () => {
    if (!productDetails?.managedByManagers && !productDetails?.managedByMembers) return "";
    if (productDetails?.managedByManagers && productDetails?.managedByMembers) return "Members & Managers";
    return productDetails?.managedByMembers ? "Members" : "Managers";
  };

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">LLC Inc Corporation</h4>

      <StatusView orderDetails={orderDetails} selectedProduct={selectedProduct} />

      <FormPDF productDetails={productDetails} />

      <hr />

      <DateView label="Incorporated Date:" date={productDetails?.incorporatedDate} />

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Limited Liability Company Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.companyName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Alternate Company Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.altCompanyName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Designator:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.designator || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Organized State:
        </Form.Label>
        <Form.Label column sm={7}>
          {state_array[productDetails?.organizedState] || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Was BOI offer declined by customer?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.boiWasDeclinedByCustomer ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Was Membership blocked for customer?
        </Form.Label>
        <Form.Label column sm={7} className={clsx({ "text-danger": productDetails?.membershipBlocked })}>
          {productDetails?.membershipBlocked ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Managed By:
        </Form.Label>
        <Form.Label column sm={7}>
          {getManagedBy() || "N/A"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5} style={{ cursor: "pointer" }} onClick={() => setMembersCollapsed(!membersCollapsed)}>
          <i className={`fa fa-caret-${membersCollapsed ? "down" : "up"}`} />
          {!getManagedBy() ? "Members" : getManagedBy()}:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.members?.length}
        </Form.Label>

        {!membersCollapsed && (
          <Form.Label column sm={12}>
            {productDetails.members.map((member, idx) => (
              <div
                key={`${idx}-${member.name}`}
                className="d-flex mb-2 p-1"
                style={{ border: "1px solid gray", backgroundColor: "#fafafa", borderRadius: "8px" }}
              >
                <div style={{ flex: "1", color: "gray" }}>#{idx + 1}</div>
                <div style={{ flex: "11" }}>{member.name}</div>
              </div>
            ))}
          </Form.Label>
        )}
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Primary Activity</h6>
      <ActivityIncView productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">General Questions</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Is this your first LLC?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.questionsFirstLLC ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Will you have employees?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.questionsHaveEmployee ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Have you started doing business?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.questionsStartedDoingBusiness ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Will you accept Credit Card Payments?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.acceptCreditCardPayments ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold mt-4">Primary Officer</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.principalOfficer?.firstName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Middle Name (optional):
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.principalOfficer?.middleName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Last Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.principalOfficer?.lastName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Suffix Name (optional):
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.principalOfficer?.suffixName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Phone:
        </Form.Label>
        <Form.Label column sm={7}>
          {phoneFormatter(productDetails?.principalOfficer?.phone) || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          E-mail:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.principalOfficer?.email || "—"}
        </Form.Label>
      </Form.Group>

      {/* <h6 className="font-weight-bold mt-4">Owner (incorpify)</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.owner?.firstName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Middle Name (optional):
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.owner?.middleName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Last Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.owner?.lastName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Suffix Name (optional):
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.owner?.suffixName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Title:
        </Form.Label>
        <Form.Label column sm={7}>
          {EIN_title_array[productDetails?.owner?.title] || "—"}
        </Form.Label>
      </Form.Group> 
      
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Previous First Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.owner?.prevFirstName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Previous Last Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.owner?.prevLastName || "—"}
        </Form.Label>
      </Form.Group>*/}

      <hr />
      <h6 className="font-weight-bold mt-4">Corporate Address</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.corporateAddress?.address1 || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address 2:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.corporateAddress?.address2 || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          City:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.corporateAddress?.city || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          State:
        </Form.Label>
        <Form.Label column sm={7}>
          {state_array[productDetails?.corporateAddress?.state] || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Zip:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.corporateAddress?.zip || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          County:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.corporateAddress?.county || "—"}
        </Form.Label>
      </Form.Group>

      <h6 className="font-weight-bold mt-4">Mailing Address</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.mailingAddress?.address1 || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address 2:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.mailingAddress?.address2 || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          City:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.mailingAddress?.city || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          State:
        </Form.Label>
        <Form.Label column sm={7}>
          {state_array[productDetails?.mailingAddress?.state] || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Zip:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.mailingAddress?.zip || "—"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          County:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.mailingAddress?.county || "—"}
        </Form.Label>
      </Form.Group>
    </div>
  );
}

export default FormLLCInc;
