import { reportsProductsProcessingConstants } from "_constants";

const initialState = {
  loading: 0,
  productsProcessing: [],
};

export function reportsProductsProcessing(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case reportsProductsProcessingConstants.GET_REPORTS_PRODUCTS_PROCESSING_REQUEST:
      return { ...state, loading: state.loading + 1, productsProcessing: [] };

    case reportsProductsProcessingConstants.GET_REPORTS_PRODUCTS_PROCESSING_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1, productsProcessing: payload };

    case reportsProductsProcessingConstants.GET_REPORTS_PRODUCTS_PROCESSING_FAILURE:
      return { ...state, loading: state.loading && state.loading - 1, productsProcessing: [] };

    default:
      return state;
  }
}
