import { Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { phoneFormatter } from "_helpers";
import { customerActions } from "_actions";

import IndividualAddress from "./IndividualAddress/View";

const InfoField = ({ label, data = "—", noColon = false, children = null, disabled = false }) => {
  return (
    <Form.Group as={Row}>
      <Form.Label column sm={5}>
        {label}
        {!noColon && ":"}
      </Form.Label>
      {children ? (
        children
      ) : (
        <Form.Label column sm={7} disabled={disabled}>
          {data}
        </Form.Label>
      )}
    </Form.Group>
  );
};

export default function FormTrademarkSummary({ orderDetails, selectedProduct }) {
  const dispatch = useDispatch();

  const productDetails = orderDetails.products[selectedProduct];
  const customer = orderDetails.customer;
  const individual = productDetails.individual;
  const organization = productDetails.organization;
  const classes = productDetails.classes;

  const orderId = orderDetails.order.id;
  const productId = productDetails.id;

  const isOrganization = !!organization;
  const isLogo = productDetails.trademarkType === "LOGO";

  const handleDownloadProductResultPDF = (fileType, filename) =>
    dispatch(customerActions.downloadProductPDF(orderId, productId, fileType, filename));

  function fileListRenderer() {
    const filesObj = productDetails.processingResult.files;

    return Object.keys(filesObj)
      .filter((key) => key.startsWith("TRADEMARK_SPECIMEN"))
      .map((key) => (
        <Row key={`${key}-${filesObj[key]}`}>
          <Form.Label column sm="7">
            {key}
          </Form.Label>

          <Col sm="5">
            <Button
              variant="primary"
              size="sm"
              onClick={() => handleDownloadProductResultPDF(key, filesObj[key]?.ourFileName)}
            >
              Download
            </Button>
          </Col>
        </Row>
      ));
  }

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">TRADEMARK SUMMARY</h4>

      <hr />

      <h6 className="font-weight-bold">Entity Information</h6>
      <InfoField label="Entity Type" data={isOrganization ? organization.type : "Individual"} />
      {isOrganization && (
        <>
          <InfoField label="Organization Name" data={organization?.name} />
          <InfoField label="State of Formation" data={organization?.stateOrCountryOfFormation} />
        </>
      )}
      <InfoField label="First Name" data={individual?.firstName} />
      <InfoField label="Last Name" data={individual?.lastName} />
      <InfoField label="Entity Phone Number" data={""}>
        <Form.Label column sm={7}>
          {phoneFormatter(individual?.phone) || "—"}
        </Form.Label>
      </InfoField>
      <InfoField label="Entity Email" data={individual?.email} />
      <InfoField label="Entity Mailing Address" data={individual && ""} />
      {individual && (
        <Form.Group className="pl-4">
          <IndividualAddress address={individual.address} />
        </Form.Group>
      )}

      <hr />

      <h6 className="font-weight-bold">Customer Information</h6>
      <InfoField label="Customer's Phone Number" data={""}>
        <Form.Label column sm={7}>
          {phoneFormatter(customer?.phone) || "—"}
        </Form.Label>
      </InfoField>
      <InfoField label="Customer's Email" data={customer?.email} />
      <InfoField label="Customer's Mailing Address" data={customer && ""} />
      {customer && (
        <Form.Group className="pl-4">
          <IndividualAddress address={customer.billingAddress} />
        </Form.Group>
      )}

      <hr />

      <h6 className="font-weight-bold">Mark Information</h6>
      <InfoField label="Trademark Type" data={productDetails.trademarkType} />
      <InfoField label="Color Claim" data={isLogo ? (productDetails.logoColoredVariety ? "Yes" : "No") : "—"} />
      <InfoField label="Logo File" data={isLogo ? productDetails.literalElements : "—"} disabled={!isLogo} />
      <InfoField
        noColon
        label="Words other than English or non-Latin characters?"
        data={productDetails.includeTranslationWords ? "Yes" : "No"}
      />
      <InfoField
        label="English Translation"
        data={productDetails.includeTranslationWords ? productDetails.translation : "—"}
      />
      <InfoField label="Trademark Current Use in Business" data={productDetails.currentTrademark ? "Yes" : "No"} />
      <InfoField label="First Use Anywhere" data={productDetails.firstUseAnywhere || "—"} />
      <InfoField label="First Use in Commerce" data={productDetails.firstUseInCommerce || "—"} />
      <InfoField label="Specimen File" data={""}>
        <Col sm={7}>
          {productDetails.processingResult.files && Object.keys(productDetails.processingResult.files).length ? (
            fileListRenderer()
          ) : (
            <Form.Label column sm={5} className="pl-0">
              No files.
            </Form.Label>
          )}
        </Col>
      </InfoField>
      <InfoField label="Specimen Description" data={productDetails.specimenDescription} />
      <InfoField label="Docket/Reference Number" data={orderId} />

      <hr />

      <h6 className="font-weight-bold">Trademark Classes</h6>
      <Col className="m-0">
        {classes && classes.length > 0 ? (
          classes.map(({ goodsCategory, goodsServiceDetail }, idx) => (
            <Col key={idx} className="pl-4 p-0">
              <InfoField label="Trademark Class" data={goodsCategory} />
              <InfoField label="Trademark Class Description" data={goodsServiceDetail} />
              {classes.length - 1 !== idx && <hr style={{ borderColor: "#edf0f3" }} />}
            </Col>
          ))
        ) : (
          <Form.Group as={Col}>
            <Form.Label column sm={5} className="pl-4">
              No Classes
            </Form.Label>
          </Form.Group>
        )}
      </Col>
    </div>
  );
}
