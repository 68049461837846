import { thirdpartylogsConstants } from '../_constants';

export function thirdpartylogs(state = {}, action) {
  switch (action.type) {
    case thirdpartylogsConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case thirdpartylogsConstants.GETALL_SUCCESS:
      return {
        items: action.result,
      };
    case thirdpartylogsConstants.GETALL_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
