import { customerConstants } from '../_constants';

const initial_state = []

export function primaryactivity(state = initial_state, action) {
  switch (action.type) {
    case customerConstants.GETPRIMARYACTIVITY_SUCCESS:
      return action.activity
    default:
      return state;
  }
}
