import { offerConstants } from "_constants";

const initialState = {
  list: [],
  listByClicks: [],
  error: "",
  status: "idle",
};

export default function offer(state = initialState, action) {
  switch (action.type) {
    case offerConstants.GET_OFFER_REQUEST:
      return { ...state, status: "loading" };
    case offerConstants.GET_OFFER_SUCCESS:
      return { ...state, status: "succeeded", list: action.offers };
    case offerConstants.GET_OFFER_FAIL:
      return { ...state, status: "failed", error: action.message };
    case offerConstants.GET_OFFER_CLICKS_REQUEST:
      return { ...state, status: "loading" };
    case offerConstants.GET_OFFER_CLICKS_SUCCESS:
      return { ...state, status: "succeeded", listByClicks: action.offers };
    case offerConstants.GET_OFFER_CLICKS_FAIL:
      return { ...state, status: "failed", error: action.message };
    case offerConstants.ADD_OFFER_SUCCESS:
      return { ...state, status: "succeeded" };
    case offerConstants.ADD_OFFER_FAIL:
      return { ...state, status: "failed", error: action.message };
    case offerConstants.RESET_OFFER_STATE:
      return initialState;
    default:
      return state;
  }
}
