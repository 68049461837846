import { config } from "_config";
import { authHeader, handleResponse } from "_helpers";

export const ZendeskService = {
  getticket,
  unlinkticket,
  get_zendesk_account,
  add_zendesk_account,
  update_zendesk_account,
};

function getticket(id) {
  const requestOptions = {
    method: "GET",
    headers: { Authorization: "Bearer " + localStorage.getItem("zauth") },
  };

  let url = `${config.zendesk_url}/api/v2/tickets/${id}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function get_zendesk_account(status) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/zendesk/accounts?active=${status}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function add_zendesk_account(account) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(account),
  };

  let url = `${config.apiUrl1}/zendesk/accounts`;
  return fetch(url, requestOptions).then(handleResponse);
}

function update_zendesk_account(account) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(account),
  };

  let url = `${config.apiUrl1}/zendesk/accounts/${account.id}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function unlinkticket(id) {
  const requestOptions = {
    method: "delete",
    headers: authHeader(),
  };

  let url = `${config.apiUrl1}/zendesk/tickets/${id}`;
  return fetch(url, requestOptions).then(handleResponse);
}
