import { Row, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import { includedName } from "constants/trademarks.constants";

import { alertActions } from "_actions";

import CoOwnerListEdit from "../CoOwnerList/Edit";
import IndividualEdit from "../Individual/Edit";
import OrganizationEdit from "../Organization/Edit";
import TrademarkClassesListEdit from "../TrademarkClassesList/EditAsync";

import StatusEdit from "../../Status/Edit";

import { TAB_CODES } from "variables/Variables";

export default function FormTrademarkRegister({
  validated,
  orderDetails,
  selectedProduct,
  availableProcessingStatus,
  initOrderDetails,
  isDirty,
  onError,
  onChange,
  ...props
}) {
  const dispatch = useDispatch();

  const orderId = orderDetails.order.id;
  const productDetails = orderDetails.products[selectedProduct];
  const isOrganization = !!productDetails.organization;

  const isLogo = productDetails.trademarkType === "LOGO";

  const handleAddError = (field) => onError(TAB_CODES.trademarkInfo, field);

  const handleProductChange = ({ target: { name, value } }) => {
    let exFields = { [name]: value };

    if (name === "includedName" && value === "NONE") exFields = { ...exFields, includedNameValue: "" };

    if (name === "trademarkType" && value !== "LOGO")
      exFields = { ...exFields, logoColoredVariety: false, colorsInLogo: "", literalElements: "" };

    if (name === "logoColoredVariety") {
      exFields = { [name]: value === "yes" };

      if (value === "yes") exFields = { ...exFields, colorsInLogo: "" };
    }

    if (name === "includeTranslationWords") {
      exFields = { [name]: value === "yes" };

      if (value !== "yes") exFields = { ...exFields, translation: "" };
    }

    if (name === "currentTrademark") {
      exFields = { [name]: value === "yes" };

      if (value !== "yes") {
        exFields = { ...exFields, firstUseAnywhere: null, firstUseInCommerce: null };

        const automationStatuses = ["AwaitingSpecimen", "SearchSentToCustForReview"];
        if (automationStatuses.includes(availableProcessingStatus.currentStatus.statusCode)) {
          dispatch(alertActions.info('Notice: Filing Status changed to "Legal Team Review"'));
          exFields = {
            ...exFields,
            processingResult: { ...productDetails.processingResult, status: "AttorneyReview" },
          };
        }
      }
    }

    if (name === "identifyOwner") {
      exFields = { organization: value === "Organization" ? true : null };

      if (value === "Organization") exFields = { ...exFields, coOwners: [] };
    }

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, ...exFields } : product
      ),
    });
  };

  const handleProductProcessingResultChange = ({ target: { name, value } }) =>
    handleProductChange({
      target: { name: "processingResult", value: { ...productDetails.processingResult, [name]: value } },
    });

  const handleProductCoOwnersChange = (value) => handleProductChange({ target: { name: "coOwners", value } });
  const handleProductClassesChange = (value) => handleProductChange({ target: { name: "classes", value } });
  const handleProductOrganizationChange = (value) => handleProductChange({ target: { name: "organization", value } });
  const handleProductIndividualChange = (value) => handleProductChange({ target: { name: "individual", value } });

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">REGISTER TRADEMARK</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
      />

      <FormPDF productDetails={productDetails} {...props} />

      <hr />
      <h6 className="font-weight-bold">Processing Result</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Serial number:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="serialNumber"
            value={productDetails.processingResult.serialNumber || ""}
            onChange={handleProductProcessingResultChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Registration number:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="registrationNumber"
            value={productDetails.processingResult.registrationNumber || ""}
            onChange={handleProductProcessingResultChange}
          />
        </Col>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Mark Information</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Trademark Type:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            as="select"
            size="sm"
            name="trademarkType"
            value={productDetails.trademarkType || ""}
            onChange={handleProductChange}
          >
            <option value="NAME">Name</option>
            <option value="SLOGAN">Slogan</option>
            <option value="LOGO">Logo</option>
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Trademark Description:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="markDesc"
            value={productDetails.markDesc || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Trademark Description")}
          />
        </Col>
      </Form.Group>

      {isLogo && (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Do you want to be able to protect your logo in a variety of colors?
            </Form.Label>
            <Col sm={7} className="d-flex align-items-center">
              <Form.Check
                id="logoColoredVariety_r_no"
                type="radio"
                label="no"
                name="logoColoredVariety"
                className="mr-3"
                disabled={!isLogo}
                value="no"
                checked={!productDetails.logoColoredVariety}
                onChange={handleProductChange}
              />
              <Form.Check
                id="logoColoredVariety_r_yes"
                type="radio"
                label="yes"
                name="logoColoredVariety"
                disabled={!isLogo}
                value="yes"
                checked={productDetails.logoColoredVariety}
                onChange={handleProductChange}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Colors list:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                size="sm"
                type="text"
                name="colorsInLogo"
                disabled={!isLogo || productDetails.logoColoredVariety}
                value={productDetails.colorsInLogo || ""}
                onChange={handleProductChange}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Logo:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="literalElements"
                disabled={!isLogo}
                value={productDetails.literalElements || ""}
                onChange={handleProductChange}
                onInvalid={() => handleAddError("Logo")}
              />
            </Col>
          </Form.Group>
        </>
      )}

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Include Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            as="select"
            size="sm"
            name="includedName"
            value={productDetails.includedName}
            onChange={handleProductChange}
          >
            {Object.keys(includedName).map((key) => (
              <option key={key} value={key}>
                {includedName[key]}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Name:
        </Form.Label>
        <Col sm={7}>
          zz
          <Form.Control
            required
            size="sm"
            type="text"
            name="includedNameValue"
            disabled={productDetails.includedName === "NONE"}
            value={productDetails.includedNameValue || ""}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Does your mark include any words other than English or non-Latin characters?
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="includeTranslationWords_r_no"
            className="mr-3"
            type="radio"
            label="No"
            name="includeTranslationWords"
            value="no"
            defaultChecked={!productDetails.includeTranslationWords}
            onChange={handleProductChange}
          />
          <Form.Check
            id="includeTranslationWords_r_yes"
            type="radio"
            label="Yes"
            name="includeTranslationWords"
            value="yes"
            defaultChecked={productDetails.includeTranslationWords}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          English translation:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="translation"
            disabled={!productDetails.includeTranslationWords}
            value={productDetails.translation || ""}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Are you currently using this trademark in your business?
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="currentTrademark_r_no"
            className="mr-3"
            type="radio"
            label="No"
            name="currentTrademark"
            value="no"
            defaultChecked={!productDetails.currentTrademark}
            onChange={handleProductChange}
          />
          <Form.Check
            id="currentTrademark_r_yes"
            type="radio"
            label="Yes"
            name="currentTrademark"
            value="yes"
            defaultChecked={productDetails.currentTrademark}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm="5">
          First use anywhere:
        </Form.Label>
        <Col sm="7">
          <Form.Control
            type="date"
            name="firstUseAnywhere"
            max={moment().format("YYYY-MM-DD")}
            disabled={!productDetails.currentTrademark}
            value={productDetails.firstUseAnywhere || ""}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm="5">
          First use in commerce:
        </Form.Label>
        <Col sm="7">
          <Form.Control
            type="date"
            name="firstUseInCommerce"
            max={moment().format("YYYY-MM-DD")}
            disabled={!productDetails.currentTrademark}
            value={productDetails.firstUseInCommerce || ""}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Website:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="website"
            value={productDetails.website || ""}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Specimen Description:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="specimenDescription"
            value={productDetails.specimenDescription || ""}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <hr />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Identify the owner of the trademark
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="identifyOwner_r_no"
            className="mr-3"
            type="radio"
            label="Individual"
            name="identifyOwner"
            value="Individual"
            defaultChecked={!productDetails.organization}
            onChange={handleProductChange}
          />
          <Form.Check
            id="identifyOwner_r_yes"
            type="radio"
            label="Organization"
            name="identifyOwner"
            value="Organization"
            defaultChecked={productDetails.organization}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <OrganizationEdit
        hidden={!isOrganization}
        organization={productDetails.organization}
        onError={handleAddError}
        onChange={handleProductOrganizationChange}
      />
      <IndividualEdit
        individual={productDetails.individual}
        onError={handleAddError}
        onChange={handleProductIndividualChange}
      />

      <CoOwnerListEdit
        hidden={isOrganization}
        coOwners={productDetails.coOwners}
        onError={handleAddError}
        onChange={handleProductCoOwnersChange}
      />

      <TrademarkClassesListEdit
        validated={validated}
        label="Trademark Classes"
        classes={productDetails.classes}
        onError={handleAddError}
        onChange={handleProductClassesChange}
      />
    </div>
  );
}
