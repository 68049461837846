import { offerGroupConstants } from "_constants";
import { offerGroupService } from "../_services";

function getAll() {
  return (dispatch) => {
    dispatch(request());

    offerGroupService.getAll().then(
      (result) => dispatch(success(result)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: offerGroupConstants.GET_OFFERGROUP_REQUEST };
  }
  function success(offers) {
    return { type: offerGroupConstants.GET_OFFERGROUP_SUCCESS, data: offers };
  }
  function failure(message) {
    return { type: offerGroupConstants.GET_OFFERGROUP_FAIL, message };
  }
}

function addGroup(form) {
  return (dispatch) => {
    return offerGroupService.addGroup(form).then(
      (result) => dispatch(success(result)),
      (error) => {
        dispatch(failure(error.message));
        return Promise.reject(error);
      }
    );
  };

  function success() {
    return { type: offerGroupConstants.ADD_OFFERGROUP_SUCCESS };
  }
  function failure(message) {
    return { type: offerGroupConstants.ADD_OFFERGROUP_FAIL, message };
  }
}

function editGroup(form, uid) {
  return (dispatch) => {
    return offerGroupService.editGroup(form, uid).then(
      (result) => {
        dispatch(success(result));
        return Promise.resolve(result);
      },
      (error) => {
        dispatch(failure(error.message));
        return Promise.reject(error);
      }
    );
  };

  function success() {
    return { type: offerGroupConstants.ADD_OFFERGROUP_SUCCESS };
  }
  function failure(message) {
    return { type: offerGroupConstants.ADD_OFFERGROUP_FAIL, message };
  }
}

function deleteGroup(uid) {
  return (dispatch) => {
    return offerGroupService.deleteGroup(uid).then(
      (result) => dispatch(success(result)),
      (error) => {
        dispatch(failure(error.message));
        return Promise.reject(error);
      }
    );
  };

  function success() {
    return { type: offerGroupConstants.DELETE_OFFERGROUP_SUCCESS };
  }
  function failure(message) {
    return { type: offerGroupConstants.DELETE_OFFERGROUP_FAIL, message };
  }
}

export const offerGroupsActions = {
  getAll,
  addGroup,
  editGroup,
  deleteGroup,
};
