import { Col, Form, Row } from "react-bootstrap";

import StatusEdit from "../../Status/Edit";

import { TAB_CODES } from "variables/Variables";

export default function FormIncorpifyBusinessNameAmendment({ orderDetails, selectedProduct, onChange, onError }) {
  const productDetails = orderDetails?.products[selectedProduct];

  const handleAddError = (field) => onError(TAB_CODES.amendmentInfo, field);

  const handleProductChange = ({ target: { name, value } }) => {
    let exFields = { [name]: value };

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, ...exFields } : product
      ),
    });
  };

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">AMENDMENT</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
      />

      <hr />
      <h6 className="font-weight-bold mb-4">Business Name Amendment Details</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          New Business Name:
        </Form.Label>

        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="newCompanyName"
            value={productDetails?.newCompanyName || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("New Company Name")}
          />
        </Col>
      </Form.Group>
    </div>
  );
}
