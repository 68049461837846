import { reportsEINPaymentConstants } from "_constants";
import { reportsEINPaymentService } from "_services";

import { alertActions } from ".";

export const reportsEINPaymentActions = { getAll, exportAll };

function getAll(params) {
  return (dispatch) => {
    dispatch(request());

    reportsEINPaymentService.getAll(params).then(
      ({ elements, ...restKeys }) => dispatch(success({ orders: elements, ...restKeys })),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: reportsEINPaymentConstants.GET_REPORTS_EIN_PAYMENT_REQUEST };
  }
  function success(payload) {
    return { type: reportsEINPaymentConstants.GET_REPORTS_EIN_PAYMENT_SUCCESS, payload };
  }
  function failure() {
    return { type: reportsEINPaymentConstants.GET_REPORTS_EIN_PAYMENT_FAILURE };
  }
}

function exportAll(params) {
  return (dispatch) => {
    reportsEINPaymentService.exportAll(params)
      .then(
        (result) => {},
        (error) => {},
      );
  };
}
