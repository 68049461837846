export const variantsConstants = {
  GET_CATEGORY_REQUEST: "GET_CATEGORY_REQUEST",
  GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
  GET_CATEGORY_FAILURE: "GET_CATEGORY_FAILURE",

  GET_PRODUCT_TYPE_REQUEST: "GET_PRODUCT_TYPE_REQUEST",
  GET_PRODUCT_TYPE_SUCCESS: "GET_PRODUCT_TYPE_SUCCESS",
  GET_PRODUCT_TYPE_FAILURE: "GET_PRODUCT_TYPE_FAILURE",

  GET_PRODUCT_CODE_REQUEST: "GET_PRODUCT_CODE_REQUEST",
  GET_PRODUCT_CODE_SUCCESS: "GET_PRODUCT_CODE_SUCCESS",
  GET_PRODUCT_CODE_FAILURE: "GET_PRODUCT_CODE_FAILURE",

  GET_PRODUCT_CODE_SALE_REQUEST: "GET_PRODUCT_CODE_SALE_REQUEST",
  GET_PRODUCT_CODE_SALE_SUCCESS: "GET_PRODUCT_CODE_SALE_SUCCESS",
  GET_PRODUCT_CODE_SALE_FAILURE: "GET_PRODUCT_CODE_SALE_FAILURE",

  GET_PROCESSING_STATUS_REQUEST: "GET_PROCESSING_STATUS_REQUEST",
  GET_PROCESSING_STATUS_SUCCESS: "GET_PROCESSING_STATUS_SUCCESS",
  GET_PROCESSING_STATUS_FAILURE: "GET_PROCESSING_STATUS_FAILURE",

  GET_PRODUCT_PROCESSING_STATUSES_REQUEST: "GET_PRODUCT_PROCESSING_STATUSES_REQUEST",
  GET_PRODUCT_PROCESSING_STATUSES_SUCCESS: "GET_PRODUCT_PROCESSING_STATUSES_SUCCESS",
  GET_PRODUCT_PROCESSING_STATUSES_FAILURE: "GET_PRODUCT_PROCESSING_STATUSES_FAILURE",

  GET_PRODUCTS_PROCESSING_STATUSES_REQUEST: "GET_PRODUCTS_PROCESSING_STATUSES_REQUEST",
  GET_PRODUCTS_PROCESSING_STATUSES_SUCCESS: "GET_PRODUCTS_PROCESSING_STATUSES_SUCCESS",
  GET_PRODUCTS_PROCESSING_STATUSES_FAILURE: "GET_PRODUCTS_PROCESSING_STATUSES_FAILURE",

  GET_PROCESSING_STATUS_2_REQUEST: "GET_PROCESSING_STATUS_2_REQUEST",
  GET_PROCESSING_STATUS_2_SUCCESS: "GET_PROCESSING_STATUS_2_SUCCESS",
  GET_PROCESSING_STATUS_2_FAILURE: "GET_PROCESSING_STATUS_2_FAILURE",

  GET_PRODUCTS_PROCESSING_STATUS_REQUEST: "GET_PRODUCTS_PROCESSING_STATUS_REQUEST",
  GET_PRODUCTS_PROCESSING_STATUS_SUCCESS: "GET_PRODUCTS_PROCESSING_STATUS_SUCCESS",
  GET_PRODUCTS_PROCESSING_STATUS_FAILURE: "GET_PRODUCTS_PROCESSING_STATUS_FAILURE",

  GET_PROCESSING_ERROR_REQUEST: "GET_PROCESSING_ERROR_REQUEST",
  GET_PROCESSING_ERROR_SUCCESS: "GET_PROCESSING_ERROR_SUCCESS",
  GET_PROCESSING_ERROR_FAILURE: "GET_PROCESSING_ERROR_FAILURE",

  GET_PROCESSING_ERROR_STATUS_REQUEST: "GET_PROCESSING_ERROR_STATUS_REQUEST",
  GET_PROCESSING_ERROR_STATUS_SUCCESS: "GET_PROCESSING_ERROR_STATUS_SUCCESS",
  GET_PROCESSING_ERROR_STATUS_FAILURE: "GET_PROCESSING_ERROR_STATUS_FAILURE",

  GET_ORDER_STATUS_REQUEST: "GET_ORDER_STATUS_REQUEST",
  GET_ORDER_STATUS_SUCCESS: "GET_ORDER_STATUS_SUCCESS",
  GET_ORDER_STATUS_FAILURE: "GET_ORDER_STATUS_FAILURE",

  GET_STATES_REQUEST: "GET_STATES_REQUEST",
  GET_STATES_SUCCESS: "GET_STATES_SUCCESS",
  GET_STATES_FAILURE: "GET_STATES_FAILURE",

  GET_INTERNATIONAL_CODES_REQUEST: "GET_INTERNATIONAL_CODES_REQUEST",
  GET_INTERNATIONAL_CODES_SUCCESS: "GET_INTERNATIONAL_CODES_SUCCESS",
  GET_INTERNATIONAL_CODES_FAILURE: "GET_INTERNATIONAL_CODES_FAILURE",

  GET_US_CODES_REQUEST: "GET_US_CODES_REQUEST",
  GET_US_CODES_SUCCESS: "GET_US_CODES_SUCCESS",
  GET_US_CODES_FAILURE: "GET_US_CODES_FAILURE",

  GET_NATIONALITIES_REQUEST: "GET_NATIONALITIES_REQUEST",
  GET_NATIONALITIES_SUCCESS: "GET_NATIONALITIES_SUCCESS",
  GET_NATIONALITIES_FAILURE: "GET_NATIONALITIES_FAILURE",

  GET_PRODUCT_FILE_TYPES_REQUEST: "GET_PRODUCT_FILE_TYPES_REQUEST",
  GET_PRODUCT_FILE_TYPES_SUCCESS: "GET_PRODUCT_FILE_TYPES_SUCCESS",
  GET_PRODUCT_FILE_TYPES_FAILURE: "GET_PRODUCT_FILE_TYPES_FAILURE",

  GET_PROCESSING_OPTIONS_REQUEST: "GET_PROCESSING_OPTIONS_REQUEST",
  GET_PROCESSING_OPTIONS_SUCCESS: "GET_PROCESSING_OPTIONS_SUCCESS",
  GET_PROCESSING_OPTIONS_FAILURE: "GET_PROCESSING_OPTIONS_FAILURE",

  GET_ACTIVITIES_REQUEST: "GET_ACTIVITIES_REQUEST",
  GET_ACTIVITIES_SUCCESS: "GET_ACTIVITIES_SUCCESS",
  GET_ACTIVITIES_FAILURE: "GET_ACTIVITIES_FAILURE",

  GET_PRICES_REQUEST: "GET_PRICES_REQUEST",
  GET_PRICES_SUCCESS: "GET_PRICES_SUCCESS",
  GET_PRICES_FAILURE: "GET_PRICES_FAILURE",

  GET_PROCESSING_STATUSES_BY_CATEGORIES_REQUEST: "GET_PROCESSING_STATUSES_BY_CATEGORIES_REQUEST",
  GET_PROCESSING_STATUSES_BY_CATEGORIES_REQUEST_2: "GET_PROCESSING_STATUSES_BY_CATEGORIES_REQUEST_2",
  GET_PROCESSING_STATUSES_BY_CATEGORIES_SUCCESS: "GET_PROCESSING_STATUSES_BY_CATEGORIES_SUCCESS",
  GET_PROCESSING_STATUSES_BY_CATEGORIES_SUCCESS_2: "GET_PROCESSING_STATUSES_BY_CATEGORIES_SUCCESS_2",
  GET_PROCESSING_STATUSES_BY_CATEGORIES_FAILURE: "GET_PROCESSING_STATUSES_BY_CATEGORIES_FAILURE",
  GET_PROCESSING_STATUSES_BY_CATEGORIES_FAILURE_2: "GET_PROCESSING_STATUSES_BY_CATEGORIES_FAILURE_2",

  GET_PROCESSING_ERROR_STATUSES_BY_CATEGORIES_REQUEST: "GET_PROCESSING_ERROR_STATUSES_BY_CATEGORIES_REQUEST",
  GET_PROCESSING_ERROR_STATUSES_BY_CATEGORIES_SUCCESS: "GET_PROCESSING_ERROR_STATUSES_BY_CATEGORIES_SUCCESS",
  GET_PROCESSING_ERROR_STATUSES_BY_CATEGORIES_FAILURE: "GET_PROCESSING_ERROR_STATUSES_BY_CATEGORIES_FAILURE",

  // by products
  GET_ALL_PRODUCT_CODES_REQUEST: "GET_ALL_PRODUCT_CODES_REQUEST",
  GET_ALL_PRODUCT_CODES_SUCCESS: "GET_ALL_PRODUCT_CODES_SUCCESS",
  GET_ALL_PRODUCT_CODES_FAILURE: "GET_ALL_PRODUCT_CODES_FAILURE",

  GET_ALL_PROCESSING_STATUSES_REQUEST: "GET_ALL_PROCESSING_STATUSES_REQUEST",
  GET_ALL_PROCESSING_STATUSES_SUCCESS: "GET_ALL_PROCESSING_STATUSES_SUCCESS",
  GET_ALL_PROCESSING_STATUSES_FAILURE: "GET_ALL_PROCESSING_STATUSES_FAILURE",

  GET_ALL_PROCESSING_ERRORS_REQUEST: "GET_ALL_PROCESSING_ERRORS_REQUEST",
  GET_ALL_PROCESSING_ERRORS_SUCCESS: "GET_ALL_PROCESSING_ERRORS_SUCCESS",
  GET_ALL_PROCESSING_ERRORS_FAILURE: "GET_ALL_PROCESSING_ERRORS_FAILURE",

  GET_ALL_ORDER_STATUSES_REQUEST: "GET_ALL_ORDER_STATUSES_REQUEST",
  GET_ALL_ORDER_STATUSES_SUCCESS: "GET_ALL_ORDER_STATUSES_SUCCESS",
  GET_ALL_ORDER_STATUSES_FAILURE: "GET_ALL_ORDER_STATUSES_FAILURE",

  GET_ALL_STATES_REQUEST: "GET_ALL_STATES_REQUEST",
  GET_ALL_STATES_SUCCESS: "GET_ALL_STATES_SUCCESS",
  GET_ALL_STATES_FAILURE: "GET_ALL_STATES_FAILURE",

  GET_ALL_PROCESSING_ERROR_STATUSES_REQUEST: "GET_ALL_PROCESSING_ERROR_STATUSES_REQUEST",
  GET_ALL_PROCESSING_ERROR_STATUSES_SUCCESS: "GET_ALL_PROCESSING_ERROR_STATUSES_SUCCESS",
  GET_ALL_PROCESSING_ERROR_STATUSES_FAILURE: "GET_ALL_PROCESSING_ERROR_STATUSES_FAILURE",

  GET_ALL_PROCESSING_OPTIONS_REQUEST: "GET_ALL_PROCESSING_OPTIONS_REQUEST",
  GET_ALL_PROCESSING_OPTIONS_SUCCESS: "GET_ALL_PROCESSING_OPTIONS_SUCCESS",
  GET_ALL_PROCESSING_OPTIONS_FAILURE: "GET_ALL_PROCESSING_OPTIONS_FAILURE",

  GET_ALL_PRICES_REQUEST: "GET_ALL_PRICES_REQUEST",
  GET_ALL_PRICES_SUCCESS: "GET_ALL_PRICES_SUCCESS",
  GET_ALL_PRICES_FAILURE: "GET_ALL_PRICES_FAILURE",
};
