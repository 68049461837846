import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

const StyledLink = styled.a`
  color: black;
  text-decoration: underline;

  &:hover {
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  }

  ${({ short }) =>
    short &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
`;
const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CustomExternalLink = ({ children, short, ...props }) => {
  if (short)
    return (
      <Wrapper>
        <StyledLink {...props}>{children}</StyledLink>
      </Wrapper>
    );

  return <StyledLink {...props}>{children}</StyledLink>;
};

export default CustomExternalLink;

CustomExternalLink.propTypes = {
  children: PropTypes.any,
  short: PropTypes.bool,
};
