import { useEffect, useState } from "react";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { offerGroupsActions, offerActions, alertActions } from "../_actions";

import Card from "components/Card/Card";
import Spinner from "components/Spinner";
import CreateUpdateGroupOfferModal from "components/Offers/CreateUpdateGroupOfferModal";
import CreateUpdateOfferModal from "components/Offers/CreateUpdateOfferModal";

export default function Offers() {
  const dispatch = useDispatch();
  const offerGroupState = useSelector((state) => state.offerGroup);
  const offerState = useSelector((state) => state.offer);
  const [showCreateGroupModal, toggleCreateGroupModal] = useState(false);
  const [showCreateOfferModal, toggleCreateOfferModal] = useState(false);
  const [editingGroup, setEditingGroup] = useState(null);
  const [editingOffer, setEditingOffer] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const thOfferGroups = [
    { code: "name", value: "Group Name" },
    { code: "Action", value: "Action" },
  ];

  const thOffers = [
    { code: "name", value: "Offer Name" },
    { code: "url", value: "URL" },
    { code: "Action", value: "Action" },
  ];

  const handleEditGroup = (group) => {
    setEditingGroup(group);
    toggleCreateGroupModal(true);
  }

  const handleEditOffer = (offer) => {
    setEditingOffer(offer);
    toggleCreateOfferModal(true);
  }

  const handleDeleteGroup = (id) => {
    if(window.confirm('Are you sure?')) {
      dispatch(offerGroupsActions.deleteGroup(id)).then(() => {
        dispatch(alertActions.success('Delete success'));
        dispatch(offerGroupsActions.getAll());
        dispatch(offerActions.getOffers(id));
        (id === selectedGroup?.id) && setSelectedGroup(null);
      }, (err) => {
        dispatch(alertActions.error(err.message));
      });
    }
  }

  const handleDeleteOffer = (data) => {
    if(window.confirm('Are you sure?')) {
      dispatch(offerActions.deleteOffer(data.id, data.groupUid)).then(() => {
        dispatch(alertActions.success('Delete success'));
        dispatch(offerActions.getOffers(data.groupUid));
      }, (err) => {
        dispatch(alertActions.error(err.message));
      });
    }
  }

  const onEditSuccess = (newGroup) => {
    setEditingGroup(null);
    (newGroup.id === selectedGroup?.id) && setSelectedGroup(newGroup);
  }

  const onEditOfferSuccess = () => {
    setEditingOffer(null);
  }

  const renderValueByFieldOfferTbl = (data, field) => {
    if (field === "url") {
      return (
        <Button
          variant="link"
          href={data["url"]}
          className="text-primary"
          target="_blank"
        >
          {data["url"]}
        </Button>
      );
    }

    if (field === "Action") {
      return (
        <>
          <Button variant="warning" className="btn btn-sm mr-1"  onClick={() => handleEditOffer(data)}>
            Edit <i className="fa fa-edit"></i>{' '}
          </Button>
          <Button variant="danger" className="btn btn-sm" onClick={() => handleDeleteOffer(data)}>
            Delete <i className="fa fa-trash"></i>{' '}
          </Button>
        </>
      );
    }

    return data[field];
  };

  const renderValueByFieldGroupOfferTbl = (data, field) => {
    if (field === "Action") {
      return (
        <>
          <Button variant="warning"
            className="btn btn-sm mr-1" 
            onClick={() => handleEditGroup(data)}>
            Edit <i className="fa fa-edit"></i>{' '}
          </Button>
        <Button variant="danger" className="btn btn-sm" onClick={() => handleDeleteGroup(data.id)}>
            Delete <i className="fa fa-trash"></i>{' '}
          </Button>
        </>
      );
    }

    if (field === "name" || field === "id") {
      return (
        <Button
          variant="link"
          size="sm"
          className="text-primary"
          onClick={() => handleOnSelectGroup(data)}
        >
          {data[field]}
        </Button>
      );
    }
    return data[field];
  };

  const handleOnSelectGroup = (group) => {
    dispatch(offerActions.getOffers(group.id));
    setSelectedGroup(group);
  };

  const handleOnCreateNewGroup = () => {
    toggleCreateGroupModal(true);
  }

  const handleOnCreateNewOffer = () => {
    toggleCreateOfferModal(true);
  }

  const handleCloseGroupModal = () => {
    toggleCreateGroupModal(false);
    setEditingGroup(null);

  }

  const handleCloseOfferModal = () => {
    toggleCreateOfferModal(false)
    setEditingOffer(null);
  }

  useEffect(() => {
    dispatch(offerGroupsActions.getAll());

    return () => {
      dispatch(offerActions.resetOfferState());
    }
  }, []);

  return (
    <div className="content">
      <Container fluid className="mt-5 py-1">
        <Row>
          <Col md={4}>
            <Card
              title="Offer Group"
              ctTableFullWidth
              actions={
                <Button variant="success" size="sm" onClick={handleOnCreateNewGroup}>
                  Create
                </Button>
              }
              content={
                offerGroupState.status === "loading" ? (
                  <Spinner />
                ) : (
                  <Table id="table-groups" responsive className="orders-table">
                    <thead>
                      <tr>
                        {thOfferGroups.map((prop, key) => {
                          return <th key={`groups-${key}`}>{prop.value}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {offerGroupState.list.map((group, key) => (
                        <tr key={`groupRow-${group.id}`} className={group.id === selectedGroup?.id && "table-bg-Completed"}>
                          {thOfferGroups.map((column, key) => (
                            <td key={`group-${column.code}`}>
                              {renderValueByFieldGroupOfferTbl(
                                group,
                                column.code
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )
              }
            />
          </Col>
          <Col md={8}>
            <Card
              title={selectedGroup ? `${selectedGroup.name}` : ""}
              ctTableFullWidth
              actions={
                <Button variant="success" size="sm" onClick={handleOnCreateNewOffer} disabled={!selectedGroup}>
                  Create offer
                </Button>
              }
              content={
                offerState.status === "loading" ? (
                  <Spinner />
                ) : !offerState.list.length ? (
                  <p className="text-center mt-1">{selectedGroup ? 'There are no offers' : 'Please select a group'}</p>
                ) : (
                  <Table id="table-group-offers" className="orders-table">
                    <thead>
                      <tr>
                        {thOffers.map((prop, key) => {
                          return <th key={`groups-${key}`}>{prop.value}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {offerState.list.map((offer) => (
                        <tr key={`offerRow-${offer.id}`}>
                          {thOffers.map((column) => (
                            <td key={`offer-${column.code}`}>
                              {renderValueByFieldOfferTbl(offer, column.code)}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )
              }
            />
          </Col>
        </Row>
      </Container>
      <CreateUpdateGroupOfferModal 
        show={showCreateGroupModal} 
        handleClose={handleCloseGroupModal}
        onEditSuccess={onEditSuccess}
        group={editingGroup}
      />
      <CreateUpdateOfferModal 
        show={showCreateOfferModal} 
        group={selectedGroup}
        handleClose={handleCloseOfferModal}
        onEditOfferSuccess={onEditOfferSuccess}
        offer={editingOffer}
      />
    </div>
  );
}
