import { useEffect, useState } from "react";
import { Modal, Button, Form, Row } from "react-bootstrap";

export const initService = {
  name: "",
  active: true,
  directory: "",
  configName: "",
  jarName: "",
  command: "",
  adminPort: 0,
  adminProtocol: "",
  node: null,
  deploymentMethod: "SIMPLE_COPY",
};

const initNode = {
  host: "",
  keyPath: "",
  user: "",
  port: 0,
};

function Node({ node, onChange }) {
  const handleChange = ({ target: { name, value, type } }) =>
    onChange({ target: { name: "node", value: { ...node, [name]: type === "number" ? Number(value) : value } } });

  const handleAddNode = () => onChange({ target: { name: "node", value: { ...initNode } } });
  const handleDeleteNode = () => onChange({ target: { name: "node", value: null } });

  return (
    <Form.Group className="ml-3">
      {!!node && (
        <>
          <Form.Group>
            <Form.Label>Host</Form.Label>
            <Form.Control required value={node.host} name="host" onChange={handleChange} />
            <Form.Control.Feedback type="invalid">Host is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Key Path</Form.Label>
            <Form.Control required value={node.keyPath} name="keyPath" onChange={handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>User</Form.Label>
            <Form.Control required value={node.user} name="user" onChange={handleChange} />
            <Form.Control.Feedback type="invalid">User is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Port</Form.Label>
            <Form.Control required type="number" value={node.port} name="port" onChange={handleChange} />
            <Form.Control.Feedback type="invalid">Port is required</Form.Control.Feedback>
          </Form.Group>
        </>
      )}
      <Form.Group as={Row}>
        <Form.Label column>{!node ? "Add" : "Delete"} Node field</Form.Label>
        <Button
          style={{ width: "85px" }}
          variant={!node ? "success" : "danger"}
          className="btn-sm pull-right mr-3"
          onClick={!node ? handleAddNode : handleDeleteNode}
        >
          {!node ? "Add" : "Delete"} <i className={`fa fa-${!node ? "plus" : "trash"}`} />
        </Button>
      </Form.Group>
    </Form.Group>
  );
}

export default function EditService({ show, service, handleClose, handleSave }) {
  const [formData, setFormData] = useState(initService);
  const [oldName, setOldName] = useState("");
  const [isDirty, setDirty] = useState(false);

  const handleChange = ({ target: { name, value, checked, type } }) => {
    setFormData({ ...formData, [name]: type === "number" ? Number(value) : type === "checkbox" ? checked : value });
    setDirty(true);
  };

  const handleCancel = () => {
    setFormData(initService);
    handleClose();
    setDirty(false);
  };

  const handleSaveService = () => {
    handleSave(formData, oldName);
    setFormData(initService);
    setDirty(false);
  };

  useEffect(() => {
    setFormData(service);
    setOldName(service.name);
  }, [service]);

  return (
    <Modal show={show} onHide={handleCancel} size="md" centered>
      <Modal.Header closeButton>
        <div>{oldName ? oldName : "New field"}</div>
      </Modal.Header>
      <Modal.Body>
        <Form onChange={() => setDirty(true)}>
          <Form.Group  controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              style={{ background: 0 }}
              name="name"
              value={formData.name}
              onChange={handleChange}
              isInvalid={!formData.name}
            />
            <Form.Control.Feedback type="invalid">Name is required</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="active">
            <Form.Check
              type="checkbox"
              label="Active"
              name="active"
              checked={formData.active}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="directory">
            <Form.Label>Directory</Form.Label>
            <Form.Control required name="directory" value={formData.directory} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">Directory is required</Form.Control.Feedback>
          </Form.Group>

          <Form.Group  controlId="configName">
            <Form.Label>Config Name</Form.Label>
            <Form.Control required name="configName" value={formData.configName} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">Config Name is required</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="jarName">
            <Form.Label>JarName</Form.Label>
            <Form.Control required name="jarName" value={formData.jarName} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">JarName is required</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="command">
            <Form.Label>Command</Form.Label>
            <Form.Control required name="command" value={formData.command} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">Command is required</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="adminPort">
            <Form.Label>Admin Port</Form.Label>
            <Form.Control required type="number" name="adminPort" value={formData.adminPort} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">Admin Port is required</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="adminProtocol">
            <Form.Label>Admin Protocol</Form.Label>
            <Form.Control required name="adminProtocol" value={formData.adminProtocol} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">Admin Protocol is required</Form.Control.Feedback>
          </Form.Group>

          <Form.Label>Node</Form.Label>
          <Node node={formData.node} onChange={handleChange} />

          <Form.Group  controlId="deploymentMethod">
            <Form.Label>Deployment Method</Form.Label>
            <Form.Control required name="deploymentMethod" value={formData.deploymentMethod} onChange={handleChange} />
            <Form.Control.Feedback type="invalid">From is required</Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Close
        </Button>
        <Button variant="primary" disabled={!formData.name || !isDirty} onClick={handleSaveService}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
