export function getZConfig() {
  const zconfig = JSON.parse(localStorage.getItem("zconfig"));
  if (!zconfig || zconfig === "null") return null;
  return zconfig;
}

export function getZConfigField(field) {
  const zconfig = JSON.parse(localStorage.getItem("zconfig"));
  if (!zconfig || zconfig === "null" || !field || !zconfig[field]) return null;
  return zconfig[field];
}
