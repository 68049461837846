import { Row, Col, Form } from "react-bootstrap";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import IndividualEdit from "../Individual/Edit";
import MonitoringReportListEdit from "../MonitoringReportList/Edit";

import StatusEdit from "../../Status/Edit";

import { TAB_CODES } from "variables/Variables";

export default function FormTrademarkMonitoring({ orderDetails, selectedProduct, onError, onChange, ...props }) {
  const productDetails = orderDetails.products[selectedProduct];

  const handleAddError = (field) => onError(TAB_CODES.trademarkInfo, field);

  const handleProductChange = ({ target: { name, value } }) =>
    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, [name]: value } : product
      ),
    });

  const handleProductOwnerChange = (value) => handleProductChange({ target: { name: "owner", value } });

  const handleProductProcessingResultChange = ({ name, value }) =>
    handleProductChange({
      target: {
        name: "processingResult",
        value: { ...productDetails.processingResult, [name]: value },
      },
    });

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">MONITORING TRADEMARK</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
      />

      <FormPDF productDetails={productDetails} {...props} />

      <hr />
      <h6 className="font-weight-bold">Mark Information</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Trademark Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="trademarkName"
            value={productDetails.trademarkName || ""}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <hr />
      <IndividualEdit
        label="Owner Information"
        individual={productDetails.owner}
        onError={handleAddError}
        onChange={handleProductOwnerChange}
      />

      <MonitoringReportListEdit
        monitoringReports={productDetails.processingResult.monitoringReports}
        onError={handleAddError}
        onChange={handleProductProcessingResultChange}
      />
    </div>
  );
}
