import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import classnames from "classnames";
import moment from "moment-timezone";

import { customerActions } from "_actions";

import TrademarkClassificationList from "../TrademarkClassificationsList/Edit";
import TrademarkEventList from "../TrademarkEventList/Edit";
import TrademarkOwnerList from "../TrademarkOwnerList/Edit";

export const initRegisteredTrademark = {
  serialNumber: 0,
  registrationNumber: "",
  markIdentification: "",
  statusCode: "",
  statusDate: null,
  filingDate: null,
  registrationDate: null,
  attorneyName: "",
  markDrawingCode: "",
  caseFileStatements: {},
  classification: [],
  owners: [],
  correspondenceName: "",
  correspondenceAddress1: "",
  correspondenceAddress2: "",
  events: [],
};

export default function FederalSearchResultList({ federalSearchResults, onError, onChange }) {
  const dispatch = useDispatch();

  const { collapseFederalSearchResults } = useSelector(({ customers }) => customers);

  const handleAddRegisteredTrademark = () => onChange([...federalSearchResults, initRegisteredTrademark]);

  const handleDeleteRegisteredTrademark = (deleteIdx) =>
    onChange(federalSearchResults.filter((o, _idx) => deleteIdx !== _idx));

  const handleChangeRegisteredTrademark = ({ target: { name, value } }, index) => {
    let newVal = value;

    onChange(
      federalSearchResults.map((federalSearch, idx) =>
        idx === index ? { ...federalSearch, [name]: newVal } : federalSearch
      )
    );
  };

  const handleChangeSerialNumber = ({ target: { name, value } }, index) => {
    if (/^[0-9\\\-]+$/.test(value) || value === "")
      onChange(
        federalSearchResults.map((federalSearch, idx) =>
          idx === index ? { ...federalSearch, [name]: value } : federalSearch
        )
      );
  };

  return (
    <>
      <hr />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          <h6 className="font-weight-bold">Federal search results</h6>
        </Form.Label>
        <Col sm={7}>
          <Button size="sm" onClick={handleAddRegisteredTrademark}>
            Add Search
          </Button>
        </Col>
      </Form.Group>

      {federalSearchResults?.map((trademark, idx) => {
        const isCollapsed = !collapseFederalSearchResults.includes(idx);

        return (
          <Col key={idx} sm={12} className="pl-3">
            <Form.Group as={Row}>
              <Form.Label
                column
                sm={5}
                style={{ cursor: "pointer" }}
                onClick={() => dispatch(customerActions.setCollapseFederalSearch(idx))}
              >
                <i className={`fa fa-caret-${isCollapsed ? "down" : "up"}`} /> Serial number:
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  size="sm"
                  type="text"
                  name="serialNumber"
                  pattern="^[0-9\\\-]+$"
                  value={trademark.serialNumber || ""}
                  onChange={(e) => handleChangeSerialNumber(e, idx)}
                />
              </Col>
            </Form.Group>

            {!isCollapsed && (
              <Col className={classnames({ collapse: true, show: !isCollapsed })}>
                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Registration number:
                  </Form.Label>
                  <Col sm={7}>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="registrationNumber"
                      value={trademark.registrationNumber || ""}
                      onChange={(e) => handleChangeRegisteredTrademark(e, idx)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Mark ID:
                  </Form.Label>
                  <Col sm={7}>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="markIdentification"
                      value={trademark.markIdentification || ""}
                      onChange={(e) => handleChangeRegisteredTrademark(e, idx)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Status code:
                  </Form.Label>
                  <Col sm={7}>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="statusCode"
                      value={trademark.statusCode || ""}
                      onChange={(e) => handleChangeRegisteredTrademark(e, idx)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Status date:
                  </Form.Label>
                  <Col sm={7}>
                    <Form.Control
                      type="date"
                      name="statusDate"
                      max={moment().format("YYYY-MM-DD")}
                      value={trademark.statusDate || ""}
                      onChange={(e) => handleChangeRegisteredTrademark(e, idx)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Filing date:
                  </Form.Label>
                  <Col sm={7}>
                    <Form.Control
                      type="date"
                      name="filingDate"
                      max={moment().format("YYYY-MM-DD")}
                      value={trademark.filingDate || ""}
                      onChange={(e) => handleChangeRegisteredTrademark(e, idx)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Registration date:
                  </Form.Label>
                  <Col sm={7}>
                    <Form.Control
                      type="date"
                      name="registrationDate"
                      max={moment().format("YYYY-MM-DD")}
                      value={trademark.registrationDate || ""}
                      onChange={(e) => handleChangeRegisteredTrademark(e, idx)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Attorney name:
                  </Form.Label>
                  <Col sm={7}>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="attorneyName"
                      value={trademark.attorneyName || ""}
                      onChange={(e) => handleChangeRegisteredTrademark(e, idx)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Mark drawing code:
                  </Form.Label>
                  <Col sm={7}>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="markDrawingCode"
                      value={trademark.markDrawingCode || ""}
                      onChange={(e) => handleChangeRegisteredTrademark(e, idx)}
                    />
                  </Col>
                </Form.Group>

                <TrademarkClassificationList
                  trademarkClassifications={trademark.classification}
                  onError={onError}
                  onChange={(value) =>
                    handleChangeRegisteredTrademark({ target: { name: "classification", value } }, idx)
                  }
                />

                <TrademarkOwnerList
                  trademarkOwners={trademark.owners}
                  onError={onError}
                  onChange={(value) => handleChangeRegisteredTrademark({ target: { name: "owners", value } }, idx)}
                />

                <hr />
                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Correspondence name:
                  </Form.Label>
                  <Col sm={7}>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="correspondenceName"
                      value={trademark.correspondenceName || ""}
                      onChange={(e) => handleChangeRegisteredTrademark(e, idx)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Correspondence address 1:
                  </Form.Label>
                  <Col sm={7}>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="correspondenceAddress1"
                      value={trademark.correspondenceAddress1 || ""}
                      onChange={(e) => handleChangeRegisteredTrademark(e, idx)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    Correspondence address 2:
                  </Form.Label>
                  <Col sm={7}>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="correspondenceAddress2"
                      value={trademark.correspondenceAddress2 || ""}
                      onChange={(e) => handleChangeRegisteredTrademark(e, idx)}
                    />
                  </Col>
                </Form.Group>

                <TrademarkEventList
                  trademarkEvents={trademark.events}
                  onError={onError}
                  onChange={(value) => handleChangeRegisteredTrademark({ target: { name: "events", value } }, idx)}
                />

                <Form.Group as={Row}>
                  <Form.Label column sm={5}>
                    {trademark.markIdentification}
                  </Form.Label>
                  <Col sm={7}>
                    <Button size="sm" variant="danger" onClick={() => handleDeleteRegisteredTrademark(idx)}>
                      Remove
                    </Button>
                  </Col>
                </Form.Group>
              </Col>
            )}
          </Col>
        );
      })}
    </>
  );
}
