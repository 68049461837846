import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment-timezone";

import { variantsActions, trademarkDashboardActions, alertActions } from "_actions";

import GraphCard from "components/Card/Card";
import Spinner from "components/Spinner";
import TrademarkDashboardFilters from "views/Reports/TrademarkDashboard/Filters";
import GraphVertical from "views/Reports/TrademarkDashboard/ChartVertical";
import GraphHorizontal from "views/Reports/TrademarkDashboard/ChartHorizontal";
import ConfirmationModal from "components/ConfirmationModal";

const Wrapper = styled.div`
  overflow: auto;
`;
const CenteredText = styled.div`
  text-align: center;
`;

export default function TrademarkDashboard() {
  const dispatch = useDispatch();

  const [date, setDate] = useState({
    from: moment().subtract(1, "months").date(1).format("YYYY-MM-DD"),
    to: moment().startOf("month").subtract(1, "day").format("YYYY-MM-DD"),
  });
  const [plotData, setPlotData] = useState({ labels: [], series: [] });
  const [dateRange, setDateRange] = useState("lastmonth");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [orientation, setOrientation] = useState("horizontal");
  const [isZeroesHidden, setIsZeroesHidden] = useState("true");
  const [statusesToShow, setStatusesToShow] = useState([]);
  const [statusesOptions, setStatusesOptions] = useState([]);
  const [hasWindowResized, setHasWindowResized] = useState(false);
  const [countOfOrders, setCountOfOrders] = useState(0);
  const [productCode, setProductCode] = useState("");
  const [savedSettings, setSavedSettings] = useState(null);

  const [loadingVariants, loadingDashboard] = useSelector(({ variants, trademarkDashboard }) => [
    variants.loading,
    trademarkDashboard.loading,
  ]);
  const processingStatus = useSelector(({ variants: { processingStatus } }) => processingStatus);
  const countOfOrdersPerStatusObj = useSelector(
    ({ trademarkDashboard: { countOfOrdersPerStatus } }) => countOfOrdersPerStatus
  );

  const loading = loadingVariants || loadingDashboard;

  const statusesShowed = plotData?.series[0]?.reduce((sum, item) => sum + item, 0);
  const statusesHidden = countOfOrders - statusesShowed;

  const handleGetData = () => {
    dispatch(trademarkDashboardActions.get({ ...date, productCode }));
  };

  const getStatuses = () => {
    if (processingStatus.length) {
      return [
        { code: "all", value: "*", label: "All" },
        ...processingStatus.map(({ code, value }) => {
          return { code, value, label: value };
        }),
      ];
    }
    return [];
  };

  const resizeHandler = () => {
    if (!hasWindowResized) setHasWindowResized(true);
  };

  const hideZeroes = () => {
    setStatusesToShow(statusesToShow.filter((status) => Object.keys(countOfOrdersPerStatusObj).includes(status.code)));
  };

  const showNonZeroes = () => {
    const statuses = getStatuses();

    setStatusesToShow(statuses.filter((status) => Object.keys(countOfOrdersPerStatusObj).includes(status.code)));
  };

  const handleChangeDataRange = ({ target: { value } }) => {
    setDateRange(value);

    switch (value) {
      case "today": {
        setDate({ from: moment().format("YYYY-MM-DD"), to: moment().format("YYYY-MM-DD") });
        break;
      }

      case "yesterday": {
        setDate({
          from: moment().subtract(1, "day").format("YYYY-MM-DD"),
          to: moment().subtract(1, "day").format("YYYY-MM-DD"),
        });
        break;
      }

      case "weektodate": {
        setDate({
          from: moment().startOf("week").format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
        });
        break;
      }

      case "lastweek": {
        setDate({
          from: moment().day(1).subtract(7, "day").format("YYYY-MM-DD"),
          to: moment().day(7).subtract(7, "day").format("YYYY-MM-DD"),
        });
        break;
      }

      case "monthtodate": {
        setDate({ from: moment().startOf("month").format("YYYY-MM-DD"), to: moment().format("YYYY-MM-DD") });
        break;
      }

      case "pastmonth": {
        setDate({
          from: moment().subtract(1, "months").format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
        });
        break;
      }

      case "lastmonth": {
        setDate({
          from: moment().subtract(1, "months").date(1).format("YYYY-MM-DD"),
          to: moment().startOf("month").subtract(1, "day").format("YYYY-MM-DD"),
        });
        break;
      }

      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(variantsActions.getprocessing_status("TRADEMARKS"));
    dispatch(variantsActions.getproductcodes("TRADEMARKS"));

    window.addEventListener("resize", resizeHandler);

    const rawSettings = localStorage.getItem("trademarkDashboard");
    if (rawSettings) {
      const settings = JSON.parse(rawSettings);
      setSavedSettings(settings);
      setProductCode(settings.productCode);
      setOrientation(settings.orientation);
      setIsZeroesHidden(settings.isZeroesHidden);
      setStatusesToShow(settings.statusesToShow);
      handleChangeDataRange({ target: { value: settings.dateRange } });
    } else {
      handleGetData(date);
    }

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  useEffect(() => {
    savedSettings !== null && handleGetData(date);
  }, [savedSettings]);

  useEffect(() => {
    if (Object.keys(countOfOrdersPerStatusObj).length) {
      const sumOfCounts = Object.values(countOfOrdersPerStatusObj).reduce((summ, value) => summ + value, 0);
      setCountOfOrders(sumOfCounts);
    } else {
      setCountOfOrders(0);
    }
  }, [countOfOrdersPerStatusObj]);

  useEffect(() => {
    if (processingStatus.length) {
      const statuses = getStatuses();

      const labels = processingStatus.reduce((obj, status) => [...obj, status.value], []);
      setPlotData({ labels, series: [[...labels].fill(0)] });

      setStatusesOptions(statuses);

      if (!localStorage.getItem("trademarkDashboard")) setStatusesToShow(statuses);
    }
  }, [processingStatus]);

  useEffect(() => {
    if (processingStatus.length) {
      const newData = statusesToShow.reduce(
        (obj, status) => {
          if (status.code === "all") return obj;

          if (!Object.keys(countOfOrdersPerStatusObj).includes(status.code)) {
            if (isZeroesHidden === "false") {
              return {
                labels: [...obj.labels, status.label],
                series: [[...obj.series[0], 0]],
              };
            }
            return obj;
          }

          return {
            labels: [...obj.labels, status.label],
            series: [[...obj.series[0], countOfOrdersPerStatusObj[status.code]]],
          };
        },
        { labels: [], series: [[]] }
      );
      setPlotData(newData);
    }
  }, [processingStatus, countOfOrdersPerStatusObj, statusesToShow, isZeroesHidden]);

  const handlers = {
    statusesHandler: setStatusesToShow,
    dateHandler: setDate,
    productCodeHandler: ({ target: { value } }) => setProductCode(value),
    handleChangeDataRange,
    orientationHandler: setOrientation,
    hideZeroesHandler: setIsZeroesHidden,
    showNonZeroesHandler: showNonZeroes,
    saveHandler: () => {
      const settings = JSON.stringify({
        dateRange,
        orientation,
        isZeroesHidden: isZeroesHidden,
        statusesToShow,
        productCode,
      });

      localStorage.setItem("trademarkDashboard", settings);

      dispatch(alertActions.success("Settings saved successfully"));
    },
    defaultSettingsHandler: () => setConfirmOpen(true),
  };

  return (
    <Container
      fluid
      className="mt-5 py-4"
      onMouseMove={() => {
        if (hasWindowResized) setHasWindowResized(false);
      }}
    >
      <TrademarkDashboardFilters
        date={date}
        orientation={orientation}
        isZeroesHidden={isZeroesHidden}
        onChange={setDate}
        onApply={handleGetData}
        statusesOptions={statusesOptions}
        statusesHandler={setStatusesToShow}
        statusesToShow={statusesToShow}
        dateRange={dateRange}
        productCode={productCode}
        handlers={handlers}
      />

      {loading ? (
        <Spinner />
      ) : (
        <GraphCard
          id="chartTrademarkDashboard"
          title="Product Statuses"
          hCenter
          content={
            loading || !plotData ? (
              <Spinner />
            ) : (
              <>
                <CenteredText>Total products found: {countOfOrders}</CenteredText>
                <CenteredText>
                  (Showed: {statusesShowed}, Hidden: {statusesHidden})
                </CenteredText>
                <Wrapper>
                  {orientation === "vertical" && <GraphVertical data={plotData} count={countOfOrders} />}
                  {orientation === "horizontal" && <GraphHorizontal data={plotData} count={countOfOrders} />}
                </Wrapper>
              </>
            )
          }
        />
      )}

      <ConfirmationModal
        show={confirmOpen}
        handleClose={() => {
          setConfirmOpen(false);
        }}
        handleConfirm={() => {
          localStorage.removeItem("trademarkDashboard");
          setConfirmOpen(false);
          location.reload();
        }}
        body="Are you sure you want to return default settings?"
      />
    </Container>
  );
}
