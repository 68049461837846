import { adsConstants } from "../_constants";

const initial_state = {
  loading: false,
  ads: null,
  accounts: null,
};

export function ads(state = initial_state, action) {
  switch (action.type) {
    case adsConstants.GET_ADS_REQUEST:
      return { ...state, loading: true, ads: null };

    case adsConstants.GET_ADS_SUCCESS:
      return { ...state, loading: false, ads: action.result };

    case adsConstants.GET_ACCOUNT_SUCCESS:
      return { ...state, loading: false, accounts: action.accounts };

    case adsConstants.ADD_ACCOUNT_SUCCESS:
      return { ...state, loading: false, accounts: [...state.accounts, action.account] };

    case adsConstants.UPDATE_ACCOUNT_SUCCESS:
      const updated_account = action.account;
      const updated_index = state.accounts.findIndex((x) => x.id === updated_account.id);
      state.accounts[updated_index] = updated_account;
      return JSON.parse(JSON.stringify(state));

    case adsConstants.DELETE_ACCOUNT_SUCCESS:
      const removed_id = action.id;
      const removed_index = state.accounts.findIndex((x) => x.id === removed_id);
      state.accounts.splice(removed_index, 1);
      return JSON.parse(JSON.stringify(state));

    default:
      return state;
  }
}
