import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { adminActions, variantsActions } from "_actions";

export default function EditRaAddressModal({ show, address = null, mode = null, handleClose = () => {} }) {
  const dispatch = useDispatch();
  const formEl = useRef(null);

  const { statesObj } = useSelector(({ variants }) => variants);

  const [newAddress, setNewAddress] = useState(null);
  const [validated, setValidated] = useState(false);

  const isAdd = mode === "add";
  const isEdit = mode === "edit";

  const handleChangeAddress = ({ target: { value, name } }) => {
    const newObj = { ...newAddress, address: { ...newAddress.address } };
    let newValue = value;

    if (name === "zip") {
      newValue = newValue.replace(/\D/gi, "");
    }

    if (name === "state") {
      newObj.state = newValue;
      newObj.address[name] = newValue;
    }

    newObj.address[name] = newValue;

    setNewAddress(newObj);
  };

  const handleOnClose = () => {
    setValidated(false);
    handleClose();
  };

  const handleSubmit = (e) => {
    const form = formEl.current;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      dispatch(adminActions.putAddressRA(newAddress, mode));
      handleOnClose();
    }
  };

  useEffect(() => {
    setNewAddress(address);
  }, [address]);

  return (
    <Modal show={show} onHide={handleOnClose} size="md" centered>
      <Modal.Header closeButton style={{ border: 0 }}>
        {isAdd && "Add New Address"}
        {isEdit && "Edit Address"}
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} noValidate ref={formEl}>
          <Form.Group>
            <Form.Label>State</Form.Label>
            {isEdit && <Form.Control required readOnly name="state" value={statesObj[newAddress?.state] || ""} />}
            {isAdd && (
              <Form.Control
                required
                as="select"
                name="state"
                value={newAddress?.state || ""}
                onChange={handleChangeAddress}
              >
                <option value={""}>Please, select state</option>
                {Object.keys(statesObj).map((state, idx) => (
                  <option value={state} key={`${state}-${idx}`}>
                    {state} – {statesObj[state]}
                  </option>
                ))}
              </Form.Control>
            )}
            <Form.Control.Feedback type="invalid">
              Please provide the state with which this address will be related
            </Form.Control.Feedback>
          </Form.Group>

          {/* <Form.Group>
            <Form.Label>Address Title</Form.Label>
            <Form.Control
              required
              name="actionRequired"
              value={newAddress?.actionRequired? || ""}
              onChange={handleChangeState}
            />
            <Form.Control.Feedback type="invalid">Please, provide action title for customer</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Address Description</Form.Label>
            <Form.Control
              required
              as="textarea"
              name="customerActionDescription"
              value={newAddress?.customerActionDescription? || ""}
              onChange={handleChangeState}
            />
            <Form.Control.Feedback type="invalid">
              Please, provide action description for customer
            </Form.Control.Feedback>
          </Form.Group> */}

          <Form.Group>
            <Form.Label>Address1:</Form.Label>
            <Form.Control
              required
              size="sm"
              type="text"
              name="address1"
              pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
              value={newAddress?.address?.address1 || ""}
              onChange={handleChangeAddress}
            />
            <Form.Control.Feedback type="invalid">Please, provide correct address1</Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>
              Address2: <span style={{ color: "gray" }}>(optional)</span>
            </Form.Label>
            <Form.Control
              size="sm"
              name="address2"
              type="text"
              pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
              value={newAddress?.address?.address2 || ""}
              onChange={handleChangeAddress}
            />
            <Form.Control.Feedback type="invalid">Please, provide correct address2</Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>City:</Form.Label>
            <Form.Control
              required
              size="sm"
              type="text"
              name="city"
              pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
              value={newAddress?.address?.city || ""}
              onChange={handleChangeAddress}
            />
            <Form.Control.Feedback type="invalid">Please, provide correct city</Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Zip Code:</Form.Label>
            <Form.Control
              required
              size="sm"
              type="text"
              name="zip"
              maxLength={5}
              pattern="^\d{5}$"
              value={newAddress?.address?.zip || ""}
              onChange={handleChangeAddress}
            />
            <Form.Control.Feedback type="invalid">Please, provide correct zip code</Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleOnClose}>
          Cancel
        </Button>

        {isAdd && (
          <Button variant="success" onClick={handleSubmit}>
            Add New Address
          </Button>
        )}
        {isEdit && (
          <Button variant="success" onClick={handleSubmit}>
            Save Changes
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

EditRaAddressModal.propTypes = {
  show: PropTypes.bool,
};
