import React from "react";
import { Col, Form, Row, Button } from "react-bootstrap";

import { initAddress } from "../IndividualAddress/Edit";

import { state_array } from "variables/Variables";

export default function NewAddress({ label, newAddress, withoutButton, onError, onChange }) {
  const handleAddNewAddress = () => onChange(initAddress);
  const handleDeleteNewAddress = () => onChange(null);
  const handleChangeNewAddress = ({ target: { name, value } }) => onChange({ ...newAddress, [name]: value });

  if (!newAddress)
    return (
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          <h6 className="font-weight-bold">{label}</h6>
        </Form.Label>
        {!withoutButton && (
          <Col sm={7}>
            <Button variant="success" onClick={handleAddNewAddress}>
              Add
            </Button>
          </Col>
        )}
      </Form.Group>
    );

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          <h6 className="font-weight-bold">{label}</h6>
        </Form.Label>
        {!withoutButton && (
          <Col sm={7}>
            <Button variant="danger" onClick={handleDeleteNewAddress}>
              Remove
            </Button>
          </Col>
        )}
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address1
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="address1"
            pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            value={newAddress.address1 || ""}
            onChange={handleChangeNewAddress}
            onInvalid={() => onError("Address")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address2
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            name="address2"
            type="text"
            pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            value={newAddress.address2 || ""}
            onChange={handleChangeNewAddress}
            onInvalid={() => onError("Address2")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          City
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="city"
            pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            value={newAddress.city || ""}
            onChange={handleChangeNewAddress}
            onInvalid={() => onError("City")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          State
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            as="select"
            size="sm"
            name="state"
            value={newAddress.state || ""}
            onChange={handleChangeNewAddress}
            onInvalid={() => onError("State")}
          >
            <option value="">Select a state</option>
            {Object.keys(state_array).map((key) => (
              <option key={key} value={key}>
                {state_array[key]}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Zip Code
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="zip"
            pattern="^\d{5}$"
            value={newAddress.zip || ""}
            onChange={handleChangeNewAddress}
            onInvalid={() => onError("Zip")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          County
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="county"
            pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            value={newAddress.county || ""}
            onChange={handleChangeNewAddress}
            onInvalid={() => onError("County")}
          />
        </Col>
      </Form.Group>
    </>
  );
}
