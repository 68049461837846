import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row, Table } from "react-bootstrap";

import { combineFromCategories } from "_helpers";
import { getPriorityStatus } from "_helpers";

const AmendmentsLLC = ({ orderDetails, selectedProduct }) => {
  const variants = useSelector(({ variants }) => variants);
  const processingStatusOpt = combineFromCategories(variants.allProcessingStatuses);
  const orderStatusOpt = combineFromCategories(variants.allOrderStatuses);

  const amendments = orderDetails?.children?.filter(({ products }) => {
    return products?.find(
      (product) =>
        product?.code?.code?.includes("INC_Amendment") || product?.code?.code?.includes("INC_States_Amendment")
    );
  });

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">Amendments LLC</h4>
      <hr />

      <Row className="mb-4">
        <Col lg md={12} className="mb-3">
          <Table responsive className="orders-table">
            <thead>
              <tr>
                <th>OrderID</th>
                <th>Date</th>
                <th>Product Type</th>
                <th>Order Amount</th>
                <th>Payment Status</th>
                <th>Filing Status</th>
              </tr>
            </thead>
            <tbody>
              {amendments?.map(
                (prop, key) =>
                  orderDetails.order.id !== prop.order.id && (
                    <tr key={key} className={"table-bg-" + getPriorityStatus(prop.products)}>
                      <td>
                        <Link to={"/admin/orders/" + prop.order.id} className="dropdown-item">
                          {prop.order.crmOrderId}
                        </Link>
                      </td>
                      <td>{moment.utc(prop.products[0]?.paymentDate).local().format("YYYY/MM/DD HH:mm:ss")}</td>
                      <td>{prop.products[0]?.code.code}</td>
                      <td>{prop.order.total}</td>
                      <td>{orderStatusOpt[prop.products[0]?.paymentStatus]}</td>
                      <td>
                        {prop.products
                          .map(({ processingResult: { status } }) => processingStatusOpt[status])
                          .join(", ")}
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default AmendmentsLLC;
