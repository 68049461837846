import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Table, Card, Form } from "react-bootstrap";
import moment from "moment-timezone";
import styled, { css } from "styled-components";

import { adminActions } from "_actions";
import { currencyFormatter } from "_helpers";

import Spinner from "components/Spinner";
import Button from "components/Button";

const binarySwitchArray = [
  { code: false, value: "No" },
  { code: true, value: "Yes" },
];

const TableRow = styled.tr`
  ${({ tableLayout }) =>
    tableLayout &&
    css`
      tablelayout: ${tableLayout};
    `}
`;

const CellHeader = styled.th`
  width: auto;

  ${({ width, minWidth }) => css`
    width: ${width};
    min-width: ${minWidth ? minWidth : 0};
  `}
`;

const Cell = styled.td`
  ${({ green }) =>
    green &&
    css`
      color: var(--green);
    `}

  ${({ red }) =>
    red &&
    css`
      color: var(--red);
    `}

    ${({ wordBreak }) =>
    wordBreak &&
    css`
      word-break: break-all;
      white-space: normal;
      padding-right: 24px !important;
    `}
`;

export default function SalesByDomain() {
  const dispatch = useDispatch();

  const [date, setDate] = useState({
    from: moment(new Date()).format("YYYY-MM-DD"),
    to: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [dateRange, setDateRange] = useState("today");
  const [campaignId, setCampaignId] = useState("");
  const [searchDomain, setSearchDomain] = useState("");
  const [includeTest, setIncludeTest] = useState(false);

  const campaigns = useSelector((state) => (state.reports.campaigns ? state.reports.campaigns : []));

  const salesByDomainData = useSelector((state) => (state.reports.sales ? state.reports.sales : []));

  const { loading } = useSelector(({ reports }) => reports);

  const campaignsObj = useMemo(() => {
    return campaigns.reduce((newObj, { id, name }) => ({ ...newObj, [id]: name }), {});
  }, [campaigns]);

  useEffect(() => {
    dispatch(adminActions.getcampaigns());
    dispatch(adminActions.getsalesbydomain(date.from, date.to, campaignId, includeTest));
  }, []);

  const getDomainSales = () => {
    dispatch(adminActions.getsalesbydomain(date.from, date.to, campaignId, includeTest));
  };

  const filteredSalesByDomainData = salesByDomainData.filter(({ domain }) =>
    domain.toLowerCase().includes(searchDomain.toLowerCase())
  );

  const handleChangeDate = ({ target: { name, value } }) => setDate({ ...date, [name]: value });

  const handleChangeDataRange = ({ target: { value } }) => {
    setDateRange(value);

    switch (value) {
      case "today": {
        setDate({ from: moment().format("YYYY-MM-DD"), to: moment().format("YYYY-MM-DD") });
        break;
      }

      case "yesterday": {
        setDate({
          from: moment().subtract(1, "day").format("YYYY-MM-DD"),
          to: moment().subtract(1, "day").format("YYYY-MM-DD"),
        });
        break;
      }

      case "weektodate": {
        setDate({
          from: moment().startOf("week").format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
        });
        break;
      }

      case "lastweek": {
        setDate({
          from: moment().day(1).subtract(7, "day").format("YYYY-MM-DD"),
          to: moment().day(7).subtract(7, "day").format("YYYY-MM-DD"),
        });
        break;
      }

      case "monthtodate": {
        setDate({ from: moment().startOf("month").format("YYYY-MM-DD"), to: moment().format("YYYY-MM-DD") });
        break;
      }

      case "pastmonth": {
        setDate({
          from: moment().subtract(1, "months").format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
        });
        break;
      }

      case "lastmonth": {
        setDate({
          from: moment().subtract(1, "months").date(1).format("YYYY-MM-DD"),
          to: moment().startOf("month").subtract(1, "day").format("YYYY-MM-DD"),
        });
        break;
      }

      default:
        break;
    }
  };

  return (
    <>
      <Container fluid className="mt-5 py-4">
        <Card className="p-2">
          <Row>
            <Col md={12}>
              <Form>
                <Form.Row>
                  <Form.Group as={Col} sm={3} className="py-0">
                    <Form.Label>Date Range:</Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      name="dateRange"
                      value={dateRange}
                      onChange={handleChangeDataRange}
                    >
                      <option value="" hidden></option>
                      <option value="today">Today</option>
                      <option value="yesterday">Yesterday</option>
                      <option value="weektodate">Week to Date</option>
                      <option value="lastweek">Last Week</option>
                      <option value="monthtodate">Month to Date</option>
                      <option value="pastmonth">Past Month</option>
                      <option value="lastmonth">Last Month</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} sm={3} className="py-0">
                    <Form.Label>Start Date:</Form.Label>
                    <Form.Control
                      size="sm"
                      type="date"
                      name="from"
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      value={date.from}
                      onChange={handleChangeDate}
                    />
                  </Form.Group>

                  <Form.Group as={Col} sm={3} className="py-0">
                    <Form.Label>End Date:</Form.Label>
                    <Form.Control
                      size="sm"
                      type="date"
                      name="to"
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      value={date.to}
                      onChange={handleChangeDate}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} sm={3} className="py-0">
                    <Form.Label>Campaign&nbsp;Id</Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      name="campaignId"
                      value={campaignId}
                      onChange={(e) => setCampaignId(e.target.value)}
                    >
                      <option value="">Select ID</option>
                      {campaigns.length > 0 &&
                        campaigns.map(({ id, name }, index) => {
                          return (
                            <React.Fragment key={index}>
                              <option value={`${id}`}>{`${id} - ${name}`}</option>
                            </React.Fragment>
                          );
                        })}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} sm={3} className="py-0">
                    <Form.Label>Show&nbsp;Test&nbsp;Sales</Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      name="testSales"
                      value={includeTest}
                      onChange={(e) => setIncludeTest(e.target.value)}
                    >
                      {binarySwitchArray.map(({ code, value }, key) => (
                        <option key={`test-${value}-${key}`} value={code}>
                          {value}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} sm={3} className="py-0">
                    <Button size="sm" isLoading={loading} onClick={getDomainSales}>
                      Filter
                    </Button>
                  </Form.Group>
                </Form.Row>
              </Form>
            </Col>
          </Row>
        </Card>

        <Card>
          <Form.Group as={Col} md={6}>
            <Form.Label>Search Domain</Form.Label>
            <Form.Control type="text" value={searchDomain} onChange={(e) => setSearchDomain(e.target.value.trim())} />
          </Form.Group>
          <Table responsive className="sales-by-domain-table">
            <thead>
              <TableRow tableLayout="fixed">
                <CellHeader width="12%">Campaign</CellHeader>
                <CellHeader width="auto" minWidth="300px">
                  Domain
                </CellHeader>
                <CellHeader width="5%">Clicks</CellHeader>
                <CellHeader width="5%">Leads</CellHeader>
                <CellHeader width="5%">Sales</CellHeader>
                <CellHeader width="6%">Sales/Leads</CellHeader>
                <CellHeader width="8%">Gross Revenue</CellHeader>
                <CellHeader width="6%">Refunds</CellHeader>
                <CellHeader width="8%">Net Revenue</CellHeader>
                <CellHeader width="10%">Avg. Order Value</CellHeader>
              </TableRow>
            </thead>
            <tbody>
              {!loading && (
                <>
                  {filteredSalesByDomainData.map((row, index) => {
                    const {
                      campaignId,
                      domain,
                      leads,
                      sales,
                      clicks,
                      grossRevenue,
                      netRevenue,
                      avgOrderValue,
                      refunds,
                    } = row;

                    return (
                      <TableRow key={index}>
                        <Cell>{`${campaignId} - ${campaignsObj[campaignId]}`}</Cell>
                        <Cell wordBreak>{domain}</Cell>
                        <Cell>{clicks}</Cell>
                        <Cell>{leads}</Cell>
                        <Cell>{sales}</Cell>
                        <Cell>{(sales / leads).toFixed(2)}</Cell>
                        <Cell green>{currencyFormatter(grossRevenue)}</Cell>
                        <Cell red>{currencyFormatter(refunds)}</Cell>
                        <Cell green>{currencyFormatter(netRevenue)}</Cell>
                        <Cell green>{currencyFormatter(avgOrderValue)}</Cell>
                      </TableRow>
                    );
                  })}
                  {!filteredSalesByDomainData.length && (
                    <TableRow>
                      <Cell>No Results</Cell>
                    </TableRow>
                  )}
                </>
              )}
            </tbody>
          </Table>

          {!!loading && <Spinner />}
        </Card>
      </Container>
    </>
  );
}
