import { thirdpartylogsConstants } from '../_constants';
import { thirdpartylogsService } from '../_services';
import { alertActions } from '.';

export const thirdpartylogsActions = {
  get,
  exportcsv,
};

function get(start_date, end_date, log_type, obj_id, req_string) {
  return (dispatch) => {
    dispatch(request());

    thirdpartylogsService.get(start_date, end_date, log_type, obj_id, req_string).then(
      (result) => dispatch(success(result)),
      (error) => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return { type: thirdpartylogsConstants.GETALL_REQUEST };
  }
  function success(result) {
    return { type: thirdpartylogsConstants.GETALL_SUCCESS, result };
  }
  function failure(error) {
    return { type: thirdpartylogsConstants.GETALL_FAILURE, error };
  }
}

function exportcsv(start_date, end_date, log_type, obj_id, req_string) {
  return (dispatch) => {
    thirdpartylogsService.exportcsv(start_date, end_date, log_type, obj_id, req_string).then(
      (result) => {},
      (error) => {},
    );
  };
}
