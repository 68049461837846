import { Row, Col, Form, Button } from "react-bootstrap";
import InputMask from "react-input-mask";
import clsx from "clsx";

import { phoneNumRegexp, phoneCleaner } from "_helpers";

import IndividualAddressEdit from "../IndividualAddress/Edit";

import { suffixArray } from "variables/Variables";

export const initIndividual = {
  firstName: "",
  middleName: "",
  lastName: "",
  suffixName: null,
  countryOfCitizenship: "",
  email: "",
  phone: "",
  address: null,
};

export default function Individual({ label, individual, isForceShowing = false, onError, onChange }) {
  const handleAddIndividual = () => onChange(initIndividual);
  const handleDeleteIndividual = () => onChange(null);
  const handleChangeIndividual = ({ target: { name, value } }) => {
    let newVal = value;

    if (name === "suffixName" && value === "") newVal = null;

    onChange({ ...individual, [name]: newVal });
  };

  const handleChangeIndividualAddress = (value) => handleChangeIndividual({ target: { name: "address", value } });
  const handlePhoneChange = ({ target: { name, value } }) =>
    handleChangeIndividual({ target: { name, value: phoneCleaner(value) } });

  if (!isForceShowing && !individual)
    return label ? (
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          <h6 className="font-weight-bold">{label}</h6>
        </Form.Label>
        <Col sm={7}>
          <Button variant="success" onClick={handleAddIndividual}>
            Add
          </Button>
        </Col>
      </Form.Group>
    ) : null;

  return (
    <>
      {label && (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            <h6 className="font-weight-bold">{label}</h6>
          </Form.Label>
          <Col sm={7}>
            <Button
              variant={individual ? "danger" : "success"}
              onClick={individual ? handleDeleteIndividual : handleAddIndividual}
            >
              {individual ? "Remove" : "Add"}
            </Button>
          </Col>
        </Form.Group>
      )}

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First Name
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            // required
            size="sm"
            type="text"
            name="firstName"
            pattern="^(^[a-zA-Z0-9 \/\-\\]{1,30})$"
            value={individual?.firstName || ""}
            onChange={handleChangeIndividual}
            onInvalid={() => onError("First Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Middle Name (optional)
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="middleName"
            pattern="^(^[a-zA-Z0-9 \/\-\\]{1,30})$"
            value={individual?.middleName || ""}
            onChange={handleChangeIndividual}
            onInvalid={() => onError("Middle Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Last Name
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="lastName"
            pattern="^(^[a-zA-Z0-9 \/\-\\]{1,30})$"
            value={individual?.lastName || ""}
            onChange={handleChangeIndividual}
            onInvalid={() => onError("Last Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Suffix Name (optional)
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            as="select"
            size="sm"
            name="suffixName"
            value={individual?.suffixName || ""}
            onChange={handleChangeIndividual}
          >
            <option value="">Please select</option>
            {suffixArray.map((prop, key) => (
              <option key={key} value={prop}>
                {prop}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Phone Number
        </Form.Label>
        <Col sm={7}>
          <InputMask
            className={clsx("form-control", "form-control-sm", {
              "is-invalid": individual?.phone && !phoneNumRegexp.test(individual?.phone),
            })}
            mask="(999) 999-9999"
            maskChar=" "
            name="phone"
            placeholder="(999) 999-9999"
            pattern="((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}"
            value={phoneCleaner(individual?.phone) || ""}
            onChange={handlePhoneChange}
            onInvalid={() => onError("Phone")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Email Address
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="email"
            // pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
            pattern="^[a-zA-Z0-9_.%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$"
            value={individual?.email || ""}
            onChange={handleChangeIndividual}
            onInvalid={() => onError("Email")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Country Of Citizenship
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="countryOfCitizenship"
            pattern="^(^[a-zA-Z0-9 \/\-\\]{1,30})$"
            value={individual?.countryOfCitizenship || ""}
            onChange={handleChangeIndividual}
            onInvalid={() => onError("Country Of Citizenship")}
          />
        </Col>
      </Form.Group>

      <IndividualAddressEdit
        required={!label}
        address={individual?.address}
        isForceShowing={isForceShowing}
        onError={onError}
        onChange={handleChangeIndividualAddress}
      />
    </>
  );
}
