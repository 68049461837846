import React from "react";
import { Form, Row } from "react-bootstrap";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import StatusView from "../Status/View";

function FormAnnualComplianceReport({ orderDetails, selectedProduct }) {
  const productDetails = orderDetails?.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">Annual Compliance Report</h4>

      <StatusView orderDetails={orderDetails} selectedProduct={selectedProduct} />

      {productDetails.webFileNumber !== undefined && (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Webfile Number:
          </Form.Label>
          <Form.Label column sm={7}>
            {productDetails?.webFileNumber || "—"}
          </Form.Label>
        </Form.Group>
      )}

      <FormPDF productDetails={productDetails} />
    </div>
  );
}

export default FormAnnualComplianceReport;
