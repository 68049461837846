import { Form, Row } from "react-bootstrap";

import { phoneFormatter } from "_helpers";

import StatusView from "../Status/View";

import Address from "components/OrderDetails/ProductDetails/Incorporation/Address/View";

export default function FormIncorpifyRaAmendment({ orderDetails, selectedProduct }) {
  const productDetails = orderDetails?.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">AMENDMENT</h4>

      <StatusView orderDetails={orderDetails} selectedProduct={selectedProduct} />

      <hr />
      <h6 className="font-weight-bold">RA Amendment Details</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Entity Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.companyName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First Name
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.firstName || "–"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Middle Name (optional)
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.middleName || "–"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Last Name
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.lastName || "–"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Phone Number
        </Form.Label>
        <Form.Label column sm={7}>
          {phoneFormatter(productDetails?.phone) || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Email Address
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.email || "–"}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">RA Address</h6>
      <Address address={productDetails?.raAddress} />
    </div>
  );
}
