const { orderFiltersConstants } = require("_constants");

export const orderFilterActions = {
  saveFilters,
};

function saveFilters(filters) {
  return (dispatch) => {
    dispatch({ type: orderFiltersConstants.SAVE_ORDER_FILTERS, payload: filters });
  };
}
