import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { usePaymentInputs } from "react-payment-inputs";

import { checkIfTestCard } from "_helpers";

import ConfirmationModal from "components/ConfirmationModal";

function AddPaymentMethodCard({ cardNumberInputId, onConfirm }) {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [cardMonth, setCardMonth] = useState("");
  const [cardYear, setCardYear] = useState("");
  const [replaceCard, setReplaceCard] = useState(false);
  const [beforeSubmit, setBeforeSubmit] = useState(true);

  const { meta, getCardNumberProps } = usePaymentInputs();
  const { erroredInputs, touchedInputs } = meta;

  const getYearDropList = () => {
    const year = new Date().getFullYear();

    return Array.from(new Array(20), (v, i) => (
      <option key={i} value={(year + i) % 100}>
        {year + i}
      </option>
    ));
  };

  const checkDate = () => {
    if (cardYear && cardMonth) {
      const expDate = new Date().setFullYear("20" + cardYear, cardMonth, 1);
      const today = new Date();

      return expDate < today;
    }

    return true;
  };

  const handleChangeCardNumber = ({ target: { value } }) => setCardNumber(value);
  const handleChangeCardMonth = ({ target: { value } }) => setCardMonth(value);
  const handleChangeCardYear = ({ target: { value } }) => setCardYear(value);
  const handleChangeReplaceCard = ({ target: { checked } }) => setReplaceCard(checked);
  const handleUpdate = (e) => {
    e.preventDefault();
    setBeforeSubmit(false);

    const section1 = document.querySelector(".is-invalid");

    if (section1 != null) section1.scrollIntoView({ behavior: "smooth", block: "start" });

    if (
      (typeof erroredInputs.cardNumber === "undefined" || checkIfTestCard(cardNumber)) &&
      typeof erroredInputs.cvc === "undefined" &&
      !checkDate()
    ) {
      const form = e.currentTarget;

      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        const section = document.querySelector(".form-control:invalid");
        if (section != null) section.scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        window.scrollTo(0, 0);
        setConfirmOpen(true);
      }
    }
  };

  const cardNumberOptions = {
    onChange: handleChangeCardNumber,
  };

  if (cardNumberInputId) {
    cardNumberOptions.id = cardNumberInputId;
  }

  return (
    <div className="form-border payment-method-form">
      <h6 className="font-weight-bold">Add / Replace Billing Method</h6>

      <Row>
        <Form.Label column sm={12}>
          Card Number
        </Form.Label>
        <Col sm={12}>
          <Form.Control
            size="sm"
            {...getCardNumberProps(cardNumberOptions)}
            value={cardNumber}
            isInvalid={
              !checkIfTestCard(cardNumber) &&
              ((touchedInputs.cardNumber && erroredInputs.cardNumber) ||
                ((!cardNumber || cardNumber.length === 0) && !beforeSubmit))
            }
            placeholder="0000 0000 0000 0000"
            data-threeds="pan"
          />
        </Col>
      </Row>

      <Row>
        <Form.Label column sm={12}>
          Exp. Date
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            as="select"
            size="sm"
            value={cardMonth}
            isInvalid={checkDate() && !beforeSubmit}
            onChange={handleChangeCardMonth}
          >
            <option value="">Month</option>
            <option value="01">January</option>
            <option value="02">February</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
            <option value="06">June</option>
            <option value="07">July</option>
            <option value="08">August</option>
            <option value="09">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </Form.Control>
        </Col>
        <Col sm={5}>
          <Form.Control
            as="select"
            size="sm"
            value={cardYear}
            isInvalid={checkDate() && !beforeSubmit}
            onChange={handleChangeCardYear}
          >
            <option value="">Year</option>
            {getYearDropList()}
          </Form.Control>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex align-items-end mt-2">
          <Form.Check
            type="checkbox"
            label="Replace the Current Card on File"
            checked={replaceCard}
            name="replaceCard"
            onChange={handleChangeReplaceCard}
          />
        </Col>
      </Row>
      <br />

      <Row className="d-flex justify-content-center">
        <Col sm={3} className="d-flex justify-content-center">
          <Button size="sm" onClick={handleUpdate}>
            Update
          </Button>
        </Col>
      </Row>

      <ConfirmationModal
        show={confirmOpen}
        handleClose={() => {
          setConfirmOpen(false);
        }}
        handleConfirm={() => {
          const cardData = {
            cardNumber: cardNumber.replace(/\s/g, ""),
            cardType: meta.cardType?.type || "TESTCARD",
            cardMonth,
            cardYear,
          };

          onConfirm && onConfirm(cardData, replaceCard);
          setConfirmOpen(false);
        }}
        body="Are you sure you want to change Payment Method?"
      />
    </div>
  );
}

export default AddPaymentMethodCard;
