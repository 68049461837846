import { config } from "_config";
import { authHeader, handleResponse, mappingQueryParams } from "_helpers";

export const userService = {
  login,
  logout,
  saveuser,
  getAll,
  getById,
  update,
  getme,
  delete: _delete,
};

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(username + ":" + password),
    },
  };

  return fetch(`${config.apiUrl}/users/oauth2/token`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function getme(user) {
  const requestOptions = {
    method: "get",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/users/me`, requestOptions)
    .then(handleResponse)
    .then((userdetail) => {
      if (user) {
        const userdata = {
          ...user,
          ...{
            id: userdetail.id,
            email: userdetail.email,
            role: userdetail.role,
            username: userdetail.username,
            availableCategories: userdetail.availableCategories,
          },
        };
        localStorage.setItem("user", JSON.stringify(userdata));
        return userdata;
      }
      return userdetail;
    });
}

function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("zauth");
  // history.push('/login');
}

function getAll(params) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl}/users${mappingQueryParams(params)}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function saveuser(user) {
  let userdata = user;
  delete userdata.page;
  delete userdata.pagecount;
  delete userdata.id;
  delete userdata.editing;
  delete userdata.categories_list;
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(userdata),
  };

  return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}

function update(user) {
  let userdata = user;
  delete userdata.page;
  delete userdata.pagecount;
  delete userdata.editing;
  delete userdata.categories_list;
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(`${config.apiUrl}/users/${user.id}`, requestOptions).then(handleResponse);
}

function _delete(user) {
  let userdata = user;
  userdata.status = "Disabled";
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(userdata),
  };

  return fetch(`${config.apiUrl}/users/${user.id}`, requestOptions).then(handleResponse);
}
