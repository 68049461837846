import { PRODUCT_CODES } from "variables/Variables";

import OrdersPage from "./OrdersPage";

const filtersPreset = [
  {
    type: "static",
    title: "General",
    filters: [
      {
        label: "Date From",
        name: "firstday",
        type: "date",
        changeAction: "default",
      },
      {
        label: "Date To",
        name: "lastday",
        type: "date",
        changeAction: "default",
      },
      {
        label: "Order ID",
        name: "orderId",
        placeholder: "Order ID",
        type: "text",
        changeAction: "default",
      },
      {
        label: "E-mail",
        name: "email",
        placeholder: "Email",
        type: "email",
        changeAction: "default",
      },
      {
        label: "Order Status",
        name: "orderStatus",
        type: "multiselect",
        changeAction: "multiselect",
        placeholder: "Status",
        optionList: "orderStatusArray",
      },
      {
        label: "Processing status",
        name: "einStatus",
        type: "select",
        changeAction: "default",
        optionList: "einStatusArray",
      },
    ],
  },
  {
    type: "expanded",
    title: "General (expanded)",
    filters: [
      {
        label: "First Name",
        name: "firstName",
        placeholder: "First Name",
        type: "text",
        changeAction: "default",
      },
      {
        label: "Last Name",
        name: "lastName",
        placeholder: "Last Name",
        type: "text",
        changeAction: "default",
      },
      {
        label: "Phone",
        name: "phone",
        placeholder: "Phone",
        type: "text",
        changeAction: "default",
      },
      {
        label: "Product Type",
        name: "productType",
        type: "select",
        optionList: "productCodeArray",
        changeAction: "default",
      },
      {
        label: "Processing Error",
        name: "einError",
        placeholder: "Error",
        type: "multiselect",
        optionList: "einErrorArray",
        changeAction: "multiselect",
      },
      {
        label: "Processing error status",
        name: "einErrorStatus",
        placeholder: "Error Status",
        type: "multiselect",
        optionList: "einErrorStatusArray",
        changeAction: "multiselect",
      },
      {
        label: "Show Tests",
        name: "includeTest",
        type: "select",
        optionList: "binarySwitchArray",
        changeAction: "default",
      },
      {
        label: "Assignee Type",
        name: "assigneeType",
        type: "select",
        optionList: "assigneeTypeOptions",
        changeAction: "default",
      },
      {
        label: "Assignee",
        name: "assigneeUser",
        type: "asyncselect",
        asyncOptionList: "loadUserOptions",
        changeAction: "asyncselect",
      },
      {
        label: "Only Customer Contact Required",
        name: "onlyCustomerContactRequired",
        type: "select",
        optionList: "binarySwitchArray",
        changeAction: "default",
      },
      {
        label: "Account Manager",
        name: "accountManager",
        type: "asyncselect",
        asyncOptionList: "loadAccountManagerOptions",
        changeAction: "asyncselect",
      },
      {
        label: "Processing Speed",
        name: "orderHasProduct",
        placeholder: "Options",
        type: "multiselect",
        optionList: "processingSpeedOptionsProducts",
        changeAction: "multiselect",
      },
    ],
  },
  {
    type: "expanded",
    title: "Advanced",
    filters: [
      {
        label: "LLC Name",
        name: "llcName",
        placeholder: "LLC Name",
        type: "text",
        changeAction: "default",
      },
      {
        label: "State",
        name: "incState",
        type: "select",
        optionList: "incStates",
        changeAction: "default",
      },
      {
        label: "Sub Status",
        name: "subStatuses",
        type: "select",
        optionList: "subStatusOptions",
        changeAction: "default",
      },
    ],
  },
];

export default function IncStatesOrder() {
  const orderPageProps = {
    filtersKey: "inc_state",
    category: "inc_states",
    filtersPreset,
    tableActions: {},
  };

  return <OrdersPage {...orderPageProps} />;
}
