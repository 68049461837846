import { reportsConstants } from "../_constants";

const initial_state = {
  loading: 0,
  campaigns: null,
  sales: null,
};

export function reports(state = initial_state, action) {
  switch (action.type) {
    case reportsConstants.GET_CAMPAIGNS_REQUEST:
      return { ...state, loading: state.loading + 1, campaigns: null };
    case reportsConstants.GET_CAMPAIGNS_FAILURE:
      return { ...state, loading: state.loading - 1, campaigns: null };
    case reportsConstants.GET_CAMPAIGNS_SUCCESS:
      return { ...state, loading: state.loading - 1, campaigns: action.campaigns };

    case reportsConstants.GET_SALES_BY_DOMAIN_REQUEST:
      return { ...state, loading: state.loading + 1, sales: null };
    case reportsConstants.GET_SALES_BY_DOMAIN_FAILURE:
      return { ...state, loading: state.loading - 1, sales: null };
    case reportsConstants.GET_SALES_BY_DOMAIN_SUCCESS:
      return { ...state, loading: state.loading - 1, sales: action.sales };

    default:
      return state;
  }
}
