import { config } from "_config";
import { authHeader, handleResponse } from "_helpers";

export const deployUIService = {
  getAll,
  deployUI,
};

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  let url = `${config.serviceurl}/services/ui/versions`;
  return fetch(url, requestOptions).then(handleResponse);
}

function deployUI(jobid, services_array) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  let url = `${config.serviceurl}/services/ui/jobs/${jobid}/deploy`;

  services_array.map((prop, key) => {
    if (key === 0) url += `?domains=${prop}`;
    else url += `&domains=${prop}`;
  });

  return fetch(url, requestOptions).then(handleResponse);
}
