import React from "react";
import styled from "styled-components";

const Container = styled.div`
  background-color: #f8f8f8;
  border-left: 4px solid #d0d0d0;
  border-right: 4px solid #d0d0d0;

  & .form-label,
  & .form-check-label {
    font-size: 0.875rem;
    text-transform: none;
    color: #2f2f2f;
  }
`;

const FormContainer = (props) => {
  const { children } = props;

  return <Container className="order-info mx-0 my-2 p-3 rounded">{children}</Container>;
};

export default FormContainer;
