import React from 'react';
import spinner from 'assets/img/bar-spinner.gif';

function Spinner(props) {
  return (
    <>
      <div className="loading-spinner">
        <h4>Loading...</h4>
        <img src={spinner} alt="spinner image" srcSet="" />
      </div>
    </>
  );
}

export default Spinner;
