import React from "react";
import moment from "moment";
import { Col, Row, Table } from "react-bootstrap";

const LogoDesignRevisions = ({ orderDetails, selectedProduct }) => {
  const productDetails = orderDetails?.products[selectedProduct];
  const reviewRequests = productDetails?.reviewRequests || [];

  function getRevisionsLeft() {
    switch (productDetails?.processingOption) {
      case "BASIC":
        return `${2 - reviewRequests.length} / 2`;

      case "STANDARD_PACKAGE":
        return `${4 - reviewRequests.length} / 4`;

      case "ULTIMATE":
        return "Unlimited";

      default:
        return "N/A";
    }
  }

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">Customer Revision Request</h4>
      <hr />

      <Row className="mb-2">
        <Col sm={3}>Package:</Col>
        <Col sm={9}>{productDetails?.processingOption || "–"}</Col>
      </Row>

      <Row className="mb-2">
        <Col sm={3}>Revisions Left:</Col>
        <Col sm={9}>{getRevisionsLeft()}</Col>
      </Row>

      <br />

      <Row>
        <Col lg md={12}>
          <Table responsive>
            <thead>
              <tr>
                <th style={{ width: "5%" }}>№</th>
                <th style={{ width: "10%" }}>Date</th>
                <th style={{ width: "auto" }}>Message</th>
              </tr>
            </thead>
            <tbody>
              {reviewRequests?.map((prop, key) => (
                <tr key={`revision-${key}`}>
                  <td style={{ cursor: "initial", verticalAlign: "top", color: "gray" }}>#{key + 1}</td>
                  <td style={{ cursor: "initial", verticalAlign: "top" }}>
                    {moment.utc(prop.timestamp).local().format("YYYY/MM/DD HH:mm:ss")}
                  </td>
                  <td style={{ cursor: "initial", verticalAlign: "top" }}>
                    {prop?.message?.split("\n").map((part, idx) => (
                      <div key={`revision-${key}-part-${idx}`}>{part}</div>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default LogoDesignRevisions;
