import IndividualView from "./Individual/View";
import StatusView from "../Status/View";

export default function FormTrademarkAmendment({ orderDetails, selectedProduct }) {
  const productDetails = orderDetails?.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">AMENDMENT</h4>

      <StatusView orderDetails={orderDetails} selectedProduct={selectedProduct} />

      <hr />
      <h6 className="font-weight-bold">Amendment Details</h6>

      <IndividualView isForceShowing individual={productDetails?.individual} />
    </div>
  );
}
