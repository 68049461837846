import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { usePaymentInputs } from "react-payment-inputs";

import { checkIfTestCard, combineFromCategories } from "_helpers";

import { state_array } from "variables/Variables";

const initialPayment = (processingOption) => {
  return {
    processingOption: processingOption || "",
    firstName: "",
    lastName: "",
    cardNumber: "",
    expMonth: "",
    expYear: "",
    cvv: "",
    billingAddress: {
      address1: "",
      city: "",
      state: "",
      zip: "",
      country: "US",
    },
  };
};

const getMonthOptions = () => [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const getYearOptions = () => {
  const startYear = new Date().getFullYear();

  return Array.from(new Array(20), (_, i) => {
    return { value: `${startYear + i}`, label: `${startYear + i}` };
  });
};

const checkIfCardExpired = ({ expMonth, expYear }) => {
  if (expYear !== null && expMonth !== null) {
    let expDate = new Date();
    expDate.setFullYear(expYear, expMonth, 1);
    let today = new Date();
    if (expDate < today) {
      return true;
    } else {
      return false;
    }
  }
  return true;
};

export default function PayForLeadModal({ show, orderDetails, handleClose = () => {}, handleConfirm = () => {} }) {
  const formEl = useRef(null);

  const allProcessingOptions = useSelector(({ variants }) => variants.allProcessingOptions);

  const processingOptionsOpt = combineFromCategories(allProcessingOptions);

  const { meta, getCardNumberProps } = usePaymentInputs();
  const { erroredInputs, touchedInputs } = meta;

  const [newPaymentDetails, setNewPaymentDetails] = useState(initialPayment(orderDetails?.order?.processingOption));
  const [validated, setValidated] = useState(false);

  const isCardNumberInvalid =
    validated &&
    !checkIfTestCard(newPaymentDetails.cardNumber) &&
    ((touchedInputs.cardNumber && erroredInputs.cardNumber) ||
      !newPaymentDetails.cardNumber ||
      newPaymentDetails.cardNumber.length === 0);

  const isCardNumberValid =
    checkIfTestCard(newPaymentDetails.cardNumber) ||
    (!!newPaymentDetails.cardNumber && touchedInputs.cardNumber && !erroredInputs.cardNumber);

  const handleChangeDetails = ({ target: { value, name } }) => {
    let newValue = value;

    if (["cardNumber", "cvv"].includes(name)) {
      newValue = newValue.replace(/\D/gi, "");
    }

    setNewPaymentDetails((prev) => ({ ...prev, [name]: newValue }));
  };

  const handleChangeBillingAddress = ({ target: { value, name } }) => {
    let newValue = value;

    if (["zip"].includes(name)) {
      newValue = newValue.replace(/\D/gi, "");
    }

    setNewPaymentDetails((prev) => ({ ...prev, billingAddress: { ...prev.billingAddress, [name]: newValue } }));
  };

  const cardNumberOptions = {
    onChange: handleChangeDetails,
  };

  const handleCancel = () => {
    handleClose();
    setNewPaymentDetails(initialPayment(orderDetails?.order?.processingOption));
    setValidated(false);
  };

  const handleSubmit = (e) => {
    const form = formEl.current;
    setValidated(true);

    if (
      form.checkValidity() === false ||
      !isCardNumberValid ||
      !newPaymentDetails.processingOption ||
      checkIfCardExpired(newPaymentDetails)
    ) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      handleConfirm(newPaymentDetails);
    }
  };

  return (
    <Modal show={show} onHide={handleCancel} size="lg" centered>
      <Modal.Header closeButton>Payment Details</Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group as={Col} sm={12} md={12} lg={12}>
            <Form.Label>Processing Option</Form.Label>
            <Form.Control
              required
              as="select"
              name="processingOption"
              placeholder="Processing Option"
              value={newPaymentDetails.processingOption || ""}
              isInvalid={validated && !newPaymentDetails.processingOption}
              isValid={validated && newPaymentDetails.processingOption}
              onChange={handleChangeDetails}
            >
              <option value="">Select...</option>
              {Object.keys(processingOptionsOpt).map((key) => (
                <option key={`processingOption-${key}`} value={key}>
                  {`${processingOptionsOpt[key]} – ${key}`}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">Required Processing Option</Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} sm={12} md={12} lg={12}>
            <Form.Label>Card Number</Form.Label>
            <Form.Control
              {...getCardNumberProps(cardNumberOptions)}
              name="cardNumber"
              placeholder="0000 0000 0000 0000"
              data-threeds="pan"
              value={newPaymentDetails.cardNumber || ""}
              isInvalid={isCardNumberInvalid}
              isValid={isCardNumberValid}
              // onChange={handleChangeDetails}
            />
            <Form.Control.Feedback type="invalid">Incorrect Card Number</Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Form validated={validated} noValidate ref={formEl}>
          <Row>
            <Form.Group as={Col} sm={6} md={6} lg={4}>
              <Form.Label>Exp. Month</Form.Label>
              <Form.Control
                required
                as="select"
                name="expMonth"
                placeholder="Month"
                value={newPaymentDetails.expMonth || ""}
                onChange={handleChangeDetails}
              >
                <option value="">Select...</option>
                {getMonthOptions().map(({ label, value }) => (
                  <option key={`expMonth-${value}`} value={value}>
                    {label}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">Required Month</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} sm={6} md={6} lg={4}>
              <Form.Label>Exp. Year</Form.Label>
              <Form.Control
                required
                as="select"
                name="expYear"
                placeholder="Year"
                value={newPaymentDetails.expYear || ""}
                onChange={handleChangeDetails}
              >
                <option value="">Select...</option>
                {getYearOptions().map(({ label, value }) => (
                  <option key={`expYear-${value}`} value={value}>
                    {label}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">Required Year</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} sm={6} md={6} lg={4}>
              <Form.Label>CVC/CVV</Form.Label>
              <Form.Control
                required
                name="cvv"
                placeholder="CVC/CVV"
                maxLength="4"
                pattern="^[0-9]{3,4}$"
                value={newPaymentDetails.cvv || ""}
                onChange={handleChangeDetails}
              />
              <Form.Control.Feedback type="invalid">Incorrect CVV/CVC</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} sm={12} md={6} lg={6}>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                required
                name="firstName"
                placeholder="First Name"
                pattern="^(?=\S)[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]*(?<=[^\s])$"
                value={newPaymentDetails.firstName || ""}
                onChange={handleChangeDetails}
              />
              <Form.Control.Feedback type="invalid">Incorrect First Name</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} sm={12} md={6} lg={6}>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                required
                name="lastName"
                placeholder="Last Name"
                pattern="^(?=\S)[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]*(?<=[^\s])$"
                value={newPaymentDetails.lastName || ""}
                onChange={handleChangeDetails}
              />
              <Form.Control.Feedback type="invalid">Incorrect Last Name</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <hr />
          <Row>
            <Form.Group as={Col} sm={12}>
              <Form.Label>Billing Address</Form.Label>
              <Form.Control
                required
                name="address1"
                placeholder="Billing Address"
                pattern="^(?=\S)[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]*(?<=[^\s])$"
                value={newPaymentDetails.billingAddress.address1 || ""}
                onChange={handleChangeBillingAddress}
              />
              <Form.Control.Feedback type="invalid">Incorrect Billing Address</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} sm={12}>
              <Form.Label>City</Form.Label>
              <Form.Control
                required
                name="city"
                placeholder="City"
                pattern="^(?=\S)[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]*(?<=[^\s])$"
                value={newPaymentDetails.billingAddress.city || ""}
                onChange={handleChangeBillingAddress}
              />
              <Form.Control.Feedback type="invalid">Incorrect City</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} sm={12} md={6}>
              <Form.Label>State</Form.Label>
              <Form.Control
                required
                as="select"
                name="state"
                placeholder="Select..."
                value={newPaymentDetails.billingAddress.state || ""}
                onChange={handleChangeBillingAddress}
              >
                <option value="">Select...</option>
                {Object.keys(state_array).map((key) => (
                  <option key={`state-${key}`} value={key}>
                    {state_array[key]}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">Required State</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} sm={12} md={6}>
              <Form.Label>ZIP</Form.Label>
              <Form.Control
                required
                name="zip"
                placeholder="ZIP"
                maxLength="5"
                pattern="^[0-9]{5}$"
                value={newPaymentDetails.billingAddress.zip || ""}
                onChange={handleChangeBillingAddress}
              />
              <Form.Control.Feedback type="invalid">Incorrect ZIP</Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="success" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
