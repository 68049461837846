import { customerConstants } from "_constants";

const initialState = {
  loading: 0,
  exportLoading: 0,
  unsubscription: false,
  orders: {},
  orderdata: {},
  availableStatuses: {},
  collapseFederalSearchResults: [],
  collapseStateSearchResults: [],
  collapseMonitoringReport: [],
  relatedorders: {},
  relatedOrdersLoading: false,
  reportdata: {},
  trackingInfo: {},
  paymentMethods: [],
  subscriptionDetails: {},
  error: null,
};

export function customers(state = initialState, action) {
  const { type, payload, error } = action;

  switch (type) {
    // get orders list
    case customerConstants.GET_ORDERS_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.GET_ORDERS_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1, orders: payload };
    case customerConstants.GET_ORDERS_FAILURE:
      return { ...state, loading: state.loading && state.loading - 1, error: payload };
    // get order details
    case customerConstants.GET_ORDER_REQUEST:
      return { ...state, loading: state.loading + 1, orderdata: {}, collapseFederalSearchResults: [] };
    case customerConstants.GET_ORDER_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1, orderdata: payload, unsubscription: false };
    case customerConstants.GET_ORDER_FAILURE:
      return { ...state, loading: state.loading && state.loading - 1 };
    // delete order
    case customerConstants.DELETE_ORDER_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.DELETE_ORDER_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1, orderdata: { wasDeleted: true } };
    case customerConstants.DELETE_ORDER_FAILURE:
      return { ...state, loading: state.loading && state.loading - 1 };
    // add note to order
    case customerConstants.POST_NOTE_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.POST_NOTE_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1 };
    case customerConstants.POST_NOTE_FAILURE:
      return { ...state, loading: state.loading && state.loading - 1 };
    // get product available statuses
    case customerConstants.GET_AVAILABLE_STATUSES_REQUEST:
      return { ...state, loading: state.loading + 1, availableStatuses: {} };
    case customerConstants.GET_AVAILABLE_STATUSES_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1, availableStatuses: payload };
    case customerConstants.GET_AVAILABLE_STATUSES_FAILURE:
      return { ...state, loading: state.loading && state.loading - 1 };
    // get related orders list
    case customerConstants.GET_RELATED_ORDERS_REQUEST:
      return { ...state, relatedOrdersLoading: true, relatedorders: {} };
    case customerConstants.GET_RELATED_ORDERS_SUCCESS:
      return { ...state, relatedOrdersLoading: false, relatedorders: payload };
    case customerConstants.GET_RELATED_ORDERS_FAILURE:
      return { ...state, relatedOrdersLoading: false };
    // add related order
    case customerConstants.ADD_RELATED_ORDER_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.ADD_RELATED_ORDER_SUCCESS:
      return { ...state, loading: state.loading - 1 };
    case customerConstants.ADD_RELATED_ORDER_FAILURE:
      return { ...state, loading: state.loading - 1 };
    // put order
    case customerConstants.PUT_ORDER_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.PUT_ORDER_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1 };
    case customerConstants.PUT_ORDER_FAILURE:
      return { ...state, loading: state.loading && state.loading - 1 };
    // post file
    case customerConstants.POST_ORDER_FILE_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.POST_ORDER_FILE_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1 };
    case customerConstants.POST_ORDER_FILE_FAILURE:
      return { ...state, loading: state.loading && state.loading - 1 };
    // delete file
    case customerConstants.DELETE_ORDER_FILE_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.DELETE_ORDER_FILE_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1 };
    case customerConstants.DELETE_ORDER_FILE_FAILURE:
      return { ...state, loading: state.loading && state.loading - 1 };
    // get dashboard report
    case customerConstants.GET_DASHBOARD_REPORT_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.GET_DASHBOARD_REPORT_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1, reportdata: action.reportdata };
    case customerConstants.GET_DASHBOARD_REPORT_FAILED:
      return { ...state, loading: state.loading && state.loading - 1, error };
    // get tracking
    case customerConstants.GET_TRACKING_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.GET_TRACKING_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1, trackingInfo: action.trackingInfo };
    case customerConstants.GET_TRACKING_FAILED:
      return { ...state, loading: state.loading && state.loading - 1, error };
    // subscription: unsubscribe
    case customerConstants.UNSUBSCRIBE_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.UNSUBSCRIBE_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1, unsubscription: true };
    case customerConstants.UNSUBSCRIBE_FAILED:
      return { ...state, loading: state.loading && state.loading - 1, error };
    // get payment methods
    case customerConstants.GET_PAYMENT_METHODS_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.GET_PAYMENT_METHODS_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1, paymentMethods: payload };
    case customerConstants.GET_PAYMENT_METHODS_FAILED:
      return { ...state, loading: state.loading && state.loading - 1 };
    // post payment method
    case customerConstants.POST_PAYMENT_METHOD_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.POST_PAYMENT_METHOD_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1 };
    case customerConstants.POST_PAYMENT_METHOD_FAILED:
      return { ...state, loading: state.loading && state.loading - 1 };
    // set payment details
    case customerConstants.POST_PAYMENT_DETAILS_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.POST_PAYMENT_DETAILS_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1 };
    case customerConstants.POST_PAYMENT_DETAILS_FAILED:
      return { ...state, loading: state.loading && state.loading - 1 };
    // subscription: details
    case customerConstants.GET_SUBSCRIPTION_DETAILS_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.GET_SUBSCRIPTION_DETAILS_SUCCESS:
      return {
        ...state,
        loading: state.loading && state.loading - 1,
        subscriptionDetails: { ...state.subscriptionDetails, [payload.productId]: payload.subscriptionDetails },
      };
    case customerConstants.GET_SUBSCRIPTION_DETAILS_FAILED:
      return { ...state, loading: state.loading && state.loading - 1 };
    // subscription: rerun
    case customerConstants.RERUN_SUBSCRIPTION_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.RERUN_SUBSCRIPTION_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1 };
    case customerConstants.RERUN_SUBSCRIPTION_FAILED:
      return { ...state, loading: state.loading && state.loading - 1 };

    case customerConstants.SET_COLLAPSE_FEDERAL_SEARCH: {
      const collapseFederalSearchResults = state.collapseFederalSearchResults.includes(payload)
        ? state.collapseFederalSearchResults.filter((idx) => idx !== payload)
        : [...state.collapseFederalSearchResults, payload];

      return { ...state, collapseFederalSearchResults };
    }

    case customerConstants.SET_COLLAPSE_STATE_SEARCH: {
      const collapseStateSearchResults = state.collapseStateSearchResults.includes(payload)
        ? state.collapseStateSearchResults.filter((idx) => idx !== payload)
        : [...state.collapseStateSearchResults, payload];

      return { ...state, collapseStateSearchResults };
    }

    case customerConstants.SET_COLLAPSE_MONITORING_REPORT: {
      const collapseMonitoringReport = state.collapseMonitoringReport.includes(payload)
        ? state.collapseMonitoringReport.filter((idx) => idx !== payload)
        : [...state.collapseMonitoringReport, payload];

      return { ...state, collapseMonitoringReport };
    }

    // charge annual compliance report
    case customerConstants.CHARGE_ANNUAL_COMPLIANCE_REPORT_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.CHARGE_ANNUAL_COMPLIANCE_REPORT_SUCCESS:
      return { ...state, loading: state.loading - 1 };
    case customerConstants.CHARGE_ANNUAL_COMPLIANCE_REPORT_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // charge SOU Fee
    case customerConstants.CHARGE_SOU_FEE_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.CHARGE_SOU_FEE_SUCCESS:
      return { ...state, loading: state.loading - 1 };
    case customerConstants.CHARGE_SOU_FEE_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // charge Extenison Fee
    case customerConstants.CHARGE_EXTENSION_FEE_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.CHARGE_EXTENSION_FEE_SUCCESS:
      return { ...state, loading: state.loading - 1 };
    case customerConstants.CHARGE_EXTENSION_FEE_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // charge Office Action
    case customerConstants.CHARGE_OFFICE_ACTION_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.CHARGE_OFFICE_ACTION_SUCCESS:
      return { ...state, loading: state.loading - 1 };
    case customerConstants.CHARGE_OFFICE_ACTION_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // export orders
    case customerConstants.GET_EXPORT_ORDERS_REQUEST:
      return { ...state, exportLoading: state.exportLoading + 1 };
    case customerConstants.GET_EXPORT_ORDERS_SUCCESS:
      return { ...state, exportLoading: state.exportLoading - 1 };
    case customerConstants.GET_EXPORT_ORDERS_FAILURE:
      return { ...state, exportLoading: state.exportLoading - 1 };

    // charge revival from abandoment
    case customerConstants.CHARGE_REVIVAL_FROM_ABANDOMENT_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.CHARGE_REVIVAL_FROM_ABANDOMENT_SUCCESS:
      return { ...state, loading: state.loading - 1 };
    case customerConstants.CHARGE_REVIVAL_FROM_ABANDOMENT_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // charge sales tax permit state fee
    case customerConstants.CHARGE_SALES_TAX_PERMIT_STATE_FEE_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.CHARGE_SALES_TAX_PERMIT_STATE_FEE_SUCCESS:
      return { ...state, loading: state.loading - 1 };
    case customerConstants.CHARGE_SALES_TAX_PERMIT_STATE_FEE_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // charge sales tax permit state fee
    case customerConstants.CREATE_DELAYED_UPSELL_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.CREATE_DELAYED_UPSELL_SUCCESS:
      return { ...state, loading: state.loading - 1 };
    case customerConstants.CREATE_DELAYED_UPSELL_FAILURE:
      return { ...state, loading: state.loading - 1 };

    // unlock automation Action
    case customerConstants.UNLOCK_AUTOMATION_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case customerConstants.UNLOCK_AUTOMATION_SUCCESS:
      return { ...state, loading: state.loading - 1 };
    case customerConstants.UNLOCK_AUTOMATION_FAILURE:
      return { ...state, loading: state.loading - 1 };

    default:
      return state;
  }
}
