import { config } from "_config";
import { authHeader, handleResponse, mappingQueryParams } from "_helpers";

export const graphSalesLeadsService = { get };

function get(options) {
  const { startDate: from, endDate: to, category, zone } = options;
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl1}/orders/sales-graph${mappingQueryParams({ category, from, to, zone })}`;

  return fetch(url, requestOptions).then(handleResponse);
}
