import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { validateUrl } from "_helpers";
import { uiActions, customerActions, alertActions } from "_actions";

export default function EditLicenseModal({
  show,
  licenses = null,
  selectedLicenseIdx,
  mode = null,
  selectedProduct,
  initOrderDetails,
  handleClose = () => {},
}) {
  const dispatch = useDispatch();
  const formEl = useRef(null);

  const hash = useSelector(({ ui }) => ui.hash);

  const [newLicense, setNewLicense] = useState(null);
  const [validated, setValidated] = useState(false);

  const isAdd = mode === "add";
  const isEdit = mode === "edit";
  const isDelete = mode === "delete";

  const isUrlValid = validateUrl(newLicense?.url);

  const handleOnClose = () => {
    setValidated(false);
    handleClose();
  };

  const handleChangeLicense = ({ target: { value, name } }) => {
    const newObj = { ...newLicense, [name]: value };
    setNewLicense(newObj);
  };

  const handleAddLicense = () => {
    try {
      const isUrl = new URL(newLicense?.url);

      if (isUrl) {
        const newOrderDetails = {
          ...initOrderDetails,
          products: [
            ...initOrderDetails?.products.map((product, idx) => {
              if (idx === selectedProduct) {
                return {
                  ...product,
                  processingResult: {
                    ...product?.processingResult,
                    licenses: [...product?.processingResult?.licenses, newLicense],
                  },
                };
              }
              return product;
            }),
          ],
        };

        dispatch(uiActions.checkHash(hash, customerActions.updateorder(initOrderDetails?.order?.id, newOrderDetails)));
        setNewLicense(null);
      }
    } catch (error) {
      console.error(error.toString());
      dispatch(alertActions.error(error?.toString()));
    }
  };

  const handleEditLicense = (licenseIdx) => {
    try {
      const isUrl = new URL(newLicense?.url);

      if (isUrl) {
        const newOrderDetails = {
          ...initOrderDetails,
          products: [
            ...initOrderDetails?.products.map((product, idx) => {
              if (idx === selectedProduct) {
                return {
                  ...product,
                  processingResult: {
                    ...product?.processingResult,
                    licenses: [
                      ...product?.processingResult?.licenses?.map((item, idx) => {
                        if (idx === licenseIdx) {
                          return newLicense;
                        }

                        return item;
                      }),
                    ],
                  },
                };
              }
              return product;
            }),
          ],
        };

        dispatch(uiActions.checkHash(hash, customerActions.updateorder(initOrderDetails?.order?.id, newOrderDetails)));
        setNewLicense(null);
      }
    } catch (error) {
      console.error(error.toString());
      dispatch(alertActions.error(error?.toString()));
    }
  };

  const handleDeleteLicense = (licenseIdx) => {
    try {
      const newOrderDetails = {
        ...initOrderDetails,
        products: [
          ...initOrderDetails?.products.map((product, idx) => {
            if (idx === selectedProduct) {
              return {
                ...product,
                processingResult: {
                  ...product?.processingResult,
                  licenses: [...product?.processingResult?.licenses?.filter((_, idx) => idx !== licenseIdx)],
                },
              };
            }
            return product;
          }),
        ],
      };

      dispatch(uiActions.checkHash(hash, customerActions.updateorder(initOrderDetails?.order?.id, newOrderDetails)));
      handleClose();
    } catch (error) {
      console.error(error.toString());
      dispatch(alertActions.error(error?.toString()));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isDelete) {
      handleDeleteLicense(selectedLicenseIdx);
    } else {
      const form = formEl.current;
      if (form.checkValidity() === false || !isUrlValid) {
        setValidated(true);
      } else {
        setValidated(false);

        if (isAdd) {
          handleAddLicense();
        }
        if (isEdit) {
          handleEditLicense(selectedLicenseIdx);
        }
      }
    }
  };

  useEffect(() => {
    if (isEdit && selectedLicenseIdx !== null && licenses) {
      setNewLicense(licenses[selectedLicenseIdx]);
    }

    if (isAdd) {
      setNewLicense({
        licenseName: "",
        authority: "",
        url: "",
        description: "",
        appliesTo: "",
        includes: "",
      });
    }
  }, [mode, licenses, selectedLicenseIdx]);

  return (
    <Modal show={show} onHide={handleOnClose} backdrop="static" size="md" centered>
      <Modal.Header closeButton style={{ border: 0 }}>
        {isAdd && "Add New License"}
        {isEdit && "Edit License"}
        {isDelete && "Delete License"}
      </Modal.Header>
      <Modal.Body>
        {isDelete ? (
          <div>Are you sure you want to delete the license?</div>
        ) : (
          <>
            <Form validated={validated} noValidate ref={formEl}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  name="licenseName"
                  placeholder="Name"
                  // pattern="^(?=\S)[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]*(?<=[^\s])$"
                  // pattern="^(?=\S).*(?<=[^\s])$"
                  value={newLicense?.licenseName || ""}
                  onChange={handleChangeLicense}
                />
                <Form.Control.Feedback type="invalid">Please, provide correct License Name value</Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Authority</Form.Label>
                <Form.Control
                  required
                  name="authority"
                  placeholder="Authority"
                  // pattern="^(?=\S)[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]*(?<=[^\s])$"
                  // pattern="^(?=\S).*(?<=[^\s])$"
                  value={newLicense?.authority || ""}
                  onChange={handleChangeLicense}
                />
                <Form.Control.Feedback type="invalid">Please, provide correct Authority value</Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  required
                  name="description"
                  placeholder="Description"
                  // pattern="^(?=\S)[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]*(?<=[^\s])$"
                  // pattern="^(?=\S).*(?<=[^\s])$"
                  value={newLicense?.description || ""}
                  onChange={handleChangeLicense}
                />
                <Form.Control.Feedback type="invalid">Please, provide correct Description value</Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Applies to</Form.Label>
                <Form.Control
                  required
                  name="appliesTo"
                  placeholder="Applies To"
                  // pattern="^(?=\S)[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]*(?<=[^\s])$"
                  // pattern="^(?=\S).*(?<=[^\s])$"
                  value={newLicense?.appliesTo || ""}
                  onChange={handleChangeLicense}
                />
                <Form.Control.Feedback type="invalid">Please, provide correct Applies To value</Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  Includes <span style={{ color: "gray" }}>(optional)</span>
                </Form.Label>
                <Form.Control
                  name="includes"
                  placeholder="Includes"
                  // pattern="^(?=\S)[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]*(?<=[^\s])$"
                  // pattern="^(?=\S).*(?<=[^\s])$"
                  value={newLicense?.includes || ""}
                  onChange={handleChangeLicense}
                />
                <Form.Control.Feedback type="invalid">Please, provide correct Includes value</Form.Control.Feedback>
              </Form.Group>
            </Form>

            <Form.Group>
              <Form.Label>Link</Form.Label>
              <Form.Control
                required
                name="url"
                placeholder="https://www.example.com"
                // pattern="^(?=\S).*(?<=[^\s])$"
                value={newLicense?.url || ""}
                onChange={handleChangeLicense}
                isInvalid={!isUrlValid && validated}
                isValid={isUrlValid && validated}
              />
              <Form.Control.Feedback type="invalid">Please, provide correct Link value</Form.Control.Feedback>
            </Form.Group>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleOnClose}>
          Cancel
        </Button>

        {isAdd && (
          <Button variant="success" onClick={handleSubmit}>
            Add New License
          </Button>
        )}
        {isEdit && (
          <Button variant="success" onClick={handleSubmit}>
            Save Changes
          </Button>
        )}
        {isDelete && (
          <Button variant="danger" onClick={handleSubmit}>
            Delete License
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

EditLicenseModal.propTypes = {
  show: PropTypes.bool,
};
