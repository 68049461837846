import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Pagination, Table } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment-timezone";

import { getPriorityStatus, combineFromCategories, history } from "_helpers";
import { customerActions } from "_actions";
import Spinner from "components/Spinner";

const HeaderWithButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  button {
    margin: 30px 0 15px;
  }
`;

export default function RelatedOrders() {
  const dispatch = useDispatch();

  const variants = useSelector(({ variants }) => variants);
  const orderDetails = useSelector(({ customers: { orderdata } }) => orderdata);

  const processingStatusOpt = combineFromCategories(variants.allProcessingStatuses);
  const orderStatusOpt = combineFromCategories(variants.allOrderStatuses);
  const { relatedorders: relatedOrdersList, relatedOrdersLoading } = useSelector(({ customers }) => customers);

  const relatedOrders = relatedOrdersList.elements || [];

  const [currentPage, setCurrentPage] = useState(1);

  if (!relatedOrders || !orderDetails.order) return null;

  const isOrderPaid = ["Success", "FullRefund", "PartialRefund"].includes(orderDetails?.products?.[0]?.paymentStatus);

  const handlePrevStep = () => {
    const newPage = currentPage % 5 === 0 ? (currentPage / 5 - 2) * 5 + 1 : (Math.floor(currentPage / 5) - 1) * 5 + 1;
    setCurrentPage(newPage);
    dispatch(customerActions.getOrderRelatedOrders(orderDetails, true, newPage));
  };
  const handleNextStep = () => {
    const newPage = currentPage % 5 === 0 ? Number(currentPage) + 1 : (Math.floor(currentPage / 5) + 1) * 5 + 1;
    setCurrentPage(newPage);
    dispatch(customerActions.getOrderRelatedOrders(orderDetails, true, newPage));
  };

  const handleFirstPage = () => {
    const newPage = 1;
    setCurrentPage(newPage);
    dispatch(customerActions.getOrderRelatedOrders(orderDetails, true, newPage));
  };

  const handleNextPage = () => {
    const newPage = currentPage + 1;
    setCurrentPage(newPage);
    dispatch(customerActions.getOrderRelatedOrders(orderDetails, true, newPage));
  };

  const handlePrevPage = () => {
    const newPage = currentPage - 1;
    setCurrentPage(newPage);
    dispatch(customerActions.getOrderRelatedOrders(orderDetails, true, newPage));
  };

  const handleLastPage = () => {
    const newPage = relatedOrdersList.totalPages;
    setCurrentPage(newPage);
    dispatch(customerActions.getOrderRelatedOrders(orderDetails, true, newPage));
  };
  const handlePageChange = (page) => {
    const newPage = page;
    setCurrentPage(page);
    dispatch(customerActions.getOrderRelatedOrders(orderDetails, true, newPage));
  };

  const renderFooter = (orders) => {
    const pagination = [];

    let totalPages = 1;
    let totalResults = 0;

    if (orders && orders.elements) {
      totalPages = orders.totalPages;
      totalResults = orders.totalResults;
    }

    if (relatedOrdersLoading) {
      return (
        <div className="ml-2">
          <Spinner />
        </div>
      );
    }

    if (!totalResults) {
      return <div className="ml-2">No results</div>;
    }

    const step = currentPage % 5 === 0 ? currentPage / 5 - 1 : Math.floor(currentPage / 5);

    if (step > 0) pagination.push(<Pagination.Ellipsis key={"prevPageList"} onClick={handlePrevStep} />);

    for (let i = 1; i <= totalPages; i++) {
      if (i > step * 5 && i <= step * 5 + 5)
        pagination.push(
          <Pagination.Item
            key={i}
            active={currentPage === i}
            onClick={currentPage !== i ? () => handlePageChange(i) : null}
          >
            {i}
          </Pagination.Item>
        );
    }

    if (totalPages > (step + 1) * 5)
      pagination.push(<Pagination.Ellipsis key={"naxtPageList"} onClick={handleNextStep} />);

    return (
      <Pagination className="ml-2 mt-2">
        <Pagination.First disabled={currentPage === 1} onClick={handleFirstPage} />
        <Pagination.Prev disabled={currentPage === 1} onClick={handlePrevPage} />
        {pagination}
        <Pagination.Next disabled={currentPage === totalPages} onClick={handleNextPage} />
        <Pagination.Last disabled={currentPage === totalPages} onClick={handleLastPage} />
      </Pagination>
    );
  };

  return (
    <>
      <HeaderWithButton>
        <h4>Related Orders:</h4>
        <Button
          variant={`outline-${isOrderPaid ? "success" : "secondary"}`}
          className="btn btn-sm mr-1"
          title={isOrderPaid ? "" : "Order should be paid to create related order"}
          disabled={!isOrderPaid}
          onClick={() => {
            history.push(`/admin/orders/${orderDetails.order.id}/create-order`);
          }}
        >
          <i className="fa fa-plus" /> Create related order
        </Button>
      </HeaderWithButton>
      <Table responsive className="orders-table">
        <thead>
          <tr>
            <th>OrderID</th>
            <th>Date</th>
            <th>Product Type</th>
            <th>Order Amount</th>
            <th>Payment Status</th>
            <th>Filing Status</th>
          </tr>
        </thead>
        <tbody>
          <tr
            className={"table-bg-" + getPriorityStatus(orderDetails.products)}
            style={{ boxShadow: "0 0 5px 1px lightgray inset" }}
          >
            <td>
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  padding: "0 3px",
                  backgroundColor: "#3f51b5",
                  color: "white",
                  fontSize: "10px",
                }}
              >
                This Order
              </div>
              <div className="dropdown-item-text">{orderDetails?.order?.crmOrderId || "N/A"}</div>
            </td>
            <td>{moment.utc(orderDetails.products[0]?.paymentDate).local().format("MM/DD/YYYY HH:mm:ss")}</td>
            <td>{orderDetails.products[0]?.code.code}</td>
            <td>{orderDetails.order.total}</td>
            <td>{orderStatusOpt[orderDetails.products[0]?.paymentStatus]}</td>
            <td>
              {orderDetails.products.map(({ processingResult: { status } }) => processingStatusOpt[status]).join(", ")}
            </td>
          </tr>

          {relatedOrders.map(
            (prop, key) =>
              orderDetails.order.id !== prop.order.id && (
                <tr key={key} className={"table-bg-" + getPriorityStatus(prop.products)}>
                  <td>
                    <Link to={"/admin/orders/" + prop.order.id} className="dropdown-item">
                      {prop?.order?.crmOrderId || "N/A"}
                    </Link>
                  </td>
                  <td>{moment.utc(prop.products[0]?.paymentDate).local().format("MM/DD/YYYY HH:mm:ss")}</td>
                  <td>{prop.products[0]?.code.code}</td>
                  <td>{prop.order.total}</td>
                  <td>{orderStatusOpt[prop.products[0]?.paymentStatus]}</td>
                  <td>
                    {prop.products.map(({ processingResult: { status } }) => processingStatusOpt[status]).join(", ")}
                  </td>
                </tr>
              )
          )}
        </tbody>
      </Table>
      {renderFooter(relatedOrdersList)}
    </>
  );
}
