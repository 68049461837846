import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { createRetryMiddleware } from "_middlewares";
import rootReducer from "../_reducers";

const loggerMiddleware = createLogger();
const retryMiddleware = createRetryMiddleware();

export const store = createStore(rootReducer, applyMiddleware(thunkMiddleware, loggerMiddleware, retryMiddleware));
