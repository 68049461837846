import React, { useState, useEffect, useMemo } from "react";
import { Col, Form, Row, Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { adminActions, variantsActions } from "_actions";

import Spinner from "components/Spinner";
import EditRaAddressModal from "components/EditRaAddressModal";

const AddressesRA = () => {
  const dispatch = useDispatch();

  const availableCategories = useSelector(({ authentication: { user } }) => user.availableCategories);
  const { addressesRA } = useSelector(({ variantsOptions }) => variantsOptions);
  const { statesObj } = useSelector(({ variants }) => variants);
  const loadingAddresses = useSelector(({ variantsOptions: { loading } }) => loading);
  const loadingVariants = useSelector(({ variants: { loading } }) => loading);

  const [mode, setMode] = useState(null);
  const [search, setSearch] = useState("");
  const [currentState, setCurrentState] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(null);

  const loading = loadingAddresses + loadingVariants;

  const searchStateResults = useMemo(
    () => addressesRA?.filter((address) => currentState === "" || address?.state === currentState),
    [addressesRA, currentState]
  );

  const searchAddressResults = useMemo(
    () =>
      searchStateResults
        ?.filter(({ address, state }) => {
          const addressString = Object.values(address)
            .join(" ")
            .concat(" " + statesObj[state])
            .toLowerCase();
          return addressString.includes(search.toLowerCase());
        })
        .sort((a, b) => (a.state > b.state ? 1 : -1)),
    [searchStateResults, search]
  );

  const addressesColumns = [
    {
      key: "state",
      label: "State",
      style: { minWidth: "120px", width: "15%" },
      render: (value) => `${value} – ${statesObj[value]}`,
    },
    {
      key: "address",
      label: "Address",
      style: { minWidth: "200px", width: "80%" },
      render: (address) => {
        if (!address) return null;

        const { address1, address2, city, state, zip } = address;
        return (
          <div>
            <div>{address1}</div>
            <div>{address2}</div>
            <div>{`${city}, ${state} ${zip}`}</div>
          </div>
        );
      },
    },
  ];

  const headerRenderer = () => (
    <thead>
      <tr style={{ tableLayout: "fixed" }}>
        {addressesColumns.map(({ key, label, render, ...props }) => (
          <th key={key} {...props}>
            {label}
          </th>
        ))}
        <th style={{ minWidth: "160px", width: "5%" }}>Edit</th>
      </tr>
    </thead>
  );

  const dataRowRenderer = (data, key) => {
    return (
      <tr key={key}>
        {addressesColumns.map(({ key: colKey, render, ...props }) => (
          <td key={key + colKey} {...props}>
            {render ? render(data[colKey]) : data[colKey] || "—"}
          </td>
        ))}
        <td style={{ minWidth: "80px", maxWidth: "80px" }}>
          <Button
            className="mb-1 ml-1"
            size="sm"
            variant="warning"
            style={{ width: "60px" }}
            onClick={() => {
              setMode("edit");
              setSelectedAddress(data);
            }}
          >
            Edit
          </Button>
        </td>
      </tr>
    );
  };

  const tableBodyRenderer = () => {
    if (!searchAddressResults?.length || loading) {
      return loading ? (
        <tbody>
          <tr>
            <td colSpan={5}>
              <Spinner />
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          <tr>
            <td colSpan={5}>
              No Addresses {currentState !== "" && `for "${statesObj[currentState]}" state`}{" "}
              {search !== "" ? "with that search request" : ""}
            </td>
          </tr>
        </tbody>
      );
    }

    return <tbody>{searchAddressResults?.map(dataRowRenderer)}</tbody>;
  };

  useEffect(() => {
    dispatch(adminActions.getAddressesRA());
    dispatch(variantsActions.getStates(availableCategories[0] || "EIN"));
  }, []);

  return (
    <div>
      <Row className="option-wrapper" style={{ padding: "10px" }}>
        <Form.Group as={Col} lg="4" md="6" sm="12">
          <Form.Label>State</Form.Label>
          <Form.Control
            size="sm"
            as="select"
            value={currentState}
            onChange={({ target: { value } }) => setCurrentState(value)}
          >
            <option value={""}>Please, select state to find</option>
            {Object.keys(statesObj).map((state, idx) => (
              <option value={state} key={`${state}-${idx}`}>
                {state} – {statesObj[state]}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} lg="4" md="6" sm="12">
          <Form.Label>Search By Address</Form.Label>
          <Form.Control
            size="sm"
            placeholder="Please, type part of address to find"
            value={search}
            onChange={({ target: { value } }) => {
              setSearch(value);
            }}
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Actions</Form.Label>
          <Col className="p-0">
            <Button
              // className="pull-right"
              size="sm"
              variant="success"
              onClick={() => {
                setMode("add");
                setSelectedAddress({
                  state: "",
                  address: {
                    address1: "",
                    address2: "",
                    city: "",
                    state: "",
                    zip: "",
                    country: null,
                    county: null,
                  },
                });
              }}
            >
              Add New Address
            </Button>
          </Col>
        </Form.Group>
      </Row>
      <Row style={{ padding: "10px" }}>
        <Table responsive hover bordered striped size="lg" style={{ width: "100% !important" }}>
          {headerRenderer()}
          {tableBodyRenderer()}
        </Table>
      </Row>

      <EditRaAddressModal
        show={Boolean(selectedAddress)}
        mode={mode}
        address={selectedAddress}
        currentState={currentState}
        handleClose={() => {
          setMode(null);
          setSelectedAddress(null);
        }}
      />
    </div>
  );
};

export default AddressesRA;
