export const offerConstants = {
  GET_OFFER_CLICKS_REQUEST: 'GET_OFFER_CLICKS_REQUEST',
  GET_OFFER_CLICKS_SUCCESS: 'GET_OFFER_CLICKS_SUCCESS',
  GET_OFFER_CLICKS_FAIL: 'GET_OFFER_CLICKS_FAIL',
  GET_OFFER_REQUEST: 'GET_OFFER_REQUEST',
  GET_OFFER_SUCCESS: 'GET_OFFER_SUCCESS',
  GET_OFFER_FAIL: 'GET_OFFER_FAIL',
  ADD_OFFER_SUCCESS: 'ADD_OFFER_SUCCESS',
  ADD_OFFER_FAIL: 'ADD_OFFER_FAIL',
  RESET_OFFER_STATE: 'RESET_OFFER_STATE',
};
