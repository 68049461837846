import { graphSalesLeadsConstants } from "_constants";

const initState = {
  loading: false,
  graph: {},
};

export function graphSalesLeads(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case graphSalesLeadsConstants.GET_REQUEST:
      return { ...state, loading: true };
    case graphSalesLeadsConstants.GET_SUCCESS:
      return { ...state, loading: false, graph: payload };
    case graphSalesLeadsConstants.GET_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
}
