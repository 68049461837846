import { zendeskConstants } from '../_constants';
import { ZendeskService } from '../_services';
import { alertActions } from './';

export const zendeskactions = {
  getticket,
  savefilter,
  get_zendesk_account,
  add_zendesk_account,
  update_zendesk_account,
};

function getticket(ticket_ids, customer_email, order_id) {
  let ticket_array = [];

  return (dispatch) => {
    for (let i = 0; i < ticket_ids.length; i++) {
      ZendeskService.getticket(ticket_ids[i].id).then(
        (result) => {
          ticket_array.push(result.ticket);
          if (ticket_array.length === ticket_ids.length) {
            dispatch(success(ticket_array));
          }
        },
        (error) => {
          if (error.status === 404) {
            ZendeskService.unlinkticket(ticket_ids[i].id).then(
              (result) => {},
              (error) => {},
            );
          } else {
            dispatch(alertActions.error('Zendesk access token is expired.'));
          }
        },
      );
    }
  };

  function success(result) {
    return { type: zendeskConstants.GETTICKET_SUCCESS, result };
  }
}

function get_zendesk_account(status) {
  return (dispatch) => {
    ZendeskService.get_zendesk_account(status).then(
      (accounts) => dispatch(success(accounts)),
      (error) => {},
    );
  };
  function success(accounts) {
    return { type: zendeskConstants.GET_ZENDESK_ACCOUNT_SUCCESS, accounts };
  }
}

function add_zendesk_account(account) {
  return (dispatch) => {
    ZendeskService.add_zendesk_account(account).then(
      (account) => {
        dispatch(alertActions.success('New account has been created'));
        dispatch(success(account));
      },
      (error) => {},
    );
  };
  function success(account) {
    return { type: zendeskConstants.ADD_ZENDESK_ACCOUNT_SUCCESS, account };
  }
}

function update_zendesk_account(account, status) {
  return (dispatch) => {
    ZendeskService.update_zendesk_account(account).then(
      (account) => {
        dispatch(alertActions.success('Account has been updated'));
        ZendeskService.get_zendesk_account(status).then(
          (accounts) => dispatch(success(accounts)),
          (error) => {},
        );
      },
      (error) => {
        if (error.status === 422) {
          dispatch(alertActions.error('url must match "https://.*.zendesk.com/"'));
        }
      },
    );
  };

  function success(accounts) {
    return { type: zendeskConstants.GET_ZENDESK_ACCOUNT_SUCCESS, accounts };
  }
}

function savefilter(options) {
  return (dispatch) => {
    dispatch(request(options));
  };

  function request(options) {
    return { type: zendeskConstants.SAVE_TICKET_FILTER, options };
  }
}
