export const variantsOptionsConstants = {
  // GET ACTIONS
  GET_ACTIONS_REQUIRED_LIST_REQUEST: "GET_ACTIONS_REQUIRED_LIST_REQUEST",
  GET_ACTIONS_REQUIRED_LIST_SUCCESS: "GET_ACTIONS_REQUIRED_LIST_SUCCESS",
  GET_ACTIONS_REQUIRED_LIST_FAILED: "GET_ACTIONS_REQUIRED_LIST_FAILED",
  // POST ACTION
  POST_ACTION_REQUEST: "POST_ACTION_REQUEST",
  POST_ACTION_SUCCESS: "POST_ACTION_SUCCESS",
  POST_ACTION_FAILED: "POST_ACTION_FAILED",

  // GET ADDRESSES RA
  GET_ADDRESSES_RA_REQUEST: "GET_ADDRESSES_RA_REQUEST",
  GET_ADDRESSES_RA_SUCCESS: "GET_ADDRESSES_RA_SUCCESS",
  GET_ADDRESSES_RA_FAILED: "GET_ADDRESSES_RA_FAILED",
  // PUT ADDRESSES RA
  PUT_ADDRESS_RA_REQUEST: "PUT_ADDRESS_RA_REQUEST",
  PUT_ADDRESS_RA_SUCCESS: "PUT_ADDRESS_RA_SUCCESS",
  PUT_ADDRESS_RA_FAILED: "PUT_ADDRESS_RA_FAILED",
};
