import { useState } from "react";
import { Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";

import { customerActions } from "_actions";

export default function Tracking(props) {
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState(false);
  const { orderDetails } = props;

  const trackingInfo = useSelector((state) => (state.customers.trackingInfo ? state.customers.trackingInfo : {}));

  const fetchTrackingInformation = (e) => {
    dispatch(customerActions.getTrackingInfo(e.target.value));
    setShowDetails(true);
  };

  return (
    <>
      <Form.Label column sm="4">
        Tracking Number:
      </Form.Label>
      {orderDetails.order.trackingNumber !== null ? (
        <Col sm="8">
          <div>
            <a className="trackingNumbers" href={orderDetails.order.trackingURL} target="_blank">
              {orderDetails.order.trackingNumber}
            </a>
          </div>
        </Col>
      ) : (
        <Col sm="8">
          <div>No Tracking Number Found</div>
        </Col>
      )}
      {trackingInfo.trackingDetails != null && trackingInfo.trackingDetails.length > 0 ? (
        <>
          {showDetails ? (
            <>
              <Form.Label column sm="4">
                Last Note:
              </Form.Label>
              <Col sm="8">{trackingInfo.trackingDetails[0].note}</Col>
              <Form.Label column sm="4">
                Timestamp
              </Form.Label>
              <Col sm="8">{moment(trackingInfo.trackingDetails[0].timestamp).format("YYYY/MM/DD HH:mm:ss")}</Col>
            </>
          ) : null}
        </>
      ) : null}

      <Form.Label column sm="4">
        Return Tracking Number:
      </Form.Label>
      {orderDetails.order.returnTrackingNumber !== null ? (
        <Col sm="8">
          <div>
            <a className="trackingNumbers" href={orderDetails.order.returnTrackingURL} target="_blank">
              {orderDetails.order.returnTrackingNumber}
            </a>
          </div>
        </Col>
      ) : (
        <Col sm="8">
          <div>No Return Tracking Number Found</div>
        </Col>
      )}
      {trackingInfo.returnTrackingDetails != null && trackingInfo.returnTrackingDetails.length > 0 ? (
        <>
          {showDetails ? (
            <>
              <Form.Label column sm="4">
                Last Note:
              </Form.Label>
              <Col sm="8">{trackingInfo.returnTrackingDetails[0].note}</Col>
              <Form.Label column sm="4">
                Timestamp
              </Form.Label>
              <Col sm="8">{moment(trackingInfo.returnTrackingDetails[0].timestamp).format("YYYY/MM/DD HH:mm:ss")}</Col>
            </>
          ) : null}
        </>
      ) : null}

      <Col sm="8">
        <div>
          {showDetails ? (
            <Button type="button" variant="primary" size="sm" onClick={(e) => setShowDetails(false)}>
              {" "}
              Hide Details
            </Button>
          ) : (
            <Button
              type="button"
              value={orderDetails.order.id}
              variant="primary"
              size="sm"
              onClick={fetchTrackingInformation}
            >
              Get Details
            </Button>
          )}
        </div>
      </Col>
    </>
  );
}
