import moment from "moment";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";

import DateEdit from "components/OrderDetails/ProductDetails/Incorporation/Date/Edit";

export default function IncorporatedDateMissingModal({ show, handleClose = () => {}, handleConfirm = () => {} }) {
  const [incDate, setIncDate] = useState(moment().format("YYYY-MM-DD"));

  return (
    <Modal show={show} onHide={handleClose} size="md" centered>
      <Modal.Header closeButton>Warning</Modal.Header>
      <Modal.Body>
        <p>What is the official formation date?</p>
        <p>
          (This <b style={{ textDecoration: "underline" }}>MUST</b> be the date listed on the official Articles of
          Organization!)
        </p>
        <div>
          <DateEdit date={incDate} onChange={(value) => setIncDate(value)} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="success" onClick={() => handleConfirm(incDate)}>
          Save date
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
