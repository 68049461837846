import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import StatusEdit from "../../Status/Edit";

export default function FormTrademarkUSPTOFilingFee({ orderDetails, selectedProduct, onChange, ...props }) {
  const productDetails = orderDetails.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">USPTO FILING FEE PRODUCT</h4>

      <StatusEdit orderDetails={orderDetails} selectedProduct={selectedProduct} onChange={onChange} />

      <FormPDF productDetails={productDetails} {...props} />
    </div>
  );
}
