import React from "react";
import styled, { css } from "styled-components";

import ChartistGraph from "react-chartist";
const StyledChartistGraph = styled(ChartistGraph)`
  height: auto !important;
  min-width: 640px;

  .ct-bar {
    stroke-width: 16;
  }

  .ct-series-a .ct-bar {
    stroke: #42a5f5;
  }

  .ct-chart-bar .ct-label.ct-horizontal.ct-start {
    font-size: 12px;
  }

  .ct-chart-bar .ct-label.ct-vertical.ct-start {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    position: absolute;
    text-align: end;
    line-height: 12px;
    font-size: 12px;
    color: #000;
  }

  & > svg {
    ${({ height }) =>
      height &&
      css`
        height: ${height}px !important;
      `}
  }
`;

const WarningText = styled.div`
  width: max-content;
  text-align: center;
  margin: 40px auto;
  padding: 16px 8px;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  background-color: #ffcdd2;
`;

const GraphHorizontal = ({ data, count }) => {
  const dataLabel = (label, idx) => {
    const percent = (100 * data.series[0][data.series[0].length - idx - 1]) / count;

    return `${label} - ${data.series[0][data.series[0].length - idx - 1]} (${
      !Number.isNaN(percent) ? percent.toFixed(2) : Number(0).toFixed(2)
    }%)`;
  };

  const optionsBar = {
    axisX: {
      position: "start",
      showGrid: true,
      scaleMinSpace: 50,
      labelInterpolationFnc: (value) => (Number.isInteger(value) ? value : ""),
    },
    axisY: {
      offset: 330,
      labelInterpolationFnc: dataLabel,
    },
    chartPadding: {
      top: 20,
      right: 50,
      bottom: 15,
      left: 0,
    },
    horizontalBars: true,
    reverseData: true,
  };

  return (
    <>
      {data.series[0]?.length && data.labels.length ? (
        <StyledChartistGraph
          height={data.labels?.length ? data.labels.length * 30 + 100 : 0}
          data={data}
          type="Bar"
          options={optionsBar}
        />
      ) : (
        <WarningText>Select as least one status to show or change date range</WarningText>
      )}
    </>
  );
};

export default GraphHorizontal;
