import { Modal, Button } from "react-bootstrap";

export default function ConfirmationModal({ show, handleClose = () => {}, handleConfirm = () => {}, body, header }) {
  return (
    <Modal show={show} onHide={handleClose} size="md" centered>
      <Modal.Header closeButton style={{ border: 0 }}>
        {header}
      </Modal.Header>
      <Modal.Body>
        {typeof body === "function" ? body() : body || "Are you sure you want to do this action?"}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          No
        </Button>
        <Button variant="danger" onClick={handleConfirm}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
