import React from "react";
import { Navbar, DropdownButton, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export const AdminNavbar = (props) => {
  const username = useSelector((state) => state.authentication.user.username);

  const handleNavBar = (e) => {
    e.stopPropagation();
    props.handleNavBar();
  };

  return (
    <Navbar expand="lg" className="navbar-default">
      <Navbar.Toggle onClick={handleNavBar} />
      <Navbar.Brand>
        <a href="#">{props.brandText}</a>
      </Navbar.Brand>

      <Navbar.Collapse className="justify-content-end show">
        <DropdownButton menuAlign="right" title={username} variant="outline-primary">
          <Link to="/admin/profile" className="dropdown-item">
            <i className="fa fa-user"></i> Profile
          </Link>
          <Dropdown.Divider />
          <Link to="/login" className="dropdown-item">
            <i className="fa fa-sign-out"></i> Logout
          </Link>
        </DropdownButton>
      </Navbar.Collapse>
    </Navbar>
  );
};
