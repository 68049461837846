import { Row, Col, Form, Button } from "react-bootstrap";
import moment from "moment-timezone";

export const initTrademarkEvent = {
  code: "",
  type: "",
  descriptionText: "",
  date: moment().format("YYYY-MM-DD"),
  number: "",
};

export default function TrademarkEventList({ trademarkEvents, onError, onChange }) {
  const handleAddEvent = () => onChange([...trademarkEvents, { ...initTrademarkEvent }]);

  const handleDeleteEvent = (deleteIdx) => onChange(trademarkEvents.filter((o, _idx) => deleteIdx !== _idx));

  const handleEventChange = ({ target: { name, value } }, index) =>
    onChange(
      trademarkEvents.map((trademarkEvent, _idx) =>
        _idx === index ? { ...trademarkEvent, [name]: value } : trademarkEvent
      )
    );

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          <h6 className="font-weight-bold">Events</h6>
        </Form.Label>
        <Col sm={7}>
          <Button size="sm" onClick={handleAddEvent}>
            Add Event
          </Button>
        </Col>
      </Form.Group>

      {trademarkEvents.map((event, idx) => (
        <Col key={idx} sm={12} className="pl-3">
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Code:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                size="sm"
                type="text"
                name="code"
                value={event.code || ""}
                onChange={(e) => handleEventChange(e, idx)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Type:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                size="sm"
                type="text"
                name="type"
                value={event.type || ""}
                onChange={(e) => handleEventChange(e, idx)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Description:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                size="sm"
                type="text"
                name="descriptionText"
                value={event.descriptionText || ""}
                onChange={(e) => handleEventChange(e, idx)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Date:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                size="sm"
                type="date"
                name="date"
                value={moment(event.date).format("YYYY-MM-DD") || ""}
                onChange={(e) => handleEventChange(e, idx)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Number:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                size="sm"
                type="text"
                name="number"
                value={event.number || ""}
                onChange={(e) => handleEventChange(e, idx)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              {event.code}
            </Form.Label>
            <Col sm={7}>
              <Button size="sm" variant="danger" onClick={() => handleDeleteEvent(idx)}>
                Remove
              </Button>
            </Col>
          </Form.Group>
        </Col>
      ))}
    </>
  );
}
