import { Col, Row, Table } from "react-bootstrap";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";
import CustomExternalLink from "components/CustomExternalLink";

import StatusView from "../Status/View";

function FormBusinessLicenses({ orderDetails, selectedProduct }) {
  const productDetails = orderDetails?.products[selectedProduct];
  const licenses = productDetails?.processingResult?.licenses || [];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">Business Licenses</h4>

      <StatusView orderDetails={orderDetails} selectedProduct={selectedProduct} />

      <FormPDF productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">Licenses and Permits</h6>

      <Row className="mb-4">
        <Col lg md={12} className="mb-3">
          <Table responsive className="orders-table">
            <thead>
              <tr className="info-row">
                <th style={{ width: "15%" }}>Name</th>
                <th style={{ width: "15%" }}>Authority</th>
                <th style={{ width: "20%" }}>Link</th>
                <th style={{ width: "20%" }}>Description</th>
                <th style={{ width: "10%" }}>Applies to</th>
                <th style={{ width: "10%" }}>Includes</th>
              </tr>
            </thead>
            <tbody>
              {licenses?.map((item, idx) => (
                <tr key={`${idx}-${item?.licenseName}`} className="info-row">
                  <td style={{ wordBreak: "break-all", verticalAlign: "top" }}>{item?.licenseName}</td>
                  <td style={{ wordBreak: "break-all", verticalAlign: "top" }}>{item?.authority}</td>
                  <td style={{ wordBreak: "break-all", verticalAlign: "top", maxWidth: "180px" }}>
                    <CustomExternalLink short href={item?.url || "#"} target="_blank">
                      {item?.url}
                    </CustomExternalLink>
                  </td>
                  <td style={{ wordBreak: "break-all", verticalAlign: "top" }}>{item?.description}</td>
                  <td style={{ wordBreak: "break-all", verticalAlign: "top" }}>{item?.appliesTo}</td>
                  <td style={{ wordBreak: "break-all", verticalAlign: "top" }}>{item?.includes || "–"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}

export default FormBusinessLicenses;
