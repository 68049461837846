import { useSelector } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import moment from "moment-timezone";

export default function TrademarkClassificationList({ trademarkClassifications }) {
  if (!trademarkClassifications) return null;

  const { internationalCodesOpt, usCodesOpt } = useSelector(({ variants }) => variants);

  return (
    <>
      {trademarkClassifications.length > 0 ? (
        trademarkClassifications.map((classification, idx) => (
          <Col key={idx} sm={12} className="pl-3">
            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                International code:
              </Form.Label>
              <Form.Label column sm={7}>
                {(classification.internationalCode || []).map((value) => internationalCodesOpt[value]).join(", ")}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                US code:
              </Form.Label>
              <Form.Label column sm={7}>
                {(classification.usCode || []).map((value) => usCodesOpt[value]).join(", ")}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Status code:
              </Form.Label>
              <Form.Label column sm={7}>
                {classification.statusCode}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Status date:
              </Form.Label>
              <Form.Label column sm={7}>
                {moment(classification.statusDate).format("YYYY/MM/DD")}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                First use anywhere date:
              </Form.Label>
              <Form.Label column sm={7}>
                {moment(classification.firstUseAnywhereDate).format("YYYY/MM/DD")}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                First use in commerce date:
              </Form.Label>
              <Form.Label column sm={7}>
                {moment(classification.firstUseInCommercialDate).format("YYYY/MM/DD")}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Primary code:
              </Form.Label>
              <Form.Label column sm={7}>
                {classification.primaryCode}
              </Form.Label>
            </Form.Group>
          </Col>
        ))
      ) : (
        <Form.Group as={Row}>
          <Form.Label column sm={12}>
            No Classifications
          </Form.Label>
        </Form.Group>
      )}
    </>
  );
}
