import { crmConfigConstants } from "_constants";
import { crmConfigService } from "_services";

import { alertActions } from "./";

export const crmConfigActions = { get, getProductCategories, saveConfig };

function get() {
  return (dispatch) => {
    dispatch(request());

    crmConfigService.get().then(
      (crmConfig) => dispatch(success(crmConfig)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: crmConfigConstants.GET_REQUEST };
  }
  function success(crmConfig) {
    return { type: crmConfigConstants.GET_SUCCESS, payload: crmConfig };
  }
  function failure(error) {
    return { type: crmConfigConstants.GET_FAILURE, payload: error };
  }
}

function getProductCategories() {
  return (dispatch) => {
    dispatch(request());

    crmConfigService.getProductCategories().then(
      ({ values }) => dispatch(success(values)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: crmConfigConstants.GET_PRODUCTS_REQUEST };
  }
  function success(payload) {
    return {
      type: crmConfigConstants.GET_PRODUCTS_SUCCESS,
      payload,
    };
  }
  function failure(error) {
    return { type: crmConfigConstants.GET_PRODUCTS_FAILURE, payload: error };
  }
}

function saveConfig(crmConfig) {
  return (dispatch) => {
    dispatch(request());

    crmConfigService.saveConfig(crmConfig).then(
      (config) => {
        dispatch(success(config));
        dispatch(alertActions.success("Successfully updated"));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: crmConfigConstants.SAVE_REQUEST };
  }
  function success(crmConfig) {
    return { type: crmConfigConstants.SAVE_SUCCESS, payload: crmConfig };
  }
  function failure(error) {
    return { type: crmConfigConstants.SAVE_FAILURE, payload: error };
  }
}
