const statuses = [
  "Error",
  "AttemptedNeedsInfo",
  "Cancelled",
  "AdditionalStepsRequired",
  "AwaitingSpecimen",
  "SpecimenReceived",
  "SearchResultsCompleted",
  "SentForReview",
  "ReadyForSubmissionToUSPTO",
  "SubmittedToUSPTO",
  "Processing",
  "SubmittedToState",
  "Completed_SS4",
  "Completed",
  "Lead",
];

export function getPriorityStatus(products) {
  let resultStatus = "";

  if (products[0])
    products.forEach(({ processingResult: { status } }) => {
      if (resultStatus) {
        const curIdx = statuses.findIndex((s) => s === resultStatus);
        const nextIdx = statuses.findIndex((s) => s === status);

        if (nextIdx < curIdx) resultStatus = status;
      } else {
        resultStatus = status;
      }
    });

  return resultStatus;
}
