import { Fragment, useMemo, useState } from "react";
import { Table, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment-timezone";

import { getPriorityStatus, currencyFormatter, checkIsLLCIncorporation, combineFromCategories } from "_helpers";
import { phoneFormatter } from "_helpers";

import { CATEGORIES_OBJ, PRODUCT_CODES } from "variables/Variables";

import Spinner from "components/Spinner";
import CopyButton from "components/CopyButton";

const codesToHide = [
  // "INC_StateIncorporationFee",
  // "TRADEMARK_Trademark_Rush_Processing"
];

const displayingProductColumns = [
  { value: "orderId", label: "Order ID" },
  { value: "timestamp", label: "Timestamp" },
  { value: "category", label: "Category" },
  { value: "productType", label: "Product Type" },
  { value: "price", label: "Price" },
  { value: "fillingStatus", label: "Product Status" },
  { value: "einErrorStatus", label: "Error Status" },
];

export default function OrdersTable(props) {
  const { currentPage, displayColumnOrderTableObj, displayingColumn, category } = props;
  const {
    openOrderDetail,
    handlePageChange,
    handleFirstPage,
    handlePrevPage,
    handlePrevStep,
    handleNextStep,
    handleNextPage,
    handleLastPage,
  } = props;

  const [uncollapsed, setUncollapsed] = useState([]);

  const { orders, loading } = useSelector(({ customers }) => customers);
  const {
    processingErrorStatusOpt,
    processingErrorsOpt,
    processingStatusOpt,
    orderStatusOpt,
    processingStatusesByCategories,
    errorProcessingStatusesByCategories,
    allProcessingStatuses,
    allProcessingErrors,
  } = useSelector(({ variants }) => variants);

  const allProcessingStatusesOpt = useMemo(() => combineFromCategories(allProcessingStatuses), [allProcessingStatuses]);
  const allProcessingErrorsOpt = useMemo(() => combineFromCategories(allProcessingErrors), [allProcessingErrors]);

  function handleClick(id) {
    if (!uncollapsed.includes(id)) {
      setUncollapsed([...uncollapsed, id]);
    } else {
      setUncollapsed(uncollapsed.filter((idx) => idx !== id));
    }
  }

  const getDataByField = (orderData, field, productIdx) => {
    const products = orderData.products.filter(({ code: { code } }) => !codesToHide.includes(code));
    const mainProduct = products[productIdx];

    switch (field) {
      case "orderId":
        return (
          <span onClick={(e) => e.stopPropagation()}>
            <Link
              to={`/admin/orders/${orderData.order.id}${mainProduct?.id ? "/" + mainProduct?.id : ""}`}
              target="_blank"
            >
              {orderData?.order?.crmOrderId || "N/A"}
            </Link>
            {/* &nbsp; <CopyButton text={orderData.order.crmOrderId} /> */}
          </span>
        );
      case "timestamp":
        if (!products?.length) return "";
        return moment.utc(mainProduct.paymentDate).local().format("MM/DD/YYYY HH:mm:ss");
      case "customeName":
        return `${orderData.customer.firstName} ${orderData.customer.middleName || ""} ${orderData.customer.lastName}`;
      case "productType":
        if (!products?.length) return "No products";

        let currentStatus =
          processingStatusOpt[mainProduct.processingResult.status] || mainProduct.processingResult.status;
        let currentError =
          processingErrorsOpt[mainProduct.processingResult.processingError] ||
          mainProduct.processingResult.processingError;

        if (category?.toLowerCase() === "all") {
          currentStatus =
            allProcessingStatusesOpt[mainProduct.processingResult.status] || mainProduct.processingResult.status;
          currentError =
            allProcessingErrorsOpt[mainProduct.processingResult.processingError] ||
            mainProduct.processingResult.processingError;
        }

        return (
          <>
            <span title={mainProduct.code.code}>{mainProduct.code.customerName}</span>
            <br />
            {`(${currentStatus || "unknown status"}${currentError ? " - " + currentError : ""})`}
          </>
        );
      case "companyName":
        if (!products?.length) return "";
        return orderData.products.find(({ code: { code } }) => checkIsLLCIncorporation(code))?.companyName;
      case "price":
        return currencyFormatter(orderData.order.total);
      case "email":
        return (
          <span>
            {orderData.customer.email}
            {/* &nbsp;<CopyButton text={orderData.customer.email} /> */}
          </span>
        );

      case "orderStatus":
        if (!products?.length) return "";
        return orderStatusOpt[mainProduct?.paymentStatus];
      case "fillingStatus": {
        if (!products?.length) return "";

        return processingStatusOpt[mainProduct?.processingResult?.status];
      }
      case "einErrorStatus": {
        if (!products?.length) return "";

        return mainProduct?.processingResult?.status === "Error"
          ? processingErrorStatusOpt[mainProduct?.processingResult?.errorStatus]
          : "";
      }
      case "phone":
        return (
          <span>
            {phoneFormatter(orderData.customer.phone)}
            {/* &nbsp;<CopyButton text={orderData.customer.phone} /> */}
          </span>
        );

      case "email/phone":
        return (
          <div>
            <div>
              {orderData.customer.email}
              {/* &nbsp;<CopyButton text={orderData.customer.email} /> */}
            </div>
            <div>
              {phoneFormatter(orderData.customer.phone)}&nbsp;
              <CopyButton text={orderData.customer.phone} />
            </div>
          </div>
        );

      case "primaryActivity":
        if (!products?.length) return "";

        return (
          <>
            {orderData.products.map(({ activityPrimaryActivity, id }) => (
              <div key={`primary-${id}`} className="p-1">
                {activityPrimaryActivity}
              </div>
            ))}
          </>
        );
      case "secondaryActivity":
        if (!products?.length) return "";

        return (
          <>
            {orderData.products.map(({ activitySpecificProducts, id }) => (
              <div key={`secondary-${id}`} className="p-1">
                {activitySpecificProducts}
              </div>
            ))}
          </>
        );
      case "automationTriggered":
        return orderData.order.automationLock;

      default:
        break;
    }
  };

  const getProductDataByField = (orderData, field, productIdx) => {
    const products = orderData.products.filter(({ code: { code } }) => !codesToHide.includes(code));
    const currentProduct = products[productIdx];

    switch (field) {
      case "orderId":
        return orderData.order.crmOrderId;
      case "timestamp":
        if (!currentProduct.paymentDate) return "";
        return moment.utc(currentProduct.paymentDate).local().format("MM/DD/YYYY HH:mm:ss");
      case "category":
        return currentProduct.code.category;
      case "productType":
        return <span title={currentProduct.code.code}>{`${currentProduct.code.customerName}`}</span>;
      case "fillingStatus": {
        if (currentProduct?.processingResult?.status === "Error") {
          return (
            <div>
              <div>{currentProduct?.processingResult?.status}</div>
              <div>
                {errorProcessingStatusesByCategories[currentProduct?.processingResult?.processingError]
                  ? errorProcessingStatusesByCategories[currentProduct?.processingResult?.processingError]
                  : currentProduct?.processingResult?.processingError}
              </div>
            </div>
          );
        }

        if (processingStatusesByCategories[currentProduct?.processingResult?.status]) {
          return processingStatusesByCategories[currentProduct?.processingResult?.status];
        }

        if (currentProduct?.processingResult?.status) {
          return currentProduct?.processingResult?.status;
        }

        return "";
      }
      case "price":
        return currencyFormatter(currentProduct.price);
      case "einErrorStatus": {
        return currentProduct?.processingResult?.status === "Error"
          ? processingErrorStatusOpt[products[productIdx]?.processingResult?.errorStatus]
          : "";
      }
      case "productId":
        if (products.length === 0) return "null";
        return currentProduct.id;

      default:
        break;
    }
  };

  const renderRow = (prop, key) => {
    let indexOfMain = prop.products.findIndex(({ code: { main } }) => main);
    let indexOfEinLlcInc = prop.products.findIndex(({ code }) => code?.code === PRODUCT_CODES.einLLCIncorporation);
    let indexOfIncLlcInc = prop.products.findIndex(({ code }) => code?.code === PRODUCT_CODES.incLLCIncorporation);
    let indexOfIncStateLlcInc = prop.products.findIndex(
      ({ code }) => code?.code === PRODUCT_CODES.incStatesLLCIncorporation
    );
    let indexOfTrademarkReg = prop.products.findIndex(({ code }) => code?.code === PRODUCT_CODES.tmRegisterTrademark);

    if (indexOfMain < 0) indexOfMain = 0;

    const products = prop.products.filter(({ code: { code } }) => !codesToHide.includes(code));
    const currentCategory = category?.toUpperCase();

    let priorityIndex = indexOfMain;
    if (currentCategory === CATEGORIES_OBJ.einInc && indexOfEinLlcInc > -1) {
      priorityIndex = indexOfEinLlcInc;
    }
    if (currentCategory === CATEGORIES_OBJ.inc && indexOfIncLlcInc > -1) {
      priorityIndex = indexOfIncLlcInc;
    }
    if (currentCategory === CATEGORIES_OBJ.incStates && indexOfIncStateLlcInc > -1) {
      priorityIndex = indexOfIncStateLlcInc;
    }
    if (currentCategory === CATEGORIES_OBJ.trademarks && indexOfTrademarkReg > -1) {
      priorityIndex = indexOfTrademarkReg;
    }

    return (
      <Fragment key={`order-${key}`}>
        <tr
          name="row"
          className={
            products[priorityIndex]?.paymentStatus === "Chargeback"
              ? "table-bg-Cancelled"
              : "table-bg-" + getPriorityStatus([products[priorityIndex]])
          }
        >
          {products.length > 1 ? (
            <td style={{ position: "relative", textAlign: "center" }} onClick={() => handleClick(prop.order.id)}>
              {uncollapsed.includes(prop.order.id) ? <i className="fa fa-minus" /> : <i className="fa fa-plus" />}
            </td>
          ) : (
            <td
              onClick={() => openOrderDetail(prop.order.id, getProductDataByField(prop, "productId", priorityIndex))}
            />
          )}

          {displayingColumn.map((columnValue, key) => (
            <td
              key={`main-${columnValue}-${key}`}
              onClick={() => openOrderDetail(prop.order.id, getProductDataByField(prop, "productId", priorityIndex))}
            >
              {getDataByField(prop, columnValue, priorityIndex)}
            </td>
          ))}
        </tr>

        {uncollapsed.includes(prop.order.id) && (
          <tr className="table-secondary">
            <td colSpan={displayingColumn.length + 1}>
              <Table responsive className="border mb-0">
                <thead>
                  <tr>
                    {displayingProductColumns.map((columnValue, key) => (
                      <th
                        key={`uncollapsed-header-${columnValue}-${key}`}
                        className="table-row"
                        style={{ color: "black", borderBottom: "2px solid gray" }}
                      >
                        {columnValue.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {products.map((product, idx) => (
                    <tr
                      key={`uncollapsed-row-${product.id}-${key}`}
                      className={
                        product.processingResult.status === "Cancelled"
                          ? "table-bg-Cancelled"
                          : "table-row table-bg-" + product.processingResult.status
                      }
                      onClick={() => openOrderDetail(prop.order.id, product.id)}
                    >
                      {displayingProductColumns.map((columnValue, key) => (
                        <td key={`uncollapsed-cell-${columnValue}-${key}`} className="pt-2 pb-2">
                          {getProductDataByField(prop, columnValue.value, idx)}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </td>
          </tr>
        )}
      </Fragment>
    );
  };

  const renderTableBody = () => {
    if (orders && orders.elements) {
      return orders.elements.map(renderRow);
    }
  };

  const renderFooter = () => {
    const pagination = [];

    let totalPages = 1;
    let totalResults = 0;

    if (orders && orders.elements) {
      totalPages = orders.totalPages;
      totalResults = orders.totalResults;
    }

    if (!totalResults) {
      return <div className="ml-2">No results</div>;
    }

    const step = currentPage % 5 === 0 ? currentPage / 5 - 1 : Math.floor(currentPage / 5);

    if (step > 0) pagination.push(<Pagination.Ellipsis key={"prevPageList"} onClick={handlePrevStep} />);

    for (let i = 1; i <= totalPages; i++) {
      if (i > step * 5 && i <= step * 5 + 5)
        pagination.push(
          <Pagination.Item key={i} active={currentPage === i} onClick={currentPage !== i ? handlePageChange : null}>
            {i}
          </Pagination.Item>
        );
    }

    if (totalPages > (step + 1) * 5)
      pagination.push(<Pagination.Ellipsis key={"naxtPageList"} onClick={handleNextStep} />);

    return (
      <Pagination className="ml-2 mt-2">
        <Pagination.First disabled={currentPage === 1} onClick={handleFirstPage} />
        <Pagination.Prev disabled={currentPage === 1} onClick={handlePrevPage} />
        {pagination}
        <Pagination.Next disabled={currentPage === totalPages} onClick={handleNextPage} />
        <Pagination.Last disabled={currentPage === totalPages} onClick={handleLastPage} />
      </Pagination>
    );
  };

  return (
    <div id="ordersTable">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Table responsive className="orders-table">
            <thead>
              <tr>
                <th></th>
                {displayingColumn.map((prop, key) => (
                  <th key={`header-${key}`}>{displayColumnOrderTableObj[prop]}</th>
                ))}
              </tr>
            </thead>
            <tbody>{renderTableBody()}</tbody>
          </Table>
          {renderFooter()}
        </>
      )}
    </div>
  );
}
