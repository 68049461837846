import { reportsEINPaymentConstants } from "_constants";

const initialState = {
  loading: 0,
  orders: [],
  maxPerPage: 25,
  page: 1,
  totalPages: 0,
  totalResults: 0,
};

export function reportsEINPayment(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case reportsEINPaymentConstants.GET_REPORTS_EIN_PAYMENT_REQUEST:
      return { ...state, loading: state.loading + 1, orders: [] };

    case reportsEINPaymentConstants.GET_REPORTS_EIN_PAYMENT_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1, ...payload };

    case reportsEINPaymentConstants.GET_REPORTS_EIN_PAYMENT_FAILURE:
      return { ...state, loading: state.loading && state.loading - 1, orders: [] };

    default:
      return state;
  }
}
