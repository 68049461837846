import { customerConstants } from '../_constants';

const initial_state = null;

export function summaryorders(state = initial_state, action) {
  switch (action.type) {
    case customerConstants.GETSUMMARY_SUCCESS:
      return action.orders;
    default:
      return state;
  }
}
