import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import StatusView from "../Status/View";

function FormIncRushProcessing({ orderDetails, selectedProduct }) {
  const productDetails = orderDetails?.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">Rush Processing Product INC</h4>

      <StatusView orderDetails={orderDetails} selectedProduct={selectedProduct} />

      <FormPDF productDetails={productDetails} />
    </div>
  );
}

export default FormIncRushProcessing;
