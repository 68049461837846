import { einoptionConstants } from '../_constants';

const initial_state = {
  loading: true,
  options: [],
};

export function einoptions(state = initial_state, action) {
  switch (action.type) {
    case einoptionConstants.GET_OPTION_REQUEST:
      return { ...state, loading: true };

    case einoptionConstants.GET_OPTION_SUCCESS:
      return { ...state, loading: false, options: action.options };

    default:
      return state;
  }
}
