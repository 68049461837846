import { reportsUsersPerformanceConstants } from "_constants";

const initialState = {
  loading: 0,
  lines: null,
};

export function reportsUsersPerformance(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case reportsUsersPerformanceConstants.GET_USERS_PERFORMANCE_REQUEST:
      return { ...state, loading: state.loading + 1, lines: null };

    case reportsUsersPerformanceConstants.GET_USERS_PERFORMANCE_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1, lines: payload?.lines };

    case reportsUsersPerformanceConstants.GET_USERS_PERFORMANCE_FAILURE:
      return { ...state, loading: state.loading && state.loading - 1, lines: null };

    default:
      return state;
  }
}
