import React, { useRef, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { getFileExtensionsString, getFileExtension, checkIfFileAllowed, sanitizeFileName } from "_helpers";
import { alertActions, customerActions } from "_actions";

import ConfirmationModal from "components/ConfirmationModal";

const MailUpload = ({ orderDetails, selectedProduct, fileType, isDirty, onShowConfirmation }) => {
  const dispatch = useDispatch();
  const [fileKeyToDelete, setFileKeyToDelete] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [note, setNote] = useState("");
  const [forceMimeCheck, setForceMimeCheck] = useState(false);

  const { productsFileTypesObj } = useSelector(({ variants }) => variants);

  const fileRef = useRef(null);

  const orderId = orderDetails.order.id;
  const productDetails = orderDetails.products[selectedProduct];
  const productFileTypes = productsFileTypesObj[productDetails?.code?.code] || {};
  const productId = productDetails.id;
  const processingResult = productDetails.processingResult;

  if (!productDetails.processingResult.files) return null;
  if (!processingResult.productResultFileType || !processingResult.productResultFileType.length) return null;

  const filteredFiles = Object.fromEntries(
    Object.entries(processingResult.files).filter((item) => item[1]?.type === fileType)
  );
  const uploadedFileMap = filteredFiles;
  const uploadedFileKeys = Object.keys(filteredFiles);
  const files = uploadedFileKeys
    .map((key) => {
      return { objKey: key, orderId: orderId, productId: productId, ...filteredFiles[key] };
    })
    .sort((a, b) => moment(b.uploaded) - moment(a.uploaded));

  const isNoFiles = !files.length;
  const extensionsMail = getFileExtensionsString(productFileTypes[fileType]?.allowedExtensions);
  const selectedFileSize = selectedFile?.size;
  const selectedFileExt = getFileExtension(selectedFile?.name);
  const isExtensionAllowed = checkIfFileAllowed(selectedFileExt, productFileTypes[fileType]?.allowedExtensions);

  const handleFileChange = (event) => {
    if (!event.target.files.length) {
      setSelectedFile(null);
    } else {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleDownloadProductResultFile = (fileKey, filename) =>
    dispatch(customerActions.downloadProductPDF(orderId, productId, fileKey, filename, forceMimeCheck));

  const handleProductPDFDelete = (fileKey) => dispatch(customerActions.deleteProductPDF(orderId, productId, fileKey));

  const handleProductPDFUpload = () => {
    const uploadFunction = () => {
      const formData = new FormData();

      formData.append("file", selectedFile, sanitizeFileName(selectedFile?.name));

      dispatch(customerActions.uploadProductPDF(orderId, productId, fileType, formData, note));
      setSelectedFile(null);
      if (fileRef.current) fileRef.current.value = null;
    };

    if (!isExtensionAllowed) {
      dispatch(alertActions.error("Please select file with correct extension: " + (extensionsMail || "any type")));

      return false;
    }

    if (selectedFileSize && selectedFileSize / 1024 / 1024 > 5) {
      dispatch(alertActions.error("File size limit is 5MB"));

      return false;
    }

    if (selectedFile === null) {
      dispatch(alertActions.error("Please select file to upload"));
    } else if (isDirty) {
      onShowConfirmation(uploadFunction);
    } else {
      uploadFunction();
    }
  };

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">MAIL UPLOAD</h4>
      <hr />

      <Row className="mb-4">
        <Col lg md={12} className="mb-3">
          <div>
            <input
              style={{ border: "2px solid lightgray", borderRadius: "4px", padding: "8px", cursor: "pointer" }}
              ref={fileRef}
              className="mb-1"
              type="file"
              accept={extensionsMail}
              onChange={handleFileChange}
            />
          </div>
          <div className="allowed-file-types-label">Allowed file types: {extensionsMail || "any type"}</div>
        </Col>
        <Col lg md={12} className="mb-3">
          <Form.Control
            as="textarea"
            rows="3"
            style={{ minHeight: "49px", minWidth: "320px", fontSize: "14px", lineHeight: "16px" }}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </Col>
        <Col lg md={12} className="mb-3">
          <Button size="sm" onClick={handleProductPDFUpload}>
            Upload Mail
          </Button>
        </Col>
      </Row>

      <h6 className="font-weight-bold">Uploaded Documents</h6>
      <Table responsive>
        <thead>
          <tr style={{ tableLayout: "fixed" }}>
            <th style={{ width: "6%" }}>Uploaded</th>
            <th style={{ width: "25%" }}>Name</th>
            <th style={{ width: "auto" }}>Note</th>
            <th style={{ width: "2%" }}>Action</th>
            <th style={{ width: "2%" }} />
          </tr>
        </thead>
        <tbody>
          {!isNoFiles && (
            <>
              {files.map((file) => (
                <tr key={`mail-${file.fileKey}`} style={{ tableLayout: "fixed" }}>
                  <td>{file?.uploaded ? moment.utc(file?.uploaded).local().format("YYYY/MM/DD HH:mm:ss") : "N/A"}</td>
                  <td>{file?.ourFileName || file.objKey?.replace(/_/g, " ")}</td>
                  <td>{file?.note || "—"}</td>
                  <td>
                    <Button
                      size="sm"
                      variant="info"
                      onClick={() => handleDownloadProductResultFile(file?.objKey, file?.ourFileName)}
                    >
                      Download
                    </Button>
                  </td>
                  <td>
                    <Button size="sm" variant="danger" onClick={() => setFileKeyToDelete(file?.objKey)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
              <tr>
                <td
                  colSpan={3}
                  title="Some files from old orders may be downloaded with the wrong file type. If you found such a file, try to check the box and download it again."
                >
                  Please, check this box if files are downloaded with the wrong file type&nbsp;
                  <i style={{ color: "gray", fontSize: "1.35em" }} className="fa fa-info-circle" />
                </td>
                <td colSpan={2}>
                  <Form.Check
                    type="checkbox"
                    label="Force check file type"
                    checked={forceMimeCheck}
                    onChange={() => setForceMimeCheck(!forceMimeCheck)}
                  />
                </td>
              </tr>
            </>
          )}
          {isNoFiles && (
            <tr>
              <td>No files</td>
            </tr>
          )}
        </tbody>
      </Table>

      <ConfirmationModal
        show={Boolean(fileKeyToDelete)}
        handleClose={() => {
          setFileKeyToDelete(null);
        }}
        handleConfirm={() => {
          handleProductPDFDelete(fileKeyToDelete);
          setFileKeyToDelete(null);
        }}
        body={`
          Are you sure you want to delete 
          ${uploadedFileMap[fileKeyToDelete]?.ourFileName} \n
          [Uploaded: ${
            uploadedFileMap[fileKeyToDelete]?.uploaded
              ? moment.utc(uploadedFileMap[fileKeyToDelete]?.uploaded).local().format("YYYY/MM/DD HH:mm:ss")
              : "N/A"
          }] ?
        `}
      />
    </div>
  );
};

export default MailUpload;
