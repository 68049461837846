import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Container, Tabs } from "react-bootstrap";

import { healthcheckActions } from "_actions";
import { transformHealthMapToList } from "_helpers/healthcheck";

import Spinner from "components/Spinner";

import TabHealthCheckBackend from "./TabHealthCheckBackend";
import TabHealthCheckFrontend from "./TabHealthCheckFrontend";
import TabDeploy from "./TabDeploy";
import TabDeployUI from "./TabDeployUI";
import TabUserLog from "./TabUserLog";
import TabThirdpartyLog from "./TabThirdpartyLog";
import TabConsumerLogs from "./TabConsumerLogs";
import HealthCheckTitle from "./CustomTabTitles/HealthCheckTitle";

function HCIndex() {
  const dispatch = useDispatch();
  const { backendHealth, frontendHealth } = useSelector(({ healthchecks }) => healthchecks);

  useEffect(() => {
    dispatch(healthcheckActions.getFrontendHealth());
    dispatch(healthcheckActions.getBackendHealth());
  }, []);

  return (
    <Container fluid className="mt-5 py-4">
      <Tabs defaultActiveKey="healthcheck_backend" id="uncontrolled-tab-example">
        <Tab
          eventKey="healthcheck_backend"
          title={
            <HealthCheckTitle
              type="Backend"
              healthchecks={backendHealth?.healthchecks?.serviceHealthchecks}
              loading={backendHealth?.loading}
            />
          }
        >
          {backendHealth?.healthchecks ? (
            <TabHealthCheckBackend healthchecks={backendHealth?.healthchecks} />
          ) : (
            <Spinner />
          )}
        </Tab>

        <Tab
          eventKey="healthcheck_frontend"
          title={
            <HealthCheckTitle
              type="Frontend"
              healthchecks={transformHealthMapToList(frontendHealth?.healthchecks?.mapOfServiceHealthchecks)}
              loading={frontendHealth?.loading}
            />
          }
        >
          {frontendHealth?.healthchecks ? (
            <TabHealthCheckFrontend healthchecks={frontendHealth?.healthchecks} />
          ) : (
            <Spinner />
          )}
        </Tab>

        <Tab eventKey="deploy" title="Deploy">
          <TabDeploy />
        </Tab>

        <Tab eventKey="deploy_ui" title="Deploy UI">
          <TabDeployUI />
        </Tab>

        <Tab eventKey="user_action_log" title="User Action Log">
          <TabUserLog />
        </Tab>

        <Tab eventKey="third_party_log" title="3rd Party System Logs">
          <TabThirdpartyLog />
        </Tab>

        <Tab eventKey="consumer_logs" title="Consumer Logs">
          <TabConsumerLogs />
        </Tab>
      </Tabs>
    </Container>
  );
}

export default HCIndex;
