import { servicesConstants } from "_constants";
import { servicesService } from "_services";

import { alertActions } from "./";

export const servicesActions = {
  getAll,
  deployservice,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());

    servicesService.getAll().then(
      (result) => dispatch(success(result)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: servicesConstants.GETALL_REQUEST };
  }
  function success(result) {
    return { type: servicesConstants.GETALL_SUCCESS, result };
  }
  function failure(error) {
    return { type: servicesConstants.GETALL_FAILURE, error };
  }
}

function deployservice(jobid, services_array) {
  return (dispatch) => {
    dispatch(request());

    servicesService.deployservice(jobid, services_array).then(
      (result) => {
        dispatch(success());
        dispatch(alertActions.success("Successfully deployed"));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: servicesConstants.DEPLOY_REQUEST };
  }
  function success() {
    return { type: servicesConstants.DEPLOY_SUCCESS };
  }
  function failure(error) {
    return { type: servicesConstants.DEPLOY_FAILURE, error };
  }
}
