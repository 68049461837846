export const adsAccountsConstants = {
  // GET ACCOUNTS
  GET_ADS_ACCOUNTS_REQUEST: "GET_ADS_ACCOUNTS_REQUEST",
  GET_ADS_ACCOUNTS_SUCCESS: "GET_ADS_ACCOUNTS_SUCCESS",
  GET_ADS_ACCOUNTS_FAILED: "GET_ADS_ACCOUNTS_FAILED",
  // CREATE ACCOUNT
  ADD_ADS_ACCOUNT_REQUEST: "ADD_ADS_ACCOUNT_REQUEST",
  ADD_ADS_ACCOUNT_SUCCESS: "ADD_ADS_ACCOUNT_SUCCESS",
  ADD_ADS_ACCOUNT_FAILED: "ADD_ADS_ACCOUNT_FAILED",
  // UPDATE ACCOUNT
  UPDATE_ADS_ACCOUNT_REQUEST: "UPDATE_ADS_ACCOUNT_REQUEST",
  UPDATE_ADS_ACCOUNT_SUCCESS: "UPDATE_ADS_ACCOUNT_SUCCESS",
  UPDATE_ADS_ACCOUNT_FAILED: "UPDATE_ADS_ADS_ACCOUNT_FAILED",
  // DELETE ACCOUNT
  DELETE_ADS_ACCOUNT_REQUEST: "DELETE_ADS_ACCOUNT_REQUEST",
  DELETE_ADS_ACCOUNT_SUCCESS: "DELETE_ADS_ACCOUNT_SUCCESS",
  DELETE_ADS_ACCOUNT_FAILED: "DELETE_ADS_ACCOUNT_FAILED",
  // PAUSE ACCOUNT
  PAUSE_ADS_ACCOUNT_REQUEST: "PAUSE_ADS_ACCOUNT_REQUEST",
  PAUSE_ADS_ACCOUNT_SUCCESS: "PAUSE_ADS_ACCOUNT_SUCCESS",
  PAUSE_ADS_ACCOUNT_FAILED: "PAUSE_ADS_ACCOUNT_FAILED",
  // CATEGORIES
  GET_CATEGORIES_REQUEST: "GET_CATEGORIES_ADS_ACCOUNTS_REQUEST",
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_ADS_ACCOUNTS_SUCCESS",
  GET_CATEGORIES_FAILED: "GET_CATEGORIES_ADS_ACCOUNTS_FAILED",
};
