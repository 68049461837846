import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Card, Table } from "react-bootstrap";

import Spinner from "components/Spinner";

const ADS_DATA = [
  { key: "accountId", label: "Account ID", colWidth: "24%" },
  { key: "campaignName", label: "Campaign", colWidth: "auto" },
  { key: "clicks", label: "Clicks", colWidth: "7%" },
  { key: "cpc", label: "CPC", colWidth: "7%" },
  { key: "conversion", label: "Conversions", colWidth: "7%" },
  { key: "cpa", label: "CPA", preffix: "$", colWidth: "7%" },
  { key: "cpaAllConv", label: "CPA/All Conv", preffix: "$", colWidth: "7%" },
  { key: "costs", label: "Cost", preffix: "$", colWidth: "7%" },
  { key: "revenue", label: "Revenue", preffix: "$", colWidth: "7%" },
  { key: "profit", label: "Profit", preffix: "$", colored: true, colWidth: "7%" },
];

function AdvertisingTable() {
  const [uncollapsed, setUncollapsed] = useState([]);

  const ads = useSelector(({ ads: { ads } }) => (ads?.accountReport ? ads.accountReport : []));
  const adsTotal = useSelector(({ ads: { ads } }) => (ads?.total ? ads.total : {}));
  const loading = useSelector(({ ads: { loading } }) => loading);

  const reportLinesRenderer = (lines, idx) => {
    return (
      <tr key={`${lines.accountId}${idx}`} hidden={lines.hidden}>
        {ADS_DATA.map(({ key, colored, preffix }, colIdx) => {
          let style = {};

          if (colored) {
            style.color = lines[key] > 0 ? "green" : lines[key] < 0 ? "red" : " black";
          }

          return (
            <td key={`${lines.accountId}${idx}${colIdx}`} style={style}>
              {preffix}
              {lines[key]}
            </td>
          );
        })}
      </tr>
    );
  };

  const accountsReportRenderer = () =>
    ads.map(({ accountId, reportLines, total }) => {
      const hidden = !uncollapsed.includes(accountId);

      return (
        <React.Fragment key={accountId}>
          <tr key={accountId + "total"} style={{ backgroundColor: "#dfdfdf" }}>
            <td>
              {accountId}{" "}
              {!!reportLines.length && (
                <i
                  className={`fa fa-${hidden ? "plus" : "minus"}`}
                  onClick={() =>
                    setUncollapsed(hidden ? [...uncollapsed, accountId] : uncollapsed.filter((v) => v !== accountId))
                  }
                />
              )}
            </td>
            <td>TOTAL</td>
            <td>{total.clicks || 0}</td>
            <td>{total.cpc || 0}</td>
            <td>{total.conversion || 0}</td>
            <td>${total.cpa || 0}</td>
            <td>${total.cpaAllConv || 0}</td>
            <td>${total.costs || 0}</td>
            <td>${total.revenue || 0}</td>
            <td style={{ color: total.profit > 0 ? "green" : total.profit < 0 ? "red" : " black" }}>
              ${total.profit || 0}
            </td>
          </tr>
          {reportLines.map((row, idx) => reportLinesRenderer({ ...row, hidden }, idx))}
        </React.Fragment>
      );
    });

  const totalRowRenderer = () => (
    <tr style={{ borderTop: "2px solid black" }}>
      <td colSpan="2">Total:</td>
      <td>{adsTotal?.clicks || 0}</td>
      <td>{adsTotal?.cpc || 0}</td>
      <td>{adsTotal?.conversion || 0}</td>
      <td>${adsTotal?.cpa || 0}</td>
      <td>${adsTotal?.cpaAllConv || 0}</td>
      <td>${adsTotal?.costs || 0}</td>
      <td>${adsTotal?.revenue || 0}</td>
      <td style={{ color: adsTotal?.profit > 0 ? "green" : adsTotal?.profit < 0 ? "red" : " black" }}>
        ${adsTotal?.profit || 0}
      </td>
    </tr>
  );

  return (
    <Card>
      <Table responsive className="orders-table">
        <thead>
          <tr style={{ tableLayout: "fixed" }}>
            {ADS_DATA.map(({ key, label, colWidth }) => (
              <th style={{ width: colWidth }} key={key}>
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!loading && accountsReportRenderer()}
          {!loading && totalRowRenderer()}
        </tbody>
      </Table>

      {loading && <Spinner />}
    </Card>
  );
}

export default AdvertisingTable;
