import React, { useState } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";

import ActionsRequired from "views/Admin/ActionsRequired";
import AddressesRA from "views/Admin/AddressesRA";

export default function VariantsOptions() {
  const [activeTab, setActiveTab] = useState("actionsRequired");

  const handleTab = (key) => setActiveTab(key);

  return (
    <Container fluid className="main-content ein-options">
      <Tabs defaultActiveKey="actionsRequired" transition={false} activeKey={activeTab} onSelect={handleTab}>
        <Tab eventKey="actionsRequired" title="Actions Required">
          <ActionsRequired />
        </Tab>
        <Tab eventKey="addressesRA" title="RA Addresses">
          <AddressesRA />
        </Tab>
      </Tabs>
    </Container>
  );
}
