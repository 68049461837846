import React, { useEffect, useState } from "react";
import { Button, Form, ButtonGroup, Dropdown, DropdownButton, Spinner } from "react-bootstrap";
import ScrollToBottom from "react-scroll-to-bottom";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import { css } from "emotion";
import axios from "axios";

import { getZConfig } from "_helpers";
import { config as initConfig } from "_config";

import LoadingSpinner from "components/Spinner";
import avarta from "assets/img/faces/face-0.jpg";

function TicketCommentbox(props) {
  const { ticketid } = useParams();
  const config = { ...initConfig, ...getZConfig() };
  const { ticket } = props;
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [submittype, setSubmittype] = useState("Submit as Solved");
  const [status, setStatus] = useState("solved");
  const [selectedfile, setSelectedfile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [agent, setAgent] = useState(null);
  const [requester, setRequester] = useState(null);

  const ROOT_CSS = css({
    height: "calc(100vh - 300px)",
  });

  let headers = {
    headers: { Authorization: "Bearer " + localStorage.getItem("zauth") },
  };

  const select_status = (e, status) => {
    setSubmittype(e.target.text);
    setStatus(status);
  };

  const getcomments = () => {
    axios.get(`${config.zendesk_url}/api/v2/tickets/${ticketid}/comments?page=1`, headers).then((res) => {
      setComments(res.data.comments);
    });
  };

  const getassigner = () => {
    if (ticket.assignee_id)
      axios.get(`${config.zendesk_url}/api/v2/users/${ticket.assignee_id}`, headers).then((res) => {
        setAgent(res.data.user);
      });
  };

  const getrequester = () => {
    if (ticket.requester_id)
      axios.get(`${config.zendesk_url}/api/v2/users/${ticket.requester_id}`, headers).then((res) => {
        setRequester(res.data.user);
      });
  };

  const updatecomments = (e) => {
    e.preventDefault();
    if (comment !== "") {
      setUploading(true);
      if (selectedfile) {
        headers = {
          ...headers,
          "Content-Type": "application/binary",
        };
        const formData = new FormData();
        formData.append("file", selectedfile, selectedfile.name);
        axios
          .post(`${config.zendesk_url}/api/v2/uploads?filename=${selectedfile.name}`, formData, headers)
          .then((res) => {
            const upload_token = res.data.upload.token;
            const body = {
              ticket: {
                comment: {
                  body: comment,
                  public: true,
                  uploads: [upload_token],
                },
                status: status,
              },
            };

            axios.put(`${config.zendesk_url}/api/v2/tickets/${ticket.id}.json`, body, headers).then((res) => {
              setSelectedfile(null);
              setComment("");
              getcomments();
              setUploading(false);
              ticket.status = res.data.ticket.status;
            });
          });
      } else {
        const body = {
          ticket: {
            comment: {
              body: comment,
              public: true,
            },
            status: status,
          },
        };

        axios.put(`${config.zendesk_url}/api/v2/tickets/${ticket.id}.json`, body, headers).then((res) => {
          setComment("");
          getcomments();
          setUploading(false);
          ticket.status = res.data.ticket.status;
        });
      }
    }
  };

  const onFileChange = (e) => {
    if (!e.target.files.length) {
      setSelectedfile(null);
    } else {
      const selected_file = e.target.files[0];
      setSelectedfile(selected_file);
    }
  };

  useEffect(() => {
    getcomments();
    getassigner();
    getrequester();
  }, []);

  return (
    <>
      <div className="col-md-8" id="ticket_comments">
        <ScrollToBottom className={ROOT_CSS}>
          {comments.length === 0 ? (
            <LoadingSpinner></LoadingSpinner>
          ) : (
            <>
              {comments.map((item, key) => {
                return (
                  <div className="comment mb-3" key={key}>
                    <span>
                      <img src={avarta} alt="logo_image" className="avarta" width="30" />
                      {ticket.requester_id !== item.author_id && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          aria-hidden="true"
                          focusable="false"
                        >
                          <g fill="none" stroke="currentColor">
                            <circle cx="8" cy="8" r="7.5"></circle>
                            <circle cx="8" cy="5.5" r="2"></circle>
                            <path strokeLinecap="round" d="M11.5 12c-.6-1.5-1.8-2.5-3.5-2.5s-2.9 1-3.5 2.5"></path>
                          </g>
                        </svg>
                      )}
                    </span>
                    <div className="m-0">
                      <p className="d-block m-0 sender_name">
                        {ticket.requester_id !== item.author_id ? (
                          <>
                            {agent && (
                              <>
                                {agent.name} <span className="agent-email">{agent.email}</span>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {requester && (
                              <>
                                {requester.name} <span className="agent-email">{requester.email}</span>
                              </>
                            )}
                          </>
                        )}
                      </p>
                      <span className="d-block message_time">
                        {moment.utc(item.created_at).local().format("YYYY-MM-DD HH:mm:ss")}
                      </span>
                      <div dangerouslySetInnerHTML={{ __html: item.html_body }} />
                      <div className="attached_files">
                        {item.attachments.length > 0 && (
                          <>
                            {item.attachments.map((item, key) => {
                              return item.content_type === "image/png" ? (
                                <>
                                  <a
                                    href={item.content_url}
                                    key={key}
                                    className="comment-attached"
                                    download
                                    target="_blank"
                                  >
                                    <div>
                                      <img src={item.content_url} alt="" />
                                    </div>
                                  </a>
                                </>
                              ) : (
                                <>
                                  <a
                                    href={item.content_url}
                                    key={key}
                                    className="comment-attached"
                                    download
                                    target="_blank"
                                  >
                                    <div className="header">
                                      <i className="pe-7s-file"></i>
                                    </div>
                                    <div className="filename">{item.file_name}</div>
                                  </a>
                                </>
                              );
                            })}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </ScrollToBottom>
        <div className="ticket-form">
          <Form onSubmit={updatecomments}>
            <Form.Control
              required
              as="textarea"
              value={ticket.status === "closed" ? "Ticket closed" : comment}
              onChange={(e) => setComment(e.target.value)}
              disabled={ticket.status === "closed"}
            />
            <div className="tool-box mt-1">
              <label htmlFor="attach_file">
                <i className="pe-7s-paperclip pull-left mr-2"></i>
                {selectedfile && <>{selectedfile.name}</>}
              </label>
              <input
                type="file"
                id="attach_file"
                hidden
                onChange={onFileChange}
                disabled={ticket.status === "closed"}
              />

              {uploading ? (
                <>
                  <Spinner animation="border" variant="primary" />
                </>
              ) : (
                <>
                  <Dropdown as={ButtonGroup}>
                    <Button variant="secondary" type="submit" disabled={ticket.status === "closed"}>
                      {submittype}
                    </Button>

                    <DropdownButton
                      title=""
                      id="dropdown-menu-align-right"
                      className="ticket_status"
                      variant="secondary"
                      drop="up"
                      disabled={ticket.status === "closed"}
                    >
                      <Dropdown.Item eventKey="open" className="open" onClick={(e) => select_status(e, "open")}>
                        Submit as Open
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="pending2"
                        className="pending"
                        onClick={(e) => select_status(e, "pending")}
                      >
                        Submit as Pending
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="solved" className="solved" onClick={(e) => select_status(e, "solved")}>
                        Submit as Solved
                      </Dropdown.Item>
                    </DropdownButton>
                  </Dropdown>
                </>
              )}
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default TicketCommentbox;
