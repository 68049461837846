import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row, Form } from "react-bootstrap";

import { customerActions, alertActions } from "_actions";

import Button from "components/Button";
import ConfirmationModal from "components/ConfirmationModal";

export default function CustomerbActions({ orderDetails }) {
  const dispatch = useDispatch();

  const [showClearInfoConfirm, setShowClearInfoConfirm] = useState(false);

  const handleClearCustomerInfo = () => {
    if (orderDetails && orderDetails?.customer?.id) {
      dispatch(customerActions.clearCustomerInfo(orderDetails?.order?.id, orderDetails?.customer?.id));
    } else {
      dispatch(alertActions.error("Order is invalid or doesn't contain related customer UID"));
    }
  };

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={4}>
          Clear Customer Info:
        </Form.Label>
        <Col sm={8}>
          <Button variant="danger" onClick={() => setShowClearInfoConfirm(true)}>
            Clear Info
          </Button>
        </Col>
      </Form.Group>
      <ConfirmationModal
        show={showClearInfoConfirm}
        handleClose={() => {
          setShowClearInfoConfirm(false);
        }}
        handleConfirm={() => {
          handleClearCustomerInfo();
          setShowClearInfoConfirm(false);
        }}
        body={() => {
          return (
            <div>
              <div>Are you sure you want to Clear Customer Info?</div>
              <div style={{ color: "gray" }}>(Email will replace with unique generated value)</div>
            </div>
          );
        }}
      />
    </>
  );
}
