import { config } from "_config";
import { authHeader, mappingQueryParams, handleResponse } from "_helpers";

export const consumerLogsService = { get, getTypes };

function get(params) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.healthcheckurl}/healthcheck/consumer-logs${mappingQueryParams(params)}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getTypes() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.healthcheckurl}/healthcheck/consumer-logs-types`;

  return fetch(url, requestOptions).then(handleResponse);
}
