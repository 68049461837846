import { Row, Form } from "react-bootstrap";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";
import DateOfBirth from "components/OrderDetails/ProductDetails/DateOfBirth/View";

import StatusView from "../Status/View";

function FormBoi({ orderDetails, selectedProduct }) {
  const productDetails = orderDetails?.products[selectedProduct];

  const isEINProductIncluded =
    productDetails?.code?.code === "EIN_BOI" || orderDetails?.products?.some(({ code }) => code?.code === "INC_EIN");

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">Beneficial Ownership Information Report</h4>

      <StatusView orderDetails={orderDetails} selectedProduct={selectedProduct} />

      <FormPDF productDetails={productDetails} />

      <hr />
      <DateOfBirth productDetails={productDetails} />

      {!isEINProductIncluded && (
        <>
          <hr />
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Social Security Number:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails?.ssn || "N/A"}
            </Form.Label>
          </Form.Group>
        </>
      )}

      <hr />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Driver's License or ID Number:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.driverLicenceOrID || "N/A"}
        </Form.Label>
      </Form.Group>
    </div>
  );
}

export default FormBoi;
