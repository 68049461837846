import { Col, Row, Form } from "react-bootstrap";
import moment from "moment-timezone";

export default function TrademarkEventList({ trademarkEvents }) {
  if (!trademarkEvents) return null;

  return (
    <>
      {trademarkEvents.length > 0 ? (
        trademarkEvents.map((event, idx) => (
          <Col key={idx} sm={12} className="pl-3">
            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Code:
              </Form.Label>
              <Form.Label column sm={7}>
                {event.code}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Type:
              </Form.Label>
              <Form.Label column sm={7}>
                {event.type}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Description:
              </Form.Label>
              <Form.Label column sm={7}>
                {event.descriptionText}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Date:
              </Form.Label>
              <Form.Label column sm={7}>
                {moment(event.date).format("YYYY/MM/DD")}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Number:
              </Form.Label>
              <Form.Label column sm={7}>
                {event.number}
              </Form.Label>
            </Form.Group>
          </Col>
        ))
      ) : (
        <Form.Group as={Row}>
          <Form.Label column sm={12}>
            No Events
          </Form.Label>
        </Form.Group>
      )}
    </>
  );
}
