import { useSelector } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes";
import moment from "moment-timezone";

export const initTrademarkClassification = {
  internationalCodeTotalNo: "",
  usCodeTotalNo: "",
  internationalCode: [],
  usCode: [],
  statusCode: "",
  statusDate: moment().format("YYYY-MM-DD"),
  firstUseAnywhereDate: moment().format("YYYY-MM-DD"),
  firstUseInCommerceDate: moment().format("YYYY-MM-DD"),
  primaryCode: "",
};

export default function TrademarkClassificationList({ trademarkClassifications, onError, onChange }) {
  const { internationalCodes, internationalCodesOpt, usCodes, usCodesOpt } = useSelector(({ variants }) => variants);

  const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  };

  const handleAddClassification = () => onChange([...trademarkClassifications, { ...initTrademarkClassification }]);

  const handleDeleteClassification = (deleteIdx) =>
    onChange(trademarkClassifications.filter((o, _idx) => deleteIdx !== _idx));

  const handleClassificationChange = ({ target: { name, value } }, index) =>
    onChange(
      trademarkClassifications.map((trademarkClassification, _idx) =>
        _idx === index ? { ...trademarkClassification, [name]: value } : trademarkClassification
      )
    );

  const handleInternationalCodeChange = (arr, index) =>
    handleClassificationChange({ target: { name: "internationalCode", value: arr.map(({ value }) => value) } }, index);

  const handleUSCodeChange = (arr, index) =>
    handleClassificationChange({ target: { name: "usCode", value: arr.map(({ value }) => value) } }, index);

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          <h6 className="font-weight-bold">Classifications</h6>
        </Form.Label>
        <Col sm={7}>
          <Button size="sm" onClick={handleAddClassification}>
            Add Classification
          </Button>
        </Col>
      </Form.Group>

      {trademarkClassifications?.map((classification, idx) => (
        <Col key={idx} sm={12} className="pl-3">
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              International code:
            </Form.Label>
            <Col sm={7}>
              <ReactMultiselectCheckboxes
                className="reactMultiSelectCheckboxes"
                placeholderButtonLabel="International code"
                getDropdownButtonLabel={getDropdownButtonLabel}
                name="internationalCode"
                options={internationalCodes}
                value={classification.internationalCode.map((value) => ({
                  value,
                  label: internationalCodesOpt[value],
                }))}
                onChange={(e) => handleInternationalCodeChange(e, idx)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              US code:
            </Form.Label>
            <Col sm={7}>
              <ReactMultiselectCheckboxes
                className="reactMultiSelectCheckboxes"
                placeholderButtonLabel="US code"
                getDropdownButtonLabel={getDropdownButtonLabel}
                name="usCode"
                options={usCodes}
                value={classification.usCode.map((value) => ({ value, label: usCodesOpt[value] }))}
                onChange={(e) => handleUSCodeChange(e, idx)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Status code:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                size="sm"
                type="text"
                name="statusCode"
                value={classification.statusCode || ""}
                onChange={(e) => handleClassificationChange(e, idx)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Status date:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                size="sm"
                type="date"
                name="statusDate"
                value={classification.statusDate || ""}
                onChange={(e) => handleClassificationChange(e, idx)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              First use anywhere date:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                size="sm"
                type="date"
                name="firstUseAnywhereDate"
                value={classification.firstUseAnywhereDate || ""}
                onChange={(e) => handleClassificationChange(e, idx)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              First use in commerce date:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                size="sm"
                type="date"
                name="firstUseInCommerceDate"
                value={classification.firstUseInCommerceDate || ""}
                onChange={(e) => handleClassificationChange(e, idx)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Primary code:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                size="sm"
                type="text"
                name="primaryCode"
                value={classification.primaryCode || ""}
                onChange={(e) => handleClassificationChange(e, idx)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              {classification.internationalCodeTotalNo}
            </Form.Label>
            <Col sm={7}>
              <Button size="sm" variant="danger" onClick={() => handleDeleteClassification(idx)}>
                Remove
              </Button>
            </Col>
          </Form.Group>
        </Col>
      ))}
    </>
  );
}
