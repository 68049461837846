import { config } from "_config";
import { handleResponse, authHeader } from "_helpers";

export const offerService = {
  getOffers,
  editOffer,
  addOffer,
  deleteOffer,
  getOfferReports,
};

function getOfferReports(searchOptions) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.apiUrl3}/clicks/offer-report?from=${searchOptions.fromDate}&to=${searchOptions.toDate}`;

  if (searchOptions.offerGroup) {
    url += `&group_uid=${searchOptions.offerGroup}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function getOffers(uid) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl3}/offer-groups/${uid}/offers`;

  return fetch(url, requestOptions).then(handleResponse);
}

function addOffer(form, uid) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(form),
  };

  const url = `${config.apiUrl3}/offer-groups/${uid}/offers`;

  return fetch(url, requestOptions).then(handleResponse);
}

function editOffer(form, uid) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(form),
  };

  const url = `${config.apiUrl3}/offer-groups/${form.groupUid}/offers/${uid}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function deleteOffer(id, groupId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  const url = `${config.apiUrl3}/offer-groups/${groupId}/offers/${id}`;

  return fetch(url, requestOptions).then(handleResponse);
}
