import { Row, Col, Form } from "react-bootstrap";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import CommonLawTrademarkResultListEdit from "../CommonLawTrademarkResultList/Edit";
import FederalSearchResultListEdit from "../FederalSearchResultList/Edit";
import GlobalTrademarkResultListEdit from "../GlobalTrademarkResultList/Edit";
import StateSearchResultListEdit from "../StateSearchResultList/Edit";
import StatusEdit from "../../Status/Edit";

import { TAB_CODES } from "variables/Variables";

export default function FormTrademarkSearch({ orderDetails, selectedProduct, onError, onChange, ...props }) {
  const productDetails = orderDetails.products[selectedProduct];

  const isBasicProduct = productDetails.code.code === "TRADEMARK_Trademark_Search_Basic";
  const isCommonLawProduct = productDetails.code.code === "TRADEMARK_Trademark_Search_Common_Law";
  const isGlobalProduct = productDetails.code.code === "TRADEMARK_Trademark_Search_Global";

  const handleAddError = (field) => onError(TAB_CODES.trademarkInfo, field);

  const handleProductChange = ({ target: { name, value } }) => {
    let exFields = { [name]: value };

    if (name === "searchTermType" && value !== "LOGO") {
      exFields = { ...exFields, colorsInLogo: "", literalElements: "" };
    }

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, ...exFields } : product
      ),
    });
  };

  const handleProductProcessingResultChange = ({ name, value }) =>
    handleProductChange({
      target: {
        name: "processingResult",
        value: { ...productDetails.processingResult, [name]: value },
      },
    });

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">SEARCH TRADEMARK</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
      />

      <FormPDF productDetails={productDetails} {...props} />

      <FederalSearchResultListEdit
        federalSearchResults={productDetails.processingResult.federalSearchResults}
        onError={handleAddError}
        onChange={(value) =>
          handleProductProcessingResultChange({
            name: "federalSearchResults",
            value,
          })
        }
      />

      {!isBasicProduct && (
        <StateSearchResultListEdit
          stateSearchResults={productDetails.processingResult.stateSearchResults}
          onError={handleAddError}
          onChange={(value) =>
            handleProductProcessingResultChange({
              name: "stateSearchResults",
              value,
            })
          }
        />
      )}

      {(isCommonLawProduct || isGlobalProduct) && (
        <CommonLawTrademarkResultListEdit
          commonLawTrademarkResults={productDetails.processingResult.commonLawResults}
          onError={handleAddError}
          onChange={(value) =>
            handleProductProcessingResultChange({
              name: "commonLawResults",
              value,
            })
          }
        />
      )}

      {isGlobalProduct && (
        <GlobalTrademarkResultListEdit
          label="Global search results"
          globalSearchs={productDetails.processingResult.globalSearchResults}
          onError={handleAddError}
          onChange={(value) =>
            handleProductProcessingResultChange({
              name: "globalSearchResults",
              value,
            })
          }
        />
      )}

      <hr />
      <h6 className="font-weight-bold">Mark Information</h6>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Trademark Type:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            as="select"
            size="sm"
            name="searchTermType"
            value={productDetails.searchTermType || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Trademark Type")}
            required
          >
            <option value="">Select type...</option>
            <option value="NAME">Name</option>
            <option value="SLOGAN">Slogan</option>
            <option value="LOGO">Logo</option>
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Trademark Description:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="text"
            value={productDetails.text || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Trademark Description")}
          />
        </Col>
      </Form.Group>

      {productDetails.trademarkType === "LOGO" && (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Logo Image:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="logoImage"
                value={productDetails.logoImage || ""}
                onChange={handleProductChange}
                onInvalid={() => handleAddError("Logo Image")}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Logo Words:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="logoWords"
                value={productDetails.logoWords || ""}
                onChange={handleProductChange}
                onInvalid={() => handleAddError("Logo Words")}
              />
            </Col>
          </Form.Group>
        </>
      )}
    </div>
  );
}
