import { Row, Form } from "react-bootstrap";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import { trademarkUseStatus } from "constants/trademarks.constants";

import IndividualView from "./Individual/View";
import TrademarkClassesListView from "./TrademarkClassesList/View";
import NewAddressView from "./NewAddress/View";

import StatusView from "../Status/View";

export default function FormTrademarkRenewal({ orderDetails, selectedProduct }) {
  const productDetails = orderDetails.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">RENEWAL TRADEMARK</h4>

      <StatusView orderDetails={orderDetails} selectedProduct={selectedProduct} />

      <FormPDF productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">Mark Information</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Serial Number:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.serialNumber}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Trademark Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.trademarkName}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          In use status:
        </Form.Label>
        <Form.Label column sm={7}>
          {trademarkUseStatus[productDetails.inUseStatus]}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          New specimen was uploaded?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.newSpecimenWasUploaded ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Specimen description:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.specimenDescription}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Is the Owner the same?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.newFirstName || productDetails.newLastName ? "No" : "Yes"}
        </Form.Label>
      </Form.Group>
      {(productDetails.newFirstName || productDetails.newLastName) && (
        <Form.Group className="pl-4">
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              New First Name
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.newFirstName}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              New Last Name
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.newLastName}
            </Form.Label>
          </Form.Group>
        </Form.Group>
      )}

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Is the Address the same?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.newAddress ? "No" : "Yes"}
        </Form.Label>
      </Form.Group>
      {productDetails.newAddress && (
        <Form.Group className="pl-4">
          <NewAddressView newAddress={productDetails.newAddress} />
        </Form.Group>
      )}

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Website URL:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.websiteURL}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Trademark Owner</h6>
      <IndividualView individual={productDetails.trademarkOwner} />

      <TrademarkClassesListView
        label="Goods and Services still in use"
        classes={productDetails.goodsAndServicesStillInUse}
      />

      <TrademarkClassesListView
        label="Goods and Services not longer in use"
        classes={productDetails.goodsAndServicesNoLongerInUse}
      />
    </div>
  );
}
