import clsx from "clsx";
import { useState } from "react";
import { Row, Form, Card } from "react-bootstrap";
import styled, { css } from "styled-components";

import { parseGoodsCategory } from "_helpers";

const StyledCard = styled(Card)`
  padding-top: 8px;
  padding-bottom: 8px;

  .form-group:not(:last-child) {
    margin-bottom: 12px;
  }

  .form-label {
    display: flex;
    align-items: center;
    color: black;
    margin: 0;
    padding-top: 3px;
    padding-bottom: 3px;
    text-transform: unset;
    font-size: 0.875rem;
  }

  & > hr {
    margin: 1rem 0;
  }
`;

const InfoText = styled.span`
  color: gray;
`;

const DescText = styled.span`
  background-color: lightgreen;
  padding: 1px 4px;
  /* white-space: nowrap; */
  border-radius: 3px;

  ${({ $default }) =>
    $default &&
    css`
      background-color: lightgray;
    `}
`;

const DescriptionNumber = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  background-color: black;
  color: white;
  min-width: 1em;
  max-width: 2em;
  height: 1em;
  padding: 2px;
`;

export default function TrademarkClassesList({ label, classes }) {
  const [collapsedRows, setCollapsedRows] = useState([]);

  if (!classes) return null;

  const handleCollapse = (rowId) => {
    if (collapsedRows.includes(rowId)) {
      setCollapsedRows(collapsedRows.filter((itemId) => rowId !== itemId));
    } else {
      setCollapsedRows([...collapsedRows, rowId]);
    }
  };

  const isRowCollapsed = (rowId) => {
    return collapsedRows.includes(rowId);
  };

  return (
    <>
      <hr />
      <h6 className="font-weight-bold">
        {label} <InfoText>({classes?.length || "0"})</InfoText>
      </h6>

      {classes?.length > 0 ? (
        classes.map(({ goods, goodsCategory, goodsServiceDetail, descriptions = [] }, idx) => {
          const goodsCategoryArray = parseGoodsCategory(goodsCategory);
          const descsArray = goodsCategoryArray.filter(({ type }) => type === "desc");

          return (
            <StyledCard key={`classes-card-${idx}`} className="mb-3 px-2">
              <Form.Group as={Row} className="mb-0">
                <Form.Label column xs={12} className="align-items-start" onClick={() => handleCollapse(idx)}>
                  <i
                    className={clsx("fa", {
                      "fa-caret-up": isRowCollapsed(idx),
                      "fa-caret-down": !isRowCollapsed(idx),
                    })}
                  />
                  &nbsp;
                  <InfoText className="mr-2">#{idx + 1}</InfoText>
                  &nbsp;
                  <InfoText className="mr-2">{goods ? "Goods" : "Service"}</InfoText>
                  &nbsp;
                  <div style={{ overflowWrap: "break-word" }}>
                    {goodsCategory ? (
                      goodsCategoryArray.map(({ type, text, descIndex }, blockId) => {
                        if (type === "desc") {
                          if (descriptions[descIndex]) {
                            return (
                              <DescText key={`category-${idx}-${blockId}`} title={text}>
                                <span style={{ whiteSpace: "nowrap" }}>
                                  <DescriptionNumber>{descIndex + 1}</DescriptionNumber>&nbsp;
                                </span>
                                {descriptions[descIndex]}
                              </DescText>
                            );
                          }

                          return (
                            <DescText $default key={`category-${idx}-${blockId}`} title={text}>
                              <span style={{ whiteSpace: "nowrap" }}>
                                <DescriptionNumber>{descIndex + 1}</DescriptionNumber>&nbsp;
                              </span>
                              {text}
                            </DescText>
                          );
                        }
                        return <span key={`category-${idx}-${blockId}`}>{text}</span>;
                      })
                    ) : (
                      <InfoText>{"<new class>"}</InfoText>
                    )}
                  </div>
                </Form.Label>
              </Form.Group>

              {isRowCollapsed(idx) && (
                <>
                  <hr />
                  <Form.Group as={Row}>
                    <Form.Label column sm={5}>
                      {goods ? "Goods" : "Services"}:
                    </Form.Label>
                    <Form.Label column sm={7}>
                      {goodsCategory}
                    </Form.Label>
                  </Form.Group>
                  {descsArray?.map((_, descIndex) => (
                    <Form.Group as={Row} key={`classDescription-${idx}-${descIndex}`}>
                      <Form.Label column sm={5}>
                        <div className="h-100">
                          <DescriptionNumber>{descIndex + 1}</DescriptionNumber>&nbsp;Please,
                          {` ${descsArray[descIndex].text}`}:
                        </div>
                      </Form.Label>
                      <Form.Label column sm={7}>
                        <div className="h-100">
                          {Array.isArray(descriptions) && descriptions[descIndex] ? descriptions[descIndex] : "—"}
                        </div>
                      </Form.Label>
                    </Form.Group>
                  ))}
                  <Form.Group as={Row}>
                    <Form.Label column sm={5}>
                      Details:
                    </Form.Label>
                    <Form.Label column sm={7}>
                      {goodsServiceDetail}
                    </Form.Label>
                  </Form.Group>
                </>
              )}
            </StyledCard>
          );
        })
      ) : (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            No Classes
          </Form.Label>
        </Form.Group>
      )}
    </>
  );
}
