import { trademarkDashboardConstants } from "_constants";

const initState = {
  loading: false,
  countOfOrdersPerStatus: {},
};

export function trademarkDashboard(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case trademarkDashboardConstants.GET_REQUEST:
      return { ...state, loading: true };
    case trademarkDashboardConstants.GET_SUCCESS:
      return { ...state, loading: false, ...payload };
    case trademarkDashboardConstants.GET_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
}
