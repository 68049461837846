import { offerGroupConstants } from "_constants";

const initialState = {
  list: [],
  error: "",
  status: "idle",
};

export default function offerGroup(state = initialState, action) {
  switch (action.type) {
    case offerGroupConstants.GET_OFFERGROUP_REQUEST:
      return { ...state, status: "loading" };
    case offerGroupConstants.GET_OFFERGROUP_SUCCESS:
      return { ...state, status: "succeeded", list: action.data };
    case offerGroupConstants.GET_OFFERGROUP_FAIL:
      return { ...state, status: "failed", error: action.message };
    case offerGroupConstants.ADD_OFFERGROUP_REQUEST:
      return { ...state, status: "loading" };
    case offerGroupConstants.ADD_OFFERGROUP_SUCCESS:
      return { ...state, status: "succeeded" };
    case offerGroupConstants.ADD_OFFERGROUP_FAIL:
      return { ...state, status: "failed", error: action.message };
    default:
      return state;
  }
}
