import { useState } from "react";
import { Row, Col, Form, Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";

import { userlogsActions } from "_actions";

import Spinner from "components/Spinner";
import Button from "components/Button";

function TabUserLog() {
  const dispatch = useDispatch();
  const userLogs = useSelector(({ userlogs: { items } }) => items);
  const loading = useSelector(({ userlogs: { loading } }) => loading);

  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [httpMethod, setHttpMethod] = useState("");
  const [uri, setUri] = useState("");
  const [payload, setPayload] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [responseCode, setResponseCode] = useState("");
  const [username, setUsername] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(userlogsActions.getAll(startDate, endDate, httpMethod, uri, payload, ipAddress, responseCode, username));
  };

  const exportcsv = () => {
    dispatch(
      userlogsActions.exportcsv(startDate, endDate, httpMethod, uri, payload, ipAddress, responseCode, username)
    );
  };

  return (
    <div className="form-border bg-white fixed-height action-log">
      <Row>
        <Col md={12}>
          <Card className="p-3">
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3}>
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Start Date"
                    name="startDate"
                    size="sm"
                    value={startDate}
                    max={endDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3}>
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="End Date"
                    name="endDate"
                    size="sm"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3}>
                  <Form.Label>URI</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="URI"
                    name="uri"
                    size="sm"
                    value={uri}
                    onChange={(e) => setUri(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3}>
                  <Form.Label>Ip Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="IP Address"
                    name="ipAddress"
                    size="sm"
                    value={ipAddress}
                    onChange={(e) => setIpAddress(e.target.value)}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3}>
                  <Form.Label>User Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="User Name"
                    name="username"
                    size="sm"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3}>
                  <Form.Label>Response Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Response Code"
                    name="responseCode"
                    size="sm"
                    value={responseCode}
                    onChange={(e) => setResponseCode(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3}>
                  <Form.Label>Http Method</Form.Label>
                  <Form.Control
                    as="select"
                    id="inlineFormCustomSelectPref"
                    size="sm"
                    value={httpMethod}
                    onChange={(e) => setHttpMethod(e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="GET">GET</option>
                    <option value="POST">POST</option>
                    <option value="PUT">PUT</option>
                    <option value="DELETE">DELETE</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3}>
                  <Form.Label>Payload</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Payload"
                    name="payload"
                    size="sm"
                    value={payload}
                    onChange={(e) => setPayload(e.target.value)}
                  />
                </Form.Group>

                <Form.Group
                  as={Col}
                  className="d-flex align-items-sm-start justify-content-sm-start"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={3}
                  xl={3}
                >
                  <Button type="submit" size="sm" className="mr-1" isLoading={loading}>
                    Search
                  </Button>

                  <Button size="sm" onClick={exportcsv}>
                    Export CSV
                  </Button>
                </Form.Group>
              </Form.Row>
            </Form>
          </Card>

          {userLogs && (
            <Card className="mb-1 full-height">
              <Card.Title className="m-0 p-2">User Action Logs Information</Card.Title>
              <Table striped bordered responsive size="sm" className="userlogstable">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>User Name</th>
                    <th>IP</th>
                    <th>URI</th>
                    <th>Http Method</th>
                    <th>Request Payload</th>
                    <th>Response Code</th>
                    <th>Response Payload</th>
                  </tr>
                </thead>
                <tbody>
                  {userLogs.map((prop, key) => (
                    <tr key={key}>
                      <td>{moment(prop.created).format("YYYY/MM/DD HH:mm:ss")}</td>
                      <td>{prop.username || "N/A"}</td>
                      <td>{prop.ip}</td>
                      <td>{prop.uri}</td>
                      <td>{prop.httpMethod}</td>
                      <td>{prop.payload}</td>
                      <td>{prop.responseCode}</td>
                      <td>{prop.responseBody}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          )}

          {loading && <Spinner />}
        </Col>
      </Row>
    </div>
  );
}

export default TabUserLog;
