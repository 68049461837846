import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, Row, Col, Form } from "react-bootstrap";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import styled from "styled-components";
import moment from "moment-timezone";

import Button from "components/Button";

const StyledButton = styled(Button)`
  height: max-content;
  align-self: flex-end;
`;

function TrademarkDashboardFilters({
  date,
  onApply,
  statusesOptions,
  statusesToShow,
  orientation,
  isZeroesHidden,
  dateRange,
  productCode,
  handlers,
}) {
  const {
    statusesHandler,
    dateHandler,
    orientationHandler,
    hideZeroesHandler,
    showNonZeroesHandler,
    handleChangeDataRange,
    productCodeHandler,
    saveHandler,
    defaultSettingsHandler,
  } = handlers;

  const { productCodes } = useSelector(({ variants }) => variants);

  const [loadingVariants, loadingDashboard] = useSelector(({ variants, trademarkDashboard }) => [
    variants.loading,
    trademarkDashboard.loading,
  ]);
  const loading = loadingVariants || loadingDashboard;

  const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  };

  const handleChangeDate = ({ target: { name, value } }) => dateHandler({ ...date, [name]: value });
  const handleChangeOrientation = ({ target: { value } }) => orientationHandler(value);
  const handleChangeHideZeroes = ({ target: { value } }) => hideZeroesHandler(value);

  return (
    <Card className="p-2">
      <Row>
        <Col>
          <Form>
            <Form.Row>
              <Form.Group as={Col} md>
                <Form.Label>Date Range:</Form.Label>
                <Form.Control size="sm" as="select" name="dateRange" value={dateRange} onChange={handleChangeDataRange}>
                  <option value="" hidden></option>
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="lastweek">Last Week</option>
                  <option value="monthtodate">Month to Date</option>
                  <option value="pastmonth">Past Month</option>
                  <option value="lastmonth">Last Month</option>
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} md>
                <Form.Label>Start Date:</Form.Label>
                <Form.Control
                  size="sm"
                  type="date"
                  name="from"
                  max={moment().format("YYYY-MM-DD")}
                  value={date.from}
                  onChange={handleChangeDate}
                />
              </Form.Group>

              <Form.Group as={Col} md>
                <Form.Label>End Date:</Form.Label>
                <Form.Control
                  size="sm"
                  type="date"
                  name="to"
                  max={moment().format("YYYY-MM-DD")}
                  value={date.to}
                  onChange={handleChangeDate}
                />
              </Form.Group>

              <Form.Group as={Col} md>
                <Form.Label>Product Code:</Form.Label>
                <Form.Control size="sm" as="select" value={productCode} onChange={productCodeHandler}>
                  <option value="">All</option>
                  {productCodes?.map(({ code, customerName }, idx) => (
                    <option key={`${code}-${idx}`} value={code}>
                      {customerName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} md style={{ display: "flex", alignItems: "flex-to" }}>
                <StyledButton size="sm" className="mr-2" isLoading={loading} onClick={onApply}>
                  Filter
                </StyledButton>
              </Form.Group>
            </Form.Row>

            <hr />

            <Form.Row>
              <Form.Group as={Col} md={3}>
                <Form.Label>Chart Orientation:</Form.Label>
                <Form.Control
                  size="sm"
                  as="select"
                  name="orientation"
                  value={orientation}
                  onChange={handleChangeOrientation}
                >
                  <option value="horizontal">Horizontal</option>
                  <option value="vertical">Vertical</option>
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label>Processing Statuses</Form.Label>
                <ReactMultiSelectCheckboxes
                  className="reactMultiSelectCheckboxes"
                  placeholderButtonLabel={"Status"}
                  name={"processingStatus"}
                  value={statusesToShow}
                  options={statusesOptions}
                  onChange={(value, event) => {
                    if (event.action === "select-option" && event.option.value === "*") {
                      statusesHandler(statusesOptions);
                    } else if (event.action === "deselect-option" && event.option.value === "*") {
                      statusesHandler([]);
                    } else if (event.action === "deselect-option") {
                      statusesHandler(value.filter((o) => o.value !== "*"));
                    } else if (value.length === statusesOptions.length - 1) {
                      statusesHandler(statusesOptions);
                    } else {
                      statusesHandler(value);
                    }
                  }}
                  getDropdownButtonLabel={getDropdownButtonLabel}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label>Hide zeroes:</Form.Label>
                <Form.Control
                  size="sm"
                  as="select"
                  name="hideZeroes"
                  value={isZeroesHidden}
                  onChange={handleChangeHideZeroes}
                >
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} md style={{ display: "flex", alignItems: "flex-to" }}>
                {/* <Button size="sm" onClick={() => hideZerosHandler()} className="mr-2">
                  Hide zeroes
                </Button> */}
                <StyledButton size="sm" onClick={() => showNonZeroesHandler()} className="mr-2">
                  Show all non-zero
                </StyledButton>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md style={{ display: "flex", alignItems: "flex-to" }}>
                <StyledButton size="sm" onClick={saveHandler} className="mr-2">
                  Save settings
                </StyledButton>
                <StyledButton size="sm" onClick={defaultSettingsHandler} className="mr-2">
                  Default settings
                </StyledButton>
                <StyledButton size="sm" onClick={() => location.reload()} className="mr-2">
                  Reset to saved
                </StyledButton>
              </Form.Group>
            </Form.Row>
          </Form>
        </Col>
      </Row>
    </Card>
  );
}

export default TrademarkDashboardFilters;
