import { config } from "_config";
import { handleResponse, authHeader } from "_helpers";

export const offerGroupService = {
  getAll,
  editGroup,
  addGroup,
  deleteGroup,
};

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  const url = `${config.apiUrl3}/offer-groups`;

  return fetch(url, requestOptions).then(handleResponse);
}

function addGroup(form) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(form),
  };

  const url = `${config.apiUrl3}/offer-groups`;

  return fetch(url, requestOptions).then(handleResponse);
}

function editGroup(form, uid) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(form),
  };

  const url = `${config.apiUrl3}/offer-groups/${uid}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function deleteGroup(uid) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  const url = `${config.apiUrl3}/offer-groups/${uid}`;

  return fetch(url, requestOptions).then(handleResponse);
}
