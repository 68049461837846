import { zendeskConstants } from '../_constants';

const initial_state = {
  tickets: [],
  accounts: [],
};

export function tickets(state = initial_state, action) {
  switch (action.type) {
    case zendeskConstants.GETTICKET_SUCCESS:
      return { ...state, tickets: action.results };
    case zendeskConstants.GET_ZENDESK_ACCOUNT_SUCCESS:
      return { ...state, accounts: action.accounts };
    case zendeskConstants.ADD_ZENDESK_ACCOUNT_SUCCESS:
      return { ...state, accounts: [...state.accounts, action.account] };
    default:
      return state;
  }
}
