import { Fragment, useState, useEffect } from "react";
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import styled, { css } from "styled-components";
import clsx from "clsx";
import cloneDeep from "lodash.clonedeep";

import { GOODS_TYPES, SERVICES_TYPES } from "_constants";
import { parseGoodsCategory } from "_helpers";
import { variantsService } from "_services";
import { usePrevious } from "_hooks";

export const initClass = {
  goods: true,
  goodsCategory: "",
  goodsServiceDetail: "",
  descriptions: [],
  description: null,
};

const StyledCard = styled(Card)`
  padding-top: 8px;
  padding-bottom: 8px;

  .form-group:not(:last-child) {
    margin-bottom: 12px;
  }

  .form-label {
    display: flex;
    align-items: center;
    color: black;
    margin: 0;
    padding-top: 3px;
    padding-bottom: 3px;
    text-transform: unset;
    font-size: 0.875rem;
  }

  .form-check-label {
    color: unset;
    font-size: unset;
    text-transform: unset;
  }

  & > hr {
    margin: 1rem 0;
  }
`;

const InfoText = styled.span`
  color: gray;
`;

const DescText = styled.span`
  background-color: lightgreen;
  padding: 1px 4px;
  border-radius: 3px;

  ${({ $default }) =>
    $default &&
    css`
      background-color: lightgray;
    `}
`;

const DescriptionNumber = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  background-color: black;
  color: white;
  min-width: 1em;
  max-width: 2em;
  height: 1em;
  padding: 2px;
`;

export default function TrademarkClassesList({ id, validated, label, classes, onError, onChange }) {
  const [isLoading, setIsLoading] = useState(false);
  const [shadowClasses, setShadowClasses] = useState([...(classes || [])]);
  const [collapsedRows, setCollapsedRows] = useState([]);

  const prevShadowClasses = usePrevious(shadowClasses);

  const handleCollapse = (rowId) => {
    if (collapsedRows.includes(rowId)) {
      setCollapsedRows(collapsedRows.filter((itemId) => rowId !== itemId));
    } else {
      setCollapsedRows([...collapsedRows, rowId]);
    }
  };

  const isRowCollapsed = (rowId) => {
    return collapsedRows.includes(rowId);
  };

  const handleAddClass = () => {
    const newClasses = classes ? [...classes, { ...initClass }] : [{ ...initClass }];
    setShadowClasses(newClasses);
    onChange(newClasses);
  };

  const handleDeleteClass = (deleteIdx) => {
    const newClasses = classes.filter((o, _idx) => deleteIdx !== _idx);
    setShadowClasses(newClasses);
    onChange(newClasses);
  };

  const handleClassChange = ({ target: { name, value } }) => {
    const [index, field, descIndex] = name.split(".");
    const newVal = field === "goods" ? value === "goods" : value;
    const selectedClass = { ...classes[index] };

    switch (field) {
      case "goods":
        selectedClass[field] = newVal;
        selectedClass.descriptions = [];
        selectedClass.goodsCategory = "";
        break;

      case "goodsCategory":
        selectedClass[field] = newVal;

        if (!newVal) {
          selectedClass.descriptions = [];
        } else {
          const goodsCategoryArray = parseGoodsCategory(selectedClass.goodsCategory);
          const descsArray = goodsCategoryArray.filter(({ type }) => type === "desc");

          if (descsArray?.length !== 0) {
            selectedClass.descriptions = descsArray.map(() => "");
          } else {
            selectedClass.descriptions = [];
          }
        }
        break;

      case "descriptions":
        if (!selectedClass[field]) {
          selectedClass[field] = [];
        }
        selectedClass[field][descIndex] = newVal;
        break;

      default:
        selectedClass[field] = newVal;
        break;
    }

    const newClasses = classes.map((item, idx) => (idx === Number(index) ? { ...selectedClass } : item));
    setShadowClasses(newClasses);
    onChange(newClasses);
  };

  const loadCategoryOptions =
    (goods) =>
    async (search, prevOptions, { page }) => {
      const options = [];
      let hasMore = false;

      setIsLoading(true);

      try {
        const { elements, maxPerPage, totalResults } = await variantsService.getUSPTOCategories({
          page,
          count: 10,
          search,
          isGoods: goods ? "true" : "false",
        });

        hasMore = totalResults > maxPerPage * page;

        elements.forEach(({ termId, description }) => {
          options.push({
            label: `${termId} - ${description}`,
            value: `${termId} - ${description}`,
          });
        });
      } catch (e) {
        setIsLoading(false);

        return {
          options: [],
          hasMore: false,
          additional: {
            page: page,
          },
        };
      }

      setIsLoading(false);

      return {
        options,
        hasMore,
        additional: {
          page: page + 1,
        },
      };
    };

  useEffect(() => {
    if (shadowClasses?.length && shadowClasses.length === prevShadowClasses?.length) {
      const idx = shadowClasses?.findIndex(({ goods }, idx) => prevShadowClasses[idx]?.goods !== goods);

      if (idx > -1) {
        const newShadowClasses = cloneDeep(shadowClasses);

        const checkGoodsCategory = async () => {
          setIsLoading(true);

          const { goodsCategory, goods } = shadowClasses[idx];
          const currentTermId = goodsCategory?.split(" - ")[0];

          try {
            const { elements } = await variantsService.getUSPTOCategories({
              page: 1,
              count: 5,
              termId: currentTermId,
              isGoods: goods ? "true" : "false",
            });

            const { termId, description } = elements[0] || {};
            newShadowClasses[idx].goodsCategory = termId === currentTermId ? `${termId} - ${description}` : "";

            setShadowClasses(newShadowClasses);
            setIsLoading(false);
          } catch (e) {
            newShadowClasses[idx].goodsCategory = "";
            setShadowClasses();
            setIsLoading(false);
          }
        };

        checkGoodsCategory();
      }
    }
  }, [prevShadowClasses, shadowClasses]);

  return (
    <>
      <hr />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          <h6 className="font-weight-bold">
            {label} <InfoText>({classes?.length || "0"})</InfoText>
          </h6>
        </Form.Label>
        <Col sm={7}>
          <Button size="sm" onClick={handleAddClass}>
            Add Class
          </Button>
        </Col>
      </Form.Group>

      {classes?.map(({ goods, goodsCategory, goodsServiceDetail, descriptions = [] }, idx) => {
        const goodsCategoryArray = parseGoodsCategory(goodsCategory);
        const descsArray = goodsCategoryArray.filter(({ type }) => type === "desc");

        return (
          <StyledCard key={`classes-card-${idx}`} className="mb-3 px-2">
            <Form.Group as={Row} className="mb-0">
              <Form.Label column xs={5} className="align-items-start" onClick={() => handleCollapse(idx)}>
                <div style={{ width: "100%", overflowWrap: "break-word" }}>
                  <i
                    className={clsx("fa", {
                      "fa-caret-up": isRowCollapsed(idx),
                      "fa-caret-down": !isRowCollapsed(idx),
                    })}
                  />
                  &nbsp;
                  <InfoText className="mr-2">#{idx + 1}</InfoText>
                  &nbsp;
                  <InfoText className="mr-2">{goods ? "Goods" : "Service"}</InfoText>
                  &nbsp;
                  {goodsCategory ? (
                    goodsCategoryArray.map(({ type, text, descIndex }, blockId) => {
                      if (type === "desc") {
                        if (descriptions && descriptions[descIndex]) {
                          return (
                            <DescText key={`category-${idx}-${blockId}`} title={text}>
                              <span style={{ whiteSpace: "nowrap" }}>
                                <DescriptionNumber>{descIndex + 1}</DescriptionNumber>&nbsp;
                              </span>
                              {descriptions[descIndex]}
                            </DescText>
                          );
                        }

                        return (
                          <DescText $default key={`category-${idx}-${blockId}`} title={text}>
                            <span style={{ whiteSpace: "nowrap" }}>
                              <DescriptionNumber>{descIndex + 1}</DescriptionNumber>&nbsp;
                            </span>
                            {text}
                          </DescText>
                        );
                      }
                      return <span key={`category-${idx}-${blockId}`}>{text}</span>;
                    })
                  ) : (
                    <InfoText>{"<new class>"}</InfoText>
                  )}
                </div>
              </Form.Label>
              <Form.Label column xs={7} className="align-items-start">
                <Button size="sm" variant="danger" onClick={() => handleDeleteClass(idx)}>
                  Remove
                </Button>
              </Form.Label>
            </Form.Group>

            <div style={{ display: isRowCollapsed(idx) ? "block" : "none" }}>
              <hr />
              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Do you sell, or intend to sell, a goods or service?
                </Form.Label>
                <Col sm={7} className="d-flex align-items-center">
                  <Form.Check
                    id={id ? `${id}_r${idx}_goods` : `r${idx}_goods`}
                    className="mr-3"
                    type="radio"
                    label="Goods"
                    name={`${idx}.goods.${id}`}
                    value="goods"
                    defaultChecked={goods}
                    onClick={handleClassChange}
                  />
                  <Form.Check
                    id={id ? `${id}_r${idx}_service` : `r${idx}_service`}
                    type="radio"
                    label="Service"
                    name={`${idx}.goods.${id}`}
                    value="services"
                    defaultChecked={!goods}
                    onClick={handleClassChange}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  {goods ? "Goods" : "Service"}:
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    required
                    hidden
                    name={`${idx}.goodsCategory`}
                    value={isLoading ? "" : (shadowClasses && shadowClasses[idx]?.goodsCategory) || ""}
                    onInvalid={() => onError(goods ? "Goods" : "Services")}
                    onChange={() => {}}
                  />
                  <AsyncPaginate
                    key={id ? `${id}.${idx}.${goods ? "goods" : "service"}` : `${idx}.${goods ? "goods" : "service"}`}
                    className="async-select-container"
                    classNamePrefix={clsx({
                      ["async-select"]: !validated,
                      ["async-select-valid"]: validated && shadowClasses && shadowClasses[idx]?.goodsCategory,
                      ["async-select-invalid"]: validated && shadowClasses && !shadowClasses[idx]?.goodsCategory,
                    })}
                    placeholder="Search categories..."
                    isLoading={isLoading}
                    isClearable={shadowClasses && shadowClasses[idx]?.goodsCategory}
                    additional={{ page: 1 }}
                    loadOptions={loadCategoryOptions(goods)}
                    value={{
                      label: shadowClasses
                        ? shadowClasses[idx]?.goodsCategory || "Start typing to search categories…"
                        : "Start typing to search categories…",
                      value: shadowClasses ? shadowClasses[idx]?.goodsCategory : "",
                    }}
                    onChange={(option) =>
                      handleClassChange({ target: { name: `${idx}.goodsCategory`, value: option?.value } })
                    }
                  />
                </Col>
              </Form.Group>

              {descsArray?.map((_, descIndex) => (
                <Form.Group as={Row} key={`classDescription-${idx}-${descIndex}`}>
                  <Form.Label column sm={5}>
                    <div className="h-100">
                      <DescriptionNumber>{descIndex + 1}</DescriptionNumber>&nbsp;Please,
                      {` ${descsArray[descIndex].text}`}:
                    </div>
                  </Form.Label>
                  <Col sm={7}>
                    <Form.Control
                      size="sm"
                      type="text"
                      name={`${idx}.descriptions.${descIndex}`}
                      value={descriptions[descIndex] || ""}
                      onChange={handleClassChange}
                    />
                  </Col>
                </Form.Group>
              ))}

              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Details:
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    size="sm"
                    type="text"
                    name={`${idx}.goodsServiceDetail`}
                    value={goodsServiceDetail || ""}
                    onChange={handleClassChange}
                  />
                </Col>
              </Form.Group>
            </div>
          </StyledCard>
        );
      })}
    </>
  );
}
