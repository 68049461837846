import { variantsConstants } from "_constants";
import { getAllByProductsHandler } from "_helpers/orderDetails";
import { variantsService } from "_services";

import { alertActions } from "./";

export const variantsActions = {
  getProductCategories,
  getproductcodes,
  getprocessing_status,
  getprocessing_status_2,
  getProcessingError,
  getprocessing_error_status,
  get_order_status,
  getvariants,
  getproductcodesforsale,
  getStates,
  getActivities,
  getPrices,
  getProcessingOptions,
  getAllVariants,
  getAllProcessingStatuses,
  getAllProcessingError,
  getAllProductCodes,
  getProcessingValuesByCategories,
  getProductProcessingStatuses,
  getAllProcessingOptions,
  getAllPrices,
};

function getProductCategories() {
  return (dispatch) => {
    dispatch(request());

    variantsService.getProductCategories().then(
      (categories) => {
        dispatch(success(categories));
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: variantsConstants.GET_CATEGORY_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_CATEGORY_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_CATEGORY_FAILURE };
  }
}

function getproductcodes(category) {
  return (dispatch) => {
    dispatch(request());

    variantsService.getproductcodes(category).then(
      (codes) => dispatch(success(codes)),
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: variantsConstants.GET_PRODUCT_CODE_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_PRODUCT_CODE_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_PRODUCT_CODE_FAILURE };
  }
}

function getproductcodesforsale() {
  return (dispatch) => {
    dispatch(request());

    variantsService.getproductcodesforsale().then(
      (codes) => dispatch(success(codes)),
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: variantsConstants.GET_PRODUCT_CODE_SALE_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_PRODUCT_CODE_SALE_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_PRODUCT_CODE_SALE_FAILURE };
  }
}

function getprocessing_status(category) {
  return (dispatch) => {
    dispatch(request());

    variantsService.getprocessing_status(category).then(
      (status) => {
        dispatch(success(status));
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: variantsConstants.GET_PROCESSING_STATUS_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_PROCESSING_STATUS_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_PROCESSING_STATUS_FAILURE };
  }
}

function getprocessing_status_2(category) {
  return (dispatch) => {
    dispatch(request());

    variantsService.getprocessing_status_2(category).then(
      (status) => {
        dispatch(success(status));
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: variantsConstants.GET_PROCESSING_STATUS_2_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_PROCESSING_STATUS_2_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_PROCESSING_STATUS_2_FAILURE };
  }
}

function getProductProcessingStatuses(category, productCode) {
  return (dispatch) => {
    dispatch(request());

    variantsService.getProductProcessingStatus(category, productCode).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: variantsConstants.GET_PRODUCT_PROCESSING_STATUSES_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_PRODUCT_PROCESSING_STATUSES_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_PRODUCT_PROCESSING_STATUSES_FAILURE };
  }
}

function getProductsProcessingStatuses(products) {
  return (dispatch) => {
    dispatch(request());

    const requests = products.map((product) =>
      variantsService.getProductProcessingStatus(product?.category, product?.code)
    );

    Promise.all(requests)
      .then((data) => {
        const payload = data.reduce((acc, { values }, idx) => ({ ...acc, [products[idx]?.code]: values }), {});

        dispatch(success(payload));
      })
      .catch((error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      });
  };

  function request() {
    return { type: variantsConstants.GET_PRODUCTS_PROCESSING_STATUSES_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_PRODUCTS_PROCESSING_STATUSES_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_PRODUCTS_PROCESSING_STATUSES_FAILURE };
  }
}

function getProductFileTypes(products) {
  return (dispatch) => {
    dispatch(request());

    const requests = products.map((product) => variantsService.getProductFileTypes(product));

    Promise.all(requests)
      .then((data) => {
        const payload = data.reduce((acc, values, idx) => ({ ...acc, [products[idx]]: values }), {});

        dispatch(success(payload));
      })
      .catch((error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      });
  };

  function request() {
    return { type: variantsConstants.GET_PRODUCT_FILE_TYPES_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_PRODUCT_FILE_TYPES_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_PRODUCT_FILE_TYPES_FAILURE };
  }
}

function getProcessingError(category) {
  return (dispatch) => {
    dispatch(request());

    variantsService.getProcessingError(category).then(
      (errors) => dispatch(success(errors)),
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: variantsConstants.GET_PROCESSING_ERROR_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_PROCESSING_ERROR_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_PROCESSING_ERROR_FAILURE };
  }
}

function getprocessing_error_status(category) {
  return (dispatch) => {
    dispatch(request());

    variantsService.getprocessing_error_status(category).then(
      (status) => dispatch(success(status)),
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: variantsConstants.GET_PROCESSING_ERROR_STATUS_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_PROCESSING_ERROR_STATUS_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_PROCESSING_ERROR_STATUS_FAILURE };
  }
}

function get_order_status(category) {
  return (dispatch) => {
    dispatch(request());

    variantsService.get_order_status(category).then(
      (status) => dispatch(success(status)),
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: variantsConstants.GET_ORDER_STATUS_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_ORDER_STATUS_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_ORDER_STATUS_FAILURE };
  }
}

function getStates(category) {
  return (dispatch) => {
    dispatch(request());

    variantsService.getStates(category).then(
      (states) => dispatch(success(states)),
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: variantsConstants.GET_STATES_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_STATES_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_STATES_FAILURE };
  }
}

function getActivities(category) {
  return (dispatch) => {
    dispatch(request());

    variantsService.getActivities(category).then(
      (states) => dispatch(success(states)),
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: variantsConstants.GET_ACTIVITIES_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_ACTIVITIES_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_ACTIVITIES_FAILURE };
  }
}

function getInternationalCodes() {
  return (dispatch) => {
    dispatch(request());

    variantsService.getInternationalCodes().then(
      (codes) => dispatch(success(codes)),
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: variantsConstants.GET_INTERNATIONAL_CODES_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_INTERNATIONAL_CODES_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_INTERNATIONAL_CODES_FAILURE };
  }
}

function getUSCodes() {
  return (dispatch) => {
    dispatch(request());

    variantsService.getUSCodes().then(
      (codes) => dispatch(success(codes)),
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: variantsConstants.GET_US_CODES_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_US_CODES_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_US_CODES_FAILURE };
  }
}

function getNationalities() {
  return (dispatch) => {
    dispatch(request());

    variantsService.getNationalities().then(
      (codes) => dispatch(success(codes)),
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: variantsConstants.GET_NATIONALITIES_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_NATIONALITIES_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_NATIONALITIES_FAILURE };
  }
}

function getPrices(category) {
  return (dispatch) => {
    dispatch(request());

    variantsService.getPrices(category).then(
      (prices) => dispatch(success(prices)),
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: variantsConstants.GET_PRICES_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_PRICES_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_PRICES_FAILURE };
  }
}

function getProcessingOptions(category) {
  return (dispatch) => {
    dispatch(request());

    variantsService.getProcessingOptions(category).then(
      (options) => dispatch(success(options.values)),
      (error) => {
        dispatch(failure());
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: variantsConstants.GET_PROCESSING_OPTIONS_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_PROCESSING_OPTIONS_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_PROCESSING_OPTIONS_FAILURE };
  }
}

function getProcessingValuesByCategories() {
  return (dispatch) => {
    dispatch(requestStatuses());
    dispatch(requestStatuses2());
    dispatch(requestErrorStatuses());

    try {
      variantsService.getProductCategories().then(
        (response) => {
          const categories = response?.values?.map((item) => item.code) || [];
          const statusesRequests = categories.map((category) => variantsService.getprocessing_status(category));
          const statusesRequests2 = categories.map((category) => variantsService.getprocessing_status_2(category));
          const errorStatusesRequests = categories.map((category) => variantsService.getProcessingError(category));

          Promise.all(statusesRequests)
            .then((data) => {
              const newObj = {};

              data.forEach(({ values }) => {
                values.forEach(({ code, value }) => {
                  newObj[code] = value;
                });
              });

              dispatch(successStatuses(newObj));
            })
            .catch(() => {
              console.error("At least one of processing-statuses requests return error");
              dispatch(failureStatuses());
            });

          Promise.all(statusesRequests2)
            .then((data) => {
              const newObj = {};

              data.forEach((subData) => {
                subData.forEach(({ subStatuses }) => {
                  subStatuses.forEach(({ subStatusCode, subStatusName }) => {
                    newObj[subStatusCode] = subStatusName;
                  });
                });
              });

              dispatch(successStatuses2(newObj));
            })
            .catch(() => {
              console.error("At least one of processing-statuses requests return error");
              dispatch(failureStatuses2());
            });

          Promise.all(errorStatusesRequests)
            .then((data) => {
              const newObj = {};

              data.forEach(({ values }) => {
                values.forEach(({ code, value }) => {
                  newObj[code] = value;
                });
              });

              dispatch(successErrorStatuses(newObj));
            })
            .catch(() => {
              console.error("At least one of processing-errors requests return error");
              dispatch(failureErrorStatuses());
            });
        },
        (error) => {
          console.error(error);
          dispatch(failureStatuses());
          dispatch(failureStatuses2());
          dispatch(failureErrorStatuses());
        }
      );
    } catch (error) {
      console.error("Can't fetch values by categories", error);
      dispatch(failureStatuses());
      dispatch(failureStatuses2());
      dispatch(failureErrorStatuses());
    }
  };

  function requestStatuses() {
    return { type: variantsConstants.GET_PROCESSING_STATUSES_BY_CATEGORIES_REQUEST };
  }
  function successStatuses(payload) {
    return { type: variantsConstants.GET_PROCESSING_STATUSES_BY_CATEGORIES_SUCCESS, payload };
  }
  function failureStatuses() {
    return { type: variantsConstants.GET_PROCESSING_STATUSES_BY_CATEGORIES_FAILURE };
  }

  function requestStatuses2() {
    return { type: variantsConstants.GET_PROCESSING_STATUSES_BY_CATEGORIES_REQUEST_2 };
  }
  function successStatuses2(payload) {
    return { type: variantsConstants.GET_PROCESSING_STATUSES_BY_CATEGORIES_SUCCESS_2, payload };
  }
  function failureStatuses2() {
    return { type: variantsConstants.GET_PROCESSING_STATUSES_BY_CATEGORIES_FAILURE_2 };
  }

  function requestErrorStatuses() {
    return { type: variantsConstants.GET_PROCESSING_ERROR_STATUSES_BY_CATEGORIES_REQUEST };
  }
  function successErrorStatuses(payload) {
    return { type: variantsConstants.GET_PROCESSING_ERROR_STATUSES_BY_CATEGORIES_SUCCESS, payload };
  }
  function failureErrorStatuses() {
    return { type: variantsConstants.GET_PROCESSING_ERROR_STATUSES_BY_CATEGORIES_FAILURE };
  }
}

function getvariants(category, products) {
  return (dispatch) => {
    dispatch(getProductCategories());
    dispatch(getproductcodes(category));
    products && dispatch(getProductsProcessingStatuses(products));
    products && dispatch(getProductFileTypes(products.map(({ code }) => code)));
    dispatch(getprocessing_status(category));
    dispatch(getProcessingError(category));
    dispatch(getprocessing_error_status(category));
    dispatch(get_order_status(category));
    dispatch(getStates(category));

    if (category === "trademarks") {
      dispatch(getProcessingOptions(category));
    }

    if (category === "TRADEMARKS") {
      dispatch(getInternationalCodes());
      dispatch(getUSCodes());
      dispatch(getNationalities());
    }
  };
}

function getAllVariants(products) {
  return (dispatch) => {
    dispatch(getProductCategories());
    dispatch(getProductsProcessingStatuses(products));
    dispatch(getProductFileTypes(products.map(({ code }) => code)));
    dispatch(getAllProductCodes(products));
    dispatch(getAllProcessingStatuses(products));
    dispatch(getAllProcessingError(products));
    dispatch(getAllOrderStatuses(products));
    dispatch(getAllStates(products));
    dispatch(getAllProcessingErrorStatuses(products));

    if (products?.some(({ category }) => ["TRADEMARKS", "trademarks"].includes(category))) {
      dispatch(getAllProcessingOptions(products));
      dispatch(getInternationalCodes());
      dispatch(getUSCodes());
      dispatch(getNationalities());
    }
  };
}

function getAllProductCodes(products) {
  return (dispatch) => {
    const props = { products, action: variantsService.getproductcodes, dispatch, request, success, failure };
    getAllByProductsHandler(props);
  };

  function request() {
    return { type: variantsConstants.GET_ALL_PRODUCT_CODES_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_ALL_PRODUCT_CODES_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_ALL_PRODUCT_CODES_FAILURE };
  }
}

function getAllProcessingStatuses(products) {
  return (dispatch) => {
    const props = { products, action: variantsService.getprocessing_status, dispatch, request, success, failure };
    getAllByProductsHandler(props);
  };

  function request() {
    return { type: variantsConstants.GET_ALL_PROCESSING_STATUSES_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_ALL_PROCESSING_STATUSES_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_ALL_PROCESSING_STATUSES_FAILURE };
  }
}
function getAllProcessingError(products) {
  return (dispatch) => {
    const props = { products, action: variantsService.getProcessingError, dispatch, request, success, failure };
    getAllByProductsHandler(props);
  };

  function request() {
    return { type: variantsConstants.GET_ALL_PROCESSING_ERRORS_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_ALL_PROCESSING_ERRORS_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_ALL_PROCESSING_ERRORS_FAILURE };
  }
}

function getAllOrderStatuses(products) {
  return (dispatch) => {
    const props = { products, action: variantsService.get_order_status, dispatch, request, success, failure };
    getAllByProductsHandler(props);
  };

  function request() {
    return { type: variantsConstants.GET_ALL_ORDER_STATUSES_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_ALL_ORDER_STATUSES_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_ALL_ORDER_STATUSES_FAILURE };
  }
}

function getAllStates(products) {
  return (dispatch) => {
    const props = { products, action: variantsService.getStates, dispatch, request, success, failure };

    getAllByProductsHandler(props);
  };

  function request() {
    return { type: variantsConstants.GET_ALL_STATES_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_ALL_STATES_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_ALL_STATES_FAILURE };
  }
}

function getAllProcessingErrorStatuses(products) {
  return (dispatch) => {
    const props = { products, action: variantsService.getprocessing_error_status, dispatch, request, success, failure };
    getAllByProductsHandler(props);
  };

  function request() {
    return { type: variantsConstants.GET_ALL_PROCESSING_ERROR_STATUSES_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_ALL_PROCESSING_ERROR_STATUSES_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_ALL_PROCESSING_ERROR_STATUSES_FAILURE };
  }
}

function getAllProcessingOptions(products) {
  return (dispatch) => {
    const props = { products, action: variantsService.getProcessingOptions, dispatch, request, success, failure };
    getAllByProductsHandler(props);
  };

  function request() {
    return { type: variantsConstants.GET_ALL_PROCESSING_OPTIONS_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_ALL_PROCESSING_OPTIONS_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_ALL_PROCESSING_OPTIONS_FAILURE };
  }
}

function getAllPrices(products) {
  return (dispatch) => {
    const props = {
      products,
      action: variantsService.getPrices,
      options: { onlyForSale: "false" },
      dispatch,
      request,
      success,
      failure,
    };
    getAllByProductsHandler(props);
  };

  function request() {
    return { type: variantsConstants.GET_ALL_PRICES_REQUEST };
  }
  function success(payload) {
    return { type: variantsConstants.GET_ALL_PRICES_SUCCESS, payload };
  }
  function failure() {
    return { type: variantsConstants.GET_ALL_PRICES_FAILURE };
  }
}
