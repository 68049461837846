import { reportsClicksConstants } from "_constants";
import { reportsClicksService } from "_services";

import { alertActions } from "./";

export const reportsClicksActions = { getAll };

function getAll(params) {
  return (dispatch) => {
    dispatch(request());

    reportsClicksService.getAll(params).then(
      ({ lines }) => dispatch(success(lines)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: reportsClicksConstants.GET_CLICKS_REQUEST };
  }
  function success(payload) {
    return { type: reportsClicksConstants.GET_CLICKS_SUCCESS, payload };
  }
  function failure() {
    return { type: reportsClicksConstants.GET_CLICKS_FAILURE };
  }
}
