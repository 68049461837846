import { useState, useRef, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { offerActions, alertActions } from "_actions";

export default function CreateUpdateOfferModal({show, handleClose, offer, onEditOfferSuccess, group}) {
  const dispatch = useDispatch();
  const offerGroupState = useSelector((state) => state.offerGroup);
  const initValue = {
    id: '',
    name: '',
    url: '',
    groupUid: '',
  };

  const [formData, setFormData] = useState(initValue);
  const [validated, setValidated] = useState(false);
  const formEl = useRef(null);

  const addOffer = () => {
    dispatch(offerActions.addOffer({
      name: formData.name,
      url: formData.url,
      groupUid: formData.groupUid
    }, formData.groupUid)).then(() => {
      dispatch(offerActions.getOffers(formData.groupUid));
      dispatch(alertActions.success('Create offer success'));
      setFormData({
        ...formData, 
        name: initValue.name,
        url: initValue.url
      });
      setValidated(false);
      handleClose();
    }, err => {
      dispatch(alertActions.error(err.message));
      setValidated(false);
    })
  }


  const editOffer = () => {
    dispatch(offerActions.editOffer(formData, formData.id)).then(() => {
      dispatch(offerActions.getOffers(group.id));
      dispatch(alertActions.success('Update group success'));
      setFormData({
        ...formData, 
        name: initValue.name,
        url: initValue.url
      });
      setValidated(false);
      onEditOfferSuccess();
      handleClose();
    }, err => {
      dispatch(alertActions.error(err.message));
      setValidated(false);
    });
  }

  const handleSubmit = (e) => {
    const form = formEl.current;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      offer ? editOffer() : addOffer();
    }
  }

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  }

  useEffect(() => {
    setFormData(offer ? offer : {...initValue, groupUid: group?.id || ''});
  }, [offer]);

  useEffect(() => {
    group && setFormData({...offer, groupUid: group.id});
  }, [group]);

  return (
    <Modal show={show} onHide={handleClose} size="md" centered>
      <Modal.Header closeButton>
        <div>{offer ? `Edit offer ${offer.name}` : `Add new offer`}</div>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} ref={formEl}>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control 
              required
              value={formData.name}
              name="name"
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
              Name is required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>URL</Form.Label>
            <Form.Control 
              value={formData.url}
              name="url"
              onChange={handleChange}
            />
          </Form.Group>
          {
            (group && offer) && (
              <Form.Group>
                <Form.Label>Group</Form.Label>
                <Form.Control
                  size="sm"
                  as="select"
                  name="groupUid"
                  required
                  value={formData.groupUid}
                  onChange={handleChange}
                >
                  <option value="">--</option>
                  {offerGroupState.list.map((group, key) => {
                    return (
                      <option value={group.id} key={key}>
                        {group.name}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Group is required
                </Form.Control.Feedback>
              </Form.Group>
            )
          }
          
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
