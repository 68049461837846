import { Fragment } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import moment from "moment-timezone";

export const initGlobalSearch = {
  trademarkNumber: "",
  type: "",
  fillingDate: moment().format("YYYY-MM-DD"),
  registrationDate: moment().format("YYYY-MM-DD"),
  niceClassification: "",
  trademarkStatus: "",
  basis: "",
  reference: "",
  ownerIdNumber: "",
  ownerName: "",
  representativeIdNumber: "",
  representativeName: "",
};

export default function GlobalTrademarkResultList({ hidden, globalSearchs, onError, onChange }) {
  const handleAddGlobalSearch = () => onChange([...globalSearchs, { ...initGlobalSearch }]);

  const handleDeleteGlobalSearch = (deleteIdx) => onChange(globalSearchs.filter((o, _idx) => deleteIdx !== _idx));

  const handleGlobalSearchChange = ({ target: { name, value } }, idx) =>
    onChange(
      globalSearchs.map((globalSearch, globalSearchIdx) =>
        globalSearchIdx === idx ? { ...globalSearch, [name]: value } : globalSearch
      )
    );

  if (hidden) return null;

  return (
    <>
      <hr />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          <h6 className="font-weight-bold">Global Searchs</h6>
        </Form.Label>
        <Col sm={7}>
          <Button size="sm" onClick={handleAddGlobalSearch}>
            Add Search
          </Button>
        </Col>
      </Form.Group>

      {globalSearchs.map((globalSearch, idx) => (
        <Fragment key={idx}>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Tradermark number:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="trademarkNumber"
                value={globalSearch.trademarkNumber || ""}
                onChange={(e) => handleGlobalSearchChange(e, idx)}
                onInvalid={() => onError("Tradermark number")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Type:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="type"
                value={globalSearch.type || ""}
                onChange={(e) => handleGlobalSearchChange(e, idx)}
                onInvalid={() => onError("Type")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Filling date:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="date"
                name="fillingDate"
                value={globalSearch.fillingDate || ""}
                onChange={(e) => handleGlobalSearchChange(e, idx)}
                onInvalid={() => onError("Filling date")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Registration date:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="date"
                name="registrationDate"
                value={globalSearch.registrationDate || ""}
                onChange={(e) => handleGlobalSearchChange(e, idx)}
                onInvalid={() => onError("Registration date")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Nice classification:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="niceClassification"
                value={globalSearch.niceClassification || ""}
                onChange={(e) => handleGlobalSearchChange(e, idx)}
                onInvalid={() => onError("Nice classification")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Status:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="trademarkStatus"
                value={globalSearch.trademarkStatus || ""}
                onChange={(e) => handleGlobalSearchChange(e, idx)}
                onInvalid={() => onError("Status")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Basis:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="basis"
                value={globalSearch.basis || ""}
                onChange={(e) => handleGlobalSearchChange(e, idx)}
                onInvalid={() => onError("Basis")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Reference:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="reference"
                value={globalSearch.reference || ""}
                onChange={(e) => handleGlobalSearchChange(e, idx)}
                onInvalid={() => onError("Reference")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Owner ID:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="ownerIdNumber"
                value={globalSearch.ownerIdNumber || ""}
                onChange={(e) => handleGlobalSearchChange(e, idx)}
                onInvalid={() => onError("Owner ID")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Owner name:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="ownerName"
                value={globalSearch.ownerName || ""}
                onChange={(e) => handleGlobalSearchChange(e, idx)}
                onInvalid={() => onError("Owner name")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Representative ID:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="representativeIdNumber"
                value={globalSearch.representativeIdNumber || ""}
                onChange={(e) => handleGlobalSearchChange(e, idx)}
                onInvalid={() => onError("Representative ID")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Representative name:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="representativeName"
                value={globalSearch.representativeName || ""}
                onChange={(e) => handleGlobalSearchChange(e, idx)}
                onInvalid={() => onError("Representative name")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              {globalSearch.rademarkNumber}
            </Form.Label>
            <Col sm={7}>
              <Button size="sm" variant="danger" onClick={() => handleDeleteGlobalSearch(idx)}>
                Remove
              </Button>
            </Col>
          </Form.Group>
        </Fragment>
      ))}
    </>
  );
}
