import { Row, Form } from "react-bootstrap";
import moment from "moment-timezone";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import StatusView from "../Status/View";

export default function ViewVitalRecord({ orderDetails, selectedProduct }) {
  const orderId = orderDetails.order.id;
  const productDetails = orderDetails.products[selectedProduct];
  const dateOfBirth = moment.utc(productDetails.dateOfBirth).format("MM/DD/YYYY");
  const applicantDateOfBirth = moment(productDetails.applicantDateOfBirth).format("MM/DD/YYYY");
  const dateOfMarriage = moment(productDetails.dateOfMarriage).format("MM/DD/YYYY");
  const mothersDateOfBirth = moment(productDetails.mothersDateOfBirth).format("MM/DD/YYYY");
  const fathersDateOfBirth = moment(productDetails.fathersDateOfBirth).format("MM/DD/YYYY");

  return (
    <div className="vital-record form-border">
      <h4 className="m-0 font-weight-bold mb-2">Vital Record</h4>

      <StatusView orderDetails={orderDetails} selectedProduct={selectedProduct} />

      <FormPDF productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">Birth Information of the Person on Record</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          State Of Birth:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.stateOfBirth}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.firstName}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Middle Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.middleName}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Last Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.lastName}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Suffix:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.suffix}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Gender:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.gender}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Has Name Changed Through Marriage?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.hasChangedNameThroughMarriage ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>
      {!productDetails.hasChangedNameByCourtOrder && productDetails.hasChangedNameThroughMarriage ? (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              New Middle Name:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.newMiddleName}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              New Last Name:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.newLastName}
            </Form.Label>
          </Form.Group>
        </>
      ) : null}
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Has Name Changed Through Court Order?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.hasChangedNameByCourtOrder ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>
      {productDetails.hasChangedNameByCourtOrder ? (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              New First Name:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.newFirstName}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              New Middle Name:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.newMiddleName}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              New Last Name:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.newLastName}
            </Form.Label>
          </Form.Group>
        </>
      ) : null}
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Date Of Birth:
        </Form.Label>
        <Form.Label column sm={7}>
          {dateOfBirth}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          City Of Birth:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.cityOfBirth}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          County Of Birth:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.countyOfBirth}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Name Of Hosiptal OR Street Address of Birth:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.nameOfHospitalOrStreetAddressOfBirth}
        </Form.Label>
      </Form.Group>
      {productDetails.stateOfBirth === "IA" ||
      productDetails.stateOfBirth === "MN" ||
      productDetails.stateOfBirth === "VT" ? (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Applicant Date of Birth:
          </Form.Label>
          <Form.Label column sm={7}>
            {applicantDateOfBirth}
          </Form.Label>
        </Form.Group>
      ) : null}
      {productDetails.stateOfBirth === "MS" ||
      productDetails.stateOfBirth === "GA" ||
      productDetails.stateOfBirth === "KS" ? (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Race:
          </Form.Label>
          <Form.Label column sm={7}>
            {productDetails.race}
          </Form.Label>
        </Form.Group>
      ) : null}
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Still Living?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.stillLiving ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Adopted?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.adopted ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>
      {productDetails.adopted ? (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Original First Name:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.originalFirstNameIfAdopted}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Original Middle Name:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.originalMiddleNameIfAdopted}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Original Last Name:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.originalFirstNameIfAdopted}
            </Form.Label>
          </Form.Group>
          {productDetails.stateOfBirth === "MI" || productDetails.stateOfBirth === "ND" ? (
            <>
              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Adopted Mother's First Name:
                </Form.Label>
                <Form.Label column sm={7}>
                  {productDetails.adoptedMothersFirstName}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Adopted Mother's Middle Name:
                </Form.Label>
                <Form.Label column sm={7}>
                  {productDetails.adoptedMothersMiddleName}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Adopted Mother's Last Name:
                </Form.Label>
                <Form.Label column sm={7}>
                  {productDetails.adoptedMothersLastName}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Adopted Father's First Name:
                </Form.Label>
                <Form.Label column sm={7}>
                  {productDetails.adoptedFathersFirstName}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Adopted Father's Middle Name:
                </Form.Label>
                <Form.Label column sm={7}>
                  {productDetails.adoptedFathersMiddleName}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Adopted Father's Last Name:
                </Form.Label>
                <Form.Label column sm={7}>
                  {productDetails.adoptedFathersLastName}
                </Form.Label>
              </Form.Group>
            </>
          ) : null}
        </>
      ) : null}

      {productDetails.stateOfBirth === "KS" ? (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Record before Adoption?
          </Form.Label>
          <Form.Label column sm={7}>
            {productDetails.recordBeforeAdoption ? "Yes" : "No"}
          </Form.Label>
        </Form.Group>
      ) : null}

      <hr />
      <h6 className="font-weight-bold">Information about the parents of the person on record</h6>

      {productDetails.stateOfBirth === "IA" ? (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Were Parents Married At Time of Birth:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.wereParentsMarriedAtTimeOfBirth ? "Yes" : "No"}
            </Form.Label>
          </Form.Group>
        </>
      ) : null}

      {productDetails.stateOfBirth === "MI" ? (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Place Of Marriage:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.placeOfMarriage}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Date Of Marriage:
            </Form.Label>
            <Form.Label column sm={7}>
              {dateOfMarriage}
            </Form.Label>
          </Form.Group>
        </>
      ) : productDetails.stateOfBirth === "KS" ? (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Mother's State or Country Of Birth:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.mothersStateOrCountryOfBirth}
            </Form.Label>
          </Form.Group>
        </>
      ) : productDetails.stateOfBirth === "NC" || productDetails.stateOfBirth === "SC" ? (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Were Parents Married At Time of Birth:
          </Form.Label>
          <Form.Label column sm={7}>
            {productDetails.wereParentsMarriedAtTimeOfBirth ? "Yes" : "No"}
          </Form.Label>
        </Form.Group>
      ) : null}

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Mother's First Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.mothersFirstName}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Mother's Middle Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.mothersMiddleName}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Mother's Last Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.mothersLastName}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Mother's Maiden Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.mothersMaidenName}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Suffix:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.mothersSuffix}
        </Form.Label>
      </Form.Group>
      {productDetails.stateOfBirth === "SC" ? (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Mother's State or Country Of Birth:
          </Form.Label>
          <Form.Label column sm={7}>
            {productDetails.mothersStateOrCountryOfBirth}
          </Form.Label>
        </Form.Group>
      ) : null}
      {productDetails.stateOfBirth === "AZ" ? (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Mother's Date Of Birth:
            </Form.Label>
            <Form.Label column sm={7}>
              {mothersDateOfBirth}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Mother's State or Country Of Birth:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.mothersStateOrCountryOfBirth}
            </Form.Label>
          </Form.Group>
        </>
      ) : productDetails.stateOfBirth === "UT" ? (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Mother's Date Of Birth:
            </Form.Label>
            <Form.Label column sm={7}>
              {mothersDateOfBirth}
            </Form.Label>
          </Form.Group>
        </>
      ) : null}
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Father unknown or not listed on birth certificate?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.fatherIsUnknown ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>
      {!productDetails.fatherIsUnknown ? (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Father's First Name:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.fathersFirstName}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Father's Middle Name:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.fathersMiddleName}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Father's Last Name:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.fathersLastName}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Father's Suffix:
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.fathersSuffix}
            </Form.Label>
          </Form.Group>
          {productDetails.stateOfBirth === "AZ" ? (
            <>
              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Father's State or Country Of Birth:
                </Form.Label>
                <Form.Label column sm={7}>
                  {productDetails.fathersStateOrCountryOfBirth}
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Father's Date Of Birth:
                </Form.Label>
                <Form.Label column sm={7}>
                  {fathersDateOfBirth}
                </Form.Label>
              </Form.Group>
            </>
          ) : productDetails.stateOfBirth === "SC" ? (
            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Father's State or Country Of Birth:
              </Form.Label>
              <Form.Label column sm={7}>
                {productDetails.fathersStateOrCountryOfBirth}
              </Form.Label>
            </Form.Group>
          ) : productDetails.stateOfBirth === "UT" ? (
            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Father's Date Of Birth:
              </Form.Label>
              <Form.Label column sm={7}>
                {fathersDateOfBirth}
              </Form.Label>
            </Form.Group>
          ) : productDetails.stateOfBirth === "KS" ? (
            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Father's State or Country Of Birth:
              </Form.Label>
              <Form.Label column sm={7}>
                {productDetails.fathersStateOrCountryOfBirth}
              </Form.Label>
            </Form.Group>
          ) : null}
        </>
      ) : null}

      <hr />
      <h6 className="font-weight-bold">Relationship to the person on record</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Relationship to person on certificate:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.relationship}
        </Form.Label>
      </Form.Group>
      {productDetails.relationship === "other" ? (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Relationship:
          </Form.Label>
          <Form.Label column sm={7}>
            {productDetails.customRelationship}
          </Form.Label>
        </Form.Group>
      ) : null}
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Reason for Applying:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.reasonOfApplying}
        </Form.Label>
      </Form.Group>
      {productDetails.reasonOfApplying === "other" ? (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Reason:
          </Form.Label>
          <Form.Label column sm={7}>
            {productDetails.customReason}
          </Form.Label>
        </Form.Group>
      ) : null}
    </div>
  );
}
