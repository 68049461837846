import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import InputMask from "react-input-mask";

import FormContainer from "components/OrderDetails/CreateOrder/FormComponents/FormContainer";
import DateOfBirth from "components/OrderDetails/ProductDetails/DateOfBirth/Edit";
import ExtraLabel from "components/OrderDetails/CreateOrder/FormComponents/ExtraLabel";

const IncBoiForm = (props) => {
  const { orderDetails, productDetails, onChange, onError } = props;

  const handleProductChange = ({ target: { name, value } }) => {
    const newObj = { ...productDetails };

    onChange({ ...newObj, [name]: ["ssn"].includes(name) && value === "" ? null : value });
  };

  function handleAddError(error) {
    onError(error);
  }

  return (
    <FormContainer>
      <DateOfBirth
        productDetails={productDetails}
        renderExtraLabel={() => {
          return <ExtraLabel $optional />;
        }}
        onChange={handleProductChange}
      />

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Social Security Number: <ExtraLabel $optional />
        </Form.Label>
        <Col sm={7}>
          <InputMask
            className="form-control form-control-sm"
            mask="999-99-9999"
            maskChar=" "
            name="ssn"
            placeholder="999-99-9999"
            pattern="^\d{3}-\d{2}-\d{4}$"
            value={productDetails?.ssn || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Social Security Number")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Driver's License or ID Number: <ExtraLabel $optional />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="driverLicenceOrID"
            value={productDetails?.driverLicenceOrID || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Social Security Number")}
          />
        </Col>
      </Form.Group>
    </FormContainer>
  );
};

export default IncBoiForm;
