import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '_actions';
import avatar from 'assets/img/faces/face-0.jpg';

function UserProfile(props) {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const [user, setUser] = useState(null);
  const [password, setPassword] = useState('');
  const [conf_password, setConf_password] = useState('');

  const changeuser = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const updateprofile = (e) => {
    e.preventDefault();

    if (password !== conf_password) return false;
    let newuser;
    if (password != '') {
      newuser = {
        ...user,
        password: password,
      };
    } else {
      newuser = {
        ...user,
      };
    }
    dispatch(userActions.updateprofile(newuser));
  };

  useEffect(() => {
    dispatch(userActions.getme());
  }, []);

  useEffect(() => {
    setUser(users.items);
  }, [users]);

  if (!user) {
    return <div></div>;
  }

  return (
    <div className="content mt-5">
      <Container fluid>
        <Row>
          <Col md={12}>
            <div className="card card-user">
              <div className="image">
                <img
                  src="https://ununsplash.imgix.net/photo-1431578500526-4d9613015464?fit=crop&fm=jpg&h=300&q=75&w=400"
                  alt="..."
                />
              </div>
              <div className="content">
                <div className="author">
                  <img className="avatar border-gray" src={avatar} alt="..." />
                  <Form onSubmit={updateprofile} className="profile-form">
                    <Row className="justify-content-md-center">
                      <Col sm="5">
                        <Form.Group as={Row}>
                          <Form.Label column sm={3}>
                            Name:
                          </Form.Label>
                          <Col sm={9}>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Name"
                              value={user.name}
                              name="name"
                              onChange={changeuser}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Form.Label column sm={3}>
                            Username:
                          </Form.Label>
                          <Col sm={9}>
                            <Form.Control
                              required
                              type="text"
                              name="username"
                              placeholder="Username"
                              value={user.username}
                              onChange={changeuser}
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                          <Form.Label column sm={3}>
                            Password:
                          </Form.Label>
                          <Col sm={9}>
                            <Form.Control
                              type="password"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <p
                              className="text-left text-danger m-0"
                              hidden={password === conf_password}
                            >
                              Password don't match
                            </p>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                          <Form.Label column sm={3}>
                            Confirm Password:
                          </Form.Label>
                          <Col sm={9}>
                            <Form.Control
                              type="password"
                              placeholder="Confirm Password"
                              value={conf_password}
                              onChange={(e) => setConf_password(e.target.value)}
                            />
                          </Col>
                        </Form.Group>

                        <Button size="sm" type="submit" className="pull-right">
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default UserProfile;
