import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Tab, Container, Row, Col, Form, Tabs, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import classnames from "classnames";
import clsx from "clsx";

import {
  getSubscriptionStatus,
  currencyFormatter,
  combineFromCategories,
  phoneFormatter,
  checkIsTrademarkMonitoring,
  checkIsLLCIncorporation,
  checkIsStateIncorporationFee,
} from "_helpers";

import OrderProductSelect from "components/OrderDetails/OrderProductSelect";
import RelatedOrders from "components/OrderDetails/RelatedOrders";
// EIN
import FormPartnership from "components/OrderDetails/ProductDetails/EINForms/FormPartnership";
import FormCCorp from "components/OrderDetails/ProductDetails/EINForms/FormCCorp";
import FormSoleProp from "components/OrderDetails/ProductDetails/EINForms/FormSoleProp";
import FormEstate from "components/OrderDetails/ProductDetails/EINForms/FormEstate";
import FormTrust from "components/OrderDetails/ProductDetails/EINForms/FormTrust";
import FormLLC from "components/OrderDetails/ProductDetails/EINForms/FormLLC";
import FormPSC from "components/OrderDetails/ProductDetails/EINForms/FormPSC";
import FormSCorp from "components/OrderDetails/ProductDetails/EINForms/FormSCorp";
import FormChurch from "components/OrderDetails/ProductDetails/EINForms/FormChurch";
import FormProfit from "components/OrderDetails/ProductDetails/EINForms/FormProfit";
// Incorporation
import FormLLCInc from "components/OrderDetails/ProductDetails/Incorporation/FormLLCInc";
import FormResidentAgent from "components/OrderDetails/ProductDetails/Incorporation/FormResidentAgent";
import StateIncFee from "components/OrderDetails/ProductDetails/Incorporation/StateIncFee";
import FormAnnualComplianceReport from "components/OrderDetails/ProductDetails/Incorporation/FormAnnualComplianceReport";
import FormRegisteredAgent from "components/OrderDetails/ProductDetails/Incorporation/FormRegisteredAgent";
import FormBusinessLicenses from "components/OrderDetails/ProductDetails/Incorporation/FormBusinessLicenses";
import FormIncRushProcessing from "components/OrderDetails/ProductDetails/Incorporation/FormIncRushProcessing";
import FormIncorpifyAmendment from "components/OrderDetails/ProductDetails/Incorporation/FormIncorpifyAmendment";
import FormBoi from "components/OrderDetails/ProductDetails/Incorporation/FormBoi";
import FormCreditCardProcessing from "components/OrderDetails/ProductDetails/Incorporation/FormCreditCardProcessing";
import FormAnnualReport from "components/OrderDetails/ProductDetails/Incorporation/FormAnnualReport";
import FormDBA from "components/OrderDetails/ProductDetails/Incorporation/FormDBA";
// Vital
import VitalRecord from "components/OrderDetails/ProductDetails/VitalRecord/ViewVitalRecord";
// Trademark
import FormTrademarkRegister from "components/OrderDetails/ProductDetails/TrademarkForms/FormTrademarkRegister";
import FormTrademarkSearch from "components/OrderDetails/ProductDetails/TrademarkForms/FormTrademarkSearch";
import FormTrademarkMonitiring from "components/OrderDetails/ProductDetails/TrademarkForms/FormTrademarkMonitoring";
import FormTrademarkStatementOfUse from "components/OrderDetails/ProductDetails/TrademarkForms/FormTrademarkStatementOfUse";
import FormTrademarkOfficeActionResponse from "components/OrderDetails/ProductDetails/TrademarkForms/FormTrademarkOfficeActionResponse";
import FormTrademarkRenewal from "components/OrderDetails/ProductDetails/TrademarkForms/FormTrademarkRenewal";
import FormTrademarkRushProcessing from "components/OrderDetails/ProductDetails/TrademarkForms/FormTrademarkRushProcessing";
import FormTrademarkExtension from "components/OrderDetails/ProductDetails/TrademarkForms/FormTrademarkExtension";
import FormTrademarkRevivalFromAbandonment from "components/OrderDetails/ProductDetails/TrademarkForms/FormTrademarkRevivalFromAbandonment";
import FormTrademarkUSPTOFilingFee from "components/OrderDetails/ProductDetails/TrademarkForms/FormTrademarkUSPTOFilingFee";
import FormTrademarkSummary from "components/OrderDetails/ProductDetails/TrademarkForms/FormTrademarkSummary";
import FormTrademarkKeepAlive from "components/OrderDetails/ProductDetails/TrademarkForms/FormTrademarkKeepAlive";
import FormTrademarkCertifiedCopy from "components/OrderDetails/ProductDetails/TrademarkForms/FormTrademarkCertifiedCopy";
import FormTrademarkAmendment from "components/OrderDetails/ProductDetails/TrademarkForms/FormTrademarkAmendment";
import FormTrademarkPrivacyPackage from "components/OrderDetails/ProductDetails/TrademarkForms/FormTrademarkPrivacyPackage";
import FormTrademarkPrivateContactInfo from "components/OrderDetails/ProductDetails/TrademarkForms/FormTrademarkPrivateContactInfo";
import FormTrademarkAssessment from "components/OrderDetails/ProductDetails/TrademarkForms/FormTrademarkAssessment";
import FormTrademarkLogoDesignService from "components/OrderDetails/ProductDetails/TrademarkForms/FormTrademarkLogoDesignService";

// Amendments
import FormIncorpifyRaAmendment from "components/OrderDetails/ProductDetails/Incorporation/FormIncorpifyRaAmendment";
import FormIncorpifyManagerAmendment from "components/OrderDetails/ProductDetails/Incorporation/FormIncorpifyManagerAmendment";
import FormIncorpifyBusinessAddressAmendment from "components/OrderDetails/ProductDetails/Incorporation/FormIncorpifyBusinessAddressAmendment";
import FormIncorpifyOtherAmendment from "components/OrderDetails/ProductDetails/Incorporation/FormIncorpifyOtherAmendment";
import FormIncorpifyBusinessNameAmendment from "components/OrderDetails/ProductDetails/Incorporation/FormIncorpifyBusinessNameAmendment";

import FormDefault from "components/OrderDetails/ProductDetails/FormDefault";

import Emails from "components/OrderDetails/Emails";
import FilingFees from "components/OrderDetails/FilingFees";
import PaymentRefunds from "components/OrderDetails/PaymentRefunds";
import OrderActions from "components/OrderDetails/Actions";
import Notes from "components/OrderDetails/Notes";
import Timeline from "components/OrderDetails/Timeline";
import Zendesk from "components/OrderDetails/Zendesk";
import PasswordReset from "components/OrderDetails/PasswordReset";
import Monitoring from "components/OrderDetails/Monitoring";
import MailUpload from "components/OrderDetails/MailUpload";
import Billing from "components/OrderDetails/Billing";
import AmendmentsLLC from "components/OrderDetails/AmendmentsLLC";
import CustomerbActions from "components/OrderDetails/CustomerActions";
import LogoDesignRevisions from "components/OrderDetails/LogoDesignRevisions";

import { state_array, TRADEMARK_REGISTER_CODES, TAB_CODES } from "variables/Variables";

function ViewOrderDetail({
  activeTab,
  orderDetails,
  availableProcessingStatus,
  selectedProduct,
  uncollapsedProducts,
  onSelectedProduct,
  onChange,
  onUpdateOrder,
  onDeleteOrder,
  onViewChange,
  onTabChange,
  onUncollapseProduct,
}) {
  if (!orderDetails) return null;
  const productDetails = orderDetails?.products[selectedProduct];

  const { productsProcessingStatuses, productsProcessingStatusesOpt, ...variants } = useSelector(
    ({ variants }) => variants
  );
  const { subscriptionDetails } = useSelector(({ customers }) => customers);

  const isTrademark = orderDetails?.products?.some(
    ({ code }) =>
      code?.category === "TRADEMARKS" ||
      code?.code === "INC_RegisterTrademark" ||
      code?.code === "INC_States_RegisterTrademark"
  );

  const trademarkMonitoringProductIdx = orderDetails?.products?.findIndex(({ code }) =>
    checkIsTrademarkMonitoring(code?.code)
  );

  // const isTrademarkMonitoring = trademarkMonitoringProductIdx !== -1;
  const isTrademarkMonitoring = (code) => checkIsTrademarkMonitoring(code);

  const isPrivacyProduct = (code) =>
    ["TRADEMARK_Private_Contact_Information", "TRADEMARK_Privacy_Package"].includes(code);

  const subscriptionStatus =
    productDetails?.code?.subscription && getSubscriptionStatus(orderDetails, selectedProduct, subscriptionDetails);

  const isTrademarkRegistration = (code) => TRADEMARK_REGISTER_CODES.includes(code);

  const isLlcIncorporation = (code) => checkIsLLCIncorporation(code);

  const isLegalTeamAssesment = (code) => ["TRADEMARK_Legal_Team_Trademark_Assessment"].includes(code);

  const isLogoDesingService = (code) => ["TRADEMARK_Logo_Design_Service"].includes(code);

  const monitoringStatus = getSubscriptionStatus(orderDetails, trademarkMonitoringProductIdx, subscriptionDetails);

  const orderStatusOpt = combineFromCategories(variants.allOrderStatuses);
  const processingErrorsOpt = combineFromCategories(variants.allProcessingErrors);
  const processingStatusesOpt = combineFromCategories(variants.allProcessingStatuses);

  const accountManager = useSelector(({ users: { user } }) => user);

  const handleUpdateOrderStatus = (e) => {
    if (e) e.preventDefault();
    onUpdateOrder(true);
  };

  const productsErrors = orderDetails.products
    .filter(({ processingResult: { status } }) => status === "Error")
    .map(({ processingResult: { processingError } }) => processingErrorsOpt[processingError])
    .join(", ");

  const statusIndicatorClasses = (status) =>
    clsx("tab-status-indicator", {
      ["bg-success"]: status === "Active",
      ["bg-warning"]: status === "Declined",
      ["bg-danger"]: status === "Cancelled",
      ["bg-default"]: !["Active", "Declined", "Cancelled"].includes(status),
    });

  let customerName = "";

  if (orderDetails) {
    switch (productDetails?.code.code) {
      case "EIN_Partnership":
      case "EIN_CCorp":
      case "EIN_LLC":
      case "INC_EIN":
      case "INC_States_EIN":
      case "EIN_SCorp":
      case "EIN_Trust":
      case "EIN_PSC":
      case "EIN_NonProfit":
      case "EIN_Church":
        customerName = productDetails?.legalName;
        break;

      case "EIN_SoleProp":
        customerName = `${productDetails?.owner?.firstName} ${productDetails?.owner?.lastName}`;
        break;

      case "EIN_Estate":
        customerName = `${productDetails?.deceased.firstName} ${productDetails?.deceased.lastName}`;
        break;

      case "INC_RegisterTrademark":
      case "INC_States_RegisterTrademark":
      case "TRADEMARK_RegisterTrademark":
      case "TRADEMARK_RegisterTrademarkV2":
      case "TRADEMARK_RegisterTrademark_Basic":
      case "TRADEMARK_RegisterTrademark_Standard":
      case "TRADEMARK_RegisterTrademark_Platinum":
        customerName = `${productDetails?.individual?.firstName} ${productDetails?.individual?.lastName}`;
        break;

      default:
        break;
    }
  }

  const infoTabRenderer = () => {
    let tabInfo = null;

    switch (productDetails.code.code) {
      case "VITAL_NewBirthCertificate": {
        tabInfo = {
          eventKey: "vital_record",
          title: TAB_CODES.vitalRecord,
          c: <VitalRecord orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }
      case "INC_RegisteredAgent":
      case "INC_States_RegisteredAgent": {
        tabInfo = {
          eventKey: "llc_info",
          title: TAB_CODES.llcIncInfo,
          c: <FormRegisteredAgent orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }
      case "EIN_ResidentAgent":
      case "INC_ResidentAgent":
      case "INC_States_ResidentAgent": {
        tabInfo = {
          eventKey: "llc_info",
          title: TAB_CODES.llcIncInfo,
          c: <FormResidentAgent orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }
      case "INC_BOI":
      case "INC_States_BOI":
      case "EIN_BOI": {
        tabInfo = {
          eventKey: "llc_info",
          title: TAB_CODES.boiInfo,
          c: <FormBoi orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }
      case "INC_AnnualReport":
      case "INC_States_AnnualReport": {
        tabInfo = {
          eventKey: "llc_info",
          title: TAB_CODES.llcIncInfo,
          c: <FormAnnualReport orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }
      case "EIN_AnnualComplianceReport":
      case "EIN_AnnualComplianceReport_Pre":
      case "INC_AnnualComplianceReport":
      case "INC_States_AnnualComplianceReport": {
        tabInfo = {
          eventKey: "llc_info",
          title: TAB_CODES.llcIncInfo,
          c: <FormAnnualComplianceReport orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }
      case "EIN_LLCIncorporation":
      case "INC_LLCIncorporation":
      case "INC_States_LLCIncorporation":
      case "INC_LLCIncorporationV2":
      case "INC_States_LLCIncorporationV2": {
        tabInfo = {
          eventKey: "llc_info",
          title: TAB_CODES.llcIncInfo,
          c: <FormLLCInc orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }
      case "INC_BusinessLicenses":
      case "INC_States_BusinessLicenses": {
        tabInfo = {
          eventKey: "llc_info",
          title: TAB_CODES.llcIncInfo,
          c: <FormBusinessLicenses orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }
      case "INC_Rush_Processing":
      case "INC_States_Rush_Processing": {
        tabInfo = {
          eventKey: "llc_info",
          title: TAB_CODES.llcIncInfo,
          c: <FormIncRushProcessing orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "EIN_SoleProp": {
        tabInfo = {
          eventKey: "ein_info",
          title: TAB_CODES.einInfo,
          c: <FormSoleProp orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "INC_EIN":
      case "INC_States_EIN":
      case "EIN_LLC": {
        tabInfo = {
          eventKey: "ein_info",
          title: TAB_CODES.einInfo,
          c: <FormLLC orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "EIN_Trust": {
        tabInfo = {
          eventKey: "ein_info",
          title: TAB_CODES.einInfo,
          c: <FormTrust orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "EIN_Estate": {
        tabInfo = {
          eventKey: "ein_info",
          title: TAB_CODES.einInfo,
          c: <FormEstate orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "EIN_NonProfit": {
        tabInfo = {
          eventKey: "ein_info",
          title: TAB_CODES.einInfo,
          c: <FormProfit orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "EIN_Church": {
        tabInfo = {
          eventKey: "ein_info",
          title: TAB_CODES.einInfo,
          c: <FormChurch orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "EIN_Partnership": {
        tabInfo = {
          eventKey: "ein_info",
          title: TAB_CODES.einInfo,
          c: <FormPartnership orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "EIN_PSC": {
        tabInfo = {
          eventKey: "ein_info",
          title: TAB_CODES.einInfo,
          c: <FormPSC orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "EIN_CCorp": {
        tabInfo = {
          eventKey: "ein_info",
          title: TAB_CODES.einInfo,
          c: <FormCCorp orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "EIN_SCorp": {
        tabInfo = {
          eventKey: "ein_info",
          title: TAB_CODES.einInfo,
          c: <FormSCorp orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "INC_RegisterTrademark":
      case "INC_States_RegisterTrademark":
      case "TRADEMARK_RegisterTrademark":
      case "TRADEMARK_RegisterTrademarkV2":
      case "TRADEMARK_RegisterTrademark_Basic":
      case "TRADEMARK_RegisterTrademark_Standard":
      case "TRADEMARK_RegisterTrademark_Platinum": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: (
            <FormTrademarkRegister
              orderDetails={orderDetails}
              availableProcessingStatus={availableProcessingStatus}
              selectedProduct={selectedProduct}
              processingErrorsOpt={processingErrorsOpt}
              productsProcessingStatusesOpt={productsProcessingStatusesOpt}
              onUpdateOrderStatus={handleUpdateOrderStatus}
              onChange={(e) => onChange(e)}
            />
          ),
        };
        break;
      }

      case "INC_Trademark_Search_Basic":
      case "INC_States_Trademark_Search_Basic":
      case "INC_Trademark_Search_Global":
      case "INC_Trademark_Search_Federal_State":
      case "INC_Trademark_Search_Common_Law":
      case "TRADEMARK_Trademark_Search_Basic":
      case "TRADEMARK_Trademark_Search_Global":
      case "TRADEMARK_Trademark_Search_Federal_State":
      case "TRADEMARK_Trademark_Search_Common_Law": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkSearch orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "INC_Trademark_Monitoring":
      case "INC_States_Trademark_Monitoring":
      case "TRADEMARK_Trademark_Monitoring": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkMonitiring orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "TRADEMARK_Trademark_Statement_Of_Use": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkStatementOfUse orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "TRADEMARK_Trademark_Office_Action_Response": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkOfficeActionResponse orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "TRADEMARK_Trademark_Renewal": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkRenewal orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "TRADEMARK_Trademark_Rush_Processing": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkRushProcessing orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "TRADEMARK_Trademark_Extension": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkExtension orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "TRADEMARK_Trademark_Revival_From_Abandonment": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkRevivalFromAbandonment orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "INC_USPTOFilingFee":
      case "INC_States_USPTOFilingFee":
      case "TRADEMARK_USPTOFilingFee": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkUSPTOFilingFee orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "TRADEMARK_Trademark_Keep_Trademark_Alive": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkKeepAlive orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "TRADEMARK_Trademark_Certified_Copy": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkCertifiedCopy orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "TRADEMARK_Amendment_TrademarkMinor":
      case "TRADEMARK_Amendment": {
        tabInfo = {
          eventKey: "amendment_info",
          title: TAB_CODES.amendmentInfo,
          c: <FormTrademarkAmendment orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "INC_Amendment_RegisteredAgentChange":
      case "INC_States_Amendment_RegisteredAgentChange": {
        tabInfo = {
          eventKey: "amendment_info",
          title: TAB_CODES.amendmentInfo,
          c: <FormIncorpifyRaAmendment orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "INC_Amendment_ManagerChange":
      case "INC_States_Amendment_ManagerChange": {
        tabInfo = {
          eventKey: "amendment_info",
          title: TAB_CODES.amendmentInfo,
          c: <FormIncorpifyManagerAmendment orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "INC_Amendment_BusinessAddressChange":
      case "INC_States_Amendment_BusinessAddressChange": {
        tabInfo = {
          eventKey: "amendment_info",
          title: TAB_CODES.amendmentInfo,
          c: <FormIncorpifyBusinessAddressAmendment orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "INC_Amendment_AllOtherAmendments":
      case "INC_States_Amendment_AllOtherAmendments": {
        tabInfo = {
          eventKey: "amendment_info",
          title: TAB_CODES.amendmentInfo,
          c: <FormIncorpifyOtherAmendment orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "INC_Amendment_BusinessNameChange":
      case "INC_States_Amendment_BusinessNameChange": {
        tabInfo = {
          eventKey: "amendment_info",
          title: TAB_CODES.amendmentInfo,
          c: <FormIncorpifyBusinessNameAmendment orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "INC_Credit_Card_Processing":
      case "INC_States_Credit_Card_Processing": {
        tabInfo = {
          eventKey: "default_info",
          title: TAB_CODES.defaultInfo,
          c: <FormCreditCardProcessing orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "TRADEMARK_Private_Contact_Information": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkPrivateContactInfo orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "TRADEMARK_Privacy_Package": {
        tabInfo = {
          eventKey: "trademark_info",
          title: TAB_CODES.trademarkInfo,
          c: <FormTrademarkPrivacyPackage orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "TRADEMARK_Logo_Design_Service": {
        tabInfo = {
          eventKey: "logo_info",
          title: TAB_CODES.logoInfo,
          c: <FormTrademarkLogoDesignService orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      case "EIN_DBA":
      case "INC_DBA":
      case "INC_States_DBA": {
        tabInfo = {
          eventKey: "default_info",
          title: TAB_CODES.defaultInfo,
          c: <FormDBA orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
      }

      default:
        tabInfo = {
          eventKey: "default_info",
          title: TAB_CODES.defaultInfo,
          c: <FormDefault orderDetails={orderDetails} selectedProduct={selectedProduct} />,
        };
        break;
    }

    return tabInfo && <Tab {...tabInfo}>{tabInfo.c}</Tab>;
  };

  const productInfoRender = (product) => {
    return (
      <Fragment key={product.id}>
        <Form.Group as={Row}>
          <Form.Label column sm="12" onClick={() => onUncollapseProduct(product.id)} role="button">
            <i className={`fa fa-arrow-${uncollapsedProducts.includes(product.id) ? "up" : "down"}`} />
            &nbsp;
            {product.customerName}
          </Form.Label>
          {uncollapsedProducts.includes(product.id) && (
            <Col className="pl-4">
              <hr />
              <Form.Group as={Row} className="pl-4">
                <Form.Label column sm="3">
                  Processing Option:
                </Form.Label>
                <Form.Label column sm="9">
                  {product?.processingOption}
                </Form.Label>
              </Form.Group>

              <Form.Group as={Row} className="pl-4">
                <Form.Label column sm="3">
                  Price:
                </Form.Label>
                <Form.Label column sm="9">
                  {currencyFormatter(product?.price)}
                </Form.Label>
              </Form.Group>

              {product?.recurringOrderPrice && (
                <Form.Group as={Row} className="pl-4">
                  <Form.Label column sm="3">
                    Recurring Amount:
                  </Form.Label>
                  <Form.Label column sm="9">
                    {currencyFormatter(product?.recurringAmount || 0)}
                  </Form.Label>
                </Form.Group>
              )}

              {product?.recurringOrderPrice && (
                <Form.Group as={Row} className="pl-4">
                  <Form.Label column sm="3">
                    Recurring Refund:
                  </Form.Label>
                  <Form.Label column sm="9">
                    {currencyFormatter(product?.recurringRefund || 0)}
                  </Form.Label>
                </Form.Group>
              )}

              <Form.Group as={Row} className="pl-4">
                <Form.Label column sm="3">
                  Payment Status:
                </Form.Label>
                <Form.Label column sm="9">
                  {orderStatusOpt[product?.paymentStatus] || "N/A"}
                </Form.Label>
              </Form.Group>

              <Form.Group as={Row} className="pl-4">
                <Form.Label column sm="3">
                  Payment Date:
                </Form.Label>
                <Form.Label column sm="9">
                  {product?.paymentDate ? moment.utc(product?.paymentDate).local().format("MM/DD/yyyy") : "N/A"}
                </Form.Label>
              </Form.Group>

              <Form.Group as={Row} className="pl-4">
                <Form.Label column sm="3">
                  Refund Issues:
                </Form.Label>
                <Form.Label column sm="9">
                  {product.refundAmount > 0 ? currencyFormatter(product?.refundAmount) : "No refund issues"}
                </Form.Label>
              </Form.Group>

              {product.refundAmount > 0 && (
                <Form.Group as={Row} className="pl-4">
                  <Form.Label column sm="3">
                    Refund Date:
                  </Form.Label>
                  <Form.Label column sm="9">
                    {!!product.refundDate ? moment.utc(product.refundDate).local().format("MM/DD/yyyy") : ""}
                  </Form.Label>
                </Form.Group>
              )}

              <Form.Group as={Row} className="pl-4">
                <Form.Label column sm="3">
                  Merchant Descriptor:
                </Form.Label>
                <Form.Label column sm="9">
                  {product.merchantDescriptor || "N/A"}
                </Form.Label>
              </Form.Group>
              <hr />
            </Col>
          )}
        </Form.Group>
      </Fragment>
    );
  };

  return (
    <Container fluid className="py-5 orderinfo">
      <Form>
        <Row className="my-3">
          <Col md={4}>
            <div className="">
              <h4>Order Information:</h4>
              {orderDetails && (
                <div>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      CRM Order ID:
                    </Form.Label>
                    <Form.Label column sm="9">
                      {orderDetails.order.crmOrderId}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Order Created:
                    </Form.Label>
                    <Form.Label column sm="9" title={orderDetails?.order?.created}>
                      {moment.utc(orderDetails?.order?.created).local().format("MM/DD/yyyy")}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      First Name:
                    </Form.Label>
                    <Form.Label column sm="9">
                      {orderDetails.customer.firstName}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Last Name:
                    </Form.Label>
                    <Form.Label column sm="9">
                      {orderDetails.customer.lastName}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      E-mail:
                    </Form.Label>
                    <Form.Label column sm="9">
                      {orderDetails.customer.email}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Product Code:
                    </Form.Label>
                    <Form.Label column sm="9">
                      {orderDetails.products.map((p, idx) => (
                        <p key={`code-${p.code.code}-${idx}`} title={p.code.code}>
                          {p.code.code}
                          <br />
                          <span style={{ color: "gray" }}>({processingStatusesOpt[p.processingResult.status]})</span>
                        </p>
                      ))}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Product Alternate Names:
                    </Form.Label>
                    <Form.Label column sm="9">
                      {isLlcIncorporation(productDetails?.code?.code)
                        ? productDetails.altCompanyName
                        : productDetails?.alternateLegalNames?.join(",") || ""}
                    </Form.Label>
                  </Form.Group>
                  {!isLlcIncorporation(productDetails?.code?.code) && (
                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        Entity Name:
                      </Form.Label>
                      <Form.Label column sm="9">
                        {customerName}
                      </Form.Label>
                    </Form.Group>
                  )}
                </div>
              )}

              <div className="mt-2">
                {orderDetails?.products.length ? (
                  <Button className="btn btn-sm btn-primary" onClick={onViewChange}>
                    <i className="fa fa-pencil" /> Edit
                  </Button>
                ) : (
                  <Button className="btn btn-sm btn-danger" onClick={() => onDeleteOrder(true)}>
                    <i className="fa fa-trash" /> Delete order without products
                  </Button>
                )}
              </div>
            </div>
          </Col>

          <Col md={8}>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                <h4>Other Details:</h4>
              </Form.Label>
              <OrderProductSelect
                selectedProduct={selectedProduct}
                orderDetails={orderDetails}
                onChange={onSelectedProduct}
              />
            </Form.Group>

            {orderDetails && productDetails && (
              <Tabs defaultActiveKey="home" transition={false} activeKey={activeTab} onSelect={onTabChange}>
                <Tab eventKey="home" title="Order">
                  <div className="order-info form-border">
                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        Order Id:
                      </Form.Label>
                      <Form.Label column sm="9">
                        {orderDetails.order.id}
                      </Form.Label>
                    </Form.Group>

                    {orderDetails?.order?.parentOrderUid && (
                      <Form.Group as={Row}>
                        <Form.Label column sm="3">
                          Parent Order Id:
                        </Form.Label>
                        <Form.Label column sm="9">
                          <Link
                            to={"/admin/orders/" + orderDetails.order.parentOrderUid}
                            style={{ textDecoration: "underline" }}
                          >
                            {orderDetails.order.parentOrderUid}
                          </Link>
                        </Form.Label>
                      </Form.Group>
                    )}

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        Order Created:
                      </Form.Label>
                      <Form.Label column sm="9" title={orderDetails?.order?.created}>
                        {moment.utc(orderDetails?.order?.created).local().format("MM/DD/yyyy")}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        Order Updated:
                      </Form.Label>
                      <Form.Label column sm="9" title={orderDetails?.order?.updated}>
                        {moment.utc(orderDetails?.order?.updated).local().format("MM/DD/yyyy")}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        Total Price:
                      </Form.Label>
                      <Form.Label column sm="9">
                        {currencyFormatter(orderDetails.order.total)}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        {productDetails.code.category === "EIN" ? "EIN Error:" : "Processing error:"}
                      </Form.Label>
                      <Form.Label column sm="9">
                        {productsErrors || "—"}
                      </Form.Label>
                    </Form.Group>

                    <br />
                    <h6 className="font-weight-bold">Account Manager Info:</h6>
                    {orderDetails?.order?.accountManagerId ? (
                      <>
                        <Form.Group as={Row}>
                          <Form.Label column sm="3">
                            ID:
                          </Form.Label>
                          <Form.Label column sm="9">
                            {orderDetails?.order?.accountManagerId}
                          </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Form.Label column sm="3">
                            Name:
                          </Form.Label>
                          <Form.Label column sm="9">
                            {accountManager?.name || "—"}
                          </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Form.Label column sm="3">
                            Email:
                          </Form.Label>
                          <Form.Label column sm="9">
                            {accountManager?.email || "—"}
                          </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Form.Label column sm="3">
                            Phone:
                          </Form.Label>
                          <Form.Label column sm="9">
                            {accountManager?.phone || "—"}
                          </Form.Label>
                        </Form.Group>
                      </>
                    ) : (
                      <Form.Group as={Row}>
                        <Form.Label column sm="12">
                          This order does not have a linked account manager
                        </Form.Label>
                      </Form.Group>
                    )}
                    <Form.Group
                      as={Row}
                      className={`mx-auto p-1 text-center border rounded ${classnames({
                        "text-dark border-dark": !orderDetails.order.customerContactRequired,
                        "text-danger border-danger": orderDetails.order.customerContactRequired,
                      })}`}
                    >
                      <Form.Label sm={12} className="m-0 w-100">
                        Customer Contact {!orderDetails.order.customerContactRequired && "Not"} Required
                      </Form.Label>
                    </Form.Group>

                    <br />
                    <h6 className="font-weight-bold">Products Info:</h6>
                    {orderDetails.products.map(productInfoRender)}

                    <RelatedOrders />
                  </div>
                </Tab>
                <Tab eventKey="customer" title="Customer">
                  <div className="order-info form-border">
                    <br />
                    <h6 className="font-weight-bold">Customer INFO</h6>
                    <hr />

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        Id:
                      </Form.Label>
                      <Form.Label column sm="9">
                        {orderDetails.customer.id}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        CRM Customer ID:
                      </Form.Label>
                      <Form.Label column sm="9">
                        {orderDetails.customer.crmcustomerId}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        Konnektive Campaign ID:
                      </Form.Label>
                      <Form.Label column sm="9">
                        {orderDetails.order.campaignId}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        First Name:
                      </Form.Label>
                      <Form.Label column sm="9">
                        {orderDetails.customer.firstName}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        Last Name:
                      </Form.Label>
                      <Form.Label column sm="9">
                        {orderDetails.customer.lastName}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        Username:
                      </Form.Label>
                      <Form.Label column sm="9">
                        {orderDetails.customer.username}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        E-mail:
                      </Form.Label>
                      <Form.Label column sm="9">
                        {orderDetails.customer.email}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        Phone:
                      </Form.Label>
                      <Form.Label column sm="9">
                        {phoneFormatter(orderDetails?.customer?.phone) || "—"}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        Gclid:
                      </Form.Label>
                      <Form.Label column sm="9" style={{ wordBreak: "break-all" }}>
                        {orderDetails.order.clid}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        Source:
                      </Form.Label>
                      <Form.Label column sm="9">
                        {orderDetails.order.source}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        Keyword:
                      </Form.Label>
                      <Form.Label column sm="9">
                        {orderDetails.order.keyword}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        AdAccount:
                      </Form.Label>
                      <Form.Label column sm="9">
                        {orderDetails.order.adAccount}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        First Click Url:
                      </Form.Label>
                      <Form.Label column sm="9" style={{ wordBreak: "break-all" }}>
                        {orderDetails.customer.firstCLickURL}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        Sale Page Url:
                      </Form.Label>
                      <Form.Label column sm="9">
                        {orderDetails.order.salePageURL}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="12">
                        Billing Address:
                      </Form.Label>
                      <Col sm="12">
                        {orderDetails.customer.billingAddress && (
                          <div className="pl-3">
                            <Form.Group as={Row}>
                              <Form.Label column sm="3">
                                Address1:
                              </Form.Label>
                              <Form.Label column sm="9">
                                {orderDetails.customer.billingAddress.address1}
                              </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Form.Label column sm="3">
                                Address2:
                              </Form.Label>
                              <Form.Label column sm="9">
                                {orderDetails.customer.billingAddress.address2}
                              </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Form.Label column sm="3">
                                City:
                              </Form.Label>
                              <Form.Label column sm="9">
                                {orderDetails.customer.billingAddress.city}
                              </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Form.Label column sm="3">
                                County:
                              </Form.Label>
                              <Form.Label column sm="9">
                                {orderDetails.customer.billingAddress.county}
                              </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Form.Label column sm="3">
                                State:
                              </Form.Label>
                              <Form.Label column sm="9">
                                {state_array[orderDetails.customer.billingAddress.state]}
                              </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Form.Label column sm="3">
                                Zip Code:
                              </Form.Label>
                              <Form.Label column sm="9">
                                {orderDetails.customer.billingAddress.zip}
                              </Form.Label>
                            </Form.Group>
                          </div>
                        )}
                      </Col>
                    </Form.Group>

                    {(isTrademark || isLlcIncorporation) && (
                      <>
                        <br />
                        <h6 className="font-weight-bold">Temporary password</h6>
                        <hr />
                        <PasswordReset orderDetails={orderDetails} />
                      </>
                    )}

                    <br />
                    <h6 className="font-weight-bold">Actions</h6>
                    <hr />
                    <CustomerbActions orderDetails={orderDetails} />
                  </div>
                </Tab>

                {infoTabRenderer()}

                {orderDetails.order.productCodes.some((p) => checkIsStateIncorporationFee(p.code)) && (
                  <Tab eventKey="llc_fee" title="State Inc Fee">
                    <StateIncFee orderDetails={orderDetails} selectedProduct={selectedProduct} onChange={onChange} />
                  </Tab>
                )}

                {isLlcIncorporation(productDetails.code.code) && (
                  <Tab eventKey="llc_mail" title="Mail LLC">
                    <MailUpload
                      orderDetails={orderDetails}
                      selectedProduct={selectedProduct}
                      fileType="TRADEMARK_MAIL"
                    />
                  </Tab>
                )}

                {isLlcIncorporation(productDetails.code.code) && (
                  <Tab eventKey="llc_amendments" title="Amendments LLC">
                    <AmendmentsLLC orderDetails={orderDetails} selectedProduct={selectedProduct} />
                  </Tab>
                )}

                {isPrivacyProduct(productDetails.code.code) && (
                  <Tab eventKey="mail_upload" title="Mail Upload">
                    <MailUpload
                      orderDetails={orderDetails}
                      selectedProduct={selectedProduct}
                      fileType="TRADEMARK_MAIL"
                    />
                  </Tab>
                )}

                {isTrademarkRegistration(productDetails.code.code) && (
                  <Tab eventKey="trademark_summary" title="Trademark Summary">
                    <FormTrademarkSummary orderDetails={orderDetails} selectedProduct={selectedProduct} />
                  </Tab>
                )}

                {isTrademarkRegistration(productDetails.code.code) && (
                  <Tab eventKey="trademark_timeline" title="Timeline">
                    <Timeline
                      orderDetails={orderDetails}
                      selectedProduct={selectedProduct}
                      availableProcessingStatus={availableProcessingStatus}
                      processingStatuses={
                        productsProcessingStatuses[
                          isTrademarkRegistration(productDetails?.code?.code)
                            ? productDetails?.code?.code
                            : "TRADEMARK_RegisterTrademark"
                        ]
                      }
                    />
                  </Tab>
                )}

                {!isLegalTeamAssesment(productDetails.code.code) && (
                  <Tab eventKey="filingFees" title="Filing Fees">
                    <FilingFees orderDetails={orderDetails} productDetails={productDetails} />
                  </Tab>
                )}

                {isLegalTeamAssesment(productDetails.code.code) && (
                  <Tab eventKey="assessment" title="Assessment">
                    <FormTrademarkAssessment orderDetails={orderDetails} selectedProduct={selectedProduct} />
                  </Tab>
                )}

                <Tab eventKey="paymentRefunds" title="Payments">
                  <PaymentRefunds orderDetails={orderDetails} />
                </Tab>

                <Tab eventKey="emails" title="Emails">
                  <Emails orderDetails={orderDetails} selectedProduct={selectedProduct} />
                </Tab>

                {isTrademarkMonitoring(productDetails.code.code) && (
                  <Tab
                    eventKey="monitoring"
                    title={
                      <span>
                        Monitoring
                        <span className={statusIndicatorClasses(monitoringStatus)} />
                      </span>
                    }
                  >
                    <Monitoring orderDetails={orderDetails} productIdx={trademarkMonitoringProductIdx} />
                  </Tab>
                )}

                {isLogoDesingService(productDetails.code.code) && (
                  <Tab eventKey="revisions" title="Revisions">
                    <LogoDesignRevisions orderDetails={orderDetails} selectedProduct={selectedProduct} />
                  </Tab>
                )}

                {!isTrademarkMonitoring(productDetails?.code?.code) && productDetails?.code?.subscription && (
                  <Tab
                    eventKey="billing"
                    title={
                      <span>
                        Billing
                        <span className={statusIndicatorClasses(subscriptionStatus)} />
                      </span>
                    }
                  >
                    <Billing orderDetails={orderDetails} selectedProduct={selectedProduct} />
                  </Tab>
                )}

                <Tab eventKey="actions" title="Actions">
                  {orderDetails.order && (
                    <OrderActions
                      orderDetails={orderDetails}
                      selectedProduct={selectedProduct}
                      onChange={onChange}
                      onDeleteOrder={onDeleteOrder}
                    />
                  )}
                </Tab>

                <Tab eventKey="notes" title="Notes">
                  <Notes orderDetails={orderDetails} />
                </Tab>

                <Tab eventKey="zendesk" title="Tickets">
                  <Zendesk orderDetails={orderDetails} activeTab={activeTab} />
                </Tab>
              </Tabs>
            )}
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default ViewOrderDetail;
