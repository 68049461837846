import { Col, Form, Row } from "react-bootstrap";

import StatusView from "../Status/View";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import { LETTERS_TYPE, LOGO_CATEGORY, LOGO_USE } from "variables/Variables";

export default function FormTrademarkLogoDesignService({ orderDetails, selectedProduct }) {
  const productDetails = orderDetails.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">LOGO DESIGN SERVICE</h4>

      <StatusView orderDetails={orderDetails} selectedProduct={selectedProduct} />

      <FormPDF productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">Business Details</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Exact Logo Wording/Business Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.logoName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Tagline/Slogan <span style={{ color: "gray" }}>(optional)</span>:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.slogan || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Business Industry:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.businessIndustry || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Business Description:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.businessDescription || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Business Products/Services:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.businessProducts || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Target Audience for Customers:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.targetAudience || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Business Website <span style={{ color: "gray" }}>(optional)</span>:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.businessWebsite || "—"}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Logo Design</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Letters Type:
        </Form.Label>
        <Form.Label column sm={7}>
          {LETTERS_TYPE[productDetails?.lettersType] || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Customer's Conception:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.customersConception || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Logo Category:
        </Form.Label>
        <Form.Label column sm={7}>
          {LOGO_CATEGORY[productDetails?.logoCategory] || "—"}
        </Form.Label>
      </Form.Group>

      <br />

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Where the logo will be used:
        </Form.Label>
        <Form.Label column sm={7}>
          {LOGO_USE[productDetails?.logoUse] || "—"}
        </Form.Label>
      </Form.Group>

      {productDetails?.logoUse === "OTHER" && (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Customer's description of use:
          </Form.Label>
          <Form.Label column sm={7}>
            {productDetails?.logoUseDescription || "—"}
          </Form.Label>
        </Form.Group>
      )}
    </div>
  );
}
