import { Row, Form } from "react-bootstrap";
import moment from "moment-timezone";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import IndividualView from "./Individual/View";
import NewAddressView from "./NewAddress/View";

import StatusView from "../Status/View";

export default function FormTrademarkStatementOfUse({ orderDetails, selectedProduct }) {
  const productDetails = orderDetails.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">STATEMENT OF USE TRADEMARK</h4>

      <StatusView orderDetails={orderDetails} selectedProduct={selectedProduct} />

      <FormPDF productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">Processing Result</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Confirmation number:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.processingResult.confirmationNumber}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Mark Information</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Serial Number:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.serialNumber}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Trademark Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.trademarkName}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Trademark Owner</h6>
      <IndividualView individual={productDetails.trademarkOwner} />

      <hr />
      <h6 className="font-weight-bold">New Address</h6>
      <NewAddressView newAddress={productDetails.newAddress} />

      <hr />

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Changes to drawing:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.changesToDrowing}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Change to Trademark Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.changesToTrademarkName}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          New specimen was uploaded?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.newSpecimenWasUploaded ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Covers all Goods?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.coversAllGoods ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Classes or Goods not included in Allegation:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.classesOrGoodsNotInclodedInAllegation}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Items covered by this statement:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.itemsCoveredByThisStatement}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Items covered by this extension request:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.itemsCoveredByThisExtensionRequest}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Specimen class:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.specimenClass}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First use anywhere:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.firstUseAnywhere && moment.utc(productDetails.firstUseAnywhere).format("YYYY/MM/DD")}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First use in commerce:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails.firstUseInCommerce && moment.utc(productDetails.firstUseInCommerce).format("YYYY/MM/DD")}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Changes to Owner</h6>
      <IndividualView individual={productDetails.changesToOwner} />
    </div>
  );
}
