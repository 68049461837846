import { modalsConstants } from "_constants";

const initialState = {
  changeEmail: {
    visible: false,
    retryAction: null,
  },
};

export function modals(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case modalsConstants.SHOW_CHANGE_EMAIL_MODAL:
      return {
        ...state,
        changeEmail: {
          ...state.changeEmail,
          visible: true,
          retryAction: payload?.retryAction || null,
        },
      };
    case modalsConstants.HIDE_CHANGE_EMAIL_MODAL:
      return { ...state, changeEmail: { ...state.changeEmail, visible: false, retryAction: null } };
    default:
      return state;
  }
}
