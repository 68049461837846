import StatusEdit from "../../Status/Edit";

export default function FormTrademarkKeepAlive({ orderDetails, selectedProduct, onChange }) {
  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">KEEP TRADEMARK ALIVE</h4>

      <StatusEdit orderDetails={orderDetails} selectedProduct={selectedProduct} onChange={onChange} />
    </div>
  );
}
