import StatusView from "../Status/View";

import Address from "components/OrderDetails/ProductDetails/Incorporation/Address/View";

export default function FormIncorpifyBusinessAddressAmendment({ orderDetails, selectedProduct }) {
  const productDetails = orderDetails?.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">AMENDMENT</h4>

      <StatusView orderDetails={orderDetails} selectedProduct={selectedProduct} />

      <hr />
      <h6 className="font-weight-bold mb-4">Business Address Amendment Details</h6>

      <h6 className="font-weight-bold">New Address</h6>
      <Address address={productDetails?.newAddress} />
    </div>
  );
}
