import { Modal, Button } from "react-bootstrap";

export default function WebFileMissingModal({ show, handleClose = () => {}, handleConfirm = () => {} }) {
  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>Warning</Modal.Header>
      <Modal.Body>
        <p>This is a Texas order and there is no Webfile Number. We need the Webfile Number to file the report.</p>
        <p>Has the customer provided this number?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Yes, I will input it and try again.
        </Button>
        <Button variant="danger" onClick={handleConfirm}>
          No, I will un-enroll this service.
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
