import { Col, Form, Row } from "react-bootstrap";
import moment from "moment-timezone";

export default function AllQuestionView({ productDetails }) {
  const einName = productDetails.code.code.replace("EIN_", "");

  return (
    <div>
      {["EIN_SoleProp", "EIN_LLC", "EIN_CCorp", "EIN_SCorp"].includes(productDetails?.code?.code) && (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Does your business plan to or currently accept Credit Card payments?
            </Form.Label>
            <Form.Label column sm={7}>
              {!productDetails.acceptCreditCardPayments ? "No" : "Yes"}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Does your business plan to pay employees via payroll?
            </Form.Label>
            <Form.Label column sm={7}>
              {!productDetails.payEmployeesViaPayroll ? "No" : "Yes"}
            </Form.Label>
          </Form.Group>
        </>
      )}

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Does your business own a highway motor vehicle weighing 55,000 pounds or more?
        </Form.Label>
        <Form.Label column sm={7}>
          {!productDetails.questionsHighway ? "No" : "Yes"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Does your business involve gambling?
        </Form.Label>
        <Form.Label column sm={7}>
          {!productDetails.questionsGambling ? "No" : "Yes"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Does your business sell or manufacture alcohol, tobacco, or firearms?
        </Form.Label>
        <Form.Label column sm={7}>
          {!productDetails.questionsATF ? "No" : "Yes"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Does your business pay federal excise taxes?
        </Form.Label>
        <Form.Label column sm={7}>
          {!productDetails.questionsExcise ? "No" : "Yes"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Has this {einName} ever received or applied for an EIN before?
        </Form.Label>
        <Form.Label column sm={7}>
          {!productDetails.questionsAppliedBefore ? "No" : "Yes"}
        </Form.Label>

        {productDetails.questionsAppliedBefore && (
          <Col sm="12">
            <div className="pl-3">
              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Previous EIN number, first 2 digits
                </Form.Label>
                <Form.Label column sm={7}>
                  {productDetails.previousEINNumberFirst2}
                </Form.Label>

                <Form.Label column sm={5}>
                  Previous EIN number, last 7 digits
                </Form.Label>
                <Form.Label column sm={7}>
                  {productDetails.previousEINNumberLast7}
                </Form.Label>
              </Form.Group>
            </div>
          </Col>
        )}
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Do you currently have, or plan to hire employees within the next year (not including owners)?
        </Form.Label>
        <Form.Label column sm={7}>
          {!productDetails.questionsHire ? "No" : "Yes"}
        </Form.Label>

        {productDetails.questionsHire && (
          <Col sm="12">
            <div className="pl-3">
              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Do you expect your employment tax liability to be $1,000 or less in a full calendar year?
                  (January-December)?
                </Form.Label>
                <Form.Label column sm={7}>
                  {!productDetails.payLessThan4k ? "No" : "Yes"}
                </Form.Label>

                {productDetails.payLessThan4k && (
                  <>
                    <Form.Label column sm={5}>
                      Do you want to file employment tax annually instead of quarterly?
                    </Form.Label>
                    <Form.Label column sm={7}>
                      {!productDetails.annualTaxes ? "No" : "Yes"}
                    </Form.Label>
                  </>
                )}

                <Form.Label column sm={5}>
                  Number of Agricultural Employees
                </Form.Label>
                <Form.Label column sm={7}>
                  {productDetails.numberOfAgricultureEmployees}
                </Form.Label>

                {productDetails.code.code !== "EIN_PSC" && (
                  <>
                    <Form.Label column sm={5}>
                      Number of Household Employees
                    </Form.Label>
                    <Form.Label column sm={7}>
                      {productDetails.numberOfHouseholdeEmployees}
                    </Form.Label>
                  </>
                )}

                <Form.Label column sm={5}>
                  Number of Other Employees
                </Form.Label>
                <Form.Label column sm={7}>
                  {productDetails.numberOfOtherEmployees}
                </Form.Label>

                <Form.Label column sm={5}>
                  First date wages or annuities were or will be paid
                </Form.Label>
                <Form.Label column sm={7}>
                  {productDetails.firstDateWages &&
                    moment(productDetails.firstDateWages).format("MM/DD/YYYY")}
                </Form.Label>
              </Form.Group>
            </div>
          </Col>
        )}
      </Form.Group>
    </div>
  );
}
