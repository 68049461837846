import { Form, Row, Col } from "react-bootstrap";
import moment from "moment-timezone";

import { month_array } from "variables/Variables";

export default function DateEdit({ productDetails, onChange }) {
  function datechange({ target: { name, value } }) {
    const dateType = productDetails.code.code === "EIN_Estate" ? "dateOfDeath" : "startDate";
    const oldDate = moment(productDetails[dateType] || new Date())
      .format("YYYY-MM-DD")
      .split("-");

    const newVal = value < 10 ? "0" + value : value;

    if (name === "year") {
      oldDate[0] = newVal;
      // oldDate[2] = "01";
    }
    if (name === "month") {
      oldDate[1] = newVal;
      oldDate[2] = "01";
    }
    if (name === "date") {
      oldDate[2] = newVal;
    }

    const leapYear = (oldDate[0] % 4 === 0 && oldDate[0] % 100 !== 0) || oldDate[0] % 400 === 0;

    if (!leapYear && oldDate[1] === "02" && oldDate[2] === "29") oldDate[2] = "28";

    const newDate = oldDate.join("-") + "T00:00:00";

    onChange({ target: { name: [dateType], value: moment(newDate).format("YYYY-MM-DDTHH:mm:ss") } });
  }

  const handleProductChange = ({ target: { name, value } }) => {
    let newVal = value;

    if (name === "altAddress" && value === "") newVal = null;
    if (name === "altAddress" && value === "true") newVal = { country: "US" };

    onChange({ target: { name, value: newVal } });
  };

  const yearRange = [];
  const dateRange = [];
  const thisYear = new Date().getFullYear();

  let d = new Date(moment().format("YYYY-MM-DDTHH:mm:ss"));
  if (productDetails.code.code === "EIN_Estate") {
    if (Boolean(productDetails.dateOfDeath)) {
      d = new Date(moment(productDetails.dateOfDeath).format("YYYY-MM-DDTHH:mm:ss"));
    }
  } else {
    if (Boolean(productDetails.startDate)) {
      d = new Date(moment(productDetails.startDate).format("YYYY-MM-DDTHH:mm:ss"));
    }
  }

  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  const date = d.getDate();
  const leapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

  let date_array = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (month === 2 && leapYear) {
    date_array[1] = 29;
  }

  for (let i = 1; i <= date_array[d.getMonth()]; i++) {
    dateRange.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  for (let i = 1990, key = 0; i <= thisYear; i++, key++) {
    yearRange.push(
      <option key={key} value={i}>
        {i}
      </option>
    );
  }

  let date_label = "Date entity was started or acquired";

  switch (productDetails.code.code) {
    case "EIN_Trust":
      date_label = "Date Trust was started or acquired";
      break;
    case "EIN_Estate":
      date_label = "Date of Death";
      break;
    default:
      break;
  }

  return (
    <>
      <hr />
      <h6 className="font-weight-bold">Dates</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          {date_label}
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Control required as="select" name="month" value={month} onChange={datechange}>
            {Object.keys(month_array).map((prop, key) => (
              <option key={key} value={prop}>
                {month_array[prop]}
              </option>
            ))}
          </Form.Control>
          <Form.Control required as="select" name="date" value={date} onChange={datechange}>
            {dateRange}
          </Form.Control>
          <Form.Control required as="select" name="year" value={year} onChange={datechange}>
            {yearRange}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Closing month of accounting year
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Control
            required
            as="select"
            name="closingMonth"
            value={productDetails.closingMonth || Object.keys(month_array)[0] || ""}
            onChange={handleProductChange}
          >
            {Object.keys(month_array).map((prop, key) => (
              <option key={key} value={prop}>
                {month_array[prop]}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>
    </>
  );
}
