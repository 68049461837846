import { history } from "_helpers";
import { useRef, useState, useEffect, useCallback } from "react";
import { Button, Dropdown } from "react-bootstrap";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

const displayListType = [
  { value: "default", label: "Default" },
  { value: "customerData", label: `Customer's Data` },
  { value: "custom", label: "Custom" },
];

export default function OrdersTableAction(props) {
  const wrapperRef = useRef();
  const { tableActions } = props;
  const { displayColumnType, displayingColumn, displayColumnOrderTable, displayColumnOrderTableObj } = props;
  const { handleChangeDisplayColumnType, handleSelectDisplayingColumns } = props;

  const [showListDisplayColumn, setShowListDisplayColumn] = useState(false);

  const handleToggleDropdown = () => setShowListDisplayColumn(!showListDisplayColumn);
  const formatToMultiSelectValue = (values) =>
    values.map((val) => ({
      value: val,
      label: displayColumnOrderTableObj[val],
    }));

  const getDisplayColumnDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
    if (value.length >= 8) {
      return `Maximum is 8 fields`;
    }

    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  };

  const handleChangeType = (value, e) => {
    e.stopPropagation();
    handleChangeDisplayColumnType(value);
    setShowListDisplayColumn(value === "custom");
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (wrapperRef && !wrapperRef.current?.contains(event.target) && showListDisplayColumn) {
        setShowListDisplayColumn(false);
      }
    },
    [showListDisplayColumn]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [handleClickOutside]);

  return (
    <div ref={wrapperRef} className="d-flex">
      {tableActions?.CREATE_ORDER && (
        <Button
          variant="outline-success"
          className="btn btn-sm mr-2"
          onClick={() => {
            history.push(`/admin/category/${tableActions.CREATE_ORDER}/create-order`);
          }}
        >
          <i className="fa fa-plus" /> Create new order
        </Button>
      )}
      {tableActions?.COLUMNS_PRESET && (
        <Dropdown show={showListDisplayColumn} alignRight>
          <Dropdown.Toggle
            variant="outline-secondary"
            size="sm"
            className="d-flex align-items-center justify-content-between"
            style={{ minWidth: "130px" }}
            onClick={handleToggleDropdown}
          >
            {displayListType.find((item) => item.value === displayColumnType)?.label}
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ minWidth: "300px" }}>
            {displayListType.map((item, key) => (
              <Dropdown.Item onClick={(e) => handleChangeType(item.value, e)} key={item.value + key}>
                <div>{item.label}</div>
                {item.value === "custom" && displayColumnType === "custom" && (
                  <div style={{ width: 250 }} className="mt-2">
                    <ReactMultiSelectCheckboxes
                      placeholderButtonLabel="Column"
                      name="displayingColumn"
                      value={formatToMultiSelectValue(displayingColumn)}
                      options={displayColumnOrderTable}
                      onChange={handleSelectDisplayingColumns}
                      getDropdownButtonLabel={getDisplayColumnDropdownButtonLabel}
                    />
                  </div>
                )}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
}
