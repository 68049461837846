import { Card, Table, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { healthcheckActions } from "_actions";

function TabHealthCheckBackend() {
  const dispatch = useDispatch();

  const { healthchecks } = useSelector(({ healthchecks }) => healthchecks.backendHealth);

  const getHealthcheck = () => {
    dispatch(healthcheckActions.getBackendHealth());
  };

  const restartService = (name) => {
    dispatch(healthcheckActions.restartService(name));
  };

  return (
    <div className="form-border bg-white fixed-height healthcheck">
      <Row>
        <Col md="12">
          <Card className="mb-1">
            <Card.Title className="m-0 p-2">
              Service Informations
              <Button type="button" className="ml-4 my-1" size="sm" onClick={getHealthcheck}>
                Ping
              </Button>
            </Card.Title>
            <Table striped bordered responsive size="sm">
              <thead>
                <tr>
                  <th>Service Name</th>
                  <th>Health</th>
                  <th>Ping</th>
                  <th>Used RAM</th>
                  <th>Threads</th>
                  <th>Disc Space</th>
                  <th>URL</th>
                  <th>Version</th>
                  <th>Last deployed</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {healthchecks.serviceHealthchecks.map((item, key) => (
                  <tr key={key} title={item.versionTooltip}>
                    <td>{item.name}</td>
                    <td>
                      <span className={"indicator-status status-" + item.marker} />
                    </td>
                    <td>{item.ping}</td>
                    <td>{item.usedMemory}</td>
                    <td>{item.threads}</td>
                    <td>{item.discSpace}</td>
                    <td>
                      <a href={item.healthcheckUrl} target="_blank">
                        {item.healthcheckUrl}
                      </a>
                    </td>
                    <td>{item.buildVersion}</td>
                    <td>{moment.utc(item.lastDeployed).local().format("YYYY/MM/DD HH:mm:ss")}</td>
                    <td>
                      <Button size="sm" onClick={() => restartService(item.name)}>
                        Restart
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default TabHealthCheckBackend;
