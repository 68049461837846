import React from "react";
import styled from "styled-components";

const OptionalLabel = styled.span`
  color: gray;
  font-size: 0.775rem;
  & sup {
    font-size: 0.725rem;
  }
`;

const RequiredLabel = styled.span`
  color: red;
  font-size: 0.775rem;
  & sup {
    font-size: 0.875rem;
  }
`;

const ExtraLabel = (props) => {
  const { $optional, $required } = props;

  if ($optional) {
    return (
      <OptionalLabel>
        <sup>(optional)</sup>
      </OptionalLabel>
    );
  }

  if ($required) {
    return (
      <RequiredLabel>
        <sup>*</sup>
      </RequiredLabel>
    );
  }

  return null;
};

export default ExtraLabel;
