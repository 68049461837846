import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row, Form, Button } from "react-bootstrap";

import { validatePassword } from "_helpers";

import { customerActions } from "_actions";

import PasswordValidation from "components/PasswordValidation";

export default function PasswordReset({ orderDetails }) {
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [confirmTouched, setConfirmTouched] = useState(false);

  // const passwordRegex = new RegExp(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/);
  const isPSWValid = validatePassword(password);
  const isPSWConfirmed = password === confirm;
  const customerId = orderDetails?.customer?.id;

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "password") {
      setPasswordTouched(true);
      setPassword(value);
    }

    if (name === "confirm") {
      setConfirmTouched(true);
      setConfirm(value);
    }
  };

  const handleSubmit = useCallback(() => {
    if (isPSWValid && isPSWConfirmed) {
      dispatch(customerActions.setPassword(customerId, password));
      setPassword("");
      setConfirm("");
      setPasswordTouched(false);
      setConfirmTouched(false);
    }
  }, [dispatch, customerId, password, isPSWValid, isPSWConfirmed]);

  return (
    <>
      <Form.Group as={Row} controlId="password">
        <Form.Label column sm={4}>
          Password
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            type="password"
            placeholder="Password"
            name="password"
            isInvalid={passwordTouched && !isPSWValid}
            value={password}
            onChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">
            <PasswordValidation password={password} />
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="confirm">
        <Form.Label column sm={4}>
          Confirm Password
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            type="password"
            placeholder="Password"
            name="confirm"
            isInvalid={confirmTouched && !isPSWConfirmed}
            value={confirm}
            onChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">Passwords is not equal!</Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Row>
        <Col sm={12} className="d-flex justify-content-end mt-3">
          <Button variant="primary" disabled={!(isPSWValid && isPSWConfirmed)} onClick={handleSubmit}>
            Set Password
          </Button>
        </Col>
      </Row>
    </>
  );
}
