import { userConstants } from "../_constants";

const initialState = {
  items: {},
  user: {},
  error: {},
  loading: false,
};

export function users(state = { ...initialState }, action) {
  switch (action.type) {
    // GET ALL USERS
    case userConstants.GETALL_REQUEST:
      return { ...initialState, loading: true };
    case userConstants.GETALL_SUCCESS:
      return { ...initialState, items: action.users, loading: false };
    case userConstants.GETALL_FAILURE:
      return { ...initialState, error: action.error, loading: false };

    // GET USER
    case userConstants.GET_USER_REQUEST:
      return { ...state, loading: true };
    case userConstants.GET_USER_SUCCESS:
      return { ...state, user: action.payload, loading: false };
    case userConstants.GET_USER_FAILURE:
      return { ...state, error: action.error, loading: false };

    // REGISTER USER
    case userConstants.REGISTER_SUCCESS:
      return { items: action.users };
    case userConstants.GETME_SUCCESS:
      return { items: action.user };

    // DELETE USER
    case userConstants.DELETE_SUCCESS:
      return { items: state.items.filter((user) => user.id !== action.id) };
    case userConstants.DELETE_FAILURE:
      return {
        ...state,
        items: state.items.map((user) => {
          if (user.id === action.id) {
            const { deleting, ...userCopy } = user;
            return { ...userCopy, deleteError: action.error };
          }
          return user;
        }),
      };

    default:
      return state;
  }
}
