import { Form, Row } from "react-bootstrap";

import { phoneFormatter } from "_helpers";

import StatusView from "../Status/View";

import Address from "components/OrderDetails/ProductDetails/Incorporation/Address/View";

export default function FormIncorpifyManagerAmendment({ orderDetails, selectedProduct }) {
  const productDetails = orderDetails?.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">AMENDMENT</h4>

      <StatusView orderDetails={orderDetails} selectedProduct={selectedProduct} />

      <hr />
      <h6 className="font-weight-bold mb-4">Manager Amendment Details</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.newManager?.firstName || "–"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Middle Name (optional):
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.newManager?.middleName || "–"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Last Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.newManager?.lastName || "–"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Phone Number:
        </Form.Label>
        <Form.Label column sm={7}>
          {phoneFormatter(productDetails?.newManager?.phone) || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Email Address:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.newManager?.email || "–"}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Address</h6>
      <Address address={productDetails?.newManager?.address} />
    </div>
  );
}
