import React from "react";
import { Form, Row } from "react-bootstrap";

import StatusView from "../Status/View";

function FormDBA({ orderDetails, selectedProduct }) {
  const productDetails = orderDetails?.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">{productDetails?.code?.customerName}</h4>

      <StatusView orderDetails={orderDetails} selectedProduct={selectedProduct} />

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          DBA Name (Doing Business As):
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.dbaName || "—"}
        </Form.Label>
      </Form.Group>
    </div>
  );
}

export default FormDBA;
