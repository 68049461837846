import React from "react";
import { Row, Form } from "react-bootstrap";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import ActivityView from "./Activity/View";
import AllQuestionView from "./GeneralQuestion/View/All";
import AltAddressView from "./AltAddress/View";
import CorpAddressView from "./CorpAddress/View";
import DateView from "./Date/View";

import StatusView from "../Status/View";

import { EIN_title_array, state_array } from "variables/Variables";

function FormLLC({ orderDetails, selectedProduct }) {
  const productDetails = orderDetails.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">LIMITED LIABILITY COMPANY</h4>

      <StatusView orderDetails={orderDetails} selectedProduct={selectedProduct} />

      <FormPDF productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">Legal information</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Legal Name of the LLC:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.legalName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Trade name or DBA of the LLC:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.dbaname || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Number of members in the LLC:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.numberOfMembers || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Are the members husband and wife?
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.husbandAndWife ? "Yes" : "No"}
          {productDetails?.husbandAndWife && (
            <p>
              {productDetails?.multiMember
                ? "We select to be classified as a multi-member LLC."
                : "We select to be classified as a single-member LLC."}
            </p>
          )}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Selected Tax classification of the LLC:
        </Form.Label>
        {productDetails?.taxClassification === "Individual" && (
          <Form.Label column sm={7}>
            Individual/Partnership: Not taxed as a separate entity from owner(s).
          </Form.Label>
        )}

        {productDetails?.taxClassification === "SCorp" && (
          <Form.Label column sm={7}>
            S-Corporation: Planning to elect an S-Corporation tax structure.
          </Form.Label>
        )}

        {productDetails?.taxClassification === "Corporation" && (
          <Form.Label column sm={7}>
            Corporation: LLC is planning to elect a Corporation tax structure.
          </Form.Label>
        )}

        {!productDetails?.taxClassification && (
          <Form.Label column sm={7}>
            N/A
          </Form.Label>
        )}
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          In which state the LLC was incorporated?
        </Form.Label>
        <Form.Label column sm={7}>
          {state_array[productDetails?.organizedState] || "N/A"}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Managing Member Information</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.owner?.firstName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Middle Name (optional):
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.owner?.middleName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Last Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.owner?.lastName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Suffix Name (optional):
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.owner?.suffixName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Title:
        </Form.Label>
        <Form.Label column sm={7}>
          {EIN_title_array[productDetails?.owner?.title] || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Social Security Number:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.owner?.ssn || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Previous First Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.owner?.prevFirstName || "—"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Previous Last Name:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.owner?.prevLastName || "—"}
        </Form.Label>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Activity</h6>

      <ActivityView productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">General questions</h6>

      <AllQuestionView productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">Corporate Address (PO Boxes are not authorized)</h6>
      <CorpAddressView productDetails={productDetails} />

      <AltAddressView productDetails={productDetails} />

      <DateView productDetails={productDetails} />
    </div>
  );
}

export default FormLLC;
