import { consumerLogsConstants } from "_constants";

const initState = {
  loading: 0,
  logs: [],
  types: [],
};

export function consumerLogs(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case consumerLogsConstants.GET_ALL_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case consumerLogsConstants.GET_ALL_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1, logs: payload };
    case consumerLogsConstants.GET_ALL_FAILURE:
      return { ...state, loading: state.loading && state.loading - 1 };

    case consumerLogsConstants.GET_TYPE_REQUEST:
      return { ...state, loading: state.loading + 1 };
    case consumerLogsConstants.GET_TYPE_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1, types: payload };
    case consumerLogsConstants.GET_TYPE_FAILURE:
      return { ...state, loading: state.loading && state.loading - 1 };

    default:
      return state;
  }
}
