import { crmConfigConstants } from "_constants";

const initState = {
  loading: false,
  crmConfig: {},
  productCategories: [],
};

export function crmConfig(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case crmConfigConstants.GET_REQUEST:
      return { ...state, loading: true };
    case crmConfigConstants.GET_SUCCESS:
      return { ...state, loading: false, crmConfig: payload };
    case crmConfigConstants.GET_FAILURE:
      return { ...state, loading: false };

    case crmConfigConstants.SAVE_REQUEST:
      return { ...state, loading: true };
    case crmConfigConstants.SAVE_SUCCESS:
      return { ...state, loading: false, crmConfig: payload };
    case crmConfigConstants.SAVE_FAILURE:
      return { ...state, loading: false, crmConfig: { ...state.crmConfig } };

    case crmConfigConstants.GET_PRODUCTS_REQUEST:
      return { ...state, loading: true };
    case crmConfigConstants.GET_PRODUCTS_SUCCESS:
      return { ...state, loading: false, productCategories: payload };
    case crmConfigConstants.GET_PRODUCTS_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
}
