import { Fragment } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

export const initCommonLawResult = {
  term: "",
  websiteURL: "",
};

export default function CommonLawTrademarkResultList({ commonLawTrademarkResults, onError, onChange }) {
  const handleAddCommonLaw = () => onChange([...commonLawTrademarkResults, { ...initCommonLawResult }]);

  const handleDeleteCommonLaw = (deleteIdx) =>
    onChange(commonLawTrademarkResults.filter((o, _idx) => deleteIdx !== _idx));

  const handleCommonLawChange = ({ target: { name, value } }, index) =>
    onChange(
      commonLawTrademarkResults.map((commonLawResult, _idx) =>
        _idx === index ? { ...commonLawResult, [name]: value } : commonLawResult
      )
    );

  return (
    <>
      <hr />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          <h6 className="font-weight-bold">Common Law Results</h6>
        </Form.Label>
        <Col sm={7}>
          <Button size="sm" onClick={handleAddCommonLaw}>
            Add Common Law
          </Button>
        </Col>
      </Form.Group>

      {commonLawTrademarkResults?.map(({ term, websiteURL }, idx) => (
        <Fragment key={idx}>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Term:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="term"
                value={term || ""}
                onChange={(e) => handleCommonLawChange(e, idx)}
                onInvalid={() => onError("Term")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Website URL:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                required
                size="sm"
                type="text"
                name="websiteURL"
                value={websiteURL || ""}
                onChange={(e) => handleCommonLawChange(e, idx)}
                onInvalid={() => onError("Website URL")}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              {term}
            </Form.Label>
            <Col sm={7}>
              <Button size="sm" variant="danger" onClick={() => handleDeleteCommonLaw(idx)}>
                Remove
              </Button>
            </Col>
          </Form.Group>
        </Fragment>
      ))}
    </>
  );
}
