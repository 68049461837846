import { config } from "_config";
import { authHeader, handleResponse, mappingQueryParams } from "_helpers";

export const healthcheckService = {
  getHealth,
  restartService,
};

function getHealth({ ping_threshold = 600, type, groupBy }) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  const url = `${config.healthcheckurl}/healthcheck${mappingQueryParams({ ping_threshold, type, groupBy })}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function restartService(name) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: name,
  };
  let url = `${config.serviceurl}/services/${name}/restart`;
  return fetch(url, requestOptions).then(handleResponse);
}
