import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Row } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";

import { customerActions } from "_actions";

import Spinner from "components/Spinner";
import LeadWarningModal from "components/LeadWarningModal";
import DateView from "./Date/View";

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 260px;
`;
const ErrorDate = styled.div`
  align-self: end;
  font-size: 10px;
`;
const StatusContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 270px;
  max-width: 500px;
`;
const CurrentStatus = styled.div``;
const NextStep = styled.div`
  font-size: 10px;
  text-align: center;
`;
const ButtonsContainer = styled.div`
  display: flex;
  gap: 32px;
  width: 100%;
  margin-top: 16px;
  max-width: 350px;

  @media (max-width: 400px) {
    max-width: 300px;
  }
`;
const ButtonsColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  flex-grow: 1;
`;
const NoteContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  max-width: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const StyledButton = styled(Button)`
  min-width: 150px;
  max-width: 150px;
  min-height: 50px;
  max-height: 50px;

  @media (max-width: 400px) {
    min-width: 120px;
  }
`;

const initialSteps = { successSteps: [], errorSteps: [] };

export default function ViewStatus({
  orderDetails,
  availableProcessingStatus,
  selectedProduct,
  processingErrorsOpt,
  productsProcessingStatusesOpt,
  onUpdateOrderStatus,
  onChange,
}) {
  if (Object.keys(orderDetails).length === 0) return null;
  if (Object.keys(availableProcessingStatus).length === 0) return null;

  const dispatch = useDispatch();

  const [message, setMessage] = useState("");
  const [customerContactRequired, setCustomerContactRequired] = useState(false);
  const [steps, setSteps] = useState(initialSteps);
  const [showLeadWarning, setShowLeadWarning] = useState({ mode: "", data: null }); // HOLD, STATUS_CHANGE

  const { loading } = useSelector(({ customers }) => customers);
  const orderdata = useSelector(({ customers: { orderdata } }) => orderdata);

  const prevSelectedProductStatus = orderdata?.products[selectedProduct]?.processingResult?.status;
  const currentProduct = orderDetails.products[selectedProduct];

  const handleSaveNote = () => {
    const newNote = { message, customerContactRequired };
    setMessage("");

    dispatch(customerActions.addNote(orderDetails.order.id, newNote));
  };

  const handleProductStatusChange = ({ target: { name, value, confirmed = false } }) => {
    if (prevSelectedProductStatus === "Lead" && !confirmed) {
      setShowLeadWarning({ mode: "STATUS_CHANGE", data: { name, value } });
      return;
    }

    const { statusCode, errorCode } = JSON.parse(value);

    const newObj = {
      ...orderDetails,
      products: orderDetails.products.map((product, idx) => {
        if (idx !== selectedProduct) return product;
        let newData = { [name]: statusCode };
        if (name === "status" && statusCode === "Error") {
          if (errorCode) {
            newData = { ...newData, errorStatus: "Verification Required", processingError: errorCode };
          } else {
            newData = { ...newData, errorStatus: "Misc", processingError: "Misc" };
          }
        }
        if (name === "status" && statusCode !== "Error") {
          newData = { ...newData, errorStatus: null, processingError: null };
        }
        if (name === "status" && !statusCode && !errorCode) newData = { ...currentProduct.processingResult };
        return { ...product, processingResult: { ...product.processingResult, ...newData } };
      }),
    };
    onChange(newObj);
    onUpdateOrderStatus();
  };

  const handlePlaceOnHold = ({ confirmed = false }) => {
    if (prevSelectedProductStatus === "Lead" && !confirmed) {
      setShowLeadWarning({ mode: "HOLD", data: null });
      return;
    }

    const newObj = {
      ...orderDetails,
      products: orderDetails.products.map((product, idx) => {
        if (idx !== selectedProduct) return product;
        const newData = { status: "Hold", errorStatus: null, processingError: null };
        return { ...product, processingResult: { ...product.processingResult, ...newData } };
      }),
    };
    onChange(newObj);
    onUpdateOrderStatus();
  };

  useEffect(() => {
    if (Object.keys(availableProcessingStatus).length !== 0) {
      const newSteps = availableProcessingStatus.nextStatuses.reduce(
        (statuses, status) => {
          if (status.errorCode || status.buttonName === "Issue not fixed") {
            statuses.errorSteps.push(status);
          } else {
            statuses.successSteps.push(status);
          }
          return statuses;
        },
        { successSteps: [], errorSteps: [] }
      );
      setSteps(newSteps);
    }
  }, [availableProcessingStatus]);

  return (
    <>
      <hr />
      {loading ? (
        <Spinner />
      ) : (
        <StatusContainer>
          {currentProduct.processingResult?.logOfStatuses?.length > 0 &&
            currentProduct.processingResult?.logOfStatuses?.slice(-1)[0]?.status === "Error" && (
              <ErrorDate>
                Error since: &nbsp;
                {moment(currentProduct.processingResult?.logOfStatuses?.slice(-1)[0]?.timestamp).format("MM-DD-YYYY")}
              </ErrorDate>
            )}

          <StatusContent>
            <CurrentStatus>
              Status:&nbsp;
              {productsProcessingStatusesOpt[currentProduct.processingResult?.status]}
              {currentProduct.processingResult?.processingError
                ? ` - ${processingErrorsOpt[currentProduct.processingResult?.processingError]}`
                : ""}
            </CurrentStatus>
            {availableProcessingStatus?.nextStatuses?.length > 0 && (
              <NextStep>
                Next Step:&nbsp;
                {availableProcessingStatus?.nextStatuses[0]?.statusName}
                &nbsp;
                {availableProcessingStatus?.nextStatuses.length > 1 && (
                  <>
                    <b>or</b> Error/Verification
                  </>
                )}
              </NextStep>
            )}

            <ButtonsContainer>
              <ButtonsColumn>
                {steps.successSteps.map(({ statusCode, buttonName }) => (
                  <StyledButton
                    key={`status-btn-${statusCode}`}
                    className={"btn btn-success btn-sm w-100 mt-2"}
                    name="status"
                    value={JSON.stringify({ statusCode })}
                    onClick={handleProductStatusChange}
                  >
                    {buttonName}
                  </StyledButton>
                ))}
              </ButtonsColumn>

              {steps.errorSteps.length > 0 && (
                <ButtonsColumn>
                  {steps.errorSteps.map(({ statusCode, errorCode, buttonName }) => (
                    <StyledButton
                      key={`error-btn-${errorCode}`}
                      className={"btn btn-danger btn-sm w-100 mt-2"}
                      name="status"
                      value={JSON.stringify({ statusCode, errorCode })}
                      onClick={handleProductStatusChange}
                    >
                      {buttonName}
                    </StyledButton>
                  ))}
                </ButtonsColumn>
              )}
            </ButtonsContainer>

            <ButtonsContainer>
              {availableProcessingStatus.currentStatus.statusCode !== "Hold" && (
                <ButtonsColumn>
                  <StyledButton className={"btn btn-warning btn-sm w-100 mt-2"} onClick={handlePlaceOnHold}>
                    Place on Hold
                  </StyledButton>
                </ButtonsColumn>
              )}
            </ButtonsContainer>

            {(steps.errorSteps.some(({ addNote }) => addNote) || steps.successSteps.some(({ addNote }) => addNote)) && (
              <>
                <hr style={{ width: "100%" }} />
                <NoteContainer>
                  <span>Quick note:</span>
                  <Form.Control
                    as="textarea"
                    style={{ resize: "none", height: "134px" }}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <Form.Check
                    id="info-customerContactRequired"
                    className="py-2"
                    type="checkbox"
                    label="Customer Contact Required"
                    name="info-customerContactRequired"
                    checked={customerContactRequired}
                    onChange={({ target: { checked } }) => setCustomerContactRequired(checked)}
                  />
                  <Button name="note" className="btn btn-primary btn-sm mt-2 align-self-end" onClick={handleSaveNote}>
                    Add note
                  </Button>
                </NoteContainer>
              </>
            )}
          </StatusContent>
        </StatusContainer>
      )}

      {currentProduct?.processingResult?.status === "Pending" &&
        (currentProduct?.processingResult?.pendingReason ||
          currentProduct?.processingResult?.dateToChangeStatus ||
          currentProduct?.processingResult?.pendingStatus) && (
          <>
            <Form.Group as={Row}>
              <Form.Label column sm="5">
                Pending:
              </Form.Label>
              <Form.Label column sm="7">
                {currentProduct.processingResult.pendingReason}
              </Form.Label>
            </Form.Group>
            <DateView label="Status Will Change On:" date={currentProduct.processingResult.dateToChangeStatus} />
            <Form.Group as={Row}>
              <Form.Label column sm="5">
                Status Will Change To:
              </Form.Label>
              <Form.Label column sm="7">
                {currentProduct.processingResult.pendingStatus}
              </Form.Label>
            </Form.Group>
          </>
        )}

      <LeadWarningModal
        show={Boolean(showLeadWarning.mode)}
        handleClose={() => {
          setShowLeadWarning({ mode: "", data: null });
        }}
        handleConfirm={() => {
          const newObj = {
            confirmed: true,
          };

          if (showLeadWarning.mode === "HOLD") {
            handlePlaceOnHold(newObj);
          }

          if (showLeadWarning.mode === "STATUS_CHANGE") {
            newObj.target = showLeadWarning.data;
            handleProductStatusChange(newObj);
          }

          setShowLeadWarning({ mode: "", data: null });
        }}
      />
    </>
  );
}
