export default function Card(props) {
  return (
    <div className={`card${props.plain ? " card-plain" : ""}`}>
      <div className={"header d-flex align-items-end" + (props.hCenter ? " text-center" : "")}>
        <div className="flex-fill">
          <h4 className="title">{props.title}</h4>
          <p className="category">{props.category}</p>
        </div>
        {props.legendInHeader && <div className="table-legend">{props.legendInHeader}</div>}
        {props.actions && <div className="table-action">{props.actions}</div>}
      </div>
      <div
        className={
          "content" +
          (props.ctAllIcons ? " all-icons" : "") +
          (props.ctTableFullWidth ? " table-full-width" : "") +
          (props.ctTableResponsive ? " table-responsive" : "") +
          (props.ctTableUpgrade ? " table-upgrade" : "")
        }
      >
        {props.content}

        <div className="footer">
          {props.legend}
          {props.stats != null ? <hr /> : ""}
          <div className="stats">
            <i className={props.statsIcon} /> {props.stats}
          </div>
        </div>
      </div>
    </div>
  );
}
