import React from "react";
import { Row, Form } from "react-bootstrap";

import { state_array } from "variables/Variables";

function CorpAddressView({ productDetails }) {
  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.corpAddress?.address1 || "–"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address2:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.corpAddress?.address2 || "–"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          City:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.corpAddress?.city || "–"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          State:
        </Form.Label>
        <Form.Label column sm={7}>
          {state_array[productDetails?.corpAddress?.state] || "–"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Zip Code:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.corpAddress?.zip || "–"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          County:
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.corpAddress?.county || "–"}
        </Form.Label>
      </Form.Group>
    </>
  );
}

export default CorpAddressView;
