import React from "react";
import { Col, Form, Row } from "react-bootstrap";

import { state_array } from "variables/Variables";

function CorpAddressEdit({ productDetails, onError, onProductChange }) {
  const handleProductCorpAddressChange = ({ target: { name, value } }) =>
    onProductChange({ target: { name: "corpAddress", value: { ...productDetails?.corpAddress, [name]: value } } });

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="address1"
            pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            value={productDetails?.corpAddress?.address1 || ""}
            onChange={handleProductCorpAddressChange}
            onInvalid={() => onError("Corporate Address")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address2
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            name="address2"
            type="text"
            pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            value={productDetails?.corpAddress?.address2 || ""}
            onChange={handleProductCorpAddressChange}
            onInvalid={() => onError("Corporate Address2")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          City
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="city"
            pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            value={productDetails?.corpAddress?.city || ""}
            onChange={handleProductCorpAddressChange}
            onInvalid={() => onError("Corporate City")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          State
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            as="select"
            name="state"
            value={productDetails?.corpAddress?.state || ""}
            onChange={handleProductCorpAddressChange}
            onInvalid={() => onError("Corporate State")}
          >
            <option value="">Select a state</option>
            {Object.keys(state_array).map((key) => (
              <option key={key} value={key}>
                {state_array[key]}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Zip Code
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="zip"
            pattern="^\d{5}$"
            value={productDetails?.corpAddress?.zip || ""}
            onChange={handleProductCorpAddressChange}
            onInvalid={() => onError("Corporate Zip")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          County
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="county"
            pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            value={productDetails?.corpAddress?.county || ""}
            onChange={handleProductCorpAddressChange}
            onInvalid={() => onError("Corporate County")}
          />
        </Col>
      </Form.Group>
    </>
  );
}

export default CorpAddressEdit;
