import orderBy from "lodash.orderby";
import moment from "moment-timezone";

import { checkIsRegisterTrademark, checkIsUSPTOFilingFee } from "_helpers/products";

const NOT_ALLOWED_STATUSES = ["Lead", "Chargeback", "Declined", "FullRefund"];
const ALLOWED_PROCESSING_RESULTS = ["Error", "Hold", "Cancelled", "AttorneyReview"];

export function getTransactions(orderDetails) {
  const { children, order, products } = orderDetails || {};
  const transactions = [];

  const registerProduct = products?.find(({ code }) => checkIsRegisterTrademark(code?.code));

  transactions.push({
    orderId: order?.id,
    productId: null,
    summary: "Package Summary",
    isPackageSummary: true,
    relatedOrder: false,
    onlyFullRefund: true,
    paymentDate: null,
    paymentDateMs: moment.utc(products[0]?.paymentDate).valueOf(),
    total: order?.total,
    refunded: products.length ? products?.reduce((sum, product) => sum + product.refundAmount, 0) : 0,
    refundDate: null,
    refundWithFee: true,
    noRefund: products.every(({ paymentStatus }) => NOT_ALLOWED_STATUSES.includes(paymentStatus)),
  });

  products.forEach(
    (
      { code: { customerName }, price, id, refundDate, paymentDate, paymentStatus, refundAmount, merchantDescriptor },
      i
    ) => {
      transactions.push({
        orderId: order?.id,
        productId: id,
        summary: customerName,
        isPackageSummary: false,
        relatedOrder: false,
        onlyFullRefund: false,
        paymentDate: paymentDate,
        paymentDateMs: moment.utc(paymentDate).valueOf(),
        total: price,
        refunded: refundAmount,
        refundDate: refundDate,
        refundWithFee: true,
        noRefund: NOT_ALLOWED_STATUSES.includes(paymentStatus),
        merchantDescriptor,
      });
    }
  );

  children
    ?.filter(({ products }) => !NOT_ALLOWED_STATUSES.includes(products[0]?.paymentStatus))
    .forEach(({ order, products }) => {
      if (products?.length) {
        transactions.push({
          orderId: order?.id,
          productId: null,
          summary:
            "(Upsell) " +
            products?.map((product) => product?.code?.customerName || products[0]?.customerName || "N/A").join(", "),
          isPackageSummary: false,
          relatedOrder: true,
          onlyFullRefund: true,
          paymentDate: products[0]?.paymentDate,
          paymentDateMs: moment.utc(products[0]?.paymentDate).valueOf(),
          total: order?.total,
          refunded: products[0]?.refundAmount,
          refundDate: null,
          refundWithFee: false,
          noRefund:
            checkIsUSPTOFilingFee(order?.productCodes[0]?.code) &&
            !ALLOWED_PROCESSING_RESULTS.includes(registerProduct?.processingResult?.status),
          merchantDescriptor: products[0]?.merchantDescriptor,
        });
      }
    });

  // return orderBy(transactions, ["paymentDateMs"], ["desc"]);
  return transactions;
}
