import { useEffect, useState } from "react";
import { Row, Col, Form, Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";

import { thirdpartytypeActions, thirdpartylogsActions } from "_actions";

import Spinner from "components/Spinner";
import Button from "components/Button";

function TabThirdpartyLog() {
  const dispatch = useDispatch();
  const thirdPartyTypes = useSelector(({ thirdpartytypes }) => thirdpartytypes.items || []);
  const thirdPartyLogs = useSelector(({ thirdpartylogs }) => thirdpartylogs.items || null);
  const loading = useSelector(({ thirdpartylogs: { loading } }) => loading);

  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [logType, setLogType] = useState("");
  const [objId, setObjId] = useState("");
  const [reqString, setReqString] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(thirdpartylogsActions.get(startDate, endDate, logType, objId, reqString));
  };

  const exportcsv = () => {
    dispatch(thirdpartylogsActions.exportcsv(startDate, endDate, logType, objId, reqString));
  };

  useEffect(() => {
    dispatch(thirdpartytypeActions.get());
  }, []);

  return (
    <div className="form-border bg-white fixed-height third-party">
      <Row>
        <Col md={12}>
          <Card className="p-3">
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Start Date"
                    name="startDate"
                    size="sm"
                    value={startDate}
                    max={endDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="End Date"
                    name="endDate"
                    size="sm"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    as="select"
                    id="inlineFormCustomSelectPref"
                    size="sm"
                    value={logType}
                    onChange={(e) => setLogType(e.target.value)}
                  >
                    <option value="">All</option>
                    {thirdPartyTypes.map((item, key) => (
                      <option value={item} key={key}>
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Form.Label>Linked Object ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Linked Object ID"
                    size="sm"
                    value={objId}
                    onChange={(e) => setObjId(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Form.Label>Request</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Request"
                    size="sm"
                    value={reqString}
                    onChange={(e) => setReqString(e.target.value)}
                  />
                </Form.Group>

                <Form.Group
                  as={Col}
                  className="d-flex align-items-sm-end justify-content-sm-end"
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                >
                  <Button type="submit" size="sm" className="mr-1" isLoading={loading}>
                    Search
                  </Button>

                  <Button size="sm" onClick={exportcsv}>
                    Export CSV
                  </Button>
                </Form.Group>
              </Form.Row>
            </Form>
          </Card>

          {thirdPartyLogs && (
            <Card className="mb-1 full-height">
              <Card.Title className="m-0 p-2">3rd Party System Logs Information</Card.Title>
              <Table striped bordered responsive size="sm" className="thirdpartylogstable">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Linked Object ID</th>
                    <th>Input Parameters</th>
                    <th>Request</th>
                    <th>Response</th>
                  </tr>
                </thead>
                <tbody>
                  {thirdPartyLogs.map((prop, key) => (
                    <tr key={key}>
                      <td>{moment(prop.created).format("YYYY/MM/DD HH:mm:ss")}</td>
                      <td>{prop.type}</td>
                      <td>{prop.linkedObjectId}</td>
                      <td>{prop.inputParameters}</td>
                      <td>{prop.request}</td>
                      <td>{prop.response}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          )}

          {loading && <Spinner />}
        </Col>
      </Row>
    </div>
  );
}

export default TabThirdpartyLog;
