import { deployUIConstants } from "_constants";

const initState = {
  loading: 0,
};

export function deployServices(state = { ...initState }, action) {
  switch (action.type) {
    case deployUIConstants.GETALL_UI_REQUEST:
      return {
        loading: state.loading + 1,
      };
    case deployUIConstants.GETALL_UI_SUCCESS:
      return {
        ...state,
        items: action.result,
        loading: state.loading - 1,
      };

    case deployUIConstants.GETALL_UI_FAILURE:
      return {
        ...state,
        loading: state.loading - 1,
        error: action.error,
      };

    case deployUIConstants.DEPLOY_UI_REQUEST:
      return {
        ...state,
        loading: state.loading + 1,
      };
    case deployUIConstants.DEPLOY_UI_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
      };

    case deployUIConstants.DEPLOY_UI_FAILURE:
      return {
        ...state,
        loading: state.loading - 1,
        error: action.error,
      };
    default:
      return state;
  }
}
