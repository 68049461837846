import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Table, Button } from "react-bootstrap";

import { adsAccounts } from "_actions";

import AdAccountModal from "./AdAccountModal";

import Spinner from "components/Spinner";

const accountDataColumns = [
  { key: "type", label: "Type", style: { minWidth: "90px", maxWidth: "90px" } },
  { key: "category", label: "Category", style: { minWidth: "120px", maxWidth: "120px" } },
  {
    key: "managerAccount",
    label: "Manager Account",
    style: { minWidth: "100px", maxWidth: "100px" },
  },
  {
    key: "clientAccount",
    label: "Client Account",
    style: { minWidth: "100px", maxWidth: "100px" },
  },
  { key: "accountId", label: "Account ID", style: { minWidth: "100px", maxWidth: "100px" } },
  { key: "clientId", label: "Client ID" },
  { key: "clientSecret", label: "Client Secret" },
  { key: "refreshToken", label: "RefreshToken" },
];

export default function AdAccount() {
  const dispatch = useDispatch();
  const adAccounts = useSelector(({ adsAccounts: { accounts } }) => accounts || []);
  const loading = useSelector(({ adsAccounts: { loading } }) => !!loading);

  const [modalStatus, setModalStatus] = useState("");
  const [selected, setSelected] = useState({});

  const addAccount = () => {
    setModalStatus("Add");
    setSelected({});
  };

  const editAccount = (account) => {
    setModalStatus("Edit");
    setSelected(account);
  };

  const deleteAccount = (account) => {
    if (window.confirm(`Are you sure to delete account ${account.clientAccount || account.accountId || account.id}?`))
      dispatch(adsAccounts.deleteAdsAccount(account.id));
  };

  const handlePauseAccount = (account) => {
    if (window.confirm(`Are you sure to pause account ${account.clientAccount || account.accountId || account.id}?`))
      dispatch(adsAccounts.pauseAdsAccount(account.id));
  };

  useEffect(() => {
    dispatch(adsAccounts.getAdsAccounts());
    dispatch(adsAccounts.getCategories());
  }, []);

  const headerRenderer = () => (
    <thead>
      <tr>
        {accountDataColumns.map(({ key, label, ...props }) => (
          <th key={key} {...props}>
            {label}
          </th>
        ))}
        <th style={{ minWidth: "80px", maxWidth: "80px" }}>Edit</th>
      </tr>
    </thead>
  );
  const accountRowRenderer = (account, key) => (
    <tr key={key}>
      {accountDataColumns.map(({ key: colKey, ...props }) => (
        <td key={key + colKey} {...props}>
          {account[colKey] || "—"}
        </td>
      ))}

      <td style={{ minWidth: "80px", maxWidth: "80px" }}>
        <Button
          className="mb-1 ml-1"
          size="sm"
          variant="warning"
          style={{ width: "60px" }}
          onClick={() => editAccount(account)}
        >
          Edit
        </Button>

        <Button className="mb-1 ml-1" size="sm" style={{ width: "60px" }} onClick={() => handlePauseAccount(account)}>
          Pause
        </Button>

        <Button
          className="mb-1 ml-1"
          size="sm"
          variant="danger"
          style={{ width: "60px" }}
          onClick={() => deleteAccount(account)}
        >
          Delete
        </Button>
      </td>
    </tr>
  );
  const tableBodyRenderer = () => <tbody>{adAccounts.map(accountRowRenderer)}</tbody>;

  return (
    <Container fluid className="mt-5 py-4 ads-accounts-table">
      {loading ? (
        <Spinner />
      ) : (
        <Row>
          <Col>
            <div style={{ minHeight: 40 }}>
              <Button className="pull-right mb-2" size="sm" onClick={() => addAccount()}>
                Add New
              </Button>
            </div>
            <Table responsive hover bordered striped size="sm">
              {headerRenderer()}
              {tableBodyRenderer()}
            </Table>
          </Col>
        </Row>
      )}

      <AdAccountModal
        show={!!modalStatus}
        modalstatus={modalStatus}
        selected={selected}
        onHide={() => setModalStatus("")}
      />
    </Container>
  );
}
