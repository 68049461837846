import { useState } from "react";
import { Modal, Button } from "react-bootstrap";

import { handleError, history } from "_helpers";
import { customerService } from "_services";

import Spinner from "components/Spinner";

const STEPS = {
  start: "step-start",
  loading: "step-loading",
  error: "step-error",
  success: "step-success",
};

function CreateOrderModal({ orderId, body, show = true, handleClose = () => {}, handleConfirm = () => {} }) {
  const [step, setStep] = useState(STEPS.start);
  const [newOrder, setNewOrder] = useState(null);
  const [error, setError] = useState(null);

  function resetModal() {
    handleClose();
    setError(null);
    setNewOrder(null);
    setStep(STEPS.start);
  }

  function handleCreateOrder() {
    customerService.createDelayedUpsell({ orderId, body }).then(
      (order) => {
        if (order?.products[0]?.paymentStatus === "Declined") {
          setError("Card was declined");
          setStep(STEPS.error);
        } else {
          setNewOrder(order || null);
          setStep(STEPS.success);
        }
      },
      (error) => {
        const errorMessage = handleError(error)?.message || error?.message || error?.toString() || "Unknown error";
        setError(errorMessage);
        setStep(STEPS.error);
      }
    );
  }

  switch (step) {
    case STEPS.start:
      return (
        <Modal centered size="md" show={show} onHide={resetModal}>
          <Modal.Header closeButton style={{ border: 0 }}>
            <b>Create Upsell Order</b>
          </Modal.Header>
          <Modal.Body>Are you sure you want to create new upsell order with this set of products?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={resetModal}>
              Cancel
            </Button>
            <Button
              variant="success"
              onClick={() => {
                setStep(STEPS.loading);
                handleCreateOrder();
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      );

    case STEPS.loading:
      return (
        <Modal centered size="md" backdrop="static" show={show} onHide={() => {}}>
          <Modal.Body>
            <Spinner />
          </Modal.Body>
        </Modal>
      );

    case STEPS.error:
      return (
        <Modal centered size="md" backdrop="static" show={show} onHide={resetModal}>
          <Modal.Header style={{ border: 0 }}>
            <b>Create Upsell Order</b>
          </Modal.Header>
          <Modal.Body>
            <div>
              <i className="fa fa-close text-danger" /> Error was occured during creating new upsell order.
            </div>
            <div className="mt-3">
              <b className="text-danger">Error Details:</b>
              <textarea rows={5} value={error || ""} readOnly style={{ width: "100%" }} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                // handleConfirm();
                resetModal();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      );

    case STEPS.success:
      return (
        <Modal centered size="md" backdrop="static" show={show} onHide={resetModal}>
          <Modal.Header style={{ border: 0 }}>
            <b>Create Upsell Order</b>
          </Modal.Header>
          <Modal.Body>
            <div>
              <i className="fa fa-check text-success" /> Upsell order created successfully – #
              {newOrder?.order?.crmOrderId}
            </div>
            {newOrder?.order?.id && <div className="mt-3">Do you want to go to new order page?</div>}
          </Modal.Body>
          <Modal.Footer>
            {newOrder?.order?.id ? (
              <>
                <Button
                  variant="secondary"
                  onClick={() => {
                    handleConfirm();
                    resetModal();
                  }}
                >
                  No, stay here
                </Button>
                <Button
                  variant="success"
                  onClick={() => {
                    resetModal();
                    handleConfirm();
                    history.push(`/admin/orders/${newOrder?.order?.id}`);
                  }}
                >
                  Yes, go to new order
                </Button>
              </>
            ) : (
              <Button
                variant="secondary"
                onClick={() => {
                  handleConfirm();
                  resetModal();
                  history.push(`/admin/orders/${orderId}`);
                }}
              >
                Close
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      );

    default:
      return null;
  }
}

export default CreateOrderModal;
