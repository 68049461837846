import { Row, Form } from "react-bootstrap";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import StatusView from "../Status/View";

function FormCreditCardProcessing({ orderDetails, selectedProduct }) {
  const productDetails = orderDetails?.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">Credit Card Processing</h4>

      <StatusView orderDetails={orderDetails} selectedProduct={selectedProduct} />

      <FormPDF productDetails={productDetails} />

      <hr />
      <h6>Credit Card Processing Types</h6>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Online
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.online ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          In-Person
        </Form.Label>
        <Form.Label column sm={7}>
          {productDetails?.inPerson ? "Yes" : "No"}
        </Form.Label>
      </Form.Group>
    </div>
  );
}

export default FormCreditCardProcessing;
