import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import moment from "moment-timezone";

import { adminActions, variantsActions } from "_actions";

import AdvertisingFilters from "./Filters";
import AdvertisingTable from "./Table";

function Advertising() {
  const dispatch = useDispatch();

  const [date, setDate] = useState({ from: moment().format("YYYY-MM-DD"), to: moment().format("YYYY-MM-DD") });
  const [category, setCategory] = useState("");
  const [includeRecurring, setIncludeRecurring] = useState(false);

  const categories = useSelector(({ variants }) => variants?.categories?.values || []);

  const handleGetData = () =>
    dispatch(adminActions.adsreport({ startDate: date.from, endDate: date.to, category, includeRecurring }));

  useEffect(() => {
    dispatch(variantsActions.getProductCategories());
  }, []);

  return (
    <Container fluid className="mt-5 py-4">
      <AdvertisingFilters
        date={date}
        category={category}
        includeRecurring={includeRecurring}
        categories={categories}
        onDateChange={setDate}
        onCategoryChange={setCategory}
        onIncludeRecurringChange={setIncludeRecurring}
        onApply={handleGetData}
      />
      <AdvertisingTable />
    </Container>
  );
}

export default Advertising;
