import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row } from "react-bootstrap";

import { customerActions } from "_actions";

export default function ActivityIncView({ productDetails }) {
  const dispatch = useDispatch();
  const { primaryactivity } = useSelector((state) => state);

  useEffect(() => {
    dispatch(customerActions.getprimaryactivity(productDetails?.code?.category));
  }, []);

  if (!primaryactivity) return null;

  const getExtraQuestion = (object, value) => {
    for (let i = 0; i < object.length; i++) {
      const item = object[i];

      if (item.code === value) return item;
    }

    return null;
  };

  const extraQuestions = (data, depth) => {
    if (!data) return null;

    return (
      <>
        {data.labelForDescriptionField && (
          <>
            <Form.Label column sm={5}>
              {data.labelForDescriptionField}
            </Form.Label>
            <Form.Label column sm={7}>
              {productDetails.productDescription}
            </Form.Label>
          </>
        )}
        {data.extraQuestionOptions.length > 0 && productDetails["productExtraQuestion" + depth] && (
          <>
            <Form.Label column sm={5}>
              {data.extraQuestion}
            </Form.Label>
            <Form.Label column sm={7}>
              {getExtraQuestion(data.extraQuestionOptions, productDetails["productExtraQuestion" + depth]).label}
            </Form.Label>
            {extraQuestions(
              getExtraQuestion(data.extraQuestionOptions, productDetails["productExtraQuestion" + depth]),
              depth + 1
            )}
          </>
        )}
      </>
    );
  };

  const findElement = (element) => element.code === productDetails.activityPrimaryActivity;

  const findProduct = (element) => element.code === productDetails.activitySpecificProducts;

  const productActivity = primaryactivity.find(findElement);

  let specificProduct = null;

  if (productActivity && productActivity?.specificProducts)
    specificProduct = productActivity?.specificProducts.find(findProduct);

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Primary Activity
        </Form.Label>
        <Form.Label column sm={7}>
          {productActivity ? productActivity?.label : productDetails.activityPrimaryActivity || "—"}
        </Form.Label>
      </Form.Group>

      {productActivity && (
        <Form.Group as={Row}>
          {productActivity?.labelForDescriptionField && (
            <>
              <Form.Label column sm={5}>
                {productActivity?.labelForDescriptionField}
              </Form.Label>
              <Form.Label column sm={7}>
                {productDetails.productDescription}
              </Form.Label>
            </>
          )}
          {productActivity?.specificProducts.length > 0 && (
            <>
              <Form.Label column sm={5}>
                {productActivity?.specificProductsLabel || "Specific Products/Services"}
              </Form.Label>
              <Form.Label column sm={7}>
                {specificProduct ? specificProduct.label : productDetails.activitySpecificProducts}
              </Form.Label>

              {specificProduct && <>{extraQuestions(specificProduct, 1)}</>}
            </>
          )}
        </Form.Group>
      )}
    </>
  );
}
