import { useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { reFileAbilityArray, rejectionReasonArray } from "variables/Variables";
import { checkIsBOI, getProductFilesArray } from "_helpers";
import { alertActions } from "_actions";

import IncorporatedDateMissingModal from "components/IncorporatedDateMissingModal";
import LeadWarningModal from "components/LeadWarningModal";
import ChangePendingStatusModal from "components/ChangePendingStatusModal";
import DateEdit from "./Date/Edit";

export default function EditStatus({ orderDetails, selectedProduct, onChange, onError }) {
  const dispatch = useDispatch();

  const [showIncorporatedDateModal, setShowIncorporatedDateModal] = useState(false);
  const [showChangePendingStatusModal, setShowChangePendingStatusModal] = useState(false);
  const [leadWarningNextStatus, setLeadWarningNextStatus] = useState("");

  const orderdata = useSelector(({ customers: { orderdata } }) => orderdata);
  const variants = useSelector(({ variants }) => variants);

  const prevSelectedProductStatus = orderdata?.products[selectedProduct]?.processingResult?.status;
  const hasMain = orderDetails?.products.some((product) => product.code.main);
  const mainProductIndex = hasMain ? orderDetails.products.findIndex((product) => product.code.main) : 0;
  const productDetails = orderDetails?.products[selectedProduct];
  const mainProductDetails = orderDetails?.products[mainProductIndex];
  const uploadedFileMap = getProductFilesArray(productDetails);

  const isBOIProduct = checkIsBOI(productDetails?.code?.code);
  const isBOITranscriptIncluded = uploadedFileMap?.some(({ type }) => type === "BOI_TRANSCRIPT");

  const einLlcProduct = orderDetails?.products?.find((product) => "EIN_LLC" === product?.code?.code);

  const incorporateEinProduct = orderDetails?.products?.find(
    (product) => "EIN_LLCIncorporation" === product?.code?.code
  );

  const incorporateLlcProduct = orderDetails?.products?.find(
    (product) => "INC_LLCIncorporation" === product?.code?.code
  );

  const incorporateStatesLlcProduct = orderDetails?.products?.find(
    (product) => "INC_States_LLCIncorporation" === product?.code?.code
  );

  const incorporateStatesLlcProductV2 = orderDetails?.products?.find(
    (product) => "INC_States_LLCIncorporationV2" === product?.code?.code
  );

  const productsProcessingStatuses =
    variants?.productsProcessingStatuses[productDetails?.code?.code]?.filter(({ code }) => {
      if (code === "MissingWebFile") {
        return (
          productDetails?.state === "TX" ||
          productDetails?.organizedState === "TX" ||
          mainProductDetails?.state === "TX" ||
          mainProductDetails?.organizedState === "TX"
        );
      }

      return true;
    }) || [];

  const processingErrorsOpt = variants.allProcessingErrors[productDetails?.code?.category];
  const processingErrorStatusesOpt = variants.allProcessingErrorStatuses[productDetails?.code?.category];
  const processingSubStatuses = variants.processingSubStatusByStatusCode[productDetails?.processingResult?.status];

  const sanitizedProcessingErrorsOpt = processingErrorsOpt?.filter(({ code }) => {
    const isTexasState =
      productDetails?.state === "TX" ||
      productDetails?.organizedState === "TX" ||
      mainProductDetails?.state === "TX" ||
      mainProductDetails?.organizedState === "TX";

    if (!isTexasState && code === "WebfileMissing") {
      return false;
    }

    return true;
  });

  const handleProductStatusChange = ({ target: { name, value, extraProductsData = {}, confirmed = false } }) => {
    if (name === "status" && value === "Completed" && isBOIProduct && !isBOITranscriptIncluded) {
      dispatch(alertActions.error(`Please upload the Transcript first before setting status to "Completed"`));
      return;
    }

    if (name === "status" && prevSelectedProductStatus === "Lead" && !confirmed) {
      setLeadWarningNextStatus(value);
      return;
    }

    if (name === "status" && value === "Pending") {
      if (
        !Object.keys(extraProductsData)?.includes(productDetails?.code?.code) &&
        !productDetails?.processingResult?.pendingReason
      ) {
        setShowChangePendingStatusModal(true);
        return;
      }
    }

    if (!showIncorporatedDateModal && name === "status" && value === "Completed") {
      if (productDetails?.code?.code === einLlcProduct?.code?.code) {
        if (
          incorporateEinProduct &&
          !incorporateEinProduct?.incorporatedDate &&
          !productDetails?.processingResult?.logOfStatuses?.some((item) => item?.status === "SubmittedToState")
        ) {
          setShowIncorporatedDateModal(true);
          return;
        }
      }

      if (productDetails?.code?.code === incorporateEinProduct?.code?.code && einLlcProduct) {
        if (
          !incorporateEinProduct?.incorporatedDate &&
          !incorporateEinProduct?.processingResult?.logOfStatuses?.some((item) => item?.status === "SubmittedToState")
        ) {
          setShowIncorporatedDateModal(true);
          return;
        }
      }

      if (productDetails?.code?.code === incorporateEinProduct?.code?.code && !einLlcProduct) {
        if (
          !incorporateEinProduct?.incorporatedDate &&
          !incorporateEinProduct?.processingResult?.logOfStatuses?.some((item) => item?.status === "SubmittedToState")
        ) {
          setShowIncorporatedDateModal(true);
          return;
        }
      }

      if (productDetails?.code?.code === incorporateLlcProduct?.code?.code) {
        if (
          !incorporateLlcProduct?.incorporatedDate &&
          !incorporateLlcProduct?.processingResult?.logOfStatuses?.some((item) => item?.status === "SubmittedToState")
        ) {
          setShowIncorporatedDateModal(true);
          return;
        }
      }

      if (productDetails?.code?.code === incorporateStatesLlcProduct?.code?.code) {
        if (
          !incorporateStatesLlcProduct?.incorporatedDate &&
          !incorporateStatesLlcProduct?.processingResult?.logOfStatuses?.some(
            (item) => item?.status === "SubmittedToState"
          )
        ) {
          setShowIncorporatedDateModal(true);
          return;
        }
      }

      if (productDetails?.code?.code === incorporateStatesLlcProductV2?.code?.code) {
        if (
          !incorporateStatesLlcProductV2?.incorporatedDate &&
          !incorporateStatesLlcProductV2?.processingResult?.logOfStatuses?.some(
            (item) => item?.status === "SubmittedToState"
          )
        ) {
          setShowIncorporatedDateModal(true);
          return;
        }
      }
    }

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) => {
        // if (idx !== selectedProduct) return product;

        const newData = {};
        let newProductData = {};
        let newProcessingData = {};

        if (idx === selectedProduct) {
          newData[name] = value;

          if (name === "status" && value === "Rejected") {
            newProductData = {
              rejectionReason: rejectionReasonArray[Object.keys(rejectionReasonArray)[0]]?.value || null,
              reFileAbility: reFileAbilityArray[Object.keys(reFileAbilityArray)[0]]?.value || null,
            };
          }

          // if (name === "status" && value !== "Rejected") {
          //   newProductData = {
          //     rejectionReason: null,
          //     reFileAbility: null,
          //   };
          // }

          if (["rejectionReason", "reFileAbility"].includes(name)) {
            newProductData = { ...newData };
          }

          if (name === "rejectionReason" && value === "MISC") {
            newProductData = { ...newData, reFileAbility: null };
          }

          if (name === "rejectionReason" && value === "ALTERNATIVE_NAME") {
            newProductData = {
              ...newData,
              reFileAbility: reFileAbilityArray[Object.keys(reFileAbilityArray)[0]]?.value || null,
            };
          }

          if (name === "status" && value === "Error") {
            newProcessingData = {
              ...newData,
              errorStatus: "VerificationRequired",
              processingError: "Misc",
            };
          }

          if (name === "status" && value !== "Error") {
            newProcessingData = { ...newData, errorStatus: null, processingError: null };
          }

          if (name === "subStatus") {
            if (!value || value === "") {
              newProcessingData = { ...newData, subStatus: null };
            } else {
              newProcessingData = { ...newData };
            }
          }

          if (name === "processingError") {
            if (!value || value === "") {
              newProcessingData = { ...newData, processingError: null, errorStatus: null };
            } else {
              newProcessingData = { ...newData };
            }
          }

          if (name === "errorStatus") {
            if (!value || value === "") {
              newProcessingData = { ...newData, processingError: null, errorStatus: null };
            } else {
              newProcessingData = { ...newData };
            }
          }

          if (name === "pendingReason") {
            if (!value || value === "") {
              newProcessingData = { ...newData, pendingReason: null };
            } else {
              newProcessingData = { ...newData };
            }
          }

          if (name === "dateToChangeStatus") {
            if (!value || value === "") {
              newProcessingData = { ...newData, dateToChangeStatus: null };
            } else {
              newProcessingData = { ...newData };
            }
          }

          if (name === "pendingStatus") {
            if (!value || value === "") {
              newProcessingData = { ...newData, pendingStatus: null };
            } else {
              newProcessingData = { ...newData };
            }
          }
        }

        if (idx !== selectedProduct || Object.keys(extraProductsData)?.includes(product?.code?.code)) {
          const extraFields = extraProductsData[product?.code?.code] || {};
          const extraFieldsKeys = Object.keys(extraFields) || [];
          const extraFieldsObj = { ...extraFields };

          if (extraFieldsKeys.length) {
            if (extraFieldsKeys.includes("processingResult")) {
              newProcessingData = { ...newProcessingData, ...extraFields.processingResult };
              delete extraFieldsObj.processingResult;
            }

            newProductData = { ...newProductData, ...extraFieldsObj };
          }
        }

        return {
          ...product,
          ...newProductData,
          processingResult: { ...product.processingResult, ...newProcessingData },
        };
      }),
    });
  };

  return (
    <>
      <hr />
      <h6 className="font-weight-bold">Status</h6>

      <Form.Group as={Row}>
        <Form.Label column sm="5">
          Filing Status:
        </Form.Label>
        <Col sm="7">
          <Form.Control
            size="sm"
            as="select"
            name="status"
            value={productDetails.processingResult.status}
            onChange={handleProductStatusChange}
          >
            {productsProcessingStatuses?.map(({ code, value }) => (
              <option key={code} value={code}>
                {value}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      {productDetails?.processingResult?.status === "Pending" &&
        (productDetails.processingResult.pendingReason ||
          productDetails.processingResult.dateToChangeStatus ||
          productDetails.processingResult.pendingStatus) && (
          <>
            <Form.Group as={Row}>
              <Form.Label column sm="5">
                Pending:
              </Form.Label>
              <Form.Label column sm="7">
                <Form.Control
                  required
                  name="pendingReason"
                  placeholder="Enter reason"
                  value={productDetails.processingResult.pendingReason || ""}
                  onChange={handleProductStatusChange}
                  onInvalid={() => onError && onError("Pending Reason")}
                />
                <Form.Control.Feedback type="invalid">Please, enter reason of pending</Form.Control.Feedback>
              </Form.Label>
            </Form.Group>
            <DateEdit
              name="dateToChangeStatus"
              label="Status Will Change On:"
              date={productDetails.processingResult.dateToChangeStatus || ""}
              onChange={handleProductStatusChange}
              onInvalid={() => onError && onError("Status Will Change On")}
            />
            <Form.Group as={Row}>
              <Form.Label column sm="5">
                Status Will Change To:
              </Form.Label>
              <Col sm="7">
                <Form.Control
                  required
                  size="sm"
                  as="select"
                  name="pendingStatus"
                  value={productDetails.processingResult.pendingStatus || ""}
                  onChange={handleProductStatusChange}
                  onInvalid={() => onError && onError("Status Will Change To")}
                >
                  {productsProcessingStatuses
                    ?.filter(({ code }) => code !== "Pending" && code !== "Lead" && code !== "Error")
                    ?.filter(({ code }) => {
                      if (code === "Rejected") {
                        return ![
                          "INC_LLCIncorporation",
                          "INC_LLCIncorporationV2",
                          "INC_LLCIncorporationV3",
                          "INC_States_LLCIncorporation",
                          "INC_States_LLCIncorporationV2",
                          "INC_States_LLCIncorporationV3",
                          "EIN_LLCIncorporation",
                          "EIN_LLCIncorporationV2",
                          "EIN_LLCIncorporationV3",
                        ].includes(productDetails?.code?.code);
                      }

                      return true;
                    })
                    ?.map(({ code, value }) => (
                      <option key={code} value={code}>
                        {value}
                      </option>
                    ))}
                </Form.Control>
              </Col>
            </Form.Group>
          </>
        )}

      {productDetails?.processingResult?.status === "Hold" && (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm="5">
              Hold Subcategory:
            </Form.Label>
            <Form.Label column sm="7">
              <Form.Control
                size="sm"
                as="select"
                name="subStatus"
                value={productDetails.processingResult.subStatus || ""}
                onChange={handleProductStatusChange}
              >
                <option value="">N/A</option>
                {processingSubStatuses?.map(({ code, value }) => (
                  <option key={code} value={code}>
                    {value}
                  </option>
                ))}
              </Form.Control>
            </Form.Label>
          </Form.Group>
        </>
      )}

      {productDetails?.processingResult?.status === "ReadyToFile" && (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm="5">
              Ready To File Subcategory:
            </Form.Label>
            <Form.Label column sm="7">
              <Form.Control
                size="sm"
                as="select"
                name="subStatus"
                value={productDetails.processingResult.subStatus || ""}
                onChange={handleProductStatusChange}
              >
                <option value="">N/A</option>
                {processingSubStatuses?.map(({ code, value }) => (
                  <option key={code} value={code}>
                    {value}
                  </option>
                ))}
              </Form.Control>
            </Form.Label>
          </Form.Group>
        </>
      )}

      {(productDetails.processingResult.status === "Error" || productDetails.processingResult.processingError) && (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm="5">
              EIN Error:
            </Form.Label>
            <Col sm="7">
              <Form.Control
                required
                size="sm"
                as="select"
                name="processingError"
                value={productDetails.processingResult.processingError || ""}
                onChange={handleProductStatusChange}
              >
                <option value="">Select option...</option>
                {sanitizedProcessingErrorsOpt.map(({ code, value }) => (
                  <option key={code} value={code}>
                    {value}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="5">
              EIN Error Status:
            </Form.Label>
            <Col sm="7">
              <Form.Control
                size="sm"
                as="select"
                name="errorStatus"
                value={productDetails.processingResult.errorStatus}
                onChange={handleProductStatusChange}
              >
                {processingErrorStatusesOpt.map(({ code, value }) => (
                  <option key={code} value={code}>
                    {value}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        </>
      )}

      {productDetails?.processingResult?.status === "Rejected" && (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm="5">
              Rejection Reason:
            </Form.Label>
            <Col sm="7">
              <Form.Control
                required
                size="sm"
                as="select"
                name="rejectionReason"
                value={productDetails?.rejectionReason || ""}
                onChange={handleProductStatusChange}
              >
                {Object.keys(rejectionReasonArray)?.map((key) => (
                  <option key={`rejection-reason-option-${key}`} value={rejectionReasonArray[key]?.value}>
                    {rejectionReasonArray[key]?.title}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>

          {productDetails?.rejectionReason !== "MISC" && (
            <Form.Group as={Row}>
              <Form.Label column sm="5">
                Re-File Ability:
              </Form.Label>
              <Col sm="7">
                <Form.Control
                  size="sm"
                  as="select"
                  name="reFileAbility"
                  value={productDetails?.reFileAbility || ""}
                  onChange={handleProductStatusChange}
                >
                  {Object.keys(reFileAbilityArray)?.map((key) => (
                    <option key={`re-file-ability-option-${key}`} value={reFileAbilityArray[key]?.value}>
                      {reFileAbilityArray[key]?.title}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>
          )}
        </>
      )}

      {showIncorporatedDateModal && (
        <IncorporatedDateMissingModal
          show={showIncorporatedDateModal}
          handleClose={() => {
            setShowIncorporatedDateModal(false);
          }}
          handleConfirm={(date) => {
            const newObj = {
              target: { name: "status", value: "Completed" },
            };

            if (productDetails?.code?.code === einLlcProduct?.code?.code && incorporateEinProduct) {
              newObj.target.extraProductsData = { [incorporateEinProduct.code.code]: { incorporatedDate: date } };
            }
            if (productDetails?.code?.code === incorporateEinProduct?.code?.code && !einLlcProduct) {
              newObj.target.extraProductsData = { [incorporateEinProduct.code.code]: { incorporatedDate: date } };
            }
            if (productDetails?.code?.code === incorporateEinProduct?.code?.code && einLlcProduct) {
              newObj.target.extraProductsData = { [incorporateEinProduct.code.code]: { incorporatedDate: date } };
            }
            if (productDetails?.code?.code === incorporateLlcProduct?.code?.code) {
              newObj.target.extraProductsData = { [incorporateLlcProduct.code.code]: { incorporatedDate: date } };
            }
            if (productDetails?.code?.code === incorporateStatesLlcProduct?.code?.code) {
              newObj.target.extraProductsData = { [incorporateStatesLlcProduct.code.code]: { incorporatedDate: date } };
            }
            if (productDetails?.code?.code === incorporateStatesLlcProductV2?.code?.code) {
              newObj.target.extraProductsData = {
                [incorporateStatesLlcProductV2.code.code]: { incorporatedDate: date },
              };
            }

            handleProductStatusChange(newObj);
            setShowIncorporatedDateModal(false);
          }}
        />
      )}

      {showChangePendingStatusModal && (
        <ChangePendingStatusModal
          show={showChangePendingStatusModal}
          processingStatuses={productsProcessingStatuses}
          productDetails={productDetails}
          handleClose={() => {
            setShowChangePendingStatusModal(false);
          }}
          handleConfirm={(data) => {
            const newObj = {
              target: { name: "status", value: "Pending", confirmed: true },
            };

            if (productDetails?.code?.code) {
              newObj.target.extraProductsData = { [productDetails.code.code]: { processingResult: { ...data } } };
            }

            handleProductStatusChange(newObj);
            setShowChangePendingStatusModal(false);
          }}
        />
      )}

      <LeadWarningModal
        show={Boolean(leadWarningNextStatus)}
        handleClose={() => {
          setLeadWarningNextStatus("");
        }}
        handleConfirm={() => {
          const newObj = {
            target: { name: "status", value: leadWarningNextStatus, confirmed: true },
          };

          setLeadWarningNextStatus("");
          handleProductStatusChange(newObj);
        }}
      />
    </>
  );
}
