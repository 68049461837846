import { consumerLogsConstants } from "_constants";
import { consumerLogsService } from "_services";
import { alertActions } from "./";

export const consumerLogsActions = { get, getTypes };

function get(from, to, type, body, error) {
  return (dispatch) => {
    dispatch(request());

    consumerLogsService.get({ from, to, type, body, error }).then(
      (result) => dispatch(success(result)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: consumerLogsConstants.GET_ALL_REQUEST };
  }
  function success(payload) {
    return { type: consumerLogsConstants.GET_ALL_SUCCESS, payload };
  }
  function failure() {
    return { type: consumerLogsConstants.GET_ALL_FAILURE };
  }
}

function getTypes() {
  return (dispatch) => {
    dispatch(request());

    consumerLogsService.getTypes().then(
      (result) => dispatch(success(result)),
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: consumerLogsConstants.GET_TYPE_REQUEST };
  }
  function success(payload) {
    return { type: consumerLogsConstants.GET_TYPE_SUCCESS, payload };
  }
  function failure() {
    return { type: consumerLogsConstants.GET_TYPE_FAILURE };
  }
}
