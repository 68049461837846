import { healthcheckConstants } from "../_constants";
import { healthcheckService } from "../_services";
import { alertActions } from "./";

export const healthcheckActions = {
  getBackendHealth,
  getFrontendHealth,
  getAllHealth,
  restartService,
};

function getAllHealth(ping_threshold) {
  return (dispatch) => {
    dispatch(request());

    healthcheckService.getHealth({ ping_threshold }).then(
      (result) => dispatch(success(result)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: healthcheckConstants.GET_ALL_HEALTH_REQUEST };
  }
  function success(result) {
    return { type: healthcheckConstants.GET_ALL_HEALTH_SUCCESS, result };
  }
  function failure(error) {
    return { type: healthcheckConstants.GET_ALL_HEALTH_FAILURE, error };
  }
}

function getBackendHealth(ping_threshold) {
  return (dispatch) => {
    dispatch(request());

    healthcheckService.getHealth({ ping_threshold, type: "BACKEND" }).then(
      (result) => dispatch(success(result)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: healthcheckConstants.GET_BACKEND_HEALTH_REQUEST };
  }
  function success(result) {
    return { type: healthcheckConstants.GET_BACKEND_HEALTH_SUCCESS, result };
  }
  function failure(error) {
    return { type: healthcheckConstants.GET_BACKEND_HEALTH_FAILURE, error };
  }
}

function getFrontendHealth(ping_threshold) {
  return (dispatch) => {
    dispatch(request());

    healthcheckService.getHealth({ ping_threshold, type: "FRONTEND", groupBy: "PROJECT_NAME" }).then(
      (result) => dispatch(success(result)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: healthcheckConstants.GET_FRONTEND_HEALTH_REQUEST };
  }
  function success(result) {
    return { type: healthcheckConstants.GET_FRONTEND_HEALTH_SUCCESS, result };
  }
  function failure(error) {
    return { type: healthcheckConstants.GET_FRONTEND_HEALTH_FAILURE, error };
  }
}

function restartService(name) {
  return (dispatch) => {
    healthcheckService.restartService(name).then(
      (result) => {
        dispatch(alertActions.success("Successfully restarted."));
      },
      (error) => dispatch(failure(error.toString()))
    );
  };
}
