import { config } from "_config";
import { authHeader, handleResponse } from "_helpers";

export const userlogsService = {
  getAll,
  exportcsv,
};

function getAll(start_date, end_date, http_mothod, uri, payload, Ipaddress, responseCode, username) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.healthcheckurl}/healthcheck/user-action-logs?from=${start_date}&to=${end_date}`;

  if (http_mothod !== "") {
    url += `&httpMethod=${http_mothod}`;
  }
  if (uri !== "") {
    url += `&URI=${uri}`;
  }
  if (payload !== "") {
    url += `&payload=${payload}`;
  }
  if (Ipaddress !== "") {
    url += `&IP=${Ipaddress}`;
  }
  if (responseCode !== "") {
    url += `&responseCode=${responseCode}`;
  }
  if (username !== "") {
    url += `&username=${username}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function exportcsv(start_date, end_date, http_mothod, uri, payload, Ipaddress, responseCode, username) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let url = `${config.healthcheckurl}/healthcheck/user-action-logs/csv?from=${start_date}&to=${end_date}`;

  if (http_mothod !== "") {
    url += `&httpMethod=${http_mothod}`;
  }
  if (uri !== "") {
    url += `&URI=${uri}`;
  }
  if (payload !== "") {
    url += `&payload=${payload}`;
  }
  if (Ipaddress !== "") {
    url += `&IP=${Ipaddress}`;
  }
  if (responseCode !== "") {
    url += `&responseCode=${responseCode}`;
  }
  if (username !== "") {
    url += `&username=${username}`;
  }

  return fetch(url, requestOptions).then(filehandle);
}

function filehandle(response) {
  response.blob().then((blob) => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    const file_name = "userlogs.csv";
    link.setAttribute("download", file_name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  });

  return true;
}
