import { useSelector } from "react-redux";
import { Row, Form } from "react-bootstrap";

import { combineFromCategories } from "_helpers";
import { reFileAbilityArray, rejectionReasonArray } from "variables/Variables";
import DateView from "./Date/View";

export default function ViewStatus({ orderDetails, selectedProduct }) {
  const { processingSubStatusOpt, productsProcessingStatusesOpt, ...variants } = useSelector(
    ({ variants }) => variants
  );

  const processingErrorsOpt = combineFromCategories(variants.allProcessingErrors);
  const processingErrorStatusOpt = combineFromCategories(variants.allProcessingErrorStatuses);

  const productDetails = orderDetails?.products[selectedProduct];

  const currentStatusObj = processingSubStatusOpt[productDetails?.processingResult?.status] || {};
  const currentSubStatus = currentStatusObj[productDetails?.processingResult?.subStatus] || "N/A";

  return (
    <>
      <hr />
      <h6 className="font-weight-bold">Status</h6>

      <Form.Group as={Row}>
        <Form.Label column sm="5">
          Filing Status:
        </Form.Label>
        <Form.Label column sm="7">
          {productsProcessingStatusesOpt[productDetails?.processingResult?.status]}
        </Form.Label>
      </Form.Group>

      {productDetails?.processingResult?.status === "Pending" &&
        (productDetails?.processingResult?.pendingReason ||
          productDetails?.processingResult?.dateToChangeStatus ||
          productDetails?.processingResult?.pendingStatus) && (
          <>
            <Form.Group as={Row}>
              <Form.Label column sm="5">
                Pending:
              </Form.Label>
              <Form.Label column sm="7">
                {productDetails.processingResult.pendingReason}
              </Form.Label>
            </Form.Group>
            <DateView label="Status Will Change On:" date={productDetails.processingResult.dateToChangeStatus} />
            <Form.Group as={Row}>
              <Form.Label column sm="5">
                Status Will Change To:
              </Form.Label>
              <Form.Label column sm="7">
                {productDetails.processingResult.pendingStatus}
              </Form.Label>
            </Form.Group>
          </>
        )}

      {productDetails?.processingResult?.status === "Hold" && (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm="5">
              Hold Subcategory:
            </Form.Label>
            <Form.Label column sm="7">
              {currentSubStatus}
            </Form.Label>
          </Form.Group>
        </>
      )}

      {productDetails?.processingResult?.status === "ReadyToFile" && (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm="5">
              Ready To File Subcategory:
            </Form.Label>
            <Form.Label column sm="7">
              {currentSubStatus}
            </Form.Label>
          </Form.Group>
        </>
      )}

      {productDetails?.processingResult?.status === "Error" && (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm="5">
              EIN Error:
            </Form.Label>
            <Form.Label column sm="7">
              {processingErrorsOpt[productDetails?.processingResult?.processingError]}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="5">
              EIN Error Status:
            </Form.Label>
            <Form.Label column sm="7">
              {processingErrorStatusOpt[productDetails?.processingResult?.errorStatus]}
            </Form.Label>
          </Form.Group>
        </>
      )}

      {productDetails?.processingResult?.status === "Rejected" && (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm="5">
              Rejection Reason:
            </Form.Label>
            <Form.Label column sm="7">
              {rejectionReasonArray[productDetails?.rejectionReason]?.title || "–"}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="5">
              Re-File Ability:
            </Form.Label>
            <Form.Label column sm="7">
              {reFileAbilityArray[productDetails?.reFileAbility]?.title || "–"}
            </Form.Label>
          </Form.Group>
        </>
      )}
    </>
  );
}
