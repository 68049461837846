import { useState, useRef, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { offerGroupsActions, alertActions } from "_actions";

export default function CreateUpdateGroupOfferModal({show, handleClose, group, onEditSuccess}) {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [validated, setValidated] = useState(false);
  const formEl = useRef(null);
  
  const handleChange = (e) => {
    setName(e.target.value);
  }

  const addGroup = () => {
    dispatch(offerGroupsActions.addGroup({
      name
    })).then(() => {
      dispatch(offerGroupsActions.getAll());
      dispatch(alertActions.success('Create group success'));
      setName('');
      setValidated(false);
      handleClose();
    }, err => {
      dispatch(alertActions.error(err.message));
      setValidated(false);
    })
  }


  const editGroup = () => {
    dispatch(offerGroupsActions.editGroup({
      id: group.id,
      name
    }, group.id)).then((newGroup) => {
      dispatch(offerGroupsActions.getAll());
      dispatch(alertActions.success('Create group success'));
      setName('');
      setValidated(false);
      onEditSuccess(newGroup);
      handleClose();
    }, err => {
      dispatch(alertActions.error(err.message));
      setValidated(false);
    });
  }

  const handleSubmit = (e) => {
    const form = formEl.current;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      group ? editGroup() : addGroup();
    }
  }

  useEffect(() => {
    setName(group ? group.name : '');
  }, [group]);

  return (
    <Modal show={show} onHide={handleClose} size="md" centered>
      <Modal.Header closeButton>
        <div>{group ? `Edit group ${group.name}` : `Add new group`}</div>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} ref={formEl}>
          <Form.Group>
            <Form.Label>Group Name</Form.Label>
            <Form.Control 
              required
              value={name}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
              Group Name is required
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
