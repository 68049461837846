import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Table, Col, Form, Row, Button } from "react-bootstrap";
import moment from "moment-timezone";

import { customerActions } from "_actions";

export default function Notes({ orderDetails }) {
  const { orderId } = useParams();

  const dispatch = useDispatch();

  const [message, setMessage] = useState("");
  const [customerContactRequired, setCustomerContactRequired] = useState(false);
  const [notes, setNotes] = useState(orderDetails.order.notes ? orderDetails.order.notes : []);

  const handleSaveNote = () => {
    const newNote = { message, customerContactRequired };
    setMessage("");

    dispatch(customerActions.addNote(orderId, newNote));
  };

  useEffect(() => {
    setNotes(orderDetails.order.notes ? orderDetails.order.notes : []);
  }, [orderDetails]);

  return (
    <div className="order-info form-border fixed-height notes">
      <Table striped bordered responsive size="sm">
        <thead>
          <tr>
            <th>Date</th>
            <th>Username</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {notes &&
            notes.map((prop, key) => (
              <tr key={key}>
                <td>{moment.utc(prop.date).local().format("YYYY-MM-DD HH:mm:ss")}</td>
                <td>{prop.username}</td>
                <td>
                  <p>{prop.message}</p>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      <Row>
        <Form.Group as={Col} className="mt-1">
          <Form.Label className="m-0">Add a Note</Form.Label>
          <Form.Control as="textarea" rows={3} value={message} onChange={(e) => setMessage(e.target.value)} />
          <Form.Check
            id="notes-customerContactRequired"
            className="py-2"
            type="checkbox"
            label="Customer Contact Required"
            name="notes-customerContactRequired"
            checked={customerContactRequired}
            onChange={({ target: { checked } }) => setCustomerContactRequired(checked)}
          />
          <Button size="sm" onClick={handleSaveNote} disabled={message === ""} className="pull-right mt-1">
            Save
          </Button>
        </Form.Group>
      </Row>
    </div>
  );
}
