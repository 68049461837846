export const includedName = {
  NONE: "None",
  OWNER_NAME: "Owner Name",
  // OWNER_NICKNAME: "Owner Nickname", // was deprecated
  OTHER_NAME: "Other Name",
  OTHER_LIVING_NAME: "Other Living Name",
};

export const trademarkUseStatus = {
  NO: "No",
  YES_ON_ALL: "Yes on all",
  YES_ON_SOME: "Yes on some",
};

export const officeActionStatus = {
  UNKNOWN_OFFICE_ACTION_TYPE: "Unknown office action type",
  PROCEDURAL_ISSUE_OFFICE_ACTION_TYPE: "Procedural issue office action type",
  REFUSED_REGISTRATION_OFFICE_ACTION_TYPE: "Refused registration office action type",
};

export const statementOfUseStatus = {
  TRADEMARK_IN_USE: "Trademark in use",
  TRADEMARK_NOT_IN_USE: "Trademark not in use",
};

export const revivalServices = {
  officeActionStatus: "Office action status",
  statementOfUseStatus: "Statement of use status",
};
