import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Form } from 'react-bootstrap';
import { zendeskactions } from '_actions';
import { WithContext as ReactTags } from 'react-tag-input';

function EditAccount(props) {
  const dispatch = useDispatch();
  const { accountdata, status } = props;
  const [account, setAccount] = useState(accountdata);
  const [tags, setTags] = useState([]);

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const onchange = (e) => {
    const { name, value } = e.target;
    setAccount({ ...account, [name]: value });
  };

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    if (tag.text.length < 4) return false;
    setTags([...tags, tag]);
  };

  const onsubmit = (e) => {
    e.preventDefault();
    dispatch(zendeskactions.update_zendesk_account(account, status));
    props.onHide();
  };

  useEffect(() => {
    setAccount(accountdata);
    let tagarray = [];
    if (accountdata) {
      accountdata.keywords.map((item, key) => {
        tagarray.push({
          id: item,
          text: item,
        });
      });
    }
    setTags(tagarray);
  }, [accountdata]);

  useEffect(() => {
    let result = tags.map((a) => a.text);
    setAccount({ ...account, keywords: result });
  }, [tags]);

  if (!account) {
    return <></>;
  }

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onsubmit}>
          <Form.Group>
            <Form.Label>Url</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Url"
              required
              name="url"
              value={account.url ?? ''}
              onChange={(e) => onchange(e)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email"
              required
              name="login"
              value={account.login ?? ''}
              onChange={(e) => onchange(e)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              required
              name="password"
              value={account.password ?? ''}
              onChange={(e) => onchange(e)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Keyword</Form.Label>

            <ReactTags
              tags={tags}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              delimiters={delimiters}
              placeholder="Add a new keyword"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              name="status"
              value={account.status ?? ''}
              onChange={(e) => onchange(e)}
            >
              <option value="Active">Active</option>
              <option value="Disabled">Inactive</option>
            </Form.Control>
          </Form.Group>

          <Button onClick={props.onHide} className="mr-2">
            Close
          </Button>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default EditAccount;
