import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Table, Card, Form } from "react-bootstrap";
import styled, { css } from "styled-components";
import moment from "moment-timezone";

import { reportsClicksActions } from "_actions";
import { currencyFormatter } from "_helpers";

import Spinner from "components/Spinner";
import Button from "components/Button";

const StyledTable = styled(Table)`
  margin-bottom: 0;
`;

const TableRow = styled.tr`
  background-color: #dfdfdf;

  ${({ tableLayout }) =>
    tableLayout &&
    css`
      tablelayout: ${tableLayout};
    `}
`;
const TableMainRow = styled(TableRow)`
  background-color: #ffffff;
`;
const TableExpandedRow = styled(TableRow)`
  background-color: #ffffff;
`;
const CellHeader = styled.th`
  width: auto;

  ${({ width, minWidth }) => css`
    width: ${width};
    min-width: ${minWidth ? minWidth : 0};
  `}
`;
const Cell = styled.td`
  ${({ green }) =>
    green &&
    css`
      color: var(--green);
    `}

  ${({ red }) =>
    red &&
    css`
      color: var(--red);
    `}

    ${({ empty }) =>
    empty &&
    css`
      background-color: #f7f7f7;
      border-bottom: 1px solid #f7f7f7;
      color: #a5a5a5;
    `}

    ${({ borderLeft }) =>
    borderLeft &&
    css`
      border-left: 1px solid #dfdfdf;
    `}

    ${({ borderRight }) =>
    borderRight &&
    css`
      border-right: 1px solid #dfdfdf;
    `}

    ${({ borderBottom }) =>
    borderBottom &&
    css`
      border-bottom: 1px solid #dfdfdf;
    `}

    ${({ wordBreak }) =>
    wordBreak &&
    css`
      word-break: break-all;
      white-space: normal;
      padding-right: 24px !important;
    `}
`;

export default function Clicks() {
  const dispatch = useDispatch();

  const [expandedRows, setExpandedRows] = useState([]);

  const clicks = useSelector(({ reportsClicks: { clicks } }) => clicks);
  const loading = useSelector(({ reportsClicks: { loading } }) => loading);

  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [search, setSearch] = useState("");

  const handleGet = () => {
    dispatch(reportsClicksActions.getAll({ from: startDate, to: endDate, search }));
    setExpandedRows([]);
  };

  useEffect(() => {
    handleGet();
  }, []);

  return (
    <>
      <Container fluid className="mt-5 py-4">
        <Card className="p-2">
          <Row>
            <Col sd={12} md={9}>
              <Form>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Start Date:</Form.Label>
                    <Form.Control
                      size="sm"
                      type="date"
                      name="from"
                      max={moment().format("YYYY-MM-DD")}
                      value={startDate}
                      onChange={({ target: { value } }) => setStartDate(value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>End Date:</Form.Label>
                    <Form.Control
                      size="sm"
                      type="date"
                      name="to"
                      max={moment().format("YYYY-MM-DD")}
                      value={endDate}
                      onChange={({ target: { value } }) => setEndDate(value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>Search:</Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="search"
                      style={{ minWidth: "120px" }}
                      value={search}
                      onChange={({ target: { value } }) => setSearch(value)}
                    />
                  </Form.Group>
                </Form.Row>
              </Form>
            </Col>

            <Form.Group as={Col} sd={12} md={3} style={{ display: "flex", alignItems: "flex-end" }}>
              <Button size="sm" className="mr-2" isLoading={loading} onClick={handleGet}>
                Filter
              </Button>
            </Form.Group>
          </Row>
        </Card>

        <Card>
          {!!loading && <Spinner />}
          {!loading && !!clicks && (
            <StyledTable responsive className="sales-by-domain-table">
              <thead>
                <TableMainRow tableLayout="fixed">
                  <CellHeader width="32px"></CellHeader>
                  <CellHeader width="auto" minWidth="300px">
                    URL
                  </CellHeader>
                  <CellHeader width="10%">Clicks</CellHeader>
                  <CellHeader width="10%">Leads</CellHeader>
                  <CellHeader width="10%">Sales</CellHeader>
                  <CellHeader width="10%">Revenue</CellHeader>
                  <CellHeader width="10%">Revenue Per Click</CellHeader>
                </TableMainRow>
              </thead>
              <tbody>
                {clicks.map(({ domain, clicks, leads, sales, revenue, revenuePerClick, urlLines }, idx) => {
                  return (
                    <Fragment key={`${domain}-${idx}`}>
                      <TableRow tableLayout="fixed">
                        <Cell>
                          {urlLines.length && expandedRows.includes(`${idx}`) ? (
                            <i
                              className="fa fa-minus"
                              onClick={() => setExpandedRows([...expandedRows.filter((id) => id !== `${idx}`)])}
                            />
                          ) : (
                            <i className="fa fa-plus" onClick={() => setExpandedRows([...expandedRows, `${idx}`])} />
                          )}
                        </Cell>
                        <Cell>{domain}</Cell>
                        <Cell>{clicks}</Cell>
                        <Cell>{leads}</Cell>
                        <Cell>{sales}</Cell>
                        <Cell green>{currencyFormatter(revenue)}</Cell>
                        <Cell green>{currencyFormatter(revenuePerClick)}</Cell>
                      </TableRow>
                      {expandedRows.includes(`${idx}`) &&
                        urlLines.map((row, idx) => (
                          <TableExpandedRow key={`${idx}-${row.url}`}>
                            <Cell empty borderRight>
                              {idx + 1}
                            </Cell>
                            <Cell wordBreak>{row.url}</Cell>
                            <Cell>{row.clicks}</Cell>
                            <Cell>{row.leads}</Cell>
                            <Cell>{row.sales}</Cell>
                            <Cell empty borderLeft />
                            <Cell empty />
                          </TableExpandedRow>
                        ))}
                    </Fragment>
                  );
                })}
                {!clicks.length && (
                  <tr>
                    <td>No Results</td>
                  </tr>
                )}
              </tbody>
            </StyledTable>
          )}
        </Card>
      </Container>
    </>
  );
}
