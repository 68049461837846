import { Row, Col, Form } from "react-bootstrap";
import moment from "moment-timezone";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import StatusEdit from "../Status/Edit";

import { suffixArray, vitalReasonOfApplying, vitalRelationship, state_array } from "variables/Variables";

const fieldsWithTime = [
  "applicantDateOfBirth",
  "mothersDateOfBirth",
  "fathersDateOfBirth",
  "dateOfBirth",
  "dateOfMarriage",
];

const fieldsAsBoolean = [
  "hasChangedNameThroughMarriage",
  "hasChangedNameByCourtOrder",
  "adopted",
  "stillLiving",
  "recordBeforeAdoption",
  "wereParentsMarriedAtTimeOfBirth",
  "fatherIsUnknown",
];

const fieldsAsEmpty = ["suffix", "mothersSuffix", "fathersSuffix"];

export default function VitalRecord({ orderDetails, selectedProduct, onError, onChange, ...props }) {
  const orderId = orderDetails.order.id;
  const productDetails = orderDetails.products[selectedProduct];
  const dateOfBirth = moment.utc(productDetails.dateOfBirth).format("YYYY-MM-DD");
  const applicantDateOfBirth = moment(productDetails.applicantDateOfBirth).format("YYYY-MM-DD");
  const mothersDateOfBirth = moment(productDetails.mothersDateOfBirth).format("YYYY-MM-DD");
  const fathersDateOfBirth = moment(productDetails.fathersDateOfBirth).format("YYYY-MM-DD");
  const dateOfMarriage = moment(productDetails.dateOfMarriage).format("YYYY-MM-DD");

  const handleProductChange = ({ target: { name, value } }) => {
    let newVal = value;

    if (fieldsWithTime.includes(name)) newVal = value + "T" + moment().format("hh:mm:ss");

    if (fieldsAsBoolean.includes(name)) newVal = value === "true";

    if (fieldsAsEmpty.includes(name) && value === "empty") newVal = null;

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, [name]: newVal } : product
      ),
    });
  };

  return (
    <div className="vital-record form-border">
      <Col sm={12}>
        <>
          <h4 className="m-0 font-weight-bold mb-2">Vital Record</h4>

          <StatusEdit orderDetails={orderDetails} selectedProduct={selectedProduct} onChange={onChange} />

          <FormPDF productDetails={productDetails} {...props} />

          <hr />
          <h6 className="font-weight-bold">Birth Information of the Person on Record</h6>

          <Form.Group as={Row}>
            <Form.Label column sm="3">
              State Of Birth:
            </Form.Label>
            <Col sm="9">
              <Form.Control
                as="select"
                name="stateOfBirth"
                required
                value={productDetails.stateOfBirth || ""}
                onChange={handleProductChange}
                onInvalid={() => onError("State of birth")}
              >
                <option value="">Select a state</option>
                {Object.keys(state_array).map((key) => (
                  <option key={key} value={key}>
                    {state_array[key]}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              First Name:
            </Form.Label>
            <Col sm="9">
              <Form.Control
                required
                name="firstName"
                type="text"
                value={productDetails.firstName || ""}
                onChange={handleProductChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Middle Name:
            </Form.Label>
            <Col sm="9">
              <Form.Control
                name="middleName"
                type="text"
                value={productDetails.middleName || ""}
                onChange={handleProductChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Last Name:
            </Form.Label>
            <Col sm="9">
              <Form.Control
                required
                name="lastName"
                type="text"
                value={productDetails.lastName || ""}
                onChange={handleProductChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Suffix:
            </Form.Label>
            <Col sm="9">
              <Form.Control
                as="select"
                name="suffix"
                value={productDetails.suffix || ""}
                onChange={handleProductChange}
              >
                <option value="empty">Please Select</option>
                {suffixArray.map((prop, key) => (
                  <option key={key} value={prop}>
                    {prop}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Gender:
            </Form.Label>
            <Col sm="9">
              <Form.Control as="select" name="gender" value={productDetails.gender} onChange={handleProductChange}>
                <option value="">Please Select</option>
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Last Name changed by marriage?
            </Form.Label>
            <Col sm={7} className="d-flex align-items-center">
              <Form.Check
                id="hasChangedNameThroughMarriage_r_yes"
                className="mr-3"
                type="radio"
                label="yes"
                name="hasChangedNameThroughMarriage"
                defaultChecked={productDetails.hasChangedNameThroughMarriage}
                value="true"
                onChange={handleProductChange}
              />
              <Form.Check
                id="hasChangedNameThroughMarriage_r_no"
                type="radio"
                label="no"
                name="hasChangedNameThroughMarriage"
                defaultChecked={!productDetails.hasChangedNameThroughMarriage}
                value="false"
                onChange={handleProductChange}
              />
            </Col>
          </Form.Group>
          {productDetails.hasChangedNameThroughMarriage && !productDetails.hasChangedNameByCourtOrder ? (
            <>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  New Middle Name:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    name="newMiddleName"
                    placeholder="New middle name"
                    type="text"
                    value={productDetails.newMiddleName || ""}
                    onChange={handleProductChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  New Last Name:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    required
                    name="newLastName"
                    placeholder="New last name"
                    type="text"
                    value={productDetails.newLastName || ""}
                    onChange={handleProductChange}
                  />
                </Col>
              </Form.Group>
            </>
          ) : null}
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Last Name changed by court order?
            </Form.Label>
            <Col sm={7} className="d-flex align-items-center">
              <Form.Check
                id="hasChangedNameByCourtOrder_r_yes"
                className="mr-3"
                type="radio"
                label="yes"
                name="hasChangedNameByCourtOrder"
                defaultChecked={productDetails.hasChangedNameByCourtOrder}
                value="true"
                onChange={handleProductChange}
              />
              <Form.Check
                id="hasChangedNameByCourtOrder_r_no"
                type="radio"
                label="no"
                name="hasChangedNameByCourtOrder"
                defaultChecked={!productDetails.hasChangedNameByCourtOrder}
                value="false"
                onChange={handleProductChange}
              />
            </Col>
          </Form.Group>
          {productDetails.hasChangedNameByCourtOrder ? (
            <>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  New First Name:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    required
                    name="newFirstName"
                    type="text"
                    value={productDetails.newFirstName || ""}
                    onChange={handleProductChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  New Middle Name:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    name="newMiddleName"
                    type="text"
                    value={productDetails.newMiddleName || ""}
                    onChange={handleProductChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  New Last Name:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    required
                    name="newLastName"
                    type="text"
                    value={productDetails.newLastName || ""}
                    onChange={handleProductChange}
                  />
                </Col>
              </Form.Group>
            </>
          ) : null}

          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Date Of Birth:
            </Form.Label>
            <Col sm="9">
              <Form.Control
                required
                size="sm"
                type="date"
                name="dateOfBirth"
                max={moment().format("YYYY-MM-DD")}
                step="1"
                value={dateOfBirth}
                onChange={handleProductChange}
                onInvalid={() => onError("Date of Birth")}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm="9">
              <Form.Control type="hidden" value={productDetails.countyOfBirth} />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              City of Birth:
            </Form.Label>
            <Col sm="9">
              <Form.Control
                name="cityOfBirth"
                type="text"
                value={productDetails.cityOfBirth || ""}
                onChange={handleProductChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              County of Birth:
            </Form.Label>
            <Col sm="9">
              <Form.Control
                name="countyOfBirth"
                type="text"
                value={productDetails.countyOfBirth || ""}
                onChange={handleProductChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Hospital Name or Address of Birth:
            </Form.Label>
            <Col sm="9">
              <Form.Control
                name="nameOfHospitalOrStreetAddressOfBirth"
                type="text"
                value={productDetails.nameOfHospitalOrStreetAddressOfBirth || ""}
                onChange={handleProductChange}
              />
            </Col>
          </Form.Group>
          {productDetails.stateOfBirth === "IA" ||
          productDetails.stateOfBirth === "MN" ||
          productDetails.stateOfBirth === "VT" ? (
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Applicant Date of Birth:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  required
                  size="sm"
                  type="date"
                  name="applicantDateOfBirth"
                  max={moment().format("YYYY-MM-DD")}
                  step="1"
                  value={applicantDateOfBirth}
                  onChange={handleProductChange}
                  onInvalid={() => onError("Applicant Date of birth")}
                />
              </Col>
            </Form.Group>
          ) : null}
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Adopted?
            </Form.Label>
            <Col sm={7} className="d-flex align-items-center">
              <Form.Check
                id="adopted_r_yes"
                className="mr-3"
                type="radio"
                label="yes"
                name="adopted"
                defaultChecked={productDetails.adopted}
                value="true"
                onChange={handleProductChange}
              />
              <Form.Check
                id="adopted_r_no"
                type="radio"
                label="no"
                name="adopted"
                defaultChecked={!productDetails.adopted}
                value="false"
                onChange={handleProductChange}
              />
            </Col>
          </Form.Group>
          {productDetails.adopted ? (
            <>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Original First Name:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    required
                    name="originalFirstNameIfAdopted"
                    type="text"
                    placeholder="Original First Name"
                    value={productDetails.originalFirstNameIfAdopted || ""}
                    onChange={handleProductChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Original Middle Name:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    name="originalMiddleNameIfAdopted"
                    type="text"
                    placeholder="Original Middle Name"
                    value={productDetails.originalMiddleNameIfAdopted || ""}
                    onChange={handleProductChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Original Last Name:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    required
                    name="originalLastNameIfAdopted"
                    type="text"
                    placeholder="Original Last Name"
                    value={productDetails.originalLastNameIfAdopted || ""}
                    onChange={handleProductChange}
                  />
                </Col>
              </Form.Group>
              {productDetails.stateOfBirth === "MI" || productDetails.stateOfBirth === "ND" ? (
                <>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Adopted Mother's First Name:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        required
                        name="adoptedMothersFirstName"
                        type="text"
                        placeholder="Enter Adopted Mother's First Name"
                        value={productDetails.adoptedMothersFirstName || ""}
                        onChange={handleProductChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Adopted Mother's Middle Name:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        name="adoptedMothersMiddleName"
                        type="text"
                        placeholder="Enter Adopted Mother's Middle Name"
                        value={productDetails.adoptedMothersMiddleName || ""}
                        onChange={handleProductChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Adopted Mother's Last Name:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        required
                        name="adoptedMothersLastName"
                        type="text"
                        placeholder="Enter Adopted Mother's Last Name"
                        value={productDetails.adoptedMothersLastName || ""}
                        onChange={handleProductChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Adopted Father's First Name:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        required
                        name="adoptedFathersFirstName"
                        type="text"
                        placeholder="Enter Adopted Father's First Name"
                        value={productDetails.adoptedFathersFirstName || ""}
                        onChange={handleProductChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Adopted Father's Middle Name:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        name="adoptedFathersMiddleName"
                        type="text"
                        placeholder="Enter Adopted Mother's Middle Name"
                        value={productDetails.adoptedFathersMiddleName || ""}
                        onChange={handleProductChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Adopted Father's Last Name:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        required
                        name="adoptedFathersLastName"
                        type="text"
                        placeholder="Enter Adopted Fathers's Last Name"
                        value={productDetails.adoptedFathersLastName || ""}
                        onChange={handleProductChange}
                      />
                    </Col>
                  </Form.Group>
                </>
              ) : null}
            </>
          ) : null}
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Still Living?
            </Form.Label>
            <Col sm={7} className="d-flex align-items-center">
              <Form.Check
                id="stillLiving_r_yes"
                className="mr-3"
                type="radio"
                label="yes"
                name="stillLiving"
                defaultChecked={productDetails.stillLiving}
                value="true"
                onChange={handleProductChange}
              />
              <Form.Check
                id="stillLiving_r_no"
                type="radio"
                label="no"
                name="stillLiving"
                defaultChecked={!productDetails.stillLiving}
                value="false"
                onChange={handleProductChange}
              />
            </Col>
          </Form.Group>
          {productDetails.stateOfBirth === "GA" || productDetails.stateOfBirth === "MS" ? (
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Race:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  required
                  name="race"
                  type="text"
                  pattern="^(^[a-zA-Z]{0,12})"
                  onInvalid={() => onError("Race")}
                  placeholder="Enter Race"
                  value={productDetails.race || ""}
                  onChange={handleProductChange}
                />
              </Col>
            </Form.Group>
          ) : productDetails.stateOfBirth === "KS" ? (
            <>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Race:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    required
                    name="race"
                    type="text"
                    pattern="^(^[a-zA-Z]{0,12})"
                    onInvalid={() => onError("Race")}
                    placeholder="Enter Race"
                    value={productDetails.race || ""}
                    onChange={handleProductChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Record Before Adoption?
                </Form.Label>
                <Col sm={7} className="d-flex align-items-center">
                  <Form.Check
                    id="recordBeforeAdoption_r_yes"
                    className="mr-3"
                    type="radio"
                    label="yes"
                    name="recordBeforeAdoption"
                    defaultChecked={productDetails.recordBeforeAdoption}
                    value="true"
                    onChange={handleProductChange}
                  />
                  <Form.Check
                    id="recordBeforeAdoption_r_no"
                    type="radio"
                    label="no"
                    name="recordBeforeAdoption"
                    defaultChecked={!productDetails.recordBeforeAdoption}
                    value="false"
                    onChange={handleProductChange}
                  />
                </Col>
              </Form.Group>
            </>
          ) : null}
          <hr />
          <h6 className="font-weight-bold">Information about the parents of the person on record</h6>
          {productDetails.stateOfBirth === "MI" ? (
            <>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Place Of Marriage:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    required
                    name="placeOfMarriage"
                    type="text"
                    placeholder="Enter Place of Marriage"
                    value={productDetails.placeOfMarriage || ""}
                    onChange={handleProductChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Date of Marriage:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    size="sm"
                    type="date"
                    name="dateOfMarriage"
                    max={moment().format("YYYY-MM-DD")}
                    step="1"
                    value={dateOfMarriage || ""}
                    onChange={handleProductChange}
                  />
                </Col>
              </Form.Group>
            </>
          ) : productDetails.stateOfBirth === "NC" ||
            productDetails.stateOfBirth === "SC" ||
            productDetails.stateOfBirth === "IA" ? (
            <>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Mother's Date of Birth:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    required
                    size="sm"
                    type="datetime-local"
                    name="mothersDateOfBirth"
                    max={moment().format("YYYY-MM-DDThh:mm:ss")}
                    step="1"
                    value={mothersDateOfBirth}
                    onChange={handleProductChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Mother's State or Country Of Birth:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    required
                    name="mothersStateOrCountryOfBirth"
                    type="text"
                    placeholder="Enter State or Country"
                    value={productDetails.mothersStateOrCountryOfBirth || ""}
                    onChange={handleProductChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Father's Date Of Birth:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    required
                    size="sm"
                    type="datetime-local"
                    name="fathersDateOfBirth"
                    max={moment().format("YYYY-MM-DDThh:mm:ss")}
                    step="1"
                    value={fathersDateOfBirth}
                    onChange={handleProductChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Father's State or Country Of Birth:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    required
                    name="fathersStateOrCountryOfBirth"
                    type="text"
                    placeholder="Enter State Or Country"
                    value={productDetails.fathersStateOrCountryOfBirth || ""}
                    onChange={handleProductChange}
                  />
                </Col>
              </Form.Group>
            </>
          ) : productDetails.stateOfBirth === "NC" ||
            productDetails.stateOfBirth === "SC" ||
            productDetails.stateOfBirth === "IA" ||
            productDetails.stateOfBirth === "IA" ? (
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Were Parents Married At Time of Birth:
              </Form.Label>
              <Col sm={7} className="d-flex align-items-center">
                <Form.Check
                  id="wereParentsMarriedAtTimeOfBirth_r_yes"
                  className="mr-3"
                  type="radio"
                  label="yes"
                  name="wereParentsMarriedAtTimeOfBirth"
                  defaultChecked={productDetails.wereParentsMarriedAtTimeOfBirth}
                  value="true"
                  onChange={handleProductChange}
                />
                <Form.Check
                  id="wereParentsMarriedAtTimeOfBirth_r_no"
                  type="radio"
                  label="no"
                  name="wereParentsMarriedAtTimeOfBirth"
                  defaultChecked={!productDetails.wereParentsMarriedAtTimeOfBirth}
                  value="false"
                  onChange={handleProductChange}
                />
              </Col>
            </Form.Group>
          ) : null}
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Mother's first name:
            </Form.Label>
            <Col sm="9">
              <Form.Control
                required
                name="mothersFirstName"
                placeholder="Mother's first name"
                type="text"
                value={productDetails.mothersFirstName || ""}
                onChange={handleProductChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Mother's middle name:
            </Form.Label>
            <Col sm="9">
              <Form.Control
                name="mothersMiddleName"
                placeholder="Mother's middle name"
                type="text"
                value={productDetails.mothersMiddleName || ""}
                onChange={handleProductChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Mother's last name:
            </Form.Label>
            <Col sm="9">
              <Form.Control
                required
                name="mothersLastName"
                placeholder="Mother's last name"
                type="text"
                value={productDetails.mothersLastName || ""}
                onChange={handleProductChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Mother's maiden name if different:
            </Form.Label>
            <Col sm="9">
              <Form.Control
                name="mothersMaidenName"
                placeholder="Mother's maiden name if different"
                type="text"
                value={productDetails.mothersMaidenName || ""}
                onChange={handleProductChange}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Suffix:
            </Form.Label>
            <Col sm="9">
              <Form.Control
                as="select"
                name="mothersSuffix"
                value={productDetails.mothersSuffix || ""}
                onChange={handleProductChange}
              >
                <option value="empty">Please select</option>
                {suffixArray.map((prop, key) => (
                  <option key={key} value={prop}>
                    {prop}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
          {productDetails.stateOfBirth === "UT" ? (
            <>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Mother's date of birth:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    required
                    size="sm"
                    type="date"
                    name="mothersDateOfBirth"
                    max={moment().format("YYYY-MM-DD")}
                    step="1"
                    value={mothersDateOfBirth}
                    onChange={handleProductChange}
                    onInvalid={() => onError("Mother's Date of Birth")}
                  />
                </Col>
              </Form.Group>
            </>
          ) : productDetails.stateOfBirth === "SC" ? (
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Mother's State or Country Of Birth:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  required
                  name="mothersStateOrCountryOfBirth"
                  type="text"
                  placeholder="Mothers state (if US) or Country of birth"
                  value={productDetails.mothersStateOrCountryOfBirth || ""}
                  onChange={handleProductChange}
                />
              </Col>
            </Form.Group>
          ) : productDetails.stateOfBirth === "KS" ? (
            <>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Mother's State or Country Of Birth:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    required
                    name="mothersStateOrCountryOfBirth"
                    type="text"
                    placeholder="Mothers state (if US) or Country of birth"
                    value={productDetails.mothersStateOrCountryOfBirth || ""}
                    onChange={handleProductChange}
                    onInvalid={() => onError("Mother's State or Country of birth")}
                  />
                </Col>
              </Form.Group>
            </>
          ) : productDetails.stateOfBirth === "AZ" ? (
            <>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Mother's Date of Birth:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    required
                    size="sm"
                    type="date"
                    name="mothersDateOfBirth"
                    max={moment().format("YYYY-MM-DD")}
                    step="1"
                    value={mothersDateOfBirth}
                    onChange={handleProductChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Mother's State or Country Of Birth:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    required
                    name="mothersStateOrCountryOfBirth"
                    type="text"
                    placeholder="Mothers state (if US) or Country of birth"
                    value={productDetails.mothersStateOrCountryOfBirth || ""}
                    onChange={handleProductChange}
                  />
                </Col>
              </Form.Group>
            </>
          ) : null}
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Father unknown or not listed on birth certificate?
            </Form.Label>
            <Col sm={7} className="d-flex align-items-center">
              <Form.Check
                id="fatherIsUnknown_r_no"
                className="mr-3"
                type="radio"
                label="no"
                name="fatherIsUnknown"
                defaultChecked={!productDetails.fatherIsUnknown}
                value="false"
                onChange={handleProductChange}
              />
              <Form.Check
                id="fatherIsUnknown_r_yes"
                type="radio"
                label="yes"
                name="fatherIsUnknown"
                defaultChecked={productDetails.fatherIsUnknown}
                value="true"
                onChange={handleProductChange}
              />
            </Col>
          </Form.Group>
          {!productDetails.fatherIsUnknown ? (
            <>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Father's First Name:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    name="fathersFirstName"
                    type="text"
                    placeholder="Father's First Name"
                    onChange={handleProductChange}
                    value={productDetails.fathersFirstName || ""}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Father's Middle Name:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    name="fathersMiddleName"
                    type="text"
                    placeholder="Father's Middle Name"
                    value={productDetails.fathersMiddleName || ""}
                    onChange={handleProductChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Father's Last Name:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    required
                    name="fathersLastName"
                    type="text"
                    placeholder="Father's Last Name"
                    value={productDetails.fathersLastName || ""}
                    onChange={handleProductChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Suffix:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    as="select"
                    name="fathersSuffix"
                    value={productDetails.fathersSuffix || ""}
                    onChange={handleProductChange}
                  >
                    <option value="empty">Please select</option>
                    {suffixArray.map((prop, key) => (
                      <option key={key} value={prop}>
                        {prop}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              {productDetails.stateOfBirth === "UT" ? (
                <>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Father's Date of Birth:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        required
                        size="sm"
                        type="date"
                        name="fathersDateOfBirth"
                        max={moment().format("YYYY-MM-DD")}
                        step="1"
                        value={fathersDateOfBirth}
                        onChange={handleProductChange}
                        onInvalid={() => onError("Father's of Birth")}
                      />
                    </Col>
                  </Form.Group>
                </>
              ) : productDetails.stateOfBirth === "SC" ? (
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Father's State or Country Of Birth:
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      required
                      name="fathersStateOrCountryOfBirth"
                      type="text"
                      placeholder="Father's state (if US) or Country of birth"
                      value={productDetails.fathersStateOrCountryOfBirth || ""}
                      onChange={handleProductChange}
                    />
                  </Col>
                </Form.Group>
              ) : productDetails.stateOfBirth === "KS" ? (
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Father's State or Country Of Birth:
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      required
                      name="fathersStateOrCountryOfBirth"
                      type="text"
                      placeholder="Father's state (if US) or Country of birth"
                      value={productDetails.fathersStateOrCountryOfBirth || ""}
                      onChange={handleProductChange}
                    />
                  </Col>
                </Form.Group>
              ) : productDetails.stateOfBirth === "AZ" ? (
                <>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Father's Date Of Birth:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        required
                        size="sm"
                        type="date"
                        name="fathersDateOfBirth"
                        max={moment().format("YYYY-MM-DD")}
                        step="1"
                        value={fathersDateOfBirth}
                        onChange={handleProductChange}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Father's State or Country Of Birth:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        required
                        name="fathersStateOrCountryOfBirth"
                        type="text"
                        placeholder="Father's state (if US) or Country of birth"
                        value={productDetails.fathersStateOrCountryOfBirth || ""}
                        onChange={handleProductChange}
                      />
                    </Col>
                  </Form.Group>
                </>
              ) : null}
            </>
          ) : null}
          <hr />
          <h6 className="font-weight-bold">Relationship to the person on record</h6>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Relation to Person on Certificate?
            </Form.Label>
            <Col sm="9">
              <Form.Control
                required
                as="select"
                name="relationship"
                value={productDetails.relationship}
                onChange={handleProductChange}
                onInvalid={() => onError("Relationship")}
              >
                {vitalRelationship.map(({ value, label }, index) => (
                  <option key={index} value={value}>
                    {label}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
          {productDetails.relationship === "other" ? (
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Relationship:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  required
                  name="customRelationship"
                  type="text"
                  placeholder="Enter Relationship"
                  value={productDetails.customRelationship}
                  onChange={handleProductChange}
                />
              </Col>
            </Form.Group>
          ) : null}
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Reason For Applying:
            </Form.Label>
            <Col sm="9">
              <Form.Control
                required
                as="select"
                name="reasonOfApplying"
                value={productDetails.reasonOfApplying}
                onChange={handleProductChange}
                onInvalid={() => onError("Reason of Applying")}
              >
                {vitalReasonOfApplying.map(({ value, label }, index) => (
                  <option key={index} value={value}>
                    {label}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
          {productDetails.reasonOfApplying === "other" ? (
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Reason:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  required
                  name="customReason"
                  type="text"
                  placeholder="Enter Reason"
                  value={productDetails.customReason}
                  onChange={handleProductChange}
                />
              </Col>
            </Form.Group>
          ) : null}
        </>
      </Col>
    </div>
  );
}
