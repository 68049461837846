import { useEffect, useState, useRef } from "react";
import { Container, Form, Row, Col, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { crmConfigActions } from "_actions";
import Card from "components/Card/Card";
import Spinner from "components/Spinner";

import EditMailgun, { initMailgun } from "components/CRMConfig/EditMailgun";
import EditDocsLob, { initDocsLob } from "components/CRMConfig/EditDocsLob";
import EditSMTP, { initSMTP } from "components/CRMConfig/EditSMTP";
import EditService, { initService } from "components/CRMConfig/EditService";
import EditUI, { initUI } from "components/CRMConfig/EditUI";
import ConfirmationModal from "components/ConfirmationModal";
import { CollapseHeader } from "components/CollapseList";
import { CollapseList } from "components/CollapseList";
import { normalizeCRMConfig } from "_helpers";

const normalizeObject = (obj) => {
  let data = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined || obj[key] === null) {
      data = { ...data, [key]: "" };
    } else if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      data = { ...data, [key]: normalizeObject(obj[key]) };
    } else if (typeof obj[key] === "object" && Array.isArray(obj[key])) {
      data = { ...data, [key]: obj[key].map(normalizeObject) };
    } else {
      data = { ...data, [key]: obj[key] };
    }
  });

  return data;
};

function DeleteButton({ onClick = () => {} }) {
  return (
    <Button style={{ width: "85px" }} variant="danger" className="btn btn-sm mr-3" onClick={onClick}>
      Delete <i className="fa fa-trash" />
    </Button>
  );
}

export default function CRMConfig() {
  const dispatch = useDispatch();
  const crmConfig = useSelector(({ crmConfig }) => crmConfig);
  const productCategories = useSelector(({ crmConfig: { productCategories } }) => productCategories);

  // simple fields
  const [testRecipient, setTestRecipient] = useState("");
  const [enabled3DS, setEnabled3DS] = useState(false);
  const [leadDiscountJobEnabled, setLDE] = useState(false);
  const [unprocessedOrderThreshold, setUOT] = useState(0);
  const [autoProcessFailedOrderThreshold, setAPFOT] = useState(0);
  const [einProcessingDelayInMinutes, setEPDIM] = useState(0);
  const [paymentAttemptsLimit, setPAL] = useState(0);
  // complex fields
  const [acceptDiscountCoupons, setAcceptDiscountCoupons] = useState({});
  const [issueDiscountCoupons, setIssueDiscountCoupons] = useState({});
  const [mailgunConfig, setMailgunConfig] = useState({});
  const [mailgun, setMailgun] = useState(initMailgun);
  const [hardCopyEmailConfig, setHCEC] = useState({});
  const [docsLob, setDocsLob] = useState(initDocsLob);
  const [smtpServerconfig, setSSC] = useState({});
  const [smtp, setSMTP] = useState(initSMTP);
  const [services, setServices] = useState([]);
  const [service, setService] = useState(initService);
  const [uis, setUIS] = useState([]);
  const [ui, setUI] = useState(initUI);
  // form var
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [availableProductCategories, setAvailableProductCategories] = useState([]);
  // modal states var
  const [isMailgunModalOpen, setMailgunModal] = useState(false);
  const [isDocsLobModalOpen, setDocsLobModal] = useState(false);
  const [isSMTPModalOpen, setSMTPModal] = useState(false);
  const [isServiceModalOpen, setServiceModal] = useState(false);
  const [isUIModalOpen, setUIModal] = useState(false);
  // collapse states
  const [isDiscountCouponsCollapsed, setIsDiscountCouponsCollapsed] = useState(true);
  const [isMailgunCollapsed, setIsMailgunCollapsed] = useState(true);
  const [isDocsLobCollapsed, setIsDocsLobCollapsed] = useState(true);
  const [isSMTPCollapsed, setIsSMTPCollapsed] = useState(true);
  const [isServicesCollapsed, setIsServicesCollapsed] = useState(true);
  const [isUISCollapsed, setIsUISCollapsed] = useState(true);

  const handleChangeAcceptDiscountCoupons = ({ target: { checked, name } }) =>
    setAcceptDiscountCoupons((prevAcceptDiscountCoupons) => ({ ...prevAcceptDiscountCoupons, [name]: checked }));
  const handleChangeIssueDiscountCoupons = ({ target: { checked, name } }) =>
    setIssueDiscountCoupons((prevIssueDiscountCoupons) => ({ ...prevIssueDiscountCoupons, [name]: checked }));
  const handleChangeEmails = ({ target: { value } }) => setTestRecipient(value);
  const handleChangeEnable3DS = ({ target: { checked } }) => setEnabled3DS(checked);
  const handleChangeLDE = ({ target: { checked } }) => setLDE(checked);
  const handleChangeUOT = ({ target: { value } }) => setUOT(value);
  const handleChangeAPFOT = ({ target: { value } }) => setAPFOT(value);
  const handleChangeEPDIM = ({ target: { value } }) => setEPDIM(value);
  const handleChangePAL = ({ target: { value } }) => setPAL(value);

  const handleDiscountCouponsCollapse = () => setIsDiscountCouponsCollapsed(!isDiscountCouponsCollapsed);
  const handleMailgunCollapse = () => setIsMailgunCollapsed(!isMailgunCollapsed);
  const handleDocsLobCollapse = () => setIsDocsLobCollapsed(!isDocsLobCollapsed);
  const handleSMTPCollapse = () => setIsSMTPCollapsed(!isSMTPCollapsed);
  const handleServicesCollapse = () => setIsServicesCollapsed(!isServicesCollapsed);
  const handleUISCollapse = () => setIsUISCollapsed(!isUISCollapsed);

  const handleDeleteRef = useRef(() => {});
  const confirmationBodyRef = useRef("");

  // MAILGUN
  const handleAddMailgun = () => {
    setAvailableProductCategories(getProductCategoriesList(mailgunConfig));
    setMailgun(initMailgun);
    setMailgunModal(true);
  };

  const handleEditMailgun = (m, name) => {
    setAvailableProductCategories(getProductCategoriesList(mailgunConfig, name));
    setMailgun({ ...m, name });
    setMailgunModal(true);
  };

  const handleDeleteMailgunField = (name) => {
    const { [name]: deleted, ...newMailgunConfig } = mailgunConfig;
    setMailgunConfig(newMailgunConfig);
  };

  const handleCloseMailgun = () => {
    setMailgun(initMailgun);
    setMailgunModal(false);
  };

  const handleSaveMailgun = (m, name, oldName) => {
    const { [oldName]: deleted, ...oldMailguns } = mailgunConfig;

    setDirty(true);
    setMailgunConfig(name === "" ? oldMailguns : { ...oldMailguns, [name]: m });

    handleCloseMailgun();
  };

  // DOCSLOB
  const handleAddDocsLob = () => {
    setAvailableProductCategories(getProductCategoriesList(hardCopyEmailConfig));
    setDocsLob(initDocsLob);
    setDocsLobModal(true);
  };

  const handleEditDocsLob = (d, name) => {
    setAvailableProductCategories(getProductCategoriesList(hardCopyEmailConfig, name));
    setDocsLob({ ...d, name });
    setDocsLobModal(true);
  };

  const handleDeleteDocsLobField = (name) => {
    const { [name]: deleted, ...newDocsLobs } = hardCopyEmailConfig;

    setHCEC(newDocsLobs);
  };

  const handleCloseDocsLob = () => {
    setDocsLob(initDocsLob);
    setDocsLobModal(false);
  };

  const handleSaveDocsLob = (d, name, oldName) => {
    const { [oldName]: deleted, ...oldDocsLobs } = hardCopyEmailConfig;

    setDirty(true);
    setHCEC(name === "" ? oldDocsLobs : { ...oldDocsLobs, [name]: d });

    handleCloseDocsLob();
  };

  // SMTP
  const handleAddSMTP = () => {
    setAvailableProductCategories(getProductCategoriesList(smtpServerconfig));
    setSMTP(initSMTP);
    setSMTPModal(true);
  };

  const handleEditSMTP = (d, name) => {
    setAvailableProductCategories(getProductCategoriesList(smtpServerconfig, name));
    setSMTP({ ...d, name });
    setSMTPModal(true);
  };

  const handleDeleteSMTPField = (name) => {
    const { [name]: deleted, ...newSMTPs } = smtpServerconfig;

    setSSC(newSMTPs);
  };

  const handleCloseSMTP = () => {
    setSMTP(initSMTP);
    setSMTPModal(false);
  };

  const handleSaveSMTP = (d, name, oldName) => {
    const { [oldName]: deleted, ...oldSMTPs } = smtpServerconfig;

    setDirty(true);
    setSSC(name === "" ? oldSMTPs : { ...oldSMTPs, [name]: d });

    handleCloseSMTP();
  };

  // Services
  const handleAddService = () => {
    setService(initService);
    setServiceModal(true);
  };

  const handleEditService = (srv) => {
    setService(srv);
    setServiceModal(true);
  };

  const handleDeleteServiceField = (name) => {
    setServices(services.filter((srv) => name !== srv.name));
  };

  const handleCloseService = () => {
    setService(initService);
    setServiceModal(false);
  };

  const handleSaveService = (d, oldName) => {
    setDirty(true);
    setServices(oldName ? services.map((srv) => (srv.name === oldName ? d : srv)) : [...services, d]);

    handleCloseService();
  };

  // UIs
  const handleAddUI = () => {
    setUI(initUI);
    setUIModal(true);
  };

  const handleEditUI = (ui) => {
    setUI(ui);
    setUIModal(true);
  };

  const handleDeleteUIField = (id) => {
    setUIS(uis.filter(({ projectId }) => projectId !== id));
  };

  const handleCloseUI = () => {
    setUI(initUI);
    setUIModal(false);
  };

  const handleSaveUI = (d, oldId) => {
    setDirty(true);
    setUIS(oldId ? uis.map((ui) => (ui.projectId === oldId ? d : ui)) : [...uis, d]);

    handleCloseUI();
  };

  // CRM CONFIG
  const handleSubmit = (e) => {
    const body = {
      ...crmConfig.crmConfig,
      acceptDiscountCoupons,
      issueDiscountCoupons,
      testRecipient,
      enabled3DS,
      leadDiscountJobEnabled,
      unprocessedOrderThreshold,
      autoProcessFailedOrderThreshold,
      einProcessingDelayInMinutes,
      paymentAttemptsLimit,
      mailgunConfig,
      hardCopyEmailConfig,
      smtpServerconfig,
      services,
      uis,
    };

    dispatch(crmConfigActions.saveConfig(normalizeCRMConfig(body)));
    setDirty(false);
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    dispatch(crmConfigActions.get());
    dispatch(crmConfigActions.getProductCategories());
  }, []);

  useEffect(() => {
    if (crmConfig.crmConfig && Object.keys(crmConfig.crmConfig)) {
      const {
        acceptDiscountCoupons,
        issueDiscountCoupons,
        testRecipient,
        enabled3DS,
        leadDiscountJobEnabled,
        unprocessedOrderThreshold,
        autoProcessFailedOrderThreshold,
        einProcessingDelayInMinutes,
        paymentAttemptsLimit,
        mailgunConfig: mgConfig,
        hardCopyEmailConfig: hceConfig,
        smtpServerconfig: smtpSC,
        services: srvs,
        uis,
      } = normalizeObject(crmConfig.crmConfig);

      setAcceptDiscountCoupons(acceptDiscountCoupons || {});
      setIssueDiscountCoupons(issueDiscountCoupons || {});

      setTestRecipient(testRecipient || "");
      setEnabled3DS(enabled3DS || false);
      setLDE(leadDiscountJobEnabled || false);
      setUOT(unprocessedOrderThreshold || 0);
      setAPFOT(autoProcessFailedOrderThreshold || 0);
      setEPDIM(einProcessingDelayInMinutes || 0);
      setPAL(paymentAttemptsLimit || 0);

      setMailgunConfig(mgConfig || {});
      setHCEC(hceConfig || {});
      setSSC(smtpSC || {});
      setServices(srvs || []);
      setUIS(uis);
    }
  }, [crmConfig.crmConfig]);

  const getProductCategoriesList = (obj, name) => {
    const objectKeys = Object.keys(obj);

    return productCategories.filter(({ code }) => !(objectKeys.includes(code) && code !== name));
  };

  const discountCouponsList = () => {
    return (
      <Table responsive bordered hover>
        <thead>
          <tr>
            <th>Discount Coupon Type</th>
            <th>Accept</th>
            <th>Issue</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(acceptDiscountCoupons).map((key) => {
            return (
              <tr key={key}>
                <td>{key}</td>
                <td>
                  <Form.Check
                    type="checkbox"
                    name={`${key}`}
                    checked={acceptDiscountCoupons[key]}
                    onChange={handleChangeAcceptDiscountCoupons}
                  />
                </td>
                <td>
                  <Form.Check
                    type="checkbox"
                    name={`${key}`}
                    checked={issueDiscountCoupons[key]}
                    onChange={handleChangeIssueDiscountCoupons}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const mailgunList = () => {
    if (!mailgunConfig) return null;

    const mailgunKeys = Object.keys(mailgunConfig);

    return (
      <>
        {mailgunKeys.length > 0 &&
          mailgunKeys.map((name) => (
            <Form.Group key={name} as={Row}>
              <Form.Label column>{name}</Form.Label>
              <Button
                style={{ width: "85px" }}
                variant="warning"
                className="btn-sm pull-right mr-1"
                onClick={() => handleEditMailgun(mailgunConfig[name], name)}
              >
                Edit <i className="fa fa-edit" />
              </Button>
              <DeleteButton
                onClick={() => {
                  confirmationBodyRef.current = `Delete ${name} field from Mailgun config?`;
                  setConfirmationOpen(true);
                  handleDeleteRef.current = () => {
                    handleDeleteMailgunField(name);
                    setDirty(true);
                  };
                }}
              />
            </Form.Group>
          ))}
        {mailgunKeys.length < productCategories.length && (
          <Form.Group as={Row}>
            <Form.Label column>Create new Mailgan field</Form.Label>
            <Button
              style={{ width: "85px" }}
              variant="success"
              className="btn-sm pull-right mr-3"
              onClick={handleAddMailgun}
            >
              Add <i className="fa fa-plus" />
            </Button>
          </Form.Group>
        )}
      </>
    );
  };

  const docsLobList = () => {
    if (!hardCopyEmailConfig) return null;

    const docsLobKeys = Object.keys(hardCopyEmailConfig);

    return (
      <>
        {docsLobKeys.length > 0 &&
          docsLobKeys.map((name) => (
            <Form.Group key={name} as={Row}>
              <Form.Label column>{name}</Form.Label>
              <Button
                style={{ width: "85px" }}
                variant="warning"
                className="btn btn-sm pull-right mr-1"
                onClick={() => handleEditDocsLob(hardCopyEmailConfig[name], name)}
              >
                Edit <i className="fa fa-edit" />
              </Button>
              <DeleteButton
                onClick={() => {
                  confirmationBodyRef.current = `Delete ${name} field from DocsLob config?`;
                  setConfirmationOpen(true);
                  handleDeleteRef.current = () => {
                    handleDeleteDocsLobField(name);
                    setDirty(true);
                  };
                }}
              />
            </Form.Group>
          ))}
        {docsLobKeys.length < productCategories.length && (
          <Form.Group as={Row}>
            <Form.Label column>Create new DocsLob field</Form.Label>
            <Button
              style={{ width: "85px" }}
              variant="success"
              className="btn-sm pull-right mr-3"
              onClick={handleAddDocsLob}
            >
              Add <i className="fa fa-plus" />
            </Button>
          </Form.Group>
        )}
      </>
    );
  };

  const smtpList = () => {
    if (!smtpServerconfig) return null;

    const smtpKeys = Object.keys(smtpServerconfig);

    return (
      <>
        {smtpKeys.length > 0 &&
          smtpKeys.map((name) => (
            <Form.Group key={name} as={Row}>
              <Form.Label column>{name}</Form.Label>
              <Button
                style={{ width: "85px" }}
                variant="warning"
                className="btn btn-sm pull-right mr-1"
                onClick={() => handleEditSMTP(smtpServerconfig[name], name)}
              >
                Edit <i className="fa fa-edit" />
              </Button>
              <DeleteButton
                onClick={() => {
                  confirmationBodyRef.current = `Delete ${name} field from SMTP config?`;
                  setConfirmationOpen(true);
                  handleDeleteRef.current = () => {
                    handleDeleteSMTPField(name);
                    setDirty(true);
                  };
                }}
              />
            </Form.Group>
          ))}
        {smtpKeys.length < productCategories.length && (
          <Form.Group as={Row}>
            <Form.Label column>Create new SMTP field</Form.Label>
            <Button
              style={{ width: "85px" }}
              variant="success"
              className="btn-sm pull-right mr-3"
              onClick={handleAddSMTP}
            >
              Add <i className="fa fa-plus" />
            </Button>
          </Form.Group>
        )}
      </>
    );
  };

  const servicesList = () => {
    if (!services) return null;

    return (
      <>
        {services.map((srv) => (
          <Form.Group key={srv.name} as={Row}>
            <Form.Label column>{srv.name}</Form.Label>
            <Button
              style={{ width: "85px" }}
              variant="warning"
              className="btn btn-sm pull-right mr-1"
              onClick={() => handleEditService(srv)}
            >
              Edit <i className="fa fa-edit" />
            </Button>
            <DeleteButton
              onClick={() => {
                confirmationBodyRef.current = `Delete ${srv.name} field from Service config?`;
                setConfirmationOpen(true);
                handleDeleteRef.current = () => {
                  handleDeleteServiceField(srv.name);
                  setDirty(true);
                };
              }}
            />
          </Form.Group>
        ))}
        <Form.Group as={Row}>
          <Form.Label column>Create new Services field</Form.Label>
          <Button
            style={{ width: "85px" }}
            variant="success"
            className="btn-sm pull-right mr-3"
            onClick={handleAddService}
          >
            Add <i className="fa fa-plus" />
          </Button>
        </Form.Group>
      </>
    );
  };

  const uisList = () => {
    if (!uis) return null;

    return (
      <>
        {uis.map((ui) => (
          <Form.Group key={ui.description} as={Row}>
            <Form.Label column>{ui.description}</Form.Label>
            <Button
              style={{ width: "85px" }}
              variant="warning"
              className="btn btn-sm pull-right mr-1"
              onClick={() => handleEditUI(ui)}
            >
              Edit <i className="fa fa-edit" />
            </Button>
            <DeleteButton
              onClick={() => {
                confirmationBodyRef.current = `Delete ${ui.description} field from SMTP config?`;
                setConfirmationOpen(true);
                handleDeleteRef.current = () => {
                  handleDeleteUIField(ui.projectId);
                  setDirty(true);
                };
              }}
            />
          </Form.Group>
        ))}
        <Form.Group as={Row}>
          <Form.Label column>Create new UI field</Form.Label>
          <Button style={{ width: "85px" }} variant="success" className="btn-sm pull-right mr-3" onClick={handleAddUI}>
            Add <i className="fa fa-plus" />
          </Button>
        </Form.Group>
      </>
    );
  };

  const crmForm = () => (
    <Form onSubmit={handleSubmit} onChange={() => setDirty(true)}>
      <h3>CRM Config</h3>
      <Form.Group controlId="testRecipient">
        <Form.Label>Test recipient</Form.Label>
        <Form.Control
          type="text"
          size="sm"
          placeholder="Test recipient"
          name="testRecipient"
          value={testRecipient}
          onChange={handleChangeEmails}
        />
      </Form.Group>
      <Form.Group controlId="enabled3ds">
        <Form.Check
          type="checkbox"
          label="Enabled 3DS"
          name="enabled3DS"
          checked={enabled3DS}
          onChange={handleChangeEnable3DS}
        />
      </Form.Group>
      <Form.Group controlId="leadDiscountJobEnabled">
        <Form.Check
          type="checkbox"
          label="Lead discount emails"
          name="leadDiscountJobEnabled"
          checked={leadDiscountJobEnabled}
          onChange={handleChangeLDE}
        />
      </Form.Group>
      <Form.Group controlId="unprocessedOrderThreshold">
        <Form.Label>Unprocessed Order Threshold</Form.Label>
        <Form.Control
          required
          type="number"
          size="sm"
          placeholder="Unprocessed Order Threshold"
          name="unprocessedOrderThreshold"
          value={unprocessedOrderThreshold}
          onChange={handleChangeUOT}
        />
      </Form.Group>
      <Form.Group controlId="autoProcessFailedOrderThreshold">
        <Form.Label>Auto Process Failed Order Threshold</Form.Label>
        <Form.Control
          required
          type="number"
          size="sm"
          placeholder="Auto Process Failed Order Threshold"
          name="autoProcessFailedOrderThreshold"
          value={autoProcessFailedOrderThreshold}
          onChange={handleChangeAPFOT}
        />
      </Form.Group>
      <Form.Group controlId="einProcessingDelayInMinutes">
        <Form.Label>EIN processing delay in minutes</Form.Label>
        <Form.Control
          required
          type="number"
          size="sm"
          placeholder="EIN processing delay in minutes"
          name="einProcessingDelayInMinutes"
          value={einProcessingDelayInMinutes}
          onChange={handleChangeEPDIM}
        />
      </Form.Group>
      <Form.Group controlId="paymentAttemptsLimit">
        <Form.Label>Payment Attempts Limit</Form.Label>
        <Form.Control
          required
          type="number"
          size="sm"
          placeholder="Payment Attempts Limit"
          name="paymentAttemptsLimit"
          value={paymentAttemptsLimit}
          onChange={handleChangePAL}
        />
      </Form.Group>
      <CollapseHeader onClick={handleDiscountCouponsCollapse}>
        <h4>Discount Coupons</h4>
        <i className={`fa fa-arrow-${isDiscountCouponsCollapsed ? "down" : "up"}`} />
      </CollapseHeader>
      <CollapseList isCollapsed={isDiscountCouponsCollapsed}>{discountCouponsList()}</CollapseList>
      <CollapseHeader onClick={handleMailgunCollapse}>
        <h4>Mailgun</h4>
        <i className={`fa fa-arrow-${isMailgunCollapsed ? "down" : "up"}`} />
      </CollapseHeader>
      <CollapseList isCollapsed={isMailgunCollapsed}>{mailgunList()}</CollapseList>
      <CollapseHeader onClick={handleDocsLobCollapse}>
        <h4>DocsLob</h4>
        <i className={`fa fa-arrow-${isDocsLobCollapsed ? "down" : "up"}`} />
      </CollapseHeader>
      <CollapseList isCollapsed={isDocsLobCollapsed}>{docsLobList()}</CollapseList>
      <CollapseHeader onClick={handleSMTPCollapse}>
        <h4>SMTP</h4>
        <i className={`fa fa-arrow-${isSMTPCollapsed ? "down" : "up"}`} />
      </CollapseHeader>
      <CollapseList isCollapsed={isSMTPCollapsed}>{smtpList()}</CollapseList>
      <CollapseHeader onClick={handleServicesCollapse}>
        <h4>Services</h4>
        <i className={`fa fa-arrow-${isServicesCollapsed ? "down" : "up"}`} />
      </CollapseHeader>
      <CollapseList isCollapsed={isServicesCollapsed}>{servicesList()}</CollapseList>
      <CollapseHeader onClick={handleUISCollapse}>
        <h4>UIs</h4>
        <i className={`fa fa-arrow-${isUISCollapsed ? "down" : "up"}`} />
      </CollapseHeader>
      <CollapseList isCollapsed={isUISCollapsed}>{uisList()}</CollapseList>
      <Button
        variant="success"
        type="submit"
        className="btn-sm pull-right"
        style={{ width: "85px", height: "35px" }}
        disabled={!isDirty}
      >
        Save
      </Button>
    </Form>
  );

  return (
    <div className="content">
      <Container fluid className="mt-5 py-1">
        <Row className="mt-3">
          <Col className="md-12">
            <Card content={crmConfig.loading ? <Spinner /> : crmForm()} />
          </Col>
        </Row>
      </Container>
      <EditMailgun
        show={isMailgunModalOpen}
        mailgun={mailgun}
        productCategories={availableProductCategories}
        handleClose={handleCloseMailgun}
        handleSave={handleSaveMailgun}
      />
      <EditDocsLob
        show={isDocsLobModalOpen}
        docsLob={docsLob}
        productCategories={availableProductCategories}
        handleClose={handleCloseDocsLob}
        handleSave={handleSaveDocsLob}
      />
      <EditSMTP
        show={isSMTPModalOpen}
        smtp={smtp}
        productCategories={availableProductCategories}
        handleClose={handleCloseSMTP}
        handleSave={handleSaveSMTP}
      />
      <EditService
        show={isServiceModalOpen}
        service={service}
        handleClose={handleCloseService}
        handleSave={handleSaveService}
      />
      <EditUI show={isUIModalOpen} ui={ui} handleClose={handleCloseUI} handleSave={handleSaveUI} />
      <ConfirmationModal
        show={isConfirmationOpen}
        handleClose={() => setConfirmationOpen(false)}
        handleConfirm={() => {
          handleDeleteRef.current();
          setConfirmationOpen(false);
        }}
        body={confirmationBodyRef.current}
      />
    </div>
  );
}
