import axios from "axios";
import { config } from "_config";
import { authHeader } from "_helpers";
import { useDispatch, useSelector } from "react-redux";
import { variantsActions } from "_actions";
import { useEffect, useState } from "react";
import { Form, Col, Row, Table, Button } from "react-bootstrap";
import { alertActions } from "_actions";

export default function Upselloffer() {
  const dispatch = useDispatch();

  const [offers, setOffers] = useState([]);
  const [status, setStatus] = useState("false");
  const [finished_product, setFinished_product] = useState("");
  const [upselloffer, setUpselloffer] = useState("");

  const product_codes = useSelector((state) => state.variants.productCodes);
  const product_codes_for_sale = useSelector((state) => state.variants.productCodesforsale);

  const sortdata = (data) => {
    data.sort((a, b) => (a.finishedProduct.code > b.finishedProduct.code ? 1 : -1));
  };

  const get_offers = () => {
    const app_header = {
      headers: authHeader(),
    };
    let url = `${config.apiUrl1}/upsell-offers`;
    if (status === "true") url += `?activeOnly=true`;
    if (status === "false") url += `?activeOnly=false`;
    axios.get(url, app_header).then((res) => {
      sortdata(res.data);
      setOffers(res.data);
    });
  };

  const save_offer = () => {
    const app_header = {
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };

    const data = {
      finishedProduct: finished_product,
      upsellOffer: upselloffer,
      disabled: false,
    };

    let url = `${config.apiUrl1}/upsell-offers`;
    axios.put(url, data, app_header).then((res) => {
      dispatch(alertActions.success("Offer updated"));
      get_offers();
    });
  };

  const delete_offer = (uid) => {
    const app_header = {
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };

    let url = `${config.apiUrl1}/upsell-offers/${uid}`;
    axios.delete(url, app_header).then(
      (res) => {
        dispatch(alertActions.success("Offer deleted"));
        get_offers();
      },
      (error) => {
        dispatch(alertActions.error(error?.message || error?.toString() || "Something went wrong..."));
      }
    );
  };

  const changestatus = (offer) => {
    const app_header = {
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };

    offer = {
      ...offer,
      disabled: !offer.disabled,
    };

    let url = `${config.apiUrl1}/upsell-offers`;
    axios.put(url, offer, app_header).then((res) => {
      dispatch(alertActions.success("Offer updated"));
      get_offers();
    });
  };

  const handlechangestatus = (status) => {
    setStatus(status);
  };

  const addnewoffer = (e) => {
    save_offer();
  };

  useEffect(() => {
    get_offers();
  }, [status]);

  useEffect(() => {
    if (product_codes.length > 0) {
      setFinished_product(product_codes[0]["code"]);
      setUpselloffer(product_codes[0]["code"]);
    }
  }, [product_codes]);

  useEffect(() => {
    dispatch(variantsActions.getproductcodes());
    dispatch(variantsActions.getproductcodesforsale());
  }, []);

  return (
    <Row style={{ padding: 10 }}>
      <Col sm="4">
        <Form.Group as={Row} className="align-items-center">
          <Form.Label column sm="4">
            Active Only:
          </Form.Label>
          <Col sm="8">
            <Form.Control
              as="select"
              size="sm"
              name="startTime"
              value={status}
              onChange={(e) => handlechangestatus(e.target.value)}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
          </Col>
        </Form.Group>
      </Col>

      <Col sm="12">
        <Form.Group as={Row} className="align-items-end">
          <Col sm="3">
            <Form.Label>Finished Product:</Form.Label>
            <Form.Control
              as="select"
              size="sm"
              name="startTime"
              value={finished_product}
              onChange={(e) => setFinished_product(e.target.value)}
            >
              {product_codes_for_sale.map((item, key) => {
                return (
                  <option value={item.code} key={key}>
                    {item.code}
                  </option>
                );
              })}
            </Form.Control>
          </Col>

          <Col sm="3">
            <Form.Label>Upsell Offer:</Form.Label>
            <Form.Control
              as="select"
              size="sm"
              name="startTime"
              value={upselloffer}
              onChange={(e) => setUpselloffer(e.target.value)}
            >
              {product_codes.map((item, key) => {
                return (
                  <option value={item.code} key={key}>
                    {item.code}
                  </option>
                );
              })}
            </Form.Control>
          </Col>

          <Col sm="2">
            <Button size="sm" onClick={addnewoffer}>
              Add
            </Button>
          </Col>
        </Form.Group>
      </Col>

      <Col sm="12" className="offers-table">
        <Table responsive bordered>
          <thead>
            <tr>
              <th>Finished Product</th>
              <th>Upsell Offer</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {offers.map((item, key) => {
              return (
                <tr key={key}>
                  <td>
                    <div>{item.finishedProduct.customerName}</div>
                    <div style={{ color: "gray" }}>{item.finishedProduct.code}</div>
                  </td>
                  <td>
                    <div>{item.upsellOffer?.customerName || "N/A"}</div>
                    <div style={{ color: "gray" }}>{item.upsellOffer?.code || "N/A"}</div>
                  </td>
                  <td>
                    <Form.Check type="checkbox" checked={!item.disabled} onChange={() => changestatus(item)} />
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => {
                        delete_offer(item.id);
                      }}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}
