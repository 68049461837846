import { useCallback, useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import cloneDeep from "lodash.clonedeep";

import { usePrevious } from "_hooks";
import { userService } from "_services";

const ASSIGNEE_TYPE_OPTIONS = [
  { code: "", value: "Choose..." },
  { code: "TEAM_USER", value: "Team User" },
  { code: "TEAM_ATTORNEY", value: "Legal Team" },
  { code: "SYSTEM", value: "System" },
  { code: "CUSTOMER", value: "Customer" },
];

const ALLOWED_ASSIGNEE_TYPES = ["TEAM_USER", "TEAM_ATTORNEY"];

export default function Assignee({ isEditable, orderDetails, selectedProduct, onChange, onValidate }) {
  const [isLoading, setIsLoading] = useState(false);
  const [initialAssigneeUsers, setInitialAssigneeUsers] = useState({});
  const [editedAssigneeUsers, setEditedAssigneeUsers] = useState({});
  const [assigneeUserLabels, setAssigneeUserLabels] = useState({});
  const assigneeType = orderDetails?.products[selectedProduct]?.assigneeType || "";
  const assigneeUser = orderDetails?.products[selectedProduct]?.assigneeUser || "";
  const assigneeUserLabel = assigneeUserLabels[selectedProduct] && assigneeUserLabels[selectedProduct][assigneeUser];
  const roleFromAssigneeType = assigneeType?.replace("TEAM_", "");
  const prevOrderDetails = usePrevious(orderDetails);

  const handleAssigneeTypeChange = useCallback(
    ({ target: { value } }) => {
      const newOrderDetails = cloneDeep(orderDetails);
      newOrderDetails.products[selectedProduct].assigneeType = value || null;

      if (
        ALLOWED_ASSIGNEE_TYPES.includes(value) &&
        editedAssigneeUsers[selectedProduct] &&
        editedAssigneeUsers[selectedProduct][value]
      ) {
        newOrderDetails.products[selectedProduct].assigneeUser = editedAssigneeUsers[selectedProduct][value];
      } else {
        newOrderDetails.products[selectedProduct].assigneeUser = initialAssigneeUsers[selectedProduct];
      }

      onChange && onChange(newOrderDetails);
      onValidate && onValidate({ isAssigneeRoleMatchesType: true });

      setAssigneeUserLabels((prevLabels) => ({
        ...prevLabels,
        [selectedProduct]: {},
      }));
    },
    [orderDetails, selectedProduct, initialAssigneeUsers, editedAssigneeUsers, onChange, onValidate]
  );

  const handleAssigneeChange = useCallback(
    ({ label, value }) => {
      const newOrderDetails = cloneDeep(orderDetails);
      newOrderDetails.products[selectedProduct].assigneeUser = value;

      onChange && onChange(newOrderDetails);
      onValidate && onValidate({ isAssigneeRoleMatchesType: true });

      setAssigneeUserLabels((prevLabels) => ({
        ...prevLabels,
        [selectedProduct]: {
          ...(prevLabels[selectedProduct] || {}),
          [value]: label,
        },
      }));

      setEditedAssigneeUsers((prevEditedUsers) => ({
        ...prevEditedUsers,
        [selectedProduct]: {
          ...(prevEditedUsers[selectedProduct] || {}),
          [assigneeType]: value,
        },
      }));
    },
    [orderDetails, selectedProduct, assigneeType, onChange, onValidate]
  );

  const loadUserOptions = useCallback(
    async (search, prevOptions, { page }) => {
      const options = [];
      let hasMore = false;

      try {
        const { elements, maxPerPage, totalResults } = await userService.getAll({
          page,
          count: 5,
          search,
          role: roleFromAssigneeType,
        });

        hasMore = totalResults > maxPerPage * page;

        elements.forEach(({ id, email, username }) => {
          options.push({
            label: `${username} - ${email}`,
            value: id,
          });
        });
      } catch (e) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: page,
          },
        };
      }

      return {
        options,
        hasMore,
        additional: {
          page: page + 1,
        },
      };
    },
    [roleFromAssigneeType]
  );

  useEffect(() => {
    if (!assigneeUserLabel && assigneeUser) {
      (async function () {
        try {
          setIsLoading(true);

          const { email, username, role } = await userService.getById(assigneeUser);

          setAssigneeUserLabels((prevLabels) => ({
            ...prevLabels,
            [selectedProduct]: {
              ...(prevLabels[selectedProduct] || {}),
              [assigneeUser]: `${username} - ${email}`,
            },
          }));

          if (ALLOWED_ASSIGNEE_TYPES.includes(assigneeType)) {
            if (!assigneeType.includes(role)) {
              if (assigneeUser === initialAssigneeUsers[selectedProduct]) {
                const newOrderDetails = cloneDeep(orderDetails);
                newOrderDetails.products[selectedProduct].assigneeUser = null;
                onValidate({ isAssigneeRoleMatchesType: false });
                onChange && onChange(newOrderDetails);
              }
            }
          }

          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
        }
      })();
    }
  }, [assigneeUserLabel, assigneeUser, assigneeType, initialAssigneeUsers, selectedProduct, onChange, onValidate]);

  useEffect(() => {
    if (!prevOrderDetails && orderDetails) {
      const assigneeUsers = orderDetails?.products.reduce((res, product, i) => {
        res[i] = product?.assigneeUser;
        return res;
      }, {});

      setInitialAssigneeUsers(assigneeUsers);
    }
  }, [prevOrderDetails, orderDetails]);

  if (isEditable) {
    return (
      <>
        <Form.Label column sm="4">
          Assignee Type:
        </Form.Label>
        <Col sm={8}>
          <Form.Control size="sm" as="select" value={assigneeType} onChange={handleAssigneeTypeChange}>
            {ASSIGNEE_TYPE_OPTIONS.map(({ code, value }, key) => (
              <option value={code} key={key}>
                {value}
              </option>
            ))}
          </Form.Control>
        </Col>

        {ALLOWED_ASSIGNEE_TYPES.includes(assigneeType) && (
          <>
            <Form.Label column sm="4">
              Assignee:
            </Form.Label>
            <Col sm={8}>
              <AsyncPaginate
                key={assigneeType}
                className="async-select-container"
                classNamePrefix="async-select"
                placeholder="Search..."
                isDisabled={!ALLOWED_ASSIGNEE_TYPES.includes(assigneeType)}
                additional={{ page: 1 }}
                value={{
                  value: assigneeUser,
                  label: isLoading ? "Loading..." : assigneeUserLabel || "Search...",
                }}
                onChange={handleAssigneeChange}
                loadOptions={loadUserOptions}
              />
            </Col>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <Form.Label column sm="4">
        Assignee Type:
      </Form.Label>
      <Form.Label column sm="8">
        {ASSIGNEE_TYPE_OPTIONS.find(({ code }) => code === assigneeType)?.value || "N/A"}
      </Form.Label>

      {ALLOWED_ASSIGNEE_TYPES.includes(assigneeType) && (
        <>
          <Form.Label column sm="4">
            Assignee:
          </Form.Label>
          <Form.Label column sm="8">
            {isLoading ? "Loading..." : assigneeUserLabel || "N/A"}
          </Form.Label>
        </>
      )}
    </>
  );
}
