import { Col, Form, Row } from "react-bootstrap";
import moment from "moment-timezone";

import { month_array } from "variables/Variables";

export default function SimpleQuestion({ productDetails, onError, onProductChange }) {
  const handleProductRadioButtonChange = ({ target: { name, value } }) =>
    onProductChange({ target: { name, value: value === "true" } });

  const handleDateChange = ({ target: { name, value } }) => {
    const newVal = value < 10 ? "0" + value : value;
    let oldDate = productDetails.firstDateWages;

    if (oldDate) oldDate = oldDate.split("T")[0].split("-");
    else oldDate = ["1996", "01", "01"];

    if (name === "year") oldDate[0] = newVal;
    if (name === "month") oldDate[1] = newVal;
    if (name === "date") oldDate[2] = newVal;

    const newDate = oldDate.join("-") + "T00:00:00";

    onProductChange({ target: { name: "firstDateWages", value: newDate } });
  };

  const einName = productDetails.code.code.replace("EIN_", "");
  const yearRange = [];
  const dateRange = [];
  const thisYear = new Date().getFullYear();

  let d = null;
  if (productDetails.firstDateWages) {
    d = new Date(moment.utc(productDetails.firstDateWages).format("YYYY-MM-DDTHH:mm:ss"));
  }

  const month = d ? d.getMonth() + 1 : "";
  const year = d ? d.getFullYear() : "";
  const date = d ? d.getDate() : "";

  for (let i = 1; i <= 31; i++) {
    dateRange.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  for (let i = 1990, key = 0; i <= thisYear + 1; i++, key++) {
    yearRange.push(
      <option key={key} value={i}>
        {i}
      </option>
    );
  }

  const requiredEmployee =
    (productDetails.numberOfAgricultureEmployees === 0 || productDetails.numberOfAgricultureEmployees === "") &&
    (productDetails.numberOfHouseholdeEmployees === 0 || productDetails.numberOfHouseholdeEmployees === "") &&
    (productDetails.numberOfOtherEmployees === 0 || productDetails.numberOfOtherEmployees === "");

  return (
    <div>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Has this {einName} ever received or applied for an EIN before?
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="questionsAppliedBefore_r_no"
            type="radio"
            label="no"
            name="questionsAppliedBefore"
            className="mr-3"
            value="false"
            defaultChecked={!productDetails.questionsAppliedBefore}
            onChange={handleProductRadioButtonChange}
          />
          <Form.Check
            id="questionsAppliedBefore_r_yes"
            type="radio"
            label="yes"
            name="questionsAppliedBefore"
            value="true"
            defaultChecked={productDetails.questionsAppliedBefore}
            onChange={handleProductRadioButtonChange}
          />
        </Col>

        {productDetails.questionsAppliedBefore && (
          <Col sm="12">
            <div className="pl-2">
              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Previous EIN number, first 2 digits
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    size="sm"
                    type="text"
                    required="required"
                    pattern="^\d{2}$"
                    placeholder="12"
                    name="previousEINNumberFirst2"
                    value={productDetails.previousEINNumberFirst2 || ""}
                    onChange={onProductChange}
                    onInvalid={() => onError("2 Digits")}
                  />
                </Col>

                <Form.Label column sm={5}>
                  Previous EIN number, last 7 digits
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    size="sm"
                    type="text"
                    required="required"
                    pattern="^\d{7}$"
                    placeholder="1234567"
                    name="previousEINNumberLast7"
                    value={productDetails.previousEINNumberLast7 || ""}
                    onChange={onProductChange}
                    onInvalid={() => onError("7 Digits")}
                  />
                </Col>
              </Form.Group>
            </div>
          </Col>
        )}
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Do you currently have, or plan to hire employees within the next year (not including owners)?
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="questionsHire_r_no"
            type="radio"
            label="no"
            name="questionsHire"
            className="mr-3"
            value="false"
            defaultChecked={!productDetails.questionsHire}
            onChange={handleProductRadioButtonChange}
          />
          <Form.Check
            id="questionsHire_r_yes"
            type="radio"
            label="yes"
            name="questionsHire"
            value="true"
            defaultChecked={productDetails.questionsHire}
            onChange={handleProductRadioButtonChange}
          />
        </Col>

        {productDetails.questionsHire && (
          <Col sm="12">
            <div className="pl-2">
              <Form.Group as={Row}>
                <Form.Label column sm={5}>
                  Do you expect your employment tax liability to be $1,000 or less in a full calendar year?
                  (January-December)?
                </Form.Label>
                <Col sm={7} className="d-flex align-items-center">
                  <Form.Check
                    type="radio"
                    label="no"
                    name="payLessThan4k"
                    className="mr-3"
                    id="payLessThan4k_no"
                    value="false"
                    defaultChecked={!productDetails.payLessThan4k}
                    onChange={handleProductRadioButtonChange}
                  />
                  <Form.Check
                    type="radio"
                    label="yes"
                    name="payLessThan4k"
                    id="payLessThan4k_yes"
                    value="true"
                    defaultChecked={productDetails.payLessThan4k}
                    onChange={handleProductRadioButtonChange}
                  />
                </Col>

                {productDetails.payLessThan4k && (
                  <>
                    <Form.Label column sm={5}>
                      Do you want to file employment tax annually instead of quarterly?
                    </Form.Label>
                    <Col sm={7} className="d-flex align-items-center">
                      <Form.Check
                        type="radio"
                        label="no"
                        name="annualTaxes"
                        className="mr-3"
                        id="annualTaxes_no"
                        value="false"
                        defaultChecked={!productDetails.annualTaxes}
                        onChange={handleProductRadioButtonChange}
                      />
                      <Form.Check
                        type="radio"
                        label="yes"
                        name="annualTaxes"
                        id="annualTaxes_yes"
                        value="true"
                        defaultChecked={productDetails.annualTaxes}
                        onChange={handleProductRadioButtonChange}
                      />
                    </Col>
                  </>
                )}

                <Form.Label column sm={5}>
                  Number of Agricultural Employees
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    required={requiredEmployee ? "required" : ""}
                    size="sm"
                    type="number"
                    min="0"
                    name="numberOfAgricultureEmployees"
                    value={productDetails.numberOfAgricultureEmployees || ""}
                    onChange={onProductChange}
                    onInvalid={() => onError("Number of Agricultural Employees")}
                  />
                </Col>

                {productDetails.code.code !== "EIN_PSC" && (
                  <>
                    <Form.Label column sm={5}>
                      Number of Household Employees
                    </Form.Label>
                    <Col sm={7}>
                      <Form.Control
                        required={requiredEmployee ? "required" : ""}
                        size="sm"
                        type="number"
                        min="0"
                        name="numberOfHouseholdeEmployees"
                        value={productDetails.numberOfHouseholdeEmployees || ""}
                        onChange={onProductChange}
                        onInvalid={() => onError("Number of Household Employees")}
                      />
                    </Col>
                  </>
                )}

                <Form.Label column sm={5}>
                  Number of Other Employees
                </Form.Label>
                <Col sm={7}>
                  <Form.Control
                    required={requiredEmployee ? "required" : ""}
                    size="sm"
                    type="number"
                    min="0"
                    name="numberOfOtherEmployees"
                    value={productDetails.numberOfOtherEmployees || ""}
                    onChange={onProductChange}
                    onInvalid={() => onError("Number of Other Employees")}
                  />
                </Col>

                <Form.Label column sm={5}>
                  First date wages or annuities were or will be paid
                </Form.Label>
                <Col sm={7} className="d-flex align-items-center">
                  <Form.Control required as="select" value={month} name="month" onChange={handleDateChange}>
                    <option value="" />
                    {Object.keys(month_array).map((prop, key) => (
                      <option key={key} value={prop}>
                        {month_array[prop]}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control required as="select" name="date" value={date} onChange={handleDateChange}>
                    <option value="" />
                    {dateRange}
                  </Form.Control>
                  <Form.Control required as="select" name="year" value={year} onChange={handleDateChange}>
                    <option value="" />
                    {yearRange}
                  </Form.Control>
                </Col>
              </Form.Group>
            </div>
          </Col>
        )}
      </Form.Group>
    </div>
  );
}
