import React, { useEffect, useState } from "react";
import { Card, Table, Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { servicesActions } from "_actions";

import Spinner from "components/Spinner";
import Button from "components/Button";

function TabDeploy(props) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [version_type, setversion_type] = useState("master");
  const [version_name, setversion_name] = useState("");

  const allversions = state.services;
  const loading = state.services.loading;

  const filter = (data) => {
    const validate = /\d*\.\d*/.test(data.versionName);
    if (version_type == "master" && validate) return true;
    if (version_type == "custom" && !validate) return true;
    return false;
  };

  const chage_type = (e) => {
    const type = e.target.value;
    setversion_type(type);
    setversion_name("");
  };

  const selected_version = (data) => {
    if (data.versionName === version_name) return true;
  };

  const resetState = () => {
    setversion_name("");
  };

  const refresh = () => {
    resetState();
    dispatch(servicesActions.getAll());
  };

  const deployservice = (key, jobid) => {
    const elements = document.getElementById("form-" + key).elements;
    let services_array = [];

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].nodeName === "INPUT" && elements[i].type === "checkbox" && elements[i].checked === true) {
        services_array.push(elements[i].name);
      }
    }
    dispatch(servicesActions.deployservice(jobid, services_array));
  };

  useEffect(() => {
    dispatch(servicesActions.getAll());
  }, []);

  return (
    <div className="form-border bg-white">
      <Row>
        <Col md="12">
          <Card className="p-3">
            <Form inline>
              <Form.Label className="my-1 mr-4" htmlFor="inlineFormCustomSelectPref">
                Version Type:
              </Form.Label>
              <Form.Control
                as="select"
                className="my-1 mr-5"
                id="inlineFormCustomSelectPref"
                size="sm"
                value={version_type}
                onChange={chage_type}
              >
                <option value="master">Master</option>
                <option value="custom">Custom</option>
              </Form.Control>

              <Form.Label className="my-1 mr-4" htmlFor="inlineFormCustomSelectPref">
                Version Name:
              </Form.Label>
              <Form.Control
                as="select"
                className="my-1 mr-5"
                id="inlineFormCustomSelectPref"
                size="sm"
                value={version_name}
                onChange={(e) => setversion_name(e.target.value)}
              >
                <option value="" disabled>
                  Select Version
                </option>
                {allversions.items && (
                  <>
                    {allversions.items.versionObjects.map((prop, key) => {
                      return (
                        <React.Fragment key={key}>
                          {filter(prop) && <option value={prop.versionName}>{prop.versionName}</option>}
                        </React.Fragment>
                      );
                    })}
                  </>
                )}
              </Form.Control>

              <Button type="button" className="my-1" size="sm" disabled={loading} isLoading={loading} onClick={refresh}>
                Refresh
              </Button>
            </Form>
          </Card>

          {allversions.items ? (
            <Card>
              <Card.Title className="m-0 p-2">Version Deployment</Card.Title>
              <Table striped bordered size="sm">
                <thead>
                  <tr>
                    <th>Version</th>
                    <th>Services</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allversions.items.versionObjects.map((prop, key) => {
                    return (
                      <React.Fragment key={key}>
                        {selected_version(prop) && (
                          <tr>
                            <td>{prop.versionName}</td>
                            <td>
                              <form action="" id={"form-" + key}>
                                {prop.services.map((service, key) => {
                                  return (
                                    <p className="m-0" key={key}>
                                      <input type="checkbox" name={service} className="mr-2" />
                                      {service}
                                    </p>
                                  );
                                })}
                              </form>
                            </td>
                            <td>
                              <Button
                                size="sm"
                                disabled={loading}
                                isLoading={loading}
                                onClick={() => deployservice(key, prop.jobId)}
                              >
                                Deploy
                              </Button>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          ) : (
            <Spinner></Spinner>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default TabDeploy;
