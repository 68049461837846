// Dashboard
import DashboardReport from "views/Dashboard/DashboardReport";
// import Dashboard from "views/Dashboard";
// Orders
import AllCategoriesOrder from "views/Orders/AllCategoriesOrder";
import EINOrder from "views/Orders/EINOrder";
import EinIncOrder from "views/Orders/EinIncOrder";
import VITALOrder from "views/Orders/VITALOrder";
import TrademarksOrder from "views/Orders/TrademarksOrder";
import IncorpifyOrder from "views/Orders/IncorpifyOrder";
import IncStatesOrder from "views/Orders/IncStatesOrder";
// Order
import OrderDetail from "views/Orders/Order/OrderDetail";
import CreateOrder from "views/Orders/Order/CreateOrder";
// Reports
import TrademarkDashboard from "views/Reports/TrademarkDashboard/TrademarkDashboard";
import SalesByDomain from "views/Reports/SalesByDomain";
import Clicks from "views/Reports/Clicks";
import UsersPerformance from "views/Reports/UsersPerformance";
import Advertising from "views/Reports/Advertising/Advertising";
import GoogleAds from "views/Reports/GoogleAds";
import GraphSalesLeads from "views/Reports/GraphSalesLeads";
import EinProcessing from "views/Reports/EinProcessing";
import EinPayment from "views/Reports/EinPayment";
// Clicks
import OfferReport from "views/OfferReport";
// Admin
import Offers from "views/Offers";
import EinOptions from "views/Admin/EinOptions";
import VariantsOptions from "views/Admin/VariantsOptions";
import AdAccount from "views/Admin/AdAccounts";
import { Users } from "views/Users";
import HCIndex from "views/Healthcheck/index";
import Credentials from "views/Tickets/Credentials";
import CRMConfig from "views/CRMConfig";

import UserProfile from "views/UserProfile";
import Tickets from "views/Tickets/Index";
import TicketDetail from "views/Tickets/Details";

const dashboardRoutes = [
  {
    layout: "/admin",
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-cart",
    component: DashboardReport,
  },
  {
    collapse: false,
    path: "#",
    name: "Orders",
    icon: "pe-7s-cart",
    layout: "/admin",
    views: [
      {
        path: "/orderlists/all",
        name: "All",
        icon: "pe-7s-cart",
        component: AllCategoriesOrder,
        layout: "/admin",
        submenu: true,
        category: "ALL",
      },
      {
        path: "/orderlists/ein",
        name: "Ein",
        icon: "pe-7s-cart",
        component: EINOrder,
        layout: "/admin",
        submenu: true,
        category: "EIN",
      },
      {
        path: "/orderlists/ein_inc",
        name: "EIN — INC",
        icon: "pe-7s-cart",
        component: EinIncOrder,
        layout: "/admin",
        submenu: true,
        category: "EIN_INC",
      },
      {
        path: "/orderlists/inc",
        name: "Incorpify",
        icon: "pe-7s-cart",
        component: IncorpifyOrder,
        layout: "/admin",
        submenu: true,
        category: "INC",
      },
      {
        path: "/orderlists/inc_state",
        name: "Incorporations States",
        icon: "pe-7s-cart",
        component: IncStatesOrder,
        layout: "/admin",
        submenu: true,
        category: "INC_States",
      },
      {
        path: "/orderlists/vital",
        name: "Vital",
        icon: "pe-7s-cart",
        component: VITALOrder,
        layout: "/admin",
        submenu: true,
        category: "VITAL",
      },
      {
        path: "/orderlists/trademarks",
        name: "Trademarks",
        icon: "pe-7s-cart",
        component: TrademarksOrder,
        layout: "/admin",
        submenu: true,
        category: "TRADEMARKS",
      },
    ],
  },
  {
    path: "/orders/:orderId/",
    name: "Orders Detail",
    icon: "pe-7s-user",
    component: OrderDetail,
    layout: "/admin",
    child: true,
  },
  {
    path: "/orders/:orderId/create-order",
    name: "Create order",
    icon: "pe-7s-user",
    component: CreateOrder,
    layout: "/admin",
    child: true,
  },
  // {
  //   path: "/category/:category/create-order",
  //   name: "Create order",
  //   icon: "pe-7s-user",
  //   component: CreateOrder,
  //   layout: "/admin",
  //   child: true,
  // },
  {
    path: "/orders/:orderId/:productId",
    name: "Orders Detail",
    icon: "pe-7s-user",
    component: OrderDetail,
    layout: "/admin",
    child: true,
  },
  {
    collapse: false,
    path: "#",
    name: "Reports",
    icon: "pe-7s-note",
    layout: "/admin",
    views: [
      {
        path: "/reports/trademarkdashboard",
        name: "Trademark Dashboard",
        component: TrademarkDashboard,
        icon: "pe-7s-graph3",
        layout: "/admin",
        submenu: true,
      },
      {
        path: "/reports/salesbydomain",
        name: "Sales by Domain",
        component: SalesByDomain,
        icon: "pe-7s-note",
        layout: "/admin",
        submenu: true,
      },
      {
        path: "/reports/clicks",
        name: "Clicks",
        component: Clicks,
        icon: "pe-7s-note",
        layout: "/admin",
        submenu: true,
      },
      {
        path: "/reports/advertising",
        name: "Advertising",
        icon: "pe-7s-monitor",
        component: Advertising,
        layout: "/admin",
        submenu: true,
      },
      {
        path: "/reports/googleAdsreport",
        name: "Google Ads Report",
        icon: "pe-7s-display2",
        component: GoogleAds,
        layout: "/admin",
        submenu: true,
      },
      {
        path: "/reports/graphsalesleads",
        name: "Sales",
        component: GraphSalesLeads,
        icon: "pe-7s-note",
        layout: "/admin",
        submenu: true,
      },
      {
        path: "/reports/ein-processing",
        name: "EIN Processing",
        icon: "pe-7s-monitor",
        component: EinProcessing,
        layout: "/admin",
        submenu: true,
      },
      {
        path: "/reports/ein-payment",
        name: "EIN Payment",
        icon: "pe-7s-note",
        component: EinPayment,
        layout: "/admin",
        submenu: true,
      },
      {
        path: "/reports/users-performance",
        name: "User Performance",
        icon: "pe-7s-note",
        component: UsersPerformance,
        layout: "/admin",
        submenu: true,
        allowedRoles: "ADMIN, DEVELOPER",
      },
    ],
  },
  {
    path: "/clicks",
    name: "Clicks",
    icon: "pe-7s-graph1",
    component: OfferReport,
    layout: "/admin",
  },
  {
    collapse: false,
    path: "#",
    name: "Admin",
    icon: "pe-7s-config",
    layout: "/admin",
    allowedRoles: "ADMIN, DEVELOPER",
    views: [
      {
        path: "/offers",
        name: "Offers",
        icon: "pe-7s-news-paper",
        component: Offers,
        layout: "/admin",
        submenu: true,
        parent: "Admin",
      },
      {
        path: "/einoptions",
        name: "EIN Options",
        icon: "pe-7s-config",
        component: EinOptions,
        layout: "/admin",
        submenu: true,
        parent: "Admin",
      },
      {
        path: "/variantsoptions",
        name: "Variants Options",
        icon: "pe-7s-look",
        component: VariantsOptions,
        layout: "/admin",
        submenu: true,
        parent: "Admin",
      },
      {
        path: "/adsaccounts",
        name: "Ads Accounts",
        icon: "pe-7s-users",
        component: AdAccount,
        layout: "/admin",
        submenu: true,
        parent: "Admin",
      },
      {
        path: "/users",
        name: "Users",
        icon: "pe-7s-users",
        component: Users,
        layout: "/admin",
        submenu: true,
        parent: "Admin",
      },
      {
        path: "/healthcheck",
        name: "Healthcheck",
        icon: "pe-7s-tools",
        component: HCIndex,
        layout: "/admin",
        submenu: true,
        parent: "Admin",
      },
      {
        path: "/zendesk",
        name: "Zendesk Configuration",
        icon: "pe-7s-id",
        component: Credentials,
        layout: "/admin",
        submenu: true,
        parent: "Admin",
      },
      {
        path: "/crmConfig",
        name: "CRM Config",
        icon: "pe-7s-settings",
        component: CRMConfig,
        layout: "/admin",
        submenu: true,
        parent: "Admin",
      },
    ],
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "pe-7s-user",
    component: UserProfile,
    layout: "/admin",
    child: true,
  },
  {
    path: "/tickets",
    name: "Tickets",
    icon: "pe-7s-ticket",
    component: Tickets,
    layout: "/admin",
  },
  {
    path: "/ticket/:ticketid",
    name: "Ticket Details",
    icon: "pe-7s-cart",
    component: TicketDetail,
    layout: "/admin",
    child: true,
  },
];

export default dashboardRoutes;
