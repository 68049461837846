import React from "react";

const CompletedFlagStyles = {
  display: "inline-block",
  width: "10px",
  height: "10px",
  borderRadius: "50%",
};

const Indicator = ({ status, margin, title = "" }) => {
  return (
    <div
      title={title}
      style={{
        ...CompletedFlagStyles,
        backgroundColor: status ? "var(--green)" : "var(--red)",
        margin: margin || "0",
      }}
    />
  );
};

export default Indicator;
