export const config = {
  publicUrl: process.env.PUBLIC_URL || "",
  apiUrl: process.env.REACT_APP_API_URL,
  apiUrl1: process.env.REACT_APP_API_URL_1,
  apiUrl2: process.env.REACT_APP_API_URL_2,
  apiUrl3: process.env.REACT_APP_API_URL_3,
  trademarksUrl: process.env.REACT_APP_TRADEMARKS_URL,
  healthcheckurl: process.env.REACT_APP_HEALTH_CHECK_URL,
  serviceurl: process.env.REACT_APP_SERVICE_URL,
  zendesk_url: process.env.REACT_APP_ZENDESK_URL,
  zendesk_token: process.env.REACT_APP_ZENDESK_API_TOKEN,
  zendesk_client_id: process.env.REACT_APP_ZENDESK_CLIENTID,
  zendesk_redirect_uri: process.env.REACT_APP_ZENDESK_REDIRECT_URI,
  membershipUrl: process.env.REACT_APP_MEMBERSHIP_URL,
  incorpifyUrl: process.env.REACT_APP_INCORPIFY_URL,
  incStatesMembershipUrl: process.env.REACT_APP_INC_STATES_MEMBERSHIP_URL,
  einMembershipUrl: process.env.REACT_APP_EIN_MEMBERSHIP_URL,
  incSalePageUrl: process.env.REACT_APP_INC_SALE_PAGE_URL,
  includeTest: process.env.REACT_APP_INCLUDE_TEST_FILTER,
  envName: process.env.REACT_APP_ENV_NAME || "dev",
};
