import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Chartist from "chartist";

import ChartistGraph from "react-chartist";

const StyledChartistGraph = styled(ChartistGraph)`
  height: auto !important;
  min-width: 640px;

  .ct-bar {
    stroke-width: 50;
  }

  .ct-series-a .ct-bar {
    stroke: #afd2ee;
  }

  .ct-series-b .ct-bar {
    stroke: #42a5f5;
  }

  .ct-series-a .ct-barlabel {
    font-size: 0;
  }

  .ct-chart-bar .ct-label.ct-horizontal.ct-end {
    display: flex;
    height: 16px !important;
    width: 100px !important;
    word-break: normal;
    white-space: normal;
    margin: 0 auto;
    font-size: 13px;
    color: #000;
  }

  .ct-chart-bar .ct-label.ct-vertical.ct-start {
    font-size: 12px;
  }

  & > svg {
    height: 430px !important;
  }

  .ct-barlabel {
    font-size: 11px;
  }

  .ct-labels foreignObject {
    height: 40px;
  }
`;

const WarningText = styled.div`
  width: max-content;
  text-align: center;
  margin: 40px auto;
  padding: 16px 8px;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  background-color: #ffcdd2;
`;

const optionsBar = {
  axisX: {
    showGrid: true,
    scaleMinSpace: 80,
    offset: 10,
  },
  axisY: {
    offset: 80,
    labelInterpolationFnc: (value) => (Number.isInteger(value) ? value : ""),
  },
  chartPadding: {
    top: 20,
    right: 10,
    bottom: 50,
    left: 0,
  },
  seriesBarDistance: 0,
};

const GraphVertical = ({ data }) => {
  const [extendedData, setExtendedData] = useState({ labels: [], series: [[], []] });

  const appendLabels = (value) => {
    if (value.type === "bar") {
      const chart = new Chartist.Svg(
        "text",
        {
          x: value.x2,
          y: value.y2 - 5,
          "text-anchor": "middle",
        },
        "ct-barlabel"
      );
      chart.text(value.value.y);
      value.group.append(chart);
    }
  };

  useEffect(() => {
    if (data.labels.length && data.series[0].length) {
      const max = data.series[0].reduce((max, value) => (value > max ? value : max));
      const newData = { ...data, series: [[], ...data.series] };
      if (max === 0) {
        data.series[0].forEach(() => newData.series[0].push(1));
      } else {
        data.series[0].forEach(() => newData.series[0].push(max + max * 0.1));
      }

      setExtendedData(newData);
    }
  }, [data]);

  return (
    <>
      {data.series[0]?.length && data.labels.length ? (
        <StyledChartistGraph
          style={{ minWidth: data.labels?.length ? data.labels.length * 120 : 0 }}
          data={extendedData}
          type="Bar"
          options={optionsBar}
          listener={{ draw: appendLabels }}
        />
      ) : (
        <WarningText>Select as least one status to show or change date range</WarningText>
      )}
    </>
  );
};

export default GraphVertical;
