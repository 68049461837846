import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Badge, Col, Table, Pagination, Form, Button, Row } from "react-bootstrap";
import moment from "moment-timezone";
import axios from "axios";

import { config as initConfig } from "_config";
import { authHeader, getZConfig, history } from "_helpers";
import { alertActions, zendeskactions } from "_actions";

import LoadingSpinner from "components/Spinner";
import zendesk_icon from "assets/img/zendesk.png";

function Tickets(props) {
  const dispatch = useDispatch();
  const config = { ...initConfig, ...getZConfig() };
  const [tickets, setTickets] = useState([]);
  const [nextlink, setNextlink] = useState(null);
  const [prevlink, setPrevlink] = useState(null);
  const [counts, setCounts] = useState(0);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [page, setpage] = useState(1);
  const [linkedorders, setLinkedorders] = useState([]);
  const items = [];
  const badge_style = {
    new: "warning",
    open: "danger",
    pending: "primary",
    solved: "info",
    closed: "dark",
  };

  const headers = {
    headers: { Authorization: "Bearer " + localStorage.getItem("zauth") },
  };

  const app_header = {
    headers: authHeader(),
  };

  const ticketfilter = useSelector((state) => state.ticketfilter);

  const firstPage = () => {
    setpage(1);
    getalltickets(1);
  };

  const lastPage = () => {
    setpage(Math.ceil(counts / 25));
    getalltickets(Math.ceil(counts / 25));
  };

  const prevPage = () => {
    const new_page = page - 1;
    setpage(new_page);
    getalltickets(new_page);
  };

  const nextPage = () => {
    const new_page = page + 1;
    setpage(new_page);
    getalltickets(new_page);
  };

  const previouse_step = (e) => {
    let new_page = 0;
    if (page % 5 === 0) {
      new_page = (page / 5 - 2) * 5 + 1;
    } else {
      new_page = (Math.floor(page / 5) - 1) * 5 + 1;
    }
    setpage(new_page);
    getalltickets(new_page);
  };

  const next_step = (e) => {
    let new_page = 0;
    if (page % 5 === 0) {
      new_page = page + 1;
    } else {
      new_page = (Math.floor(page / 5) + 1) * 5 + 1;
    }
    setpage(new_page);
    getalltickets(new_page);
  };

  const pageChanged = (e) => {
    setpage(e.target.text);
    getalltickets(e.target.text);
  };

  const getlinkedorders = (tickets) => {
    let dataArray = [];

    for (var o in tickets) {
      dataArray.push(tickets[o].id);
    }
    let url = `${config.apiUrl1}/zendesk/tickets`;

    for (let i = 0; i < dataArray.length; i++) {
      const id = dataArray[i];
      if (i === 0) url += "?ids=" + id;
      else url += "&ids=" + id;
    }

    axios
      .get(url, app_header)
      .then((res) => {
        setLinkedorders(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getalltickets = (selectedpage) => {
    let currentpage;
    if (selectedpage) currentpage = selectedpage;
    else currentpage = page;
    setLoading(true);

    let ticketstatus;
    if (ticketfilter) {
      setStatus(ticketfilter.status);
      ticketstatus = ticketfilter.status;
      if (!selectedpage) {
        setpage(ticketfilter.page);
        currentpage = ticketfilter.page;
      }
    } else {
      ticketstatus = status;
    }

    let url;
    if (ticketstatus === "")
      url = `${config.zendesk_url}/api/v2/search.json?query=type:ticket&per_page=25&sort_by=created_at&sort_order=desc&page=${currentpage}`;
    else
      url = `${config.zendesk_url}/api/v2/search.json?query=type:ticket+status:${ticketstatus}&per_page=25&sort_by=created_at&sort_order=desc&page=${currentpage}`;
    axios
      .get(url, headers)
      .then((res) => {
        setTickets(res.data.results);
        getlinkedorders(res.data.results);
        setPrevlink(res.data.previous_page);
        setNextlink(res.data.next_page);
        setCounts(res.data.count);
        setLoading(false);

        dispatch(
          zendeskactions.savefilter({
            status: ticketstatus,
            page: currentpage,
          })
        );
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const searchticket = (e) => {
    setLoading(true);
    let url;
    if (status === "")
      url = `${config.zendesk_url}/api/v2/search.json?query=type:ticket&per_page=25&sort_by=created_at&sort_order=desc&page=1`;
    else
      url = `${config.zendesk_url}/api/v2/search.json?query=type:ticket+status:${status}&per_page=25&sort_by=created_at&sort_order=desc&page=1`;
    axios
      .get(url, headers)
      .then((res) => {
        setTickets(res.data.results);
        setPrevlink(res.data.previous_page);
        setNextlink(res.data.next_page);
        setCounts(res.data.count);
        setLoading(false);
        setpage(1);
        dispatch(
          zendeskactions.savefilter({
            status: status,
            page: 1,
          })
        );
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const openorderdetail = (ticketid) => {
    history.push("/admin/ticket/" + ticketid);
  };

  const totalpages = Math.ceil(counts / 25);

  if (tickets) {
    let step = 0;
    if (page % 5 === 0) step = page / 5 - 1;
    else step = Math.floor(page / 5);
    if (step > 0) items.push(<Pagination.Ellipsis onClick={previouse_step} />);

    for (let i = 1; i <= totalpages; i++) {
      if (i > step * 5 && i <= step * 5 + 5) {
        items.push(
          <Pagination.Item
            key={i}
            active={Number(page) === i}
            disabled={Number(page) === i}
            onClick={(e) => pageChanged(e)}
          >
            {i}
          </Pagination.Item>
        );
      }
    }
    if (totalpages > (step + 1) * 5) items.push(<Pagination.Ellipsis onClick={next_step} />);
  }

  const readUrlParam = (url, param) => {
    param += "=";
    if (url.indexOf(param) !== -1) {
      var start = url.indexOf(param) + param.length;
      var value = url.substr(start);
      if (value.indexOf("&") !== -1) {
        var end = value.indexOf("&");
        value = value.substring(0, end);
      }
      return value;
    } else {
      return false;
    }
  };

  const authorize_zendesk = (e) => {
    localStorage.removeItem("zconfig");
    var endpoint = "https://us-taxid-filing.zendesk.com/oauth/authorizations/new";
    var url_params =
      "?" +
      "response_type=token" +
      "&" +
      "redirect_uri=" +
      config.zendesk_redirect_uri +
      "&" +
      "client_id=" +
      config.zendesk_client_id +
      "&" +
      "scope=" +
      encodeURIComponent("read write");
    window.location = endpoint + url_params;
  };

  const url_hash = props.location.hash;
  if (url_hash.indexOf("access_token=") !== -1) {
    var access_token = readUrlParam(url_hash, "access_token");
    localStorage.setItem("zauth", access_token);
    window.location = config.zendesk_redirect_uri;
  }

  useEffect(() => {
    if (localStorage.getItem("zauth")) {
      getalltickets(null);
    }
  }, []);

  if (!localStorage.getItem("zauth")) {
    return (
      <Container fluid className="mt-5 py-5 fixed-height tickets">
        <div className="text-center zendesk-authorize mt-5 pt-5">
          <h3>You have to authorize Zendesk to load this page.</h3>

          <h4>
            InnovaLab CRM <br /> +
          </h4>
          <img src={zendesk_icon} alt="logo_image" />

          <Button onClick={authorize_zendesk}>Authorize</Button>
        </div>
      </Container>
    );
  }

  return (
    <>
      <Container fluid className="mt-5 py-4 mb-5 fixed-height tickets">
        <Form className="mb-2" inline>
          <Form.Label className="mr-1" htmlFor="ticket_status">
            Ticket Status:
          </Form.Label>
          <Form.Control
            as="select"
            name="ticket_status"
            size="sm"
            id="ticket_status"
            className="mr-1"
            onChange={(e) => setStatus(e.target.value)}
            value={status}
          >
            <option value="">Please select</option>
            <option value="new">New</option>
            <option value="pending">Pending</option>
            <option value="solved">Solved</option>
            <option value="open">Open</option>
            <option value="closed">Closed</option>
          </Form.Control>
          <Button size="sm" onClick={searchticket}>
            Search
          </Button>

          <Form.Label className="ml-5">Results Found: {counts}</Form.Label>

          <Form.Label className="ml-5">Current Page: {page}</Form.Label>
        </Form>

        <Table striped bordered responsive size="sm">
          <thead>
            <tr>
              <th>Ticket Id</th>
              <th>Date Created</th>
              <th>Subject</th>
              <th>E-Mail</th>
              <th>Status</th>
              <th>Keywords</th>
              <th>View Ticket</th>
              <th>View Order</th>
            </tr>
          </thead>
          <tbody>
            {!loading && (
              <>
                {tickets.map((prop, key) => {
                  return (
                    <React.Fragment key={key}>
                      <tr>
                        <td>{prop.id}</td>
                        <td>{moment.utc(prop.created_at).local().format("YYYY-MM-DD HH:mm:ss")}</td>
                        <td>{prop.subject}</td>
                        <td>
                          {prop.via.channel === "web" && "Via Web"}
                          {prop.via.channel === "sample_ticket" && "Sample Ticket"}
                          {prop.via.channel === "email" && prop.via.source.from.address}
                        </td>

                        <td>
                          <Badge variant={badge_style[prop.status]}>{prop.status}</Badge>
                        </td>
                        <td>
                          {linkedorders[linkedorders.findIndex((x) => x.id === "" + prop.id)] ? (
                            <>
                              {linkedorders[linkedorders.findIndex((x) => x.id === "" + prop.id)][
                                "detectedKeywords"
                              ].join(", ")}
                            </>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          <Button className="btn btn-sm" size="sm" onClick={(e) => openorderdetail(prop.id)}>
                            View
                          </Button>
                        </td>
                        <td>
                          {linkedorders[linkedorders.findIndex((x) => x.id === "" + prop.id)] ? (
                            <>
                              <Link
                                to={
                                  "/admin/orders/" +
                                  linkedorders[linkedorders.findIndex((x) => x.id === "" + prop.id)]["orderUid"]
                                }
                                className="dropdown-item"
                              >
                                {linkedorders[linkedorders.findIndex((x) => x.id === "" + prop.id)]["orderCRMId"]}
                              </Link>
                            </>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </tbody>
        </Table>
        {loading ? (
          <LoadingSpinner></LoadingSpinner>
        ) : (
          <Pagination className="ml-2 mt-2">
            <Pagination.First disabled={prevlink ? false : true} onClick={firstPage} />
            <Pagination.Prev disabled={prevlink ? false : true} onClick={prevPage} />
            {items}
            <Pagination.Next disabled={nextlink ? false : true} onClick={nextPage} />
            <Pagination.Last disabled={nextlink ? false : true} onClick={lastPage} />
          </Pagination>
        )}
      </Container>
    </>
  );
}

export default Tickets;
