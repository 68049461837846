import { Row, Col, Form } from "react-bootstrap";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import IndividualEdit from "../Individual/Edit";
import NewAddressEdit from "../NewAddress/Edit";
import StatusEdit from "../../Status/Edit";

import { TAB_CODES } from "variables/Variables";

export default function FormTrademarkOfficeActionResponse({
  orderDetails,
  selectedProduct,
  onError,
  onChange,
  ...props
}) {
  const productDetails = orderDetails.products[selectedProduct];

  const handleAddError = (field) => onError(TAB_CODES.trademarkInfo, field);

  const handleProductChange = ({ target: { name, value } }) => {
    let exFields = { [name]: value };

    if (name === "newSpecimenWasUploaded") exFields = { [name]: value === "yes" };

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, ...exFields } : product
      ),
    });
  };

  const handleProductNewAddressChange = (value) => handleProductChange({ target: { name: "newAddress", value } });
  const handleProductTrademarkOwnerChange = (value) =>
    handleProductChange({ target: { name: "trademarkOwner", value } });
  const handleProductOwnerChange = (value) => handleProductChange({ target: { name: "owner", value } });
  const handleProductChangesToOwnerChange = (value) =>
    handleProductChange({ target: { name: "changesToOwner", value } });

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">OFFICE ACTION RESPONSE TRADEMARK</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
      />

      <FormPDF productDetails={productDetails} {...props} />

      <hr />
      <h6 className="font-weight-bold">Mark Information</h6>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Serial Number:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="serialNumber"
            value={productDetails.serialNumber || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Serial Number")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Trademark Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="trademarkName"
            value={productDetails.trademarkName || ""}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <hr />
      <IndividualEdit
        label="Trademark Owner"
        individual={productDetails.trademarkOwner}
        onError={handleAddError}
        onChange={handleProductTrademarkOwnerChange}
      />

      <hr />
      <NewAddressEdit
        label="New Address"
        newAddress={productDetails.newAddress}
        onError={handleAddError}
        onChange={handleProductNewAddressChange}
      />

      <hr />

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Changes to drawing:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="changesToDrowing"
            value={productDetails.changesToDrowing || ""}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Change to Trademark Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="changesToTrademarkName"
            value={productDetails.changesToTrademarkName || ""}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          New specimen was uploaded?
        </Form.Label>
        <Col sm={7} className="d-flex align-items-center">
          <Form.Check
            id="newSpecimenWasUploaded_r_no"
            className="mr-3"
            type="radio"
            label="no"
            name="newSpecimenWasUploaded"
            value="no"
            defaultChecked={!productDetails.newSpecimenWasUploaded}
            onChange={handleProductChange}
          />
          <Form.Check
            id="newSpecimenWasUploaded_r_yes"
            type="radio"
            label="yes"
            name="newSpecimenWasUploaded"
            value="yes"
            defaultChecked={productDetails.newSpecimenWasUploaded}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <hr />
      <IndividualEdit
        label="Changes to Owner"
        individual={productDetails.changesToOwner}
        onError={handleAddError}
        onChange={handleProductChangesToOwnerChange}
      />
    </div>
  );
}
