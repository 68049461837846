import { Col, Form, Row } from "react-bootstrap";
import InputMask from "react-input-mask";
import clsx from "clsx";

import { phoneNumRegexp, phoneCleaner } from "_helpers";

import StatusEdit from "../../Status/Edit";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import { state_array, TAB_CODES } from "variables/Variables";

export default function FormTrademarkPrivacyPackage({ orderDetails, selectedProduct, onChange, onError, ...props }) {
  const productDetails = orderDetails.products[selectedProduct];

  const handleAddError = (field) => onError(TAB_CODES.trademarkInfo, field);

  const handleProductChange = ({ target: { name, value } }) => {
    const exFields = { [name]: value };

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, ...exFields } : product
      ),
    });
  };

  const handlePhoneChange = ({ target: { name, value } }) => {
    const newData = phoneCleaner(value);

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, [name]: newData } : product
      ),
    });
  };

  const handleVirtualAddressChange = ({ target: { name, value } }) => {
    const exFields = { [name]: value };

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct
          ? { ...product, virtualAddress: { ...productDetails.virtualAddress, ...exFields } }
          : product
      ),
    });
  };

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">PRIVACY PACKAGE</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
      />

      <FormPDF productDetails={productDetails} {...props} />

      <hr />
      <h6 className="font-weight-bold">Private Info</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Private Phone Number:
        </Form.Label>
        <Col sm={7}>
          <InputMask
            className={clsx("form-control", "form-control-sm", {
              "is-invalid": productDetails?.phoneNumber && !phoneNumRegexp.test(productDetails?.phoneNumber),
            })}
            mask="(999) 999-9999"
            maskChar=" "
            name="phoneNumber"
            placeholder="(999) 999-9999"
            pattern="((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}"
            value={phoneCleaner(productDetails?.phoneNumber) || ""}
            onChange={handlePhoneChange}
            onInvalid={() => handleAddError("Private Phone")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Private Email:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="email"
            pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
            value={productDetails.email}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Private Email")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm="12">
          Virtual Address:
        </Form.Label>
        <Col sm="12">
          {productDetails.virtualAddress && (
            <div className="pl-3">
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Address1:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    required
                    name="address1"
                    pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
                    onChange={handleVirtualAddressChange}
                    value={productDetails.virtualAddress.address1 || ""}
                    onInvalid={() => handleAddError("Address1")}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Address2:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    name="address2"
                    pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
                    onChange={handleVirtualAddressChange}
                    value={productDetails.virtualAddress.address2 || ""}
                    onInvalid={() => handleAddError("Address2")}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  City:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    required
                    name="city"
                    pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
                    onChange={handleVirtualAddressChange}
                    value={productDetails.virtualAddress.city || ""}
                    onInvalid={() => handleAddError("City")}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  County:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    name="county"
                    pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
                    onChange={handleVirtualAddressChange}
                    value={productDetails.virtualAddress.county || ""}
                    onInvalid={() => handleAddError("County")}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  State:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    required
                    as="select"
                    name="state"
                    onChange={handleVirtualAddressChange}
                    value={productDetails.virtualAddress.state || ""}
                    onInvalid={() => handleAddError("State")}
                  >
                    <option value="">Select a state</option>
                    {Object.keys(state_array).map((key) => (
                      <option key={key} value={key}>
                        {state_array[key]}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Zip Code:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    required
                    name="zip"
                    pattern="^\d{5}$"
                    onChange={handleVirtualAddressChange}
                    value={productDetails.virtualAddress.zip || ""}
                    onInvalid={() => handleAddError("Zip")}
                  />
                </Col>
              </Form.Group>
            </div>
          )}
        </Col>
      </Form.Group>
    </div>
  );
}
