import { capitalizeString } from "_helpers/formatter";
import orderBy from "lodash.orderby";

export function getBillsOfSelectedProduct(subscriptionDetails, productId) {
  const bills = subscriptionDetails[productId]?.bills;
  return orderBy(bills || [], ["billNumber"], ["desc"]);
}

export function getSubscriptionDetailsOfSelectedProduct(subscriptionDetails = {}, productId) {
  return subscriptionDetails[productId] || null;
}

export function getSubscriptionStatus(orderDetails, selectedProduct, subscriptionDetails) {
  const { id } = orderDetails?.products[selectedProduct] || {};
  const { status, processingError } = orderDetails?.products[selectedProduct]?.processingResult || {};

  const { subscriptionStatus } = getSubscriptionDetailsOfSelectedProduct(subscriptionDetails, id) || {};

  if (status === "Error" && processingError === "CardDeclined") {
    return "Declined";
  }

  if (subscriptionStatus) {
    return capitalizeString(subscriptionStatus);
  }

  if (status === "Cancelled") {
    return "Cancelled";
  }

  return "Active";
}
