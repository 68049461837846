export const GOODS_TYPES = {
  "001": "Chemicals",
  "002": "Paints",
  "003": "Cosmetics and Cleaning Products",
  "004": "Lubricants and Fuels",
  "005": "Pharmaceuticals",
  "006": "Metal Products",
  "007": "Machinery",
  "008": "Hand Tools",
  "009": "Computer and Software and Electrical and Scientific Products",
  "010": "Medical Instruments",
  "011": "Environmental Control Apparatus",
  "012": "Vehicles",
  "013": "Firearms and Explosives and Fireworks",
  "014": "Jewelry and Precious Metals and Watches",
  "015": "Musical Instruments",
  "016": "Paper Goods and Printed Material",
  "017": "Rubber Products",
  "018": "Leather Products (not clothing)",
  "019": "Building Materials (non-metallic)",
  "020": "Furniture",
  "021": "Houseware and Glass Products",
  "022": "Ropes and Tents and Sails and Bags and Fiber products",
  "023": "Yarns and Threads",
  "024": "Fabrics and Textiles",
  "025": "Clothing and Footwear",
  "026": "Lace and Ribbons and Embroidery and Fancy Goods",
  "027": "Floor Coverings",
  "028": "Toys and Sporting Goods",
  "029": "Meat and Fish and Poultry and Processed Food Products",
  "030": "Staple Foods",
  "031": "Natural Agricultural Products",
  "032": "Light Beverages",
  "033": "Wines and Spirits",
  "034": "Smokers Articles",
};

export const SERVICES_TYPES = {
  "035": "Advertising and Business Management and Office Functions",
  "036": "Insurance and Financial and Real Estate Affairs",
  "037": "Construction and Repair and Installation Services",
  "038": "Communication Services",
  "039": "Transport and Storage and Travel",
  "040": "Treatment of Materials",
  "041": "Education and Entertainment and Sporting and Cultural Activities",
  "042": "Computer and Software services and Scientific Services",
  "043": "Restaurant and Hotel Services",
  "044": "Medical and Veterinarian Services",
  "045": "Legal and Personal and Security services",
};
