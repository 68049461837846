import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import StatusEdit from "./Status/Edit";

function FormDefaultEdit({ orderDetails, selectedProduct, onChange, onError }) {
  const productDetails = orderDetails?.products[selectedProduct];

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">{productDetails?.code?.customerName}</h4>

      <StatusEdit orderDetails={orderDetails} selectedProduct={selectedProduct} onChange={onChange} onError={onError} />

      <FormPDF productDetails={productDetails} />
    </div>
  );
}

export default FormDefaultEdit;
