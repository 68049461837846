import { Fragment } from "react";
import { Row, Form } from "react-bootstrap";

export default function GlobalTrademarkResultList({ hidden, globalSearchs }) {
  if (hidden || !globalSearchs) return null;

  return (
    <>
      <hr />
      <h6 className="font-weight-bold">Global Search results:</h6>

      {globalSearchs.length > 0 ? (
        globalSearchs.map((globalSearch, idx) => (
          <Fragment key={idx}>
            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Trademark number:
              </Form.Label>
              <Form.Label column sm={7}>
                {globalSearch.trademarkNumber}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Type:
              </Form.Label>
              <Form.Label column sm={7}>
                {globalSearch.type}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Filling date:
              </Form.Label>
              <Form.Label column sm={7}>
                {globalSearch.fillingDate}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Registration date:
              </Form.Label>
              <Form.Label column sm={7}>
                {globalSearch.registrationDate}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Nice classification:
              </Form.Label>
              <Form.Label column sm={7}>
                {globalSearch.niceClassification}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Status:
              </Form.Label>
              <Form.Label column sm={7}>
                {globalSearch.trademarkStatus}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Basis:
              </Form.Label>
              <Form.Label column sm={7}>
                {globalSearch.basis}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Reference:
              </Form.Label>
              <Form.Label column sm={7}>
                {globalSearch.reference}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Owner ID:
              </Form.Label>
              <Form.Label column sm={7}>
                {globalSearch.ownerIdNumber}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Owner name:
              </Form.Label>
              <Form.Label column sm={7}>
                {globalSearch.ownerName}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Representative ID:
              </Form.Label>
              <Form.Label column sm={7}>
                {globalSearch.representativeIdNumber}
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Representative name:
              </Form.Label>
              <Form.Label column sm={7}>
                {globalSearch.representativeName}
              </Form.Label>
            </Form.Group>
          </Fragment>
        ))
      ) : (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            No Global search results
          </Form.Label>
        </Form.Group>
      )}
    </>
  );
}
