import { Form, Row, Col } from "react-bootstrap";
import moment from "moment-timezone";
import styled from "styled-components";

import { month_array } from "variables/Variables";

const StatusText = styled.span`
  color: gray;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export default function DateEdit({ label, date, hasInitial = false, isCanUnset = true, onChange = () => {} }) {
  function datechange({ target: { name, value } }) {
    const formatDate = date ? moment(date) : null;
    const oldDate = moment(formatDate || new Date())
      .format("YYYY-MM-DD")
      .split("-");

    const newVal = value < 10 ? "0" + value : value;

    if (name === "year") {
      oldDate[0] = newVal;
      // oldDate[2] = "01";
    }
    if (name === "month") {
      oldDate[1] = newVal;
      oldDate[2] = "01";
    }
    if (name === "day") {
      oldDate[2] = newVal;
    }

    const leapYear = (oldDate[0] % 4 === 0 && oldDate[0] % 100 !== 0) || oldDate[0] % 400 === 0;

    if (!leapYear && oldDate[1] === "02" && oldDate[2] === "29") oldDate[2] = "28";

    const newDate = oldDate.join("-") + "T00:00:00";

    onChange(moment(newDate).format("YYYY-MM-DD"));
  }

  const yearRange = [];
  const dateRange = [];
  const thisYear = new Date().getFullYear();

  let d = new Date(moment().format("YYYY-MM-DDTHH:mm:ss"));
  if (Boolean(date) || hasInitial) {
    d = new Date(moment(date || new Date()).format("YYYY-MM-DDTHH:mm:ss"));
  }

  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  const day = d.getDate();
  const leapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

  let day_array = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (month === 2 && leapYear) {
    day_array[1] = 29;
  }

  for (let i = 1; i <= day_array[d.getMonth()]; i++) {
    dateRange.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  for (let i = 1990, key = 0; i <= thisYear; i++, key++) {
    yearRange.push(
      <option key={key} value={i}>
        {i}
      </option>
    );
  }

  return (
    <Form.Group as={Row}>
      {label && (
        <Form.Label column sm={5}>
          {label}{" "}
          {!date ? (
            <StatusText title="Click to set the date" onClick={datechange}>
              (not set) <i className="fa fa-plus" />
            </StatusText>
          ) : (
            <StatusText title="Click to unset the date" onClick={() => isCanUnset && onChange(null)}>
              (is set) {isCanUnset && <i className="fa fa-trash" />}
            </StatusText>
          )}
        </Form.Label>
      )}
      <Col sm={label ? 7 : 12} className="d-flex align-items-center">
        <Form.Control
          required={Boolean(date)}
          as="select"
          name="month"
          size="sm"
          value={Boolean(date) || hasInitial ? month : ""}
          onChange={datechange}
        >
          <option hidden value="" />
          {Object.keys(month_array).map((prop, key) => (
            <option key={key} value={prop}>
              {month_array[prop]}
            </option>
          ))}
        </Form.Control>
        <Form.Control
          required={Boolean(date)}
          as="select"
          name="day"
          size="sm"
          value={Boolean(date) || hasInitial ? day : ""}
          onChange={datechange}
        >
          <option hidden value="" />
          {dateRange}
        </Form.Control>
        <Form.Control
          required={Boolean(date)}
          as="select"
          name="year"
          size="sm"
          value={Boolean(date) || hasInitial ? year : ""}
          onChange={datechange}
        >
          <option hidden value="" />
          {yearRange}
        </Form.Control>
      </Col>
    </Form.Group>
  );
}
