import { userlogsConstants } from '../_constants';
import { userlogsService } from '../_services';

export const userlogsActions = {
  getAll,
  exportcsv,
};

function getAll(start_date, end_date, http_mothod, uri, payload, Ipaddress, responseCode, username) {
  return (dispatch) => {
    dispatch(request());

    userlogsService
      .getAll(start_date, end_date, http_mothod, uri, payload, Ipaddress, responseCode, username)
      .then(
        (result) => dispatch(success(result)),
        (error) => dispatch(failure(error.toString())),
      );
  };

  function request() {
    return { type: userlogsConstants.GETALL_REQUEST };
  }
  function success(result) {
    return { type: userlogsConstants.GETALL_SUCCESS, result };
  }
  function failure(error) {
    return { type: userlogsConstants.GETALL_FAILURE, error };
  }
}

function exportcsv(start_date, end_date, http_mothod, uri, payload, Ipaddress, responseCode, username) {
  return (dispatch) => {
    userlogsService
      .exportcsv(start_date, end_date, http_mothod, uri, payload, Ipaddress, responseCode, username)
      .then(
        (result) => {},
        (error) => {},
      );
  };
}
