import { Form, Row } from "react-bootstrap";
import moment from "moment-timezone";

import { month_array } from "variables/Variables";

export default function DateView({ productDetails }) {
  let dateLabel = "Date entity was started or acquired";

  let dateValue = "N/A";

  if (Boolean(productDetails.startDate)) {
    dateValue = moment(productDetails.startDate).format("MM/DD/YYYY");
  }

  switch (productDetails.code.code) {
    case "EIN_Trust":
      dateLabel = "Date Trust was started or acquired";
      break;
    case "EIN_Estate":
      dateLabel = "Date of Death";
      if (Boolean(productDetails.dateOfDeath)) {
        dateValue = moment(productDetails.dateOfDeath).format("MM/DD/YYYY");
      }
      break;
    default:
      break;
  }

  return (
    <>
      <hr />
      <h6 className="font-weight-bold">Dates</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          {dateLabel}
        </Form.Label>
        <Form.Label column sm={7}>
          {dateValue}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Closing month of accounting year
        </Form.Label>
        <Form.Label column sm={7}>
          {month_array[productDetails.closingMonth] || "N/A"}
        </Form.Label>
      </Form.Group>
    </>
  );
}
