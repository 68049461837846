import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import InputMask from "react-input-mask";

import { checkIsIncStates, checkIsIncorpify } from "_helpers";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import ActivityEdit from "../Activity/Edit";
import AllQuestionEdit from "../GeneralQuestion/Edit/All";
import AltAddressEdit from "../AltAddress/Edit";
import CorpAddressEdit from "../CorpAddress/Edit";
import DateEdit from "../Date/Edit";

import StatusEdit from "../../Status/Edit";

import { suffixArray, EIN_title_array, state_array, TAB_CODES } from "variables/Variables";

function FormLLC({ orderDetails, selectedProduct, onError, onChange }) {
  const productDetails = orderDetails.products[selectedProduct];
  const llcIncorporationProduct = orderDetails?.products?.find(
    (p) => checkIsIncorpify(p.code.code) || checkIsIncStates(p.code.code)
  );
  const hasLLCIncorporationProduct = Boolean(llcIncorporationProduct);

  const handleAddError = (field) => onError(TAB_CODES.einInfo, field);

  const handleProductChange = ({ target: { name, value, values } }) => {
    const extFields = values
      ? values
      : { [name]: ["husbandAndWife", "multiMember"].includes(name) ? value === "Yes" : value };

    const newObj = {
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, ...extFields } : product
      ),
    };

    if (name === "activityPrimaryActivity") {
      newObj.products[selectedProduct].activitySpecificProducts = null;
      newObj.products[selectedProduct].productDescription = null;
      newObj.products[selectedProduct].productExtraQuestion1 = null;
      newObj.products[selectedProduct].productExtraQuestion2 = null;
      newObj.products[selectedProduct].productExtraQuestion3 = null;
    }

    if (name === "activitySpecificProducts") {
      newObj.products[selectedProduct].productDescription = null;
      newObj.products[selectedProduct].productExtraQuestion1 = null;
      newObj.products[selectedProduct].productExtraQuestion2 = null;
      newObj.products[selectedProduct].productExtraQuestion3 = null;
    }

    onChange(newObj);
  };

  const handleProductOwnerChange = ({ target: { name, value } }) =>
    handleProductChange({
      target: {
        name: "owner",
        value: {
          ...productDetails?.owner,
          [name]: ["suffixName", "ssn"].includes(name) && value === "" ? null : value,
        },
      },
    });

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">LIMITED LIABILITY COMPANY</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
      />

      <FormPDF productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">Legal information</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Legal Name of the LLC:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            readOnly={hasLLCIncorporationProduct}
            size="sm"
            type="text"
            name="legalName"
            pattern="^[ a-zA-Z0-9\.\/,\\;\-:_'~˜`’\(\)\x22\[\]\?<>!\{\}@#\$%\^&\*\+=]+$"
            value={productDetails?.legalName || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Legal Name of the LLC")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Trade name or DBA of the LLC:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="dbaname"
            pattern="^[ a-zA-Z0-9\.\/,\\;\-:_'~˜`’\(\)\x22\[\]\?<>!\{\}@#\$%\^&\*\+=]+$"
            value={productDetails?.dbaname || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Trade name or DBA of the LLC")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Number of members in the LLC:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            readOnly={hasLLCIncorporationProduct}
            required
            size="sm"
            type="number"
            name="numberOfMembers"
            min="1"
            value={productDetails?.numberOfMembers || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Number of members in the LLC")}
          />
        </Col>
      </Form.Group>

      {productDetails?.numberOfMembers == 2 && (
        <>
          <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Are the members husband and wife?
            </Form.Label>
            <Col sm={7} className="pl-5">
              <Form.Check
                id="husbandAndWifefalse"
                className="mr-3"
                type="radio"
                label="No"
                name="husbandAndWife"
                defaultChecked={!productDetails?.husbandAndWife}
                value="No"
                onChange={handleProductChange}
              />
              <Form.Check
                id="husbandAndWifetrue"
                type="radio"
                label="Yes"
                name="husbandAndWife"
                defaultChecked={productDetails?.husbandAndWife}
                value="Yes"
                onChange={handleProductChange}
              />
            </Col>
          </Form.Group>

          {productDetails?.husbandAndWife && (
            <Form.Group as={Row}>
              <Form.Label column sm={12}>
                Please choose one of the following:
              </Form.Label>
              <Col sm={12} className="pl-5">
                <Form.Check
                  id="multiMembertrue"
                  className="mr-3"
                  type="radio"
                  label="We select to be classified as a multi-member LLC."
                  name="multiMember"
                  value="Yes"
                  defaultChecked={productDetails?.multiMember}
                  onChange={handleProductChange}
                />
                <Form.Check
                  id="multiMemberfalse"
                  type="radio"
                  label="We select to be classified as a single-member LLC."
                  name="multiMember"
                  defaultChecked={!productDetails?.multiMember}
                  value="No"
                  onChange={handleProductChange}
                />
              </Col>
            </Form.Group>
          )}
        </>
      )}

      <Form.Group as={Row}>
        <Form.Label column sm={12}>
          Select the Tax classification of the LLC:
        </Form.Label>
        <Col sm={12} className="pl-5">
          <Form.Check
            id="tax_first"
            type="radio"
            label="Individual/Partnership: Not taxed as a separate entity from owner(s)."
            name="taxClassification"
            className="mr-3"
            defaultChecked={productDetails?.taxClassification === "Individual"}
            value="Individual"
            onChange={handleProductChange}
          />
          <Form.Check
            id="tax_sec"
            type="radio"
            label="S-Corporation: Planning to elect an S-Corporation tax structure."
            name="taxClassification"
            defaultChecked={productDetails?.taxClassification === "SCorp"}
            value="SCorp"
            onChange={handleProductChange}
          />
          <Form.Check
            id="tax_third"
            type="radio"
            label="Corporation: LLC is planning to elect a Corporation tax structure."
            name="taxClassification"
            defaultChecked={productDetails?.taxClassification === "Corporation"}
            value="Corporation"
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          In which state the LLC was incorporated?
        </Form.Label>
        <Col sm={7}>
          {hasLLCIncorporationProduct ? (
            <Form.Control
              readOnly
              required
              size="sm"
              name="organizedState"
              value={state_array[productDetails?.organizedState] || ""}
              onChange={handleProductChange}
              onInvalid={() => handleAddError("In which state the LLC was incorporated?")}
            />
          ) : (
            <Form.Control
              as="select"
              name="organizedState"
              value={productDetails?.organizedState || ""}
              onChange={handleProductChange}
              onInvalid={() => handleAddError("In which state the LLC was incorporated?")}
            >
              <option value="">Select a state</option>
              {Object.keys(state_array).map((key) => (
                <option key={key} value={key}>
                  {state_array[key]}
                </option>
              ))}
            </Form.Control>
          )}
        </Col>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Managing Member Information</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="firstName"
            pattern="^(^[a-zA-Z0-9 \/\-\\]{1,30})$"
            value={productDetails?.owner?.firstName || ""}
            onChange={handleProductOwnerChange}
            onInvalid={() => handleAddError("First Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Middle Name (optional):
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="middleName"
            pattern="^(^[a-zA-Z0-9 \/\-\\]{1,30})$"
            value={productDetails?.owner?.middleName || ""}
            onChange={handleProductOwnerChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Last Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="lastName"
            pattern="^(^[a-zA-Z0-9 \/\-\\]{1,30})$"
            value={productDetails?.owner?.lastName || ""}
            onChange={handleProductOwnerChange}
            onInvalid={() => handleAddError("Last Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Suffix Name (optional):
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            as="select"
            name="suffixName"
            value={productDetails?.owner?.suffixName || ""}
            onChange={handleProductOwnerChange}
            onInvalid={() => handleAddError("Suffix Name")}
          >
            <option value="">Please select</option>
            {suffixArray.map((prop, key) => (
              <option key={key} value={prop}>
                {prop}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Title:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            as="select"
            name="title"
            value={productDetails?.owner?.title || ""}
            onChange={handleProductOwnerChange}
            onInvalid={() => handleAddError("Title")}
          >
            {Object.keys(EIN_title_array).map((prop, key) => (
              <option key={key} value={prop}>
                {EIN_title_array[prop]}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Social Security Number:
        </Form.Label>
        <Col sm={7}>
          <InputMask
            className="form-control form-control-sm"
            mask="999-99-9999"
            maskChar=" "
            name="ssn"
            placeholder="999-99-9999"
            pattern="^\d{3}-\d{2}-\d{4}$"
            value={productDetails?.owner?.ssn || ""}
            onChange={handleProductOwnerChange}
            onInvalid={() => handleAddError("Social Security Number")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Previous First Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="prevFirstName"
            pattern="^(^[a-zA-Z0-9 \/\-\\]{1,30})$"
            value={productDetails?.owner?.prevFirstName || ""}
            onChange={handleProductOwnerChange}
            onInvalid={() => handleAddError("Previous First Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Previous Last Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="prevLastName"
            pattern="^(^[a-zA-Z0-9 \/\-\\]{1,30})$"
            value={productDetails?.owner?.prevLastName || ""}
            onChange={handleProductOwnerChange}
            onInvalid={() => handleAddError("Previous Last Name")}
          />
        </Col>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Activity</h6>
      <ActivityEdit
        isReadOnly={hasLLCIncorporationProduct}
        productDetails={productDetails}
        onError={handleAddError}
        onProductChange={handleProductChange}
      />

      <hr />
      <h6 className="font-weight-bold">General questions</h6>
      <AllQuestionEdit productDetails={productDetails} onError={handleAddError} onProductChange={handleProductChange} />

      <hr />
      <h6 className="font-weight-bold">Corporate Address (PO Boxes are not authorized)</h6>
      <CorpAddressEdit productDetails={productDetails} onError={handleAddError} onProductChange={handleProductChange} />

      <AltAddressEdit productDetails={productDetails} onError={handleAddError} onProductChange={handleProductChange} />

      <DateEdit productDetails={productDetails} onChange={handleProductChange} />
    </div>
  );
}

export default FormLLC;
