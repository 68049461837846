import { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

export const initDocsLob = {
  name: "",
  addressFrom: {
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    county: "",
  },
  from: "",
};

export default function EditDocsLob({
  show,
  docsLob,
  productCategories = [],
  handleClose,
  handleSave,
}) {
  const [formData, setFormData] = useState(initDocsLob);
  const [oldName, setOldName] = useState("");
  const [isDirty, setDirty] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    if (name.match(/\./)) {
      const [parentField, childField] = name.split(".");

      setFormData({
        ...formData,
        [parentField]: { ...formData[parentField], [childField]: value },
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleCancel = () => {
    setFormData(initDocsLob);
    handleClose();
    setDirty(false);
  };

  const handleSaveDocsLob = () => {
    const { name, ...d } = formData;

    handleSave(d, name, oldName);
    setFormData(initDocsLob);
    setDirty(false);
  };

  useEffect(() => {
    setFormData(docsLob);
    setOldName(docsLob.name);
  }, [docsLob]);

  return (
    <Modal show={show} onHide={handleCancel} size="md" centered>
      <Modal.Header closeButton>
        <div>{oldName ? oldName : "New field"}</div>
      </Modal.Header>
      <Modal.Body>
        <Form onChange={() => setDirty(true)}>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              as="select"
              name="name"
              value={formData.name}
              isInvalid={!formData.name}
              style={{ background: 0 }}
              onChange={handleChange}
            >
              <option value="">Select Name</option>
              {productCategories.map(({ code }) => (
                <option key={code} value={code}>
                  {code}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Name is required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Label>Adress from</Form.Label>
          <Form.Group className="ml-3">
            <Form.Group>
              <Form.Label>Address 1</Form.Label>
              <Form.Control
                required
                value={formData.addressFrom.address1}
                name="addressFrom.address1"
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Address 1 is required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Address 2</Form.Label>
              <Form.Control
                required
                value={formData.addressFrom.address2}
                name="addressFrom.address2"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>City</Form.Label>
              <Form.Control
                required
                value={formData.addressFrom.city}
                name="addressFrom.city"
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                City is required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>State</Form.Label>
              <Form.Control
                required
                value={formData.addressFrom.state}
                name="addressFrom.state"
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                State is required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>ZIP</Form.Label>
              <Form.Control
                required
                value={formData.addressFrom.zip}
                name="addressFrom.zip"
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                ZIP is required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Country</Form.Label>
              <Form.Control
                required
                value={formData.addressFrom.country}
                name="addressFrom.country"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>County</Form.Label>
              <Form.Control
                required
                value={formData.addressFrom.county}
                name="addressFrom.county"
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                County is required
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Group>
          <Form.Group>
            <Form.Label>From</Form.Label>
            <Form.Control
              required
              value={formData.from}
              name="from"
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
              From is required
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Close
        </Button>
        <Button
          variant="primary"
          disabled={!formData.name || !isDirty}
          onClick={handleSaveDocsLob}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
