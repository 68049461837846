import { Row, Col, Form } from "react-bootstrap";
import InputMask from "react-input-mask";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import ActivityEdit from "../Activity/Edit";
import AllQuestionEdit from "../GeneralQuestion/Edit/All";
import AltAddressEdit from "../AltAddress/Edit";
import CorpAddressEdit from "../CorpAddress/Edit";
import DateEdit from "../Date/Edit";

import StatusEdit from "../../Status/Edit";

import { suffixArray, EIN_title_array, TAB_CODES } from "variables/Variables";

export default function FormProfit({ orderDetails, selectedProduct, onError, onChange }) {
  const productDetails = orderDetails.products[selectedProduct];

  const handleAddError = (field) => onError(TAB_CODES.einInfo, field);

  const handleProductChange = ({ target: { name, value, values } }) => {
    const extFields = values ? values : { [name]: value };

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, ...extFields } : product
      ),
    });
  };

  const handleProdcutResponsiblePartyChange = ({ target: { name, value } }) =>
    handleProductChange({
      target: {
        name: "responsibleParty",
        value: {
          ...productDetails.responsibleParty,
          [name]: ["suffixName", "ssn"].includes(name) && value === "" ? null : value,
        },
      },
    });

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">NON-PROFIT ORGANIZATION</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
      />

      <FormPDF productDetails={productDetails} />

      <hr />
      <h6 className="font-weight-bold">Non-profit Organization</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Legal Name of the Non-profit Organization:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="legalName"
            pattern="^[ a-zA-Z0-9\.\/,\\;\-:_'~˜`’\(\)\x22\[\]\?<>!\{\}@#\$%\^&\*\+=]+$"
            value={productDetails.legalName || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Legal Name of the Non-profit Organization")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          DBA of the Non-Profit:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="dbaname"
            pattern="^[ a-zA-Z0-9\.\/,\\;\-:_'~˜`’\(\)\x22\[\]\?<>!\{\}@#\$%\^&\*\+=]+$"
            value={productDetails.dbaname || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("DBA of the Non-Profit")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Type of Non-Profit:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="nonProfitType"
            value={productDetails.nonProfitType || ""}
            onChange={handleProductChange}
          />
        </Col>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Responsible Party Information</h6>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          First Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="firstName"
            pattern="^(^[a-zA-Z0-9 \/\-\\]{1,30})$"
            value={productDetails.responsibleParty.firstName || ""}
            onChange={handleProdcutResponsiblePartyChange}
            onInvalid={() => handleAddError("First Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Middle Name (optional):
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="middleName"
            pattern="^(^[a-zA-Z0-9 \/\-\\]{1,30})$"
            value={productDetails.responsibleParty.middleName || ""}
            onChange={handleProdcutResponsiblePartyChange}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Last Name:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="lastName"
            pattern="^(^[a-zA-Z0-9 \/\-\\]{1,30})$"
            value={productDetails.responsibleParty.lastName || ""}
            onChange={handleProdcutResponsiblePartyChange}
            onInvalid={() => handleAddError("Last Name")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Suffix Name (optional):
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            as="select"
            name="suffixName"
            value={productDetails.responsibleParty.suffixName || ""}
            onChange={handleProdcutResponsiblePartyChange}
          >
            <option value="">Please select</option>
            {suffixArray.map((prop, key) => (
              <option key={key} value={prop}>
                {prop}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Title:
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            as="select"
            name="title"
            value={productDetails.responsibleParty.title || ""}
            onChange={handleProdcutResponsiblePartyChange}
            onInvalid={() => handleAddError("Title")}
          >
            {Object.keys(EIN_title_array).map((prop, key) => (
              <option key={key} value={prop}>
                {EIN_title_array[prop]}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Social Security Number:
        </Form.Label>
        <Col sm={7}>
          <InputMask
            className="form-control form-control-sm"
            mask="999-99-9999"
            maskChar=" "
            name="ssn"
            placeholder="999-99-9999"
            pattern="^\d{3}-\d{2}-\d{4}$"
            value={productDetails.responsibleParty.ssn || ""}
            onChange={handleProdcutResponsiblePartyChange}
            onInvalid={() => handleAddError("Social Security Number")}
          />
        </Col>
      </Form.Group>

      <hr />
      <h6 className="font-weight-bold">Activity</h6>
      <ActivityEdit productDetails={productDetails} onError={handleAddError} onProductChange={handleProductChange} />

      <hr />
      <h6 className="font-weight-bold">General questions</h6>
      <AllQuestionEdit productDetails={productDetails} onError={handleAddError} onProductChange={handleProductChange} />

      <hr />
      <h6 className="font-weight-bold">Corporate Address (PO Boxes are not authorized)</h6>
      <CorpAddressEdit productDetails={productDetails} onError={handleAddError} onProductChange={handleProductChange} />

      <AltAddressEdit productDetails={productDetails} onError={handleAddError} onProductChange={handleProductChange} />

      <DateEdit productDetails={productDetails} onChange={handleProductChange} />
    </div>
  );
}
