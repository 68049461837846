import React from "react";
import { Col, Form, Row } from "react-bootstrap";

import FormContainer from "components/OrderDetails/CreateOrder/FormComponents/FormContainer";
import ExtraLabel from "components/OrderDetails/CreateOrder/FormComponents/ExtraLabel";
import { state_array } from "variables/Variables";

const EinResidentAgent = (props) => {
  const { orderDetails, productDetails, onChange, onError } = props;

  // const llcIncorporationProduct = orderDetails?.products?.find((p) => checkIsLLCIncorporation(p.code.code));

  const handleProductChange = ({ target: { name, value } }) => {
    const newObj = { ...productDetails };

    onChange({ ...newObj, [name]: ["ssn"].includes(name) && value === "" ? null : value });
  };

  // const handleProductProcessingResultChange = ({ target: { name, value } }) =>
  //   handleProductChange({
  //     target: {
  //       name: "processingResult",
  //       value: {
  //         ...productDetails?.processingResult,
  //         [name]: ["email", "companyName"].includes(name) && value === "" ? null : value,
  //       },
  //     },
  //   });

  function handleAddError(error) {
    onError(error);
  }

  return (
    <FormContainer>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          State: <ExtraLabel $required />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            as="select"
            name="state"
            size="sm"
            value={productDetails?.state || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("State")}
          >
            <option value="">Select a state</option>
            {Object.keys(state_array).map((key) => (
              <option key={key} value={key}>
                {state_array[key]}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>

      {/* <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Company Name: <ExtraLabel $optional />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="companyName"
            pattern="^[ a-zA-Z0-9\.\/,\\;\-:_'~˜`’\(\)\x22\[\]\?<>!\{\}@#\$%\^&\*\+=]+$" // \x22 is "
            value={productDetails?.processingResult?.companyName || ""}
            onChange={handleProductProcessingResultChange}
            onInvalid={() => handleAddError("Company Name of the LLC")}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Email: <ExtraLabel $optional />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="email"
            name="email"
            value={productDetails?.processingResult?.email || ""}
            onChange={handleProductProcessingResultChange}
            onInvalid={() => handleAddError("Email")}
          />
        </Col>
      </Form.Group> */}

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Webfile Number: <ExtraLabel $optional />
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            size="sm"
            type="text"
            name="webFileNumber"
            pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
            value={productDetails?.webFileNumber || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("Webfile Number")}
          />
        </Col>
      </Form.Group>
    </FormContainer>
  );
};

export default EinResidentAgent;
