export const crmConfigConstants = {
  SAVE_REQUEST: "CONFIG_SAVE_REQUEST",
  SAVE_SUCCESS: "CONFIG_SAVE_SUCCESS",
  SAVE_FAILURE: "CONFIG_SAVE_FAILURE",

  GET_REQUEST: "CONFIG_GET_REQUEST",
  GET_SUCCESS: "CONFIG_GET_SUCCESS",
  GET_FAILURE: "CONFIG_GET_FAILURE",

  GET_PRODUCTS_REQUEST: "CONFIG_GET_PRODUCTS_REQUEST",
  GET_PRODUCTS_SUCCESS: "CONFIG_GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_FAILURE: "CONFIG_GET_PRODUCTS_FAILURE",
};
