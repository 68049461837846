import { Row, Form, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import { customerActions } from "_actions";

import CommonLawTrademarkResultListEdit from "../CommonLawTrademarkResultList/Edit";
import FederalSearchResultListEdit from "../FederalSearchResultList/Edit";
import StateSearchResultListEdit from "../StateSearchResultList/Edit";

import { TAB_CODES } from "variables/Variables";

function MonitoringReportList({ monitoringReports, onError, onChange }) {
  const dispatch = useDispatch();

  const { collapseMonitoringReport } = useSelector(({ customers }) => customers);

  const handleChangeMonitoringReport = ({ name, value, idx }) => {
    onChange({
      name: "monitoringReports",
      value: { ...monitoringReports, [idx]: { ...monitoringReports[idx], [name]: value } },
    });
  };

  const handleAddError = (field) => onError(TAB_CODES.trademarkInfo, field);

  return (
    <>
      <hr />
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          <h6 className="font-weight-bold">Monitoring Reports</h6>
        </Form.Label>
      </Form.Group>

      {Object.keys(monitoringReports)?.map((idx) => {
        const isCollapsed = !collapseMonitoringReport.includes(idx);

        return (
          <Col key={idx} sm={12} className="pl-3">
            <Form.Group
              as={Row}
              sm={3}
              onClick={(e) => {
                e.target.name !== `checkbox-${idx}` && dispatch(customerActions.setCollapseMonitoringReport(idx));
              }}
            >
              <Form.Label column style={{ cursor: "pointer" }}>
                <i className={`fa fa-caret-${isCollapsed ? "down" : "up"}`} />
                {idx}
              </Form.Label>
              <Form.Check column inline className="ml-4">
                <Form.Check.Input
                  checked={monitoringReports[idx].completed}
                  name={`checkbox-${idx}`}
                  style={{ transform: "scale(1.3)" }}
                  type="checkbox"
                  onChange={({ target: { name, checked } }) => {
                    handleChangeMonitoringReport({
                      name: "completed",
                      value: checked,
                      idx: name.slice(9),
                    });
                  }}
                />
              </Form.Check>
            </Form.Group>
            <Col key={idx} className={classnames({ collapse: true, show: !isCollapsed })}>
              <FederalSearchResultListEdit
                federalSearchResults={monitoringReports[idx].federalSearchResults}
                onError={handleAddError}
                onChange={(value) => {
                  handleChangeMonitoringReport({
                    name: "federalSearchResults",
                    value,
                    idx,
                  });
                }}
              />
              <StateSearchResultListEdit
                stateSearchResults={monitoringReports[idx].stateSearchResults}
                onError={handleAddError}
                onChange={(value) =>
                  handleChangeMonitoringReport({
                    name: "stateSearchResults",
                    value,
                    idx,
                  })
                }
              />
              <CommonLawTrademarkResultListEdit
                commonLawTrademarkResults={monitoringReports[idx].commonLawResults}
                onError={handleAddError}
                onChange={(value) =>
                  handleChangeMonitoringReport({
                    name: "commonLawResults",
                    value,
                    idx,
                  })
                }
              />
            </Col>
          </Col>
        );
      })}
    </>
  );
}

export default MonitoringReportList;
