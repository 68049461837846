import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Col, Table, Card, Form } from "react-bootstrap";
import moment from "moment-timezone";

import { customerActions } from "_actions";

import Spinner from "components/Spinner";
import Button from "components/Button";

export default function DashboardReport() {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [category, setCategory] = useState("");
  const [zone, setZone] = useState("America/New_York");
  const [dateRange, setDateRange] = useState("today");
  const [includeRecurring, setIncludeRecurring] = useState(false);
  const [isDataRequested, setIsDataRequested] = useState(false);

  const customers = useSelector(({ customers }) => customers);
  const { declinedOrders, profit, reportFrom, reportTo, successfulOrders, totalAdvertisingSpend, totalRevenue } =
    customers?.reportdata ? customers.reportdata : {};

  useEffect(() => {
    switch (dateRange) {
      case "today":
        setStartDate(moment().format("YYYY-MM-DD"));
        setEndDate(moment().format("YYYY-MM-DD"));
        break;

      case "yesterday":
        setStartDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
        setEndDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
        break;

      case "weektodate":
        setStartDate(moment().startOf("week").format("YYYY-MM-DD"));
        setEndDate(moment().format("YYYY-MM-DD"));
        break;

      case "lastweek":
        setStartDate(moment().day(1).subtract(7, "day").format("YYYY-MM-DD"));
        setEndDate(moment().day(7).subtract(7, "day").format("YYYY-MM-DD"));
        break;

      case "monthtodate":
        setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
        setEndDate(moment().format("YYYY-MM-DD"));
        break;

      case "pastmonth":
        setStartDate(moment().subtract(1, "months").format("YYYY-MM-DD"));
        setEndDate(moment().format("YYYY-MM-DD"));
        break;

      case "lastmonth":
        setStartDate(moment().subtract(1, "months").date(1).format("YYYY-MM-DD"));
        setEndDate(moment().startOf("month").subtract(1, "day").format("YYYY-MM-DD"));
        break;

      default:
        break;
    }
  }, [dateRange]);

  // Disable initial loading for Andy request
  // useEffect(() => {
  //   dispatch(customerActions.getdashboardreport(startDate, endDate, category, zone));
  // }, []);

  const filter = () => {
    dispatch(customerActions.getdashboardreport({ startDate, endDate, category, zone, includeRecurring }));
    setIsDataRequested(true);
  };

  return (
    <Container fluid className="mt-5 py-4">
      <Card className="p-2">
        <Form>
          <Form.Row>
            <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3}>
              <Form.Label>Date Range:</Form.Label>
              <Form.Control
                size="sm"
                as="select"
                name="dateRange"
                value={dateRange}
                onChange={(e) => setDateRange(e.target.value)}
              >
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="weektodate">Week to Date</option>
                <option value="lastweek">Last Week</option>
                <option value="monthtodate">Month to Date</option>
                <option value="pastmonth">Past Month</option>
                <option value="lastmonth">Last Month</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3}>
              <Form.Label>Start Date:</Form.Label>
              <Form.Control
                size="sm"
                type="date"
                name="orderDate"
                max={moment().format("YYYY-MM-DD")}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3}>
              <Form.Label>End Date:</Form.Label>
              <Form.Control
                size="sm"
                type="date"
                name="orderDate"
                max={moment().format("YYYY-MM-DD")}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3}>
              <Form.Label>Category</Form.Label>
              <Form.Control
                size="sm"
                as="select"
                placeholder="Category"
                name="category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="">Select Category</option>
                <option value="EIN">EIN</option>
                <option value="EIN_INC">EIN — INC</option>
                <option value="INC">INCORPIFY</option>
                <option value="INC_States">INC_States</option>
                <option value="VITAL">VITAL</option>
                <option value="TRADEMARKS">TRADEMARKS</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className="d-flex align-items-end" as={Col} xs={12} sm={6} md={6} lg={3} xl={3}>
              <Form.Check
                id="includeRecurring"
                type="checkbox"
                name="includeRecurring"
                checked={includeRecurring}
                onChange={() => {
                  setIncludeRecurring(!includeRecurring);
                }}
              />
              <Form.Label htmlFor="includeRecurring">Recurring payments</Form.Label>
            </Form.Group>
          </Form.Row>
          <Form.Row className="justify-content-start">
            <Form.Group as={Col} xs={12} sm={6} md={6} lg={3} xl={3}>
              <Button size="sm" isLoading={customers.loading} onClick={filter}>
                Filter
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
      </Card>

      <Card>
        {!isDataRequested && <div className="p-2">Please, click "Filter" to load data</div>}
        {customers.loading ? (
          <Spinner />
        ) : (
          !!customers.reportdata &&
          !!profit && (
            <Table striped responsive="sm" size="sm" className="dashboard-report-table">
              <tbody>
                <tr>
                  <td>From</td>
                  <td>{moment.utc(reportFrom).local().format("MMM Do YYYY HH:mm A")}</td>
                </tr>
                <tr>
                  <td>To</td>
                  <td>{moment.utc(reportTo).local().format("MMM Do YYYY HH:mm A")}</td>
                </tr>
                <tr>
                  <td>Successful Orders</td>
                  <td>{successfulOrders}</td>
                </tr>
                <tr>
                  <td>Declined Orders</td>
                  <td>{declinedOrders}</td>
                </tr>
                <tr>
                  <td>Total Spent on Advertising</td>
                  <td>{totalAdvertisingSpend}</td>
                </tr>
                <tr>
                  <td>Total Revenue</td>
                  <td>{totalRevenue}</td>
                </tr>
                <tr>
                  <td>Profit</td>
                  <td style={{ color: profit.includes("-") ? "red" : "green" }}>{profit}</td>
                </tr>
              </tbody>
            </Table>
          )
        )}
      </Card>
    </Container>
  );
}
