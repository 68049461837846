import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { adminActions } from "_actions";

export default function EditActionModal({
  show,
  action = null,
  mode = null,
  product,
  category,
  handleClose = () => {},
}) {
  const dispatch = useDispatch();
  const formEl = useRef(null);

  const { processingStatus, processingStatusOpt } = useSelector(({ variants }) => variants);

  const [newAction, setNewAction] = useState(null);
  const [validated, setValidated] = useState(false);

  const isAdd = mode === "add";
  const isEdit = mode === "edit";
  const isDelete = mode === "delete";

  const handleChangeAction = ({ target: { value, name } }) => {
    const newObj = { ...newAction, [name]: value };
    setNewAction(newObj);
  };

  const handleOnClose = () => {
    setValidated(false);
    handleClose();
  };

  const handleSubmit = (e) => {
    if (isDelete) {
      const deletedAction = { ...newAction, actionRequired: null, customerActionDescription: null };
      dispatch(adminActions.createActionRequiredFields(deletedAction, mode, category));
      handleOnClose();
    } else {
      const form = formEl.current;
      if (form.checkValidity() === false) {
        setValidated(true);
      } else {
        dispatch(adminActions.createActionRequiredFields(newAction, mode, category));
        handleOnClose();
      }
    }
  };

  useEffect(() => {
    setNewAction(action);
  }, [action]);

  return (
    <Modal show={show} onHide={handleOnClose} size="md" centered>
      <Modal.Header closeButton style={{ border: 0 }}>
        {isAdd && "Add New Action"}
        {isEdit && "Edit Action"}
        {isDelete && "Clear Action"}
      </Modal.Header>
      <Modal.Body>
        {isDelete ? (
          <div>Are you sure you want to clear the action?</div>
        ) : (
          <Form validated={validated} noValidate ref={formEl}>
            <Form.Group>
              <Form.Label>Category</Form.Label>
              <Form.Control
                required
                readOnly
                name="category"
                value={product?.category || ""}
                onChange={handleChangeAction}
              />
              <Form.Control.Feedback type="invalid">
                Category is required, something went wrong...
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Status</Form.Label>
              {(isEdit || isAdd) && (
                <Form.Control required readOnly name="status" value={processingStatusOpt[newAction?.status] || ""} />
              )}
              <Form.Control.Feedback type="invalid">
                Please provide the status with which this action will be related
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Product</Form.Label>
              {(isEdit || isAdd) && (
                <Form.Control required readOnly name="status" value={product?.customerName || ""} />
              )}
              <Form.Control.Feedback type="invalid">
                Please provide the status with which this action will be related
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Action Title</Form.Label>
              <Form.Control
                required
                name="actionRequired"
                value={newAction?.actionRequired || ""}
                onChange={handleChangeAction}
              />
              <Form.Control.Feedback type="invalid">Please, provide action title for customer</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Action Description</Form.Label>
              <Form.Control
                required
                as="textarea"
                name="customerActionDescription"
                value={newAction?.customerActionDescription || ""}
                onChange={handleChangeAction}
              />
              <Form.Control.Feedback type="invalid">
                Please, provide action description for customer
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleOnClose}>
          Cancel
        </Button>

        {isAdd && (
          <Button variant="success" onClick={handleSubmit}>
            Add New Action
          </Button>
        )}
        {isEdit && (
          <Button variant="success" onClick={handleSubmit}>
            Save Changes
          </Button>
        )}
        {isDelete && (
          <Button variant="danger" onClick={handleSubmit}>
            Clear Action
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

EditActionModal.propTypes = {
  show: PropTypes.bool,
};
