import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, Form } from 'react-bootstrap';
import { zendeskactions } from '_actions';
import { WithContext as ReactTags } from 'react-tag-input';

function AddAccount(props) {
  const dispatch = useDispatch();
  const [account, setAccount] = useState({
    url: '',
    login: '',
    password: '',
    keywords: [],
    status: 'Active',
  });
  const [tags, setTags] = useState([]);
  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const resetform = (e) => {
    setAccount({
      url: '',
      login: '',
      password: '',
      keywords: [],
      status: 'Active',
    });

    setTags([]);
  };

  const onchange = (e) => {
    const { name, value } = e.target;
    setAccount({ ...account, [name]: value });
  };

  const onsubmit = (e) => {
    e.preventDefault();
    dispatch(zendeskactions.add_zendesk_account(account));
    props.onHide();
  };

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    if (tag.text.length < 4) return false;
    setTags([...tags, tag]);
  };

  useEffect(() => {
    let result = tags.map((a) => a.text);
    setAccount({ ...account, keywords: result });
  }, [tags]);

  useEffect(() => {
    resetform();
  }, [props]);

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add New Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onsubmit}>
          <Form.Group>
            <Form.Label>Url</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter Url"
              name="url"
              value={account.url}
              onChange={(e) => onchange(e)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              type="email"
              placeholder="Email"
              name="login"
              value={account.login}
              onChange={(e) => onchange(e)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder="Password"
              name="password"
              value={account.password}
              onChange={(e) => onchange(e)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Keywords (at least 4 letters)</Form.Label>

            <ReactTags
              tags={tags}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              delimiters={delimiters}
              placeholder="Add a new keyword"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              name="status"
              value={account.status}
              onChange={(e) => onchange(e)}
            >
              <option value="Active">Active</option>
              <option value="Disabled">Inactive</option>
            </Form.Control>
          </Form.Group>

          <Button onClick={props.onHide} className="mr-2">
            Close
          </Button>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default AddAccount;
