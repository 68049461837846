import React from "react";
import styled, { css } from "styled-components";

import { useCopyToClipboard } from "_hooks";

const Container = styled.div`
  position: relative;
  display: inline;
  font-size: 1.3em;

  &:hover {
    & svg {
      filter: drop-shadow(0px 0px 1px rgb(0 0 0 / 0.8));
    }
  }

  &:after {
    content: "Copied!";
    position: absolute;
    left: 2px;
    bottom: -13px;

    border-radius: 3px;
    background-color: #00000055;
    color: white;
    text-align: center;
    word-break: keep-all;

    padding: 0;
    width: 0;
    height: 0;
    opacity: 0;
    font-size: 0;

    transition: opacity 0.2s linear;

    ${({ isActive }) =>
      isActive &&
      css`
        padding: 1px;
        width: 34px;
        height: 13px;
        opacity: 1;
        font-size: 0.475em;
      `}
  }
`;

const ClipboardIcon = () => (
  <svg viewBox="0 0 21 21" fill="currentColor" height="1em" width="1em">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
      <path d="M7.5 4.5h-2a1 1 0 00-1 1v11a1 1 0 001 1h10a1 1 0 001-1v-11a1 1 0 00-1-1h-2" />
      <path d="M8.5 3.5h4a1 1 0 110 2h-4a1 1 0 110-2z" />
    </g>
  </svg>
);

const ClipboardCheckIcon = () => (
  <svg viewBox="0 0 21 21" fill="currentColor" height="1em" width="1em">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
      <path d="M7.5 4.5h-2a1 1 0 00-1 1v11a1 1 0 001 1h10a1 1 0 001-1v-11a1 1 0 00-1-1h-2" />
      <path d="M8.5 3.5h4a1 1 0 110 2h-4a1 1 0 110-2zM7.5 11.5l2 2 5-5" />
    </g>
  </svg>
);

function CopyButton({ text, ...props }) {
  const [isCopied, handleCopy] = useCopyToClipboard();

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isCopied) {
      handleCopy(text);
    }
  };

  return (
    <Container {...props} isActive={isCopied} onClick={handleClick}>
      {isCopied ? <ClipboardCheckIcon /> : <ClipboardIcon />}
    </Container>
  );
}

export default CopyButton;
