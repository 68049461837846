import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Container, Button, Card, Form, Row, Col } from "react-bootstrap";
import { adminActions } from "_actions";
import moment from "moment-timezone";

function GoogleAds(props) {
  const dispatch = useDispatch();

  const [startdate, setStartdate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [enddate, setenddate] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const generateReport = () => {
    dispatch(adminActions.generatereport(startdate, enddate));
  };

  return (
    <>
      <Container fluid className="mt-5 py-4">
        <Card className="p-2">
          <Row>
            <Col md={6}>
              <Form>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Start Date:</Form.Label>
                    <Form.Control
                      size="sm"
                      type="date"
                      name="orderDate"
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      value={startdate}
                      onChange={(e) => setStartdate(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>End Date:</Form.Label>
                    <Form.Control
                      size="sm"
                      type="date"
                      name="orderDate"
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      value={enddate}
                      onChange={(e) => setenddate(e.target.value)}
                    />
                  </Form.Group>
                </Form.Row>
              </Form>
            </Col>

            <Form.Group as={Col} md={6} style={{ display: "flex", alignItems: "flex-end" }}>
              <Button size="sm" onClick={generateReport}>
                Google Adwords Click Report
              </Button>
            </Form.Group>
          </Row>
        </Card>
      </Container>
    </>
  );
}

export default GoogleAds;
