// import { useSelector } from "react-redux";
import { Fragment } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
// import ReactMultiselectCheckboxes from "react-multiselect-checkboxes";

import { state_array } from "variables/Variables";

export const initTrademarkOwner = {
  partyName: "",
  partyType: "",
  legalEntityTypeCode: "",
  address1: "",
  address2: "",
  city: "",
  country: "",
  state: "",
  postcode: "",

  entryNumber: null,
  nameChangeExplanation: null,
  nationality: null,
};

export default function TrademarkOwnerList({ trademarkOwners, onError, onChange }) {
  // const { nationalities, nationalitiesOpt } = useSelector(({ variants }) => variants);

  // const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
  //   if (value && value.some((o) => o.value === "*")) {
  //     return `${placeholderButtonLabel}: All`;
  //   } else {
  //     return `${placeholderButtonLabel}: ${value.length} selected`;
  //   }
  // };

  const handleAddOwner = () => onChange([{ ...initTrademarkOwner }, ...trademarkOwners]);

  const handleDeleteOwner = (deleteIdx) => onChange(trademarkOwners.filter((o, _idx) => deleteIdx !== _idx));

  const handleOwnerChange = ({ target: { name, value } }, index) =>
    onChange(trademarkOwners.map((owner, _idx) => (_idx === index ? { ...owner, [name]: value } : owner)));

  // const handleNationalityChange = (arr, index) =>
  //   handleOwnerChange({ target: { name: "nationality", value: arr.map(({ value }) => value) } }, index);

  if (!trademarkOwners) return null;

  const labelRenderer = (idx) => {
    switch (idx) {
      case 0:
        return (
          <>
            <h6 className="font-weight-bold">Current Trademark Owners</h6>
          </>
        );
      case 1:
        return (
          <>
            <hr />
            <h6 className="font-weight-bold">Trademark Owner History</h6>
          </>
        );

      default:
        return <hr />;
    }
  };

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          <h6 className="font-weight-bold">Owner list</h6>
        </Form.Label>
        <Col sm={7}>
          <Button size="sm" onClick={handleAddOwner}>
            Add Owner
          </Button>
        </Col>
      </Form.Group>

      {trademarkOwners.map((owner, idx) => (
        <Fragment key={`tm-owner-${idx}`}>
          {labelRenderer(idx)}
          <Col key={idx} sm={12} className="pl-3">
            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Party name:
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  size="sm"
                  type="text"
                  name="partyName"
                  value={owner.partyName || ""}
                  onChange={(e) => handleOwnerChange(e, idx)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Party type:
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  size="sm"
                  type="text"
                  name="partyType"
                  value={owner.partyType || ""}
                  onChange={(e) => handleOwnerChange(e, idx)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Legal entity type:
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  size="sm"
                  type="text"
                  name="legalEntityTypeCode"
                  value={owner.legalEntityTypeCode || ""}
                  onChange={(e) => handleOwnerChange(e, idx)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Address 1:
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  size="sm"
                  type="text"
                  name="address1"
                  value={owner.address1 || ""}
                  onChange={(e) => handleOwnerChange(e, idx)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Address 2:
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  size="sm"
                  type="text"
                  name="address2"
                  value={owner.address2 || ""}
                  onChange={(e) => handleOwnerChange(e, idx)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                City:
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  size="sm"
                  type="text"
                  name="city"
                  value={owner.city || ""}
                  onChange={(e) => handleOwnerChange(e, idx)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Country:
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  size="sm"
                  type="text"
                  name="country"
                  value={owner.country || ""}
                  onChange={(e) => handleOwnerChange(e, idx)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                State
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  as="select"
                  size="sm"
                  name="state"
                  value={owner.state || ""}
                  onChange={(e) => handleOwnerChange(e, idx)}
                >
                  <option value="">Select a state</option>
                  {Object.keys(state_array).map((prop, key) => (
                    <option key={key} value={prop}>
                      {state_array[prop]}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                Postcode:
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  size="sm"
                  type="text"
                  name="postcode"
                  value={owner.postcode || ""}
                  onChange={(e) => handleOwnerChange(e, idx)}
                />
              </Col>
            </Form.Group>

            {/* <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Entry number:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                size="sm"
                type="text"
                name="entryNumber"
                value={owner.entryNumber || ""}
                onChange={(e) => handleOwnerChange(e, idx)}
              />
            </Col>
          </Form.Group> */}

            {/* <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Nationality:
            </Form.Label>
            <Col sm={7}>
              <ReactMultiselectCheckboxes
                className="reactMultiSelectCheckboxes"
                placeholderButtonLabel="Nationality"
                getDropdownButtonLabel={getDropdownButtonLabel}
                name="usCode"
                options={nationalities}
                value={owner.nationality.map((value) => ({ value, label: nationalitiesOpt[value] }))}
                onChange={(e) => handleNationalityChange(e, idx)}
              />
            </Col>
          </Form.Group> */}

            {/* <Form.Group as={Row}>
            <Form.Label column sm={5}>
              Name change explanation:
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                size="sm"
                type="text"
                name="nameChangeExplanation"
                value={owner.nameChangeExplanation || ""}
                onChange={(e) => handleOwnerChange(e, idx)}
              />
            </Col>
          </Form.Group> */}

            <Form.Group as={Row}>
              <Form.Label column sm={5}>
                {owner.partyName}
              </Form.Label>
              <Col sm={7}>
                <Button size="sm" variant="danger" onClick={() => handleDeleteOwner(idx)}>
                  Remove
                </Button>
              </Col>
            </Form.Group>
          </Col>
        </Fragment>
      ))}
    </>
  );
}
