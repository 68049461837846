import { Row, Form } from "react-bootstrap";

export default function Organization({ organization }) {
  if (!organization) return null;

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Formation
        </Form.Label>
        <Form.Label column sm={7}>
          {organization.usBased ? "US Based" : "Non US Based"}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Organization Name
        </Form.Label>
        <Form.Label column sm={7}>
          {organization.name}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Organization Type
        </Form.Label>
        <Form.Label column sm={7}>
          {organization.type}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          State of Formation
        </Form.Label>
        <Form.Label column sm={7}>
          {organization.stateOrCountryOfFormation}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Position
        </Form.Label>
        <Form.Label column sm={7}>
          {organization.position}
        </Form.Label>
      </Form.Group>
    </>
  );
}
