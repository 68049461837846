import React from "react";
import { Col, Form, Row } from "react-bootstrap";

import FormPDF from "components/OrderDetails/ProductDetails/FormPDF";

import StatusEdit from "../../Status/Edit";

function FormAnnualReport({ orderDetails, selectedProduct, onChange }) {
  const productDetails = orderDetails?.products[selectedProduct];
  const processingResult = productDetails?.processingResult;

  const handleProductChange = ({ target: { name, value } }) =>
    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, [name]: value } : product
      ),
    });

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">Annual Report</h4>
      <StatusEdit orderDetails={orderDetails} selectedProduct={selectedProduct} onChange={onChange} />

      {productDetails.webFileNumber !== undefined && (
        <Form.Group as={Row}>
          <Form.Label column sm={5}>
            Webfile Number:
          </Form.Label>
          <Col sm={7}>
            <Form.Control
              // required={productDetails?.state === "TX" && processingResult?.status !== "MissingWebFile"}
              size="sm"
              type="text"
              name="webFileNumber"
              pattern="^[a-zA-Z0-9!@#%&'_=;:,<> \+\*\^\|\{\}\.\?\$\(\)\[\]\-\/\\]+$"
              value={productDetails?.webFileNumber || ""}
              onChange={handleProductChange}
            />
          </Col>
        </Form.Group>
      )}
      <FormPDF productDetails={productDetails} />
    </div>
  );
}

export default FormAnnualReport;
