import React from "react";
import { Row, Form } from "react-bootstrap";

import { state_array } from "variables/Variables";

function NewAddress({ newAddress }) {
  if (!newAddress) return null;

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address
        </Form.Label>
        <Form.Label column sm={7}>
          {newAddress.address1}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Address2
        </Form.Label>
        <Form.Label column sm={7}>
          {newAddress.address2}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          City
        </Form.Label>
        <Form.Label column sm={7}>
          {newAddress.city}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          State
        </Form.Label>
        <Form.Label column sm={7}>
          {state_array[newAddress.state]}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          Zip Code
        </Form.Label>
        <Form.Label column sm={7}>
          {newAddress.zip}
        </Form.Label>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          County
        </Form.Label>
        <Form.Label column sm={7}>
          {newAddress.county}
        </Form.Label>
      </Form.Group>
    </>
  );
}

export default NewAddress;
