import { variantsOptionsConstants } from "_constants";

const initial_state = {
  loading: 0,
  actionsRequiredList: null,
  addressesRA: null,
};

export function variantsOptions(state = initial_state, action) {
  const { type, payload } = action;

  switch (type) {
    // GET ACTIONS
    case variantsOptionsConstants.GET_ACTIONS_REQUIRED_LIST_REQUEST:
      return { ...state, loading: state.loading + 1 };

    case variantsOptionsConstants.GET_ACTIONS_REQUIRED_LIST_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1, actionsRequiredList: payload };

    case variantsOptionsConstants.GET_ACTIONS_REQUIRED_LIST_FAILED:
      return { ...state, loading: state.loading && state.loading - 1 };

    // POST ACTION
    case variantsOptionsConstants.POST_ACTIONS_REQUEST:
      return { ...state, loading: state.loading + 1 };

    case variantsOptionsConstants.POST_ACTIONS_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1 };

    case variantsOptionsConstants.POST_ACTIONS_FAILED:
      return { ...state, loading: state.loading && state.loading - 1 };

    // GET RA ADDRESSES
    case variantsOptionsConstants.GET_ADDRESSES_RA_REQUEST:
      return { ...state, loading: state.loading + 1 };

    case variantsOptionsConstants.GET_ADDRESSES_RA_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1, addressesRA: payload };

    case variantsOptionsConstants.GET_ADDRESSES_RA_FAILED:
      return { ...state, loading: state.loading && state.loading - 1 };

    // PUT RA ADDRESS
    case variantsOptionsConstants.PUT_ADDRESS_RA_REQUEST:
      return { ...state, loading: state.loading + 1 };

    case variantsOptionsConstants.PUT_ADDRESS_RA_SUCCESS:
      return { ...state, loading: state.loading && state.loading - 1 };

    case variantsOptionsConstants.PUT_ADDRESS_RA_FAILED:
      return { ...state, loading: state.loading && state.loading - 1 };

    default:
      return state;
  }
}
