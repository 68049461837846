import { customerConstants, modalsConstants } from "_constants";

export const createRetryMiddleware = () => {
  return ({ dispatch }) =>
    (next) =>
    (action) => {
      const { type, payload } = action;

      if (type === customerConstants.PUT_ORDER_FAILURE && payload) {
        dispatch({ type: modalsConstants.SHOW_CHANGE_EMAIL_MODAL, payload });
      }

      return next(action);
    };
};
