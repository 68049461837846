import React from "react";
import { Col, Form, Row } from "react-bootstrap";

import StatusEdit from "../../Status/Edit";

import { TAB_CODES } from "variables/Variables";

function FormDBA({ orderDetails, selectedProduct, onError, onChange }) {
  const productDetails = orderDetails?.products[selectedProduct];

  const handleAddError = (field) => onError(TAB_CODES.llcIncInfo, field);

  const handleProductChange = ({ target: { name, value } }) => {
    let exFields = { [name]: value || null };

    onChange({
      ...orderDetails,
      products: orderDetails.products.map((product, idx) =>
        idx === selectedProduct ? { ...product, ...exFields } : product
      ),
    });
  };

  return (
    <div className="form-border">
      <h4 className="m-0 font-weight-bold mb-2">{productDetails?.code?.customerName}</h4>

      <StatusEdit
        orderDetails={orderDetails}
        selectedProduct={selectedProduct}
        onChange={onChange}
        onError={handleAddError}
      />

      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          DBA Name (Doing Business As):
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            type="text"
            name="dbaName"
            pattern="^[ a-zA-Z0-9\.\/,\\;\-:_'~˜`’\(\)\x22\[\]\?<>!\{\}@#\$%\^&\*\+=]+$" // \x22 is "
            value={productDetails?.dbaName || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("DBA Name (Doing Business As)")}
          />
        </Col>
      </Form.Group>
    </div>
  );
}

export default FormDBA;
