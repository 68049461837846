import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Form } from "react-bootstrap";
import classnames from "classnames";
import moment from "moment-timezone";

import { customerActions } from "_actions";

import TrademarkClassificationList from "../TrademarkClassificationsList/View";
import TrademarkEventList from "../TrademarkEventList/View";
import TrademarkOwnerList from "../TrademarkOwnerList/View";

export default function FederalSearchResultList({ federalSearchResults }) {
  const dispatch = useDispatch();

  const { collapseFederalSearchResults } = useSelector(({ customers }) => customers);

  return (
    <>
      <hr />
      <h6 className="font-weight-bold">Federal search results</h6>

      {federalSearchResults?.length > 0 ? (
        federalSearchResults.map((result, idx) => {
          const isCollapsed = !collapseFederalSearchResults.includes(idx);

          return (
            <Col key={idx} sm={12} className="pl-3">
              <Form.Group as={Row} onClick={() => dispatch(customerActions.setCollapseFederalSearch(idx))}>
                <Form.Label column sm={5} style={{ cursor: "pointer" }}>
                  <i className={`fa fa-caret-${isCollapsed ? "down" : "up"}`} /> Serial number:
                </Form.Label>
                <Form.Label column sm={7} style={{ cursor: "pointer" }}>
                  {result.serialNumber}
                </Form.Label>
              </Form.Group>

              {!isCollapsed && (
                <Col className={classnames({ collapse: true, show: !isCollapsed })}>
                  <Form.Group as={Row}>
                    <Form.Label column sm={5}>
                      Registration number:
                    </Form.Label>
                    <Form.Label column sm={7}>
                      {result.registrationNumber}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={5}>
                      Mark ID:
                    </Form.Label>
                    <Form.Label column sm={7}>
                      {result.markIdentification}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={5}>
                      Status code:
                    </Form.Label>
                    <Form.Label column sm={7}>
                      {result.statusCode}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={5}>
                      Status date:
                    </Form.Label>
                    <Form.Label column sm={7}>
                      {result.statusDate ? moment.utc(result.statusDate).format("YYYY/MM/DD") : "—"}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={5}>
                      Filing date:
                    </Form.Label>
                    <Form.Label column sm={7}>
                      {result.filingDate ? moment.utc(result.filingDate).format("YYYY/MM/DD") : "—"}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={5}>
                      Registration date:
                    </Form.Label>
                    <Form.Label column sm={7}>
                      {result.registrationDate ? moment.utc(result.registrationDate).format("YYYY/MM/DD") : "—"}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={5}>
                      Attorney name:
                    </Form.Label>
                    <Form.Label column sm={7}>
                      {result.attorneyName}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={5}>
                      Mark drawing code:
                    </Form.Label>
                    <Form.Label column sm={7}>
                      {result.markDrawingCode}
                    </Form.Label>
                  </Form.Group>

                  <hr />
                  <h6>Classifications</h6>
                  <TrademarkClassificationList trademarkClassifications={result.classification} />

                  <TrademarkOwnerList trademarkOwners={result.owners} />

                  <hr />
                  <Form.Group as={Row}>
                    <Form.Label column sm={5}>
                      Correspondence name:
                    </Form.Label>
                    <Form.Label column sm={7}>
                      {result.correspondenceName}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={5}>
                      Correspondence address1:
                    </Form.Label>
                    <Form.Label column sm={7}>
                      {result.correspondenceAddress1}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={5}>
                      Correspondence address2:
                    </Form.Label>
                    <Form.Label column sm={7}>
                      {result.correspondenceAddress2}
                    </Form.Label>
                  </Form.Group>

                  <h6>Events</h6>
                  <TrademarkEventList trademarkEvents={result.events} />
                </Col>
              )}
            </Col>
          );
        })
      ) : (
        <Form.Group as={Row}>
          <Form.Label column sm={12}>
            No search results
          </Form.Label>
        </Form.Group>
      )}
    </>
  );
}
