import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";

import { modalsConstants } from "_constants";
import { customerActions, variantsActions, uiActions, alertActions, userActions } from "_actions";
import { history } from "_helpers";

import Spinner from "components/Spinner";
import ConfirmationModal from "components/ConfirmationModal";
import ChangeEmailConfirmationModal from "components/ChangeEmailConfirmationModal";

import ViewOrderDetail from "./ViewOrderDetail";
import EditOrderDetail from "./EditOrderDetail";

import { TRADEMARK_REGISTER_CODES } from "variables/Variables";

const codesToHide = [
  // "INC_StateIncorporationFee",
  "TRADEMARK_Trademark_Rush_Processing",
];

export default function OrderDetail() {
  const { orderId, productId } = useParams();
  const dispatch = useDispatch();

  const orderdata = useSelector(({ customers: { orderdata } }) => orderdata);
  const availableProcessingStatus = useSelector(({ customers: { availableStatuses } }) => availableStatuses);

  const loading = useSelector(({ customers: { loading } }) => loading);
  const hash = useSelector(({ ui }) => ui.hash);
  const { visible: isChangeEmailConfirmationModalOpen, retryAction: changeEmailRetryAction } = useSelector(
    ({ modals }) => modals.changeEmail
  );

  const [isEditing, setEditing] = useState(false);
  const [hasStatusChanged, setHasStatusChanged] = useState(false);
  const [hasOrderSaved, setHasOrderSaved] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [activeTab, setActiveTab] = useState("home");
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [uncollapsedProducts, setUncollapsedProducts] = useState([]);

  const trademarkRegister = orderdata?.products?.find(({ code: { code } }) => TRADEMARK_REGISTER_CODES.includes(code));

  const handleViewChange = () => {
    setEditing(!isEditing);

    if (isEditing) dispatch(uiActions.checkHash(hash, customerActions.getOrder(orderId)));
  };

  const handleUncollapseProduct = (id) => {
    if (uncollapsedProducts.includes(id)) {
      setUncollapsedProducts(uncollapsedProducts.filter((product) => product !== id));
    } else {
      setUncollapsedProducts([...uncollapsedProducts, id]);
    }
  };

  const handleSelectedProduct = (idx) => {
    history.replace("/admin/orders/" + orderId + "/" + orderdata.products[idx].id);
    setSelectedProduct(Number(idx));
  };

  const handleUpdateOrder = (cb) => {
    setHasOrderSaved(true);
    dispatch(uiActions.checkHash(hash, customerActions.updateorder(orderId, orderDetails, cb)));
  };

  const handleDeleteOrder = (id, cb) => {
    dispatch(customerActions.deleteOrder(id, cb));
  };

  const handleChangeEmailConfirmationConfirm = () => {
    const { id, data } = changeEmailRetryAction?.payload || {};
    dispatch(customerActions.changeEmail(id, data?.customer?.email));
    dispatch({ type: modalsConstants.HIDE_CHANGE_EMAIL_MODAL });
  };

  const handleChangeEmailConfirmationHide = () => {
    dispatch(customerActions.getOrder(orderId));
    dispatch({ type: modalsConstants.HIDE_CHANGE_EMAIL_MODAL });
  };

  useEffect(() => {
    setOrderDetails(orderdata);

    if (
      !!(
        Object.keys(orderdata).length &&
        orderdata.order &&
        orderdata.order.id === orderId &&
        orderdata.products[selectedProduct]
      )
    ) {
      const hasMain = orderdata.products.some((product) => product.code.main);
      const mainProductIndex = hasMain ? orderdata.products.findIndex((product) => product.code.main) : 0;
      const indexOfProduct = orderdata.products.findIndex((product) => product.id === productId);

      if (!productId) {
        handleSelectedProduct(mainProductIndex);
      } else {
        if (indexOfProduct < 0 || codesToHide.includes(orderdata.products[indexOfProduct].code.code)) {
          let errorMessage = codesToHide.includes(orderdata.products[indexOfProduct]?.code.code)
            ? "This product cannot be selected. Redirecting to the main product..."
            : "The product does not exist in this order. Redirecting to the main product...";
          dispatch(alertActions.error(errorMessage));
          handleSelectedProduct(mainProductIndex);
        } else {
          handleSelectedProduct(indexOfProduct);
        }
      }

      const products = orderdata.products.map(({ code: { code, category } }) => ({ code, category }));

      dispatch(variantsActions.getAllVariants(products));

      if (orderdata?.order?.accountManagerId) dispatch(userActions.getById(orderdata?.order?.accountManagerId));

      if (trademarkRegister)
        dispatch(customerActions.getAvailableStatuses(trademarkRegister?.code?.category, trademarkRegister?.id));
    }
  }, [orderdata]);

  useEffect(() => {
    if (Object.keys(orderdata).length && orderdata.order && orderdata.products[selectedProduct]) {
      const hasActiveTab = document.querySelector(".tab-pane.active");
      if (!hasActiveTab) {
        setActiveTab("home");
      }
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (orderdata?.products && orderdata.products[selectedProduct]) {
      const { code } = orderdata.products[selectedProduct];
      dispatch(variantsActions.getprocessing_status_2(code?.category));
    }
  }, [selectedProduct, orderdata]);

  useEffect(() => {
    dispatch(uiActions.checkHash(hash, customerActions.getOrder(orderId)));
  }, [orderId]);

  useEffect(() => {
    if (hasOrderSaved) {
      setEditing(false);
      setHasStatusChanged(false);
      setHasOrderSaved(false);
    }
  }, [hasOrderSaved]);

  useEffect(() => {
    if (hasStatusChanged) {
      handleUpdateOrder();
    }
  }, [hasStatusChanged]);

  return (
    <>
      <div>
        {loading ? (
          <Container fluid className="py-5 orderinfo">
            <Spinner />
          </Container>
        ) : isEditing ? (
          <EditOrderDetail
            orderDetails={orderDetails}
            availableProcessingStatus={availableProcessingStatus}
            activeTab={activeTab}
            selectedProduct={selectedProduct}
            uncollapsedProducts={uncollapsedProducts}
            onSelectedProduct={handleSelectedProduct}
            onViewChange={handleViewChange}
            onUpdateOrder={handleUpdateOrder}
            onDeleteOrder={setConfirmationOpen}
            onChange={setOrderDetails}
            onTabChange={setActiveTab}
            onUncollapseProduct={handleUncollapseProduct}
          />
        ) : (
          <ViewOrderDetail
            orderDetails={orderDetails?.order && orderDetails}
            availableProcessingStatus={availableProcessingStatus}
            activeTab={activeTab}
            selectedProduct={selectedProduct}
            uncollapsedProducts={uncollapsedProducts}
            onSelectedProduct={handleSelectedProduct}
            onViewChange={handleViewChange}
            onUpdateOrder={setHasStatusChanged}
            onDeleteOrder={setConfirmationOpen}
            onChange={setOrderDetails}
            onTabChange={setActiveTab}
            onUncollapseProduct={handleUncollapseProduct}
          />
        )}
      </div>
      <ConfirmationModal
        show={isConfirmationOpen}
        handleClose={() => {
          setConfirmationOpen(false);
        }}
        handleConfirm={() => {
          setConfirmationOpen(false);
          handleDeleteOrder(orderDetails.order.id, () => {
            if (orderDetails.order.productCodes.length && orderDetails.order.productCodes[0].category) {
              history.push(`/admin/orderlists/${orderDetails.order.productCodes[0].category.toLowerCase()}`);
            } else {
              history.push("/");
            }
          });
        }}
        body="Are you sure you want completely delete this order with all products?"
      />
      <ChangeEmailConfirmationModal
        show={isChangeEmailConfirmationModalOpen}
        handleClose={handleChangeEmailConfirmationHide}
        handleConfirm={handleChangeEmailConfirmationConfirm}
      />
    </>
  );
}
