import { userConstants } from "_constants";
import { userService } from "_services";
import { history, getAvailableCategory } from "_helpers";

import { alertActions } from "./";

export const userActions = {
  login,
  logout,
  saveuser,
  updateuser,
  getAll,
  getById,
  delete: _delete,
  getme,
  updateprofile,
};

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));

    userService.login(username, password).then(
      (user) => {
        localStorage.setItem("user", JSON.stringify(user));
        userService.getme(user).then((userdetail) => {
          const categoryToRedirect = getAvailableCategory(userdetail);

          dispatch(success(userdetail));
          history.push(categoryToRedirect ? `/admin/orderlists/${categoryToRedirect}` : "/admin/dashboard");
        });
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }

  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function getme() {
  return (dispatch) => {
    userService.getme().then(
      (user) => {
        dispatch(success(user));
      },
      () => {}
    );
  };

  function success(user) {
    return { type: userConstants.GETME_SUCCESS, user };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function saveuser(user) {
  const { pagecount, searchByName, searchByRole, includeDisabled } = user;

  return (dispatch) => {
    dispatch(request(user));
    userService.saveuser(user).then(
      (userdata) => {
        userService
          .getAll({
            page: 1,
            count: pagecount,
            search: searchByName,
            role: searchByRole,
            includeDisabled: includeDisabled,
          })
          .then(
            (users) => dispatch(success(users)),
            (error) => dispatch(failure(error.toString()))
          );
        dispatch(alertActions.success("Registration successful"));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }

  function success(users) {
    return { type: userConstants.REGISTER_SUCCESS, users };
  }

  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function getAll(params) {
  return (dispatch) => {
    dispatch(request());

    userService.getAll(params).then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }

  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }

  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

function getById(id) {
  return (dispatch) => {
    dispatch(request());

    userService.getById(id).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GET_USER_REQUEST };
  }
  function success(payload) {
    return { type: userConstants.GET_USER_SUCCESS, payload };
  }
  function failure(payload) {
    return { type: userConstants.GET_USER_FAILURE, payload };
  }
}

function _delete(user) {
  return (dispatch) => {
    userService.delete(user).then((user) => {
      userService.getAll({ page: 1, count: 25 }).then(
        (users) => dispatch(success(users)),
        (error) => dispatch(failure(error.toString()))
      );
      dispatch(alertActions.success("Deleted successful"));
    });
  };

  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }

  function failure(id, error) {
    return { type: userConstants.DELETE_FAILURE, id, error };
  }
}

function updateuser(user) {
  const { pagecount, searchByName, searchByRole, includeDisabled } = user;

  return (dispatch) => {
    userService.update(user).then((userdata) => {
      userService
        .getAll({
          page: 1,
          count: pagecount,
          search: searchByName,
          role: searchByRole,
          includeDisabled: includeDisabled,
        })
        .then((users) => dispatch(success(users)));
      dispatch(alertActions.success("Updated successful"));
    });
  };

  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
}

function updateprofile(user) {
  return (dispatch) => {
    userService.update(user).then(
      (user) => {
        let olduser = JSON.parse(localStorage.getItem("user"));
        olduser = {
          ...olduser,
          username: user.username,
        };
        localStorage.setItem("user", JSON.stringify(olduser));
        dispatch(success(olduser));
        dispatch(alertActions.success("Profile updated"));
      },
      (error) => {
        dispatch(alertActions.error(error.message));
      }
    );
  };

  function success(user) {
    return { type: userConstants.PROFILE_UPDATED, user };
  }
}
