import FormContainer from "components/OrderDetails/CreateOrder/FormComponents/FormContainer";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { state_array } from "variables/Variables";

const IncCertifiedCopyStateFee = (props) => {
  const { orderDetails, productDetails, onChange, onError } = props;

  const handleProductChange = ({ target: { name, value } }) => {
    const newObj = { ...productDetails };

    onChange({ ...newObj, [name]: value });
  };

  function handleAddError(error) {
    onError(error);
  }

  return (
    <FormContainer>
      {/*<div className="text-center" style={{ fontSize: "0.875rem" }}>
        Product not required extra details
      </div> */}
      <h6 className="font-weight-bold">State Fee Details</h6>
      <Form.Group as={Row}>
        <Form.Label column sm={5}>
          State
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            required
            size="sm"
            as="select"
            name="state"
            value={productDetails?.state || ""}
            onChange={handleProductChange}
            onInvalid={() => handleAddError("State")}
          >
            <option value="">Select a state</option>
            {Object.keys(state_array).map((key) => (
              <option key={key} value={key}>
                {state_array[key]}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Form.Group>
    </FormContainer>
  );
};

export default IncCertifiedCopyStateFee;
