import { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

export const initSMTP = {
  name: "",
  host: "",
  port: "",
  smtpUsername: "",
  smtpPassword: "",
};

export default function EditSMTP({
  show,
  smtp,
  productCategories = [],
  handleClose,
  handleSave,
}) {
  const [formData, setFormData] = useState(initSMTP);
  const [oldName, setOldName] = useState("");
  const [isDirty, setDirty] = useState(false);
  const [isPassFieldDirty, setPassFieldDirty] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleCancel = () => {
    setFormData(initSMTP);
    handleClose();
    setDirty(false);
    setPassFieldDirty(false);
  };

  const handleSaveSMTP = () => {
    const { name, ...d } = formData;

    handleSave(d, name, oldName);
    setFormData(initSMTP);
    setDirty(false);
    setPassFieldDirty(false);
  };

  useEffect(() => {
    setFormData(smtp);
    setOldName(smtp.name);
    setPassFieldDirty(!smtp.smtpPassword);
  }, [smtp]);

  return (
    <Modal show={show} onHide={handleCancel} size="md" centered>
      <Modal.Header closeButton>
        <div>{oldName ? oldName : "New field"}</div>
      </Modal.Header>
      <Modal.Body>
        <Form autoComplete="off" onChange={() => setDirty(true)}>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              as="select"
              name="name"
              value={formData.name}
              isInvalid={!formData.name}
              style={{ background: 0 }}
              onChange={handleChange}
            >
              <option value="">Select Name</option>
              {productCategories.map(({ code }) => (
                <option key={code} value={code}>
                  {code}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Name is required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Host</Form.Label>
            <Form.Control
              required
              value={formData.host}
              name="host"
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
              Host is required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Port</Form.Label>
            <Form.Control
              required
              value={formData.port}
              name="port"
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
              Port is required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Username</Form.Label>
            <Form.Control
              required
              value={formData.smtpUsername}
              name="smtpUsername"
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
              Username is required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Password</Form.Label>
            {isPassFieldDirty ? (
              <Form.Control
                required
                value={formData.smtpPassword}
                name="smtpPassword"
                onChange={handleChange}
              />
            ) : (
              <Form.Control
                required
                value={formData.smtpPassword}
                name="smtpPassword"
                type="password"
                autoComplete="new-password"
                onChange={() => setPassFieldDirty(true)}
              />
            )}
            <Form.Control.Feedback type="invalid">
              Password is required
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Close
        </Button>
        <Button
          variant="primary"
          disabled={!formData.name || !isDirty}
          onClick={handleSaveSMTP}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
